<template>
  <div class="container">
    <div class="phoneMenuBar">
      <div
        class="img-container"
        @click="handleNavClick(this.menuElements[0].title)"
      >
        <img
          :src="
            selectedPage === menuElements[0].title
              ? menuElements[0].iconSelected
              : menuElements[0].icon
          "
          class="menu-icon-img"
          :style="{ height: '40px' }"
        />
      </div>
      <div
        class="img-container"
        @click="handleNavClick(this.menuElements[1].title)"
      >
        <img
          :src="
            selectedPage === menuElements[1].title
              ? menuElements[1].iconSelected
              : menuElements[1].icon
          "
          class="menu-icon-img"
        />
      </div>
      <div class="img-container" @click="handleNavClick(menuElements[2].title)">
        <img
          :src="
            selectedPage === menuElements[2].title
              ? menuElements[2].iconSelected
              : menuElements[2].icon
          "
          class="menu-icon-img"
        />
      </div>
      <div class="bottom">
        <div
          class="img-container"
          @click="handleNavClick(menuElements[3].title)"
          v-if="false"
        > 
        <!-- TODO: need to show this based on group in the future -->
          <img
            :style="{ width: '35px' }"
            :src="
              selectedPage === menuElements[3].title
                ? menuElements[3].iconSelected
                : menuElements[3].icon
            "
            class="menu-icon-img"
          />
        </div>
        <div
          class="img-container"
          @click="handleNavClick(menuElements[4].title)"
        >
          <img
            :src="
              selectedPage === menuElements[4].title
                ? menuElements[4].iconSelected
                : menuElements[4].icon
            "
            class="menu-icon-img"
          />
        </div>
      </div>
    </div>
    <div class="header">
      <template v-if="selectedPage === 'active' && currentCall">
        <ActiveCallComp @updateOnHold="updateOnHold" :currentCall="currentCall"
      /></template>
      <template v-else-if="selectedPage === 'incoming' && currentCall">
        <IncomingCallComp :currentCall="currentCall"
      /></template>
      <template v-else-if="selectedPage === 'Keypad'">
        <KeypadComp :currentCall="currentCall" @navigate="handleNavigate"
      /></template>
      <template v-else-if="selectedPage === 'Call History'">
        <CallHistoryComp :currentCall="currentCall" @navigate="handleNavigate"
      /></template>
      <template v-else-if="selectedPage === 'Calls On Hold'">
        <CallsOnHoldComp @updateOnHold="updateOnHold" :currentCall="currentCall" @navigate="handleNavigate"
      /></template>
      <template v-else-if="selectedPage === 'Voicemail'">
        <VoicemailComp :currentCall="currentCall" @navigate="handleNavigate"
      /></template>
      <template v-else-if="selectedPage === 'Settings'">
        <SettingsComp :currentCall="currentCall" @navigate="handleNavigate"
      /></template>
      <!-- <button class="close" @click="handleClickCloseButton">&times;</button> -->
    </div>
  </div>
</template>

<script>
import KeypadComp from "./Keypad/KeypadComp.vue";
import VoicemailComp from "./Voicemail/VoicemailComp.vue";
import SettingsComp from "./Settings/SettingsComp.vue";
import CallHistoryComp from "./CallHistory/CallHistoryComp.vue";
import CallsOnHoldComp from "./CallsOnHold/CallsOnHoldComp.vue";
import ActiveCallComp from "./ActiveCall/ActiveCallComp.vue";
import IncomingCallComp from "./IncomingCall/IncomingCallComp.vue";
import { useWebRTCStore } from "@/stores/webRTC/webRTCStore.js";
import { useAuthStore } from "@/stores/auth/authStore.js";
import { mapActions, mapStores } from "pinia";

export default {
  name: "PhonePopout",
  components: {
    KeypadComp,
    VoicemailComp,
    SettingsComp,
    CallHistoryComp,
    CallsOnHoldComp,
    ActiveCallComp,
    IncomingCallComp,
  },
  watch: {
    currentCall() {
      this.selectedPage = null;
    },
    isOnHold(newValue) {
      if (newValue === true) {
        this.menuElements[2].icon = require("@/assets/icons/callsonhold_green.png");
        this.menuElements[2].iconSelected = require("@/assets/icons/callsonhold_green.png");
      } else {
        this.menuElements[2].icon = require("@/assets/icons/callsonhold_mblue.png");
        this.menuElements[2].iconSelected = require("@/assets/icons/callsonhold_mblue.png");
      }
    }
  },
  computed: {
    ...mapStores(useWebRTCStore),
    ...mapStores(useAuthStore),
    selectedPage() {
      return this.WebRTCStore.getSelectedPage;
    },
    currentCall() {
      return this.WebRTCStore.getCurrentCall;
    }
  },
  methods: {
    ...mapActions(useWebRTCStore, ["changeSelectedLayout"]),
    handleClickCloseButton() {
      this.$emit("close");
    },
    handleNavClick(page) {
      this.WebRTCStore.changeSelectedPage(page);
    },
    handleNavigate(location) {
      this.WebRTCStore.changeSelectedPage(location);
    },
    updateOnHold() {
      let result = this.WebRTCStore.getCallsOnHold;
      const keys = Object.keys(result);
      if (keys.length > 0) {
        this.menuElements[2].icon = require("@/assets/icons/callsonhold_green.png");
        this.menuElements[2].iconSelected = require("@/assets/icons/callsonhold_green.png");
      } else {
        this.menuElements[2].icon = require("@/assets/icons/callsonhold_mblue.png");
        this.menuElements[2].iconSelected = require("@/assets/icons/callsonhold_mblue.png");
      }
    }
  },
  data() {
    return {
      menuElements: [
        {
          icon: require("@/assets/icons/keypad_dkblue.png"),
          iconSelected: require("@/assets/icons/keypad_mblue.png"),
          title: "Keypad",
          menuItems: [],
          isHover: false,
          clickActive: false,
        },
        {
          icon: require("@/assets/icons/callhistory_dkblue.png"),
          iconSelected: require("@/assets/icons/callhistory_mblue.png"),
          title: "Call History",
          menuItems: [],
          isHover: false,
          clickActive: false,
        },
        {
          icon: require("@/assets/icons/callsonhold_mblue.png"),
          iconSelected: require("@/assets/icons/callsonhold_mblue.png"),
          title: "Calls On Hold",
          menuItems: [],
          isHover: false,
          clickActive: false,
        },
        {
          icon: require("@/assets/icons/voicemail_dkblue.png"),
          iconSelected: require("@/assets/icons/voicemail_mblue.png"),
          title: "Voicemail",
          menuItems: [],
          isHover: false,
          clickActive: false,
        },
        {
          icon: require("@/assets/icons/settings_dkblue.png"),
          iconSelected: require("@/assets/icons/settings_mblue.png"),
          title: "Settings",
          menuItems: [],
          isHover: false,
          clickActive: false,
        },
      ],
      webphone: null,
      isOnHold: false,
    };
  },
  emits: [
    "close"
  ],
  mounted() {
    // TODO: need to start webstore here and get the

    this.WebRTCStore.$subscribe((mutation, state) => {
      if (Object.keys(state.callsOnHold).length === 0) {
        this.menuElements[2].icon = require("@/assets/icons/callsonhold_mblue.png");
        this.menuElements[2].iconSelected = require("@/assets/icons/callsonhold_mblue.png");
        
      } else {
        this.menuElements[2].icon = require("@/assets/icons/callsonhold_green.png");
        this.menuElements[2].iconSelected = require("@/assets/icons/callsonhold_green.png");
      }
    });
    // this.webphone = this.WebRTCStore.getLibWebPhone;
    // if(!this.webphone){
    //   this.WebRTCStore.connectToWebRTC_phone(this.AuthStore.getCurrentUser.username);
    //   this.webphone = this.WebRTCStore.getLibWebPhone;
      
    // }
  },
};
</script>

<style scoped>
.container {
  border: 1px solid #271d7a;
  width: 350px;
  height: 550px;
  display: flex;
}

.header {
  background-color: #fff;
  flex: 1 80%;
  display: flex;
  flex-direction: column;
}

.header .close {
  position: absolute;
  right: 10px;
  top: 5px;
  font-size: 24px;
  line-height: 0;
  width: 10px;
  height: 10px;
}
.phoneMenuBar {
  flex: 1 15%;
  background-color: #e2f1f4;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 10px;
}

.img-container:hover {
  background-color: #ffffff;
  cursor: pointer;
  border-top-left-radius: 2.5px;
  border-bottom-left-radius: 2.5px;
}

.img-container {
  width: 61px;
  padding: 9.5px 8px;
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.menu-icon-img {
  object-fit: contain;
  width: 60px;
  height: 30px;
}

.bottom {
  position: absolute;
  bottom: 1px;
}
</style>
