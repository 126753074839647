<template>
  <div class="editor-bulkupdate" v-if="editor">
  
    <Toolbar class="toolbar-bulkupdate" :editor="editor" />
    <editor-content class="editor-content-bulk-panel" :editor="editor" />
    <h1>{{ result }}</h1>
    <BottomToolbar class="bottom-toolbar-bulkupdate" hideSubmitButtons/>
  </div>
</template>

<script>
import Highlight from "@tiptap/extension-highlight";
import TaskItem from "@tiptap/extension-task-item";
import TaskList from "@tiptap/extension-task-list";
import StarterKit from "@tiptap/starter-kit";
import Underline from "@tiptap/extension-underline";
import Placeholder from "@tiptap/extension-placeholder";
import Color from "@tiptap/extension-color";
import { Editor, EditorContent } from "@tiptap/vue-3";

import Toolbar from "./subComponents/Toolbar.vue";
import BottomToolbar from "./subComponents/BottomToolbar.vue";
import TextStyle from "@tiptap/extension-text-style";

export default {
  components: {
    EditorContent,
    Toolbar,
    BottomToolbar,
  },

  data() {
    return {
      editor: null,
      result: null,
      hideCC: true,
      hideBCC: true,
    };
  },
  props: {
    subjectLine: String,
  },
  mounted() {
    this.editor = new Editor({
      extensions: [
        StarterKit,
        Highlight.configure({ multicolor: true }),
        TaskList,
        TaskItem,
        Underline,
        TextStyle,
        Placeholder.configure({
          placeholder: "Begin typing your message here...",
        }),
        Color.configure({
          types: ["textStyle"],
        }),
      ],
    });
  },

  methods: {
    handleCc(data) {
      // TODO: what needs to be done here besides a console.log? 
      console.log(data);
    },
  },

  beforeUnmount() {
    this.editor.destroy();
  },
};
</script>

<style scoped>
.editor-content-bulk-panel .ProseMirror {
  height: 150px;
  padding: 1rem 2rem;
  color: #271d7a;
  font-size: 12pt;
}

.ProseMirror:focus {
  outline: none;
}

.sender-header.hide {
  display: none;
}

.editor-content-bulk-panel .ProseMirror p.is-editor-empty:first-child::before {
  color: #b1cdea;
  content: attr(data-placeholder);
  float: left;
  font-size: 12pt;
  height: 0;
  pointer-events: none;
}

.editor-bulkupdate {
  outline: 0.25pt solid #271d7a;
  overflow: hidden;
}

.subject-header {
  background-color: #396fba;
  color: #ffffff;
  font-size: 10pt;
  padding: 10px;
  display: flex;
  justify-content: space-between;
}

.sender-header {
  border-bottom: 0.25pt solid #dde9f4;
}

.toolbar-bulkupdate {
  border: 1px solid #dde9f4;
  background-color: #ecf2f8;
  padding-left: 1rem;
  padding-top: 5px;
  padding-bottom: 5px;
}

.bottom-toolbar-bulkupdate {
  background-color: #ecf2f8;
  border-top: 1px solid #dde9f4;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 30px;
}
</style>
