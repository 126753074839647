<template>
    <div>
        <label v-if="PROPS.required" :class="label_size_class">{{ PROPS.label }} *</label>
        <label v-else :class="label_size_class">{{ PROPS.label }}</label>
    </div>
    <div>   
        <v-select ref="searchableSelect" 
            :options="sortedOptions" 
            :clearable="PROPS.clearable"
            :placeholder="PROPS.placeholder"
            :multiple="PROPS.multiple" 
            :disabled="PROPS.disabled"
            :filterable="PROPS.filterable"
            :searchable="PROPS.searchable"
            :select-on-tab="false"
            :modelValue="setValue"
            :class="select_size_class"
            @option:selected="handleSelected">





            <template #open-indicator="{ attributes }">
                <span v-bind="attributes"><img width="20" src="@/assets/icons/caret_closed_dkblue.png" alt=""></span>
            </template>
        </v-select>  
    </div> 
</template>



<script setup>
import { defineProps, defineEmits, defineExpose, computed } from "vue";


//Emits
const emit = defineEmits(["update:modelValue"]);


//Exposed Functions
defineExpose({
    clearSelection
});


//Props
const PROPS = defineProps({
    options: Array,
    name: String,
    multiple: {
        type: Boolean,
        default: false,
    },
    disabled: {
        type: Boolean,
        default: false,
    },
    clearable: {
        type: Boolean,
        default: false,
    },
    searchable: {
        type: Boolean,
        default: true,
    },
    filterable: {
        type: Boolean,
        default: true,
    },
    placeholder: {
        type: String,
        default: ""
    },
    label: {
        type: String,
        default: ""
    },
    required: { //Add Required Visual to Inputs Label
        type: Boolean,
        default: false
    },
    size: {
        type: String,
        default: "default"
    },
    modelValue: {
        type: String
    }
});


//Computed Values
const sortedOptions = computed(() => {
    if (PROPS.options && Array.isArray(PROPS.options)) {
        return [...PROPS.options].sort((a, b) => a.label.localeCompare(b.label));
    }
    return PROPS.options;
});

const setValue = computed(() => {
    for (let option of PROPS.options) {
        if (PROPS.modelValue == option.value) {
            return option.label;
        }
    }
    
    return PROPS.modelValue;
});

const label_size_class = computed(() => {
    if (PROPS.size == 'large') {
        return 'labelLarge';
    }
    else {
        return 'labelDefault';
    }
});

const select_size_class = computed(() => {
    if (PROPS.size == 'large') {
        return 'largeSelect';
    }
    else {
        return 'defaultSelect';
    }
})


//Functions
function handleSelected(option) {
    emit("update:modelValue", option.value);
}

//Exposed Functions
function clearSelection() {
    this.$refs.searchableSelect.clearSelection()
}
</script>


<style scoped>
@import "vue-select/dist/vue-select.css";

.largeSelect {
    --vs-line-height: 2;
    background-color: white;
    font-size: 12px;
    color: #271d7a;
    --vs-selected-color: #271d7a;
    --vs-font-size: 12px;
    --vs-dropdown-option--active-bg: #eff4f9;
    --vs-dropdown-option--active-color: #271d7a;
    --vs-border-color: #271d7a;
    --vs-border-width: 1px;
    --vs-border-style: solid;
    --vs-border-radius: 2px;
    min-width: 155px;
    --vs-dropdown-min-width: 155px;
}

.defaultSelect {
    --vs-line-height: 1.4;
    background-color: white;
    font-size: 12px;
    color: #271d7a;
    --vs-selected-color: #271d7a;
    --vs-font-size: 12px;
    --vs-dropdown-option--active-bg: #eff4f9;
    --vs-dropdown-option--active-color: #271d7a;
    --vs-border-color: #271d7a;
    --vs-border-width: 1px;
    --vs-border-style: solid;
    --vs-border-radius: 2px;
    min-width: 155px;
    --vs-dropdown-min-width: 155px;
}

.labelDefault {
    font-size: 9pt;
    font-weight: 600;
    color: #271d7a;
    padding-bottom: 5px;
}

.labelLarge {
    font-size: 25px;
    font-weight: 500;
    color: #271d7a;
    padding-bottom: 5px;
}
</style>