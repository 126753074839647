<template>
  <div v-if="currentCall?.status === 'active'">
    <div class="activeCallHeader" @click="handleActiveClick">
      <img
        :src="require('@/assets/icons/call_white.png')"
        class="activeCallIcon"
      />
      <h1 class="activeCallText">
        Active Call
        <span :style="{ paddingLeft: '10px' }">
          ({{ currentCall["Total Call Duration"] }})
        </span>
      </h1>
    </div>
    <h2 class="voicemailActiveText">Voicemail</h2>
  </div>
  <div v-else class="enterNumberBoxContainer">
    <img
      :src="require('@/assets/icons/voicemail_dkblue.png')"
      class="voicemailIcon"
    />
    <h1 class="voicemailHeader">Voicemail</h1>
  </div>

  <div v-if="false" class="voicemailContainer">
    <template v-for="(voicemail, index) in voicemails" :key="index">
      <VoicemailCard
        :voicemail="voicemail"
        :isSelected="voicemail == selectedVoicemail"
        @selectedVoicemail="handleSelectedVoicemail"
      />
    </template>
  </div>
  <div
    class="activeCallButtonContainer"
    v-if="currentCall?.status === 'active'"
  >
    <DividerComp class="activeCallDivider" />
    <div class="hangupButtonContainer">
      <LightButton
        class="hangupButton"
        icon="hangup.png"
        :style="{
          height: '35px',
          backgroundColor: '#e78535',
          color: 'white',
        }"
        @click="handleHangup"
      />
    </div>
  </div>
  <div v-else class="callButtonsContainer">
    <div class="callButton">
      <VoicemailAudio v-if="false" :audio="selectedVoicemail?.audio" />
      <CallButton @click="handleCallClick" />
    </div>
  </div>
</template>

<script>
import VoicemailCard from "./VoicemailCard.vue";
import CallButton from "../Keypad/CallButton.vue";
import VoicemailAudio from "./VoicemailAudio.vue";
import DividerComp from "@/components/base_components/DividerComp.vue";
import LightButton from "../LightButtonWebRTC.vue";
import { useWebRTCStore } from "@/stores/webRTC/webRTCStore.js";
import { mapActions, mapStores } from "pinia";
export default {
  name: "VoicemailComp",
  props: { currentCall: Object },
  emits: ["navigate"],
  components: {
    VoicemailCard,
    CallButton,
    VoicemailAudio,
    DividerComp,
    LightButton,
  },
  data() {
    return {
      number: "",
      selectedVoicemail: null,
      voicemails: [
        {
          caller: "Unavailable",
          time: "06/26/2023 - 10:05:07",
          duration: "01:23 m",
          audio: "./assets/audio-example.mp3",
        },
        {
          caller: "Andres Gomez",
          time: "06/26/2023 - 10:05:07",
          duration: "14s",
          audio: "./assets/audio-example.mp3",
        },
      ],
    };
  },
  computed: {
    ...mapStores(useWebRTCStore),
  },
  methods: {
    ...mapActions(useWebRTCStore, ["changeNumber"]),
    handleDigitClick(digit) {
      this.number += String(digit);
    },
    handleCallClick() {
      let dial_pad = this.WebRTCStore.getLibWebPhone.getDialpad();
      dial_pad.clear();
      dial_pad.dial("*");
      dial_pad.dial("9");
      dial_pad.dial("8");
      dial_pad.call(false);

      this.WebRTCStore.changeSelectedPage("Keypad");
      this.WebRTCStore.setCurrentCallStatus("active");
    },
    handleSelectedVoicemail(voicemail) {
      if (voicemail === this.selectedVoicemail) {
        this.selectedVoicemail = null;
      } else {
        this.selectedVoicemail = voicemail;
      }
    },
    handleActiveClick() {
      this.$emit("navigate", "active");
    },
  },
};
</script>

<style scoped>
.voicemailContainer {
  padding: 10px 10px 0px 10px;
  background-color: #f8f9fc;
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: auto;
  height: 100%;
}
.activeCallHeader {
  padding-top: 25px;
  padding-bottom: 25px;
  background-color: #e78535;
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
}
.activeCallHeader:hover {
  outline: 0.25px solid #271d7a;
  cursor: pointer;
}
.activeCallIcon {
  height: 30px;
  width: 24px;
  margin-right: 20px;
  margin-left: 20px;
  text-align: center;
}
.activeCallText {
  color: #ffffff;
  font-size: 19px;
  font-weight: 500;
}
.activeCallButtonContainer {
  margin: auto auto 0 auto;
  padding-bottom: 30px;
  width: 100%;
  background-color: #f8f9fc;
  flex: 1;
}
.hangupButtonContainer {
  display: flex;
  padding-top: 20px;
}
.hangupButton {
  width: 90%;
  display: flex;
  margin: auto;
}
.activeCallDivider {
  width: 100%;
  margin-bottom: 25px;
}
.enterNumberBoxContainer {
  margin-top: 25px;
  margin-bottom: 25px;
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
}
.voicemailIcon {
  height: 15px;
  width: 34px;
  margin-right: 15px;
  margin-left: 15px;
  text-align: center;
}
.voicemailHeader {
  color: #271d7a;
  font-size: 19px;
  font-weight: 500;
}
.callButton {
  margin: auto auto 0 auto;
  padding: 5px;
  padding-bottom: 30px;
  width: 90%;
}
.callButtonsContainer {
  background-color: #f8f9fc;
}
.voicemailActiveText {
  background-color: #f8f9fc;
  font-size: 17px;
  font-weight: 500;
  color: #271d7a;
  padding: 10px 0px 0px 20px;
}
</style>
