<template>
  <div>
    <input
      v-model="url"
      type="url"
      placeholder="Enter a URL"
      @input="validateURL"
      class="inputNormal"

    />
    <p v-if="!isValidURL" class="invalidUrl">Invalid URL</p>
  </div>
</template>

<script>
export default {
  name: "SmartLink",
  emits: ["url"],
  props: {
    url_value: String
  },
  data() {
    return {
      url: "",
      isValidURL: true,
    };
  },
  methods: {
    validateURL() {
      try {
        new URL(this.url);
        this.isValidURL = true;
        this.$emit("url", this.url);
      } catch {
        this.isValidURL = false;
        this.$emit("url", null);
      }
    },
  },
  watch: {
    url_value: {
      immediate: true,
      deep: true,
      handler(url_value) {
        this.url = url_value;
      },
    },
  },
  unmounted() {
    this.url = "";
  },
};
</script>
<style scoped>
.inputNormal {
  outline: #2e3e91 solid 0.25pt;
  border-radius: 2px;
  padding-left: 8px;
  padding-top: 2px;
  padding-bottom: 2px;
  font-size: 9pt;
  font-weight: 500;
  color: #271d7a;
  background-color: #ffffff;
  height: 28px;
  width: 100%;
}
.inputNormal:hover {
  outline: #2e3e91 solid 0.5pt;
}
.inputNormal:focus {
  outline: #2e3e91 solid 1pt;
}
.inputNormal::placeholder {
  opacity: 0;
  size: 9pt;
  color: #b1cdea;
}
.inputNormal:focus::placeholder {
  opacity: 1;
}
.invalidUrl {
  font-size: 9pt;
  font-weight: 500;
  color: #e89032;
}
</style>
