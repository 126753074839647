<template>
  <div 
    class="sideDrawer"
    :class="{
      open: drawerOpen,
      formBackground:
        drawerData[0]?.type === 'form' || drawerData[0]?.type === 'review',
     }"
  >
    <button
      class="sideDrawerRemoveButton"
      @click="handleClose"
      v-if="drawerData[0]?.title !== 'Filters'"
    >
      <img
        class="sideDrawerRemoveButton"
        :src="require('@/assets/icons/Remove.png')"
      />
    </button>
    <div class="sideDrawerContainer">
      <h3 class="sideDrawerTitle">{{ drawerData[0]?.title }}</h3>
      <table class="sideDrawerTable">
        <tr>
          <td>Quality Category</td>
          <td colspan="2">Scale</td>
          <td>Score</td>
        </tr>
        <tr>
          <td></td>
          <td>UnSatisfactory</td>
          <td>Satisfactory</td>
          <td></td>
        </tr>
        <tr>
          <td>Tone</td>
          <td class="light-blue">0-19</td>
          <td class="dark-blue">20-23</td>
          <td :class="previousSubmissionData?.score?.tone < 20 ? 'table-data-scale-unsatisfactory' : 'table-data-scale-satisfactory'">{{ previousSubmissionData?.score?.tone }}</td>
        </tr>
        <tr>
          <td>Greeting</td>
          <td class="light-blue">0-9</td>
          <td class="dark-blue">10-12</td>
          <td :class="previousSubmissionData?.score?.greeting < 10 ? 'table-data-scale-unsatisfactory' : 'table-data-scale-satisfactory'">{{ previousSubmissionData?.score?.greeting }}</td>
        </tr>
        <tr>
          <td>Trouble</td>
          <td class="light-blue">0-35</td>
          <td class="dark-blue">36-40</td>
          <td :class="previousSubmissionData?.score?.trouble < 36 ? 'table-data-scale-unsatisfactory' : 'table-data-scale-satisfactory'">{{ previousSubmissionData?.score?.trouble }}</td>
        </tr>
        <tr>
          <td>Documentation</td>
          <td class="light-blue">0-21</td>
          <td class="dark-blue">22-25</td>
          <td :class="previousSubmissionData?.score?.documentation < 22 ? 'table-data-scale-unsatisfactory' : 'table-data-scale-satisfactory'">{{ previousSubmissionData?.score?.documentation }}</td>
        </tr>
        <tr>
          <td>Compliance</td>
          <td class="light-blue">Fail</td>
          <td class="dark-blue">Pass</td>
          <td v-if="previousSubmissionData?.score?.compliance == 'Pass'" class="table-data-scale-satisfactory">Pass</td>
          <td v-if="previousSubmissionData?.score?.compliance == 'Fail'" class="table-data-scale-unsatisfactory">Fail</td>
        </tr>
        <tr>
          <td colspan="2">100 Possible Points</td>
          <td colspan="1">Total Score</td>
          <td colspan="1" :class="previousSubmissionData?.score?.compliance === 'Fail' || accumulatedScore < 85 ? 'table-data-scale-unsatisfactory' : 'table-data-scale-satisfactory' ">{{ previousSubmissionData?.score?.сompliance === 'Fail' ? '0' : accumulatedScore}}</td>
        </tr>
      </table>
    </div>
    <div class="formContainer">
      <div v-if="drawerData[0].type === 'form'">
        <QualityAuditsForm
          :formData="drawerData"
          @close-click="handleClose"
          :numCols="numCols"
          :pdf="false"
          @submissionDataUpdated="handleSubmissionDataUpdate"
          @submission-data-independent="handleSubmissionIndependentData"
          @submission-data="handleFormSubmission"
          :buttonOptions="drawerData[0].buttonOptions"
          :previousFormValues="previousSubmissionData"
        >
          <slot></slot>
          <template v-slot:submit-button>
            <slot name="submit-button"></slot>
          </template>
        </QualityAuditsForm>
      </div>
    </div>
  </div>
</template>

<script>
import QualityAuditsForm from "@/components/CcManagement/Attendance/Forms/QualityAuditsForm.vue";

export default {
  name: 'ReviewSideDrawer',
  components: {
    QualityAuditsForm
  },
  props: {
    drawerData: {
      type: Array,
      default: () => []
    },
    drawerOpen: {
      type: Boolean,
      default: false
    },
    previousSubmissionData: {
      type: Object,
      default: () => ({})
    },
    numCols: {
      type: Number,
      default: 6
    },
  },
  computed: {
    isCompliancePass() {
        return this.previousSubmissionData?.score?.compliance === 'Pass';
    },
    accumulatedScore() {
      const total = this.accumulateNumericValues(this.previousSubmissionData?.score);
      return total;
    }
  },
  methods: {
    accumulateNumericValues(obj) {
      let total = 0;

      for (const key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
          const value = obj[key];
          if (typeof value === 'number') {
            total += value;
          }
        }
      }

      return total;
    },
    handleClose() {
      this.$emit("close-click");
    },
    handleSubmissionDataUpdate(data) {
      this.$emit("submissionDataUpdate", data);
    },
    handleFormSubmission (data) {
      this.$emit('form-submission', data)
    },
    handleSubmissionIndependentData(data) {
      this.$emit('submission-data-independent', data)
    }
  },
  emits: [
    "close-click",
    "submissionDataUpdate",
    "form-submission",
    "submission-data-independent"
  ]
}
</script>

<style scoped>
.sideDrawer {
  position: fixed;
  top: 93px!important;
  right: 0;
  max-height: calc(100vh - 93px);
  overflow-x: auto;
  padding-top: 16px;
  max-width: 550px;
  background-color: #fff;
  z-index: 10;
  transition: transform 0.2s ease;
  transform: translateX(100%);
}

.sideDrawer.open {
  filter: drop-shadow(5px 5px 5px rgb(39, 29, 122, 1));
  transform: translateX(0);
}
.sideDrawerRemoveButton {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  align-items: flex-end;
  width: 31px;
  height: 35px;
  padding: 20px 16px 0px 0px;
}

.sideDrawerTitle {
  padding-bottom: 10px;
  font-size: 14pt;
  font-weight: bold;
  color: #271d7a;
}

.sideDrawerInputCol {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-left: 35px;
  padding-right: 35px;
}

.sideDrawerSubtitle {
  padding-bottom: 20px;
  color: #271d7a;
}

.sideDrawerInputLabel {
  color: #271d7a;
  font-weight: 500;
  font-size: 10pt;
  padding: 5px 0px 3px 0px;
}

.sideDrawerContainer {
  padding: 0 16px;
}

.sideDrawerTable {
  width: 100%;
  border: 1px solid #000;
  border-collapse: collapse;
  font-size: 13px;
  margin-bottom: 20px;
}
.sideDrawerTable td {
  padding: 2px;
  border: 1px solid #000;
}

.light-blue {
  background-color :#fca608;
}
.dark-blue {
  background-color: #3e87d3;
  color: #fff;
}

.formContainer {
  overflow-y: auto;
  padding-bottom: 0;
}

:deep(.formButtonPositionBottom) {
  position: sticky;
  bottom: 0;
}
:deep(.formHeader) {
  display: none;
}

.table-data-scale-unsatisfactory {
  text-align: center;
  background-color :#fca608;
}
.table-data-scale-satisfactory {
  text-align: center;
  background-color: #3e87d3;
  color: #fff;
}
:deep(.formButtonPositionBottom .formDarkButton ) {
  padding-left: 16px;
  padding-right: 16px;
}
</style>