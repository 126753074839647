<template>
  <div class="incomingCallHeader">
    <img
      :src="require('@/assets/icons/call_white.png')"
      class="incomingCallIcon"
    />
    <h1 class="incomingCallText">Incoming Call</h1>
  </div>

  <div class="incomingCallInfo">
    <div
      v-for="(value, key, index) in currentCall"
      :key="index"
      class="incomingCallDetail"
    >
      <label class="incomingCallDetailLabel" v-if="key !== 'status'">{{
        key
      }}</label>
      <span class="incomingCallDetailValue" v-if="key !== 'status'">
        {{ value }}</span
      >
    </div>
  </div>
  <div class="incomingCallButtonContainer">
    <DividerComp class="incomingCallDivider" />
    <div class="activeButtonContainer">
      <LightButton
        icon="ignore.png"
        title="Ignore"
        :style="{ height: '35px', width: '120px' }"
        @click="hanleIgnore"
      />
    </div>
    <div class="incomingButtonContainer">
      <LightButton
        class="incomingButton"
        icon="incomingcall.png"
        :style="{
          height: '35px',
          backgroundColor: '#b8d998',
          color: 'white',
        }"
        @click="handlePickup"
      />
    </div>
  </div>
</template>

<script>
import DividerComp from "@/components/base_components/DividerComp.vue";
import LightButton from "../LightButtonWebRTC.vue";
import { useWebRTCStore } from "@/stores/webRTC/webRTCStore.js";
import { mapActions, mapStores } from "pinia";
export default {
  name: "IncomingCallComp",
  props: {
    currentCall: Object,
  },
  components: { DividerComp, LightButton },
  data() {
    return {
      recording: true,
    };
  },
  computed: {
    ...mapStores(useWebRTCStore),
  },
  methods: {
    ...mapActions(useWebRTCStore, ["changeNumber"]),
    handlePickup() {
      let dialpad = this.WebRTCStore.getLibWebPhone.getDialpad();
      
      if(this.WebRTCStore.getCurrentCallCallStatus != "active"){
        dialpad.answer();
        //this.WebRTCStore.changeSelectedPage("active");
      }
      else{
        // TODO: for some reason we have an issue with this not transfering the call over. 
        // Tested with both dialpad and handling the call. 
        let current_call = this.WebRTCStore.getCurrentCallObject;
        if(current_call){
          current_call._clearPrimary();
          // dialpad.answer();
          let new_call = this.WebRTCStore.getNewCallObject;
          if(new_call){
            new_call._setPrimary();
            new_call.answer();
            this.WebRTCStore.setCurrentCallCallObj(new_call);
          }
        }
      }
    },
    hanleIgnore() {
      this.WebRTCStore.hangupCurrentCall();
      this.WebRTCStore.changeSelectedPage("Keypad");
    },
  },
};
</script>

<style scoped>
.incomingCallInfo {
  padding: 15px 10px 0px 5px;
  background-color: #f8f9fc;
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: auto;
  height: 100%;
}
.incomingCallHeader {
  padding-top: 25px;
  padding-bottom: 25px;
  background-color: #b8d998;
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
}
.recording {
  background-color: #f8f9fc;
  padding: 10px 0px 10px 20px;
  display: flex;
  justify-content: space-between;
}
.recordStatus {
  color: #271d7a;
  font-weight: 500;
}
.recordImg {
  width: 13px;
  margin-right: 10px;
}
.incomingCallIcon {
  height: 30px;
  width: 24px;
  margin-right: 20px;
  margin-left: 20px;
  text-align: center;
}
.incomingCallText {
  color: #ffffff;
  font-size: 19px;
  font-weight: 500;
}
.incomingCallButtonContainer {
  margin: auto auto 0 auto;
  padding-bottom: 30px;
  width: 100%;
  background-color: #f8f9fc;
  flex: 1;
}
.incomingCallDivider {
  width: 100%;
  margin-bottom: 25px;
}

.incomingCallButton {
  width: 80%;
  margin: auto;
}
.recordButton {
  margin-right: 10px;
  width: 80px;
}
.incomingCallDetail {
  padding-bottom: 15px;
  padding-left: 15px;
  display: flex;
  flex-direction: column;
}
.incomingCallDetailLabel {
  color: #3e87d3;
  font-size: 10pt;
  padding-bottom: 5px;
}
.incomingCallDetailValue {
  color: #271d7a;
  font-size: 10pt;
  font-weight: 400;
}
.activeButtonContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0px 15px 0px 15px;
}
.incomingButtonContainer {
  display: flex;
  padding-top: 20px;
}
.incomingButton {
  width: 90%;
  display: flex;
  margin: auto;
}
</style>
