<template>
    <div class="form_container" :style="{ maxWidth: dynamicWidth + 'px'}">
      <FormComp 
        :formData="formData" 
        :buttonOptions="{ positionBottom: false, centered: false, fillToParent: false, divider: true }" 
        title="Set Account Limits"
        @submission-data="handleFormSubmission"
      />
    </div>
    <AlertsGroup
      v-model:alerts="alerts"
    />
  </template>
  
  <script>
  import FormComp from "@/components/base_components/FormComponents/FormComp.vue";
  import AlertsGroup from "@/components/base_components/AlertsGroup.vue";
  import { addAlert } from '@/helpers/alertUtils';
  import { ERROR, SUCCESS } from '@/constants/statuses.constants';
  import { useAccountLimitsStore } from "@/stores/SwitchOps/AccountLimits/accountLimitsStore.js";
  import { mapActions, mapStores } from 'pinia';

  export default {
    name: "SetAccountLimits",
    data() {
      return {
        alerts: [],
        windowWidth: window.innerWidth,
        formData: [
          {
            title: "Set Account Limits",
            type: "bulkUpdate", button: "Update" 
          },
          {
            inputs: [
              { name: "Account ID", type: "bubble", required: true },
            ],
          },
          {
            inputs: [
              { name: "Two-Way Trunks (Range: 0-5000)", type: "bubble", required: false },
              { name: "Inbound Trunks (Range: 0-5000)", type: "bubble", required: false },
            ],
          },
          {
            inputs: [
              { name: "Outbound Trunks (Range: 0-5000)", type: "bubble", required: false },
              { name: "Resource-consuming Calls (Range: 0-5000)", type: "bubble", required: false },
            ],
          },
          {
            inputs: [
                { name: "Allow Prepay", type: "radio", options: [{label:"Yes", value: "Yes"},{label: "No", value: "No"}], required: false }
            ],
          }
        ],
      }
    },
    mounted() {
      window.addEventListener("resize", this.handleResize);
    },
    methods: {
      ...mapActions(useAccountLimitsStore, ['setLimits']),
      handleFormSubmission(formData) {
        if (formData === "Error") {
          addAlert(ERROR, 'Missing Required Values', this.alerts);
        } else {
          var results = this.setLimits(formData["Two-Way Trunks (Range: 0-5000)"], formData["Allow Prepay"], formData["Outbound Trunks (Range: 0-5000)"], formData["Resource-consuming Calls (Range: 0-5000)"], formData["Inbound Trunks (Range: 0-5000)"], formData["Account ID"])
          
          if(results.error) {
            addAlert(ERROR, results.message, this.alerts);
          }
          addAlert(SUCCESS, 'Successfully Set Limits!', this.alerts);
        }
      },
      handleResize() {
        this.windowWidth = window.innerWidth;
      },
    },
    computed: {
      ...mapStores(useAccountLimitsStore),
      dynamicWidth() {

      let width = "";
      console.log(window.innerWidth)
      console.log('window', this.windowWidth)
      if (this.windowWidth > 1000) {
          width = Number(this.windowWidth) - 400;
      } else {
          width = Number(this.windowWidth) - 310;
      }
      console.log('width ', width)
      return width;
      },
    },
    watch: {
      windowWidth() {
        this.$nextTick(() => {
          this.dynamicWidth;
        });
      },
    },
    components: {
      FormComp,
      AlertsGroup
    }
  }
  </script>
  
  <style scoped>
    .form_container {
      padding: 20px ;
      background-color: #e2f1f4;
      width: 590px;
    }

    :deep(.formInputLabel) {
      text-wrap: nowrap;
    }
  
    .formContainer {
      padding-bottom: 0;
    }
    
    @media (max-width: 911px) {
      :deep(.formInputLabel) {
        text-wrap: wrap;
      }
    }
  </style>