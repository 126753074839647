<template>
  <div>
    <DarkButton
      @click.prevent="addOption"
      class="dynamicOptionAdd"
      title="Add Option"
    >
    </DarkButton>
    <div
      v-for="(option, index) in options"
      :key="option"
      class="dynamicOptionContainer"
    >
      <label class="dynamicOptionLabel">
        {{ `Option ${index + 1}` }} <span>*</span>
      </label>
      <div class="inputContainer">
        <FillInBubble
          :placeholder="`Option ${index + 1}`"
          :isRequired="true"
          class="dynamicOptionsBubble"
          @input="updateInputVal(index, $event)"
          :value="option.value"
        />
        <button @click.prevent="removeOption(index)" class="dynamicOptionClose">
          <img src="@/assets/icons/Remove.png" class="dynamicOptionsRemove" />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import DarkButton from "@/components/base_components/Buttons/DarkButton.vue";
import FillInBubble from "@/components/base_components/FormComponents/FillInBubble.vue";

export default {
  name: "DynamicOptions",
  props: {
    initialOptions: {
      type: Array,
      default: () => [{ value: "", label: "" }],
    },
    previousFormValues: Object,
  },
  data() {
    return {
      options: [{ value: "", label: "" }],
    };
  },
  methods: {
    addOption() {
      const newOption = { value: "", label: "" };
      this.options.push(newOption);
    },
    removeOption(index) {
      this.options.splice(index, 1);
    },
    updateInputVal(index, event) {
      if (event.target.value) {
        this.options[index].value = event.target.value;
        this.options[index].label = event.target.value;
      }
      this.$emit("dOptionsInput", this.options);
    },
  },
  components: { FillInBubble, DarkButton },
  watch: {
    previousFormValues: {
      immediate: true,
      handler() {
        this.options = this.initialOptions;
      },
    },
  },
  emits: [
    "dOptionsInput"
  ]
};
</script>

<style scoped>
.dynamicOptionAdd {
  margin-bottom: 20px;
}
.dynamicOptionContainer {
  margin-bottom: 20px;
}
.dynamicOptionsBubble {
  width: 99%;
}

.dynamicOptionInput {
  margin-right: 10px;
}

.dynamicOptionClose {
  padding-left: 5px;
  margin-right: -10px;
}

.dynamicOptionLabel {
  margin-right: 10px;
  margin-bottom: 5px;
  display: block;
  font-size: 9pt;
  font-weight: 500;
  color: #271d7a;
}
.inputContainer {
  display: flex;
  align-items: center;
}
.dynamicOptionsRemove {
  width: 20px;
  height: 15px;
  padding-left: 5px;
  padding-top: 1px;
}
</style>
