import { defineStore } from 'pinia';


export const useHelpStore = defineStore('HelpManagement', {
    state: () => ({
        page: ""
    }), 
    getters: {
        getPage(state) {
            return state.page
        }
    },
    actions: {
        setPage(selectedPage ) {
            this.page = selectedPage;
        }
    },
})