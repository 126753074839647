import { defineStore } from 'pinia'
import { API } from "aws-amplify";
import * as queries from "@/graphql/queries.js";
import * as mutations from "@/graphql/mutations.js";
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api";

export const useCCManagementSchedulingStore = defineStore('CCManagementScheduling', {
    state: () => ({
        employees: [],
        partners: [],
    }),
    getters: {
        getEmployees(state) {
            return state.employees
        },
        getPartners(state) {
            return state.partners
        }
    },
    actions: {
        getCorrectValue(data) {
            if (typeof data === 'object' && data !== null && 'label' in data) {
                return data.label;
            }
            return data;
        },
        async getSupervisors() {
            var error_message = "Unable to Get Supervisors";
            try {
                var hasData = true;
                var nextToken = null;
                var supervisorsSet = new Set();

                while (hasData) {
                    var options = {
                        query: queries.listEmployees,
                        variables: {
                            filter: {
                                _deleted: { ne: true },
                                assigned_group: { eq: "Contact Center" }
                            },
                            limit: 2000
                        },
                        authMode: GRAPHQL_AUTH_MODE.API_KEY
                    }

                    if (nextToken != null) {
                        options.variables.nextToken = nextToken;
                    }

                    var result = await API.graphql(options);

                    for (const item of result.data.listEmployees.items) {
                        if (item.name && !supervisorsSet.has(item.name)) {
                            supervisorsSet.add(item.name);
                        }
                    }

                    if (result.data.listEmployees.nextToken != null) {
                        nextToken = result.data.listEmployees.nextToken;
                    } else {
                        hasData = false;
                    }
                }

                //return Array.from(supervisorsSet).sort(); 
                return Array.from(supervisorsSet);
            }
            catch (error) {
                return { error: true, message: error_message };
            }
        },
        async fetchPositions() {
            const errorMessage = "Unable to fetch positions";
            try {
                let hasData = true;
                let nextToken = null;
                const positionsSet = new Set();

                while (hasData) {
                    const options = {
                        query: queries.listEmployees,
                        variables: {
                            filter: {
                                _deleted: { ne: true },
                                assigned_group: { eq: "Contact Center" }
                            },
                            limit: 2000
                        },
                        authMode: GRAPHQL_AUTH_MODE.API_KEY
                    };

                    if (nextToken) {
                        options.variables.nextToken = nextToken;
                    }

                    const result = await API.graphql(options);

                    for (const item of result.data.listEmployees.items) {
                        if (item.position && !positionsSet.has(item.position)) {
                            positionsSet.add(item.position);
                        }
                    }

                    if (result.data.listEmployees.nextToken) {
                        nextToken = result.data.listEmployees.nextToken;
                    } else {
                        hasData = false;
                    }
                }

                return Array.from(positionsSet);
            } catch (error) {
                console.error(errorMessage, error);
                return { error: true, message: errorMessage };
            }
        },
        async getEmployeeInformation() {
            var error_message = "Unable to Get Employees";
            this.employees = [];
            this.employeeVersions = {};
            try {
                var hasData = true;
                var nextToken = null;
                var employeeData = [];


                while (hasData) {
                    var options = {
                        query: queries.listEmployees,
                        variables: {
                            filter: {
                                _deleted: { ne: true },
                                assigned_group: { eq: "Contact Center" }
                            },
                            limit: 2000
                        },
                        authMode: GRAPHQL_AUTH_MODE.API_KEY
                    }

                    if (nextToken != null) {
                        options.variables.nextToken = nextToken;
                    }

                    var employees = await API.graphql(options);

                    for (const item of employees.data.listEmployees.items) {
                        employeeData.push(item);
                    }

                    if (employees.data.listEmployees.nextToken != null) {
                        nextToken = employees.data.listEmployees.nextToken;
                    }
                    else {
                        hasData = false;
                    }
                }

                this.employees = employeeData;
                return this.employees
            }
            catch (error) {
                return { error: true, message: error_message };
            }
        },
        async addEmployee(name, ticket_number, email, position, supervisor, start_time, duration, work_days) {
            var error_message = "Unable to Add Employee";

            const workDaysString = work_days.map(day => day.label).join(', ');
            supervisor = this.getCorrectValue(supervisor);

            try {
                var options = {
                    query: mutations.createEmployees,
                    variables: {
                        input: {
                            name: name,
                            employee_ticket_number: ticket_number,
                            assigned_group: "Contact Center",
                            email: email,
                            position: position,
                            supervisor: supervisor,
                            start_time: start_time,
                            terminated: "No",
                            duration: duration,
                            work_days: workDaysString
                        }
                    },
                    authMode: GRAPHQL_AUTH_MODE.API_KEY
                }

                var result = await API.graphql(options);

                if (!result.data.createEmployees.id) {
                    return { error: true, message: error_message };
                }

                return { error: false, message: 'Ok' };
            }
            catch (error) {
                return { error: true, message: error_message };
            }
        },
        async editEmployee(id, name, ticket_number, email, position, supervisor, start_time, terminated, duration, work_days, date_terminated) {
            var error_message = "Unable to Edit Employee";

            terminated = this.getCorrectValue(terminated);

            if (date_terminated && terminated === 'Yes') {
                const parts = date_terminated.split('/');
                date_terminated = `${parts[2]}-${parts[0].padStart(2, '0')}-${parts[1].padStart(2, '0')}`;
            } else {
                date_terminated = null;
            }

            try {
                const queryOptions = {
                    query: queries.getEmployees,
                    variables: { id: id },
                    authMode: GRAPHQL_AUTH_MODE.API_KEY
                };

                const queryResult = await API.graphql(queryOptions);

                if (!queryResult.data || !queryResult.data.getEmployees) {
                    return { error: true, message: "Employee not found" };
                }

                const _version = queryResult.data.getEmployees._version;
                const workDaysString = work_days.map(day => day.label).join(', ');
                supervisor = this.getCorrectValue(supervisor);

                const updateOptions = {
                    query: mutations.updateEmployees,
                    variables: {
                        input: {
                            id: id,
                            name: name,
                            employee_ticket_number: ticket_number,
                            email: email,
                            position: position,
                            supervisor: supervisor,
                            start_time: start_time,
                            terminated: terminated,
                            duration: duration,
                            work_days: workDaysString,
                            date_terminated: terminated === 'Yes' ? date_terminated : null,
                            _version: _version
                        }
                    },
                    authMode: GRAPHQL_AUTH_MODE.API_KEY
                };

                const updateResult = await API.graphql(updateOptions);

                if (!updateResult.data.updateEmployees.id) {
                    return { error: true, message: error_message };
                }

                return { error: false, message: "Successfully Updated Employee" };
            }
            catch (error) {
                return { error: true, message: error_message };
            }
        },
        async removeEmployee(id) {
            var error_message = "Unable to Remove Employee";

            try {
                const queryOptions = {
                    query: queries.getEmployees,
                    variables: { id: id },
                    authMode: GRAPHQL_AUTH_MODE.API_KEY
                };

                const queryResult = await API.graphql(queryOptions);

                if (!queryResult.data || !queryResult.data.getEmployees) {
                    return { error: true, message: "Partner not found" };
                }

                const _version = queryResult.data.getEmployees._version;

                const deleteOptions = {
                    query: mutations.deleteEmployees,
                    variables: {
                        input: {
                            id: id,
                            _version: _version
                        }
                    },
                    authMode: GRAPHQL_AUTH_MODE.API_KEY
                };

                const deleteResult = await API.graphql(deleteOptions);

                if (!deleteResult.data.deleteEmployees.id) {
                    return { error: true, message: error_message };
                }

                return { error: false, message: "Successfully removed employee" };
            }
            catch (error) {
                return { error: true, message: error_message };
            }
        },
        async getPartnerInformation() {
            var error_message = "Unable to get Partners";
            this.partners = [];
            this.partnerVersions = {};
            try {
                var partnerData = [];

                var options = {
                    query: queries.listManagerPartners,
                    variables: {
                        filter: {
                            _deleted: { ne: true }
                        },
                        limit: 2000
                    },
                    authMode: GRAPHQL_AUTH_MODE.API_KEY
                };

                var partners = await API.graphql(options);

                for (const item of partners.data.listManagerPartners.items) {
                    partnerData.push(item);
                }
                
                this.partners = partnerData;

                return this.partners
            }
            catch (error) {
                return { error: true, message: error_message };
            }
        },
        async addPartner(name, ticket_queue_name, contact_category_name) {
            var error_message = "Unable to Add Partner";

            try {
                var query = `mutation MyMutation($input: CreateManagerPartnersInput!) {
                    createManagerPartners(input: $input) {
                        id
                        name
                        ticket_queue_name
                        category
                    }
                }
            `;

                var variables = {
                    input: {
                        name: name,
                        ticket_queue_name: ticket_queue_name,
                        category: contact_category_name
                    }
                };

                var options = {
                    query: query,
                    variables: variables,
                    authMode: GRAPHQL_AUTH_MODE.API_KEY
                };

                var result = await API.graphql(options);

                if (!result.data.createManagerPartners.id) {
                    return { error: true, message: result.errors };
                }

                return { error: false, message: 'Ok' };
            }
            catch (error) {
                return { error: true, message: error_message };
            }
        },
        async editPartner(id, name, ticket_queue_name, contact_category_name) {

            var error_message = "Unable to Edit Partner";

            contact_category_name = this.getCorrectValue(contact_category_name);

            try {
                const queryOptions = {
                    query: queries.getManagerPartners,
                    variables: { id: id },
                    authMode: GRAPHQL_AUTH_MODE.API_KEY
                };

                const queryResult = await API.graphql(queryOptions);

                if (!queryResult.data || !queryResult.data.getManagerPartners) {
                    return { error: true, message: "Partner not found" };
                }

                const _version = queryResult.data.getManagerPartners._version;

                var updateQuery = `mutation UpdateManagerPartners($input: UpdateManagerPartnersInput!) {
                  updateManagerPartners(input: $input) {
                    id
                    name
                    ticket_queue_name
                    category
                    _version
                    }
                  }`;

                var options = {
                    query: updateQuery,
                    variables: {
                        input: {
                            id: id,
                            name: name,
                            ticket_queue_name: ticket_queue_name,
                            category: contact_category_name,
                            _version: _version
                        }
                    },
                    authMode: GRAPHQL_AUTH_MODE.API_KEY
                };

                var result = await API.graphql(options);

                if (!result.data.updateManagerPartners.id) {
                    return { error: true, message: error_message };
                }

                return { error: false, message: 'Successfully updated partner' };
            }
            catch (error) {
                return { error: true, message: error_message };
            }
        },
        async removePartner(id) {
            var error_message = "Unable to Remove Partner";

            try {

                const queryOptions = {
                    query: queries.getManagerPartners,
                    variables: { id: id },
                    authMode: GRAPHQL_AUTH_MODE.API_KEY
                };

                const queryResult = await API.graphql(queryOptions);

                if (!queryResult.data || !queryResult.data.getManagerPartners) {
                    return { error: true, message: "Partner not found" };
                }

                const _version = queryResult.data.getManagerPartners._version;

                var options = {
                    query: mutations.deleteManagerPartners,
                    variables: {
                        input: {
                            id: id,
                            _version: _version
                        }
                    },
                    authMode: GRAPHQL_AUTH_MODE.API_KEY
                };

                var result = await API.graphql(options);

                if (!result.data.deleteManagerPartners.id) {
                    return { error: true, message: error_message };
                }

                return { error: false, message: 'Successfully removed partner' };
            }
            catch (error) {
                return { error: true, message: error };
            }
        },
        initializeSchedule() {
            const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
            const schedule = {};

            daysOfWeek.forEach(day => {
                schedule[day] = new Array(24).fill(0);
            });

            return schedule;
        },
        processTicketData(ticketData) {
            let multiDimensionalArray = Array.from({ length: 24 }, () => Array(7).fill(0));

            ticketData.forEach(ticket => {
                let createdAt = new Date(ticket.createdAt);
                let dayOfWeek = createdAt.getDay();
                let hourOfDay = createdAt.getHours();

                multiDimensionalArray[hourOfDay][dayOfWeek]++;
            });

            return multiDimensionalArray;
        },
        async calculateTicketVolume(startDate, endDate) {

            const formattedStartDate = new Date(startDate).toISOString();
            const formattedEndDate = new Date(endDate).toISOString();

            try {
                let hasPartnerData = true;
                let partnerNextToken = null;
                let partnerData = [];
                let ticketData = [];

                while (hasPartnerData) {
                    let partnerOptions = {
                        query: queries.listManagerPartners,
                        variables: {
                            filter: {
                                _deleted: { ne: true },
                            },
                            limit: 2000
                        },
                        authMode: GRAPHQL_AUTH_MODE.API_KEY
                    };

                    if (partnerNextToken) {
                        partnerOptions.variables.nextToken = partnerNextToken;
                    }

                    const partnerResult = await API.graphql(partnerOptions);

                    for (const result of partnerResult.data.listManagerPartners.items) {
                        partnerData.push(result.ticket_queue_name);
                    }

                    if (partnerResult.data.listManagerPartners.nextToken) {
                        partnerNextToken = partnerResult.data.listManagerPartners.nextToken;
                    } else {
                        hasPartnerData = false;
                    }
                }

                for (const queueName of partnerData) {
                    let hasTicketData = true;
                    let ticketNextToken = null;

                    const searchTickets = /* GraphQL */ `query searchTickets(
                        $filter: SearchableTicketsFilterInput
                        $limit: Int
                        $nextToken: String
                      ) {
                        searchTickets(filter: $filter, limit: $limit, nextToken: $nextToken) {
                          items {
                            id
                            queue_id
                            subject
                            description
                            status
                            time_started
                            time_last_contact
                            priority
                            owner
                            owner_group
                            creator
                            requestor
                            requestor_group
                            cc
                            createdAt
                            updatedAt
                            contact_information
                            special_instructions
                            _version
                            _deleted
                            _lastChangedAt
                            __typename
                          }
                          nextToken
                        }
                      }
                    `;

                    // probably make search query 
                    while (hasTicketData) {
                        let ticketOptions = {
                            query: searchTickets,
                            variables: {
                                filter: {
                                    _deleted: { ne: true },
                                    requestor: { eq: queueName },
                                    createdAt: { ge: formattedStartDate, le: formattedEndDate }
                                },
                                limit: 1000
                            },
                            authMode: GRAPHQL_AUTH_MODE.API_KEY
                        };

                        if (ticketNextToken) {
                            ticketOptions.variables.nextToken = ticketNextToken;
                        }

                        const ticketResult = await API.graphql(ticketOptions);

                        ticketData.push(...ticketResult.data.searchTickets.items);

                        if (ticketResult.data.searchTickets.nextToken) {
                            ticketNextToken = ticketResult.data.searchTickets.nextToken;
                        } else {
                            hasTicketData = false;
                        }
                    }
                }

                let processedData = this.processTicketData(ticketData);
                return processedData;
            } catch (error) {
                console.error("Error fetching ticket volume:", error);
                return { error: true, message: error.message };
            }
        },
        async calculateStaffing(position) {
            try {
                var hasData = true;
                var nextToken = null;
                var employeeData = [];

                var queryFilter = {
                    _deleted: { ne: true },
                    assigned_group: { eq: "Contact Center" },
                    terminated: { eq: "No" }
                };

                
                if(position.length == 1) {
                    queryFilter.position = {eq: position[0].label};
                } else if(position.length > 1) {
                    var innerFilter = [];
                    for(var entry of position) {
                        innerFilter.push({"position": {eq: entry.label}});
                    }
                    queryFilter.or = innerFilter;
                }

                while (hasData) {
                    var options = {
                        query: queries.listEmployees,
                        variables: {
                            filter: queryFilter,
                            limit: 2000
                        },
                        authMode: GRAPHQL_AUTH_MODE.API_KEY
                    }

                    if (nextToken != null) {
                        options.variables.nextToken = nextToken;
                    }

                    var employees = await API.graphql(options);

                    for (const item of employees.data.listEmployees.items) {
                        employeeData.push(item);
                    }

                    if (employees.data.listEmployees.nextToken != null) {
                        nextToken = employees.data.listEmployees.nextToken;
                    }
                    else {
                        hasData = false;
                    }
                }

                var weekObject = {
                    Sunday: 0,
                    Monday: 0,
                    Tuesday: 0,
                    Wednesday: 0,
                    Thursday: 0,
                    Friday: 0,
                    Saturday: 0,
                };

                var multiDimensionalArray = [
                    JSON.parse(JSON.stringify(weekObject)), //12 AM - 1 AM
                    JSON.parse(JSON.stringify(weekObject)), //1 AM - 2 AM
                    JSON.parse(JSON.stringify(weekObject)), //2 AM - 3 AM
                    JSON.parse(JSON.stringify(weekObject)), //3 AM - 4 AM
                    JSON.parse(JSON.stringify(weekObject)), //4 AM - 5 AM
                    JSON.parse(JSON.stringify(weekObject)), //5 AM - 6 AM
                    JSON.parse(JSON.stringify(weekObject)), //6 AM - 7 AM
                    JSON.parse(JSON.stringify(weekObject)), //7 AM - 8 AM
                    JSON.parse(JSON.stringify(weekObject)), //8 AM - 9 AM
                    JSON.parse(JSON.stringify(weekObject)), //9 AM - 10 AM
                    JSON.parse(JSON.stringify(weekObject)), //10 AM - 11 AM
                    JSON.parse(JSON.stringify(weekObject)), //11 AM - 12 PM
                    JSON.parse(JSON.stringify(weekObject)), //12 PM - 1 PM
                    JSON.parse(JSON.stringify(weekObject)), //1 PM - 2 PM
                    JSON.parse(JSON.stringify(weekObject)), //2 PM - 3 PM
                    JSON.parse(JSON.stringify(weekObject)), //3 PM - 4 PM
                    JSON.parse(JSON.stringify(weekObject)), //4 PM - 5 PM
                    JSON.parse(JSON.stringify(weekObject)), //5 PM - 6 PM
                    JSON.parse(JSON.stringify(weekObject)), //6 PM - 7 PM
                    JSON.parse(JSON.stringify(weekObject)), //7 PM - 8 PM
                    JSON.parse(JSON.stringify(weekObject)), //8 PM - 9 PM
                    JSON.parse(JSON.stringify(weekObject)), //9 PM - 10 PM
                    JSON.parse(JSON.stringify(weekObject)), //10 PM - 11 PM
                    JSON.parse(JSON.stringify(weekObject)), //11 PM - 12 AM
                ];


                for (var employee of employeeData) {
                    var start_time = Number(employee.start_time);
                    var duration = Number(employee.duration);

                    for (var i = 0; i < duration; i++) {
                        var index = (start_time + i) % 24;
                        var work_days = employee.work_days.split(',');

                        for (var day of work_days) {
                            // console.log(day);
                            multiDimensionalArray[index][day.trim()] += 1;
                        }
                    }
                }

                var returnArray = [];


                for (var object of multiDimensionalArray) {
                    var array = [
                        object.Sunday,
                        object.Monday,
                        object.Tuesday,
                        object.Wednesday,
                        object.Thursday,
                        object.Friday,
                        object.Saturday
                    ]

                    returnArray.push(array);
                }

                return returnArray;
            }
            catch (error) {
                return { error: true, message: error };
            }

        },
        async calculateCoverage(startDate, endDate, weeks, position) {
            try {
                const staffingData = await this.calculateStaffing(position);
                const ticketVolumeData = await this.calculateTicketVolume(startDate, endDate);

                let coverage = {};

                for (let day in ticketVolumeData) {
                    coverage[day] = [];
                    for (let hour = 0; hour < 24; hour++) {
                        if (staffingData[day][hour] === 0) {
                            let volumePerWeek = ticketVolumeData[day][hour] / weeks;
                            coverage[day][hour] = parseFloat((volumePerWeek).toFixed(2));
                        } else {
                            let volumePerWeek = ticketVolumeData[day][hour] / weeks;
                            coverage[day][hour] = parseFloat((volumePerWeek / staffingData[day][hour]).toFixed(2));
                        }
                    }
                }

                return coverage;
            } catch (error) {
                console.error("Error calculating coverage:", error);
                return { error: true, message: error.message };
            }
        },
        async getCoverageData(filterParams) {
            //console.log("Incoming filter parameters:", filterParams);

            const { end_date: endDate, weeks, start_date: startDate, position, chart_type: chartType } = filterParams;

            switch (chartType) {
                case 'Coverage':
                    return await this.calculateCoverage(startDate, endDate, weeks, position);
                case 'Staffing':
                    return await this.calculateStaffing(position);
                case 'Volume':
                    return await this.calculateTicketVolume(startDate, endDate);
                default:
                    return { error: true, message: 'Unknown chart type' };
            }
        }
    },
})