<template>
  <div v-if="allowDateRange">
    <div class="datePickerCheckboxContainer">
      <CheckBox
        :checked="dateRangeChecked"
        :item="input"
        @toggle-selected="toggleSelected()"
      />
      <label class="dateCheckboxLabel">Select Date Range</label>
    </div>

    <VDatePicker v-if="dateRangeChecked" v-model="date" is-range>
      <template #default="{ inputValue, inputEvents }">
        <div class="dateRange" v-bind="$emit('range-value', inputValue)">
          <div class="inputLabelContainer">
            <label>From</label>
            <input
              class="dateInput"
              :value="inputValue.start"
              v-on="inputEvents.start"
            />
          </div>
          <div class="inputLabelContainer">
            <label>To</label>
            <input
              class="dateInput"
              :value="inputValue.end"
              v-on="inputEvents.end"
            />
          </div>
        </div>
      </template>
    </VDatePicker>
    <VDatePicker mode="time" v-model="date" :timezone="timezone">
      <template #default="{ inputValue, inputEvents }">
        <div class="singleDate">
          <div>
            <input class="dateInput" :value="inputValue" v-on="inputEvents" />
          </div>
        </div>
      </template>
    </VDatePicker>
  </div>
  <div v-else>
    <VDatePicker
      mode="time"
      v-model="date"
      class="singleDatePicker"
      :timezone="timezone"
    >
      <template #default="{ inputValue, inputEvents }">
        <div v-bind="$emit('date-value', input, inputValue)">
          <div>
            <input
              class="dateInputSingle"
              :value="inputValue"
              v-on="inputEvents"
            />
          </div>
        </div>
      </template>
    </VDatePicker>
  </div>
</template>

<script>
import CheckBox from "./CheckBox.vue";

export default {
  name: "TimePicker",
  props: {
    input: Object,
    allowDateRange: Boolean,
    value: String,
    existingDate: String,
  },
  data() {
    return {
      dateRangeChecked: false,
      showDatePicker: false,
      internalRange: null,
      date: null,
      range: null,
      timezone: "",
    };
  },
  created() {
    if (this.allowDateRange) {
      this.range = {
        start: new Date(),
        end: new Date(),
      };
      this.date = new Date();
    } else {
      if (this.value && this.existingDate) {
        this.date = this.createDate(this.value, this.existingDate);
      } else {
        this.date = new Date();
      }
    }
  },
  watch: {
    existingDate(newDate) {
     
      if (newDate == undefined) {
        let today = new Date();
        let hour = today.getHours();
        let minute = today.getMinutes();
        let second = today.getSeconds();
        let currentTime = hour + ":" + minute + ":" + second
        let todayFormatted = today.toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: '2-digit' });
        this.date = this.createDate(currentTime, todayFormatted);
        return
      }
      if (this.value) {
        this.date = this.createDate(this.value, newDate);
      }
    },
  },
  methods: {
    toggleSelected() {
      this.dateRangeChecked = !this.dateRangeChecked;
    },
    createDate(time, date) {
      const [timePart, meridiem] = time.split(" ");
      const [hours, minutes] = timePart.split(":");
      let [day, month, year] = date.split("/");
      if (year.length === 2) {
        year = "20" + year;
      }
      let parsedHours = parseInt(hours, 10);
      if (meridiem?.toLowerCase() === "pm" && parsedHours < 12) {
        parsedHours += 12;
      } else if (meridiem?.toLowerCase() === "am" && parsedHours === 12) {
        parsedHours = 0;
      }

      const dateObj = new Date(
        `${year}-${day}-${month}T${parsedHours}:${minutes}`
      );
      return dateObj;
    },
  },
  computed: {
    getStyle() {
      if (this.width === "parent") {
        return "fillParentWidth";
      } else {
        return "fitToText";
      }
    },
  },
  components: { CheckBox },
  emits: [
    "range-value",
    "date-value"
  ]
};
</script>

<style scoped>
.dateInput {
  border: 0.25px solid #2e3e91;
  border-radius: 1px;
  padding-left: 10px;
  color: #271d7a;
  padding: 2px 2px 2px 8px;
  font-size: 9pt;
  background-repeat: no-repeat;
  background-position: right 5px center;
  background-size: 16px 16px;
  background-image: url("@/assets/icons/Time.png");
}
.dateInputSingle {
  border: 0.25px solid #2e3e91;
  border-radius: 2px;
  padding-left: 10px;
  color: #271d7a;
  padding: 2px 2px 2px 8px;
  font-size: 9pt;
  height: 28px;
  width: 100%;
  background-repeat: no-repeat;
  background-position: right 5px center;
  background-size: 16px 16px;
  background-image: url("@/assets/icons/Time.png");
}
input:focus {
  outline: none;
}
.inputLabelContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.singleDate {
  width: 50%;
  padding: 4px 0px 4px 0px;
}
.dateRange {
  display: flex;
  flex-wrap: wrap;
}
@media (min-width: 1029px) {
  .dateRange > * {
    flex: 1;
    margin-right: 10px;
  }
}
.dateRange > :last-child {
  margin-right: 0;
}
.inputLabelContainer label {
  font-size: 8pt;
  color: #271d7a;
  font-weight: 600;
  padding-bottom: 2px;
}
.dateCheckboxLabel {
  font-size: 8pt;
  color: #271d7a;
  font-weight: 600;
  vertical-align: middle;
}
.datePickerCheckboxContainer {
  display: flex;
  align-items: center;
  padding: 0px 0px 4px 1px;
}
</style>