<template>
  <button
    class="removeButton"
    :class="{ selected: isSelected }"
    @click="toggleSelected"
    tabindex="-1"
  >
    <span class="removeButton-icon"></span>
    <span class="removeButton-check"></span>
  </button>
</template>

<script>
export default {
  name: "RemoveButtonForDraggableListComp",
  data() {
    return {
      isSelected: false,
    };
  },
  methods: {
    toggleSelected() {
      this.isSelected = !this.isSelected;
    },
  },
};
</script>

<style scoped>
.removeButton {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
}

.removeButton-icon {
  display: inline-block;
  width: 16px;
  height: 16px;
  outline: 0.25pt solid #2e3e91;
  margin-right: 5px;
  position: relative;
  border-radius: 1px;
}

.removeButton-icon::before {
  content: "✕";
  color: #2e3e91;
  font-size: 12px;
  line-height: 17px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  border-radius: 1px;
}

.removeButton:hover .removeButton-icon::before {
  outline: 0.5pt solid #2e3e91;
  text-align: center;
  border-radius: 1px;
}

.removeButton:active .removeButton-icon::before {
  color: #ffffff;
  background-color: #2b2d84;
  outline: 1pt solid #2e3e91;
  text-align: center;
}
</style>
