<template>
  <div class="agents-card">
    <h1 class="header">Agents</h1>
    <div class="agents-card-holder">
      <div class="progress-item progress-green">
        <div class="progress-label">Ready</div>
        <div class="progress" :style="{'--percent': `${agentsReadyPercent}%`}" :data-value="queue.agentsReady"/>
      </div>
      <div class="progress-item progress-orange">
        <div class="progress-label">On Phone</div>
        <div class="progress" :style="{'--percent': `${onPhonePercent}%`}" :data-value="queue.agentsOnPhoneAndAway.first"/>
      </div>
      <div class="progress-item progress-yellow">
        <div class="progress-label">Away</div>
        <div class="progress" :style="{'--percent': `${awayPercent}%`}" :data-value="queue.agentsOnPhoneAndAway.second"/>
      </div>
      <div class="scale">
        <div class="start">0</div>
        <div class="middle">{{ total / 2 }}</div>
        <div class="end">{{ total }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AgentsCardTest',
  props: {
    queue: Object,
  },
  computed: {
    total () {
      return Number(this.queue.agentsReady) +
        Number(this.queue.agentsOnPhoneAndAway.first) +
        Number(this.queue.agentsOnPhoneAndAway.second)
    },
    agentsReadyPercent () {
      return +this.queue.agentsReady * 100/ this.total;
    },
    onPhonePercent () {
      return +this.queue.agentsOnPhoneAndAway.first * 100/ this.total;
    },
    awayPercent () {
      return +this.queue.agentsOnPhoneAndAway.second * 100/ this.total;
    }
  },
}
</script>

<style scoped>
.agents-card-holder {
  padding: 0px 30px;
}
.header {
  padding: 10px 15px 25px 15px;
  font-size: 11pt;
  color: #271d7a;
}
.progress {
  position: relative;
  height: 8px;
  background-color: #dde9f4;
}

.progress::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  width: var(--percent);
  animation: progress .4s ease;
  border: 1px solid transparent;
  transition: .3s ease border-color;
}

.progress::after {
  content: attr(data-value);
  position: absolute;
  top: -45px;
  font-weight: 500;
  font-size: 26pt;
  width: var(--percent);
  text-align: center;
  color: #271d7a;
  animation: progress .4s ease;
  opacity: 0;
  user-select: none;
  pointer-events: none;
  transition: .3s ease opacity;
}

@keyframes progress {
  from {
    width: 0;
  }

  to {
    width: var(--percent);
  }
}

.progress-green .progress::before {
  background-color: #add38d;
}
.progress-orange .progress::before {
  background-color: #e87928;
}
.progress-yellow .progress::before {
  background-color: #eade53;
}
.progress-item {
  display: grid;
  grid-template-columns: 110px 1fr;
  align-items: center;
}

.progress-item:hover .progress::before {
  border-color: #271d7a; 
}

.progress-item:hover .progress::after {
  opacity: 1;
}

.progress-item {
  margin-bottom: 30px;
}

.progress-yellow {
  margin-bottom: 10px;
}
.progress-label {
  display: flex;
  gap: 10px;
  align-items: center;
  font-size: 15px;
  font-weight: 500;
  color: #271d7a;
}
.progress-green .progress-label::before {
  background-color: #add38d;
}
.progress-orange .progress-label::before {
  background-color: #e87928;
}
.progress-yellow .progress-label::before {
  background-color: #eade53;
}

.progress-label::before {
  content: '';
  display: inline-flex;
  align-items: center;
  width: 10px;
  height: 10px;
}

.scale {
  display: flex;
  justify-content: space-between;
  margin-left: 110px;
  font-size: 15px;
  font-weight: 500;
  color: #271d7a;
  border-top: 1px solid #e5e5e5;
}
</style>