<template>
  <div class="notificationCard" @click="handleCardClick">
    <div class="notificationHeader">
      <div class="dateInfo">
        <p class="date">{{ computedDate }}</p>
        <p class="time">{{ computedTime }}</p>
      </div>
      <button class="close" @click.stop="handleClickCloseButton">
        &times;
      </button>
    </div>

    <div class="systemTypeContent">
      <p class="maintenanceUpdateText" v-if="notification.type == 'Maintenance Update'">{{ computedSystemType }}</p>
      <p class="incidentReportText" v-else-if="notification.type == 'Incident Report'">{{ computedSystemType }}</p>
      <p class="regularText" v-else>{{ computedSystemType }}</p>
    </div>

    <div v-if="notification.type == 'Post'" style="padding-left: 10px;">
      <p class="titleText">Status: {{ notification.status }}</p>
    </div>

    <div class="titleContent">
      <p class="titleText">{{ elipsisTitle }}</p>
    </div>


    <div class="notificationContent">
      <p class="notificationParagraph">
        {{ elipsisContent }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "NotificationCard",
  props: { notification: Object },
  emits: ["delete-notification", "expand-notification"],
  computed: {
    computedDate() {
      const isoString = this.notification.createdAt;
      const date = new Date(isoString);
      const month = date.toLocaleString("default", { month: "long" });
      const day = date.getDate();

      return `${month} ${day}`;
    },
    computedTime() {
      const isoString = this.notification.createdAt;
      const date = new Date(isoString);
      let hours = date.getHours();
      const ampm = hours >= 12 ? 'PM' : "AM";

      if (hours > 12) {
        hours -= 12;
      }
      else if (hours === 0) {
        hours = 12;
      }

      const minutes = date.getMinutes();
      const time = `${hours}:${minutes.toString().padStart(2, '0')} ${ampm}`;

      return time;
    },
    computedSystemType() {
      return `${this.notification.tracked_system} - ${this.notification.type}`;
    },
    elipsisContent() {
      if (this.notification.content.length > 20) {
        return this.notification.content.substring(0, 20) + "...";
      }
      else {
        return this.notification.content;
      }
    },
    elipsisTitle() {
      if (this.notification.title.length > 25) {
        return this.notification.title.substring(0, 25) + "...";
      }
      else {
        return this.notification.title;
      }
    }
  },
  methods: {
    handleClickCloseButton() {
      this.$emit("delete-notification", this.notification);
    },
    handleCardClick() {
      this.$emit("expand-notification", this.notification);
    },
  },
};
</script>

<style scoped>
.notificationCard {
  border: 1px solid #271d7a;
  width: 200px;
  height: 100%;
  background-color: #ffffff;
  cursor: pointer;
}

.notificationHeader {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 0 10px;
}

.notificationHeader .close {
  position: absolute;
  right: 13px;
  top: 10px;
  font-size: 24px;
  line-height: 0;
  width: 10px;
  height: 10px;
}

.dateInfo {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding-top: 5px;
}
.date {
  padding-right: 20px;
  color: #271d7a;
  font-weight: 600;
  font-size: 11pt;
  display: flex;
  align-content: center;
}
.time {
  color: #271d7a;
  font-weight: 400;
  font-size: 10pt;
  display: flex;
  align-content: center;
  padding-top: 2px;
}
.notificationContent {
  height: 100px;
  overflow: hidden;
  padding: 10px 10px 10px 10px;
}
.notificationParagraph {
  height: 100%;
  overflow: hidden;
  color: #271d7a;
  font-weight: 400;
  font-size: 10pt;
}

.systemTypeContent {
  padding-left: 10px;
}

.maintenanceUpdateText {
  color: orange;
  font-weight: 600;
  font-size: 9pt;
}

.incidentReportText {
  color: red;
  font-weight: 600;
  font-size: 9pt;
}

.regularText {
  color: #5c95d6;
  font-weight: 600;
  font-size: 9pt;
}

.titleText {
  color: #271d7a;
  font-weight: 600;
  font-size: 10pt;
}

.titleContent {
  padding-top: 10px;
  padding-left: 10px;
}

</style>
