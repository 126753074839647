<template>
  <div
    class="expandableCard"
    :tabindex="0"
    :class="{ expandableCardOpen: open }"
  >
    <div class="selected" :class="{ open: open }" @click="toggleCard">
      <img
        v-if="open"
        src="@/assets/icons/caret_open_dkblue.png"
        class="expandableCardCaret"
      />
      <img
        v-else
        src="@/assets/icons/caret_closed_dkblue.png"
        class="expandableCardCaret"
      />
      <span
        class="expandableCardTitle"
        :class="{ expandableCardTitleOpen: open }"
      >
        <b>{{ flow.flowName }}</b> - {{ flow.partners_label }}
      </span>
      <button @click.stop="handleAddClick(flow)">
        <img src="@/assets/icons/Add.png" class="expandableQuestionAdd" />
      </button>
    </div>
    <div class="wrapper">
      <div class="items" :class="{ selectHide: !open }">
        <span>Flow Type: {{ flow.partners_label }}</span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "ExpandableQuestion",
  props: {
    flow: Object,
    open: Boolean,
    dependents: Boolean,
  },
  data() {
    return {
      addQuestion: false,
      selectedOption: {},
      selectQuestion: {
        title: "Select a Question",
      },
    };
  },
  methods: {
    toggleCard() {
      this.$emit("toggle");
    },
    handleAddClick(flow) {
      this.$emit("addFlow", flow);
    },
    handleAddDependent(option, question) {
      this.addQuestion = true;
      this.selectedOption = option;
      this.$emit("addDependentToFlow", option, question);
    },
  },
  emits: [
    "toggle",
    "addFlow",
    "addDependentToFlow"
  ],
};
</script>
<style scoped>
.expandableCard .open {
  background-color: #e2f1f4 !important;
  border-radius: 1px;
  border: 0.25px solid #2e3e91;
}
.wrapper {
  background: #fff;
  overflow: hidden;

  transition: all 0.5s ease-in-out;
}
.expandableCard {
  position: relative;
  text-align: left;
  z-index: 1;
  font-size: 9pt;
  filter: drop-shadow(1px 1px 1px rgba(39, 29, 122, 0.2));
  display: flex;
  flex-direction: column;
}

.expandableCardTitle {
  font-size: 10pt;
  padding-left: 30px;
  padding-right: 8px;
}
.expandableCardTitleOpen {
  font-weight: 600;
}
.buttonList {
  display: flex;
  flex-direction: row;
  height: 200px;
}
.buttonList .toolButton {
  margin-right: 5px;
  margin-left: 10px;
  height: 30px;
}

.expandableCard .selected {
  color: #271d7a;
  cursor: pointer;
  user-select: none;
  padding: 2px 2px 2px 8px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  height: 37px;
  background-color: #e2f1f4;
}
.expandableCardDetail {
  padding-bottom: 10px;
  color: #271d7a;
  padding-left: 10px;
  user-select: none;
}
.expandableCard .selected:after {
  position: absolute;
  top: 22px;
  right: 10px;
}
.expandableQuestionAdd {
  width: 14px;
  margin-top: 6px;
  padding: 3px;
  /* outline: 1px solid #271d7a; */
  background-color: #fff;
  position: absolute;
  top: 5px;
  right: 18px;
  border-radius: 2px;
}

.expandableCard .items {
  overflow: hidden;
  background-color: #ffffff;
  left: 0;
  right: 0;
  padding: 20px 3px 20px 20px;
  display: flex;
  flex-direction: column;
  color: #271d7a;
  font-size: 10pt;
}

.expandableCardOpen {
  background-color: #eff4f9;
  z-index: 5;
  height: auto;
}

.selectHide {
  display: none !important;
}
.expandableCardCaret {
  position: absolute;
  left: 0;
  top: 0;
  width: 26px;
  padding-top: 14px;
  padding-left: 12px;
}
.optionsList {
  display: flex;
  flex-direction: column;
  padding-left: 10px;
}
.addIcon {
  width: 14px;
  padding-left: 4px;
}
</style>
