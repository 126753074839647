<template>
    <SwitchOpsNavBar
      :title="`SwitchOps - Account Limits`"
      @button-click="handleButtonClick"
      :selectedPage="currentSelectedPage"
    />
    <div class="mainContainer">
      <div class="mainContent">
        <LeftNavigation
          :items="items"
          @selected-page="handleSelectedPage"
          :start-category="currentSelectedPage"
        />
        <div class="pageContent">
          <SetAccountLimits v-if="currentSelectedPage == 'Set Account Limits'"/>
          <ViewAccountLimits v-else/>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import SwitchOpsNavBar from "@/components/AccountLimits/SwitchOpsNavBar.vue";
  import LeftNavigation from "@/components/base_components/Navigation/LeftNavigation.vue";
  import SetAccountLimits from "@/views/SwitchOps/AccountLimits/SetAccountLimits.vue";
  import ViewAccountLimits from "@/views/SwitchOps/AccountLimits/ViewAccountLimits.vue";
  import { Storage } from 'aws-amplify';
  import { mapStores, mapActions} from 'pinia';
  import { useHelpStore } from '@/stores/Help/helpStore.js';

  export default {
    name: "SwitchOpsAccountLimits",
    data() {
      return {
        items: [
          { category: "Set Account Limits" },
          { category: "View Account Limits" },
        ],
        currentSelectedPage: 'Set Account Limits'
      }
    },
    components: {
      SwitchOpsNavBar,
      LeftNavigation,
      SetAccountLimits,
      ViewAccountLimits
    },
    mounted() {
      window.addEventListener("resize", this.handleResize);
      this.HelpManagementStore.setPage('SwitchOps / Account Limits / Set Account Limits');
    },
  
    beforeUnmount() {
      window.removeEventListener("resize", this.handleResize);
    },
    computed: {
      ...mapStores(useHelpStore)
    },
    methods: {
      ...mapActions(useHelpStore, ['setPage']),

      handleSelectedPage(category) {
        this.currentSelectedPage = category;
        this.HelpManagementStore.setPage(`SwitchOps / Account Limits / ${category}`);
      },
      async handleButtonClick() {
        const pdfUrl = await Storage.get("helpsettingtrunklimits.pdf", { level: "public" });
        const newWindow = window.open(pdfUrl, '_blank', 'noopener,noreferrer'); 
        if(newWindow) {
          newWindow.opener = null;
        }
      }
    }
  }
  </script>
  
  <style scoped>
    html {
    overflow-y: auto !important;
    }
    .mainContainer {
      display: flex;
      background-color: #e2f1f4;
      min-height: calc(100vh - 93px);
    }
    .pageContent {
      flex-grow: 1;
    }
    .mainContent {
      display: flex;
      flex-grow: 1;
      position: relative;
    }
    .configAlerts {
      position: fixed;
      right: 0;
      padding-bottom: 10px;
      padding-right: 20px;
      z-index: 9;
    }
    .leftNavContainer {
      box-shadow: none;
    }
  </style>