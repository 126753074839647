<template>
  <div class="newNotFormCont">
    <div class="contentContainer">
      <DropDown
        :options="[
          { label: 'New Post', value: 'Post' },
          { label: 'New Incident Report', value: 'Incident Report' },
          { label: 'New Maintenance Update', value: 'Maintenance Update' },
        ]"
        ref="filterDropdown"
        :default="{
          label: 'Select Notification Type',
          value: 'notificationType',
        }"
        :value="submissionData['notificationType']?.label"
        @input="updateDropDownVal('notificationType', $event)"
        class="dropdown"
      />
      <DividerComp class="dropdownDivider" />
      <DropDown
        :options="trackedSystems"
        ref="filterDropdown"
        :default="{
          label: 'Select Tracked System',
          value: 'selectTrackedSystem',
        }"
        :value="submissionData['trackedSystem']?.label"
        @input="updateDropDownVal('trackedSystem', $event)"
        class="dropdown"
      />
      <DividerComp
        class="dropdownDivider"
        v-if="submissionData['notificationType']?.value === 'Post'"
      />
      <DropDown
        v-if="submissionData['notificationType']?.value === 'Post'"
        :options="[
          { label: 'Operational', value: 'Operational' },
          { label: 'Under Maintenance', value: 'Under Maintenance' },
          { label: 'Investigation Reported', value: 'Investigation Reported' },
          { label: 'Issue', value: 'Issue' },
          { label: 'Partial Outage', value: 'Partial Outage' },
          { label: 'Down', value: 'Down' },
        ]"
        ref="filterDropdown"
        :default="{
          label: 'Select Status',
          value: 'status',
        }"
        :value="submissionData['status']?.label"
        @input="updateDropDownVal('status', $event)"
        class="dropdown"
      />

      <BulkUpdatePanel class="bulkUpdate" @input="handleMessageInput" />
      <div class="postButtonContainer">
        <DarkButton title="Post" @click="handlePost" class="postButton" />
      </div>
    </div>
  </div>
</template>

<script>
import DarkButton from "../base_components/Buttons/DarkButton.vue";
import DividerComp from "../base_components/DividerComp.vue";
import DropDown from "../base_components/FormComponents/DropDown.vue";
import BulkUpdatePanel from "./BulkUpdatePanel.vue";
import { mapStores } from 'pinia';
import { useStatusNotificationStore } from "@/stores/site_notifications/statusNotificationStore.js";

export default {
  name: "NewNotificationForm",
  components: { DropDown, DividerComp, BulkUpdatePanel, DarkButton },
  emits: [
    "delete-notification",
    "close",
    "postSuccess",
    "postFailure"
  ],
  computed: {
    ...mapStores(useStatusNotificationStore),
    isFormValid() {
      console.log(this.submissionData, this.submissionData["status"]);
      if (
        this.submissionData["notificationType"] &&
        this.submissionData["trackedSystem"] &&
        this.submissionData["message"]
      ) {
        if (this.submissionData["notificationType"].value === "Post") {
          if (this.submissionData["status"]) {
            return true;
          } else {
            return false;
          }
        } else {
          return true;
        }
      } else {
        return false;
      }
    },
    trackedSystems() {
      return this.StatusNotificationStore.getTrackedSystems;
    }
  },
  methods: {
    handleClickCloseButton() {
      this.$emit("close");
    },
    handleDeleteNotification() {
      console.log("Delete", this.notification);
      this.$emit("delete-notification", this.notification);
    },
    updateDropDownVal(name, input) {
      this.submissionData[name] = input;
    },
    handleMessageInput(event) {
      const pElement = event.target.querySelector("p");
      const pContent = pElement.textContent;

      this.submissionData["message"] = pContent;
    },
    async handlePost() {
      if (this.isFormValid) {
        console.log("Success", this.submissionData);
        await this.StatusNotificationStore.createNotification(this.submissionData);
        this.$emit("postSuccess", this.submissionData);
        this.submissionData = {};
      } else {
        console.log("Error");
        this.$emit("postFailure", this.submissionData);
        // this.alerts.push({
        //   id: this.alerts.length,
        //   name: "Error",
        //   description: "Missing Required Values",
        // });
      }
    },
  },
  data() {
    return {
      submissionData: {},

      formData: [],
    };
  },
  watch: {
    submissionData: {
      deep: true,
      handler(newSub) {
        if (newSub["notificationType"]?.value !== "Post") {
          if (newSub["status"]) {
            delete this.submissionData["status"];
          }
        }
      },
    },
  },
};
</script>

<style scoped>
.newNotFormCont {
  border-left: 1px solid #271d7a;
  border-right: 1px solid #271d7a;
  border-bottom: 1px solid #271d7a;
  width: 400px;
  height: 100%;
}

.contentContainer {
  position: relative;
  display: flex;
  height: 100%;
  flex-direction: column;
  padding: 0;
  background-color: #ffffff;
  padding: 20px 0px 10px 0px;
}

.contentContainer .close {
  position: absolute;
  right: 10px;
  top: 5px;
  font-size: 24px;
  line-height: 0;
  width: 10px;
  height: 10px;
}
.dropdown {
  width: 60%;
  margin: 0 auto;
}
.dropdownDivider {
  margin: 20px;
}
.bulkUpdate {
  margin: 120px 10px 10px 10px;
}
.postButton {
  width: 90px;
}
.postButtonContainer {
  justify-content: center;
  display: flex;
  position: absolute;
  bottom: 60px;
  left: 50%;
  right: 50%;
}
</style>
