/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cloud_logic_custom": [
        {
            "name": "Quickbase",
            "endpoint": "https://cjj3uxicq3.execute-api.us-east-1.amazonaws.com/production",
            "region": "us-east-1"
        },
        {
            "name": "apiMappings",
            "endpoint": "https://kkf8eudgl9.execute-api.us-east-1.amazonaws.com/production",
            "region": "us-east-1"
        },
        {
            "name": "bandwidth",
            "endpoint": "https://iyar2v3th1.execute-api.us-east-1.amazonaws.com/production",
            "region": "us-east-1"
        },
        {
            "name": "census",
            "endpoint": "https://cz013ikjz7.execute-api.us-east-1.amazonaws.com/production",
            "region": "us-east-1"
        },
        {
            "name": "fieldNation",
            "endpoint": "https://n66oc5o5lc.execute-api.us-east-1.amazonaws.com/production",
            "region": "us-east-1"
        },
        {
            "name": "fractel",
            "endpoint": "https://c5abaqqtal.execute-api.us-east-1.amazonaws.com/production",
            "region": "us-east-1"
        },
        {
            "name": "frontendAPI",
            "endpoint": "https://mqv92l7il5.execute-api.us-east-1.amazonaws.com/production",
            "region": "us-east-1"
        },
        {
            "name": "generalLog",
            "endpoint": "https://6zxn1xx4f0.execute-api.us-east-1.amazonaws.com/production",
            "region": "us-east-1"
        },
        {
            "name": "google",
            "endpoint": "https://itrypg36g7.execute-api.us-east-1.amazonaws.com/production",
            "region": "us-east-1"
        },
        {
            "name": "inteliquent",
            "endpoint": "https://hd2mfoqs0l.execute-api.us-east-1.amazonaws.com/production",
            "region": "us-east-1"
        },
        {
            "name": "mail",
            "endpoint": "https://4hrb05bp16.execute-api.us-east-1.amazonaws.com/production",
            "region": "us-east-1"
        },
        {
            "name": "quickbaseWebhook",
            "endpoint": "https://4w7qs6t7li.execute-api.us-east-1.amazonaws.com/production",
            "region": "us-east-1"
        },
        {
            "name": "quickbooks",
            "endpoint": "https://6n1cp9hsua.execute-api.us-east-1.amazonaws.com/production",
            "region": "us-east-1"
        },
        {
            "name": "revio",
            "endpoint": "https://l4c60bfe3g.execute-api.us-east-1.amazonaws.com/production",
            "region": "us-east-1"
        },
        {
            "name": "revioWebhook",
            "endpoint": "https://vh7epmy2jk.execute-api.us-east-1.amazonaws.com/production",
            "region": "us-east-1"
        },
        {
            "name": "sandboxAPI",
            "endpoint": "https://ssi8sam2sa.execute-api.us-east-1.amazonaws.com/production",
            "region": "us-east-1"
        },
        {
            "name": "sureTax",
            "endpoint": "https://0nw5nss8nd.execute-api.us-east-1.amazonaws.com/production",
            "region": "us-east-1"
        },
        {
            "name": "switch",
            "endpoint": "https://20ndya39ef.execute-api.us-east-1.amazonaws.com/production",
            "region": "us-east-1"
        },
        {
            "name": "switchPivots",
            "endpoint": "https://vb9lnqcn82.execute-api.us-east-1.amazonaws.com/production",
            "region": "us-east-1"
        },
        {
            "name": "switchWebhook",
            "endpoint": "https://glrsxdozk3.execute-api.us-east-1.amazonaws.com/production",
            "region": "us-east-1"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://rv22bqbiy5hdtfffz6ufeapari.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AWS_IAM",
    "aws_appsync_apiKey": "da2-arfv2rq2u5cujld36qpoywobc4",
    "aws_cognito_identity_pool_id": "us-east-1:8588bc04-bf47-44aa-a474-a7c2936aaeb8",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_HtFT0kO5k",
    "aws_user_pools_web_client_id": "6k1rbvkbelrmeut1bluls6b0u3",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [],
    "aws_cognito_mfa_configuration": "ON",
    "aws_cognito_mfa_types": [
        "SMS",
        "TOTP"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_NUMBERS",
            "REQUIRES_SYMBOLS",
            "REQUIRES_UPPERCASE"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "lex-connect-site-uploads154944-production",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
