<template>
  <div
    class="expandableCard"
    :tabindex="0"
    :class="{ expandableCardOpen: open }"
  >
    <div
      class="selected"
      :class="{ open: open }"
      @click="toggleCard(dependent)"
    >
      <img
        v-if="open"
        src="@/assets/icons/caret_open_dkblue.png"
        class="expandableCardCaret"
      />
      <img
        v-else
        src="@/assets/icons/caret_closed_dkblue.png"
        class="expandableCardCaret"
      />
      <span
        class="expandableCardTitle"
        :class="{ expandableCardTitleOpen: open }"
        v-html="question.type == 'Flow' ? question.flow : question.description"
      >
      </span>
      <span v-if="question.dependents?.length" class="hasDep"
        >Has Dependents</span
      >
    </div>
    <div class="wrapper">
      <div class="items" :class="{ selectHide: !open }">
        <span>Question Type: {{ question.type }}</span>
        Options:
        <div
          v-for="(option, index) in question.options"
          :key="index"
          class="optionsList"
        >
          <div class="row" :class="{ dep: selectedOptions.includes(option) }">
            <span :style="{ paddingLeft: '15px' }"
              >{{ index + 1 }}) {{ option.label }}</span
            >

            <div v-for="(dependent, index) in question.dependents" :key="index">
              <ExpandableFlowCard
                v-if="dependent.dependentOn == option.label"
                :question="dependent"
                :open="dependent.open"
                @toggle="toggleN(index)"
                @toggleNested="(dependent) => toggleNested(dependent)"
                @removeQuestion="handleRemoveClick(question, dependent)"
              />
            </div>
            <ExpandableFlowCard
              v-if="selectedOption === option"
              :style="{ marginTop: '10px' }"
              :question="selectQuestion"
              :open="true"
              :dependents="dependents"
              @removeQuestion="handleRemoveSelected(option)"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "ExpandableFlowCard",
  props: {
    question: Object,
    open: Boolean,
    dependents: Array,
  },
  data() {
    return {
      addQuestion: false,
      selectedOption: {},
      selectedOptions: [],
      showBox: false,
      dependent: {},
      level: 0,
      selectQuestion: {
        description: "Select a Question",
      },
    };
  },
  methods: {
    toggleCard(dependent) {
      if (this.open) {
        this.selectedOption = {};
        this.$emit("changeFocus");
      }
      this.$emit("toggle", "flow", dependent);
    },
    toggleN(depIndex) {
      const dep = this.question.dependents[depIndex];
      this.$emit("toggleNested", dep);
    },
    toggleNested(dependent) {
      this.$emit("toggleNested", dependent);
    },

    handleRemoveClick(question, dependent) {
      if (dependent) {
        this.selectedOption = {};
      }
      this.$emit("removeQuestion", question, dependent);
    },
    handleRemoveSelected(option) {
      this.selectedOptions = this.selectedOptions.filter((opt) => {
        opt !== option;
      });
      this.selectedOption = {};
      this.$emit("changeFocus");
    },
    handleAdd(option) {
      this.selectedOption = option;
    },
    handleAddDependent(option, question) {
      this.selectedOptions.push(option);
      this.selectedOption = option;
      this.$emit("addDependentToFlow", option, question);
    },
  },
  watch: {
    // question: {
    //   deep: true,
    //   handler() {
    //     this.selectedOption = {};
    //   },
    // },
  },
  emits: [
    "toggle",
    "toggleNested",
    "addQuestion",
    "removeQuestion",
    "addDependentToFlow",
    "changeFocus",
  ],
};
</script>
<style scoped>
.expandableCard .open {
  background-color: #e2f1f4 !important;
  border-radius: 1px;
  border: 0.25px solid #2e3e91;
}
.wrapper {
  background: #fff;
  overflow: hidden;

  transition: all 0.5s ease-in-out;
}
.expandableCard {
  position: relative;
  text-align: left;
  z-index: 1;
  font-size: 9pt;
  display: flex;
  flex-direction: column;
}

.expandableCardTitle {
  font-size: 10pt;
  padding-left: 5px;
  padding-right: 8px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.expandableCardTitleOpen {
  font-weight: 600;
}
.buttonList {
  display: flex;
  flex-direction: row;
  height: 200px;
}
.buttonList .toolButton {
  margin-right: 5px;
  margin-left: 10px;
  height: 30px;
}

.expandableCard .selected {
  color: #271d7a;
  cursor: pointer;
  user-select: none;
  padding: 2px 30px 2px 8px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  height: 37px;
  background-color: #e2f1f4;
  overflow: hidden;
  white-space: normal;
}
.expandableCardDetail {
  padding-bottom: 10px;
  color: #271d7a;
  padding-left: 10px;
  user-select: none;
}
.expandableCard .selected:after {
  position: absolute;
  top: 22px;
  right: 10px;
}
.ExpandableFlowCardAdd {
  width: 14px;
  margin-top: 6px;
  padding: 3px;
  outline: 1px solid #271d7a;
  background-color: #fff;
  position: absolute;
  top: 5px;
  left: 12px;
  border-radius: 2px;
}

.expandableCard .items {
  overflow: hidden;
  background-color: #ffffff;
  left: 0;
  right: 0;
  padding: 20px 0px 20px 20px;
  display: flex;
  flex-direction: column;
  color: #271d7a;
  font-size: 10pt;
}

.expandableCardOpen {
  background-color: #eff4f9;
  z-index: 5;
  height: auto;
}

.selectHide {
  display: none !important;
}
.expandableCardCaret {
  position: absolute;
  right: 0;
  top: 0;
  width: 26px;
  padding-top: 14px;
  padding-right: 12px;
}
.hasDep {
  position: absolute;
  right: 0;
  top: 0;
  padding-top: 9px;
  padding-right: 50px;
  color: #79a8dd;
  font-size: 10pt;
}
.optionsList {
  display: flex;
  flex-direction: column;
}
.addIcon {
  width: 14px;
  padding-left: 4px;
}
.dep {
  border-left: 1px solid rgba(39, 29, 122, 0.2);
  border-top: 1px solid rgba(39, 29, 122, 0.2);
  border-bottom: 1px solid rgba(39, 29, 122, 0.2);
}
</style>
