<template>
    <span class="customDisplayHTML" :data-question_id="PROPS.question.id" v-html="CustomDisplayResult"></span>
</template>




<script setup>
    import { onMounted, defineProps, ref } from "vue";
    import AWS from "aws-sdk";


    //Props
    const PROPS = defineProps({
        script: {
            type: String,
            default: null
        },
        account_number: {
            type: String,
            default: null
        },
        ticket_number: {
            type: String,
            default: null
        },
        queue: {
            type: String,
            default: null
        },
        question: {
            type: Object,
            default: () => {}
        }
    });

    const CustomDisplayResult = ref(null);


    //Life Cycle Hooks
    onMounted(async () => {
        let script_name = "";

        if (PROPS.script == "MLEC District Type") {
            script_name = "getMLECDistrictType";
        }

        const lambda = new AWS.Lambda();

        const lambda_result = await lambda.invoke({
            FunctionName: script_name,
            Payload: JSON.stringify({
                queue: PROPS.queue,
                external_id: PROPS.account_number,
                ticket_id: PROPS.ticket_number
            })
        }).promise();

        console.log(lambda_result);

        if (lambda_result.StatusCode == 200) {
            CustomDisplayResult.value = JSON.parse(lambda_result.Payload)
        }
    });
</script>





<style scoped>




</style>