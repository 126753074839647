<template>
  <div
    class="alternateOptions"
    :class="{ alternateOptionsOpen: open }"
    :tabindex="0"
    @blur="open = false"
  >
    <div class="selected" :class="{ open: open }" @click="open = !open">
      <img
        v-if="selected.icon"
        :src="require(`../../assets/icons/${selected.icon}`)"
        class="alternateOptionsIcon"
      />

      {{ selected.text ? selected.text : `${label}: ${selected.split(" ")[0]}` }}

      <img
        v-if="open"
        :src="getCaretSrc('open')"
        class="alternateOptionsCaret"
      />
      <img v-else :src="getCaretSrc('closed')" class="alternateOptionsCaret" />
    </div>

    <div class="items" :class="{ selectHide: !open }">
      <div
        v-for="(option, i) of options"
        :key="i"
        @click="toggleSelected(option)"
        class="alternateOption"
      >
        <div class="optionLabel">
          <img
            v-if="option.icon"
            :src="require(`../../assets/icons/${option.icon}`)"
            class="alternateOptionsIcon"
          />
          <span class="optionText">{{ option.text || option }}</span>
          <span class="alternateOptionsMultiCheckbox">
            <CheckBox
              :item="option"
              :multi-select="false"
              :checked="
                selected.text
                  ? selected.text === option.text
                  : selected === option
              "
              @toggle-selected="toggleSelected(option)"
              @mousedown.prevent
            />
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script scoped>
import CheckBox from "./FormComponents/CheckBox.vue";

export default {
  name: "AlternateOptions",
  props: {
    options: Array,
    default: [String, Object],
    label: String,
    caretColorWhite: Boolean,
  },
  data() {
    return {
      selected: this.default
        ? this.default
        : this.options.length > 0
        ? this.options[0]
        : null,
      open: false,
    };
  },
  methods: {
    toggleSelected(option) {
      if (this.selected !== option || this.selected === null) {
        this.selected = option;
        this.open = false;
        this.$emit("input", option);
      }
    },
    getCaretSrc(state) {
      if (this.caretColorWhite) {
        return require(`../../assets/icons/caret_${state}_white.png`);
      } else {
        return require(`../../assets/icons/caret_${state}_dkblue.png`);
      }
    },
  },
  components: { CheckBox },
  emits: [
    "input"
  ]
};
</script>

<style scoped>
.alternateOptions {
  position: relative;
  font-size: 10pt;
  font-weight: 400;
  z-index: 1000;
  border-radius: 1px;
  padding: 4px 0px 4px 8px;
  color: #271d7a;
}

.alternateOptionsOpen {
  z-index: 5;
  /* outline: 0.25px solid #271d7a; */
}

.selected {
  display: flex;
  align-items: center;
  white-space: nowrap;
}

.alternateOptionsIcon {
  height: 14px;
  margin-right: 5px;
}

.alternateOptions .items {
  color: #ffffff;
  overflow: hidden;
  position: absolute;
  background-color: #ffffff;
  /* left: 0; */
  right: 0;
  z-index: 1;
  margin-top: 10px;
  padding: 5px;
  min-width: 100%;
}

.alternateOptions .items div {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  color: #271d7a;
  cursor: pointer;
  user-select: none;
}
.items {
  margin-top: 3px;
  filter: drop-shadow(1px 1px 1px rgba(39, 173, 122, 0.2));
}
.alternateOptions:hover {
  cursor: pointer;
  /* outline: 0.25px solid #271d7a; */
}

.alternateOptions .items div:hover {
  background-color: #eff4f9;
}
.alternateOption {
  padding: 2px 0px 2px 3px;
}

.selectHide {
  display: none;
}

.optionText {
  margin-left: 5px;
  margin-right: 15px;
}
.alternateOptionsMultiCheckbox {
  width: 20px;
  padding-top: 3px;
}

.optionLabel {
  display: flex;
  align-items: center;
  width: 100%;
  white-space: nowrap;
}
.alternateOptionsCaret {
  position: relative;
  width: 25px;
  padding-left: 10px;
  padding-right: 5px;
  margin-bottom: 1px;
}
</style>
