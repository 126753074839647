<template>
  <input
    type="text"
    class="inputNormal"
    ref="bubble"
    :placeholder="placeholder"
    :value="modelValue"
    @input="handleInput"
    v-bind="$attrs"
    :readonly="readonly"
    
    :class="{ readonly: readonly }"
  />
</template>

<script>
export default {
  name: "FillInBubble",
  props: {
    placeholder: String,
    modelValue: String,
    readonly: Boolean,
    fixedWidth: Boolean
  },
  methods: {
    handleInput (e) {
      this.$emit('update:modelValue', e.target.value);

      this.inputResize();
    },
    inputResize () {
      if (!this.fixedWidth) { return }

      const textLength = this.$refs.bubble.value.length;
      const characterWidth = 6;

      this.$refs.bubble.style.minWidth = `${(textLength + 1) * characterWidth}px`
    }
  },
  mounted () {
    this.inputResize();
  },
  updated () {
    this.inputResize();
  },
  emits: ["update:modelValue"],
};
</script>

<style scoped>
.inputNormal {
  outline: #2e3e91 solid 0.25pt;
  border-radius: 2px;
  padding-left: 8px;
  padding-top: 2px;
  padding-bottom: 2px;
  font-size: 9pt;
  font-weight: 500;
  color: #271d7a;
  background-color: #ffffff;
  height: 28px;
}
.inputNormal:hover {
  outline: #2e3e91 solid 0.5pt;
}
.inputNormal:focus {
  outline: #2e3e91 solid 1pt;
}
.inputNormal::placeholder {
  opacity: 0;
  size: 9pt;
  color: #b1cdea;
}
.inputNormal:focus::placeholder {
  opacity: 1;
}

.readonly {
  background-color: #eff4f9;
}
</style>
