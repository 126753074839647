<template>
  <div class="searchBarFlowContainer">
    <input
      type="search"
      class="searchBarFlow"
      placeholder="Search Available Questions"
      :value="modelValue"
      @input="$emit('update:modelValue', $event.target.value)"
      v-bind="$attrs"
    />
  </div>
</template>

<script>
export default {
  name: "searchBarFlow",
  props: {
    placeholder: String,
    modelValue: String,
    readonly: Boolean,
  },
  data() {
    return {
      searchValue: "",
    };
  },
  methods: {
    emitSearchValue() {
      this.$emit("search-value", this.searchValue);
    },
  },
  emits: [
    "update:modelValue",
    "search-value"
  ]
};
</script>

<style scoped>
.searchBarFlowContainer {
  position: relative;
}
.searchBarFlow {
  width: 200px;
  border-radius: 3px;
  padding-left: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  background-color: #ffffff;
  outline: 0.25pt solid #2e3e91;
  font-size: 9pt;
  font-weight: 500;
  color: #271d7a;
}
.searchBarFlow::placeholder {
  font-size: 9pt;
  color: #b1cdea;
}
.searchBarFlow:hover {
  outline: 0.5pt solid #2e3e91;
}
.searchBarFlow:focus-within {
  background-color: #ffffff;
  outline: 0.5pt solid #2e3e91;
  caret-color: #2e3e91;
  font-weight: 500;
  color: #271d7a;
}
.searchBarFlowIcon {
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  width: 14px;
  height: 14px;
}
</style>
