<template>
  <div class="searchBarContainer">
    <input
      type="search"
      class="searchBar"
      placeholder="Search"
      v-model="searchValue"
      @input="emitSearchValue"
    />
    <img
      class="searchBarIcon"
      v-bind:src="require(`../../assets/icons/Search.png`)"
    />
  </div>
</template>

<script>
export default {
  name: "SearchBar",
  data() {
    return {
      searchValue: "",
    };
  },
  methods: {
    emitSearchValue() {
      this.$emit("search-value", this.searchValue);
    },
  },
  emits: [
    "search-value"
  ]
};
</script>

<style scoped>
.searchBarContainer {
  position: relative;
}
.searchBar {
  border-radius: 3px;
  padding-left: 30px;
  padding-top: 5px;
  padding-bottom: 5px;
  background-color: #ffffff;
  outline: 0.25pt solid #2e3e91;
  font-size: 9pt;
  font-weight: 500;
  color: #271d7a;
}
.searchBar::placeholder {
  font-size: 9pt;
  color: #b1cdea;
}
.searchBar:hover {
  outline: 0.5pt solid #2e3e91;
}
.searchBar:focus-within {
  background-color: #ffffff;
  outline: 0.5pt solid #2e3e91;
  caret-color: #2e3e91;
  font-weight: 500;
  color: #271d7a;
}
.searchBarIcon {
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  width: 14px;
  height: 14px;
}
</style>
