<template>
  <textarea 
    class="inputEx textarea" 
    :placeholder="placeholder" 
    v-model="inputValue">
  </textarea>
</template>
  
<script>
export default {
  name: "TextBox",
  props: {
    placeholder: String,
    value: String,
  },
  computed: {
    inputValue: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
  },
  emits: ["input"],
}
</script>

  
<style scoped>
.textarea {
  display: block;
  width: 100%;
  overflow: auto;
  resize: both;
  min-height: 100px;
  line-height: 20px;
}

.inputEx {
  outline: #2e3e91 solid 0.25pt;
  border-radius: 2px;
  padding: 8px;
  font-size: 9pt;
  font-weight: 500;
  color: #271d7a;
  background-color: #ffffff;
  box-sizing: border-box;
}

.inputEx:hover {
  outline: #2e3e91 solid 0.5pt;
}

.inputEx:focus {
  outline: #2e3e91 solid 1pt;
}

.inputEx::placeholder {
  color: #b1cdea;
}</style>