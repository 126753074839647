<template>
    <div class="audioPlayer">
      <audio :title="fileName" controls :src="audioSrc" style="width: 100%" >
        <a :href="audioSrc" :download="fileName">{{ fileName }}</a>
      </audio>
    </div>
  </template>
  <script setup>
  import { ref, defineProps, toRefs, watch } from "vue";
  let props = defineProps({
    audio: Object // Specify the prop type as an object
  });
  const { audio } = toRefs(props);
  const audioSrc = ref("");
  const fileName = ref("");

  watch(audio, (newAudio) => {
    // console.log("New audio source:", newAudio?.audioSrc);
    // console.log("New audio fileName:", newAudio?.fileName);
    audioSrc.value = newAudio?.audioSrc || "";
    fileName.value = newAudio?.audioFileName || "";
  }, { deep: true, immediate: true });
  
  </script>
  
  <style scoped>
  .audioPlayer {
    display: flex;
    align-items: center;
    width: 100%;
  }
  
  .progressBar {
    width: 300px;
    height: 4px;
    background-color: #e4edf4;
    position: relative;
    margin-right: 10px;
    width: 100%;
    border-radius: 2px;
    display: flex;
    flex-direction: column;
  }
  
  .progress {
    height: 100%;
    background-color: #3e87d3;
    border-radius: 2px;
  }
  .column {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .timeInfo {
    display: flex;
    justify-content: space-between;
    color: #271d7a;
    font-weight: 500;
    font-size: 10pt;
    padding-top: 10px;
    padding-bottom: 20px;
  }
  .buttonContainer {
    display: flex;
    justify-content: space-between;
    color: #271d7a;
    font-size: 10pt;
    padding-bottom: 30px;
  }
  </style>
  