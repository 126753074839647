<template>
    <v-sheet v-if="isOpen" elevation="5" class="sideDrawer">
        <div class="sideDrawerHeader">
            <div class="sideDrawerTitle">
                <h3>{{  PROPS.title }}</h3>
            </div>
            <button class="sideDrawerCloseButton">
                <img class="sideDrawerCloseImage" @click="closeSideDrawer" :src="require('../../assets/icons/Remove.png')"/>
            </button>
        </div>
        <div class="sideDrawerContent">
            <slot name="content"></slot>
        </div>
        <div class="sideDrawerFooter">                
            <slot name="footer"></slot>
        </div> 
        
    </v-sheet>

</template>




<script setup>
    import { ref, defineExpose, defineEmits, defineProps } from "vue";

    //Consts
    const isOpen = ref(false);


    //Props
    const PROPS = defineProps({
        title: {
            type: String,
            default: null
        },
    });

    //Emits
    const emit = defineEmits(["drawer-closed", "drawer-opened"]);

    //Exposes
    defineExpose({
        closeSideDrawer,
        openSideDrawer
    });


    //Exposed Functions
    function closeSideDrawer() {
        isOpen.value = false;
        emit('drawer-closed');
    }

    function openSideDrawer() {
        isOpen.value = true;
        emit('drawer-opened');
    }
</script>





<style scoped>
.sideDrawer {
    position: fixed;
    z-index: 10;
    float: right;
    right: 0;
    height: 100%;
    background-color: #ffffff;
    min-width: 350px;
}

.sideDrawerHeader {
    padding: 10px;
    padding-left: 16px;
}

.sideDrawerTitle {
    display: inline-block;
    color: #271d7a;
}

.sideDrawerCloseButton {
    display: block;
    float: right;
    padding-right: 5px;

}

.sideDrawerCloseImage {
    width: 15px;
    height: 15px;
}

.sideDrawerContent {
    display: relative;
    overflow-y: auto;
    overflow-x: hidden;
    height: 80%;
    padding: 15px;
}

.sideDrawerFooter {
    padding: 15px;
    bottom: 0;
    position: absolute;
    width: 100%;
    background-color: #ffffff;
    border: none;
    border-top: 0.5px solid #dde9f4;
    z-index: 10;
}





</style>