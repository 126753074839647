<template>
  <div class="questionContainer">
    <ExpandableFlowCard
      v-for="(question, index) in questions"
      :key="index"
      :question="question"
      :open="question.open"
      @toggle="toggleCard(index)"
      @toggleNested="(dependent) => toggleNested(index, dependent)"
      @addDependentToFlow="handleAddDependent"
      @removeQuestion="handleRemoveQuestion"
      @changeFocus="() => this.$emit('changeFocus')"
      @moveQuestion="handleMovingQuestion"
    />
  </div>
</template>

<script>
import ExpandableFlowCard from "./ExpandableFlowCard.vue";

export default {
  name: "CurrentFlow",
  props: {
    drawerOpen: Boolean,
    questions: Array,
    editFlow: Object,
  },
  data() {
    return {
      submissionData: {},
    };
  },
  watch: {
    drawerData() {
      this.submissionData = {};
    },
  },
  methods: {
    toggleCard(index, depIndex, dependent) {
      this.$emit("toggle", index, "flow", dependent);
    },
    toggleNested(index, dependent) {
      this.$emit("toggle", index, "flow", dependent);
    },
    handleAddDependent(option, question) {
      this.$emit("addDependentToFlow", option, question);
    },
    handleRemoveQuestion(question, dependent) {
      this.$emit("removeQuestion", question, dependent);
    },
    handleMovingQuestion(question, type) {
      this.$emit("moveQuestion", question, type);
    },
  },
  components: {
    ExpandableFlowCard,
  },
  emits: [
    "removeQuestion",
    "toggle",
    "addDependentToFlow",
    "changeFocus",
    "moveQuestion"
  ],
};
</script>

<style scoped>
.questionContainer {
  outline: 1px solid #271d7a;
  margin: 10px;
  height: 100%;
  overflow: auto;
}
</style>
