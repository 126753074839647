<template>
  <div
    class="card"
    tabindex="0"
    @click="toggleSelected(this.category)"
    :class="{ selectedPage: isSelected }"
  >
    <div class="row">
      <img :src="require('@/assets/icons/webphone_missed.png')" class="icon" />
      <div class="column">
        <h3 class="caller">{{ voicemail.caller }}</h3>
        <div class="time">
          <span class="timeText">{{ voicemail.time }}</span>
          <span class="timeText">
            {{ voicemail.duration }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "VoicemailCard",
  props: { voicemail: Object, isSelected: Boolean },
  methods: {
    toggleSelected() {
      this.$emit("selectedVoicemail", this.voicemail);
    },
  },
  emits: [
    "selectedVoicemail"
  ]
};
</script>

<style scoped>
.time {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}
.row {
  display: flex;
  flex-direction: row;
}
.column {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.icon {
  width: 20px;
  height: 20px;
  margin-right: 15px;
  margin-top: 6px;
}
.card {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
}
.card:hover {
  background-color: #ffffff;
}
.selectedPage {
  background-color: #ffffff;
  border-left: 2px solid #3e87d3;
}
.caller {
  font-size: 11pt;
  padding-bottom: 3px;
  color: #e99835;
  text-align: left;
  font-weight: 500;
  width: 100%;
}
.timeText {
  font-size: 10pt;
  color: #271d7a;
  text-align: left;
  font-weight: normal;
  font-weight: 500;
}
</style>
