<template>
  <div :style="{ backgroundColor: '#e2f1f4', height: '100%' }">
    <div class="container qualityForm">
      <div class="left">
        <div class="section">
          <h1 class="wizardHeader">{{ header }}</h1>
          <img
            src="@/assets/LogicomUSA_logo_fullcolor_web.png"
            class="logicomLogo"
          />
          <QualityAuditFillInInfoForm
            :formData="fillInInfoFormData"
            :numCols="6"
            :clearFillInInfo="clearFillInInfo"
            @invalid-file-type="handleInvalidFileType"
            @submission-data="handleFormSubmission"
            :previousFormValues="
              previousFormValues?.['fillInInfo'] || fillInInfoValue
            "
          />
        </div>
        <DividerComp class="divider" />
        <div class="wizard">
          <ProgressMenu
            :steps="formData"
            :currentIndex="currentIndex"
            :questionIndex="currentIndex"
            :isMenuClickable="isMenuClickable"
            @sectionClick="(index) => (currentIndex = index)"
          />
        </div>
      </div>
      <div class="right">
        <div class="wizardForm">
          <QualityAuditFormCompWizard
            @dropdown-data="updateDropDownSelection"
            @date-update="handleDateUpdate"
            @toggle-checkbox="toggleCheckBox"
            :formData="formData"
            title="Add Device"
            :numCols="auditNumCols"
            :currentIndex="currentIndex"
            :buttonList="buttonList"
            :clearWizardForm="clearWizardForm"
            @nextSection="handleNext"
            @previousSection="handlePrevious"
            @addListItem="handleListItems"
            @submission-data="handleFormSubmission"
            :previousFormValues="previousFormValues?.['wizard']"
          ></QualityAuditFormCompWizard>
        </div>
      </div>
      <div class="addedList">
        <ListComp
          v-if="formData[currentIndex].add"
          :headers="formData[currentIndex].headers"
          :items="
            previousFormValues?.['listItems'][formData[currentIndex].title] ||
            listItems[formData[currentIndex].title]
          "
          :name="formData[currentIndex].title"
          @remove-item="handleRemoveItem"
          is-deletable
          :error-message="`No ${formData[currentIndex].title} yet`"
          :style="{ height: 'fit-content' }"
        />
      </div>
    </div>
    <AlertsGroup
      v-model:alerts="alerts"
    />
  </div>
</template>

<script>
import ProgressMenu from "@/components/base_components/FormComponents/Wizard/ProgressMenu.vue";
import QualityAuditFormCompWizard from "@/components/CcManagement/QualityAudit/QualityAuditFormCompWizard.vue";
import DividerComp from "@/components/base_components/DividerComp.vue";
import ListComp from "@/components/base_components/ListComp.vue";
import QualityAuditFillInInfoForm from "@/components/CcManagement/QualityAudit/QualityAuditFillInInfoForm.vue";
import AlertsGroup from "@/components/base_components/AlertsGroup.vue";
import { addAlert } from '@/helpers/alertUtils';
import { ERROR, SUCCESS } from "@/constants/statuses.constants.js";

export default {
  name: "QualityAuditWizardComp",
  emits: ['toggle-checkbox', 'dropdown-data', 'wizard-data', 'handle-zero-result'],
  props: {
    fillInInfoFormData: Array,
    fillInInfoValue: Object,
    formData: Array,
    auditNumCols: Number,
    previousFormValues: Object,
    header: String,
    buttonList: Array,
    isMenuClickable: Boolean,
    fillInRequiredToSave: Boolean,
  },

  methods: {
    handleInvalidFileType() {
      addAlert(ERROR, "File Upload Must Be .mp3", this.alerts);
    },
    handleDateUpdate(section, input, event) {
      if (!this.submissionData.wizard || !this.submissionData.wizard['Basic Information']) {
        this.submissionData.wizard= {};
        this.submissionData.wizard['Basic Information'] = {};
      }
      this.submissionData.wizard['Basic Information'][input.value] = event;
    },
    toggleCheckBox(section, input, event) {
      this.$emit('toggle-checkbox', section, input, event)
    },
    updateDropDownSelection(value) {
      if (value.value === 'inbound' || value.value === 'outbound') {
      
        if (!this.submissionData.wizard || !this.submissionData.wizard['Compliance']) {
          this.submissionData.wizard= {};
          this.submissionData.wizard['Compliance'] = {};
          this.submissionData.wizard['Compliance'].callType = value.value;
        } else {
          this.submissionData.wizard['Compliance'].callType = value.value;
        }

       this.$emit('dropdown-data', value);


      } else if (value && value.name === 'Name of Employee') {
        if (!this.submissionData.wizard || !this.submissionData.wizard['Basic Information']) {
          this.submissionData.wizard= {};
          this.submissionData.wizard['Basic Information'] = {};
        }
        this.submissionData.wizard['Basic Information'].employee = value;
      } else if (value.value === 'threeStep' || value.value === 'twoStep'){
        if (!this.submissionData.wizard || !this.submissionData.wizard['Compliance']) {
          this.submissionData.wizard = {};
          this.submissionData.wizard['Compliance'] = {};
          this.submissionData.wizard['Compliance'].verificationLevel = value.value;
        } else {
          this.submissionData.wizard['Compliance'].verificationLevel = value.value;
        }
        this.$emit('dropdown-data', value)
      } else {
        this.$emit('dropdown-data', value)
      }
    },
    handleNext() {
      if (!this.submissionData.wizard[this.formData[this.currentIndex].title]) {
        this.submissionData.wizard[this.formData[this.currentIndex].title] = {};
        this.$emit('handle-zero-result', this.formData[this.currentIndex].title)
      } else {
        let title = this.formData[this.currentIndex].title;
        if (title !== 'Basic Information' && title !== 'Compliance') {
          this.$emit('handle-zero-result', this.formData[this.currentIndex].title)
        }
        
      }
      if (this.formData.length - 1 > this.currentIndex) {
        if (this.fillInRequiredToSave) {
          if (this.isFillInFormValid) {
            this.currentIndex += 1;
          } else {
            addAlert(ERROR, 'Missing Required Values', this.alerts);
          }
        } else {
          this.currentIndex += 1;
        }
      }
    },
    handlePrevious() {
      if (this.currentIndex > 0) {
        this.currentIndex -= 1;
      }
    },
    handleResize() {
      this.windowHeight = window.innerHeight;
    },
    handleListItems(item) {
      if (item === "Error") {
        addAlert(ERROR, 'Missing Required Values', this.alerts);
      } else {
        let section = this.formData[this.currentIndex].title;
        if (!this.listItems[section]) {
          this.listItems[section] = []; // Create the section object if it doesn't exist
        }
        this.listItems[section].push(item);
        this.submissionData["listItems"] = this.listItems;
      }
    },
    handleRemoveItem(item) {
      let section = this.formData[this.currentIndex].title;
      this.listItems[section] = this.listItems[section].filter(
        (i) => i !== item
      );
      alert(
        `${
          item?.[this.formData?.[this.currentIndex].headers[0]]
        } has been removed`
      );
    },
    resetForm() {
      this.submissionData = {};
      this.listItems = {};
      this.currentIndex = 0;
      this.clearFillInInfo = true;
      this.clearWizardForm = true;
    },
    validateEmail(email) {
      return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    },
    validatePhoneNumber(number) {
      return /^(\+?\d{1,3}[-.\s]?)?\(?\d{3}\)?[-.\s]?\d{3}[-.\s]?\d{4}$/.test(
        number
      );
    },
    handleFormSubmission(formData, component, subType, errorMessage) {
      if (formData === "Error") {
        addAlert(ERROR, errorMessage || "Missing Required Values", this.alerts);
      } else {
        this.submissionData[component] = Object.assign(
          {},
          this.submissionData[component],
          formData
        );
        if (component === "fillInInfo") {
          // If a user deletes their answer(s), delete it from submissionData here so submission is blocked
          const formDataKeys = Object.keys(formData);
          const submissionDataKeys = Object.keys(
            this.submissionData[component]
          );
          const keysToDelete = [...formDataKeys, ...submissionDataKeys].filter(
            (key) =>
              !formDataKeys.includes(key) || !submissionDataKeys.includes(key)
          );

          keysToDelete.forEach((key) => {
            delete this.submissionData[component][key];
          });
        } else if (component === "wizard") {
          if (subType === "submit") {
            if (!this.submissionData.wizard['Documentation']) {
              this.submissionData.wizard['Documentation'] = {};
              this.$emit('handle-zero-result', 'Documentation')
            }
            if (this.isFillInFormValid) {
              if (this.submissionData.fillInInfo.file === null) {
                addAlert(ERROR, errorMessage || "Call Recording File Missing", this.alerts);
              } else {
                this.$emit("wizard-data", this.submissionData, subType);
                this.resetForm();
              }
            } else {
              addAlert(ERROR, errorMessage || "Missing Required Values", this.alerts);
            }
          } else {
            //Save and autosave
            if (this.fillInRequiredToSave) {
              if (this.isFillInFormValid) {
                this.$emit("wizard-data", this.submissionData, subType);

                if (subType === "save") {
                  addAlert(SUCCESS, "Successfully Saved", this.alerts);
                  this.resetForm();
                } else if (subType === "autoSave") {
                  this.handleNext();
                }
              } else {
                addAlert(ERROR, errorMessage || "Missing Required Values", this.alerts);
              }
            } else {
              this.$emit("wizard-data", this.submissionData, subType);
              this.resetForm();
            }
          }
        }
      }
    },
  },
  computed: {
    dynamicHeight() {
      return Number(this.windowHeight) - 50;
    },
    // standardFormValidators() {
    //   return {
    //     phone: this.validatePhoneNumber,
    //     email: this.validateEmail,
    //   };
    // },
    isFillInFormValid() {
      return this.fillInInfoFormData
        .slice(1, this.fillInInfoFormData.length)[0]
        .inputs?.every((input) => {
          if (input.required) {
            if (
              this.submissionData?.["fillInInfo"] &&
              !this.submissionData?.["fillInInfo"][input.name] &&
              !this.submissionData?.["fillInInfo"][input.value]
            ) {
              if (input.name !== 'Upload') {
                return false;
              }
            }
            // let value =
            //   this.submissionData?.["fillInInfo"][input.name] ||
            //   this.submissionData?.["fillInInfo"][input.value];

            // let validator =
            //   this.customFormValidators?.[input.value] ||
            //   this.standardFormValidators?.[input.validator];

            // if (validator && !validator(value)) {
            //   this.errorMessage = `${input.name} is invalid`;
            //   return false;
            // }
            return true;
          } else {
            return true
          }
        });
    },
  },
  mounted() {
    window.addEventListener("resize", this.handleResize);
    this.windowHeight = window.innerHeight;
  },

  beforeUnmount() {
    window.removeEventListener("resize", this.handleResize);
  },
  watch: {
    windowHeight() {
      this.$nextTick(() => {
        this.dynamicHeight;
      });
    },
    fillInInfoValue(newValue) {
      this.submissionData = Object.assign({}, newValue);
    },
    previousFormValues: {
      deep: true,
      immediate: true,
      handler(newValue) {
        if (newValue?.listItems) {
          this.listItems = newValue.listItems;
          this.submissionData["listItems"] = Object.assign(
            {},
            this.submissionData["listItems"],
            this.listItems
          );
        }
      },
    },
    clearWizardForm: {
      deep: true,
      handler(newValue) {
        this.$nextTick(() => {
          if (newValue) {
            this.clearWizardForm = false;
          }
        });
      },
    },
    clearFillInInfo: {
      deep: true,
      handler(newValue) {
        this.$nextTick(() => {
          if (newValue) {
            this.clearFillInInfo = false;
          }
        });
      },
    },
  },
  data() {
    return {
      currentIndex: 0,
      windowHeight: null,
      listItems: {},
      submissionData: {},
      alerts: [],
      clearWizardForm: false,
      clearFillInInfo: false,
    };
  },
  components: {
    ProgressMenu,
    QualityAuditFormCompWizard,
    DividerComp,
    ListComp,
    QualityAuditFillInInfoForm,
    AlertsGroup,
  },
};
</script>
<style scoped>
.container {
  height: 100%;
  display: flex;
  padding: 20px 0px 20px 20px;
}
@media (max-width: 820px) {
  .qualityForm {
    flex-direction: column !important;
  }
}


.left {
  flex: 1 30%;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  padding: 16px 0px 0px 10px;
}

.divider {
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
}

.right {
  flex: 1 70%;
  display: flex;
  flex-direction: column;
  background-color: #fff;
}
.addedList {
  flex: 1 50%;
  display: flex;
  flex-direction: column;
  margin-right: 10px;
  margin-left: 10px;
}
.wizardHeader {
  color: #271d7a;
  font-size: 14pt;
  font-weight: bold;
  padding: 0px 10px 20px 5px;
}
.logicomLogo {
  width: 100%;
  padding: 0px 30px 0px 5px;
}
.wizard {
  padding-top: 20px;
}
.wizardForm {
  filter: drop-shadow(2px 2px 2px rgba(39, 29, 122, 0.1));
  box-shadow: -5px 0 5px -5px rgba(39, 29, 122, 0.1);
  height: 100%;
}
</style>
