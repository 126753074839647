import { defineStore } from 'pinia'
import { API } from "aws-amplify";
import { Auth } from "aws-amplify";

export const useTextToSpeechStore = defineStore('TextToSpeech', {
    state: () => ({
        example: ""
    }), 
    getters: {
        getExample(state) {
            return state.example
        }
    },
    actions: {
        async ConvertTextToSpeech(message, voice, pitch, speed, audioDeviceProfile) {
            const api_name = "google";
            const path = "/frontend/text2speech";
    
            const userAuth = `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`;
    
            var bodyJSON = {
                input: {
                    text: message
                },
                voice: {
                    languageCode: "en-US",
                    name: voice
                },
                audioConfig: {
                    pitch: pitch,
                    speakingRate: speed,
                    audioEncoding: "MP3"
                }
            };
    
            if (audioDeviceProfile !== "") {
                bodyJSON.audioConfig.effectsProfileId = [audioDeviceProfile];
            }
    
            const myInit = {
                body: bodyJSON,
                headers: {
                    Authorization: userAuth,
                    "Content-Type": "application/json"
                }  
            };

            // Test retry mech
            // let forceFail = true;  
    
            const maxRetries = 10; 
            let retries = 0;
            while (retries < maxRetries) {
                try {
                    // if (forceFail) throw new Error("Force fail for testing");

                    const response = await API.post(api_name, path, myInit);
                    // console.log("Text to speech response", response);
                    
                    if (response && response.success) {
                        return response;
                    }
                }
                catch (error) {
                    console.error(`Attempt ${retries + 1} failed. Retrying...`);
                }
    
                await new Promise((resolve) => setTimeout(resolve, 200));
                retries++;
            }
        }
    },
})
