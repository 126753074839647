<template>
    <h1 class="header">Queue Dashboard - {{ queue.name }}</h1>
    <div class="row">
      <div class="column" v-for="(item, index) in items" :key="index">
        <span class="queueNumber">{{ item.number }}</span>
        <div class="row callsWaiting" style="margin-left: 30px;">
          <!-- style="padding-left: 25px;" -->
          <img
            :src="require(`@/assets/icons/${item.icon}`)"
            class="waitingImg"
          /><span class="label">{{ item.name }}</span>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: "dashboardCard",
    props: {
      queue: Object,
    },
    computed: {
      items() {
        return [
          {
            name: "Calls Waiting in Queue",            
            number: this.queue.stats['active_session_count'],
            icon: "callswaiting_larger.png",
          },
          {
            name: "Average Wait Time",            
            number: this.queue.stats['average_wait'],
            icon: "waittime_larger.png",
          },
          {
            name: "Estimated Hold Time - Current",            
            number: this.queue.stats.estimated_wait,
            icon: "holdtime_current_larger.png",
          },
          {
            name: "Longest Hold Time - Day",            
            number: this.queue.stats.longest_wait,
            icon: "holdtime_day_larger.png",
          },
          {
            name: "Callbacks Scheduled",
            number: this.queue.tickets.callbacks_scheduled || 0,
            icon: "callbacks.png",
          },
          {
            name: "New Tickets",
            number: this.queue.tickets.new || 0,            
            icon: "newtickets.png",
          },
        ];
      },
    },
  };
  </script>
  <style scoped>
  .header {
    font-size: 11pt;
    color: #271d7a;
  }
  .row {
    display: flex;
    flex-direction: row;
    text-align: center;
    flex-wrap: wrap; /* Allow row to wrap to next line */
    justify-content: center;
    align-items: stretch; /* Stretch columns to fill the row vertically */
  }
  .column {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
  .queueNumber {
    font-size: 30pt;
    color: #271d7a;
    font-weight: 500;
    text-align: center;
  }
  .waitingImg {
    width: 36px;
    height: 36px;
    margin-top: 5px;
    margin-right: 5px;
  }
  .callsWaiting {
    display: flex;
    text-align: center;
    justify-content: center;
  }
  .label {
    text-align: left;
    width: 120px;
    padding-left: 10px;
    font-size: 11pt;
    color: #271d7a;
    font-weight: 500;
    display: flex;
    align-items: center;
  }
  </style>
  