const ADD_PARTNER = 'Add Partner';
const EDIT_PARTNER = 'Edit Partner';
const UPDATE_PARTNER = 'Update Partner';
const REMOVE_PARTNER = 'Remove Partner';
const PARTNER_CREATE_ALERT = 'Successfully Added Partner';
const PARTNER_REMOVE_ALERT = 'Partner was removed';
const PARTNER_UPDATE_ALERT = 'Partner was updated';

export {
  ADD_PARTNER,
  EDIT_PARTNER,
  REMOVE_PARTNER,
  UPDATE_PARTNER,
  PARTNER_CREATE_ALERT,
  PARTNER_REMOVE_ALERT,
  PARTNER_UPDATE_ALERT
}