<template>
    <!-- type="number" -->
    <input
    type="text"
    class="inputNormal"
    :placeholder="placeholder"
    :value="modelValue"
    @input="$emit('update:modelValue', $event.target.value)"
    v-bind="$attrs"
    :readonly="readonly"
    :class="{ readonly: readonly }"
  />
</template>

<script>
export default {
  name: "FillInBubble",
  props: {
    placeholder: String,
    modelValue: String,
    readonly: Boolean,
  },
  emits: ["update:modelValue"],
};
</script>

<style scoped>
.inputNormal {
  outline: rgba(46, 62, 145, 0.4) solid 0.25pt;
  border-radius: 2px;
  padding-left: 8px;
  padding-top: 2px;
  padding-bottom: 2px;
  font-size: 9pt;
  font-weight: 500;
  color: #271d7a;
  background-color: #ffffff;
  height: 28px;
  text-align: center;
}
.inputNormal:hover {
  outline: #2e3e91 solid 0.5pt;
}
.inputNormal:focus {
  outline: #2e3e91 solid 1pt;
}
.inputNormal::placeholder {
  text-align: center;
  color: rgba(46, 62, 145, 0.4);
}

.readonly {
  background-color: #eff4f9;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="text"] {
  -moz-appearance: textfield;
}
</style>
