<template>
  <div>
    <div class="sideDrawer">
      <ManagementSideDrawer
        :drawer-open="localDrawer"
        buttonType="Close"
        :numCols="12"
        @close-click="handleCloseClick"
        :drawer-data="getDrawerData"
        @form-submission="handleSubmit"
        :previousSubmissionData="preSubmissionData"
        :readonly="isLocalReadonly"
        :width="325"
      />
    </div>
    
    <div class="tableContainer">
      <TableComp
        :headers="tableHeaders"
        :data-list="filteredData"
        background-color="#e2f1f4"
        :single-select="true"
        :select-all="false"
        uniqueKey="id"
        :left-nav-present="true"
        @selected-item="handleSelectedItem"
        @edit-row="handleEditRow"
        @click-row="handleEditRow"
      />
    </div>
    <AlertsGroup
      v-model:alerts="alerts"
    />
  </div>
</template>

<script>
import TableComp from "@/components/base_components/TableComp.vue";
import ManagementSideDrawer from "@/components/Voice/Management/SideDrawer/ManagementSideDrawer.vue";
import AlertsGroup from '@/components/base_components/AlertsGroup.vue';
import { SUCCESS, ERROR, CONFIRM_REMOVE } from "@/constants/statuses.constants"
import { FORM_DRAWER_TYPE } from '@/constants/drawer.constants';
import { MISSING_FIELDS } from '@/constants/alerts.constants';
import { 
  ADD_PARTNER,
  EDIT_PARTNER,
  REMOVE_PARTNER,
  UPDATE_PARTNER,
  PARTNER_CREATE_ALERT,
  PARTNER_REMOVE_ALERT,
  PARTNER_UPDATE_ALERT
} from '@/constants/pages/partners.constants';
import { addAlert } from '@/helpers/alertUtils';

import { useCCManagementSchedulingStore } from "@/stores/ContactCenter/Management/CCManagementSchedulingStore.js";
import { mapStores } from 'pinia';

export default {
  name: 'PartnersView',
  components: {
    TableComp,
    ManagementSideDrawer,
    AlertsGroup
  },
  props: {
    drawer: {
      type: Boolean,
      default: false
    },
    create: {
      type: Boolean,
      default: false
    },
    update: {
      type: Boolean,
      default: false
    },
    isRemove: {
      type: Boolean,
      default: false
    },
    remove: {
      type: Boolean,
      default: false
    },
    search: {
      type: String,
      default: ''
    },
    isAdmin: {
      type: Boolean,
      default: false
    },
    isReadonly: {
      type: Boolean,
      default: false
    }
  },
  data () {
    const sideDrawerForm = [
      {
        name: "Name",
        type: "bubble",
        value: 'name',
        required: true,
      },
      { 
        name: "Ticket Queue Name",
        type: 'bubble',
        value: "ticket_queue_name",
        required: true,
      },
      { 
        name: "Category",
        value: 'category',
        type: "multiSelect",
        default: "T1",
        options: [
          { label: "T1", value: "T1" },
          { label: "Electric Answer", value: "Electric Answer" },
          { label: "Voice", value: "Voice" },
        ],
        required: false,
      },
      // { 
      //   name: "Contact Center Category",
      //   value: 'contact_category_name',
      //   type: "select",
      //   options: [
      //     { label: "None", value: null },
      //     { label: "T1", value: "T1" },
      //     { label: "Electric Answer", value: "Electric Answer" },
      //   ],
      //   required: false,
      // },
    ];

    const sideDrawerFormRemove = [
      {
        name: "Name",
        type: "bubble",
        value: 'name',
        required: true,
        readonly: true
      },
    ];

    return {
      tableHeaders: [
        { text: "", value: "checkbox", width: 22.6, fixed: true },
        { text: "Name", value: "name" },
        { text: "Ticket Queue Name", value: "ticket_queue_name" },
        { text: "Category", value: "category" },
        { text: "", value: "edithover", width: 18 }
      ],
      tableData:  [

      ],
      createFormData: [
        { title: ADD_PARTNER, button: ADD_PARTNER, type: FORM_DRAWER_TYPE },
        { title: '', inputs: sideDrawerForm }
      ],
      updateFormData: [
        { title: EDIT_PARTNER, button: UPDATE_PARTNER, type: FORM_DRAWER_TYPE },
        { title: '', inputs: sideDrawerForm }
      ],
      removeFormData: [
        { title: REMOVE_PARTNER, button: CONFIRM_REMOVE, type: FORM_DRAWER_TYPE },
        { title: '', inputs: sideDrawerFormRemove }
      ],
      alerts: [],
      submissionData: {},
      isSelectedRow: false
    }
  },
  async mounted() {
    let result = await this.CCManagementSchedulingStore.getPartnerInformation();
    
    if (result) {
      this.tableData = this.CCManagementSchedulingStore.getPartners.map(partner => {
        return {
          id: partner.id,
          name: partner.name,
          ticket_queue_name: partner.ticket_queue_name,
          category: partner.category
        };
      });
    } else {
      console.error("Failed to fetch work partners: ", result.error);
    }

  },
  watch: {
    'CCManagementSchedulingStore.getPartners': {
      deep: true,
      handler(newPartners) {
        this.tableData = newPartners.map(partner => {
          return {
            id: partner.id,
            name: partner.name,
            ticket_queue_name: partner.ticket_queue_name,
            category: partner.category
          };
        });
      },
    },
  },
  computed: {
    ...mapStores(useCCManagementSchedulingStore),
    isCreateForm: {
      get () {
        return this.create;
      },
      set (create) {
        this.$emit('update:create', create);
      }
    },
    isUpdateForm: {
      get () {
        return this.update;
      },
      set (update) {
        this.$emit('update:update', update);
      }
    },
    isRemoveForm: {
      get () {
        return this.remove;
      },
      set (remove) {
        this.$emit('update:remove', remove);
      }
    },
    localIsRemove: {
      get () {
        return this.isRemove;
      },
      set (isRemove) {
        this.$emit('update:isRemove', isRemove);
      }
    },
    localDrawer: {
      get () {
        return this.drawer;
      },
      set (drawer) {
        this.$emit('update:drawer', drawer)
      }
    },
    isLocalReadonly: {
      get () {
        return this.isReadonly
      },
      set (isReadonly) {
        this.$emit('update:isReadonly', isReadonly)
      }
    },
    filteredData () {
      if (this.searchResult !== '') {
        return this.tableData.filter(item => {
          return Object.values(item).some(fieldValue =>
            String(fieldValue).toLowerCase().includes(this.searchResult.toLowerCase())
          );
        });
      }
      
      return this.tableData
    },
     preSubmissionData () {
      if (this.isCreateForm) {
        return {}
      }

      return this.submissionData;
    },
    searchResult: {
      get () {
        return this.search;
      },
      set (search) {
        this.$emit('update:search', search);
      }
    },
    getDrawerData () {
      if (this.isCreateForm) {
        return this.createFormData;
      }
      
      if (this.isRemoveForm) {
        return this.removeFormData;
      }

      return this.updateFormData;
    }
  },
  methods: {
    handleSelectedItem(selectedItem) {
      console.log("handleSelecteditem", selectedItem);
      if (selectedItem !== null) {
        this.localIsRemove = true
        const { id, name, queue_name, category } = selectedItem;
        this.isRemoveForm = true;
        this.submissionData = {
          id,
          name,
          queue_name,
          category
        };
        this.isSelectedRow = true;
      } else {
        this.localIsRemove = false;
        this.isRemoveForm = false;
        this.isSelectedRow = false;
      }
      
    },
    handleEditRow(selectedItem) {
      console.log("editRow selecteditem", selectedItem);
      this.localDrawer = true;
      this.isCreateForm = false;
      this.isRemoveForm = false;
      this.isUpdateForm = true;
      
      var adjusted_category = [];
      if(selectedItem.category) {
        adjusted_category = selectedItem.category.split(',').map(item => ({"label": item.trim(), "value": item.trim()}));
      }

      this.submissionData = {
        id: selectedItem.id,
        name: selectedItem.name,
        ticket_queue_name: selectedItem.ticket_queue_name,
        category: adjusted_category
      };
    },
    handleCloseClick () {
      this.localDrawer = false;
      this.isCreateForm = false;
      this.isUpdateForm = false;
      this.isLocalReadonly = false;
    },
    async handleCreateForm(submissionData) {
      console.log("Create submissionData", submissionData);
      if (submissionData !== ERROR) {
        const { name, ticket_queue_name, category } = submissionData;

        var adjusted_category = "";
        if(Array.isArray(category) && category.length > 0) {
          var labelArray = category.map(obj => obj.label);
          adjusted_category = labelArray.join(', ');
        }

        // let contactLabel = category.label;

        let result = await this.CCManagementSchedulingStore.addPartner(name, ticket_queue_name, adjusted_category);

        if (result && !result.error) {
          addAlert(SUCCESS, PARTNER_CREATE_ALERT, this.alerts);
          await this.CCManagementSchedulingStore.getPartnerInformation();
        } else {
          addAlert(ERROR, result.message, this.alerts);
        }

        this.localDrawer = false;
        if (!this.isSelectedRow) {
          this.submissionData = {};
        }
        this.isCreateForm = true;
      } else {
        addAlert(ERROR, MISSING_FIELDS, this.alerts);
      }
    },
    async handleRemoveForm(submissionData) {
      if (submissionData !== ERROR) {
        const { id } = submissionData;

        let result = await this.CCManagementSchedulingStore.removePartner(id);

        if (result && !result.error) {
          addAlert(SUCCESS, PARTNER_REMOVE_ALERT, this.alerts);
          await this.CCManagementSchedulingStore.getPartnerInformation();
        } else {
          addAlert(ERROR, result.message || "Failed to remove partner", this.alerts);
        }

        this.localDrawer = false;
        this.isRemoveForm = false;
        this.isLocalReadonly = false;
        this.localIsRemove = false;
      } else {
        addAlert(ERROR, MISSING_FIELDS, this.alerts);
      }
    },
    async handleUpdateForm(submissionData) {
      if (submissionData !== ERROR) {
        const { id, name, ticket_queue_name, category } = submissionData;

        var adjusted_category = category;
        if(Array.isArray(category)) {
          var labelArray = category.map(obj => obj.label);
          adjusted_category = labelArray.join(', ');
        }

        const result = await this.CCManagementSchedulingStore.editPartner(id, name, ticket_queue_name, adjusted_category);

        if (result && !result.error) {
          addAlert(SUCCESS, PARTNER_UPDATE_ALERT, this.alerts);
          await this.CCManagementSchedulingStore.getPartnerInformation();
          this.localDrawer = false;
          this.isRemoveForm = false;
          this.isLocalReadonly = false;
        } else {
          addAlert(ERROR, result.message || "Failed to update partner", this.alerts);
        }
      } else {
        addAlert(ERROR, MISSING_FIELDS, this.alerts);
      }
    },
    handleSubmit(submissionData) {
      if (this.isCreateForm) {
        this.handleCreateForm(submissionData);
      } else if (this.isRemoveForm) {
        this.handleRemoveForm(submissionData);
      } else if (this.isUpdateForm) {
        this.handleUpdateForm(submissionData);
      }
    },
    formatSelect (item) {
      if (typeof item === 'string') {
        return item
      }

      return item?.label
    }
  },
  unmounted () {
    this.isLocalReadonly = false;
    this.localDrawer = false;
    this.isCreateForm = false;
    this.isUpdateForm = false;
    this.localIsRemove = false;
  },
  emits: [
    "update:create",
    "update:update",
    "update:remove",
    "update:isRemove",
    "update:drawer",
    "update:isReadonly",
    "update:search"
  ]
}
</script>

<style scoped>
.pageContent {
  flex-grow: 1;
}
.mainContainer {
  display: flex;
  background-color: #e2f1f4;
  height: 100%;
}
.mainContent {
  display: flex;
  flex-grow: 1;
  position: relative;
}
.tableContainer {
  height: 100%;
  padding: 20px;
  background-color: #e2f1f4;
}
.sideDrawer {
  flex: 0 0 auto;
}
:deep(.formContent) {
  min-height: calc(100vh - 164px);
}
.readonly :deep(.dropdownContainer),
.readonly :deep(.inputNormal) {
  user-select: none;
  pointer-events: none;
}
</style>