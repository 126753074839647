const ADD_EMPLOYEE = 'Add Employee';
const EDIT_EMPLOYEE = 'Edit Employee';
const REMOVE_EMPLOYEE = 'Remove Employee';
const EMPLOYEE_CREATE_ALERT = 'Successfully Added Employee';
const EMPLOYEE_REMOVE_ALERT = 'Employee was removed';
const EMPLOYEE_UPDATE_ALERT = 'Employee data was updated';

export {
  ADD_EMPLOYEE,
  EDIT_EMPLOYEE,
  REMOVE_EMPLOYEE,
  EMPLOYEE_CREATE_ALERT,
  EMPLOYEE_REMOVE_ALERT,
  EMPLOYEE_UPDATE_ALERT
}