<template>
  <div class="leftColFormContainer">
    <div class="leftColFormContent">
      <p class="formHeader">{{ formData[0].title }}</p>
      <img
        v-if="removeIcon"
        class="formRemoveIcon"
        :src="require('@/assets/icons/Remove.png')"
        @click="handleClose"
      />
      <div v-for="(section, sectionIndex) in formData" :key="sectionIndex">
        <template v-if="sectionIndex !== 0 && section.show !== false">
          <p class="formSectionTitle">
            {{ section.title }}
          </p>
          <v-form ref="form" @submit.prevent="onSubmit()">
            <div
              class="leftColFormInputs"
              :class="{
                formInputsWithScroll:
                  hasScrollbar && buttonOptions.positionBottom,
              }"
              ref="formInputs"
            >
              <template v-if="section.checkbox">
                <div class="checkboxWrapper">
                  <CheckBox
                    :item="section.checkbox"
                    @toggle-selected="
                      this.submissionData[section.checkbox.name] =
                        !this.submissionData[section.checkbox.name]
                    "
                  />
                  <label class="formInputLabel pl-2">{{
                    section.checkbox.name
                  }}</label>
                </div>
              </template>
              <v-row dense>
                <template v-for="input in section.inputs" :key="input">
                  <v-col
                    :cols="input.fullWidth ? 12 : 8"
                    class="formInputCol px-4"
                  >
                    <label
                      class="formInputLabel"
                      v-if="
                        input.type !== 'checkbox' &&
                        input.type != 'dynamicOptions'
                      "
                      >{{ input.name }}
                      <span v-if="input.required" class="required-asterisk"
                        >*</span
                      ></label
                    >
                    <template v-if="input.type === 'select'">
                      <div class="dropdownContainer">
                        <DropDown
                          :name="input.name"
                          :options="input.options"
                          :default="!input.noDefault && getDefaultValue(input)"
                          @input="updateDropDownVal(input, $event)"
                          :value="
                            input?.options?.find(
                              (option) =>
                                option.value === submissionData?.[input.name]
                            ) ||
                            submissionData[input.name] ||
                            formData?.[1].inputs?.[1]?.options?.find(
                              (option) =>
                                option.value === submissionData[input.value]
                            )
                          "
                        />
                      </div>
                    </template>
                    <template v-else-if="input.type === 'multiSelect'">
                      <DropDownMulti
                        :name="input.name"
                        :options="input.options"
                        placeholder="Select Fields"
                        :default="getDefaultValue(input)"
                        @input="updateDropDownVal(input.name, $event)"
                        :value="submissionData[input.name]"
                      />
                    </template>
                    <template v-else-if="input.name === 'Subject'">
                      <v-col class="formInputCol" :style="{ padding: '0px' }">
                        <FillInBubble
                          :placeholder="input.name"
                          :isRequired="input.required"
                          @input="updateInputVal(input, $event)"
                          :value="submissionData[input.name]"
                        />
                        <BulkUpdatePanel class="bulkUpdateText" />
                      </v-col>
                    </template>
                    <template v-else-if="input.type === 'expandableBubble'">
                      <ExpandableFillInBubble
                        :placeholder="input.name"
                        :isRequired="input.required"
                        @input="updateInputVal(input, $event)"
                        :value="
                          submissionData?.[input.value] ||
                          submissionData?.[input.name]
                        "
                      />
                    </template>
                    <template v-else-if="input.type === 'radio'">
                      <RadioComp
                        :input="input"
                        :submissionData="submissionData"
                        @toggle-selected="updateRadioValue(input.name, $event)"
                        :multiSelect="input.multiSelect"
                      />
                    </template>
                    <template v-else-if="input.type === 'bubble'">
                      <FillInBubble
                        :placeholder="input.name"
                        :isRequired="input.required"
                        @change="updateInputVal(input, $event)"
                        :value="
                          submissionData?.[input.value]
                            ? submissionData?.[input.value]
                            : submissionData?.[input.name]
                        "
                        :readonly="input.readonly"
                      />
                    </template>
                    <template v-else-if="input.type === 'checkbox'">
                      <div
                        class="formInputCheckbox"
                        :class="{
                          checkboxInRow: !(section.inputs.length % 2),
                        }"
                      >
                        <CheckBox
                          :item="input.name"
                          @toggle-selected="
                            this.submissionData[input.name] =
                              !this.submissionData[input.name]
                          "
                        />
                        <label class="formInputLabel pl-2">{{
                          input.name
                        }}</label>
                      </div>
                    </template>
                    <template v-else-if="input.type === 'dateRange'">
                      <DatePicker
                        :allowDateRange="true"
                        :input="input"
                        @date-value="handleDateChange"
                      />
                    </template>
                    <template v-else-if="input.type === 'datePicker'">
                      <DatePicker
                        :allowDateRange="false"
                        :input="input"
                        @date-value="handleDateChange"
                        :value="previousFormValues?.[input.value]"
                      /> </template
                    ><template v-else-if="input.type === 'timePicker'">
                      <TimePicker
                        :allowDateRange="false"
                        :input="input"
                        @date-value="handleDateChange"
                        :value="submissionData?.[input.value]"
                        :existingDate="
                          submissionData['dateEST'] ||
                          previousFormValues?.['dateEST']
                        "
                      />
                    </template>
                    <template v-else-if="input.type === 'dynamicOptions'">
                      <DynamicOptions
                        @dOptionsInput="updateOptionsVal(input.type, $event)"
                        :initialOptions="
                          Object.keys(previousFormValues).length
                            ? [...previousFormValues?.['dynamicOptions']]
                            : submissionData?.['dynamicOptions']
                        "
                      />
                    </template>
                    <template v-else-if="input.type === 'button'">
                      <slot
                        name="button"
                        :sendSubmissionData="sendSubmissionData"
                      >
                        <DarkButton> {{ input.name }}</DarkButton>
                      </slot>
                    </template>

                    <template v-else-if="input.type === 'filePicker'">
                      <div>
                        <ClickableMediumBlueButton :selectedFile="selectedFile" @file-selected="handleFileSelected" />
                          <!-- <p v-if="selectedFile">Selected file: {{ selectedFile.name }}</p> -->
                      </div>
                      
                    </template>
                  
                    <template v-else-if="input.type === 'smartLink'">
                      <SmartLink />
                    </template>
                    <template v-else-if="input.type === 'textarea'">
                      <slot name="textarea">
                        <textarea
                          :readonly="
                            input.readonly !== undefined ? input.readonly : true
                          "
                          :value="
                            submissionData?.[input.value] ||
                            submissionData?.[input.name] ||
                            input.text
                          "
                          :placeholder="input.placeholder"
                          class="textareaNormal"
                          @change="updateInputVal(input, $event)"
                        ></textarea>
                      </slot>
                    </template>
                  </v-col>
                </template>
              </v-row>
            </div>
          </v-form>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import DropDown from "./../../base_components/FormComponents/DropDown.vue";
import FillInBubble from "./../../base_components/FormComponents/FillInBubble.vue";
import DarkButton from "./../../base_components/Buttons/DarkButton.vue";
import ClickableMediumBlueButton from "./../../base_components/Buttons/ClickableMediumBlueButton.vue";

import CheckBox from "./../../base_components/FormComponents/CheckBox.vue";
import DropDownMulti from "./../../base_components/FormComponents/DropDownMulti.vue";
import BulkUpdatePanel from "./../../base_components/Textbox/BulkUpdatePanel.vue";
import DatePicker from "./../../base_components/FormComponents/DatePicker.vue";
import ExpandableFillInBubble from "@/components/base_components/ExpandableFillInBubble.vue";
import RadioComp from "./../../base_components/FormComponents/RadioComp.vue";
import DynamicOptions from "@/components/base_components/DynamicOptions.vue";
import TimePicker from "./../../base_components/FormComponents/TimePicker.vue";
import SmartLink from "./../../base_components/FormComponents/SmartLink.vue";

export default {
  name: "QualityAuditFillInInfoForm",
  props: {
    formData: Array,
    title: String,
    numCols: Number,
    removeIcon: Boolean,
    previousFormValues: Object,
    clearFillInInfo: Boolean,
  },
  watch: {
    windowHeight() {
      this.$nextTick(() => {
        this.dynamicHeight;
      });
    },
    clearFillInInfo: {
      deep: true,
      handler(newValue) {
        if (newValue) {
          this.submissionData = { ...this.previousFormValues };
        }
      },
    },
    previousFormValues: {
      deep: true,
      handler(newValues) {
        this.previousFormValuesTriggered = true;
        this.submissionData = { ...newValues };
      },
    },
    submissionData: {
      deep: true,
      handler(newSubmission) {
        this.$emit("submission-data", newSubmission, "fillInInfo");
      },
    },
  },
  computed: {
    dynamicHeight() {
      return Number(this.windowHeight) - 93;
    },
    dropdownName(input) {
      return `Select a ${input.name}`;
    },
    isFormValid() {
      return this.formData?.every((section) => {
        return section?.sections?.every((section) => {
          return section?.inputs?.every((input) => {
            let value =
              this.submissionData[section.title]?.[input.name] ||
              this.submissionData[section.title]?.[input.value];

            if (input.required) {
              if (!value) {
                this.errorMessage = `${input.name} is missing`;
                return false;
              }
              let validator =
                this.formValidators[input.value] ||
                this.standardFormValidators[input.validator];

              if (validator && !validator(value)) {
                this.errorMessage = `${input.name} is invalid`;
                return false;
              }
              return true;
            } else {
              return true;
            }
          });
        });
      });
    },
  },
  data() {
    return {
      submissionData: {},
      hasScrollbar: false,
      windowHeight: null,
      showDatePicker: false,
      selectedStartDate: "",
      selectedEndDate: "",
      selectedFile: null,
      uploadButtonText: "Upload a file",
      currentSelectedFile: {},
      previousFormValuesTriggered: false,
    };
  },
  methods: {
    handleFileSelected(file) {
      if (!file.name.toLowerCase().endsWith('.mp3')) {
        this.selectedFile = null;
        this.submissionData['file'] = null;
        this.$emit('invalid-file-type');
      } else {
        this.selectedFile = file;
        this.submissionData['file'] = file;
      }
    },
    // handleFileSelected(file) {
    //   this.selectedFile = file;
    //   this.submissionData['file'] = file;
    // },

    handleDateChange(input, value) {
      if (value) {
        if (input.value) {
          this.submissionData[input.value] = value;
        } else {
          this.submissionData[input.name] = value;
        }
      }
    },
    toggleDatePicker() {
      this.showDatePicker = !this.showDatePicker;
    },
    updateSelectedDate(date) {
      this.selectedStartDate = date;
    },
    getDefaultValue(input) {
      const vowels = ["a", "e", "i", "o", "u"];

      const inputName = input.name.toLowerCase();

      return vowels.includes(inputName.charAt(0))
        ? `Select an ${inputName}`
        : `Select a ${inputName}`;
    },
    updateDropDownVal(input, event) {
      if (input.value) {
        this.submissionData[input.value] = event.value;
      } else {
        this.submissionData[input.name] = event.value;
      }
      let name = input.name;
      this.$emit("dropdown-data", { name, value: event.value });

      // check if this input control's any other dropdown data
      const currentInputObject = this.formData[1].inputs.find(
        (input) => input.name === name
      );

      if (currentInputObject.controls) {
        const dependent = currentInputObject.controls;
        this.submissionData[dependent] = null;
        this.$emit("dependent-dropdown-data");
      }
    },
    updateInputVal(input, event) {
      if (event.target.value) {
        if (input.value) {
          this.submissionData[input.value] = event.target.value;
        } else {
          this.submissionData[input.name] = event.target.value;
        }
        this.$emit("input-data", event.target.value);
      } else {
        if (event.target.innerText) {
          if (input.value) {
            this.submissionData[input.value] = event.target.innerText;
          } else {
            this.submissionData[input.name] = event.target.innerText;
          }
        } else {
          delete this.submissionData[input.name];
          delete this.submissionData[input.value];
        }
      }
    },
    updateRadioValue(name, option) {
      if (Array.isArray(option) && name) {
        this.submissionData[name] = option;
      } else {
        if (name) {
          this.submissionData[name] = option.value;
        }
      }
    },
    updateOptionsVal(type, value) {
      this.submissionData[type] = value;
    },
    checkScrollbar() {
      const element = this.$refs.formInputs;
      if (this.windowHeight < element[0].scrollHeight + 200) {
        this.hasScrollbar = true;
      } else {
        this.hasScrollbar = false;
      }
    },
    handleResize() {
      this.windowHeight = window.innerHeight;
      this.checkScrollbar();
    },
  },
  mounted() {
    this.submissionData = { ...this.previousFormValues };
    window.addEventListener("resize", this.handleResize);
    this.windowHeight = window.innerHeight;
    this.checkScrollbar();
  },

  beforeUnmount() {
    window.removeEventListener("resize", this.handleResize);
  },

  updated() {
    this.checkScrollbar();
  },
  components: {
    FillInBubble,
    DropDown,
    DarkButton,
    ClickableMediumBlueButton,
    CheckBox,
    DropDownMulti,
    BulkUpdatePanel,
    DatePicker,
    ExpandableFillInBubble,
    RadioComp,
    DynamicOptions,
    TimePicker,
    SmartLink,
  },
  emits: [
    "submission-data",
    "dropdown-data",
    "input-data",
    "dependent-dropdown-data",
    "submissionDataUpdated",
    "invalid-file-type"
  ],
};
</script>

<style scoped>
.formButtonDivider {
  margin: 0px 0px 16px 0px;
  width: 100%;
}
.checkboxWrapper {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  padding-bottom: 10px;
  padding-left: 16px;
}
.formInputCheckbox {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.checkboxInRow {
  padding-top: 27px;
  padding-left: 3px;
}
.formButtonContainer {
  width: 100%;
  padding-bottom: 8px;
  bottom: 0;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  z-index: 5;
}
.formButtonPositionBottom {
  position: absolute;
  padding-bottom: 16px;
}
.formButtonCentered {
  align-items: center;
  text-align: center;
}
.formDarkButton {
  padding: 0px 15px 0px 15px;
}
.formInputCol {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}
.leftColFormContainer {
  background-color: #ffffff;
  overflow: hidden;
  height: 100%;
}
.leftColFormContent {
  overflow-y: auto;
  height: 100%;
  overflow-x: hidden;
}

/*
.leftColFormInputs {
}
*/

.formInputsWithScroll {
  height: calc(100% - 70px);
  padding-bottom: 70px;
}
/* .formSectionTitle {
  padding-left: 35px;
  font-size: 11pt;
  font-weight: bold;
  color: #3e87d3;
} */
.formSectionDivider {
  margin: 11px 11px 25px 11px;
}
.formInputLabel {
  font-size: 9pt;
  font-weight: 600;
  color: #271d7a;
  padding-bottom: 5px;
}
.formHeader {
  padding-top: 5px;
  padding-left: 16px;
  padding-bottom: 10px;
  font-size: 14pt;
  font-weight: bold;
  color: #271d7a;
}
.formRemoveIcon {
  position: absolute;
  cursor: pointer;
  right: 0;
  top: 0;
  height: 35px;
  padding-top: 20px;
  padding-right: 20px;
}

.textareaNormal {
  outline: #2e3e91 solid 0.25pt;
  border-radius: 2px;
  padding-left: 8px;
  padding-top: 2px;
  padding-bottom: 2px;
  font-size: 9pt;
  color: #271d7a;
  background-color: #ffffff;
  height: 150px;
}

.bulkUpdateText {
  margin-top: 30px;
}
.inLineWithInputs {
  padding-left: 35px;
}


.file-button {
  position: relative;
  color: #ffffff;
  border: none;
  width: 50%;
}

input.file-input {
  padding: 5px 20px 5px 20px;
  background-color: #3e87d3;
  font-size: 10pt;
  font-weight: 600;
  filter: drop-shadow(1px 1px 1px rgba(39, 29, 122, 0.1));
  color: white;
  border-radius: 2px;
}
.mediumButton:hover {
  outline: #271d7a solid 1pt;
}
.mediumButton:active {
  padding: 5px 20px 5px 20px;
  background-color: #3556a6;
  outline: #2e3e91 solid 0.25pt;
  filter: none;
  box-shadow: 0 0 4px rgba(43, 45, 132, 0.6) inset;
  color: white;
}

.mediumButton.disabled {
  opacity: 0.5;
  pointer-events: none;
}

</style>
