<template>
  <div class="flows">
    <div class="sideDrawer">
      <CreateFlowSideDrawer
        :drawerOpen="createFlow"
        buttonType="Close"
        :numCols="12"
        :width="700"
        :top-aligned="false"
        :questions="questions"
        :editFlow="editFlow"
        :flows="flows"
        :copyFlowValue="copyFlowValueC"
        @close-click="handleCloseClick"
        @form-submission="handleFormSubmission"
        @update-questions="handleUpdateQuestions"
      />
      <FormSideDrawer
        :drawerOpen="this.submissionData.length ? true : false"
        buttonType="Create Flow"
        :drawer-data="
          secondDrawerType == 'Flow' ? flowDrawerData : editDrawerData
        "
        :previousSubmissionData="selectedItemEdit"
        @submissionDataUpdate="handleSubmissionUpdate"
        @close-click="handleCloseClick"
        @form-submission="handleFinalizeSub"
        :num-cols="12"
        :width="300"
        :top-aligned="false"
        :key="localKey"
      />
      <!--All Changes Publish/Undo Drawer-->
      <PublishUndoSideDrawer
        :drawerOpen="publish || undo"
        buttonType="Close"
        :drawerData="getPublishUndoDrawerData"
        @close-click="handleCloseClick"
        @form-submission="handlePublishUndoFormSubmission"
        @confirm-action="handleConfirmAction"
        :previousSubmissionData="submissionData"
        :numCols="12"
        :width="350"
        :top-aligned="false"
      />
      <!--Individual Publish/Undo Drawer-->
      <PublishUndoSideDrawer
        :drawerOpen="publishIndv || undoIndv"
        buttonType="Close"
        :drawerData="getIndvDrawerData"
        @close-click="handleCloseClick"
        @confirm-action="handleIndvAction"
        :previousSubmissionData="submissionData"
        :numCols="12"
        :width="350"
        :top-aligned="false"
      />
    </div>
    <div class="flowsContainer" :style="{ height: dynamicHeight - 20 + 'px' }">
      <TableComp
        :headers="headers"
        :dataList="tableData"
        @select-actions="handleSelectedAction"
        @selected-item="handleSelectedItem"
        @edit-row="handleEditFlow"
        backgroundColor="#e2f1f4"
        :filterOptionsArray="filterOptionsArray"
        :select-all="false"
        :single-select="true"
        unique-key="flowName"
        :leftNavPresent="true"
        :fixed-width="false"
        :clearSingleSelected="clearSelectedItem"
        @click-row="handleEditFlow"
        @partner-selected="handlePartnerSelected"
        :partners="partners"
        :key="localKey"
      ></TableComp>
    </div>
  </div>
</template>
<script>
import TableComp from "@/components/CcManagement/TableCompCC.vue";
import CreateFlowSideDrawer from "@/components/CcManagement/Flows/CreateFlowSideDrawer.vue";
import FormSideDrawer from "@/components/CcManagement/Flows/FormSideDrawer.vue";
import PublishUndoSideDrawer from "@/components/CcManagement/Flows/PublishUndoSideDrawer.vue";
import { useFlowManagerStore } from "@/stores/ContactCenter/Management/FlowManagerStore.js";
import { mapStores, mapActions } from 'pinia';

export default {
  name: "FlowsView",
  data() {
    return {
      editFlow: [],
      copyFlowValue: [],
      submissionData: [],
      currentIndex: 0,
      selectedItemEdit: {},
      selectedItem: [],
      partners: [],
      flowDrawerData: [
        { 
          title: "Create New Flow", 
          type: "form", 
          button: "Create Flow", 
          buttonOptions: {
            positionBottom: true,
            centered: true,
            fillToParent: true,
          }
        },
        {
          title: "",
          inputs: [
            {
              name: "Flow Name",
              value: "flowName",
              type: "bubble",
              required: true,
              
            },
            {
              name: "Queue this Flow Applies To",
              value: "partners_staging",
              type: "select",
              options: [],
              required: true,
              sort_options: false
            },
          ],
        },
      ],
      editDrawerData: [
        { 
          title: "Edit Flow", 
          type: "form", 
          button: "Update Flow",
          buttonOptions: {
            positionBottom: true,
            centered: true,
            fillToParent: true,
          }
        },
        {
          title: "",
          inputs: [
            {
              name: "Flow Name",
              value: "flowName",
              type: "bubble",
              required: true,
              readonly: true,
            },
            {
              name: "Queue this Flow Applies To",
              value: "partners_staging",
              type: "select",
              options: [],
              required: true,
              sort_options: false
            },
          ],
        },
      ],
      flows: [],
      adjustedQueueList: [],
      questions: [
        // {
        //   description: "Are services curretly working?",
        //   type: "Radio Button Fields",
        //   options: [
        //     { label: "Yes", value: "yes" },
        //     { label: "No", value: "no" },
        //   ],
        //   open: false,
        // },
        // {
        //   description: "Result of Tier 1 Troubleshooting:",
        //   type: "radio",
        //   options: [
        //     { label: "RESOLVED", value: "RESOLVED" },
        //     { label: "ESCALATION", value: "ESCALATION" },
        //     { label: "SETUP CALLBACK", value: "SETUP CALLBACK" },
        //   ],
        //   open: false,
        // },
        // {
        //   description: "Are services curretly working?9876",
        //   type: "Radio Button Fields",
        //   options: [
        //     { label: "Yes", value: "yes" },
        //     { label: "No", value: "no" },
        //   ],
        //   open: false,
        // },
        // {
        //   description: "Are services curretly working?124",
        //   type: "Radio Button Fields",
        //   options: [
        //     { label: "Yes", value: "yes" },
        //     { label: "No", value: "no" },
        //   ],
        //   open: false,
        // },
      ],
      sampleFlow: [
        {
          description: "!!!!!!!!  STOP AND READ !!!!!!!!!",
          type: "Display Only",
          open: false,
        },
        {
          description: "Link to ...",
          url: "https://google.com",
          type: "smartLink",
          open: false,
        },
        {
          description: "DISPLAY IN NOTES",
          type: "Display In Notes",
          open: false,
        },
        {
          description: "Text Box",
          type: "Text Box",
          open: false,
        },
        {
          description:
            "Check ALL THE Boxes of verification that member supplied.",
          type: "radio",
          open: false,
          options: [
            {
              label: "Name of Account holder.",
              value: "Name of Account holder.",
              open: false,
            },
            {
              label: "Complete Address including city and state.",
              value: "Complete Address including city and state.",
              open: false,
            },
            { label: "Account number.", value: "Account number." },
            {
              label: "Last 4 of MAC id of RG /ONT",
              open: false,
              value: "Last 4 of MAC id of RG /ONT",
            },
            {
              label: "Member Gave permission to access the account",
              value: "Member Gave permission to access the account",
              open: false,
            },
          ],
          multiSelect: true,
        },
        {
          description: "Is the member fully verified?",
          type: "radio",
          open: false,
          options: [
            {
              label: "Member is Fully Verified",
              value: "Member is Fully Verified",
              open: false,
            },
            {
              label: "Member is Not Fully Verified",
              value: "Member is Not Fully Verified",
              open: false,
            },
          ],
          multiSelect: false,
          dependents: [
            {
              description: "RG IP Addres/Link:",
              type: "bubble",
              dependentOn: "Member is Fully Verified",
              question: "Is the member fully verified?",
              open: false,
            },
            {
              description:
                "If Caller is not verified. Do not open the member's account in any internal tools. Continue with basic troubleshooting.",
              type: "Display Only",
              dependentOn: "Member is Not Fully Verified",
              question: "Is the member fully verified?",
              open: false,
            },
            {
              description: "Input smartLink",
              type: "smartLink",
              dependentOn: "Member is Not Fully Verified",
              question: "Is the member fully verified?",
              open: false,
            },
          ],
        },
        {
          description: "Nested Dependent",
          type: "radio",
          open: false,
          options: [
            {
              label: "Member is Fully Verified",
              value: "Member is Fully Verified",
              open: false,
            },
            {
              label: "Member is Not Fully Verified",
              value: "Member is Not Fully Verified",
              open: false,
            },
          ],
          multiSelect: false,
          dependents: [
            {
              description: "RG IP Addres/Link:",
              type: "radio",
              dependentOn: "Member is Fully Verified",
              question: "Is the member fully verified?",
              open: false,
              options: [
                {
                  label: "Member is Fully Verified",
                  value: "Member is Fully Verified",
                  open: false,
                },
                {
                  label: "Member is Not Fully Verified",
                  value: "Member is Not Fully Verified",
                  open: false,
                },
              ],
              dependents: [
                {
                  description: "SECON DEP",
                  type: "radio",
                  dependentOn: "Member is Fully Verified",
                  question: "Is the member fully verified?",
                  open: false,
                  options: [
                    {
                      label: "Member is Fully Verified",
                      value: "Member is Fully Verified",
                      open: false,
                    },
                    {
                      label: "Member is Not Fully Verified",
                      value: "Member is Not Fully Verified",
                      open: false,
                    },
                  ],
                  dependents: [
                    {
                      description: "THIRD DEP:",
                      type: "radio",
                      dependentOn: "Member is Fully Verified",
                      question: "Is the member fully verified?",
                      open: false,
                      options: [
                        {
                          label: "Member is Fully Verified",
                          value: "Member is Fully Verified",
                          open: false,
                        },
                        {
                          label: "Member is Not Fully Verified",
                          value: "Member is Not Fully Verified",
                          open: false,
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          description:
            "Troubleshooting Steps Performed with Tier 1 Technician:",
          type: "bubble",
          open: false,
        },
        {
          description: "Result of Tier 1 Troubleshooting:",
          type: "radio",
          open: false,
          options: [
            { label: "RESOLVED", value: "RESOLVED" },
            { label: "ESCALATION", value: "ESCALATION" },
            { label: "SETUP CALLBACK", value: "SETUP CALLBACK" },
          ],
          dependents: [
            {
              description: "ISSUE RESOLVED",
              type: "Display In Notes",
              dependentOn: "RESOLVED",
              question: "Result of Tier 1 Troubleshooting:",
              open: false,
            },
            {
              description: "Escalate ticket to Internal Internet",
              type: "Display Only",
              dependentOn: "ESCALATION",
              question: "Result of Tier 1 Troubleshooting:",
              open: false,
            },
            {
              description: "Setup callback according to documented procedure",
              type: "Display Only",
              dependentOn: "SETUP CALLBACK",
              question: "Result of Tier 1 Troubleshooting:",
              open: false,
            },
          ],
        },
      ],
      headers: [
        { text: "Flow name", value: "flowName" },
        { text: "Number of Questions", value: "questions" },
        { text: "Queues", value: "partners_label_staging" },
        { text: "Changed", value: "changed" },
        { text: "Marked for Removal", value: "markedForRemoval" },
        { text: "", value: "edithover",  },
      ],
      data: [
        // {
        //   header: true,
        //   flowName: "FF-170599001",
        //   questions: "139.60.150.242",
        //   partners: "Coosa Valley",
        //   changed: "No",
        //   markedForRemoval: "No",
        // },
        // {
        //   header: true,
        //   flowName: "FF-170599002",
        //   questions: "139.60.150.242",
        //   partners: "Coosa Valley",
        //   changed: "No",
        //   markedForRemoval: "No",
        // },
      ],
      clearSelectedItem: false,
      undoReviewDrawerData: [
        {
          title: "Undo",
          button: "Undo",
          type: "review",
          confirmPhrase: "This will reset the staging environment.",
        },
        {
          title: "",
          details: [
            {
              name: "Please confirm that you wish to reset the staging environment.",
              color: "#271d7a",
              type: "detail",
            },
            {
              name: "This will undo all unpublished changes in staging.",
              color: "#e89032",
              weight: 700,
            },
          ],
        },
        {
          title: "Confirm Undo",
          details: [
            {
              name: "Enter the following phrase to confirm the reset.*",
              color: "#271d7a",
              type: "detail",
            },
          ],
        },
      ],
      localKey: 0,
      localSelectedFlow: {},
      selectedQueue: ""
    };
  },
  props: {
    filterOptionsArray: Array,
    dynamicHeight: Number,
    markedForRemoval: Array,
    unmarkForRemoval: Array,
    createFlow: Boolean,
    searchValue: String,
    publish: Boolean,
    undo: Boolean,
    publishIndv: Boolean,
    undoIndv: Boolean,
    copyFlow: Boolean,
    secondDrawerType: String,
  },
  async mounted() {
    await this.getQuestions();
    await this.getFlows();
    var flowData = this.FlowManagerStore.fetchFlows;
    await this.updateDataWithOptions(flowData);

    this.flows = this.data;

    this.flowDrawerData = [
        { 
          title: "Create New Flow", 
          type: "form", 
          button: "Create Flow" ,
          buttonOptions: {
            positionBottom: true,
            centered: true,
            fillToParent: true,
          }
        },
        {
          title: "",
          inputs: [
            {
              name: "Flow Name",
              value: "flowName",
              type: "bubble",
              required: true,
            },
            {
              name: "Queue this Flow Applies To",
              value: "partners_staging",
              type: "select",
              options: this.partners,
              required: true,
              sort_options: false
            },
          ],
        },
    ];
    this.editDrawerData = [
        { title: "Edit Flow", type: "form", button: "Update Flow" },
        {
          title: "",
          inputs: [
            {
              name: "Flow Name",
              value: "flowName",
              type: "bubble",
              required: true,
              readOnly: true,
            },
            {
              name: "Queue this Flow Applies To",
              value: "partners_staging",
              type: "select",
              options: this.partners,
              required: true,
              sort_options: false
            },
          ],
        },
    ];

    this.handleUpdatePartnerList();
  },
  computed: {
    ...mapStores(useFlowManagerStore),
    tableData() {
      var fullData = this.data;
      if(this.selectedQueue != "") {
        fullData = fullData.filter((record) => {
          return record.allowed_queues_staging == this.selectedQueue;
        });
      }

      return fullData.filter((item) => {
        return Object.values(item).some((value) =>
          String(value)
            .toLowerCase()
            .trim()
            .includes(this.searchValue?.toLowerCase().trim())
        );
      });
    },
    getPublishUndoDrawerData() {
      if (this.publish && !this.undo) {
        return this.publishReviewDrawerData;
      } else if (!this.publish && this.undo) {
        return this.undoReviewDrawerData;
      }
      return [];
    },
    getIndvDrawerData() {
      if (this.publishIndv && !this.undoIndv) {
        return [
          {
            title: "Publish",
            button: "Publish",
            type: "review",
            confirmPhrase:
              "This will publish changes for the staging flow.",
          },
          {
            title: "",
            details: [
              {
                name: "Please confirm that you wish to publish the changes for this flow.",
                color: "#271d7a",
                type: "detail",
              },
            ],
          },
          {
            title: "Confirm Publish",
            details: [
              {
                name: "Enter the following phrase to confirm the publish.*",
                color: "#271d7a",
                type: "detail",
              },
            ],
          },
        ];
      } else if (!this.publishIndv && this.undoIndv) {
        return [
          {
            title: "Undo",
            button: "Undo",
            type: "review",
            confirmPhrase: "This will reset the staging flow.",
          },
          {
            title: "",
            details: [
              {
                name: "Please confirm that you wish to reset the staging flow.",
                color: "#271d7a",
                type: "detail",
              },
            ],
          },
          {
            title: "Confirm Undo",
            details: [
              {
                name: "Enter the following phrase to confirm the reset.*",
                color: "#271d7a",
                type: "detail",
              },
            ],
          },
        ]
      }
      return [];
    },
    copyFlowValueC() {
      if (this.copyFlow) {
        return this.copyFlowValue;
      } else return [];
    },
    markedForRemovalNumber() {
      const itemsMarkedForRemoval = this.data.filter((dataItem) => {
        return dataItem.markedForRemoval === "Yes";
      });

      return itemsMarkedForRemoval.length;
    },
    publishReviewDrawerData() {
      return [
        {
          title: "Publish",
          button: "Publish",
          type: "review",
          confirmPhrase:
            "This will publish all changes to flows and questions.",
        },
        {
          title: "",
          details: [
            {
              name: "Please confirm that you wish to publish all changes to flows and questions.",
              color: "#271d7a",
              type: "detail",
            },
            {
              name: `This will remove ${this.markedForRemovalNumber} flow(s) from the live environment.`,
              color: "#e89032",
              weight: 700,
              markedForRemoval: this.markedForRemovalNumber,
            },
          ],
        },
        {
          title: "Confirm Publish",
          details: [
            {
              name: "Enter the following phrase to confirm the publish.*",
              color: "#271d7a",
              type: "detail",
            },
          ],
        },
      ];
    },
  },
  methods: {
    ...mapActions(useFlowManagerStore, ['getFlows', 'getQuestions']),
    handleSubmissionUpdate(data) {
      console.warn(data);
      if(typeof data.partners_staging != "undefined" && data.partners_staging.label) {
        this.handleUpdatePartnerList(data.partners_staging.label);
      }
    },
    handleUpdatePartnerList(selectedQueue="") {
      var alreadyUsedQueues = this.data.map((flow) => flow.partners_label_staging);
      this.questions = this.FlowManagerStore.fetchQuestions;

      var partnerData = this.FlowManagerStore.fetchQueuesOptionArray;
      var tempValue = [];
      var tempValueAdjusted = [];
      var newValue = [];
      var newValueAdjusted = [];
      for(let queue of partnerData) {
          if(queue.label == "All") {
            tempValue.push(queue);
          }
          else if(['Work in Progress', 'Supporting Flow'].includes(queue.label)) {
            tempValue.push(queue);
            tempValueAdjusted.push(queue);
          } else {
            //Check if we are editing or creating a queue
            if(this.submissionData.length) {
              var recordQueue = "";
              if(this.selectedItemEdit.partners_staging) {
                recordQueue = this.selectedItemEdit.partners_staging;
              }

              //If we have new selected queue, then that queue must not be added to the list
              if(selectedQueue == queue.label) {
                newValue.push(queue);
                continue;
              }
              //If the queue is not currently selected, and it is the original queue for the record, it needs to be added to the list
              if(selectedQueue != queue.label && recordQueue == queue.label) {
                newValueAdjusted.push(queue);
                newValue.push(queue);
                continue;
              }

              //Standard Check
              if(alreadyUsedQueues.indexOf(queue.label) == -1) {
                newValueAdjusted.push(queue);
              }
              newValue.push(queue);
            } else {
              if(alreadyUsedQueues.indexOf(queue.label) == -1) {
                newValueAdjusted.push(queue);
              }
              newValue.push(queue);
            }
          }
      }

      tempValue = tempValue.sort((a, b) => a.label.localeCompare(b.label));
      newValue = newValue.sort((c, d) => c.label.localeCompare(d.label));
      tempValueAdjusted = tempValueAdjusted.sort((a, b) => a.label.localeCompare(b.label));
      newValueAdjusted = newValueAdjusted.sort((c, d) => c.label.localeCompare(d.label));
      this.partners = [...tempValue, ...newValue];
      this.adjustedQueueList = [...tempValueAdjusted, ...newValueAdjusted];
      
      this.flowDrawerData[1].inputs.find(obj => obj.value == "partners_staging").options = [...tempValueAdjusted, ...newValueAdjusted];
      this.editDrawerData[1].inputs.find(obj => obj.value == "partners_staging").options = [...tempValueAdjusted, ...newValueAdjusted];

    },
    handlePartnerSelected(data) {
      if(data.label == "All") {
        this.selectedQueue = "";
      } else {
        this.selectedQueue = data.label;
      }
    },
    handleUpdateQuestions() {
      this.questions = this.FlowManagerStore.fetchQuestions;
    },
    async updateDataWithOptions(data) {
      var updatedData = [];

      var options = [
        { label: "View", value: "view" },
        { label: "Edit", value: "edit" },
        { label: "Copy", value: "copy" },
      ];
      
      for(var dataItem of data) {
        var tempItem = {};
        var tempOptions = [...options];
        var publishedAdded = false;

        if(dataItem.markedForRemoval == "Yes" || dataItem.changed == "Yes") {
          tempOptions.push({ label: "Publish", value: "publish" });
          publishedAdded = true;
        
          if(dataItem.currentlyPublished == "Yes") {
            tempOptions.push({ label: "Undo", value: "undo" }); 
          }
        }

        if(dataItem.currentlyPublished == "No" && publishedAdded == false) {
          tempOptions.push({ label: "Publish", value: "publish" });
        }

        if(dataItem.markedForRemoval == "No") {
          tempOptions.push({ label: "Remove", value: "remove" });
        }

        if(dataItem.markedForRemoval == "Yes") {
          tempOptions.push({ label: "Undo Remove", value: "undoRemove" });
        }

        tempItem = {...dataItem, ...{options: tempOptions}};
        updatedData.push(tempItem);
      }

      this.data = updatedData;
    },
    async handleConfirmAction(success) {
      if (success) {
        if (this.publish) {
          this.$emit("add-alert", "Notification", "Started publishing flows");
          this.$emit("publish", this.tableData);
          await this.FlowManagerStore.publishFlowsToProduction();
          var publishData = this.FlowManagerStore.fetchFlows;
          await this.updateDataWithOptions(publishData);
          await this.handleUpdatePartnerList();
          this.$emit("add-alert", "Success", "Successfully published flows");
        } else {
          this.$emit("add-alert", "Notification", "Successfully removed changes");
          this.$emit("undo", this.tableData);
          await this.FlowManagerStore.overwriteStagingFlowsFromProduction();
          var flowData = this.FlowManagerStore.fetchFlows;
          await this.updateDataWithOptions(flowData);
          this.handleUpdatePartnerList();
          this.$emit("add-alert", "Success", "Successfully removed changes");
        }
      }
    },
    async handleIndvAction(success) {
      if(success) {
        var id = this.localSelectedFlow.id;
        if(this.publishIndv) {
          this.$emit("add-alert", "Notification", "Publishing Flow");
          this.$emit("publishIndv", this.tableData);
          await this.FlowManagerStore.publishIndividualFlowToProduction(id);
          var indvFlowData = this.FlowManagerStore.fetchFlows;
          await this.updateDataWithOptions(indvFlowData);
          this.handleUpdatePartnerList();
          this.$emit("add-alert", "Notification", "Successfully Published Flow");
        } else {
          this.$emit("add-alert", "Notification", "Removing Flow Changes");
          this.$emit("undoIndv", this.tableData);
          await this.FlowManagerStore.overwriteIndividualStagingFlowFromProduction(id);
          var flowData = this.FlowManagerStore.fetchFlows;
          await this.updateDataWithOptions(flowData);
          this.handleUpdatePartnerList();
          this.$emit("add-alert", "Notification", "Successfully Removed Changes");
        }
      }
    },

    handleSelectedItem(selectedItem) {
      this.selectedItem = selectedItem;
      this.$emit("selected-item", selectedItem);
    },
    handleCloseClick() {
      this.editFlow = {};
      this.submissionData = {};
      this.localSelectedFlow = {};
      this.selectedItemEdit = [];
      this.handleUpdatePartnerList();
      this.$emit("close-click");
    },
    reformatDataEntry(row) {
      let formattedRow = JSON.parse(JSON.stringify(row));
      for (let key in row) {
        // let value = formattedRow[key];
        if (key === "flowName" && this.copyFlow) {
          delete formattedRow[key];
        }
      }
      return formattedRow;
    },
    handleSelectedAction(item, action) {
      var title = "";

      title = action.label;
      this.localSelectedFlow = item;

      if(['View', 'Copy', 'Edit', 'Remove', 'Undo Remove', 'Publish', 'Undo'].includes(title)) {
        //Handle the action
        if(title == "Edit") {
          this.handleEditFlow(item);
        } else {
          //Set the selected item, like a checkbox
          if(title == 'Publish' || title == 'Undo') {
            title = `${title} Indv`;
          }
          this.selectedItem = item;
          this.$emit("selected-item", item);
          this.$emit("action-triggered", title);
        }
      }
    },
    handleEditFlow(flow) {

      this.submissionData = flow;
      this.selectedItemEdit = this.reformatDataEntry(flow);
      this.editFlow = flow.staging_json;
      // this.editFlow = this.sampleFlow;
      this.$emit("open-drawer");
    },
    handleFormSubmission(flow) {
      if (flow === "Error") {
        this.$emit("add-alert", "Error", "Please Create a Flow");
      } else {
        this.submissionData = flow;

        if (this.editFlow.length && !this.copyFlow) {
          this.$emit("editFlowSuccess");
        } else {
          this.$emit("createFlowSuccess");
        }
      }
    },
    handlePublishUndoFormSubmission(flow) {
      if (flow === "Error") {
        this.$emit("add-alert", "Error", "Please Create a Flow");
      } else {
        this.submissionData = flow;

        if (this.editFlow.length && !this.copyFlow) {
          this.$emit("editFlowSuccess");
        } else {
          this.$emit("createFlowSuccess");
        }
      }
    },
    async handleFinalizeSub(secondFormData) {
      if (secondFormData === "Error") {
        this.$emit("add-alert", "Error", "Missing Required Values");
      } else {
        var flow = this.submissionData;
        var name = secondFormData.flowName;
        var allowed_queues = "";
        
        if(secondFormData.partners_staging){
          allowed_queues = secondFormData.partners_staging.label;
        }
        else if (secondFormData["Queue this Flow Applies To"]){
          allowed_queues = secondFormData["Queue this Flow Applies To"].label;
        }
        
        if (this.secondDrawerType === "Flow") {
          await this.FlowManagerStore.createFlow(allowed_queues, name, flow);
          var flowData = this.FlowManagerStore.fetchFlows;
          await this.updateDataWithOptions(flowData);
          this.handleUpdatePartnerList();
          this.submissionData = {};
          this.$emit("createFlowSuccess");
          this.clearSelectedItem = true;
          this.$emit("selected-item", []);
          this.$emit("add-alert", "Success", "Successfully Created Flow");
        } else if (this.secondDrawerType === "Edit") {
          var id = secondFormData.id;
          var version = secondFormData._version;

          console.log("UPDATING THE FLOW");
          console.log(flow);

          await this.FlowManagerStore.updateFlow(id, allowed_queues, name, flow, version);
          var editData = this.FlowManagerStore.fetchFlows;
          await this.updateDataWithOptions(editData);
          this.handleUpdatePartnerList();
          this.submissionData = {};
          this.$emit("editFlowSuccess");
          this.clearSelectedItem = true;
          this.$emit("selected-item", []);
          this.$emit("add-alert", "Success", "Successfully Edited Flow");
        }
      }
    }
  },
  watch: {
    data() {
      this.localKey = this.localKey + 1;
    },
    async markedForRemoval(marked) {
      await this.FlowManagerStore.removeFlow(marked.id, marked._version);
      var flowData = this.FlowManagerStore.fetchFlows;
      await this.updateDataWithOptions(flowData);
      this.handleUpdatePartnerList();
      // this.data.forEach((dataItem) => {
      //   if (marked.flowName === dataItem.flowName) {
      //     dataItem.markedForRemoval = "Yes";
      //   }
      // });
      this.$emit("add-alert", "Success", "Flow marked for removal");
      this.clearSelectedItem = true;
      this.$emit("selected-item", []);
    },
    async unmarkForRemoval(marked) {
      await this.FlowManagerStore.unRemoveFlow(marked.id, marked._version);
      var flowData = this.FlowManagerStore.fetchFlows;
      await this.updateDataWithOptions(flowData);
      this.handleUpdatePartnerList();
      // this.data.forEach((dataItem) => {
      //   if (marked.flowName === dataItem.flowName) {
      //     dataItem.markedForRemoval = "No";
      //   }
      // });
      this.$emit("add-alert", "Success", "Flow no longer marked for removal");
      this.clearSelectedItem = true;
      this.$emit("selected-item", []);
    },
    createFlow(newVal) {
      if (!newVal) {
        this.editFlow = [];
      }
    },
    copyFlow(newVal) {
      if (newVal) {
        this.copyFlowValue = this.selectedItem.staging_json;
        // this.copyFlowValue = this.sampleFlow;
        this.selectedItemEdit = this.reformatDataEntry(this.selectedItem);
        this.$emit("open-drawer");
      } else {
        this.$emit("clse-cliock");
      }
    },
    clearSelectedItem(newVal) {
      if (newVal) {
        this.$nextTick(() => {
          this.clearSelectedItem = false;
        });
      }
    },
  },
  components: {
    TableComp,
    CreateFlowSideDrawer,
    FormSideDrawer,
    PublishUndoSideDrawer,
  },
  emits: [
    "selectedItem",
    "editRow",
    "publish",
    "undo",
    "open-drawer",
    "closeClick",
    "close-click",
  ],
};
</script>
<style scoped>
.flowsContainer {
  padding: 20px;
  /* margin-left: auto; */
  /* margin-right: auto; */
  width: 100%;
  background-color: #e2f1f4;
}
.flows :deep(.sectionTitle) {
  color: #3e87d3!important;
}

.flowsContainer :deep(.customize-table) {
  overflow: auto;
  width: 100% !important;
}
.flows :deep(.cont) {
  margin-right: auto;
  margin-left: 16px;
}

:deep(.customize-table) {
  padding-bottom: 140px;
}
</style>
