<template>
    <div class="flowsContainer">
        <LightButton v-if="selectedQueue != null" @click.prevent="handleBack()">Back To Dashboard</LightButton>
        <div :style="{ height: PROPS.dynamicHeight - 100 + 'px' }">
            <RealTimeQueueDash v-if="selectedQueue != null" :queue="selectedQueue" />
            <TableComponent v-else :table_headers="headers" :table_data="queues">
                <template #item-name="item">
                    <span class="queueName" @click="handleSelectedQueue(item)">{{ item.name }}</span>
                </template>
                <template #item-active-calls="item">
                    <span> {{ item.stats.active_session_count }}</span>
                </template>
            </TableComponent>
        </div>
    </div>
</template>

<script setup>
import { computed, defineProps, ref } from 'vue';
import { useQubicleRealtimeStore } from '@/stores/ContactCenter/Management/QubicleRealtimeStore';
import RealTimeQueueDash from './Queue/RealTimeQueueDash2.vue';
import TableComponent from '@/components/base_components/TableComponent.vue';
import LightButton from '@/components/base_components/Buttons/LightButton.vue';

const QubicleRealtimeStore = useQubicleRealtimeStore();
const selectedQueue = ref(null);

const PROPS = defineProps({
    dynamicHeight: Number,
})

var headers = [
    { text: "Queue Name", value: "name", sortable: false },
    { text: "Active Calls", value: "stats.active_calls", sortable: false },
    { text: "Calls in Queue", value: "stats.calls_in_queue", sortable: false },
    { text: "Current Hold", value: "stats.estimated_wait", sortable: false },
    { text: "Longest Hold Today", value: "stats.longest_wait", sortable: false },
    { text: "Agents Ready", value: "stats.ready", sortable: false },
    { text: "Agents Away", value: "stats.away", sortable: false },
];

const queues = computed(() => {
    let queues = QubicleRealtimeStore.$state.queues;

    let sorted = Object.values(queues).sort((a, b) => {
        return a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1;
    })

    return sorted;
})

async function handleSelectedQueue(queue) {
    selectedQueue.value = queue;
}

async function handleBack() {
    selectedQueue.value = null;
}

</script>

<style scoped>
.flowsContainer {
    padding: 20px;
    width: 100%;
    background-color: #fcfdfe;
    height: 100%;
}

.queueConsole {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 5px;
    font-family: "Red Hat Display Bold", sans-serif;
    font-size: 12px;
    color: #271d7a;
    width: 100%;
    justify-items: center;
    align-items: center;
    text-align: center;
}

.queueCard {
    display: flex;
    flex-direction: column;
    gap: 5px;
    border-color: #271d7a;
    border-style: solid;
    border-width: 2pt;
    justify-content: left;
    align-items: left;
    text-align: left;
    vertical-align: middle;
    padding: 10px;
    border-radius: 1pt;
    width: 225px;
}

.queueCardRow {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 12px;
    width: 100%;
    align-items: center;
}

.queueName {
    font-size: 9pt;
    font-family: "Red Hat Display Bold", sans-serif;
    font-weight: 600;
}

.tableIcons {
    width: 28px;
    padding-right: 15px;
}

.tableText {
    color: #271d7a;
    font-size: 12pt;
}

.tooltip {
    position: relative;
    display: inline-block;
    bottom: 100;
    width: 100%;
    /* border-bottom: 1px dotted black; */
}

.tooltip .tooltiptext {
    visibility: hidden;
    width: 100px;
    border-color: #271d7a;
    border-style: solid;
    color: #271d7a;
    font-family: "Red Hat Display Bold", sans-serif;
    font-size: 10pt;
    font-weight: 400;
    background-color: #fff;
    text-align: center;
    border-radius: 1pt;
    padding: 5px 0;

    /* Position the tooltip */
    position: absolute;
    z-index: 1;
}

.tooltip:hover .tooltiptext {
    visibility: visible;
    bottom: 0;
}

.dot {
    height: 25px;
    width: 25px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
}
</style>