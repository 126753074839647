<template>
  <div class="disciplinaryActionsContainer">
    <ManagementSideDrawer
      :drawer-open="localDrawer"
      buttonType="Close"
      :numCols="12"
      @close-click="handleCloseClick"
      :drawer-data="getDrawerData"
      @form-submission="handleSubmit"
      :previousSubmissionData="preSubmissionData"
      :readonly="isLocalReadonly"
    />

    <div class="tableContainer">
      <TableComp
        :headers="getTableHeaders"
        :data-list="getTableData"
        backgroundColor="#e2f1f4"
        :single-select="true"
        :select-all="false"
        uniqueKey="id"
        :left-nav-present="true"
        :fixedWidth="false"
        @selected-item="handleSelectedItem"
        @edit-row="handleEditRow"
        @click-row="handleEditRow"
      />
    </div>
    <AlertsGroup
      v-model:alerts="alerts"
    />
  </div>
</template>

<script>
import TableComp from "@/components/base_components/TableComp.vue";
import ManagementSideDrawer from "@/components/Voice/Management/SideDrawer/ManagementSideDrawer.vue";
import AlertsGroup from '@/components/base_components/AlertsGroup.vue';
import { CONFIRM_REMOVE, ERROR, SUCCESS, UPDATE } from '@/constants/statuses.constants.js';
import {
  ADD_DISCIPLINARY_ACTION,
  DISCIPLINARY_CREATE_ALERT,
  DISCIPLINARY_REMOVE_ALERT,
  DISCIPLINARY_UPDATE_ALERT,
  EDIT_DISCIPLINARY_ACTION,
  REMOVE_DISCIPLINARY_ACTION
} from '@/constants/pages/disciplinary-action.constants.js'
import { FORM_DRAWER_TYPE } from '@/constants/drawer.constants';
import { MISSING_FIELDS } from '@/constants/alerts.constants';
import { formatTableDate } from '@/helpers/dateUtils';
import { addAlert } from '@/helpers/alertUtils';

import { useCCManagementAttendanceStore } from "@/stores/ContactCenter/Management/CCManagementAttendanceStore.js";
import { mapStores } from 'pinia';

export default {
  name: 'DisciplinaryActionsView',
  components: {
    TableComp,
    ManagementSideDrawer,
    AlertsGroup
  },
  props: {
    drawer: {
      type: Boolean,
      default: false
    },
    create: {
      type: Boolean,
      default: false
    },
    update: {
      type: Boolean,
      default: false
    },
    isRemove: {
      type: Boolean,
      default: false
    },
    remove: {
      type: Boolean,
      default: false
    },
    search: {
      type: String,
      default: ''
    },
    isAdmin: {
      type: Boolean,
      default: false
    },
    isReadonly: {
      type: Boolean,
      default: false
    }
  },
  data () {
    const sideDrawerForm = [
      {
        name: "Date",
        type: "datePicker",
        value: 'date',
        // fullWidth: true,
        required: true,
      },
      { 
        name: "Employee",
        value: 'employee',
        type: "select",
        // fullWidth: true,
        options: [
          // { label: "Kia Hodge", value: "Kia Hodge" },
          // { label: "Jeremy Gould", value: "Jeremy Gould" },
          // { label: "Carin Mcgarvey", value: "Carin Mcgarvey" },
          // { label: "John Popa", value: "John Popa" },
          // { label: "Erik Chauca", value: "Erik Chauca" },
          // { label: "Courtney Dykes", value: "Courtney Dykes" },
          // { label: "Stephen Bowlin", value: "Stephen Bowlin" },
          // { label: "John Doe", value: "john_doe" },
          // { label: "Jane Smith", value: "jane_smith" },
          // { label: "Alex Johnson", value: "alex_johnson" },
          // { label: "Emily Davis", value: "emily_davis" },
        ],
        required: true,
      },
      { 
        name: "Disciplinary Action",
        value: 'disciplinary_action',
        // fullWidth: true,
        type: "select",
        options: [
          {label: "Verbal Warning", value: "verbal_warning" },
          { label: "Written Warning", value: "written_warning" },
          { label: "Final Warning", value: "final_warning" },
          { label: "Termination", value: "termination" }
        ],
        required: true,
      },
      {
        name: "Documentation",
        type: "textarea",
        value: "documentation",
        required: true,
        fullWidth: true,
        readonly: false,
      }
    ]

    return {
      tableHeaders: [
        { text: "Date", value: "date" },
        { text: "Employee", value: "employee" },
        { text: "Disciplinary Action", value: "disciplinary_action" },
        { text: "Documentation", value: "documentation" },
      ],
      tableData:  [
        // {
        //   id: 1,
        //   date: "2022-09-06",
        //   employee: 'John Doe',
        //   disciplinary_action: 'Some infraction',
        //   documentation: 'Some documentation'
        // },
        // {
        //   id: 2,
        //   date: "2023-09-07",
        //   employee: 'John Doe 1',
        //   disciplinary_action: 'Some infraction 1',
        //   documentation: 'Some documentation 1'
        // }
      ],
      // createFormData: [
      //   { title: ADD_DISCIPLINARY_ACTION, button: ADD_DISCIPLINARY_ACTION, type: FORM_DRAWER_TYPE },
      //   { title: '', inputs: sideDrawerForm }
      // ],
      // updateFormData: [
      //   { title: EDIT_DISCIPLINARY_ACTION, button: UPDATE, type: FORM_DRAWER_TYPE },
      //   { title: '', inputs: sideDrawerForm }
      // ],
      removeFormData: [
        { title: REMOVE_DISCIPLINARY_ACTION, button: CONFIRM_REMOVE, type: FORM_DRAWER_TYPE },
        { title: '', inputs: sideDrawerForm }
      ],

      alerts: [],
      submissionData: {},
      isSelectedRow: false,
      transformedResultOptions: []
    }
  },
  async mounted() {
    let resultOptions = await this.CCManagementAttendanceStore.getEmployeeInformationOptions();

    this.transformedResultOptions = resultOptions.map(name => ({
      label: name,
      value: name,
    }));

    console.log("this.transformedResultOptions", this.transformedResultOptions);

    let result = await this.CCManagementAttendanceStore.getEmployeeInformation();
    console.log("mounted result: ", result);

    if (result) {
      this.tableData = this.CCManagementAttendanceStore.getEmployees.map(employee => {
        return {
          id: employee.id,
          date: employee.date,
          employee: employee.employee ? employee.employee.name : 'N/A',
          disciplinary_action: employee.action,
          documentation: employee.documentation,
        };
      });
      console.log("this.tableData", this.tableData)
    } else {
      console.error("Failed to fetch work partners: ", result);
    }

  },
  watch: {
    'CCManagementAttendanceStore.getEmployees': {
      deep: true,
      handler(newEmployees) {
        this.tableData = newEmployees.map(employee => {
          return {
            id: employee.id,
            date: employee.date,
            employee: employee.employee ? employee.employee.name : 'N/A',
            disciplinary_action: employee.action,
            documentation: employee.documentation,
          };
        });
      },
    },
  },
  computed: {
    ...mapStores(useCCManagementAttendanceStore),
    localDrawer: {
      get () {
        return this.drawer;
      },
      set (drawer) {
        this.$emit('update:drawer', drawer);
      }
    },
    isCreateForm: {
      get () {
        return this.create;
      },
      set (create) {
        this.$emit('update:create', create);
      }
    },
    isUpdateForm: {
      get () {
        return this.update;
      },
      set (update) {
        this.$emit('update:update', update);
      }
    },
    isRemoveForm: {
      get () {
        return this.remove;
      },
      set (remove) {
        this.$emit('update:remove', remove);
      }
    },
    localIsRemove: {
      get () {
        return this.isRemove;
      },
      set (isRemove) {
        this.$emit('update:isRemove', isRemove);
      }
    },
    isLocalReadonly: {
      get () {
        return this.isReadonly
      },
      set (isReadonly) {
        this.$emit('update:isReadonly', isReadonly)
      }
    },
    searchResult: {
      get () {
        return this.search;
      },
      set (search) {
        this.$emit('update:search', search);
      }
    },
    getDrawerData() {
      if (this.isCreateForm) {
        const createSideDrawerForm = [
          {
            name: "Date",
            type: "datePicker",
            value: 'date',
            // fullWidth: true,
            required: true,
          },
          {
            name: "Employee",
            value: 'employee',
            type: "select",
            // fullWidth: true,
            options: this.transformedResultOptions,
            required: true,
          },
          {
            name: "Disciplinary Action",
            value: 'disciplinary_action',
            type: "select",
            // fullWidth: true,
            options: [
              { label: "Verbal Warning", value: "verbal_warning" },
              { label: "Written warning", value: "written_warning" },
              { label: "Probation", value: "probation" },
              { label: "Suspension", value: "suspension" },
              { label: "Demotion", value: "demotion" },
              { label: "Termination", value: "termination" },
              { label: "Restitution", value: "restitution" },
              { label: "Community Service", value: "community_service" },
              { label: "Expulsion", value: "expulsion" },
            ],
            required: true,
          },
          {
            name: "Documentation",
            type: "textarea",
            value: "documentation",
            required: true,
            fullWidth: true,
            readonly: false,
          }
        ];

        return [
          { title: ADD_DISCIPLINARY_ACTION, button: ADD_DISCIPLINARY_ACTION, type: FORM_DRAWER_TYPE },
          { title: '', inputs: createSideDrawerForm },
        ];
      }

      if (this.isRemoveForm) {
        return this.removeFormData;
      }

      const updateSideDrawerForm = [
          {
            name: "Date",
            type: "datePicker",
            value: 'date',
            // fullWidth: true,
            required: true,
          },
          {
            name: "Employee",
            value: 'employee',
            type: "select",
            // fullWidth: true,
            options: this.transformedResultOptions,
            required: true,
          },
          {
            name: "Disciplinary Action",
            value: 'disciplinary_action',
            type: "select",
            // fullWidth: true,
            options: [
              { label: "Verbal Warning", value: "verbal_warning" },
              { label: "Written warning", value: "written_warning" },
              { label: "Probation", value: "probation" },
              { label: "Suspension", value: "suspension" },
              { label: "Demotion", value: "demotion" },
              { label: "Termination", value: "termination" },
              { label: "Restitution", value: "restitution" },
              { label: "Community Service", value: "community_service" },
              { label: "Expulsion", value: "expulsion" },
            ],
            required: true,
          },
          {
            name: "Documentation",
            type: "textarea",
            value: "documentation",
            required: true,
            fullWidth: true,
            readonly: false,
          }
        ];

        return [
          { title: EDIT_DISCIPLINARY_ACTION, button: UPDATE, type: FORM_DRAWER_TYPE },
          { title: '', inputs: updateSideDrawerForm },
        ];
    },
    // getDrawerData () {
    //   if (this.isCreateForm) {
    //     return this.createFormData;
    //   }
      
    //   if (this.isRemoveForm) {
    //     return this.removeFormData;
    //   }

    //   return this.updateFormData;
    // },

    getTableData () {
      return this.filteredData.map(item => {
        return { header: true, ...item }
      })
    },
    getTableHeaders () {
      const adminHeaders = [
        { text: "", value: "checkbox", width: 23.5, fixed: true },  
        ...this.tableHeaders,
        { text: "", value: "edithover", width: 23.95 }
      ];

      return this.isAdmin ? adminHeaders : this.tableHeaders
    },

    filteredData () {
      if (this.searchResult !== '') {
        return this.tableData.filter(item => {
          return Object.values(item).some(fieldValue =>
            String(fieldValue).toLowerCase().includes(this.searchResult.toLowerCase())
          );
        });
      }
      
      return this.tableData
    }, 

    preSubmissionData () {
      if (this.isCreateForm) {
        return {}
      }

      return this.submissionData;
    }
  },
  methods: {
    handleSelectedItem(selectedItem) {
      if (selectedItem !== null) {
        this.localIsRemove = true
        const { date, documentation, employee, disciplinary_action, id } = selectedItem;
        this.localIsRemove = true;
        this.isRemoveForm = false;
        this.submissionData = {
          id,
          date: formatTableDate(date),
          employee,
          disciplinary_action,
          documentation,
        };
        this.isSelectedRow = true;
      } else {
        this.localIsRemove = false;
        this.isRemoveForm = false;
        this.isSelectedRow = false;
      }
      
    },
    handleEditRow ({ id, date, documentation, employee, disciplinary_action }) {
      this.localDrawer = true;
      this.isRemoveForm = false;
      this.isUpdateForm = true;

      this.submissionData = {
        id,
        date: formatTableDate(date),
        employee,
        disciplinary_action,
        documentation
      };
    },
    handleCloseClick () {
      this.localDrawer = false;
      this.isCreateForm = false;
      this.isUpdateForm = false;
      this.isLocalReadonly = false;
    },

    async handleCreateForm(submissionData) {
      if (submissionData !== ERROR) {
        const { date, employee, disciplinary_action, documentation } = submissionData

        let result = await this.CCManagementAttendanceStore.addEmployee(date, employee.label, disciplinary_action.label, documentation);
        if (result) {
          addAlert(SUCCESS, DISCIPLINARY_CREATE_ALERT, this.alerts);
          await this.CCManagementAttendanceStore.getEmployeeInformation();
          this.localDrawer = false;
          if (!this.isSelectedRow) {
            this.submissionData = {};
          }
          this.isCreateForm = false;
        } else {
          addAlert(ERROR, "Failed to create action", this.alerts)
        }
      } else {
        addAlert(ERROR, MISSING_FIELDS, this.alerts);
      }
    },


    async handleRemoveForm (submissionData) {
      if (submissionData !== ERROR) {
        const { id } = submissionData;

        let result = await this.CCManagementAttendanceStore.removeEmployee(id);
        if (result && !result.error) {
          addAlert(SUCCESS, DISCIPLINARY_REMOVE_ALERT, this.alerts);
          await this.CCManagementAttendanceStore.getEmployeeInformation();
          this.localDrawer = false;
          this.isRemoveForm = false;
          this.isLocalReadonly = false;
          this.localIsRemove = false
        } else {
          addAlert(ERROR, result.message, this.alerts);
        }
      } else {
        addAlert(ERROR, MISSING_FIELDS, this.alerts);
      } 
    },
    async handleUpdateForm (submissionData) {
      if (submissionData !== ERROR) {
        const { id, date, employee, disciplinary_action, documentation } = submissionData;
        
        let result = await this.CCManagementAttendanceStore.editEmployee(id, date, employee, disciplinary_action, documentation);

        if (result && !result.error) {
          addAlert(SUCCESS, DISCIPLINARY_UPDATE_ALERT, this.alerts);   
          await this.CCManagementAttendanceStore.getEmployeeInformation();      
          this.localDrawer = false;
          this.isRemoveForm = false;
          this.isLocalReadonly = false
        } else {
          addAlert(ERROR, "Unable to update", this.alerts);
        }
      } else {
        addAlert(ERROR, MISSING_FIELDS, this.alerts);
      } 
    },
    handleSubmit(submissionData) {
      if (this.isCreateForm) {
        this.handleCreateForm(submissionData);
      } else if (this.isRemoveForm) {
        this.handleRemoveForm(submissionData);
      } else if (this.isUpdateForm) {
        this.handleUpdateForm(submissionData);
      }
    },

    handleSearch (value) {
      this.searchResult = value;
    },
    formatSelect (item) {
      if (typeof item === 'string') {
        return item
      }

      return item?.label
    }
  },
  unmounted () {
    this.isLocalReadonly = false;
    this.localDrawer = false;
    this.isCreateForm = false;
    this.isUpdateForm = false;
    this.localIsRemove = false;
  },
  emits: [
    "update:drawer",
    "update:create",
    "update:update",
    "update:remove",
    "update:isRemove",
    "update:isReadonly",
    "update:search"
  ]
}
</script>

<style scoped>
.pageContent {
  flex-grow: 1;
}
.mainContainer {
  display: flex;
  background-color: #e2f1f4;
  height: 100%;
}
.mainContent {
  display: flex;
  flex-grow: 1;
  position: relative;
}

.tableContainer {
  height: 100%;
  width: 90%;
  margin-left: 20px;
  margin-top: 20px;
  background-color: #e2f1f4;
  /* display: flex;
  flex-direction: column; */
}
.sideDrawer {
  flex: 0 0 auto;
}
.readonly :deep(.dateInputSingle),
.readonly :deep(.dropdownContainer),
.readonly :deep(.textareaNormal) {
  user-select: none;
  pointer-events: none;
}
</style>