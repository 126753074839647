<template>
  <button
    class="lightButton"
    @click.prevent="handleButtonClick"
    :class="{ callButtonActive: active }"
  >
    <slot name="left-icon">
      <img
        class="iconClass"
        v-bind:src="require(`../../../assets/icons/${icon}`)"
      />
    </slot>

    <slot name="right-icon"></slot>
  </button>
</template>

<script>
export default {
  name: "CallOnHoldButton",
  props: {
    title: String,
    icon: String,
    active: { type: [Boolean, Object], default: false },
  },
  methods: {
    handleButtonClick() {
      this.$emit("button-click", this.title);
    },
  },
  emits: [
    "button-click"
  ]
};
</script>

<style scoped>
.lightButton {
  padding: 15px;
  background-color: #fcfdfe;
  outline: rgba(46, 62, 145, 0.3) solid 0.25pt;
  font-size: 10pt;
  color: #2e3e91;
  filter: drop-shadow(1px 1px 1px rgba(39, 29, 122, 0.3));
  border-radius: 1px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding-left: 8px;
  padding-right: 8px;
  height: 35px;
}
.lightButton:hover {
  outline: #2e3e91 solid 1pt;
}
.callButtonActive {
  background-color: #b8d998;
}
.lightButton:active {
  padding-left: 8px;
  padding-right: 8px;
  background-color: #ecf2f8;
  outline: #2e3e91 solid 0.25pt;
  filter: none;
  font-size: 10pt;
  box-shadow: 0 0 4px rgba(57, 111, 186, 0.4) inset;
  color: #2e3e91;
}
.iconClass {
  height: 22px;
  margin-right: 7px;
  margin-left: 2px;
}
</style>
