<template>
  <button :data-tooltip="tooltipTitle" class="button" @click.prevent="handleButtonClick">
    <slot></slot>
  </button>
</template>

<script>
export default {
  props: {
    tooltipTitle: String,
  },
  methods: {
    handleButtonClick() {
      this.$emit("button-click", this.tooltipTitle);
    },
  },
};
</script>

<style scoped>
.button:hover::before {
  content: attr(data-tooltip);
  background: #6b9fd9;
  color: white;
  padding: 0.25rem 0.4rem;
  width: max-content;
  opacity: 100%;
  border-radius: 2.5px;
  position: absolute;
  top: -0.25rem;
  font-size: 10pt;
  outline: none;
  left: 50%;
}

.button::before {
  transform: translateX(-50%) translateY(-100%);
}

.button {
  position: relative;
}

</style>
