<template>
    <div class="leftNavTopBar">
      <div class="leftNavContent">
        <div class="leftNavTitle">
          {{ title }}
        </div>
      </div>
  
      <div class="rightAlignedButtons">
        <template v-if="selectedPage === 'Set Account Limits'">
              <LightButton
                title="Instructions"
                icon="Comment.png"
                @button-click="handleButtonClick"
              />
        </template>
  
      </div>
    </div>
  </template>
  
  <script>
  import LightButton from "@/components/base_components/Buttons/LightButton.vue";
  export default {
    name: "SwitchOpsNavBar",
    props: {
      selectedPage: String,
      title: String,
      icon: Image
    },
    data() {
      return {
      }
    },
    methods: {
      handleButtonClick(title) {
        this.$emit("button-click", title);
      },
      handleLayoutChange(option) {
        this.$emit("layout-change", option);
      },
    },
    computed: {},
    components: {
      LightButton,
    },
    emits: [
      "button-click",
      "layout-change"
    ]
  };
  </script>
  <style scoped>
  .leftNavTopBar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 7px 3px 8px 10px;
    background-color: #f4fafa;
    color: #271d7a;
    font-weight: 600;
    font-size: 12pt;
    filter: drop-shadow(1px 1px 1px rgba(39, 29, 122, 0.2));
    font-family: "Red Hat Display", sans-serif;
    position: relative;
    z-index: 7;
  }
  .leftNavTitle {
    color: #271d7a;
    font-weight: 500;
    font-size: 12pt;
    font-family: "Red Hat Display", sans-serif;
  }
  .leftNavContent {
    display: flex;
  }
  .rightAlignedButtons {
    display: flex;
    gap: 10px;
    padding-right: 10px;
  }
  </style>
  