<template>
    <span v-html="CustomActionResult"></span>
</template>




<script setup>
    import { onMounted, defineProps, defineEmits, ref } from "vue";
    import AWS from "aws-sdk";


    //Props
    const PROPS = defineProps({
        script: {
            type: String,
            default: null
        },
        account_number: {
            type: String,
            default: null
        },
        ticket_number: {
            type: String,
            default: null
        },
        queue: {
            type: String,
            default: null
        }
    });

    const CustomActionResult = ref(null);

    
    //Emits
    const emit = defineEmits(["toggle-selected"]);


    //Functions
    function toggleValue(item) {
        emit("toggle-selected", item);
    }




    //Life Cycle Hooks
    onMounted(async () => {
        const lambda = new AWS.Lambda();
 

        const lambda_result = await lambda.invoke({
            FunctionName: PROPS.script,
            Payload: JSON.stringify({
                queue: PROPS.queue,
                external_id: PROPS.account_number,
                ticket_id: PROPS.ticket_number
            })
        }).promise();

        console.log(lambda_result);

        if (lambda_result.StatusCode == 200) {
            CustomActionResult.value = JSON.parse(lambda_result.Payload)
            toggleValue({label: CustomActionResult.value, value: CustomActionResult.value});
        }
    });
</script>





<style scoped>




</style>