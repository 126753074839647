<template>
  <div
    class="dropdown"
    :tabindex="0"
    @blur="open = false"
    :class="{ dropdownOpen: open }"
  >
    <div class="selected" :class="{ open: open }" @click="open = !open">
      {{ selected.label ? selected.label : selected
      }}<button
        class="resetButton"
        @click.stop="clearSelection"
        v-if="this.clear && this.initial !== this.selected"
      >
        Reset
      </button>
      <img
        v-if="open"
        src="@/assets/icons/caret_open_dkblue.png"
        class="dropdownCaret"
      />
      <img
        v-else
        src="@/assets/icons/caret_closed_dkblue.png"
        class="dropdownCaret"
      />
    </div>

    <div class="items" :class="{ selectHide: !open }">
      <div
        v-for="(option, i) in sortedOptions"
        :key="i"
        @click="
          selected = option;
          open = false;
          $emit('input', option);
        "
        class="optionContainer"
      >
        <img
          v-if="option.icon"
          :src="require(`@/assets/icons/${option.icon}`)"
          class="dropdownIcon"
        />
        {{ option.label }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DropDown",
  props: {
    options: Array,
    default: String,
    value: {
      type: Object,
      default: null,
    },
    clear: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    clearSelection() {
      this.selected = this.initial;
      this.$emit("input", this.initial);
    },
  },
  data() {
    return {
      selected: this.default
        ? this.default
        : this.options.length > 0
        ? this.options[0]
        : null,
      open: false,
      initial: null,
    };
  },
  computed: {
    sortedOptions() {
      if (this.options && Array.isArray(this.options)) {
        let noneOption = this.options.find(option => option.label === 'None');
        let otherOptions = this.options.filter(option => option.label !== 'None');

        let sortedOtherOptions = otherOptions.sort((a, b) => a.label.localeCompare(b.label));

        if (noneOption) {
          sortedOtherOptions.unshift(noneOption);
        }

        return sortedOtherOptions;
      }
      return this.options;
    }
    // sortedOptions() {
    //   if (this.options && Array.isArray(this.options)) {
    //     return [...this.options].sort((a, b) => a.label.localeCompare(b.label));
    //   }
    //   return this.options;
    // }
  },
  watch: {
    value: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (newValue === null) {
          this.selected = this.default
            ? this.default
            : this.options.length > 0
            ? this.options[0]
            : null;
          this.initial = this.selected;
        } else {
          this.selected = newValue;
          this.initial = newValue;
        }
      },
    },
    default(newValue) {
      if (newValue !== this.selected && !this.value) {
        this.selected = newValue;
      }
    },
  },
  emits: [
    "input"
  ]
};
</script>

<style scoped>
.selected.open {
  background-color: #eff4f9;
}
.dropdown {
  position: relative;
  text-align: left;
  font-size: 9pt;
  filter: drop-shadow(1px 1px 1px rgba(39, 29, 122, 0.2));
  z-index: 4;
}
.dropdownOpen {
  z-index: 10;
}
.dropdown > div {
  background-color: #ffffff;
}

.dropdown .selected {
  border-radius: 2px;
  border: 0.25px solid #2e3e91;
  color: #271d7a;
  padding-left: 10px;
  cursor: pointer;
  user-select: none;
  padding: 4px 2px 2px 8px;
  height: 28px;
  z-index: 1;
}

.dropdown .selected:after {
  position: absolute;
  top: 22px;
  right: 10px;
}

.dropdown .items {
  color: #ffffff;
  overflow: auto;
  position: absolute;
  background-color: #ffffff;
  left: 0;
  right: 0;
  z-index: 1;
  max-height: 200px;
  border: .33px solid #2E3E91;
}

.dropdown .items div {
  color: #271d7a;
  padding-left: 1em;
  cursor: pointer;
  user-select: none;
  margin-top: 3px;
  margin-bottom: 3px;
}

.dropdown .items div:hover {
  background-color: #eff4f9;
}

.selectHide {
  display: none;
}
.dropdownCaret {
  position: absolute;
  right: 0;
  top: 0;
  width: 23px;
  padding-top: 12px;
  padding-right: 10px;
}
.resetButton {
  position: absolute;
  right: 40px;
  top: 0;
  width: 23px;
  padding-top: 5px;
  padding-right: 10px;
}
.dropdownIcon {
  width: 16px;
  vertical-align: middle;
  padding-right: 3px;
}
.optionContainer {
  display: flex;
  align-items: center;
}
</style>
