<template>
  <div
    class="pdf-content"
  >
    <div
      class="pdf-content__details"
    >
      <h4>{{ name }}</h4>
      <a
        :href="`mailto:${email}`"
      >
        {{ email }}
      </a>
    </div>
    <div>
      <h4>Infractions</h4>
      <table
        v-if="infractions"
      >
        <tr>
          <th>Date</th>
          <th>Infractions</th>
          <th>Documentation</th>
        </tr>
        <tr
          v-for="{ id, date, infraction, documentation } in infractions"
          :key="id"
        >
          <td>
            {{ date }}
          </td>
          <td>
            {{ infraction }}
          </td>
          <td>
            {{ documentation }}
          </td>
        </tr>
      </table>
    </div>

    <div>
      <h4>Disciplinary Actions</h4>
      <table
        v-if="disciplinaryActions"
      >
        <tr>
          <th>Date</th>
          <th>Disciplinary Action</th>
          <th>Documentation</th>
        </tr>
        <tr
          v-for="{ id, date, disciplinary_action, documentation } in disciplinaryActions"
          :key="id"
        >
          <td>
            {{ date }}
          </td>
          <td>
            {{ disciplinary_action }}
          </td>
          <td>
            {{ documentation }}
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PdfContent',
  props: {
    name: {
      type: String,
      default: ''
    },
    email: {
      type: String,
      default: ''
    },
    disciplinaryActions: {
      type: Array,
      default: () => []
    },
    infractions: {
      type: Array,
      default: () => []
    }
  }
}
</script>

<style scoped>
.pdf-content {
  display: flex;
  padding: 20px;
  width: 100%;
  flex-direction: column;
  background-color: #fff;
}

.pdf-content h4 {
  margin-bottom: 15px;
}

.pdf-content a {
  display: inline-block;
  text-decoration: none;
  margin-bottom: 20px;
  color: #3e87d3;
}

table {
  width: 100%;
  border-collapse: collapse;
  background-color: #fff;
  font-size: 13px;
  text-align: left;
  border: 1px solid #e0e0e0;
}

table:not(:last-of-type) {
  margin-bottom: 30px;
}

table th {
  background-color: #5c95d6;
  color: #fff;
  border: none;
  border-bottom: 0.5pt solid #dde9f4;
  padding: 10px;
  position: relative;
}

table td {
  padding: 10px;
  border-bottom: 0.5pt solid #dde9f4;
}
</style>