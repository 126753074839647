<template>
  <div v-if="currentCall?.status === 'active'">
    <div class="activeCallHeader">
      <img
        :src="require('@/assets/icons/call_white.png')"
        class="activeCallIcon"
        @click="handleActiveClick"
      />
      <h1 class="activeCallText" @click="handleActiveClick">
        Active Call
        <span :style="{ paddingLeft: '10px' }">
          ({{ currentCall["Total Call Duration"] }})
        </span>
      </h1>
      <img
        :src="muted ? require('@/assets/icons/unmute_combined.png') : require('@/assets/icons/mute_combined.png') "
        class="mute-icon"
        @click="handleMute"/>
    </div>
    <h2 class="callHistoryActiveText">Call History</h2>
  </div>
  <div v-else class="enterNumberBoxContainer">
    <img
      :src="require('@/assets/icons/callhistory_dkblue.png')"
      class="callHistoryIcon"
    />
    <h1 class="callHistoryHeader">Call History</h1>
  </div>

  <div class="callHistoryContainer">
    <template v-for="(call, index) in callHistory" :key="index">
      <CallHistoryCard :call="call"
        @selectedCall="handleSelectedCall"
        :isSelected="call == selectedCall"
      />
    </template>
  </div>
  <div
    class="activeCallButtonContainer"
    v-if="currentCall?.status === 'active'"
  >
    <DividerComp class="activeCallDivider" />
    <div class="hangupButtonContainer">
      <LightButton
        class="hangupButton"
        icon="hangup.png"
        :style="{
          height: '35px',
          backgroundColor: '#e87928',
          color: 'white',
        }"
        @click="handleHangup"
      />
    </div>
  </div>
  <div v-else class="callButtonContainer">
    <DividerComp class="callDivider" />
    <CallHistoryCallButton
      class="callButton"
      @click="handleCallClick"
      :icon="selectedCall ? 'call_white.png' : 'call_dkblue.png'"
      :active="selectedCall"
    />
  </div>
</template>

<script>
import CallHistoryCard from "./CallHistoryCard.vue";
import CallHistoryCallButton from "./CallHistoryCallButton";
import DividerComp from "@/components/base_components/DividerComp.vue";
import LightButton from "../LightButtonWebRTC.vue";
import { useWebRTCStore } from "@/stores/webRTC/webRTCStore.js";
import { mapActions, mapStores } from "pinia";
export default {
  name: "CallHistoryComp",
  props: { currentCall: Object },
  emits: ["navigate"],
  components: { CallHistoryCard, CallHistoryCallButton, LightButton, DividerComp },
  data() {
    return {
      selectedCall: null,
      callHistory: [],
    };
  },
  computed: {
    ...mapStores(useWebRTCStore),

    muted() {
      return this.WebRTCStore.getMuteState;
    }
  },
  methods: {
    ...mapActions(useWebRTCStore, ["changeSelectedLayout", "toggleMuted"]),
    handleActiveClick() {
      this.$emit("navigate", "active");
    },
    handleHangup() {
      this.WebRTCStore.hangupCurrentCall();
    },
    handleCallClick() {
      if(this.selectedCall){
        let valueArr = [...String(this.selectedCall.number)];
        let dial_pad = this.WebRTCStore.getLibWebPhone.getDialpad();
        dial_pad.clear();
        for (var digit of valueArr) {
          dial_pad.dial(digit);
        }

        dial_pad.call(false);
        this.WebRTCStore.setCurrentCallCallerID(this.selectedCall.number);
        this.WebRTCStore.setCurrentCallCallerName(this.selectedCall.display_name ? this.selectedCall.display_name : "");
        this.WebRTCStore.setCurrentCallFromQueue("");
        this.WebRTCStore.setCurrentCallCallDuration("0");
        
        this.WebRTCStore.setCurrentCallStatus("active");
        this.WebRTCStore.changeSelectedPage("active");
      }
    },
    handleSelectedCall(call) {
      if (call === this.selectedCall) {
        this.selectedCall = null;
      } else {
        this.selectedCall = call;
      }
    },
    handleMute(){
      this.WebRTCStore.toggleMuted();
    },
  },
  created() {
    let call_history = this.WebRTCStore.getCallHistory;
    for(let call_history_id in call_history){
      let call_info = call_history[call_history_id];
      let history = {
        caller: call_info.display_name ? call_info.display_name : call_info.number,
        time: call_info.date_time,
        duration: call_info.duration,
        audio: "", // TODO: needs an mp3 in the future
        number: call_info.number, 
        type: call_info.missed ? "missed" : call_info.direction
      };

      this.callHistory.push(history)
    }
  },
};
</script>

<style scoped>
.callHistoryContainer {
  padding: 10px 10px 0px 10px;
  background-color: #f8f9fc;
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: auto;
  height: 100%;
}
.enterNumberBoxContainer {
  margin-top: 25px;
  margin-bottom: 25px;
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
}
.callHistoryIcon {
  height: 32px;
  width: 32px;
  margin-right: 20px;
  margin-left: 20px;
  text-align: center;
}
.callHistoryHeader {
  color: #271d7a;
  font-size: 19px;
  font-weight: 500;
}
.activeCallHeader {
  padding-top: 25px;
  padding-bottom: 25px;
  background-color: #e78535;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.activeCallHeader:hover {
  outline: 0.25px solid #271d7a;
  cursor: pointer;
}
.activeCallIcon {
  height: 30px;
  width: 24px;
  margin-right: 20px;
  margin-left: 20px;
  text-align: center;
}
.activeCallText {
  color: #ffffff;
  font-size: 19px;
  font-weight: 500;
}
.activeCallButtonContainer {
  margin: auto auto 0 auto;
  padding-bottom: 30px;
  width: 100%;
  background-color: #f8f9fc;
  flex: 1;
}
.hangupButtonContainer {
  display: flex;
  /* padding-top: 20px; */
}
.hangupButton {
  width: 90%;
  display: flex;
  margin: auto;
}
.callHistoryActiveText {
  background-color: #f8f9fc;
  font-size: 17px;
  font-weight: 500;
  color: #271d7a;
  padding: 10px 0px 0px 20px;
}
.callButtonContainer {
  margin: auto auto 0 auto;
  padding-bottom: 30px;
  width: 100%;
  background-color: #f8f9fc;
}
.activeCallDivider {
  width: 100%;
  margin-bottom: 25px;
}
.callButton {
  width: 90%;
  margin: auto;
}
.callDivider {
  width: 100%;
  margin-bottom: 30px;
}
.mute-icon {
  height: 35px;
  margin-right: 15px;
}
</style>
