<template>
  <div>
    <div
      class="sideDrawer"
    >
      <FilterSideDrawer
        button-type="Close"
        :drawer-open="localDrawer"
        :drawer-data="drawerData"
        @close-click="handleCloseClick"
        @submit-click="handleContinue"
      />
    </div>
    <div class="tableContainer"
    >
      <TableComp
        v-if="!localDetailsInfo"
        :headers="getTableHeaders"
        :data-list="getTableData"
        :single-select="true"
        :select-all="false"
        :left-nav-present="true"
        uniqueKey="id"
        background-color="#e2f1f4"
        @selected-item="handleSelectedItem"
        @click-row="handleClickRow"
      />

      <h4 v-if="localDetailsInfo && tableInfractionsData.length">Infractions</h4>

      <TableComp
        v-if="localDetailsInfo && tableInfractionsData.length"
        :headers="tableInfractionsHeaders"
        :data-list="filteredInfractionsData"
        :left-nav-present="true"
        :single-select="true"
        :select-all="false"
        uniqueKey="id"
        background-color="#e2f1f4"
        @selected-item="handleSelectedItem"
        @click-row="handleClickRow"
      />

      <h4 v-if="localDetailsInfo && tableInfractionsData.length">Disciplinary Actions</h4>

      <TableComp
        v-if="localDetailsInfo && tableInfractionsData.length"
        :headers="tableDisciplinaryHeaders"
        :data-list="filteredDisciplinaryData"
        :left-nav-present="true"
        :single-select="true"
        :select-all="false"
        uniqueKey="id"
        background-color="#e2f1f4"
        @selected-item="handleSelectedItem"
        @click-row="handleClickRow"
      />
    </div>
    <div
      id="pdf-file"
      v-show="pdfVisible"
    >
      <PdfContent
        :name="submissionData.name"
        :email="submissionData.email"
        :disciplinaryActions="filteredDisciplinaryData"
        :infractions="filteredInfractionsData"
      />
    </div>
  </div>
</template>

<script>
import TableComp from "@/components/base_components/TableComp.vue";
import FilterSideDrawer from "@/components/base_components/SideDrawer/FilterSideDrawer";
import PdfContent from '@/components/Voice/Management/Pdf/PdfContent.vue';
import html2pdf from "html2pdf.js";
import { DRAWER_FILTER_TITLE, APPLY_DRAWER_TYPE } from '@/constants/drawer.constants';
import { APPLY } from '@/constants/statuses.constants';

import { useCCManagementAttendanceStore } from "@/stores/ContactCenter/Management/CCManagementAttendanceStore.js";
import { mapStores } from 'pinia';

export default {
  name: 'OverviewView',
  components: {
    TableComp,
    PdfContent,
    FilterSideDrawer
  },
  props: {
    drawer: {
      type: Boolean,
      default: false
    },
    isViewButton: {
      type: Boolean,
      default: false
    },
    isDetailsInfo: {
      type: Boolean,
      default: false
    },
    isAdmin: {
      type: Boolean,
      default: false
    },
    search: {
      type: String,
      default: ''
    },
    isPdfDownload: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      tableHeaders: [
        { text: "Name", value: "name" },
        { text: "Email", value: "email" },
        { text: "Number of infractions", value: "infraction_count" },
        { text: "Discipline Level", value: "discipline_level" },
      ],
      tableData:  [

      ],

      tableInfractionsHeaders: [
        { text: "Date", value: "date" },
        { text: "Infractions", value: "infraction" },
        { text: "Documentation", value: "documentation" }
      ],

      tableDisciplinaryHeaders: [
        { text: "Date", value: "date" },
        { text: "Disciplinary Action", value: "disciplinary_action" },
        { text: "Documentation", value: "documentation" }
      ],
      submissionData: {},
      pdfVisible: false,
      drawerData: [
        { title: DRAWER_FILTER_TITLE, button: APPLY, type: APPLY_DRAWER_TYPE },
        {
          title: '',
          details: [
            {
              name: "Limit Data to 6 Months",
              type: "checkbox",
              value: 'limit',
              checked: true
            }
          ]
        },
      ],
      isDataLimit: true,
      currentDate: new Date()
    }
  },
  async mounted() {
    let result = await this.CCManagementAttendanceStore.getEmployeeOverview();
    console.log("mounted result: ", result);

    if (result && Array.isArray(result)) {
      this.tableData = result.map(employee => {
        console.log("Mapping employee: ", employee);

        const infractions = employee.infractions ? employee.infractions.map(infraction => {
          return {
            id: infraction.id,
            date: infraction.date,
            infraction: infraction.infraction,
            documentation: infraction.documentation
          };
        }) : [];

        const disciplinary_actions = employee.disciplinary_actions ? employee.disciplinary_actions.map(action => {
          return {
            id: action.id,
            date: action.date,
            disciplinary_action: action.disciplinary_action,
            documentation: action.documentation
          };
        }) : [];

        return {
          id: employee.id,
          name: employee.name,
          email: employee.email,
          infraction_count: employee.infraction_count,
          discipline_level: employee.discipline_level,
          infractions: infractions,
          disciplinary_actions: disciplinary_actions,
        };
      });

      console.log("this.tableData", this.tableData);
    } else {
      console.error("Failed to fetch employee overview: ", result);
    }
  },
  computed: {
    ...mapStores(useCCManagementAttendanceStore),
    localDrawer: {
      get () {
        return this.drawer;
      },
      set (drawer) {
        this.$emit('update:drawer', drawer);
      }
    },
    localDetailsInfo: {
      get () {
        return this.isDetailsInfo;
      },
      set (value) {
        this.$emit('update:isDetailsInfo', value)
      }
    },
    halfYearDate () {
      const date = new Date();
      date.setMonth(date.getMonth() - 6);
      return date;
    },
    isViewButtonVisible: {
      get () {
        return this.isViewButton
      },
      set (isViewButton) {
        this.$emit('update:isViewButton', isViewButton)
      }
    }, 
    searchResult: {
      get () {
        return this.search;
      },
      set (search) {
        this.$emit('update:search', search);
      }
    },
    getTableData () {
      return this.isAdmin ? this.filteredData.map(item => {
        return { header: true, ...item }
      }) :  this.tableData
    },
    getTableHeaders () {
      const adminHeaders = [
        { text: "", value: "checkbox", width: 22, fixed: true },  
        ...this.tableHeaders
      ];

      return this.isAdmin ? adminHeaders : this.tableHeaders
    },
    filteredData() {
      if (this.searchResult !== '') {
        const lowerCaseSearchResult = this.searchResult.toLowerCase();
        return this.tableData.filter(item => {
          return (
            item.name.toLowerCase().includes(lowerCaseSearchResult) ||
            item.email.toLowerCase().includes(lowerCaseSearchResult)
          );
        });
      }
      
      return this.tableData;
    },

    filteredInfractionsData() {
      if (this.searchResult !== '' || this.isDataLimit) {
        const filterData = item => {
          return Object.values(item).some(fieldValue =>
            String(fieldValue).toLowerCase().includes(this.searchResult.toLowerCase())
          );
        };

        if (!this.isDataLimit) {
          return this.tableInfractionsData.filter(filterData);
        } else {
          const currentDate = new Date();
          const sixMonthsAgo = new Date();
          sixMonthsAgo.setMonth(sixMonthsAgo.getMonth() - 6);

          const limitedData = this.tableInfractionsData.filter(action => {
            const actionDate = new Date(action.date);
            return actionDate >= sixMonthsAgo && actionDate <= currentDate;
          });

          return limitedData.filter(filterData);
        }
      }

      return this.tableInfractionsData;
    },

    filteredDisciplinaryData () {
      if (this.searchResult !== '' || this.isDataLimit) {
        const filterData = item => {
          return Object.values(item).some(fieldValue =>
            String(fieldValue).toLowerCase().includes(this.searchResult.toLowerCase())
          );
        };

        if (!this.isDataLimit) {
          return this.tableDisciplinaryData.filter(filterData);
        } else {
          const currentDate = new Date();
          const sixMonthsAgo = new Date();
          sixMonthsAgo.setMonth(sixMonthsAgo.getMonth() - 6);

          const limitedData = this.tableDisciplinaryData.filter(action => {
            const actionDate = new Date(action.date);
            return actionDate >= sixMonthsAgo && actionDate <= currentDate;
          });

          return limitedData.filter(filterData);
        }
      }

      return this.tableDisciplinaryData;
    },

    tableInfractionsData () {
      if (Array.isArray(this.submissionData.infractions)) {
        return this.submissionData.infractions.map(({
          id, date, infraction,documentation
        }) => {
          return { id, date, infraction, documentation }
        });
      } else {
        return [];
      }     
    },
    tableDisciplinaryData () {
      if (Array.isArray(this.submissionData.disciplinary_actions)) {
        return this.submissionData.disciplinary_actions.map(
          ({ id, date, disciplinary_action, documentation }) => {
            return { id, date, disciplinary_action, documentation };
          }
        );
      } else {
        return [];
      }
    },
  },
  methods: {
    downloadPdf() {
      this.pdfVisible = true;
      html2pdf(
        document.getElementById('pdf-file'),
        {
          pagebreak: {
            mode: ['avoid-all']
          },
          margin: 2,
          filename: `${this.submissionData.name}_${this.submissionData.email}.pdf`,
        }
      ).then(() => {
        this.pdfVisible = false;
      });
    },

    handleSelectedItem(selectedItem) {
      if (selectedItem) {
        this.isViewButtonVisible = true;
        this.submissionData = selectedItem;
      } else {
        this.isViewButtonVisible = false
        this.submissionData = {};
      }
    },
    handleCloseClick () {
      this.localDrawer = !this.localDrawer;
    },
    handleContinue ({ limit }) {
      this.localDrawer = false;
      this.isDataLimit = !limit
    },
    handleClickRow (data) {
      if (data) {
        this.localDetailsInfo = true;
        this.submissionData = data;
      }
    }
  },
  watch: {
    isPdfDownload () {
      this.downloadPdf()
    }
  },
  unmounted () {
    this.localDrawer = false;
    this.isViewButtonVisible = false;
    this.localDetailsInfo = false;
  },
  emits: [
    "update:drawer",
    "update:isDetailsInfo",
    "update:isViewButton",
    "update:search"
  ]
}
</script>

<style scoped>
.pageContent {
  flex-grow: 1;
}
.mainContainer {
  display: flex;
  background-color: #e2f1f4;
  height: 100%;
}
.mainContent {
  display: flex;
  flex-grow: 1;
  position: relative;
}
.tableContainer {
  /* padding: 20px; */
  margin-left: 20px;
  margin-top: 20px;
  width: 90%;
  height: 100%;
  background-color: #e2f1f4;
}
.tableContainer h4{
  color: #271d7a;
  margin-bottom: 15px;
  padding-left: 25px;
}

.sideDrawer {
  position: fixed;
  top: auto;
  z-index: 99;
  bottom: 0;
  min-height: calc(100vh - 93px);
  max-height: calc(100vh - 93px);
}
.sideDrawer {
  flex: 0 0 auto;
}
.configAlerts {
  position: fixed;
  right: 0;
  padding-bottom: 10px;
  padding-right: 20px;
  z-index: 11;
}

.readonly :deep(.inputNormal){
  user-select: none;
  pointer-events: none;
}

:deep(.sideDrawerInputResetWrapper) {
  visibility: hidden;
}

:deep(button.drawerFilterTitle) {
  visibility: hidden;
}
</style>