<template>
  <button
    class="lightButton"
    @click.prevent="handleButtonClick"
    :class="{ lightButtonActive: active }"
  >
    <slot name="left-icon">
      <img
        :class="buttonClass"
        v-if="icon"
        v-bind:src="require(`@/assets/icons/${icon}`)"
      />
    </slot>

    <slot>
      <span class="lightButtonText" v-if="title !== 'drawer'">
        {{ title }}
      </span>
    </slot>

    <slot name="right-icon"></slot>
  </button>
</template>

<script>
export default {
  name: "LightButton",
  props: {
    title: String,
    icon: String,
    active: { type: Boolean, default: false },
    color: String,
  },
  methods: {
    handleButtonClick() {
      this.$emit("button-click", this.title);
    },
  },
  computed: {
    buttonClass() {
      return this.title !== "drawer"
        ? "lightButtonIcon"
        : "lightButtonIconNoTitle";
    },
  },
  emits: [
    "button-click"
  ]
};
</script>

<style scoped>
.lightButton {
  padding: 5px;
  background-color: #fcfdfe;
  outline: rgba(46, 62, 145, 0.2) solid 0.25pt;
  font-size: 11pt;
  color: #2e3e91;
  filter: drop-shadow(1px 1px 1px rgba(39, 29, 122, 0.4));
  border-radius: 1px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding-left: 8px;
  padding-right: 8px;
  height: 25px;
}
.lightButton:hover {
  outline: #2e3e91 solid 1pt;
}
.lightButtonActive {
  padding-left: 8px;
  padding-right: 8px;
  background-color: #ecf2f8;
  outline: #2e3e91 solid 0.25pt;
  filter: none;

  box-shadow: 0 0 4px rgba(57, 111, 186, 0.4) inset;
  color: #2e3e91;
}
.lightButton:active {
  padding-left: 8px;
  padding-right: 8px;
  background-color: #ecf2f8;
  outline: #2e3e91 solid 0.25pt;
  filter: none;
  font-size: 10pt;
  box-shadow: 0 0 4px rgba(57, 111, 186, 0.4) inset;
  color: #2e3e91;
}
.lightButtonIcon {
  height: 16px;
  margin-right: 12px;
  margin-left: 2px;
}
.lightButtonIconNoTitle {
  width: 16px;
  height: 16px;
  margin-right: 2px;
  margin-left: 2px;
}
.lightButtonText {
  display: flex;
  align-items: center;
}
</style>
