import { defineStore } from 'pinia';


export const useAlertsStore = defineStore('Alerts', {
    state: () => ({
        alerts: [],
    }), 
    getters: {
        getAlerts(state) {
            return state.alerts;
        },
    },
    actions: {
        addAlert(name, description, disapperTimeout = 5000, checkForDuplicates = false,) {
            const alertObject = {
              id: this.alerts.length,
              name,
              description,
            };
          
            if (!checkForDuplicates || !this.alerts.some(alert => alert.description === description)) {
              this.alerts.push(alertObject);
          
              if (disapperTimeout != 0) {
                // Set timeout to remove the alert after the specified duration
                setTimeout(() => {
                  this.removeAlert(alertObject.id);
                }, disapperTimeout);
              }
            }
        },
        removeAlert(id) {
            const index = this.alerts.findIndex(alert => alert.id === id);
            if (index !== -1) {
                this.alerts.splice(index, 1);
            }
        }
    },
})