export function downloadCSV(headers, data, filterOptionsArray, name) {
    const filteredData = applyFilters(data, filterOptionsArray);
    const csvContent =
      "data:text/csv;charset=utf-8," +
      encodeURI(buildCSVContent(headers, filteredData));
    const link = document.createElement("a");
    link.setAttribute("href", csvContent);
    link.setAttribute("download", `${name}.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
  
  function applyFilters(data, filterOptionsArray) {
    return data.filter((row) => {
      return filterOptionsArray.every((filter) => {
        const headerValue = filter.field;
        const comparison = filter.comparison;
        const criteria = filter.criteria;
        const rowData = row[headerValue];
  
        switch (comparison) {
          case "=":
            return rowData === criteria;
          case "<":
            return rowData < criteria;
          case ">":
            return rowData > criteria;
          case "in":
            return criteria.includes(rowData);
          default:
            return false;
        }
      });
    });
  }
  
  function buildCSVContent(headers, data) {
    const csvRows = [];
    const headerRow = headers.map((header) => header.text);
    csvRows.push(headerRow);
  
    data.forEach((row) => {
      const rowData = headers.map((header) => row[header.value]);
      csvRows.push(rowData);
    });
  
    return csvRows.map((row) => row.join(",")).join("\n");
  }
  

  export function downloadMultipleCSV(tables, name) {
    const csvContent =
      "data:text/csv;charset=utf-8," + encodeURI(buildMultipleCSVContent(tables));
    const link = document.createElement("a");
    link.setAttribute("href", csvContent);
    link.setAttribute("download", `${name}.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
  
  function buildMultipleCSVContent(tables) {
    let csvContent = [];
    tables.forEach((table) => {
      const csvRows = [];
      const headerRow = table.headers.map((header) => header.text);
      csvRows.push([]);
      csvRows.push(headerRow);
  
      table.data.forEach((row) => {
        const rowData = table.headers.map((header) => {
          // Check if the row value is an array
          if (header.value !== "checkbox" && header.value !== "edithover") {
            if (Array.isArray(row[header.value])) {
              // If it's an array, join its values with a comma
              return `"${row[header.value].join(", ")}"`;
            } else {
              return row[header.value];
            }
          }
        });
        csvRows.push(rowData);
      });
      csvContent.push(csvRows.join("\n"));
      csvContent.push("\r");
    });
    return csvContent;
  }