/**
 * Removes duplicate values from an array.
 *
 * @param {Array} arr - The input array to remove duplicates from.
 * @return {Array} - A new array with duplicate values removed.
 */

export function uniq(arr) {
    return arr.filter((value, index, self) => {
      return self.indexOf(value) === index;
    });
  }