<template>
  <div class="notificationContainer">
    <div class="detailCont" v-if="selectedNotification">
      <div class="header">
        <div class="heading">Details:</div>
        <button class="close" @click="handleDetailClose">&times;</button>
        <NotificationDetail
          :notification="selectedNotification"
          @delete-notification="handleDeleteNotification"
        />
      </div>
    </div>
    <div class="newNotContainer" v-else-if="newNotification && canAdministerNotifications == true">
      <div class="newNotificationHeader">
        <div class="heading">New Notification</div>
        <button class="close" @click="handleNewNotCLose">&times;</button>
        <NewNotificationForm
          @postSuccess="handlePostSuccess"
          @postFailure="handlePostFailure"
        />
      </div>
    </div>

    <div class="container">
      <div class="header">
        <div class="heading">Notifications</div>
        <button class="close" @click="handleClickCloseButton">&times;</button>
        <div class="notificationCardContainer">
          <div
            v-for="(notification, index) in getNotificationsList"
            :key="index"
          >
            <NotificationCard
              :notification="notification"
              @expand-notification="handleExpandNotification"
              @delete-notification="handleDeleteNotification"
              :class="{ selected: notification === selectedNotification }"
            />
          </div>
        </div>
        <div v-if="!notifications.length" class="">No new notifications</div>
        <div class="paginationContainer">
          <div class="row">
            <span class="pageNumberContainer">
              <span
                v-for="paginationNumber in getPaginationList"
                :key="{ paginationNumber }"
                class="pageNumber"
                :class="{
                  pagActive: paginationNumber === currentPage,
                }"
              >
                <button
                  class="px-2"
                  :disabled="isNumberInvalid(paginationNumber)"
                  @click="handlePageButton(paginationNumber)"
                >
                  {{ paginationNumber }}
                </button>
              </span>
            </span>
          </div>
          <div class="arrowButtons">
            <button @click="prevPage" :disabled="isFirstPage">
              <img
                class="tablePagButton"
                v-bind:src="require(`@/assets/icons/Table - back.png`)"
              />
            </button>
            <button @click="nextPage" :disabled="isLastPage">
              <img
                class="tablePagButton"
                v-bind:src="require(`@/assets/icons/Table - next.png`)"
              />
            </button>
          </div>
          <DarkButton
            title="New Notification"
            class="notificationButton"
            @click="handleNewNotification"
            v-if="canAdministerNotifications"
          />
        </div>
      </div>
    </div>
    <AlertsGroup
      v-model:alerts="alerts"
    />
  </div>
</template>

<script>
import DarkButton from "../base_components/Buttons/DarkButton.vue";
import NotificationCard from "./NotificationCard.vue";
import NotificationDetail from "./NotificationDetail.vue";
import NewNotificationForm from "./NewNotificationForm.vue";
import AlertsGroup from "@/components/base_components/AlertsGroup.vue";
import { addAlert } from '@/helpers/alertUtils';
import { ERROR, SUCCESS } from "@/constants/statuses.constants.js";
import { mapStores } from 'pinia';
import { useStatusNotificationStore } from "@/stores/site_notifications/statusNotificationStore.js";
import { useAuthStore } from "@/stores/auth/authStore.js";

export default {
  name: "NotificationsPopup",
  components: {
    NotificationCard,
    NotificationDetail,
    DarkButton,
    NewNotificationForm,
    AlertsGroup,
  },
  props: {
    usersNotifications: Array,
  },
  computed: {
    ...mapStores(useStatusNotificationStore, useAuthStore),
    getNotificationsList() {
      let startIndex = (this.currentPage - 1) * 5;
      let endIndex = this.currentPage * 5;
      return this.usersNotifications.slice(startIndex, endIndex);
    },
    getPageCount() {
      if (this.usersNotifications.length < 4) {
        return 1;
      }
      return Math.ceil(this.usersNotifications.length / 5);
    },
    canAdministerNotifications() {
      var groups = this.AuthStore.getCurrentUser.signInUserSession.accessToken.payload["cognito:groups"];
      if(groups.includes('SuperUser') || groups.includes('Notification Administrators')) {
        return true;
      }

      return false;
    },
    getPaginationList() {
      const number = this.getPageCount;
      const current = this.currentPage;
      const result = [];
      if (number <= 3) {
        for (let i = 1; i <= number; i++) {
          result.push(i);
        }
      } else if (current <= 2) {
        for (let i = 1; i <= 3; i++) {
          result.push(i);
        }
        result.push("...");
        result.push(number);
      } else if (number - current <= 2) {
        result.push(1);
        result.push("...");
        for (let i = number - 2; i <= number; i++) {
          result.push(i);
        }
      } else {
        result.push(1);
        result.push("...");
        result.push(current);
        result.push("...");
        result.push(number);
      }

      return result;
    },
  },
  methods: {
    handleClickCloseButton() {
      this.$emit("close");
    },
    handleDetailClose() {
      console.log("detail close");
      this.selectedNotification = null;
    },
    handleNewNotCLose() {
      this.newNotification = false;
    },
    async handleExpandNotification(notification) {
      console.log("Expand", notification);
      this.selectedNotification = notification;
      this.newNotification = false;
      await this.StatusNotificationStore.markNotificationAsRead(notification);
    },
    async handleDeleteNotification(notification) {
      console.log("Delete", notification);

      this.selectedNotification = null;
      if (this.getPageCount < this.currentPage) {
        this.currentPage--;
      }
      await this.StatusNotificationStore.deleteNotification(notification);

      // this.notifications = this.notifications.filter((not) => {
      //   return not !== notification;
      // });
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage -= 1;
      }
    },
    nextPage() {
      if (this.getPageCount > this.currentPage) {
        this.currentPage += 1;
      }
    },
    isNumberInvalid(num) {
      return isNaN(num);
    },
    handlePageButton(page) {
      this.currentPage = page;
    },
    handleNewNotification() {
      this.newNotification = true;
      this.selectedNotification = false;
    },
    handlePostSuccess() {
      this.newNotification = false;
      addAlert(SUCCESS, "Post was successful", this.alerts);
    },
    handlePostFailure() {
      addAlert(ERROR, "Missing Required Values", this.alerts);
    },
  },
  data() {
    return {
      currentPage: 1,
      selectedNotification: null,
      newNotification: false,
      alerts: [],
      notifications: [
        {
          title: "Notification 1",
          date: "July 1",
          time: "12:15 AM",
          subject: "Ref: Urgent",
        },
        {
          title: "Notification 2",
          date: "July 2",
          time: "8:40 PM",
          subject: "Ref: Urgent",
        },
        {
          title: "Notification 3",
          date: "July 3",
          time: "5:15 AM",
          subject: "Ref: Urgent",
        },
        {
          title: "Notification 4",
          date: "July 4",
          time: "3:00 PM",
          subject: "Ref: Urgent",
        },
        {
          title: "Notification 5",
          date: "July 45",
          time: "3:00 PM",
          subject: "Ref: Urgent",
        },
        {
          title: "Notification 6",
          date: "July 41",
          time: "3:00 PM",
          subject: "Ref: Urgent",
        },
        {
          title: "Notification 45",
          date: "July 43",
          time: "3:00 PM",
          subject: "Ref: Urgent",
        },
        {
          title: "Notification 41",
          date: "July 49",
          time: "3:00 PM",
          subject: "Ref: Urgent",
        },
        {
          title: "Notification 42",
          date: "July 47",
          time: "3:00 PM",
          subject: "Ref: Urgent",
        },
        {
          title: "Notification 48",
          date: "July 14",
          time: "3:00 PM",
          subject: "Ref: Urgent",
        },
        {
          title: "Notification 94",
          date: "July 44",
          time: "3:00 PM",
          subject: "Ref: Urgent",
        },
        {
          title: "Notification 24",
          date: "July 4",
          subject: "Ref: Urgent",
          time: "3:00 PM",
        },
      ],
    };
  },
  emits: ["close"]
};
</script>

<style scoped>
.notificationContainer {
  display: flex;
  flex-direction: row;
  height: 100vh;
  border: 1px solid #271d7a;
  background-color: rgb(226, 241, 244);
}
.container {
  width: 200px;
}
.detailCont {
  border: 1px solid #271d7a;
  position: absolute;
  width: 400px;
  border-bottom: 1px solid #271d7a;
  border-left: 1px solid #271d7a;
  border-right: 1px solid #271d7a;
  left: 202%;
  transform: translateX(-202%);
  background-color: #ffffff;
  height: 100vh;
}
.newNotContainer {
  position: absolute;
  width: 400px;
  border-bottom: 1px solid #271d7a;
  border-left: 1px solid #271d7a;
  border-right: 1px solid #271d7a;
  left: 202%;
  transform: translateX(-202%);
  background-color: #ffffff;
  height: 100vh;
}
.newNotificationHeader {
  position: relative;
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center;
  padding-top: 1px;
  background-color: #ffffff;
}
.newNotificationHeader .close {
  position: absolute;
  right: 10px;
  top: 7px;
  font-size: 24px;
  line-height: 0;
  width: 10px;
  height: 10px;
}
.header {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  padding-top: 1px;
  background-color: rgb(226, 241, 244);
  color: #271d7a;
}

.header .close {
  position: absolute;
  right: 10px;
  top: 7px;
  font-size: 24px;
  line-height: 0;
  width: 10px;
  height: 10px;
}
.heading {
  display: flex;
  align-items: center;
  text-align: left;
  width: 100%;
  font-weight: 600;
  padding-left: 10px;
}
.selected {
  background-color: rgb(226, 241, 244) !important;
}
.tablePagButton {
  width: 32px;
  height: 32px;
  filter: drop-shadow(1px 1px 1px rgba(39, 29, 122, 0.2));
}

.pageNumber {
  background-color: #ffffff;
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
  margin: 0px 5px 0px 5px;
}
.pageNumberContainer {
  color: #271d7a;
  font-size: 10pt;
  height: 24px;
  width: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.paginationContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 50px;
  width: 100%;
  padding-top: 20px;
  background-color: rgb(226, 241, 244);
}
.arrowButtons {
  padding-top: 10px;
}
.notificationButton {
  display: flex;
  margin-bottom: 10px;
}
.configAlerts {
  position: fixed;
  right: 0;
  margin-bottom: 50px;
  z-index: 9;
}
.notificationCardContainer {
  overflow-y: auto;
  overflow-x: hidden;
  width: 200px;
  margin-bottom: 180px;
}
</style>
