/* eslint-disable */
import { defineStore } from 'pinia'
import { API, Auth} from "aws-amplify";
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api";
import { useAuthStore } from '@/stores/auth/authStore.js'
import awsconfig from '@/aws-exports';

export const useTaxCalculatorStore = defineStore('TaxCalculator', {
    dependencies: [
        useAuthStore
    ],
    state: () => ({
        taxes: [],
        products: {},
        productOptions: []
    }),
    getters: {
        getTaxes(state) {
            return state.taxes
        },
        getProducts(state) {
            return state.products
        },
        getProductOptions(state) {
            return state.productOptions
        }
    },
    actions: {
        async initializePage() {
            var authStore = useAuthStore();
            return authStore.validateAdminStatus();
        },
        async sendMailTaxes(email, taxesFilesPath, taxesFileName) {
            var error_message = 'Unable to send email with Taxes data';
        
            const emailData = {
                to: email,
                subject: 'Tax Calculator Result',
                body: 'Attached Tax Calculator Result',
                from: "no-reply@lexconnect.cloud",
                attachments: [
                    {
                        bucket: awsconfig.aws_user_files_s3_bucket,
                        path: `public/${taxesFilesPath}`,
                        fileName: taxesFileName
                    }
                ]
            };

            try {
                const api_name = "mail";
                const path = `/frontend/send`;
        
                const userAuth = `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`;
        
                const myInit = {
                    headers: {
                        Authorization: userAuth,
                        "Content-Type": "application/json"
                    },
                    body: emailData
                }
        
                let result_json = await API.post(api_name, path, myInit);
        
                if (!result_json.success) {
                    return { error: true, message: error_message };
                }
        
                return { error: false, message: "Email sent successfully" };
        
            } catch (error) {
                return { error: true, message: error };
            }
        }, 
        async fetchTaxInformation(total, tax_code, zip) {
            const authStore = useAuthStore();
            const ENVIRONMENT = authStore.getCurrentEnvironment;
          
            var error_message = 'Unable to Retrieve Tax Information';
            try {
                var userPartner = authStore.getCurrentDBUser.partner;
               
                const currentDate = new Date();

                const year = currentDate.getFullYear();
                const month = String(currentDate.getMonth() + 1).padStart(2, '0');
                const day = String(currentDate.getDate()).padStart(2, '0');

                const formattedDate = `${year}-${month}-${day}`;
                const json = JSON.stringify({
                    externalID: "123456789",
                    revenue: total,
                    taxCode: tax_code.value,
                    regulatoryCode: "03",
                    taxExemptionCodeList: "00",
                    quantity: "1",
                    zipCode: zip,
                    resOrBus: "R",
                    dateToProcess: formattedDate,
                    partner: userPartner,
                    decimal_places: 5
                });


                //return {error: false, message: 'Ok'};
                let result = await fetch(`https://${ENVIRONMENT}api.lexconnect.cloud/sure_tax/frontend/calculate`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${(await Auth.currentSession())
                            .getIdToken()
                            .getJwtToken()}`
                    },
                    body: json,
                });

                if(!result.ok) {
                    return {error: true, message: error_message};
                }

                let returnedJSON = await result.json();
               
                let response = returnedJSON.data;

                if(response.Successful == 'N') {
                    return {error: true, message: error_message};
                }

                if(response.GroupList.length > 0) {
                    this.taxes = [];
                    for(let element of response.GroupList[0].TaxList) {
                        var temp = {
                            taxAuthority: `${element.TaxAuthorityID} - ${element.TaxAuthorityName}`,
                            taxType: element.TaxTypeCode,
                            taxTypeDescription: element.TaxTypeDesc,
                            revenue: element.Revenue,
                            revenueBase: element.RevenueBase,
                            taxOnTax: element.TaxOnTax,
                            taxRate: `${(element.TaxRate * 100).toFixed(5)}%`,
                            feeRate: element.FeeRate,
                            pctTaxable: `${(element.PercentTaxable * 100).toFixed(5)}%`,
                            taxAmount: element.TaxAmount,
                        }
                        this.taxes.push(temp);
                    }
                } else {
                    let newObj = {};
                    newObj.TaxAuthorityID = "";
                    newObj.TaxAuthorityName = "";
                    newObj.TaxTypeCode = "";
                    newObj.TaxTypeDesc = "";
                    newObj.Revenue = "";
                    newObj.RevenueBase = "";
                    newObj.TaxOnTax = "";
                    newObj.TaxRate = 0;
                    newObj.FeeRate = "";
                    newObj.PercentTaxable = 0;
                    newObj.TaxAmount = "";
                    if(response.HeaderMessage == "Success"){
                        newObj.TaxTypeDesc = "No taxes";
                    }
                    else{
                        console.log(response);
                        newObj.TaxTypeDesc = "Error occured. Please reach out to an Admin if it continues.";
                    }

                    this.taxes = [newObj];
                }

                return {error: false, message: 'Ok'};
                
            }
            catch (error) {
                return {error: true, message: error};
            }
        },
        async fetchProducts() {
            this.products = [];
            this.productOptions = [];
            var error_message = 'Unable to Retrieve Products';
            try {
                var result = await this.makeProductAndTaxesCall();
                if(result.error) {
                    return {error: true, message: error_message};
                }
                
                //TODO: Set variables to real call data in the below format
                var returnedProducts = result.productData;

                for(let product of returnedProducts) {
                    var temp = {};
                    temp["item_total"] = product.unitPrice;
                    temp["tax_code"] = product.tax_class_id;
                    temp["productName"] = product.productName;
                    this.products[product.productName] = temp;
                    this.productOptions.push({value: product.productName, label: `${product.productName} - ${product.frequency}`});
                }

                var additionalProducts = {
                    "international_usage_product": {
                        "item_total": "0.00",
                        "tax_code": "050158",
                        "productName": "International Usage Product"
                    },
                    "toll_free_usage_product": {
                        "item_total": "0.00",
                        "tax_code": "010209",
                        "productName": "Toll Free Usage Product"
                    },
                    "411_directory_assistance": {
                        "item_total": "0.00",
                        "tax_code": "110302",
                        "productName": "411 Directory Assistance Product"
                    }
                };

                var additionalProductOptions = [
                    {
                        label: "International Usage Product",
                        value: "international_usage_product"
                    },
                    {
                        label: "Toll Free Usage Product",
                        value: "toll_free_usage_product"
                    },
                    {
                        label: "411 Directory Assistance Product",
                        value: "411_directory_assistance"
                    }
                ]

                this.products = {...this.products, ...additionalProducts};
                this.productOptions = this.productOptions.concat(additionalProductOptions);
                this.productOptions.sort((a,b) => a.label.localeCompare(b.label));
                return {error: false, message: 'Ok'}
                
            }
            catch (error) {
                return {error: true, message: error};
            }

        },
        async makeProductAndTaxesCall() {
            try {
                var hasData = true;
                var nextToken = null;
                var productsData = [];

                var listQuery = `query ListPricebooks(
                    $filter: ModelPricebooksFilterInput
                    $limit: Int
                    $nextToken: String
                  ) {
                    listPricebooks(filter: $filter, limit: $limit, nextToken: $nextToken) {
                        items {
                            PricebookProducts {
                                items {
                                    unit_price
                                    pricebook_default_product {
                                        name
                                        frequency
                                        primary_product_code
                                    }
                                }
                            }
                        }
                      nextToken
                      startedAt
                    }
                }`
                var authStore = useAuthStore();
                var userPartner = authStore.getCurrentDBUser.partner
                var filters = userPartner == "LogicomUSA" ? {} : {partner: {eq: userPartner}};

                while (hasData) {
                    var options = {
                        query: listQuery,
                        variables: {
                            filter: filters,
                            limit: 2000
                        },
                        authMode: GRAPHQL_AUTH_MODE.API_KEY
                    }

                    if (nextToken != null) {
                        options.variables.nextToken = nextToken;
                    }
                    var products = await API.graphql(options);
                    for (const item of products.data.listPricebooks.items) {
                        for(const product of item.PricebookProducts.items) {
                            var tempObj = product.pricebook_default_product;
                            tempObj['unit_price'] = product.unit_price;
                            productsData.push(tempObj);
                        }
                    }

                    if (products.data.listPricebooks.nextToken != null) {
                        nextToken = products.data.listPricebooks.nextToken;
                    }
                    else {
                        hasData = false;
                    }
                }

                var productDict = {};
                var product_code_list = [];
                for(let productObj of productsData) {
                    if(product_code_list.indexOf(productObj.primary_product_code) == -1) {
                        product_code_list.push(productObj.primary_product_code);
                        productDict[productObj.primary_product_code] = {
                            productName: productObj.name,
                            unitPrice: productObj.unit_price,
                            frequency: productObj.frequency
                        }
                    }
                }
                
                var revioListQuery = `query ListRevioProducts(
                    $filter: ModelRevioProductsFilterInput
                    $limit: Int
                    $nextToken: String
                  ) {
                    listRevioProducts(filter: $filter, limit: $limit, nextToken: $nextToken) {
                      items {
                        code_1
                        tax_code {
                            items {
                                tax_code
                            }
                        }
                      }
                      nextToken
                      startedAt
                    }
                }`
                
                var taxesFilter = {};
                var innerFilter = [];
                for(let item of product_code_list) {
                    innerFilter.push({code_1: {eq: item}});
                }
                taxesFilter['or'] = innerFilter;
                hasData = true;
                nextToken = null;
                var taxCodeData = [];
                while (hasData) {
                    options = {
                        query: revioListQuery,
                        variables: {
                            filter: taxesFilter,
                            limit: 2000
                        },
                        authMode: GRAPHQL_AUTH_MODE.API_KEY
                    }

                    if (nextToken != null) {
                        options.variables.nextToken = nextToken;
                    }

                    var codes = await API.graphql(options);

                    for (const item of codes.data.listRevioProducts.items) {
                        taxCodeData.push(item);
                    }

                    if (codes.data.listRevioProducts.nextToken != null) {
                        nextToken = codes.data.listRevioProducts.nextToken;
                    }
                    else {
                        hasData = false;
                    }
                }
                console.log('Tax Code Data: ', taxCodeData);
                for(let code of taxCodeData) {
                    productDict[code.code_1]['tax_class_id'] = code.tax_code?.items?.[0]?.tax_code || "";
                }
                var finalList = Object.values(productDict);
                
                return {error: false, message: 'Ok', productData: finalList};
            }
            catch (error) {
                //return {error: true, message: "Unable to Get Products"};
                return {"error": true, message: error}
            }
        },
        async sendEmail(email, zipCode, itemTotal, taxCode) {
            if(email == "") {
                return {error: false, message: "No Email"};
            }
            if(taxCode == "") {
                return {error: false, message: "No tax code"};
            }
            if(itemTotal == "") {
                return {error: false, message: "No Item Total"};
            }
            if(zipCode == "") {
                return {error: false, message: "No Zip Code"};
            }
            
            var zipCodes = {};
            zipCodes[`${zipCode}`] = email;
            var taxCodes = [taxCode];
            const currentDate = new Date();
            

            var outputArr = [];

            Object.entries(zipCodes).forEach(([zipCode, email]) => {
                for(let code of taxCodes) {
                    var tax_json_output = fetchTaxJsonForEmail(zipCode, code);
                    outputArr.push(tax_json_output);
                }
            });


            let content = "";

            let formattedDate = currentDate.toLocaleDateString('en-US', {
                month: '2-digit',
                day: '2-digit',
                year: 'numeric'
            });
            content = "Below is the tax information as of " + formattedDate + ": <br><br>\n\n";

            for(let index of outputArr){
                if(Object.keys(index).indexOf("TAX INFO") >= 0 && index["TAX INFO"].indexOf("<td>") !== false){
                    for(let item in index){

                        content += "<b>" + item + ":</b> " + index[item] + "<br>\n";
                    }
                    content += "<br><br>\n\n";
                }
            }
            content += "Thank you.<br>\n";

            const from = "system@logicomusa.net";
            const subject = "";

            const json = {
                to: [email],
                from: from,
                subject: subject,
                body: content
            }

            // let result = await fetch(`https://${ENVIRONMENT}api.lexconnect.cloud/mail/frontend/send`, { //TODO: Use environment
            //     method: 'POST',
            //     headers: {
            //         'Content-Type': 'application/json',
            //         Authorization: `Bearer ${(await Auth.currentSession())
            //             .getIdToken()
            //             .getJwtToken()}`
            //     },
            //     body: json,
            // });
        },
        fetchTaxJsonForEmail(zipCode, taxCode) {
            let external_id = "123456789";
            let regulatoryCode = "03";
            let sureTaxExemption = "00";
            let sureTaxExemptionReasonCode = "";
            let quantity = "";
            let custType = "R";
            const currentDate = new Date();
            let formattedDate = currentDate.toISOString().substring(0, 10);

            //TODO: Insert call to suretax

            var outputObj = {};
            outputObj["ZIP CODE"] = zipCode;
            outputObj["TAX CODE"] = taxCode;
            outputObj["CITY"] = "";
            outputObj["COUNTY"] = "";

            outputObj["TAX INFO"] = "<br/><table border='1' text='center'><tr><th>Tax Athority</th><th>Tax Type</th><th>Tax Type Description</th><th>Revenue</th><th>Revenue Base</th><th>Tax On Tax</th><th>Tax Rate</th><th>Fee Rate</th><th>Pct Taxable</th><th>Tax Amount</th></tr>";


            for(var tax_item of tax_json_output) {
                outputObj["CITY"] = tax_item.CityName;
                outputObj["COUNTY"] = tax_item.CountyName;

                tax_info = "<tr>";
                tax_info += "<td>" + tax_item.TaxAuthorityID + " - " + tax_item.TaxAuthorityName  + "</td>";
                tax_info += "<td>" + tax_item.TaxTypeCode + "</td>";
                tax_info += "<td>" + tax_item.TaxTypeDesc + "</td>";
                tax_info += "<td>" + itemTotal + "</td>";
                tax_info += "<td>" + tax_item.RevenueBase + "</td>";
                tax_info += "<td>" + tax_item.TaxOnTax + "</td>";
                tax_info += "<td>" + decimalToPercent(tax_item.TaxRate) + "%</td>";
                tax_info += "<td>" + tax_item.FeeRate + "</td>";
                tax_info += "<td>" + decimalToPercent(tax_item.PercentTaxable) + "%</td>";
                tax_info += "<td>" + tax_item.TaxAmount + "</td>";
                tax_info += "</tr>";      
                outputObj["TAX INFO"] += tax_info;
                
                total_tax += tax_item.TaxAmount;
            }

            outputObj["TAX INFO"] += "</table>";
            outputObj["TAX INFO"] += "<b>TOTAL TAX:</b> \$" + $total_tax + " <br/>";
            return outputObj;         
        },
        uploadFileToS3(fileName, fileContent) {
            const s3 = new AWS.S3();
        
            const params = {
                Bucket: "lex-connect-data-files/frontend/",
                Key: fileName,
                Body: fileContent,
                ContentType: 'text/csv'
            };
            return s3.upload(params).promise();
        },
        downloadAndDeleteFile(filename) {

        }

        
    },
})

