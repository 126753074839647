<template>
  <div>
    <AuditOverviewDrawer
      v-model:drawerOpen="localDrawer"
      :drawerData="drawerData"
      @button-click="handleButtonClick"
      @close-click="handleCloseClick"
      @submit-click="handleApplyFilter"
      :numCols="12"
    />
    <div class="auditOverviewTableContainer">
      <AuditOverviewTable
        :headers="tableHeaders"
        :data-list="getTableData"
        background-color="#e2f1f4"
        :single-select="true"
        :select-all="false"
        uniqueKey="id"
        :fixedWidth="true"
        :left-nav-present="true"
        @selected-item="handleSelectedItem"
        @edit-row="handleEditRow"
        @click-row="handleClickRow"
      />
    </div>
    <AlertsGroup
      v-model:alerts="alerts"
    />
  </div>
</template>

<script>
import AuditOverviewTable from "@/components/CcManagement/Attendance/Table/AuditOverviewTable.vue";
import AuditOverviewDrawer from '@/components/CcManagement/Attendance/Drawer/AuditOverviewDrawer.vue';
import AlertsGroup from '@/components/base_components/AlertsGroup.vue';
import { ERROR } from "@/constants/statuses.constants.js";
import { addAlert } from '@/helpers/alertUtils';

import { useQualityAuditStore } from "@/stores/ContactCenter/Management/QualityAuditStore.js";
import { mapStores } from 'pinia';

export default {
  name: 'AuditOverview',
  components: {
    AuditOverviewTable,
    AuditOverviewDrawer,
    AlertsGroup
  },
  props: {
    drawer: {
      type: Boolean,
      default: false
    },
  },
  data () {
    return {
      tableHeaders: [
        { text: "Employee", value: "employee" },
        { text: "Number of Audits", value: "number_of_audits" },
        { text: "Average Total Score", value: "average_total_score" },
        { text: "Average Percent Score", value: "average_percent_score" },
        { text: "Average Tone Score", value: "average_tone_score" },
        { text: "Average Ticket & Troubleshoot Score", value: "average_ticket_score" },
        { text: "Compliance Score", value: "compliance_score" },
        { text: "Average Strong Greeting & Ending Score", value: "average_greeting_score" },
        { text: "Average Documentation Score", value: "average_documentation_score" },
      ],
      tableData:  [
 
      ],
      drawerData: [
        {
          title: 'Filters', button: 'Apply', type: 'apply'
        },
        {
          title: "",
          details: [
            {
              name: 'Start Date',
              type: 'datePicker',
              value: 'start_date'
            },
            {
              name: 'End Date',
              type: 'datePicker',
              value: 'end_date'
            }
          ]
        }
      ],
      alerts: [],
      filterParams: {
        start_date: null,
        end_date: null,
      },
    }
  },
  async mounted() {
    try {
      const result = await this.QualityAuditStore.fetchQualityAuditsOverview();
      //console.log("result this.table", result);
      this.tableData = result; 
    } catch (error) {
      console.error("Error fetching Quality Audits Overview:", error);
      
    }
  },

  computed: {
    ...mapStores(useQualityAuditStore),
    getTableData () {
      if (this.tableData && this.tableData.length > 0 ) {
        return this.tableData.map(item => {
          return {
            employee: item.employee,
            number_of_audits: item.number_of_audits,
            average_total_score: this.tableColor(this.convertCoordinate(item.average_total_score), 'average_total_score'),
            average_percent_score: this.tableColor(this.convertCoordinate(item.average_percent_score), 'average_percent_score'),
            average_tone_score: this.tableColor(this.convertCoordinate(item.average_tone_score), 'average_tone_score'),
            average_ticket_score: this.tableColor(this.convertCoordinate(item.average_tone_score), 'average_ticket_score'),
            compliance_score: item.compliance_score,
            average_greeting_score: this.tableColor(this.convertCoordinate(item.average_greeting_score), 'average_greeting_score'),
            average_documentation_score: this.tableColor(this.convertCoordinate(item.average_documentation_score), 'average_documentation_score')
          }
        })
      } else {
        return [];
      }
      
    },
    localDrawer: {
      get () {
        return this.drawer;
      },
      set (drawer) {
        this.$emit('update:drawer', drawer);
      }
    },
  },
  methods: {
    convertCoordinate(preciseCoord) {
      // Check if there are more than one decimal place
      if ((preciseCoord % 1).toFixed(2) !== '0.00') {
          return preciseCoord.toFixed(1) + "";
      } else {
          return preciseCoord + "";
      }
  },
    tableColor(value, field) {
      let color;
      const HIGH_VALUE = 'edit-cell-dark';
      const LOW_VALUE = 'edit-cell-light';

      const fieldThresholds = {
        average_total_score: 85,
        average_percent_score: 85,
        average_tone_score: 19,
        average_ticket_score: 35,
        average_greeting_score: 9,
        average_documentation_score: 21,
      };

      const threshold = fieldThresholds[field];
      const formatValue = field === 'average_percent_score' ? `${value}%` : value

      if (threshold !== undefined) {
        color = value > threshold ? HIGH_VALUE : LOW_VALUE;
      } else {
        color = LOW_VALUE;
      }

      return {
        value: formatValue,
        color,
      };
    },
    async handleApplyFilter({ start_date, end_date }) {
      console.log("FILTER", { start_date, end_date });

      if (!start_date && end_date) {
        addAlert(ERROR, 'Missing Start Date field', this.alerts);
      } else if (start_date && !end_date) {
        addAlert(ERROR, 'Missing End Date field', this.alerts);
      } else if (start_date && end_date) {
        const params = { start_date, end_date };

        try {
          const result = await this.QualityAuditStore.fetchQualityAuditsOverview(params);
          this.tableData = result;
        } catch (error) {
          console.error("Error fetching filtered Quality Audits:", error);
        }
      } else {
        try {
          const result = await this.QualityAuditStore.fetchQualityAuditsOverview();
          this.tableData = result;
        } catch (error) {
          console.error("Error fetching Quality Audits:", error);
        }
      }
    },
    handleClickRow (data) {
      console.log(data);
    }
  },
  unmounted () {
    this.localDrawer = false;
  },
  emits: [
    "update:drawer"
  ]
}
</script>

<style scoped>
.auditOverviewTableContainer {
  padding: 0px 20px 20px 20px;
}
.sideDrawer {
  position: fixed;
  top: auto;
  z-index: 99;
  bottom: 0;
  min-height: calc(100vh - 93px);
  max-height: calc(100vh - 93px);
}
</style>