<template>
  <h1 class="header">Tickets Created</h1>
  <div class="row">
    <div class="infoContainer">
      <div>
        <span class="queueNumber">{{
          queue.logicomUSAAndOverflowTickets.first
        }}</span>
        <div class="ticketsCreated">
          <img
            :src="require(`@/assets/icons/LogicomUSA.png`)"
            class="waitingImg"
          /><span class="infoLabel">LogicomUSA</span>
        </div>
      </div>
      <div>
        <span class="queueNumber">{{
          queue.logicomUSAAndOverflowTickets.second
        }}</span>
        <div class="ticketsCreated">
          <img
            :src="require(`@/assets/icons/Overflow.png`)"
            class="waitingImg"
          /><span class="infoLabel">Overflow</span>
        </div>
      </div>
    </div>
    <div class="donutContainer">
      <DoughnutChart :queue="queue" :style="{ marginBottom: '30px' }" />
    </div>
  </div>
</template>

<script>
import DoughnutChart from "./DoughnutChart.vue";
export default {
  name: "TicketsCreatedCard",
  props: {
    queue: Object,
  },
  data() {
    return {
      legendData: [
        {
          name: "Answered Calls",
          icon: "Inbound.png",
        },
        {
          name: "Missed Calls",
          icon: "Missed.png",
        },
        {
          name: "Abandoned Calls",
          icon: "Abandonded.png",
        },
        {
          name: "Calls Handed to Overflow",
          icon: "Overflow.png",
        },
      ],
    };
  },

  components: { DoughnutChart },
};
</script>
<style scoped>
.header {
  padding: 10px 15px 15px 15px;
  font-size: 11pt;
  color: #271d7a;
}
.row {
  display: flex;
  flex-direction: row;
  text-align: center;
  flex-wrap: wrap;
  justify-content: center;
  align-items: stretch;
}
.infoContainer {
  flex: 1 40%;
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-bottom: 20px;
  box-shadow: inset -5px 1px 2px -5px rgba(39, 29, 122, 0.2);
}
.donutContainer {
  flex: 1 55%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  height: 100%;
  width: 100%;
}
.column {
  padding-left: 20px;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 10px;
}
.column span {
  flex-grow: 1;
}
.queueNumber {
  font-size: 30pt;
  color: #271d7a;
  font-weight: 500;
  text-align: center;
}
.waitingImg {
  width: 10px;
  height: 10px;
  margin-top: 6px;
}
.ticketsCreated {
  display: flex;
  text-align: center;
  justify-content: center;
  width: 100%;
}
.label {
  text-align: left;
  padding-left: 10px;
  font-size: 11pt;
  color: #271d7a;
  font-weight: 500;
  display: flex;
  align-items: center;
  text-align: left;
  width: 140px;
}
.infoLabel {
  text-align: center;
  padding-left: 10px;
  font-size: 11pt;
  color: #271d7a;
  font-weight: 500;
  display: flex;
  align-items: center;
  display: inline-flex;
}
.icon {
  width: 10px;
  height: 10px;
}
.legendContainer {
  display: inline-flex;
  flex: 1;
  align-items: center;
  margin-bottom: 10px;
  flex-wrap: wrap;
}
</style>
