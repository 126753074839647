<template>
    <span class="inputEx textarea" role="textbox" contenteditable>{{
      value
    }}</span>
  </template>
  
  <script>
  export default {
    name: "ExpandableFillInBubble",
    props: {
      placeholder: String,
      value: String,
    },
  };
  </script>
  
  <style scoped>
  .textarea {
    display: block;
    max-width: 100%;
    overflow: hidden;
    resize: both;
    min-height: 28px;
    line-height: 10px;
  }
  
  .inputEx {
    outline: #2e3e91 solid 0.25pt;
    border-radius: 2px;
    padding-left: 8px;
    padding-top: 9px;
    padding-bottom: 2px;
    font-size: 9pt;
    font-weight: 500;
    color: #271d7a;
    background-color: #ffffff;
    height: 28px;
  }
  .inputEx:hover {
    outline: #2e3e91 solid 0.5pt;
  }
  .inputEx:focus {
    outline: #2e3e91 solid 1pt;
  }
  .inputEx::placeholder {
    opacity: 0;
    size: 9pt;
    color: #b1cdea;
  }
  .inputEx:focus::placeholder {
    opacity: 1;
  }
  </style>