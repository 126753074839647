<template>
    <v-container id="container" fluid v-if="!USER_IS_LOGGED_IN">
      <v-layout full-height>
        <v-row align-content="center" no-gutters>
          <v-col cols="7" id="image_container"></v-col>
          <v-col id="login_container">
            <LoginComponent/>
          </v-col>
        </v-row>
      </v-layout>
    </v-container>
</template>


<script setup>
// import loginForm from "@/components/auth/loginForm.vue";
import LoginComponent from "@/components/auth/LoginComponent.vue";
import { useAuthStore } from "@/stores/auth/authStore";
import { computed } from 'vue';

const authStore = useAuthStore();


const USER_IS_LOGGED_IN = computed(() => {
  return authStore.getUserAuthStatus;
});



</script>


<style scoped>
  #image_container {
    height: 100%;
    background: url("@/assets/loginpage_image.png") top center no-repeat;
    background-size: 100%, contain;
  }

  #container {
    height: 100%;
    padding: 0px;
  }


  @media screen and (max-width: 960px) {
    #login_container {
      height: 100%;
      background: url("@/assets/loginpage_image.png") center center no-repeat;
      background-size: cover;
      background-position: center;
    }

    #image_container {
      display: none;
    }

  }


  @media screen and (min-width: 960px) {
      #image_container {
        height: 100%;
        background: url("@/assets/loginpage_image.png") center center no-repeat;
        background-size: cover;
        background-position: center;
      }
  }



  @media screen and (min-width: 1200px) {
      #image_container {
        height: 100%;
        background: url("@/assets/loginpage_image.png") left center no-repeat;
        background-size: cover;
        background-position: center;
      }
  }


</style>