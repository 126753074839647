<template>
    <button v-if="button_type == 'tooltip'" :data-tooltip="title" :class="{[$style[button_type]]: true, tool_tip_on_bottom}" @click.prevent="handleButtonClick">
        <slot name="tooltip_button_slot"></slot>
    </button>
    <button
        v-else
        @click.prevent="handleButtonClick"
        :class="{ 
            [$style[button_type]]: true, 
            active, 
            disabled, 
            fitToText: width == 'fitToText', 
            fillParentWidth: width == 'parent' 
        }"
    >
        <slot name="left-icon">
            <img
                :class="{leftIconWithTitle: title, iconNoTitle: !title}"
                v-if="left_icon"
                v-bind:src="require(`@/assets/icons/${left_icon}`)"
            />
        </slot>
        <slot name="button-text">
            <span class="buttonText" v-if="title">{{ title }}</span>
        </slot>
        <slot name="left-icon">
            <img
                :class="{rightIconWithTitle: title, iconNoTitle: !title}"
                v-if="right_icon"
                v-bind:src="require(`@/assets/icons/${right_icon}`)"
            />
        </slot>
    </button>
</template>

<script setup>
import { defineProps, defineEmits, useCssModule } from "vue";

/*
Not Including"
    RemoveButton.vue
        - This looks more appropriate for a rebuilt list component.
    ClickableMediumBlueButton.vue
        - This is a file upload component that looks like a button.  It is not a true button.
*/


useCssModule();
const emit = defineEmits(['click']);

const PROPS = defineProps({
    button_type: {
        type: String,
        default: 'medium'
    },
    right_icon: {
        type: String,
        default: ''
    },
    left_icon: {
        type: String,
        default: ''
    },
    title: {
        type: String,
        default: ''
    },
    active: {
        type: Boolean,
        default: false
    },
    width: {
        type: String,
        default: ''
    },
    disabled: {
        type: Boolean,
        default: false
    },
    value: {
        type: String,
        default: null
    },
    tool_tip_on_bottom: {
        type: Boolean,
        default: false
    }
});


function handleButtonClick() {
    emit('click', PROPS.title, PROPS.value);
}

</script>

<style module>
/* Light Button CSS */
.light {
  padding: 5px 20px 5px 20px;
  background-color: #fcfdfe;
  font-size: 10pt;
  font-weight: 600;
  filter: drop-shadow(1px 1px 1px rgba(39, 29, 122, 0.2));
  color: #2e3e91;
  border-radius: 2px;
  outline: #2e3e91 solid 0.25pt;

}
.light:hover {
  outline: #2e3e91 solid 1pt;
}
.light .buttonText {
  display: flex;
  align-items: center;
}
.light:active, .light.active {
  padding: 5px 20px 5px 20px;
  background-color: #ecf2f8;
  outline: #2e3e91 solid 0.25pt;
  filter: none;
  font-size: 10pt;
  box-shadow: 0 0 4px rgba(57, 111, 186, 0.4) inset;
  color: #2e3e91;
}

/* Medium Button CSS */
.medium {
  padding: 5px 20px 5px 20px;
  background-color: #3e87d3;
  font-size: 10pt;
  font-weight: 600;
  filter: drop-shadow(1px 1px 1px rgba(39, 29, 122, 0.1));
  color: white;
  border-radius: 2px;
}
.medium:hover {
  outline: #271d7a solid 1pt;
}
.medium:active, .medium.active {
  padding: 5px 20px 5px 20px;
  background-color: #3556a6;
  outline: #2e3e91 solid 0.25pt;
  filter: none;
  box-shadow: 0 0 4px rgba(43, 45, 132, 0.6) inset;
  color: white;
}

/* Dark Button CSS */
.dark {
  padding: 5px 20px 5px 20px;
  background-color: #2b2d84;
  color: white !important;
  font-size: 10pt;
  font-weight: 600;
  filter: drop-shadow(1px 1px 1px rgba(39, 29, 122, 0.1));
  border-radius: 2px;
}
.dark:hover {
  outline: #84b1e0 solid 1pt;
}

.dark:active, .dark.active {
  padding: 5px;
  background-color: #84b1e0;
  outline: #2b2d84 solid 0.25pt;
  padding-left: 20px;
  padding-right: 20px;
  font-size: 10pt;
  font-weight: 600;
  box-shadow: 0 0 4px rgba(57, 111, 186, 0.6) inset;
  filter: none;
  color: white;
}

/* Tooltip Button CSS */
.tooltip:hover::before {
  content: attr(data-tooltip);
  background: #6b9fd9;
  color: white;
  padding: 0.25rem 0.4rem;
  width: max-content;
  opacity: 100%;
  border-radius: 2.5px;
  position: absolute;
  top: -0.25rem;
  font-size: 10pt;
  outline: none;
  left: 50%;
}

.tooltip::before {
  transform: translateX(-50%) translateY(-100%);
}

.tooltip.tool_tip_on_bottom::before {
  transform: translateX(-50%) translateY(133%);
}

.tooltip {
  position: relative;
}
</style>

<style scoped>
.rightIconWithTitle {
  height: 12px;
  margin-left: 7px;
  margin-right: 2px;
}
.leftIconWithTitle {
  height: 12px;
  margin-right: 7px;
  margin-left: 2px;
}
.iconNoTitle {
  width: 16px;
  height: 16px;
  margin-right: 2px;
  margin-left: 2px;
}
.fillParentWidth {
  width: 100% !important;
}
.fitToText {
  padding-left: 20px;
  padding-right: 20px;
}
.disabled {
    opacity: .5;
    pointer-events: none;
}
</style>