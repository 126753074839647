<template>
    <div>
      <Doughnut
        :data="chartDataQueue"
        :options="chartOptions"
        :plugins="[centerText]"
        :width="160"
        :height="160"
      ></Doughnut>
    </div>
  </template>
  <script setup>
  import { Doughnut } from "vue-chartjs";
  import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
  
  ChartJS.register(ArcElement, Tooltip, Legend);
  </script>
  
  <script>
  export default {
    name: "DoughnutChart",
    props: {
      queue: Object,
    },
    components: { Doughnut },
    data() {
      return {
        chartOptions: {
          responsive: false,
          maintainAspectRatio: false,
          cutout: "85%",
          plugins: {
            legend: {
              display: false,
            },
            tooltip: {
              enabled: false,
            },
          },
        },
        centerText: {
          id: "centerText",
          afterDatasetsDraw(chart) {
            let images = ["/assets/LogicomUSA.png", "/assets/Overflow.png"];
            const {
              ctx,
              chartArea: { top, width, height },
            } = chart;
  
            let dataset = chart.data.datasets[0].data;
            let activeIndex = chart.getActiveElements()[0]?.index;
  
            let activeData = dataset[activeIndex]
              ? dataset[activeIndex] + "%"
              : "";
            let activeLabel = chart.data.labels[activeIndex] || "";
  
            ctx.font = "500 26pt Red Hat Display";
            ctx.fillStyle = "rgba(39, 29, 122, 1)";
            ctx.textAlign = "center";
            ctx.fillText(activeData, width / 2, height / 2 + top);
  
            var img = document.createElement("img");
            if (activeIndex !== undefined) {
              img.src = images[activeIndex];
            }
            let text = ctx.measureText(activeLabel);
  
            ctx.drawImage(
              img,
              width / 2 - text.actualBoundingBoxRight / 2 - 3,
              height / 2 + top + 22 - 11,
              12,
              12
            );
            ctx.font = "12pt Red Hat Display";
            ctx.fillStyle = "rgba(39, 29, 122, 1)";
            ctx.fillText(activeLabel, width / 2 + 10, height / 2 + top + 22, 100);
            ctx.save();
          },
        },
      };
    },
    computed: {
      chartDataQueue() {
      var sum = parseFloat(this.queue.tickets.logicom) + parseFloat(this.queue.tickets.overflow);
      var logicom = Math.round((parseFloat(this.queue.tickets.logicom) / sum) * 100);
      var overflow = Math.round((parseFloat(this.queue.tickets.overflow) / sum) * 100);      

        return {
          labels: ["LogicomUSA", "Overflow"],
          datasets: [
            {
              backgroundColor: ["#3e87d3", "#e87928"],
              data: [
                logicom,
                overflow,
              ],
              hoverBackgroundColor: ["#3e87d3", "#e87928"],
  
              hoverBorderColor: "#271d7a",
              hoverBorderWidth: 1,
            },
          ],
  
          hoverOffset: 4,
        };
      },
    },
  };
  </script>
  <style scoped></style>
  