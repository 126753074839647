<template>
    <div class="textToSpeechView">

        <SecondaryNavBar title="SwitchOps - Text-to-Speech Converter" />
        <div class="sideDrawer">
            <SideDrawer :drawerOpen="playTextToSpeech" buttonType="Close" :drawerData="sideDrawerData"
                @close-click="handleCloseClick" />
        </div>
        <div class="contentContainer" :style="{ height: dynamicHeight - 60 + 'px' }">
            <div class="form-container">
                <FormComp :formData="formData" title="Add Device" :num-cols="8" :buttonOptions="buttonOptions"
                    @submission-data="openSidePanel">
                </FormComp>
            </div>
            <AlertsGroup
                v-model:alerts="alerts"
            />
        </div>
    </div>
</template>




<script>
import { reactive } from "vue";
import SecondaryNavBar from "@/components/base_components/Navigation/SecondaryNavBar.vue";
import FormComp from "@/components/base_components/FormComponents/FormComp.vue";
import SideDrawer from "@/components/textToSpeech/SideDrawer.vue";
import AlertsGroup from "@/components/base_components/AlertsGroup.vue";
import { addAlert } from '@/helpers/alertUtils';
import { ERROR } from '@/constants/statuses.constants';
import { useTextToSpeechStore } from "@/stores/SwitchOps/TextToSpeech/textToSpeechStore.js";
import { useHelpStore } from '@/stores/Help/helpStore.js';
import { mapStores, mapActions } from 'pinia';

export default {
    name: "textToSpeechView",
    data() {
        return {
            alerts: [],
            playTextToSpeech: false,
            sideDrawerData: reactive([
                { title: "Text-to-Speech Converter", type: "textToSpeech", button: "Close" },
            ]),
            buttonOptions: {
                positionBottom: false,
                centered: true,
                fillToParent: true,
                divider: true
            },
            formData: [
                { title: "Text-to-Speech Converter", type: "form", button: "Play Text-to-Speech" },
                {
                    inputs: [
                        {
                            name: "Voice",
                            type: "select",
                            required: true,
                            options: [
                                { label: "Female 1", value: "en-US-Wavenet-C" },
                                { label: "Female 2", value: "en-US-Wavenet-E" },
                                { label: "Female 3", value: "en-US-Wavenet-F" },
                                { label: "Male 1", value: "en-US-Wavenet-A" },
                                { label: "Male 2", value: "en-US-Wavenet-B" },
                                { label: "Male 3", value: "en-US-Wavenet-D" }],
                        }
                    ],
                },
                {
                    inputs: [
                        {
                            name: "Speed",
                            type: "select",
                            required: true,
                            options: [
                                { label: "Extremely Slow", value: 0.25 },
                                { label: "Very Slow", value: 0.5 },
                                { label: "Slow", value: 0.75 },
                                { label: "Default", value: 1 },
                                { label: "Fast", value: 1.25 },
                                { label: "Very Fast", value: 1.5 },
                                { label: "Ludicrous Speed", value: 2 }],
                        }
                    ],
                },
                {
                    inputs: [
                        {
                            name: "Pitch",
                            type: "select",
                            required: true,
                            options: [
                                { label: "Extreemly Low", value: -20 },
                                { label: "Even Lower", value: -15 },
                                { label: "Lower", value: -10 },
                                { label: "Low", value: -5 },
                                { label: "Default", value: 0 },
                                { label: "High", value: 5 },
                                { label: "Higher", value: 10 },
                                { label: "Even Higher", value: 15 },
                                { label: "Extreemly High", value: 20 },
                            ],
                        }
                    ],
                },
                {
                    inputs: [
                        {
                            name: "Audio Device Profile",
                            type: "select",
                            options: [
                                { label: "Default", value: "" },
                                { label: "Smart Watch or Wearable", value: ["wearable-class-device"] },
                                { label: "Smartphone", value: ["handset-class-device"] },
                                { label: "Headphones or Earbuds", value: ["headphone-class-device"] },
                                { label: "Small Bluetooth Speaker", value: ["small-bluetooth-speaker-class-device"] },
                                { label: "Smart Bluetooth Speaker", value: ["medium-bluetooth-speaker-class-device"] },
                                { label: "Home Entertainment System or Smart TV", value: ["large-home-entertainment-class-device"] },
                                { label: "Car Speaker", value: ["large-automotive-class-device"] },
                                { label: "Interative Voice Response(IVR) System", value: ["telephony-class-application"] }
                            ],
                        }
                    ],
                },
                {
                    inputs: [
                        {
                            name: "Message",
                            type: "expandableBubble",
                            required: true,
                        }
                    ],
                }
            ]
        }
    },
    components: {
        SecondaryNavBar,
        FormComp,
        SideDrawer,
        AlertsGroup
    },
    mounted() {
        this.HelpManagementStore.setPage('SwitchOps / Text-to-Speech Converter');
        this.playTextToSpeech = false;
    },
    watch: {
        windowHeight() {
            this.$nextTick(() => {
                this.dynamicHeight;
            });
        },
    },
    computed: {
        ...mapStores(useTextToSpeechStore, useHelpStore),
    },
    methods: {
        ...mapActions(useTextToSpeechStore, ['ConvertTextToSpeech']),
        ...mapActions(useHelpStore, ['setPage']),
        async openSidePanel(formData) {
            if (formData != "Error") {
                this.sideDrawerData[0].audioSource = "";
                const result = await this.TextToSpeechStore.ConvertTextToSpeech(formData.Message, formData.Voice.value, formData.Pitch.value, formData.Speed.value, formData["Audio Device Profile"].value[0]);

                var audio = new Audio("data:audio/mp3;base64," + result.data.audioContent);

                this.sideDrawerData[0].audioSource = audio.getAttribute("src");

                this.playTextToSpeech = true;
            }
            else {
                // alert that not all fields should 
                addAlert(ERROR, "Please fill in all values!", this.alerts);
            }
        },
        handleCloseClick() {
            this.playTextToSpeech = false;
        }
    },
};


</script>


<style scoped>
.textToSpeechView {
    background-color: #e2f1f4;
    height: 100%;
}

.form-container {
    margin: 20px 0px 20px 20px;
    width: 45%;
}

.contentContainer {
    display: flex;
    flex-grow: 1;
    position: relative;
    background-color: #e2f1f4;
}

:deep(.textarea) {
  min-height: 70px;
  resize: vertical;
}

:deep(.formSectionDivider) {
    margin-bottom: 4px;
    margin-top: 0px;
}

@media (max-width: 820px) {
    .textToSpeechView .form-container{
        width: 100%;
        margin: 20px;
    }
}
</style>