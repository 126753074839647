<template>
  <div
    class="sideDrawer formBackground"
    :class="{
      open: drawerOpen,
      top: topAligned,
    }"
    :style="{ width: dynamicWidth + 'px' }"
  >
    <button class="sideDrawerRemoveButton" @click="handleClose">
      <img
        class="sideDrawerRemoveButton"
        :src="require('@/assets/icons/Remove.png')"
      />
    </button>
    <h1 class="createFlowHeader">View Flow {{ currentFlow?.flowName }}</h1>
    <div class="column">
      <ViewFlow
        :questions="currentFlowCopy"
        @changeFocus="handleChangeFocus"
        @toggle="toggleCard"
        @addDependentToFlow="handleAddDependent"
      />
    </div>
  </div>
</template>

<script>
/* TODO: FIX THIS FILE IT HAS ESLINT ISSUES */
import ViewFlow from "./ViewFlow.vue";

export default {
  name: "ViewFlowSideDrawer",
  props: {
    drawerOpen: Boolean,
    drawerData: Array,
    width: Number,
    currentFlow: Object,
    numCols: { type: Number, default: 6 },
    topAligned: { type: Boolean, default: true },
  },
  data() {
    return {
      submissionData: {},
      addingDependent: false,
      currentDependentQ: {},
      level: 0,
      currentFlowCopy: [],
      searchValue: "",

      windowHeight: null,
      selectedItemView: {},
    };
  },
  mounted() {
    window.addEventListener("resize", this.handleResize);
    this.windowHeight = window.innerHeight;
  },

  beforeUnmount() {
    window.removeEventListener("resize", this.handleResize);
  },
  computed: {
    dynamicHeight() {
      return Number(this.windowHeight) - 39;
    },
    dynamicWidth() {
      return 550;
    },
  },
  watch: {
    drawerData() {
      this.submissionData = {};
    },
    windowHeight() {
      this.$nextTick(() => {
        this.dynamicHeight;
      });
    },
    currentFlow: {
      deep: true,
      immediate: true,
      handler(newFlow) {
        if(this.drawerOpen) {
          this.handleRetrieveFlow(newFlow);
        }
      },
    },
  },
  methods: {
    handleRetrieveFlow(flow) {
      this.selectedItemView = JSON.parse(JSON.stringify(flow));
      this.currentFlowCopy = flow.staging_json;
    },
    handleClose() {
      this.$emit("close-click");
    },
    toggleCard(index, type, dependent) {
      if (type === "flow") {
        if (dependent !== undefined) {
          dependent.open = !dependent.open;
        } else {
          this.currentFlowCopy[index].open = !this.currentFlowCopy[index].open;
        }
      } else {
        this.currentFlowCopy[index].open = !this.currentFlowCopy[index].open;
      }
    },
    handleChangeFocus() {
      this.currentDependentQ = {};
    },
    handleAddDependent(option, question) {
      this.currentDependentQ = { option, question };
    },
  },
  components: {
    ViewFlow,
  },
  emits: [
    "close-click",
    "submit-click",
    "add-category"
  ]
};
</script>

<style scoped>
.sideDrawer {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 6;
  bottom: 0;
  background-color: #f4fafa;
  overflow-x: auto;
  transition: transform 0.2s ease;
  transform: translateX(100%);
  display: flex;
  flex-direction: column;
}
.top {
  top: 93px;
}
.sideDrawer.open {
  transform: translateX(0);
  filter: drop-shadow(5px 5px 5px rgb(39, 29, 122, 1));
}
.drawerTitle {
  color: #271e7a;
  font-weight: bold;
  font-size: 14pt;
  padding-top: 5px;
}
.drawerCloseContainer {
  position: absolute;
  bottom: 20px;
  width: 100%;
}

.sideDrawerDivider {
  margin-bottom: 16px;
}
.sideDrawerRemoveButton {
  position: absolute;
  top: 0;
  right: 0;
  width: 31px;
  height: 35px;
  padding: 20px 16px 0px 0px;
}
.formBackground {
  background-color: #ffffff;
}
.drawerCloseButton {
  padding: 0px 20px 20px 20px;
}
.questionSearch {
  padding-left: 30px;
}
.createFlowHeader {
  padding-top: 16px;
  padding-left: 16px;
  padding-bottom: 10px;
  font-size: 14pt;
  font-weight: bold;
  color: #271d7a;
}
.row {
  display: flex;
  flex-direction: row;
  padding: 20px;
  height: 80%;
}
.column {
  padding: 0px 6px 6px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
}
.label {
  font-size: 10pt;
  color: #271d7a;
  padding-left: 10px;
  font-weight: 500;
}
.submitButton {
  display: flex;
  justify-content: center;
  margin: auto;
}
</style>
