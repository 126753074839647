<template>
  <div
    class="statusDropdown"
    :tabindex="0"
    @blur="open = false"
    :class="{ statusDropdownOpen: open }"
  >
    <div
      class="selected"
    >
      <span>{{
        stopwatch.hours < 10 ? `0${stopwatch.hours}` : stopwatch.hours
      }}</span
      >:<span>{{
        stopwatch.minutes < 10 ? `0${stopwatch.minutes}` : stopwatch.minutes
      }}</span
      >:<span>{{
        stopwatch.seconds < 10 ? `0${stopwatch.seconds}` : stopwatch.seconds
      }}</span>
    </div>
  </div>
</template>
<script setup></script>
<script scoped>
export default {
  name: "TimeComp",
  props: {
    default: String,
    value: {
      type: String,
      default: null,
    },
    stopwatch: Object,
  },
  data() {
    return {
      selected: null,
      open: false,
    };
  },
  computed: {
  },
  created() {
  
  },
  emits: [
    "input", "status-change"
  ]
};
</script>

<style scoped>
.Ready {
  border: 1px solid #add38d;
  filter: drop-shadow(1px 1px 1px rgba(173, 211, 141, 1));
}
.not_logged_in {
  border: 1px solid #271d7a;
  filter: drop-shadow(1px 1px 1px rgba(39, 29, 122, 1));
}
.Away {
  border: 1px solid #eee253;
  filter: drop-shadow(1px 1px 1px rgba(238, 226, 83, 1));
}
.onAcall {
  border: 1px solid #e87928;
  filter: drop-shadow(1px 1px 1px rgba(232, 121, 40, 1));
}
.onAExternalCall {
  border: 1px solid #e87928;
  filter: drop-shadow(1px 1px 1px rgba(232, 121, 40, 1));
}

.Recovery {
  border: 1px solid #8f35f1;
  filter: drop-shadow(1px 1px 1px rgb(143, 53, 241));
}

.open {
  background-color: #eff4f9;
}
.statusDropdown {
  position: relative;
  text-align: left;
  font-size: 10pt;
  background-color: #ffffff;
  font-weight: 500;
  z-index: 999;
}
.statusDropdownOpen {
  z-index: 999;
}
.statusDropdown > div {
  background-color: #ffffff;
  z-index: 999;
}

.statusDropdown .selected {
  position: relative;
  border-radius: 1px;
  color: #271d7a;
  padding-left: 10px;
  /* cursor: pointer; */
  user-select: none;
  padding: 2px 2px 2px 8px;
  height: 25px;
  z-index: 999;
  width: 70px;
}
.selectedText {
  position: absolute;
  right: 40px;
  top: 2px;
  z-index: 999;
}

.statusDropdown .selected:after {
  position: absolute;
  top: 22px;
  right: 10px;
  z-index: 999;
}

.statusDropdown .items {
  color: #ffffff;
  overflow: hidden;
  position: absolute;
  background-color: #ffffff;
  left: 0;
  right: 0;
  z-index: 999;
  width: 250px;
}

.statusDropdown .items div {
  color: #271d7a;
  padding-left: 1em;
  cursor: pointer;
  user-select: none;
}

.statusDropdown .items div:hover {
  background-color: #eff4f9;
}
.items {
  filter: drop-shadow(1px 1px 1px rgba(39, 173, 122, 0.2));
}

.selectHide {
  display: none;
}
.statusDropdownCaret {
  position: absolute;
  right: 0;
  top: 9px;
  width: 23px;
  padding-right: 10px;
}
</style>
