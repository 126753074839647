<template>
  <div class="stepIndicator" :style="{ height: height }">
    <div
      v-for="(step, index) in steps"
      :key="index"
      :class="[
        'step',
        { active: index === currentIndex },
        { stepMargin: index !== questionsCount - 1 },
      ]"
      :style="{ height: stepHeight }"
    ></div>
  </div>
</template>

<script>
export default {
  name: "ProgressBar",

  props: {
    steps: {
      type: Array,
      required: true,
    },
    currentIndex: {
      type: Number,
      required: true,
    },
    stepHeight: {
      type: String,
      default: "44px",
    },

    height: {
      type: String,
      default: "200px",
    },
    questionsCount: Number,
  },
};
</script>

<style scoped>
.stepIndicator {
  width: 5px;
  background-color: #e2f1f4;
  border-radius: 1px;
}

.step {
  width: 100%;
  transition: height 0.3s;
}
.stepMargin {
  margin-bottom: 10px;
}

.step.active {
  background-color: #3e87d3;
}
</style>
