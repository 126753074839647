const ADD_DISCIPLINARY_ACTION = 'Add Disciplinary Action';
const EDIT_DISCIPLINARY_ACTION = 'Edit Disciplinary Action';
const REMOVE_DISCIPLINARY_ACTION = 'Remove Disciplinary Action';
const DISCIPLINARY_CREATE_ALERT = 'Successfully Added Disciplinary Action';
const DISCIPLINARY_REMOVE_ALERT = 'Disciplinary action was removed';
const DISCIPLINARY_UPDATE_ALERT = 'Disciplinary action was updated';

export {
  ADD_DISCIPLINARY_ACTION,
  EDIT_DISCIPLINARY_ACTION,
  REMOVE_DISCIPLINARY_ACTION,
  DISCIPLINARY_CREATE_ALERT,
  DISCIPLINARY_REMOVE_ALERT,
  DISCIPLINARY_UPDATE_ALERT
}