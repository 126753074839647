<template>
  <div>
    <div class="left-content">
      <!-- <ButtonWithTooltip tooltipTitle="Add Article">
        <img
          :src="require('../../../../assets/icons/Article.png')"
        />
      </ButtonWithTooltip> -->

      <!-- <ButtonWithTooltip
        tooltipTitle="Attachment"
        :disabled="!!fileText"
        @click="handleFileButtonUpload"
      >
        <img :src="require('../../../../assets/icons/Attachment.png')" />
      </ButtonWithTooltip>
      <div
        @dragover.prevent
        @drop.prevent
      >
        <div
          class="file-holder"
          :class="{
            'dragging': isDragging,
            'attached': isAttached
          }"
          @dragenter="handleDragEnter"
          @dragleave="handleDragLeave"
          @drop="handleDrop"
        >
          <input
            v-if="!fileText"
            type="file"
            ref="file"
            :accept="attachFilesFormat"
            @change="handleFileUpload( $event )"
          >
          <div v-else class="attached-file">
            {{ fileText }}
            <button
              type="button"
              class="file-holder-btn-close"
              @click="removeFile"
            >
              <img
                :src="require('../../../../assets/icons/Remove.png')"
              />
            </button>
          </div>
        </div>
      </div> -->
    </div>
    <div class="right-content" v-if="!hideSubmitButtons">
      <LightButton
        title="Cancel"
        class="cancel-button"
        @button-click="handleButtonClick('Cancel')"
      />
      <DarkButton class="submit-button"  @button-click="handleButtonClick('submit')">Submit</DarkButton>
      <div
        class="icon-container"
        @blur="dropdownIsOpen = false"
        :tabindex="0"
        @click="dropdownIsOpen = !dropdownIsOpen"
      >
        <img :src="getCaretSrc()" class="caret-icon" />
        <div class="dropdown-container" v-if="dropdownIsOpen">
          <button
            @click="handleDropdownClick"
            v-for="(dropdownOption, i) in dropdownOptions"
            :key="i"
          >
            {{ dropdownOption }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LightButton from "../../Buttons/LightButton.vue";
import DarkButton from "../../Buttons/DarkButton.vue";
// import ButtonWithTooltip from "../../Buttons/ButtonWithTooltip.vue";

export default {
  components: {
    LightButton,
    DarkButton,
    // ButtonWithTooltip,
  },
  data() {
    return {
      dropdownOptions: [
        "Submit and Take",
        "Submit and Re-open",
        "Submit and Resolve",
        "Submit and Stall",
        "Submit and Dispatch",
        "Submit and Reject",
      ],
      dropdownIsOpen: false,
      // fileText: '',
      // isDragging: false,
      // isAttached: false
    };
  },
  props: {
    hideSubmitButtons: Boolean,
    // file: {
    //   type: String,
    //   default: ''
    // },
    // attachFilesFormat: {
    //   type: String,
    //   default: '.jpg, .jpeg, .png'
    // }
  },
  methods: {
    handleDropdownClick() {
      this.dropdownIsOpen = !this.dropdownIsOpen;
    },
    getCaretSrc() {
      if (this.dropdownIsOpen) {
        return require("../../../../assets/icons/caret_open_white.png");
      } else {
        return require("../../../../assets/icons/caret_closed_white.png");
      }
    },
    handleButtonClick(title) {
      this.$emit("button-click", title);
    },
    // handleFileUpload (event) {
    //   this.fileText = event.target.files[0].name;
    //   this.localFile = event.target.files[0];
    //   this.isAttached = true;
    // },
    // handleFileButtonUpload () {
    //   this.$refs.file.click();
    // },
    // removeFile () {
    //   this.fileText = '';
    //   this.localFile = '';
    //   this.isAttached = false;
    // },
    // handleDrop (e) {
    //   this.isDragging = false;
    //   this.fileText = e.dataTransfer.files[0].name;
    //   this.localFile = e.dataTransfer.files[0];
    //   this.isAttached = true;
    // },
    // handleDragEnter() {
    //   this.isDragging = true;
    // },
    // handleDragLeave () {
    //   this.isDragging = false;
    // }
  },
  emits: [
    "button-click"
  ]
};
</script>

<style scoped>
.left-content {
  display: flex;
  gap: 10px;
}

.left-content img {
  height: 20px;
  cursor: pointer;
}

.caret-icon {
  height: 7px;
  margin-bottom: 1px;
}

button {
  position: relative;
}

.right-content {
  display: flex;
}

.icon-container {
  background-color: #271d7a;
  padding: 0px 13px;
  cursor: pointer;
  position: relative;
  height: 25px;
}

.dropdown-container {
  position: absolute;
  top: -0.5rem;
  right: 0;
  transform: translateY(-100%);
  background-color: #fcfdfe;
  outline: #2e3e91 solid 1pt;
  font-size: 10pt;
  color: #2e3e91;
  filter: drop-shadow(1px 1px 1px rgb(39, 29, 122));
  border-radius: 1px;
  font-weight: 600;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  text-align: center;
  width: max-content;
  gap: 10px;
}

.dropdown-container button {
  width: 100%;
  text-align: left;
  padding: 5px 12px;
}
.dropdown-container button:hover {
  background-color: #ecf2f8;
  width: 100%;
}

.submit-button {
  display: flex;
  align-items: center;
  margin-left: 10px;
  height: 25px;
  border-radius: 2px 0px 0px 2px !important;
}

.cancel-button {
  background-color: #ecf2f8;
}

.icon:hover::before {
  content: attr(data-tooltip);
  background: #6b9fd9;
  color: white;
  padding: 0.25rem 0.4rem;
  width: max-content;
  opacity: 100%;
  border-radius: 5px;
  position: absolute;
  top: -0.25rem;
  font-size: 10pt;
  outline: none;
  left: 50%;
}

.icon::before {
  transform: translateX(-50%) translateY(-100%);
}

/* .attached-file {
  display: flex;
  align-items: center;
  gap: 13px;
}

.file-holder {
  position: relative;
  display: flex;
  align-items: center;
  min-width: 200px;
  min-height: 40px;
  border-radius: 5px;
  border: 1px dashed #396fba;
  color: #271d7a;
  background-color: #e0ebf5;
}

.file-holder::before {
  content: 'Drag and Drop Attachment';
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  font-size: 14px;
  text-align: center;
}

.file-holder.dragging {
  border: 1px dashed #c0d6ed;
  background-color: #c0d6ed;
}
.file-holder.attached {
  border-color: transparent;
  background-color: transparent;
}
.file-holder.attached::before{
  opacity: 0;
}

.file-holder-btn-close {
  position: relative;
  width: 13px;
  height: 13px;
}
.file-holder-btn-close img {
  display: block;
  max-width: 100%;
  max-height: 13px;
}
.file-holder input[type="file"] {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 0;
  height: 0;
  overflow: hidden;
  opacity: 0;
  height: 60px;
}
.file-holder.dragging input[type="file"] {
  pointer-events: auto;
} */
</style>
