<template>
  <div v-if="currentCall?.status === 'active'">
    <div class="activeCallHeader">
      <img
        :src="require('@/assets/icons/call_white.png')"
        class="activeCallIcon"
         @click="handleActiveClick"
      />
      <h1 class="activeCallText" @click="handleActiveClick">
        Active Call
        <span :style="{ paddingLeft: '10px' }">
          ({{ currentCall["Total Call Duration"] }})
        </span>
      </h1>
      <img
        :src="muted ? require('@/assets/icons/unmute_combined.png') : require('@/assets/icons/mute_combined.png') "
        class="mute-icon"
        @click="handleMute"/>
    </div>
  </div>
  <div v-else class="enterNumberBoxContainer">
    <NumberTextBox
      ref="enterNumberBox"
      placeholder="Enter Number"
      class="enterNumberBox"
      :value="this.number"
      @keydown="handleNumberBoxKeyPress"
      @input="handleNumberBoxInput"
      @paste="handlePaste"
    />
  </div>

  <div class="keypad">
    <v-row no-gutters :cols="4">
      <v-col no-gutters>
        <v-row no-gutters class="numberRow">
          <button class="numberButton" @click="handleDigitClick(1)">
            <div class="numberInput">
              <div class="column">
                <span class="digit">1</span>
              </div>
            </div>
          </button>
          <button class="numberButton" @click="handleDigitClick(2)">
            <div class="numberInput">
              <div class="column">
                <span class="digit">2</span>
                <span class="letters">ABC</span>
              </div>
            </div>
          </button>
          <button class="numberButton" @click="handleDigitClick(3)">
            <div class="numberInput">
              <div class="column">
                <span class="digit">3</span>
                <span class="letters">DEF</span>
              </div>
            </div>
          </button>
        </v-row>
        <v-row no-gutters class="numberRow">
          <button class="numberButton" @click="handleDigitClick(4)">
            <div class="numberInput">
              <div class="column">
                <span class="digit">4</span>
                <span class="letters">GHI</span>
              </div>
            </div>
          </button>
          <button class="numberButton" @click="handleDigitClick(5)">
            <div class="numberInput">
              <div class="column">
                <span class="digit">5</span>
                <span class="letters">JKL</span>
              </div>
            </div>
          </button>
          <button class="numberButton" @click="handleDigitClick(6)">
            <div class="numberInput">
              <div class="column">
                <span class="digit">6</span>
                <span class="letters">MNO</span>
              </div>
            </div>
          </button>
        </v-row>
        <v-row no-gutters class="numberRow">
          <button class="numberButton" @click="handleDigitClick(7)">
            <div class="numberInput">
              <span class="digit">7</span><span class="letters">PQRS</span>
            </div>
          </button>
          <button class="numberButton" @click="handleDigitClick(8)">
            <div class="numberInput">
              <div class="column">
                <span class="digit">8</span>
                <span class="letters">TUV</span>
              </div>
            </div>
          </button>
          <button class="numberButton" @click="handleDigitClick(9)">
            <div class="numberInput">
              <div class="column">
                <span class="digit">9</span>
                <span class="letters">WXYZ</span>
              </div>
            </div>
          </button>
        </v-row>
        <v-row no-gutters class="numberRow">
          <button class="numberButton" @click="handleDigitClick('*')">
            <div class="numberInput">
              <div class="column">
                <span class="digit">*</span>
              </div>
            </div>
          </button>
          <button class="numberButton" @click="handleDigitClick(0)">
            <div class="numberInput">
              <div class="column">
                <span class="digit">0</span>
                <span class="letters">+</span>
              </div>
            </div>
          </button>
          <button class="numberButton" @click="handleDigitClick('#')">
            <div class="numberInput">
              <div class="column">
                <span class="digit">#</span>
              </div>
            </div>
          </button>
        </v-row>
        <!-- <v-row no-gutters class="numberRow"> -->
          <div class="clearButtonContainer">
            <LightButton
              icon="Remove.png"
              title="Clear"
              :style="{ height: '35px', width: '90%', margin: '9px auto' }"
              @click="handleClear"
            />
          </div>
          <!-- <button class="numberButton" @click="handleClear">
            <div class="numberInput">
              <div class="column">
                <span class="digit">Clear</span>
              </div>
            </div>
          </button> -->
        <!-- </v-row> -->
      </v-col>
    </v-row>
    <div
      class="activeCallButtonContainer"
      v-if="currentCall?.status === 'active'"
    >
      <DividerComp class="activeCallDivider" />
      <div class="hangupButtonContainer">
        <LightButton
          class="hangupButton"
          :icon="'hangup.png'"
          :style="{
            height: '35px',
            backgroundColor: '#e78535',
            color: 'white',
          }"
          @click="handleHangupClick"
        />
      </div>
    </div>
    <div v-else class="callButton">
      <CallButton @click="(loc) => handleCallClick(loc)" />
    </div>
  </div>
</template>

<script>
import NumberTextBox from "./NumberTextBox.vue";
import CallButton from "./CallButton.vue";
import DividerComp from "@/components/base_components/DividerComp.vue";
import LightButton from "../LightButtonWebRTC.vue";
import { useWebRTCStore } from "@/stores/webRTC/webRTCStore.js";
import { mapActions, mapStores } from "pinia";
export default {
  name: "KeypadComp",
  props: { currentCall: Object },
  emits: ["navigate"],
  // data() {
  //   return {
  //     muted: 
  //   };
  // },
  computed: {
    ...mapStores(useWebRTCStore),
    number() {
      return this.WebRTCStore.getNumber;
    },
    muted() {
      return this.WebRTCStore.getMuteState;
    }
  },
  components: { NumberTextBox, CallButton, LightButton, DividerComp },
  methods: {
    ...mapActions(useWebRTCStore, ["changeNumber", "toggleMuted"]),
    handleDigitClick(digit) {
      let num = this.number;
      num += String(digit);
      this.WebRTCStore.changeNumber(num);
      if(this.WebRTCStore.getCurrentCallCallStatus == "active"){
        let dial_pad = this.WebRTCStore.getLibWebPhone.getDialpad();
        dial_pad.dial(digit);
      }
    },
    handleMute(){
      this.WebRTCStore.toggleMuted();
    },
    handleCallClick() {

      console.log("handleCallClick");
      console.log(this.number);

      if(this.number){
        let valueArr = [...String(this.number)];
        let dial_pad = this.WebRTCStore.getLibWebPhone.getDialpad();
        dial_pad.clear();
        //FIX FOR LEX-624 Before Dialing Terminate any existing call in the dial_pad
        dial_pad.terminate();
                
        for (var digit of valueArr) {
          dial_pad.dial(digit);
        }

        dial_pad.call(false);
        this.WebRTCStore.setCurrentCallCallerID(this.number);
        this.WebRTCStore.setCurrentCallCallerName("");
        this.WebRTCStore.setCurrentCallFromQueue("");
        this.WebRTCStore.setCurrentCallCallDuration("0");
        
        this.WebRTCStore.setCurrentCallStatus("active");
        this.WebRTCStore.changeSelectedPage("active");
        this.handleClear();
      }
    },
    handleHangupClick() {
      this.WebRTCStore.hangupCurrentCall();
    },
    handleActiveClick() {
      this.$emit("navigate", "active");
    },
    handleClear(){
      let dial_pad = this.WebRTCStore.getLibWebPhone.getDialpad();
      dial_pad.clear();

      let num = "";
      this.WebRTCStore.changeNumber(num);
    },
    handleNumberBoxKeyPress(event) {
      const regex = /^[0-9*#+]$/;
      const allowedKeys = ["Backspace", "Delete", "Home", "End", "ArrowLeft", "ArrowRight", "ArrowUp", "ArrowDown", "Enter", "Control"];

      if((event.key == "v" || event.key == "V" || event.key == "A" || event.key == "a" || event.key == "c" || event.key == "C" || event.key == "z" || event.key == "Z") && event.ctrlKey){
        return;
      }

      if(!regex.test(event.key) && !allowedKeys.includes(event.key)) {
        event.preventDefault();
      }

      if(event.key == "Enter"){
        this.handleCallClick();
      }
    },
    handleNumberBoxInput(event) {
      let value = event.target.value;
      value = value.replace(/[^0-9*#]/g, "");

      this.WebRTCStore.changeNumber(value);
    },
    handlePaste(event) {
       event.preventDefault();

      const pastedText = event.clipboardData.getData("text");
      this.WebRTCStore.changeNumber(pastedText.replace(/[^0-9*#]/g, ""));
    },
  },
};
</script>

<style scoped>
.keypad {
  background-color: #f7f9fc;
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
  height: 100%;
}
.numberInput {
  padding: 5px 39px 5px 39px;
  width: 60px;
}
.numberRow {
  padding: 10px;
  display: flex;
  justify-content: center;
}
.digit {
  display: flex;
  justify-content: center;
  font-size: 24pt;
  color: #3e87d3;
  font-weight: 500;
  margin-bottom: -5px;
}
.column {
  display: flex;
  flex-direction: column;
}
.letters {
  display: flex;
  justify-content: center;
  font-size: 8pt;
  color: #271d7a;
  font-weight: 600;
}
.numberButton {
  display: flex;
  justify-content: center;
  margin-left: 5px;
  margin-right: 5px;
}
.numberButton:hover {
  background-color: #ffffff;
}
.enterNumberBox {
  display: flex;
  margin: auto;
  width: 85%;
  font-weight: bold;
}
.enterNumberBoxContainer {
  margin-top: 20px;
  margin-bottom: 20px;
  background-color: #ffffff;
}
.callButton {
  margin: auto auto 0 auto;
  padding-bottom: 28px;
  width: 90%;
}
.activeCallDivider {
  width: 100%;
  margin-bottom: 12px;
}
.activeCallHeader {
  padding-top: 25px;
  padding-bottom: 25px;
  background-color: #e78535;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.activeCallHeader:hover {
  outline: 0.25px solid #271d7a;
  cursor: pointer;
}
.activeCallIcon {
  height: 30px;
  width: 24px;
  margin-right: 20px;
  margin-left: 20px;
  text-align: center;
}
.activeCallText {
  color: #ffffff;
  font-size: 19px;
  font-weight: 500;
}
.activeCallButtonContainer {
  margin: auto auto 0 auto;
  padding-bottom: 30px;
  width: 100%;
  background-color: #f8f9fc;
  flex: 1;
}
.hangupButtonContainer {
  display: flex;
  /* padding-top: 20px; */
}
.hangupButton {
  width: 90%;
  display: flex;
  margin: auto;
}
.mute-icon {
  height: 35px;
  margin-right: 15px;
}
</style>
