<template>
  <div class="editor" v-if="useEditor == true">
    <Toolbar class="toolbar" :editor="editor" />
    <editor-content class="editor-content" :editor="editor" v-model="content"/>
    <h1>{{ result }}</h1>
  </div>
  <div v-else>
    <textarea class="editor_textarea" rows="2"></textarea>
  </div>
</template>

<script>
import Highlight from "@tiptap/extension-highlight";
import TaskItem from "@tiptap/extension-task-item";
import TaskList from "@tiptap/extension-task-list";
import StarterKit from "@tiptap/starter-kit";
import Underline from "@tiptap/extension-underline";
import Placeholder from "@tiptap/extension-placeholder";
import Color from "@tiptap/extension-color";
import Heading from "@tiptap/extension-heading";
import BulletList from "@tiptap/extension-bullet-list";
import { Editor, EditorContent } from "@tiptap/vue-3";

import Toolbar from "./subComponents/Toolbar.vue";
import TextStyle from "@tiptap/extension-text-style";
import { useAccountNotificationStore } from "@/stores/ContactCenter/Management/AccountNotificationStore.js";
import { mapActions, mapStores } from 'pinia';

export default {
  components: {
    EditorContent,
    Toolbar,
  },

  data() {
    return {
      editor: null,
      result: null,
      useEditor: false,
      content: ""
    };
  },
  props: {
    subjectLine: String,
    type: String,
  },
  mounted() {
    this.editor = new Editor({
      extensions: [
        StarterKit,
        Highlight.configure({ multicolor: true }),
        TaskList,
        TaskItem,
        Underline,
        TextStyle,
        Heading,
        Placeholder.configure({
          placeholder: "Begin typing your message here...",
        }),
        Color.configure({
          types: ["textStyle"],
        }),
        BulletList
      ],
    });
    this.editor.on('update', ({editor}) => {
      this.$emit('update-value', editor.getHTML());
    })

    const textareas = document.querySelectorAll('.editor_textarea');

    textareas.forEach(textarea => {
      textarea.addEventListener('input', this.handleTextareaChange);
    });
  },

  methods: {
    ...mapActions(useAccountNotificationStore, ['getSpecificInformation']),
    handleTextareaChange(event) {
      console.log(event);
      this.$emit('update-value', event.target.value)
    },
    fetchTextBoxInformation() {
      var info = this.getSpecificInformation()
      if(info.error) {
      // TODO: what needs to be done here besides a console.log? 
        console.log('OMG');
      } else {
        var InformationType = this.AccountNotificationStore.currentInformationType;
        if(InformationType == "ticket_subject_values") {
          this.useEditor = false;
          this.$nextTick(() => {
            document.querySelector('.editor_textarea').textContent = info.message;
            const textareas = document.querySelectorAll('.editor_textarea');

            textareas.forEach(textarea => {
              textarea.addEventListener('input', this.handleTextareaChange);
            });
          });
        } else {
          this.useEditor = true;
          this.editor.commands.setContent(info.message);
        }
      }
    }
  },
  computed: {
    ...mapStores(useAccountNotificationStore),
    selectedPartner() {
      return this.AccountNotificationStore.currentPartner;
    },
    selectedInformationType() {
      return this.AccountNotificationStore.currentInformationType;
    }
  },
  watch: {
    selectedPartner() {
      this.fetchTextBoxInformation();
    },
    selectedInformationType() {
      this.fetchTextBoxInformation();
    },
    content(newValue) {
      console.log(newValue)
    },
    content_textarea(newValue) {
      console.log(newValue)
    }
  },
  beforeUnmount() {
    this.editor.destroy();
  },
  emits: [
    'update-value'
  ]
};
</script>

<style scoped>
.editor-content :deep(.ProseMirror) {
  height: 258px;
  padding: 2rem;
  color: #271d7a;
  font-size: 12pt;
  background-color: #ffffff;
}

:deep(.ProseMirror:focus) {
  outline: none;
}


:deep(.ProseMirror p.is-editor-empty:first-child::before) {
  color: #b1cdea;
  content: attr(data-placeholder);
  float: left;
  font-size: 12pt;
  height: 100%;
  pointer-events: none;
}

.editor {
  /* border-radius: 7pt; */
  outline: 0.25pt solid #271d7a;
  overflow: hidden;
  min-height: 300px;
  max-height: 300px;
}

:deep(.editor-content .ProseMirror) {
  overflow: auto;
}
.toolbar {
  border: 1px solid #dde9f4;
  background-color: #ecf2f8;
  padding-left: 1rem;
  padding-top: 5px;
  padding-bottom: 5px;
}
.editor_textarea {
  width: 100%;
  outline: 0.25pt solid #2e3e91;
  border-radius: 0px;
  color: #271d7a;
}
.editor_textarea:focus-visible {
  outline: none;
  border-radius: 0px;
}
</style>