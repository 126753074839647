<template>
  <div
    class="card"
    tabindex="0"
    @click="toggleSelected(this.category)"
    :class="{ selectedPage: isSelected }"
  >
    <h3 class="title">
      {{ category }}
    </h3>
    <p class="description">{{ description }}</p>
  </div>
</template>

<script>
export default {
  name: "SelectPages",
  props: {
    category: String,
    description: String,
    isSelected: Boolean,
  },
  methods: {
    toggleSelected(category) {
      this.$emit("select-page", category);
    },
  },
  emits: [
    "select-page"
  ]
};
</script>

<style scoped>
.card {
  padding-left: 10px;
  /* padding-right: 20px; */
  padding-top: 10px;
  padding-bottom: 10px;
}
.card:hover {
  background-color: #f2f6fa;
}
.selectedPage {
  background-color: #f2f6fa;
  border-left: 2px solid #3e87d3;
}
.title {
  font-size: 12pt;
  color: #271d7a;
  text-align: left;
  font-weight: 500;
  width: 100%;
}
.selectedPage .title {
  color: #3e87d3;
}
.description {
  font-size: 9pt;
  color: #271d7a;
  text-align: left;
  font-weight: normal;
}
</style>
