import { defineStore } from 'pinia';
import { API } from 'aws-amplify';
import * as queries from "@/graphql/queries.js";
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api';

export const useCarrierRatesStore = defineStore('CarrierRates', {
  state: () => ({
    rates: [],
  }),

  getters: {
    getCarrierRates(state) {
      return state.rates;
    },
  },

  actions: {
    async fetchCarrierRates(npanxx, lata, rate_center) {
      try {
        var hasData = true;
        var nextToken = null;
        var ratesData = [];

        while (hasData) {
          const filter = {};

          if (npanxx) filter.npanxx = { wildcard: npanxx };
          if (lata) filter.lata = { wildcard: lata };
          if (rate_center) filter.rate_center = { matchPhrase: rate_center };

          const variables = {
            filter,
            limit: 2000
          };

          if (nextToken != null) {
            variables.nextToken = nextToken;
          }

          const options = {
            query: queries.searchCarrierRates,
            variables,
            authMode: GRAPHQL_AUTH_MODE.API_KEY,
          };

          const response = await API.graphql(options);

          for (const rate of response.data.searchCarrierRates.items) {
            ratesData.push({
              npanxx: rate.npanxx,
              carrier: rate.carrier,
              lata: rate.lata,
              direction: rate.direction,
              rateCenter: rate.rate_center,
              interstateRate: rate.interstate_rate,
              intrastateRate: rate.intrastate_rate,
            });
          }

          if (response.data.searchCarrierRates.nextToken != null) {
            nextToken = response.data.searchCarrierRates.nextToken;
          } else {
            hasData = false;
          }
        }

        this.rates = ratesData;
      } catch (error) {
        return { error: true, message: 'Unable to fetch carrier rates' };
      }
    },
  },
});