<template>
    <div class="agents-card">
        <div v-for="session of queue.current_queue_sessions" :key="session">

            <p>{{ session.session_id }}</p>
            <p>{{ session.session_object.caller_id_name }}</p>
            <p>{{ session.session_object.caller_id_number }}</p>
        </div>
        
    </div>
  </template>
  
  <script>
  export default {
    name: 'CurrentCallsInQueueCard',
    props: {
      queue: Object,
    },
  }
  </script>
  
  <style scoped>
  
  </style>