<template>
  <div class="qualityAuditsPdf">
    <div class="qualityAuditsHeading">
      <img
        src="@/assets/LogicomUSA_logo_fullcolor_web.png"
        class="logicomLogo"
      />
      <div class="auditInfo">
        <p class="infoField">
          <span class="infoLabel">Employee:</span>
          {{ previousSubmissionData.employee }}
        </p>
        <p class="infoField">
          <span class="infoLabel">Date of Audit:</span>
          {{ auditInfoWithFormattedDates.audit_date }}
        </p>
        <p class="infoField">
          <span class="infoLabel">Date of Call:</span>
          {{ auditInfoWithFormattedDates.date_call }}
        </p>
        <p class="infoField">
          <span class="infoLabel">Ticket Number:</span>
          {{ previousSubmissionData.ticket_number }}
        </p>
        <p class="infoField">
          <span class="infoLabel">Verification Level:</span>
          {{ previousSubmissionData.auditFieldsObject?.Compliance?.verificationLevel }}
        </p>
        <p class="infoField">
          <span class="infoLabel">Call Type:</span>
          {{  previousSubmissionData.auditFieldsObject?.Compliance?.callType }}
        </p>
      </div>
      <h3 class="qualityAuditsPdfTitle text-center">
        Contact Center Quality Audit
      </h3>
      <!-- <h3 class="qualityAuditsPdfTitle">{{ tableData[0]?.title }}</h3> -->
      <h4 class="qualityAuditsPdfSubtitle">{{ tableData[0]?.subtitle }}</h4>
      <table class="qualityAuditsPdfTable">
        <tr>
          <td>Quality Category</td>
          <td colspan="2">Scale</td>
          <td>Score</td>
        </tr>
        <tr>
          <td></td>
          <td>UnSatisfactory</td>
          <td>Satisfactory</td>
          <td></td>
        </tr>
        <tr>
          <td>Tone</td>
          <td class="light-orange">0-19</td>
          <td class="dark-blue">20-23</td>
          <td
            :class="previousSubmissionData?.score?.tone > 19 ? 'table-data-scale-satisfactory' : 'table-data-scale-unsatisfactory'"
          >
            {{ previousSubmissionData?.score?.tone }}
          </td>
        </tr>
        <tr>
          <td>Greeting</td>
          <td class="light-orange">0-9</td>
          <td class="dark-blue">10-12</td>
          <td
            :class="previousSubmissionData?.score?.greeting > 9 ? 'table-data-scale-satisfactory' : 'table-data-scale-unsatisfactory'"
          >
            {{ previousSubmissionData?.score?.greeting }}
          </td>
        </tr>
        <tr>
          <td>Trouble</td>
          <td class="light-orange">0-35</td>
          <td class="dark-blue">36-40</td>
          <td
            :class="previousSubmissionData?.score?.trouble > 35 ? 'table-data-scale-satisfactory' : 'table-data-scale-unsatisfactory'"
          >
            {{ previousSubmissionData?.score?.trouble }}
          </td>
        </tr>
        <tr>
          <td>Documentation</td>
          <td class="light-orange">0-21</td>
          <td class="dark-blue">22-25</td>
          <td
            :class="previousSubmissionData?.score?.documentation > 21 ? 'table-data-scale-satisfactory' : 'table-data-scale-unsatisfactory'"
          >
            {{ previousSubmissionData?.score?.documentation }}
          </td>
        </tr>
        <tr>
          <td>Compliance</td>
          <td class="light-orange">Fail</td>
          <td class="dark-blue">Pass</td>
          <td
            :class="isCompliancePass ? 'table-data-scale-satisfactory' : 'table-data-scale-unsatisfactory'"
          >
            {{ isCompliancePass ? 'Pass' : 'Fail' }}
          </td>
        </tr>
        <tr>
          <td colspan="2">100 Possible Points</td>
          <td colspan="1">Total Score</td>
          <td
            colspan="1"
            :class="previousSubmissionData?.score?.compliance === 'Fail' || accumulatedScore < 85 ? 'table-data-scale-unsatisfactory' : 'table-data-scale-satisfactory'"
          >
            {{ accumulatedScore }}
          </td>
        </tr>
      </table>
    </div>
    <div class="formContainer">
      <div
        v-for="(section, index) in sectionedFormData"
        :key="index"
        class="sectionContainer"
      >
        <h3 class="sectionTitle">{{ section.title }}</h3>
        <div class="sectionContent">
          <div class="sectionCheckboxes">
            <div
              v-for="(checkbox, cIndex) in section.checkboxes"
              :key="`checkbox-${index}-${cIndex}`"
              class="checkboxContainer"
            >
              <input
                type="checkbox"
                :id="`checkbox-${index}-${cIndex}`"
                :checked="checkbox.isChecked"
                disabled
              />
              <label
                :for="`checkbox-${index}-${cIndex}`"
                >{{ checkbox.label }}</label
              >
            </div>
          </div>
          <div v-if="section.comment" class="sectionComments">
            <div
              v-html="section.comment.replace(/\n/g, '<br>')"
              class="commentText"
            ></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//import QualityAuditsForm from "@/components/CcManagement/Attendance/Forms/QualityAuditsForm.vue";


export default {
  name: 'QualityAuditsPdf',
  components: {
   
  },
  props: {
    tableData: {
      type: Array,
      default: () => []
    },
    previousSubmissionData: {
      type: Object,
      default: () => ({})
    },
  },
  data() {
    return {
      pointValues: {
        // 23 points
        "Overall Tone": {
          "askingForMemberSAssistanceInsteadOfOrderingMemberAround": 2,
          "avoidingDeadAir": 4,
          "backgroundSoundQuality": 3,
          "ownershipThroughoutCall": 2,
          "pleaseAndThankYouPhrases": 4,
          "politeThroughCall": 4,
          "positiveTone": 4
        },
        // 12 points
        "Strong Greeting And Ending": {
          "endingBrandingWithPartnerName": 1,
          "endingConfidentEnding": 2,
          "endingRecapValueStatement": 1,
          "endingSettingCorrectExpectationsPositioning": 2,
          "greetingEmpathyStatement": 2,
          "greetingIdentifyCorrectPartner": 1,
          "greetingIdentifyIssueOfferAssistance": 1,
          "greetingIdentifyName": 1,
          "greetingOfferTicketNumber": 1
        },
        // 40 points
        "Troubleshooting": {
          "bestPossibleResolutionReached": 10,
          "correctUseOfProbingQuestions": 5,
          "followedCorrectTechnicalTroubleshootingProcess": 10,
          "memberLevelDescriptionOfEquipmentLimitedTechJargon": 5,
          "rebootedOntAndRgWithMemberPermission": 5,
          "remainedWithinScopeOfSupport": 5
        },
        // 25 points
        "Documentation": {
          "correctEscalationField": 1,
          "correctIssueCode": 1,
          "correctResolutionCode": 1,
          "correctVerificationField": 3,
          "filledOutFlowProperlyAndTruthfully": 3,
          "followedFlowStepsCorrectly": 3,
          "partnerSpecificTicketHandling": 3,
          "professionalAndDetailedNotes": 5,
          "usedAdvancedSupportFlow": 5
        }
      }
    };
  },
  computed: {
    isCompliancePass() {
      return this.previousSubmissionData?.score?.compliance === 'Pass';
    },
    sectionedFormData() {
      // Compliance is a Pass/Fail section - they fail complince & the audit if any of the questions are missed in Compliance
      // Weights are calculated based on the point values total of there sections - converted to a two deciaml percentage
      const result = [];
      if (!this.previousSubmissionData || !this.previousSubmissionData.auditFieldsObject) return result;

      //console.log("pdf previousSubmissionData", this.previousSubmissionData); 

      const overallTotalPoints = this.calculateOverallTotalPoints();
      const complianceData = this.previousSubmissionData.auditFieldsObject.Compliance || {};
      const { verificationLevel, callType } = complianceData;

      this.tableData.forEach(section => {
        if (['Review', 'Basic Information', 'Call Recording'].includes(section.title)) return;

        let sectionCheckboxes = [];
        let sectionPoints = 0; 
        let sectionComment = this.previousSubmissionData.auditFieldsObject[section.title]?.comments || '';
        let complianceFail = false;

        if (section.title === "Compliance") {
          const visibleCheckboxes = this.getVisibleComplianceCheckboxes(verificationLevel, callType, section.inputs || []);
          visibleCheckboxes.forEach(checkbox => {
            const isChecked = !!complianceData[checkbox.name];
            sectionCheckboxes.push({
              ...checkbox,
              isChecked,
            });
            if (!isChecked) complianceFail = true;
          });
        } else {
          section.inputs.forEach(input => {
            if (input.type === 'checkbox') {
              const isChecked = !!this.previousSubmissionData.auditFieldsObject[section.title]?.[input.name];
              const pointValue = this.pointValues[section.title]?.[input.name] || 0;
              sectionPoints += pointValue;
              sectionCheckboxes.push({
                ...input,
                isChecked,
                label: `${input.label} (${pointValue})`,
              });
            }
          });
        }

        let sectionTitle = section.title;
        if (section.title !== "Compliance") {
          const sectionWeight = ((sectionPoints / overallTotalPoints) * 100).toFixed(2);
          sectionTitle += ` (Weight: ${sectionWeight}%)`;
        } else {
          sectionTitle += complianceFail ? " - Fail" : " - Pass";
        }

        result.push({
          title: sectionTitle,
          checkboxes: sectionCheckboxes,
          comment: sectionComment,
        });
      });

      return result;
    },
    auditInfoWithFormattedDates() {
      return {
        ...this.previousSubmissionData,
        audit_date: this.formatDate(this.previousSubmissionData.audit_date),
        date_call: this.formatDate(this.previousSubmissionData.date_call),
      };
    },
    accumulatedScore() {
      return this.accumulateNumericValues(this.previousSubmissionData?.score);
    }
  },
  methods: {
    calculateOverallTotalPoints() {
      let totalPoints = 0;
      for (const section in this.pointValues) {
        if (section !== "Compliance") {
          for (const value of Object.values(this.pointValues[section])) {
            totalPoints += value;
          }
        }
      }
      return totalPoints;
    },
    getVisibleComplianceCheckboxes(verificationLevel, callType, checkboxes) {
      const conditions = {
        'twoStep': {
          'inbound': ['verifiedFullAccountHolderSName', 'verifiedFullServiceAddress', 'askedForPermissionToAccessAccount'],
          'outbound': ['statementOfRecording', 'verifiedFullAccountHolderSName', 'verifiedFullServiceAddress', 'askedForPermissionToAccessAccount'],
        },
        'threeStep': {
          'inbound': ['verifiedFullAccountHolderSName', 'verifiedFullServiceAddress', 'verifiedAccountNumberOrLast4DigitsOfPartnerProvidedRgOrOntMacAddress', 'askedForPermissionToAccessAccount'],
          'outbound': ['statementOfRecording', 'verifiedFullAccountHolderSName', 'verifiedFullServiceAddress', 'verifiedAccountNumberOrLast4DigitsOfPartnerProvidedRgOrOntMacAddress', 'askedForPermissionToAccessAccount'],
        },
      };

      const applicableCheckboxes = conditions[verificationLevel]?.[callType] || [];
      //console.log("applicableCheckboxes", applicableCheckboxes);
      return checkboxes.filter(checkbox => applicableCheckboxes.includes(checkbox.name));
    },
    formatDate(dateString) {
      if (!dateString) return '';
      const [year, month, day] = dateString.split('-');
      return `${month}/${day}/${year}`;
    },
    accumulateNumericValues(obj) {
      let total = 0;

      for (const key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
          const value = obj[key];
          if (typeof value === 'number') {
            total += value;
          }
        }
      }

      return total;
    },
  }
}
</script>

<style
  scoped
>
.qualityAuditsHeading, .formContainer {
  max-width: 750px;
  margin: auto; 
}

.sectionContainer {
  border: 1px solid #222222; 
  padding: 15px;
  text-align: center; 
  width: 100%; 
  display: block;
  margin-bottom: 20px; 
}

.sectionContent {
  display: grid;
  grid-template-columns: 3fr 3fr;
  align-items: start;
  gap: 10px; 
  position: relative;
}

.sectionContent::after {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%; 
  border-right: 1px solid #222222;
  transform: translateX(-50%); 
}

.sectionCheckboxes {
  padding: 0px;
  /* border-right: 1px solid #222222;   */
  text-align: left;
}

.sectionComments {
  padding: 0px;
  text-align: left;
}

.sectionTitle {
  width: 100%;
  text-align: center;
  color: #3e87d3;
  margin-bottom: 5px;
  border-bottom: 1px solid #222222;
  padding-bottom: 5px;
}
.checkboxContainer {
  flex-wrap: wrap;
  gap: 3px;
}

.checkboxContainer label {
  word-break: break-word; 
  flex: 1; 
}

.sectionComments textarea {
  color: black; 
}
.qualityAuditsPdf {
  padding-top: 20px;
  background-color: #fff;
}

.auditInfo {
  text-align: right;
}

.infoLabel {
  font-weight: bold;
}

.infoField {
  margin: 0;
  font-size: 0.8em;
}

.qualityAuditsHeading {
  padding: 0 20px;
}
.qualityAuditsPdfTitle {
  padding-top: 5px;
  padding-bottom: 10px;
  font-size: 14pt;
  font-weight: bold;
  color: #271d7a;
  
}
.qualityAuditsPdfSubtitle {
  padding-bottom: 20px;
  color: #271d7a;
}

.qualityAuditsPdfTable {
  width: 100%;
  border: 1px solid #000;
  border-collapse: collapse;
  font-size: 13px;
  margin-bottom: 20px;
}
.qualityAuditsPdfTable td {
  padding: 2px;
  border: 1px solid #000;
}

.light-orange {
  background-color: #fca608;
}
.dark-blue {
  background-color: #3e87d3;
  color: #fff;
}

:deep(.formHeader) {
  display: none;
}

:deep(.formButtonPositionBottom) {
  display: none;
}

:deep(.checkbox-icon) {
  border: 1px solid #2e3e91;
}

:deep(.checkbox.checkboxSelected .checkbox-icon::before) {
  content: '';
}

:deep(.checkbox.checkboxSelected .checkbox-icon::after) {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  content: "";
  width: 5px;
  height: 5px;
  border-right: 1px solid #fff;
  border-bottom: 1px solid #fff;
  transform-origin: 50% 25%;
  transform: rotate(45deg);
}

.table-data-scale-unsatisfactory {
  text-align: center;
  background-color :#fca608;
}
.table-data-scale-satisfactory {
  text-align: center;
  background-color: #3e87d3;
  color: #fff;
}
.wizardHeader {
  color: #271d7a;
  font-size: 14pt;
  font-weight: bold;
  padding: 0px 10px 20px 0px;
}
.logicomLogo {
  width: 40%;
  padding: 0px 30px 0px 5px;
}
</style>