<template>
  <div class="alertBox" :style="alertStyle">
    <button @click="removeAlert" class="removeButtonAlert">
      <img
        :src="require('../../assets/icons/Remove.png')"
        class="alertRemove"
      />
    </button>
    <div class="alertColor" :style="{ backgroundColor: getColorByName }">
      <img
        v-if="alertIcon !== ''"
        :src="require(`../../assets/icons/${alertIcon}.png`)"
        class="alertImage"
      />
    </div>
    <div class="alertText">
      <h3 class="alertName">{{ name }}</h3>
      <p class="alertDescription">{{ description }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "AlertComp",
  props: {
    name: String,
    description: String,
    color: String,
  },
  methods: {
    removeAlert() {
      this.$emit("removed-alert", this.name);
    },
  },
  computed: {
    getColorByName() {
      if (this.name === "Notification") {
        return "#eac749";
      } else if (this.name === "Success") {
        return "#5fc0d3";
      } else if (this.name === "Download") {
        return "#3e87d3";
      } else if (this.name === "Error") {
        return "#e89032";
      } else {
        if (this.color === "LightBlue") {
          return "#3e87d3";
        } else if (this.color === "DarkBlue") {
          return "#271d7a";
        } else {
          return "#ffffff";
        }
      }
    },
    alertStyle() {
      return {
        outline: `1px solid ${this.getColorByName}`,
      };
    },
    alertIcon() {
      if (this.name === "Notification") {
        return "Notifications";
      } else if (this.name === "Download") {
        return "Article_white";
      } else if (this.name === "Success") {
        return "success";
      } else if (this.name === "Error") {
        return "error";
      } else {
        return "";
      }
    },
  },
  emits: [
    "removed-alert"
  ]
};
</script>

<style scoped>
.alertBox {
  display: flex;
  align-items: center;
  background-color: #f7fbfc;
  outline: 1px solid;
  border-radius: 1px;
  height: 55px;
  width: 225px;
  filter: drop-shadow(1px 1px 1px rgb(39, 29, 122, 0.2));
  position: relative;
  z-index: 12;
}

.alertColor {
  width: 28px;
  height: 100%;
  position: relative;
}
.alertName {
  font-size: 10pt;
  color: #271d7a;
  text-align: left;
  font-weight: 600;
  margin-top: -3px;
}
.alertDescription {
  font-size: 8pt;
  color: #271d7a;
  text-align: left;
  font-weight: normal;
  padding-top: 4px;
}
.alertText {
  padding: 16px;
}
.alertRemove {
  position: absolute;
  top: 0;
  right: 0;
  width: 20px;
  padding-top: 11px;
  padding-right: 11px;
}
.alertImage {
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  width: 16px;
  height: 16px;
  margin: 0;
  padding: 0;
  object-fit: contain;
}
</style>
