<template>
  <div v-if="allowDateRange">
    <div class="datePickerCheckboxContainer">
      <CheckBox
        :checked="dateRangeChecked"
        :item="input"
        @toggle-selected="toggleSelected()"
      />
      <label class="dateCheckboxLabel">Select Date Range</label>
    </div>

    <VDatePicker v-if="dateRangeChecked" v-model="range" is-range :popover="{visibility: 'click'}">
      <template #default="{ inputValue, inputEvents }">
        <div class="dateRange" v-bind="$emit('range-value', inputValue, input)">
          <div class="inputLabelContainer">
            <label>From</label>
            <input
              class="dateInput"
              :value="inputValue.start"
              v-on="inputEvents.start"
            />
          </div>
          <div class="inputLabelContainer">
            <label>To</label>
            <input
              class="dateInput"
              :value="inputValue.end"
              v-on="inputEvents.end"
            />
          </div>
        </div>
      </template>
    </VDatePicker>
    <VDatePicker v-else v-model="date" :popover="{visibility: 'click'}">
      <template #default="{ inputValue, inputEvents }">
        <div
          class="singleDate"
          v-bind="$emit('date-value', input, inputValue)"
        >
          <div>
            <input class="dateInput" :value="inputValue" v-on="inputEvents" />
          </div>
        </div>
      </template>
    </VDatePicker>
  </div>

  <div v-else-if="allowDateTime">
    <VDatePicker v-model="date" mode="dateTime" :popover="{visibility: 'click'}">
      <template #default="{ inputValue, inputEvents }">
        <div v-bind="$emit('date-time-value',  input, inputValue)">
          <div>
            <input class="dateInputSingle" 
            :value="inputValue" 
            v-on="inputEvents" />
          </div>
        </div>
      </template>
    </VDatePicker>
  </div>

  <div v-else>
    <VDatePicker v-model="date" class="singleDatePicker" :popover="{visibility: 'click'}">
      <template #default="{ inputValue, inputEvents }">
        <div v-bind="$emit('date-value', input, inputValue)">
          <div>
            <input
              class="dateInputSingle"
              :value="inputValue"
              v-on="inputEvents"
            />
          </div>
        </div>
      </template>
    </VDatePicker>
  </div>
</template>

<script>
import CheckBox from "./CheckBox.vue";

export default {
  name: "DatePicker",
  props: {
    input: Object,
    allowDateRange: Boolean,
    value: String,
    allowDateTime: Boolean,
    populateCurrentDate: {
      type: Boolean,
      default: true,
    },
    adjustForTimezone: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      dateRangeChecked: false,
      showDatePicker: false,
      internalRange: null,
      date: null,
      range: null,
      dateTime: null,
    };
  },
  created() {    

    if (this.populateCurrentDate) {
      if (this.allowDateRange) {
        this.range = {
          start: new Date(),
          end: new Date(),
        };
        this.date = new Date();
      } else {
        if (this.value) {
          this.date = this.adjustForTimezone ? this.adjustDateForTimezone(this.value) : new Date(this.value);
        } else {
          this.date = "";
        }
      }
      if (this.allowDateTime) {
        this.dateTime = new Date();
      }
    }
    else {
      this.date = null;
      this.dateTime = null;
    }

    
  },
  beforeUpdate () {
    if (this.value) {
      this.date = this.adjustForTimezone ? this.adjustDateForTimezone(this.value) : new Date(this.value);
    }
  },
  methods: {
    toggleSelected() {
      this.dateRangeChecked = !this.dateRangeChecked;
    },
    adjustDateForTimezone(dateStr) {
      if (!dateStr) return null;
      var date = new Date(dateStr + 'T00:00:00');
      return date;
    }
  },
  computed: {
    getStyle() {
      if (this.width === "parent") {
        return "fillParentWidth";
      } else {
        return "fitToText";
      }
    },
  },
  components: { CheckBox },
  emits: [
    "range-value",
    "date-value",
    "date-time-value"
  ]
};
</script>

<style scoped>
.dateInput {
  border: 0.25px solid #2e3e91;
  border-radius: 1px;
  padding-left: 10px;
  color: #271d7a;
  padding: 2px 2px 2px 8px;
  font-size: 9pt;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: right 5px center;
  background-size: 16px 16px;
  background-image: url("@/assets/icons/Calendar.png");
}
.dateInputSingle {
  border: 0.25px solid #2e3e91;
  border-radius: 2px;
  padding-left: 10px;
  color: #271d7a;
  padding: 2px 2px 2px 8px;
  font-size: 9pt;
  height: 28px;
  width: 100%;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: right 5px center;
  background-size: 16px 16px;
  background-image: url("@/assets/icons/Calendar.png");
}
input:focus {
  outline: none;
}
.inputLabelContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.singleDate {
  width: 50%;
  padding: 4px 0px 4px 0px;
}
.dateRange {
  display: flex;
  flex-wrap: wrap;
}
@media (min-width: 1029px) {
  .dateRange > * {
    flex: 1;
    margin-right: 10px;
  }
}
.dateRange > :last-child {
  margin-right: 0;
}
.inputLabelContainer label {
  font-size: 8pt;
  color: #271d7a;
  font-weight: 600;
  padding-bottom: 2px;
}
.dateCheckboxLabel {
  font-size: 8pt;
  color: #271d7a;
  font-weight: 600;
  vertical-align: middle;
}
.datePickerCheckboxContainer {
  display: flex;
  align-items: center;
  padding: 0px 0px 4px 1px;
}
</style>
