<template>
  <div class="audioPlayer">
    <audio
      ref="audioPlayer"
      :src="audioSrc"
      @timeupdate="updateProgressBar"
      @loadedmetadata="setDuration"
    ></audio>
    <div class="column">
      <div class="progressBar">
        <div class="progress" :style="{ width: progressPercent }"></div>
      </div>
      <div class="timeInfo">
        <span>{{ currentTimeFormatted }}</span>
        <span>{{ durationFormatted }}</span>
      </div>
      <div class="buttonContainer">
        <LightButtonWebRTC
          :style="{ height: '35px', width: '110px' }"
          :icon="isPlaying ? 'play.png' : 'play.png'"
          :title="isPlaying ? 'Pause' : 'Play'"
          @click="togglePlayPause"
        />
        <LightButtonWebRTC
          :style="{ height: '35px', width: '110px' }"
          icon="Remove.png"
          title="Delete"
          @click="handleDelete"
        />
      </div>
    </div>
  </div>
</template>
<script setup>
import LightButtonWebRTC from "../LightButtonWebRTC.vue";
import { ref, computed, onMounted, defineProps, toRefs, watch } from "vue";
const props = defineProps(["audio"]);
const { audio } = toRefs(props);
const audioPlayer = ref(null);
const audioSrc = audio;
const isPlaying = ref(false);
const currentTime = ref(0);
const duration = ref(0);

const progressPercent = computed(() => {
  if (!currentTime.value && !duration.value) {
    return 0 + "%";
  }
  return (currentTime.value / duration.value) * 100 + "%";
});

function updateProgressBar() {
  currentTime.value = audioPlayer.value.currentTime;
  duration.value = isNaN(audioPlayer.value.duration)
    ? 0
    : audioPlayer.value.duration;
}
function setDuration() {
  duration.value = isNaN(audioPlayer.value.duration)
    ? 0
    : audioPlayer.value.duration;
}

function formatTime(timeInSeconds) {
  const minutes = Math.floor(timeInSeconds / 60);
  const seconds = Math.floor(timeInSeconds % 60);
  return `${minutes.toString().padStart(2, "0")}:${seconds
    .toString()
    .padStart(2, "0")}`;
}

const currentTimeFormatted = computed(() => formatTime(currentTime.value));
const durationFormatted = computed(() => formatTime(duration.value));

function togglePlayPause() {
  if (audioPlayer.value.paused) {
    audioPlayer.value.play();
    isPlaying.value = true;
  } else {
    audioPlayer.value.pause();
    isPlaying.value = false;
  }
}
function handleDelete() {
      // TODO: what needs to be done here besides a console.log? 
  console.log("Delete clicked");
}
watch(props, (newProps) => {
  if (!newProps.audio) {
    // Reset progress and duration when audio becomes undefined
    duration.value = ref(0);
  }
});

onMounted(() => {
  audioPlayer.value.addEventListener("timeupdate", updateProgressBar);
  audioPlayer.value.addEventListener("ended", () => {
    isPlaying.value = false;
    currentTime.value = 0;
  });
});
</script>

<style scoped>
.audioPlayer {
  display: flex;
  align-items: center;
  width: 100%;
}

.progressBar {
  width: 300px;
  height: 4px;
  background-color: #e4edf4;
  position: relative;
  margin-right: 10px;
  width: 100%;
  border-radius: 2px;
  display: flex;
  flex-direction: column;
}

.progress {
  height: 100%;
  background-color: #3e87d3;
  border-radius: 2px;
}
.column {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.timeInfo {
  display: flex;
  justify-content: space-between;
  color: #271d7a;
  font-weight: 500;
  font-size: 10pt;
  padding-top: 10px;
  padding-bottom: 20px;
}
.buttonContainer {
  display: flex;
  justify-content: space-between;
  color: #271d7a;
  font-size: 10pt;
  padding-bottom: 30px;
}
</style>
