<template>
  <div
    class="sideDrawer formBackground"
    :class="{
      open: drawerOpen,
      top: topAligned,
    }"
    :style="{ width: dynamicWidth + 'px' }"
  >
    <button class="sideDrawerRemoveButton" @click="handleClose">
      <img
        class="sideDrawerRemoveButton"
        :src="require('@/assets/icons/Remove.png')"
      />
    </button>
    <div
      v-if="drawerData[0].type === 'form'"
      :style="{ height: dynamicHeight - 70 + 'px' }"
    >
      <FormComp
        :formData="drawerData"
        @close-click="handleClose"
        :numCols="numCols"
        @submissionDataUpdated="handleSubmissionDataUpdate"
        @submission-data="handleFormSubmission"
        :buttonOptions="buttonOptions"
        :fixed-inputs-width="fixedInputsWidth"
        :previousFormValues="previousSubmissionData"
        :clearForm="clearForm"
      />
    </div>
    <div v-else-if="drawerData[0].type === 'review'">
      <ReviewForm
        @button-click="$emit('button-click', $event)"
        :drawer-data="drawerData"
        @confirm-review="$emit('confirm-edit', $event)"
      />
    </div>
    <div class="drawerCloseContainer">
      <div v-if="drawerData[0].button === 'Close'">
        <DividerComp class="sideDrawerDivider" />
        <div class="drawerCloseButton">
          <DarkButton
            :title="drawerData[0].button"
            width="parent"
            @click="handleClose"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DividerComp from "@/components/base_components/DividerComp.vue";
import DarkButton from "@/components/base_components/Buttons/DarkButton.vue";
import FormComp from "./FormComp.vue";
import ReviewForm from "@/components/base_components/FormComponents/ReviewForm.vue";

export default {
  name: "FormSideDrawer",
  props: {
    drawerOpen: Boolean,
    drawerData: Array,
    width: Number,
    previousSubmissionData: Object,
    numCols: { type: Number, default: 6 },
    topAligned: { type: Boolean, default: true },
    fixedInputsWidth: {
      type: Boolean,
      default: true
    },
    clearForm: Boolean,
  },
  data() {
    return {
      submissionData: {},
      windowHeight: null,
    };
  },
  mounted() {
    //console.log('Drawer Data on Mounted:', this.drawerData);
    window.addEventListener("resize", this.handleResize);
    this.windowHeight = window.innerHeight;
  },

  beforeUnmount() {
    window.removeEventListener("resize", this.handleResize);
  },
  computed: {
    buttonOptions () {
      return {
        ...this.drawerData[0].buttonOptions,
        divider: true
      }
    },
    dynamicHeight() {
      return Number(this.windowHeight) - 39;
    },
    dynamicWidth() {
      const type = this.drawerData[0].type;
      if (type === "form") {
        if (this.width) {
          return this.width;
        }
        return 550;
      } else if (type === "info") {
        return 350;
      } else {
        return 300;
      }
    },
  },
  watch: {
    drawerData() {
      this.submissionData = {};
    },
    windowHeight() {
      this.$nextTick(() => {
        this.dynamicHeight;
      });
    },
  },
  methods: {
    handleClose() {
      this.$emit("close-click");
    },
    handleSubmit(type) {
      this.$emit("submit-click", this.submissionData, type);
    },
    handleFormSubmission(formData) {
      if (formData === "Error") {
        this.$emit("form-submission", "Error");
      } else {
        this.$emit("form-submission", formData);
      }
    },
    updateInputVal(name, event) {
      if (event.target.value) {
        this.submissionData[name] = event.target.value;
      } else {
        delete this.submissionData[name];
      }
    },
    updateDropDownMultiVal(name, input) {
      if (name == "Categories") {
        const values = input.map((obj) => obj.label);
        this.$emit("add-category", values);
      } else {
        const values = input.map((obj) => obj.value);
        this.submissionData[name] = values;
      }
    },
    updateDropDownVal(name, input) {
      this.submissionData[name] = input.value;
    },
    handleSubmissionDataUpdate(data) {
      this.$emit("submissionDataUpdate", data);
    },
  },
  components: {
    DividerComp,
    DarkButton,
    FormComp,
    ReviewForm,
  },
  emits: [
    "button-click",
    "confirm-edit",
    "close-click",
    "submit-click",
    "form-submission",
    "add-category",
    "submissionDataUpdate"
  ]
};
</script>

<style scoped>
.sideDrawer {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 6;
  bottom: 0;
  background-color: #f4fafa;
  overflow-x: auto;
  transition: transform 0.2s ease;
  transform: translateX(100%);
  display: flex;
  flex-direction: column;
}
.top {
  top: 93px;
}
.sideDrawer.open {
  filter: drop-shadow(5px 5px 5px rgb(39, 29, 122, 1));
  transform: translateX(0);
}
.drawerTitle {
  color: #271e7a;
  font-weight: bold;
  font-size: 14pt;
  padding-top: 5px;
}
.drawerCloseContainer {
  margin-top: auto;
}

.sideDrawerDivider {
  margin-bottom: 20px;
}
.sideDrawerRemoveButton {
  position: absolute;
  top: 0;
  right: 0;
  width: 31px;
  height: 35px;
  padding: 20px 16px 0px 0px;
}
.formBackground {
  background-color: #ffffff;
}
.drawerCloseButton {
  padding: 0px 20px 20px 20px;
}
:deep(.formButtonPositionBottom .formDarkButton ) {
  padding-left: 16px;
  padding-right: 16px;
}
</style>
