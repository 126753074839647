const ADD_INFRACTION = 'Add Infraction';
const EDIT_INFRACTION = 'Edit Infraction';
const REMOVE_INFRACTION = 'Remove Infraction';
const INFRACTION_CREATE_ALERT = 'Successfully Added Infraction';
const INFRACTION_REMOVE_ALERT = 'Infraction was removed';
const INFRACTION_UPDATE_ALERT = 'Infraction was updated';

export {
  ADD_INFRACTION,
  EDIT_INFRACTION,
  REMOVE_INFRACTION,
  INFRACTION_CREATE_ALERT,
  INFRACTION_REMOVE_ALERT,
  INFRACTION_UPDATE_ALERT
}