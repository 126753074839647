<template>
  <hr class="divider" />
</template>

<script>
export default {
  name: "DividerComp",
};
</script>

<style scoped>
.divider {
  border: none;
  border-top: 0.5px solid #dde9f4;
}
</style>
