<template>
  <div class="questionsList">
    <div class="progressBar">
      <ProgressBar
        :steps="steps"
        :questionCount="getQuestionCount"
        :currentIndex="questionIndex"
        :height="getQuestionCount * 32 - 10 + 'px'"
        stepHeight="22px"
      ></ProgressBar>
    </div>
    <div class="steps">
      <div v-for="(section, index) in steps" :key="index">
        <div
          class="question"
          :class="{
            activeQuestion: index === currentIndex,
            questionMargin: index !== Object.keys(steps).length,
          }"
        >
          <p v-if="isMenuClickable">
            <button @click="handleSectionClick(index)" v-if="isMenuClickable">
              {{ section.title }}
            </button>
          </p>
          <p v-else>
            {{ section.title }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProgressBar from "./ProgressBar";
export default {
  name: "ProgressMenu",
  props: {
    steps: Array,
    currentIndex: Number,
    questionIndex: Number,
    isMenuClickable: Boolean,
  },
  computed: {
    getQuestionCount() {
      return this.steps.length;
    },
  },
  methods: {
    handleSectionClick(index) {
      this.$emit("sectionClick", index);
    },
  },
  components: { ProgressBar },
  emits: ["sectionClick"],
};
</script>

<style scoped>
.questionsList {
  max-width: 300px;
  padding-right: 10px;
  padding-left: 10px;
  display: flex;
  justify-content: space-around;
}
.activeQuestion {
  font-weight: bold;
  color: #3e87d3;
}

.question {
  height: 22px;

  /* overflow: hidden; */
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 185px;
  font-weight: normal;
  font-size: 14px;
  color: #271d7a;
}

.activeQuestion {
  font-weight: bold;
  color: #3e87d3;
}
.steps {
  margin-right: auto;
  padding-left: 10px;
}
.progressBar {
  padding-left: 5px;
}
.questionMargin {
  margin-bottom: 10px;
}
</style>
