<template>
  <div class="activeCallHeader">
    <img
      :src="require('@/assets/icons/call_white.png')"
      class="activeCallIcon"
      @click="handleActiveClick"
    />
    <h1 class="activeCallText" @click="handleActiveClick">
      Active Call
      <span :style="{ paddingLeft: '10px' }">
        ({{ currentCall["Total Call Duration"] }})
      </span>
    </h1>
    <img
      :src="muted ? require('@/assets/icons/unmute_combined.png') : require('@/assets/icons/mute_combined.png') "
      class="mute-icon"
      @click="handleMute"/>
  </div>
  <div class="recording" v-if="false">
    <!-- TODO: need to show this based on group in the future -->
    <div>
      <img class="recordImg" :src="require('@/assets/icons/record.png')" />
      <span class="recordStatus"> Recording </span>
    </div>
    <LightButton
      @click="handleRecordClick"
      :title="recording ? 'Pause' : 'Resume'"
      class="recordButton"
    />
  </div>
  <DividerComp />
  <div class="activeCallInfo">
    <div
      v-for="(value, key, index) in currentCall"
      :key="index"
      class="activeCallDetail"
    >
      <label class="activeCallDetailLabel" v-if="key !== 'status'">{{
        key
      }}</label>
      <span class="activeCallDetailValue" v-if="key !== 'status'">
        {{ value }}</span
      >
    </div>
  </div>

  <div v-if="transferStarted" class="activeCallButtonContainer">
    <DividerComp class="activeCallDivider" />
    <NumberTextBox
      ref="enterNumberBox"
      placeholder="Enter Number"
      class="enterNumberBox"
      :value="this.transferNumber"
      @keydown="handleNumberBoxKeyPress"
      @input="handleNumberBoxInput"
      @paste="handlePaste"
    />
    <div class="activeButtonContainer">
      <LightButton
        icon="transfer.png"
        title="Warm"
        :style="{ height: '35px', width: '100px' }"
        @click="handleWarm"
      /><LightButton
        icon="transfer.png"
        title="Blind"
        :style="{ height: '35px', width: '100px' }"
        @click="handleBlind"
      /><LightButton
        title="X"
        :style="{ height: '35px', width: '40px' }"
        @click="handleTransfer"
      />
    </div>
    <div class="hangupButtonContainer">
      <LightButton
        class="hangupButton"
        icon="hangup.png"
        :style="{
          height: '35px',
          backgroundColor: '#e78535',
          color: 'white',
        }"
        @click="handleHangup"
      />
    </div>
  </div>
  <div v-else class="activeCallButtonContainer">
    <DividerComp class="activeCallDivider" />
    <div class="activeButtonContainer">
      <LightButton
        icon="hold.png"
        title="Hold"
        :style="{ height: '35px', width: '120px' }"
        @click="handleHold"
      /><LightButton
        icon="transfer.png"
        title="Transfer"
        :style="{ height: '35px', width: '125px' }"
        @click="handleTransfer"
      />
      <!-- <LightButton
        :icon="muted ? 'unmute_combined.png' : 'mute_combined.png' "
        :title="muted ? 'Unmute' : 'Mute'"
        :style="{ height: '35px', width: '80px' }"
        @click="handleMute"
      /> -->
    </div>
    <div class="hangupButtonContainer">
      <LightButton
        class="hangupButton"
        icon="hangup.png"
        :style="{
          height: '35px',
          backgroundColor: '#e78535',
          color: 'white',
        }"
        @click="handleHangup"
      />
    </div>
  </div>
</template>

<script>
import DividerComp from "@/components/base_components/DividerComp.vue";
import LightButton from "../LightButtonWebRTC.vue";
import NumberTextBox from "../Keypad/NumberTextBox.vue";
import { useWebRTCStore } from "@/stores/webRTC/webRTCStore.js";
import { mapActions, mapStores } from "pinia";
export default {
  name: "ActiveCallComp",
  props: {
    currentCall: Object,
  },
  emits: ["updateOnHold"],
  components: { DividerComp, LightButton, NumberTextBox },
  data() {
    return {
      recording: true,
      transferStarted: false,
      transferNumber: "",

    };
  },
  computed: {
    ...mapStores(useWebRTCStore),

    muted() {
      return this.WebRTCStore.getMuteState;
    }
  },
  methods: {
    ...mapActions(useWebRTCStore, ["changeNumber", "toggleMuted", "clearMute"]),
    handleRecordClick() {
      this.WebRTCStore.currentCallObject.sendDTMF("*91"); // recording code needs to be sent
      this.recording = !this.recording;

    },
    handleHangup() {
      this.WebRTCStore.hangupCurrentCall();
      this.$emit('updateOnHold');
    },
    handleHold() {
      let current_call = this.WebRTCStore.getCurrentCallObject;
      if(current_call){
        current_call._clearPrimary();
      } else {
        this.$emit('updateOnHold');
      }
    },
    handleBlind() {
      // if textbox value is empty
      let current_call = this.WebRTCStore.getCurrentCallObject;
      if(current_call){
        current_call.transfer(this.transferNumber);
      }
    },
    handleWarm() {
      let current_call = this.WebRTCStore.getCurrentCallObject;
      if(current_call){
        current_call._clearPrimary();
        // current_call.transfer();
        let number_to_dial = [...this.transferNumber];

        let dial_pad = this.WebRTCStore.getLibWebPhone.getDialpad();
        dial_pad.clear();

        for (var digit of number_to_dial) {
          dial_pad.dial(digit);
        }

        dial_pad.call(false);
        dial_pad.clear();
        this.WebRTCStore.changeSelectedPage("Calls On Hold");
      }
    },
    handleTransfer() {
      if (this.currentCall["Call Type"] === "Conference") {
        console.log(this.currentCall);
        console.log("HANDLING CONFERENCE TRANSFER");
        var joined_call = this.WebRTCStore.getJoinedCallObject;

        if(joined_call){
          let current_call = this.WebRTCStore.getCurrentCallObject;
          if(current_call){
            let call_sesion = current_call._getSession();
            joined_call.warmTransfer(current_call.remoteIdentity(true)._uri._user, call_sesion);
          }
        }
      }
      else {
        this.transferStarted = !this.transferStarted;
      }
    },
    handleMute(){
      this.WebRTCStore.toggleMuted();
    },
    handleActiveClick() {
      this.transferStarted = false;
    },
    handleNumberBoxKeyPress(event) {
      const regex = /^[0-9*#]$/;
      const allowedKeys = ["Backspace", "Delete", "Home", "End", "ArrowLeft", "ArrowRight", "ArrowUp", "ArrowDown"];

      if (!regex.test(event.key) && !allowedKeys.includes(event.key)) {
        event.preventDefault();
      }
    },
    handleNumberBoxInput(event) {
      let value = event.target.value;
      value = value.replace(/[^0-9*#]/g, "");

      this.transferNumber = value;
      this.WebRTCStore.changeNumber(value);

    },
    handlePaste(event) {
      event.preventDefault();
      const pastedText = event.clipboardData.getData("text");
      this.transferNumber = pastedText.replace(/[^0-9*#]/g, "");
      this.WebRTCStore.changeNumber(pastedText.replace(/[^0-9*#]/g, ""));
    },
  },
};
</script>

<style scoped>
.activeCallInfo {
  padding: 20px 10px 0px 10px;
  background-color: #f8f9fc;
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: auto;
  height: 100%;
}
.activeCallHeader {
  padding-top: 25px;
  padding-bottom: 25px;
  background-color: #e78535;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.activeCallHeader:hover {
  outline: 0.25px solid #271d7a;
  cursor: pointer;
}
.recording {
  background-color: #f8f9fc;
  padding: 10px 0px 10px 20px;
  display: flex;
  justify-content: space-between;
}
.recordStatus {
  color: #271d7a;
  font-weight: 500;
}
.recordImg {
  width: 13px;
  margin-right: 10px;
}
.activeCallIcon {
  height: 30px;
  width: 24px;
  margin-right: 10px;
  margin-left: 20px;
  text-align: center;
}
.activeCallText {
  color: #ffffff;
  font-size: 19px;
  font-weight: 500;
}
.activeCallButtonContainer {
  margin: auto auto 0 auto;
  padding-bottom: 30px;
  width: 100%;
  background-color: #f8f9fc;
  flex: 1;
}
.activeCallDivider {
  width: 100%;
  margin-bottom: 25px;
}

.activeCallButton {
  width: 80%;
  margin: auto;
}
.recordButton {
  margin-right: 10px;
  width: 80px;
}
.activeCallDetail {
  padding-bottom: 15px;
  padding-left: 15px;
  display: flex;
  flex-direction: column;
}
.activeCallDetailLabel {
  color: #3e87d3;
  font-size: 10pt;
  padding-bottom: 5px;
}
.activeCallDetailValue {
  color: #271d7a;
  font-size: 10pt;
  font-weight: 400;
}
.activeButtonContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0px 15px 0px 15px;
}
.hangupButtonContainer {
  display: flex;
  padding-top: 20px;
}
.hangupButton {
  width: 90%;
  display: flex;
  margin: auto;
}
.enterNumberBox {
  display: flex;
  margin: auto;
  margin-bottom: 20px;
  width: 90%;
  font-weight: bold;
}
.mute-icon {
  height: 35px;
  margin-right: 15px;
}
</style>
