<template>
  <button
    class="menu-item"
    :class="{ 'is-active': isActive ? isActive() : null }"
    @click.stop.prevent="action"
    :title="title"
    ref="buttonElement"
  >
    <svg class="remix" @click="isColorPicker && selectColorAndClose()">
      <use :xlink:href="`${remixiconUrl}#ri-${icon}`" />
    </svg>

    <v-color-picker
      hide-canvas
      hide-inputs
      show-swatches
      :swatches="swatches"
      :class="{ showColorPicker }"
      v-model="textColor"
      class="color-picker"
      @click.prevent
    ></v-color-picker>
  </button>
</template>

<script>
import remixiconUrl from "remixicon/fonts/remixicon.symbol.svg";

export default {
  props: {
    icon: {
      type: String,
      required: true,
    },

    title: {
      type: String,
      required: true,
    },

    action: {
      type: Function,
      required: true,
    },

    isActive: {
      type: Function,
      default: null,
    },
    isColorPicker: {
      type: Boolean,
      default: false,
    },
    editor: {
      type: Object,
      required: false,
    },
    actionType: {
      type: String,
      required: false,
    },
  },

  data() {
    return {
      remixiconUrl,
      showColorPicker: false,
      textColor: "black",
      swatches: [
        ['#000000'], ['#271d7a'], ['#5fc0d3'],
        ['#eee253'], ['#e87928'], ['#add38d'], ['#FF0000']
      ],
    };
  },
  methods: {
    selectColorAndClose() {
      if (this.actionType === "highlight") {
        this.editor
          .chain()
          .focus()
          .toggleHighlight({ color: this.textColor })
          .run();
      } else {
        this.editor.chain().focus().setColor(this.textColor).run();
      }
      this.showColorPicker = !this.showColorPicker;
    },

    handleOutsideClick(event) {
      const colorPickerElement = this.$el.querySelector(".color-picker");
      const buttonElement = this.$refs.buttonElement;

      if (
        colorPickerElement &&
        !colorPickerElement.contains(event.target) &&
        buttonElement &&
        !buttonElement.contains(event.target)
      ) {
        this.showColorPicker = false;
      }
    },
  },
  mounted() {
    // Attach a click event listener to the window
    window.addEventListener("click", this.handleOutsideClick);
  },
  beforeUnmount() {
    // Clean up the event listener when the component is destroyed
    window.removeEventListener("click", this.handleOutsideClick);
  },
};
</script>

<style scoped>
.menu-item {
  border: none;
  border-radius: 0.4rem;
  color: #fff;
  cursor: pointer;
  height: 1.75rem;
  padding: 0.25rem;
  margin-right: 0.25rem;
  position: relative;
  /* z-index: 100000; */
}

.menu-item.is-active {
  border: 2px solid black;
  box-shadow: #271d71;
}
svg {
  height: 100%;
  width: 20px;
}

.color-picker.v-color-picker {
  border: 2px solid black;
  position: absolute;
  z-index: 10;
  display: none;
}

.color-picker.v-color-picker.showColorPicker {
  display: block;
}

.color-picker.v-color-picker:focus-within {
  display: block;
}
</style>
