<template>
  <div>
    <a :href="url" rel="noopener noreferrer" target="_blank"><span v-html="question"></span></a>
  </div>
</template>

<script>
export default {
  name: "SmartLink",
  emits: ["url"],
  props: {
    url: String,
    question: String,
  },
};
</script>
<style scoped></style>
