import { defineStore } from 'pinia'
import { API } from "aws-amplify";
import * as queries from "@/graphql/queries.js";
import * as mutations from "@/graphql/mutations.js";
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api";

export const useCCManagementAttendanceStore = defineStore('CCManagementAttendance', {
    state: () => ({
        overview: [],
        infractions: [],
        employees: [],
    }), 
    getters: {
        getOverview(state) {
            return state.overview
        },
        getInfractions(state) {
            return state.infractions
        },
        getEmployees(state) {
            return state.employees
        }
    },
    actions: {
        getCorrectValue(data) {
            if (typeof data === 'object' && data !== null && 'label' in data) {
                return data.label;
            }
            return data;
        },
        calculateDisciplinaryLevel(infractions) {
            return infractions.length >= 5 ? 'High' : 'Low';
        },
        async getEmployeeOverview() {
            try {
                const customQuery = `
                    query MyQuery {
                        listEmployees(filter: {assigned_group: {eq: "Contact Center"}}) {
                            items {
                                id
                                name
                                email
                                assigned_group
                                contact_center_infractions {
                                    items {
                                        infraction
                                        date
                                        details
                                    }
                                }
                                employee_actions {
                                    items {
                                        action
                                        date
                                        documentation
                                    }
                                }
                            }
                        }
                    }`;
        
                const queryOptions = {
                    query: customQuery,
                    authMode: GRAPHQL_AUTH_MODE.API_KEY
                };
        
                const queryResult = await API.graphql(queryOptions);
                const employees = queryResult.data.listEmployees.items;
        
                const overviewData = employees.map(employee => ({
                    id: employee.id,
                    name: employee.name,
                    email: employee.email,
                    infraction_count: employee.contact_center_infractions.items.length,
                    discipline_level: this.calculateDisciplinaryLevel(employee.contact_center_infractions.items),
                    infractions: employee.contact_center_infractions.items.map(infraction => ({
                        id: employee.id,
                        date: infraction.date,
                        infraction: infraction.infraction,
                        documentation: infraction.details
                    })),
                    disciplinary_actions: employee.employee_actions.items.map(action => ({
                        id: employee.id,
                        date: action.date,
                        disciplinary_action: action.action,
                        documentation: action.documentation
                    }))
                }));
        
                this.tableData = overviewData;
                return this.tableData;
        
            } catch (error) {
                return {error: true, message: "Unable to fetch overview"};
            }
        },
        async getEmployeeInformationOptions() {
            var error_message = "Unable to Get Employees";
            this.employeeOptions = [];
            try {
                var hasData = true;
                var nextToken = null;
                var employeeData = [];


                while (hasData) {
                    var options = {
                        query: queries.listEmployees,
                        variables: {
                            filter: {
                                _deleted: { ne: true },
                                assigned_group: { eq: "Contact Center" }
                            },
                            limit: 2000
                        },
                        authMode: GRAPHQL_AUTH_MODE.API_KEY
                    }

                    if (nextToken != null) {
                        options.variables.nextToken = nextToken;
                    }

                    var employees = await API.graphql(options);

                    for (const item of employees.data.listEmployees.items) {
                        employeeData.push(item.name);
                    }

                    if (employees.data.listEmployees.nextToken != null) {
                        nextToken = employees.data.listEmployees.nextToken;
                    }
                    else {
                        hasData = false;
                    }
                }

                this.employeeOptions = employeeData;
                return this.employeeOptions
            }
            catch(error) {
                return {error: true, message: error_message};
            }
        },
        async getSpecificEmployeeInformation() {
            var employees = await this.getEmployeeInformation();
            var finalList = [];
            var filteredList = employees.filter(obj => obj.employee.assigned_group == "Contact Center");
            for(var person of filteredList) {
                var temp = {
                    id: person.id,
                    employee_id: person.employee_id,
                    name: person.employee.name,
                    scheduled_shift: person.employee.work_days
                }
                finalList.push(temp);
            }

            return finalList;

        },
        async getEmployeeInformation() {
            var error_message = "Unable to Get Employees";
            this.employees = [];
            this.employeeVersions = {};
            try {
                var hasData = true;
                var nextToken = null;
                var employeeData = [];


                const listEmployeeActions = /* GraphQL */ `
                query ListEmployeeActions(
                    $filter: ModelEmployeeActionsFilterInput
                    $limit: Int
                    $nextToken: String
                ) {
                    listEmployeeActions(filter: $filter, limit: $limit, nextToken: $nextToken) {
                    items {
                        id
                        employee_id
                        date
                        action
                        employee {
                            name
                        }
                        documentation
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                        __typename
                    }
                    nextToken
                    startedAt
                    __typename
                    }
                }
                `;


                while (hasData) {
                    var options = {
                        query: listEmployeeActions,
                        variables: {
                            filter: {
                                _deleted : { ne: true },
                            },
                            limit: 2000
                        },
                        authMode: GRAPHQL_AUTH_MODE.API_KEY
                    }

                    if (nextToken != null) {
                        options.variables.nextToken = nextToken;
                    }

                    var employees = await API.graphql(options);

                    for (const item of employees.data.listEmployeeActions.items) {
                        employeeData.push(item);
                    }

                    if (employees.data.listEmployeeActions.nextToken != null) {
                        nextToken = employees.data.listEmployeeActions.nextToken;
                    }
                    else {
                        hasData = false;
                    }
                }

                this.employees = employeeData;

                return this.employees
            }
            catch(error) {
                return {error: true, message: error_message};
            }
        },
        async addEmployee(date, employee, disciplinary_action, documentation) {
            var error_message = "Unable to Add Employee";
            
            const queryOptions = {
                query: queries.listEmployees,
                variables: {
                    filter: { name: { eq: employee } }
                },
                authMode: GRAPHQL_AUTH_MODE.API_KEY
            };
            
            try {
                const queryResult = await API.graphql(queryOptions);
                
                if (queryResult.data.listEmployees.items.length === 0) {
                    return { error: true, message: "Employee not found" };
                }
                
                const employee_id = queryResult.data.listEmployees.items[0].id;

                const [month, day, year] = date.split('/');
                const dateObj = new Date(`${year}-${month}-${day}`);
            
                const awsDate = `${dateObj.getFullYear()}-${String(dateObj.getMonth() + 1).padStart(2, '0')}-${String(dateObj.getDate()).padStart(2, '0')}`;
                
                const options = {
                    query: mutations.createEmployeeActions,
                    variables: {
                        input: {
                            date: awsDate,
                            action: disciplinary_action,
                            documentation: documentation,
                            employee_id: employee_id 
                        }
                    },
                    authMode: GRAPHQL_AUTH_MODE.API_KEY
                };
                
                const result = await API.graphql(options);
                
                if (!result.data.createEmployeeActions.id) {
                    return { error: true, message: error_message };
                }
                
                return { error: false, message: 'Ok' };
            }
            catch(error) {
                return { error: true, message: error_message};
            }
        },
        async editEmployee(id, date, employee, disciplinary_action, documentation) {

            employee = this.getCorrectValue(employee);
            disciplinary_action = this.getCorrectValue(disciplinary_action);

            var error_message = "Unable to Edit Employee";

            try {
                const queryOptions = {
                    query: queries.getEmployeeActions,
                    variables: { id: id },
                    authMode: GRAPHQL_AUTH_MODE.API_KEY
                };

                const queryResult = await API.graphql(queryOptions);

                if (!queryResult.data || !queryResult.data.getEmployeeActions) {
                    return { error: true, message: "Infraction not found" };
                }

                const _version = queryResult.data.getEmployeeActions._version;

                const [month, day, year] = date.split('/');
                const dateObj = new Date(`${year}-${month}-${day}`);
            
                const awsDate = `${dateObj.getFullYear()}-${String(dateObj.getMonth() + 1).padStart(2, '0')}-${String(dateObj.getDate()).padStart(2, '0')}`;

                var options = {
                    query: mutations.updateEmployeeActions,
                    variables: {
                        input: {
                            id: id,
                            date: awsDate,
                            action: disciplinary_action,
                            documentation: documentation,
                            _version: _version
                        },
                    },
                    authMode: GRAPHQL_AUTH_MODE.API_KEY
                }

                var result = await API.graphql(options);

                if (!result.data.updateEmployeeActions.id) {
                    return {error: true, message: error_message};
                }

                return { error: false }
            }
            catch(error) {
                return {error: true, message: error_message};
            }
        },
        async removeEmployee(id) {
            var error_message = "Unable to Remove Employee";
            try {

                const queryOptions = {
                    query: queries.getEmployeeActions,
                    variables: { id: id },
                    authMode: GRAPHQL_AUTH_MODE.API_KEY
                };

                const queryResult = await API.graphql(queryOptions);

                if (!queryResult.data || !queryResult.data.getEmployeeActions) {
                    return { error: true, message: "Infraction not found" };
                }

                const _version = queryResult.data.getEmployeeActions._version;

                var options = {
                    query: mutations.deleteEmployeeActions,
                    variables: {
                        input: {
                            id: id,
                            _version: _version
                        }
                    },
                    authMode: GRAPHQL_AUTH_MODE.API_KEY
                }

                var result = await API.graphql(options);

                if (!result.data.deleteEmployeeActions.id) {
                    return {error: true, message: error_message};
                }

                return {error: false }
            }
            catch(error) {
                return {error: true, message: error_message};
            }
        },
        async getInfractionInformation() {
            var error_message = "Unable to Get Infractions";
            this.infractions = [];
            this.infractionVersions = {};
            try {
                var hasData = true;
                var nextToken = null;
                var infractionData = [];


                while (hasData) {
                    var options = {
                        query: queries.listContactCenterInfractions,
                        variables: {
                            filter: {
                                _deleted: { ne: true },
                            },
                            limit: 2000
                        },
                        authMode: GRAPHQL_AUTH_MODE.API_KEY
                    }

                    if (nextToken != null) {
                        options.variables.nextToken = nextToken;
                    }

                    var infractions = await API.graphql(options);

                    for (const item of infractions.data.listContactCenterInfractions.items) {
                        infractionData.push(item);
                    }

                    if (infractions.data.listContactCenterInfractions.nextToken != null) {
                        nextToken = infractions.data.listContactCenterInfractions.nextToken;
                    }
                    else {
                        hasData = false;
                    }
                }

                this.infractions = infractionData;

                return this.infractions

            }
            catch(error) {
                return {error: true, message: error_message};
            }
        },
        async addInfraction(date, employee, infraction, documentation, scheduled_shift, clock_in_time, clock_out_time) {
            var error_message = "Unable to Add Infraction";

            const queryOptions = {
                query: queries.listEmployees,
                variables: {
                    filter: { name: { eq: employee } }
                },
                authMode: GRAPHQL_AUTH_MODE.API_KEY
            };

            try {
                const queryResult = await API.graphql(queryOptions);
                
                if (queryResult.data.listEmployees.items.length === 0) {
                    return { error: true, message: "Employee not found" };
                }
                
                const employee_id = queryResult.data.listEmployees.items[0].id;

                const [month, day, year] = date.split('/');
                const dateObj = new Date(Date.UTC(year, month - 1, day)); // Months are 0-based

                const isoYear = dateObj.getUTCFullYear();
                const isoMonth = String(dateObj.getUTCMonth() + 1).padStart(2, '0');
                const isoDay = String(dateObj.getUTCDate()).padStart(2, '0');

                // Construct the AWS date format
                const awsDate = `${isoYear}-${isoMonth}-${isoDay}`;

                var options = {
                    query: mutations.createContactCenterInfractions,
                    variables: {
                        input: {
                            date: awsDate,
                            employee_name: employee,
                            infraction: infraction === 'Yes' ? true : false,
                            details: documentation,
                            scheduled_shift: scheduled_shift,
                            clock_in_time: clock_in_time,
                            clock_out_time: clock_out_time,
                            employee_id: employee_id
                        }
                    },
                    authMode: GRAPHQL_AUTH_MODE.API_KEY
                }

                var result = await API.graphql(options);

                if (!result.data.createContactCenterInfractions.id) {
                    return {error: true, message: error_message};
                }
                
                return {error: false, message: 'Ok'};
            }
            catch(error) {
                return {error: true, message: error_message};
            }
        },
        async editInfraction(id, date, employee, infraction, documentation, scheduled_shift, clock_in_time, clock_out_time ) {

            employee = this.getCorrectValue(employee);
            infraction = this.getCorrectValue(infraction);

            var error_message = "Unable to Edit Infraction";
            try {
                if (id === "" || typeof id === "undefined") {
                    return { error: true, message: error_message };
                }

                const queryOptions = {
                    query: queries.getContactCenterInfractions,
                    variables: { id: id },
                    authMode: GRAPHQL_AUTH_MODE.API_KEY
                };

                const queryResult = await API.graphql(queryOptions);

                if (!queryResult.data || !queryResult.data.getContactCenterInfractions) {
                    return { error: true, message: "Infraction not found" };
                }

                const _version = queryResult.data.getContactCenterInfractions._version;

                const [month, day, year] = date.split('/');
                const dateObj = new Date(Date.UTC(year, month - 1, day)); // Months are 0-based

                const isoYear = dateObj.getUTCFullYear();
                const isoMonth = String(dateObj.getUTCMonth() + 1).padStart(2, '0');
                const isoDay = String(dateObj.getUTCDate()).padStart(2, '0');

                // Construct the AWS date format
                const awsDate = `${isoYear}-${isoMonth}-${isoDay}`;
        
                var options = {
                    query: mutations.updateContactCenterInfractions,
                    variables: {
                        input: {
                            id: id,
                            date: awsDate,
                            employee_name: employee,
                            infraction: infraction,
                            details: documentation,
                            scheduled_shift: scheduled_shift,
                            clock_in_time: clock_in_time,
                            clock_out_time: clock_out_time,
                            _version: _version
                        },
                    },
                    authMode: GRAPHQL_AUTH_MODE.API_KEY
                };
        
                var result = await API.graphql(options);
        
                if (!result.data.updateContactCenterInfractions.id) {
                    return { error: true, message: error_message };
                }
        
                return { error: false, message: "Infraction successfully edited" };
            } catch (error) {
                return { error: true, message: error_message };
            }
        },
        async removeInfraction(id) {
            var error_message = "Unable to Remove Infraction";
            try {
                if(id == "" || typeof id == undefined) {
                    return {error: false, message: error_message};
                }

                const queryOptions = {
                    query: queries.getContactCenterInfractions,
                    variables: { id: id },
                    authMode: GRAPHQL_AUTH_MODE.API_KEY
                };

                const queryResult = await API.graphql(queryOptions);

                if (!queryResult.data || !queryResult.data.getContactCenterInfractions) {
                    return { error: true, message: "Infraction not found" };
                }

                const _version = queryResult.data.getContactCenterInfractions._version;

                var options = {
                    query: mutations.deleteContactCenterInfractions,
                    variables: {
                        input: {
                            id: id,
                            _version: _version
                        }
                    },
                    authMode: GRAPHQL_AUTH_MODE.API_KEY
                }

                var result = await API.graphql(options);

                if (!result.data.deleteContactCenterInfractions.id) {
                    return {error: true, message: error_message};
                }

                return { error: false }
            }
            catch(error) {
                return {error: true, message: error_message};
            }
        },
    },
})