<template>
    <div class="sideDrawer formBackground" :class="{
        open: drawerOpen,
        top: topAligned,
    }" :style="{ width: dynamicWidth + 'px' }">
        <button class="sideDrawerRemoveButton" @click="handleClose">
            <img class="sideDrawerRemoveButton" :src="require('@/assets/icons/Remove.png')" />
        </button>
        <h1 class="createFlowHeader" v-if="editFlow.length">Edit Flow</h1>
        <h1 class="createFlowHeader" v-else-if="copyFlowValue.length">Copy Flow</h1>
        <h1 class="createFlowHeader" v-else>Create New Flow</h1>
        <div :style="{ height: dynamicHeight - 108 + 'px' }">
            <DarkButton style="margin-bottom: 15px; margin-left: 15px;"  title="Toggle Flows/Questions" @click="toggleSections" /> 
            <p class="label" :style="{ paddingLeft: '16px' }">Search</p>
            <SearchBar placeholder="Search" class="questionSearch" @input="handleSearchValue"
                :value="searchValue" />
            <div class="row">
                <div v-if="section == 'Questions'" class="column">
                    <label class="label">Available Questions</label>
                    <ExpandableAddQuestionCard @toggle="handleQuestionFlip" @update-questions="updateQuestionsList"/>
                    <AvailableQuestionsList :questions="questionList" @toggle="toggleCard"
                        @addQuestion="handleAddQuestion" v-if="!createQuestionsToggle"/>
                </div>
                <div v-else class="column">
                    <label class="label">Available Flows</label>
                    <AvailableFlowsList :flows="flowList" @toggle="toggleCard"
                        @addFlow="handleAddFlow" />
                </div>
                <div class="column">
                    <label class="label">Current Flow</label>
                    <CurrentFlow :questions="currentFlow" @moveQuestion="handleMovingQuestion" @changeFocus="handleChangeFocus" @toggle="toggleCard"
                        @addDependentToFlow="handleAddDependent" @removeQuestion="handleRemoveQuestion" />
                </div>
            </div>
            <div class="drawerCloseContainer">
                <DividerComp class="sideDrawerDivider" />
                <div :style="{ width: '250px', margin: 'auto' }">
                    <DarkButton class="submitButton" title="Finalize Flow" width="parent" @click="handleFormSubmission" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import DividerComp from "@/components/base_components/DividerComp.vue";
import DarkButton from "@/components/base_components/Buttons/DarkButton.vue";
import SearchBar from "./SearchBarFlow.vue";
import AvailableQuestionsList from "./AvailableQuestionsList.vue";
import ExpandableAddQuestionCard from "./ExpandableAddQuestionCard.vue";
import AvailableFlowsList from "./AvailableFlowsList.vue";
import CurrentFlow from "./CurrentFlow.vue";

export default {
    name: "CreateFlowSideDrawer",
    props: {
        drawerOpen: Boolean,
        drawerData: Array,
        width: Number,
        previousSubmissionData: Object,
        numCols: { type: Number, default: 6 },
        topAligned: { type: Boolean, default: true },
        questions: Array,
        editFlow: Object,
        copyFlowValue: Object,
        flows: Array
    },
    data() {
        return {
            submissionData: {},
            addingDependent: false,
            currentDependentQ: {},
            level: 0,
            currentFlow: [],
            questionsCopy: [],
            flowsCopy: [],
            searchValue: "",
            section: "Questions",
            windowHeight: null,
            createQuestionsToggle: false
        };
    },
    mounted() {
        window.addEventListener("resize", this.handleResize);
        this.windowHeight = window.innerHeight;
    },

    beforeUnmount() {
        window.removeEventListener("resize", this.handleResize);
    },
    computed: {
        dynamicHeight() {
            return Number(this.windowHeight) - 39;
        },
        dynamicWidth() {
            return 1000;
        },
        questionList() {
            let searchVal = this.searchValue?.length
                ? this.searchValue.toLowerCase().trim()
                : "";
            return this.questionsCopy.filter((question) => {
                return question.description.toLowerCase().trim().includes(searchVal);
            });
        },
        flowList() {
            let searchVal = this.searchValue?.length
                ? this.searchValue.toLowerCase().trim()
                : "";
            return this.flowsCopy.filter((flow) => {
                return flow.name.toLowerCase().trim().includes(searchVal);
            });
        }
    },
    watch: {
        drawerData() {
            this.submissionData = {};
        },
        windowHeight() {
            this.$nextTick(() => {
                this.dynamicHeight;
            });
        },
        questions: {
            deep: true,
            immediate: true,
            handler(newQuestions) {
                this.questionsCopy = newQuestions;
            },
        },
        flows: {
            deep: true,
            immediate: true,
            handler(newFlows) {
                this.flowsCopy = newFlows;
            },
        },
        editFlow: {
            deep: true,
            immediate: true,
            handler(newFlow) {
                if (newFlow.length) {
                    this.currentFlow = newFlow;
                } else {
                    this.currentFlow = [];
                }
            },
        },
        copyFlowValue: {
            deep: true,
            immediate: true,
            handler(newFlow) {
                if (newFlow.length) {
                    this.currentFlow = newFlow;
                } else {
                    this.currentFlow = [];
                }
            },
        },
    },
    methods: {
        handleQuestionFlip(newVal) {
            this.createQuestionsToggle = newVal;
        },
        updateQuestionsList() {
            this.$emit('update-questions');
        },
        handleMovingQuestion(question, type) {
            this.findQuestionIndexAndMove(question, this.currentFlow, type)
        },
        findQuestionIndexAndMove(question, questionArray, type) {

            console.log("Question Clicked", question);
            console.log("Type of movement: ", type);
            console.log("ALL THE QUESTIONS: ", questionArray);

            for (let i = 0; i < questionArray.length; i++) {
                if (questionArray[i].timestamp == question.timestamp) {
                    if (type == "Move Up") {
                        let index = questionArray.findIndex(
                            (q) => q.timestamp === question.timestamp
                        );
                        
                        
                        console.log(index);

                        if (index > 0) {
                            let temp = questionArray[index - 1];
                            questionArray[index - 1] = questionArray[index];
                            questionArray[index] = temp;
                        }
                    } else if (type == "Move Down") {
                        let index = questionArray.findIndex(
                            (q) => q.timestamp === question.timestamp
                        );

                        console.log(index);

                        if (index < questionArray.length - 1) {
                            let temp = questionArray[index + 1];
                            questionArray[index + 1] = questionArray[index];
                            questionArray[index] = temp;
                        }
                    }
                    return;
                }
                if (questionArray[i].dependents) {
                    this.findQuestionIndexAndMove(question, questionArray[i].dependents, type);
                }
            }
        },
        handleClose() {
            this.currentFlow = [];
            this.searchValue = "";
            this.$emit("close-click");
        },
        toggleSections() {
            if (this.section == "Questions") {
                this.section = "Flows";
                this.createQuestionsToggle = false;
            } else {
                this.section = "Questions";
            }
        },
        handleSearchValue(searchValue) {
            this.searchValue = searchValue.target.value;
        },

        handleSubmit(type) {
            this.$emit("submit-click", this.submissionData, type);
        },
        handleFormSubmission() {
            if (this.currentFlow.length > 0) {
                this.$emit("form-submission", this.currentFlow);
            } else {
                this.$emit("form-submission", "Error");
            }
        },
        updateInputVal(name, event) {
            if (event.target.value) {
                this.submissionData[name] = event.target.value;
            } else {
                delete this.submissionData[name];
            }
        },
        updateDropDownMultiVal(name, input) {
            if (name == "Categories") {
                const values = input.map((obj) => obj.label);
                this.$emit("add-category", values);
            } else {
                const values = input.map((obj) => obj.value);
                this.submissionData[name] = values;
            }
        },
        updateDropDownVal(name, input) {
            this.submissionData[name] = input.value;
        },
        toggleCard(index, type, dependent) {
            if (type === "flow") {
                if (dependent !== undefined) {
                    dependent.open = !dependent.open;
                } else {
                    this.currentFlow[index].open = !this.currentFlow[index].open;
                }
            }
            else if (type === "FlowList") {
                let flows = this.flowList;
                flows[index].open = !flows[index].open;
            }
            else {
                let questions = this.questionList;
                questions[index].open = !questions[index].open;
            }
        },
        handleChangeFocus() {
            this.currentDependentQ = {};
        },
        handleAddDependent(option, question) {
            this.currentDependentQ = { option, question };
        },
        handleAddQuestion(question) {
            if (Object.keys(this.currentDependentQ).length) {
                let newDep = {
                    ...question,
                    open: true,
                    dependentOn: this.currentDependentQ.option.label,
                    question: this.currentDependentQ.question.description,
                    timestamp: Date.now(),
                };
                if (!this.currentDependentQ.question.dependents) {
                    this.currentDependentQ.question.dependents = [];
                }
                this.currentDependentQ.question.dependents.push(newDep);
            } else {
                this.currentFlow.push({ ...question, timestamp: Date.now() });
                this.currentDependentQ = {};
            }
        },
        handleAddFlow(flow) {
            if (Object.keys(this.currentDependentQ).length) {

                let newDep = {
                    flow: flow.flowName,
                    flow_file: flow.s3_staging_bucket_key.split("/")[1],
                    type: "Flow",
                    open: true,
                    dependentOn: this.currentDependentQ.option.label,
                    question: this.currentDependentQ.question.description,
                    timestamp: Date.now(),
                    description: flow.flowName
                };

                if (!this.currentDependentQ.question.dependents) {
                    this.currentDependentQ.question.dependents = [];
                }
                this.currentDependentQ.question.dependents.push(newDep);
            } else {
                this.currentFlow.push({ 
                    flow: flow.flowName,
                    flow_file: flow.s3_staging_bucket_key.split("/")[1],
                    type: "Flow", 
                });
                this.currentDependentQ = {};
            }
        },
        handleRemoveQuestion(question) {
            this.findQuestionRecursive(question, this.currentFlow);
        },
        findQuestionRecursive(question, currentFlow) {
            for (var flow_question of currentFlow) {
                if (flow_question.description == question.description && flow_question.timestamp == question.timestamp) {
                    const index_to_remove = currentFlow.findIndex((q) => q.description === question.description);
                    currentFlow.splice(index_to_remove, 1);
                    return;
                }

                if (flow_question.dependents) {
                    this.findQuestionRecursive(question, flow_question.dependents);
                }
            }      
        }
    },
    components: {
        DividerComp,
        DarkButton,
        SearchBar,
        AvailableQuestionsList,
        CurrentFlow,
        AvailableFlowsList,
        ExpandableAddQuestionCard
    },
    emits: [
        "close-click",
        "submit-click",
        "form-submission",
        "add-category",
        "update-questions"
    ]
};
</script>

<style scoped>
.sideDrawer {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 6;
    bottom: 0;
    background-color: #f4fafa;
    overflow-x: auto;
    transition: transform 0.2s ease;
    transform: translateX(100%);
    display: flex;
    flex-direction: column;
}

.top {
    top: 93px;
}

.sideDrawer.open {
    filter: drop-shadow(5px 5px 5px rgb(39, 29, 122, 1));
    transform: translateX(0);
}

.drawerTitle {
    color: #271e7a;
    font-weight: bold;
    font-size: 14pt;
    padding-top: 5px;
}

.drawerCloseContainer {
    position: absolute;
    bottom: 20px;
    width: 100%;
}

.sideDrawerDivider {
    margin-bottom: 20px;
}

.sideDrawerRemoveButton {
    position: absolute;
    top: 0;
    right: 0;
    width: 31px;
    height: 35px;
    padding: 20px 16px 0px 0px;
}

.formBackground {
    background-color: #ffffff;
}

.drawerCloseButton {
    padding: 0px 20px 20px 20px;
}

.questionSearch {
    padding-left: 16px;
}

.createFlowHeader {
    padding-top: 16px;
    padding-left: 16px;
    padding-bottom: 10px;
    font-size: 14pt;
    font-weight: bold;
    color: #271d7a;
}

.row {
    display: flex;
    flex-direction: row;
    padding: 5px 5px 16px;
    height: 80%;
}

.column {
    padding: 0px 6px 6px;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    width: 50%;
}

.label {
    font-size: 10pt;
    color: #271d7a;
    padding-left: 10px;
    font-weight: 500;
}

.submitButton {
    display: flex;
    justify-content: center;
    margin: auto;
}

:deep(.expandableCard .selected) {
    background-color: #eef3f8;
}

:deep(.expandableCard .open) {
    background-color: #eef3f8 !important;
}
</style>
