<template>
  <nav class="flow-nav"
    :style="{
      minHeight: `${minNavHeight}px`
    }"
  >
    <ul class="flow-nav-list">
      <li
        v-for="(item, index) in items"
        :key="index"
        class="flow-nav-item"
        :class="{
          'active':  index === localCurrentStep,
          'inactive': index > localCurrentIndex
        }"
        @click="handleClick(index, item)"
      >
        <!-- {{ item }} -->
        <span v-html="item.value"></span>
      </li>
    </ul>
  </nav>
</template>

<script>
const DEBOUNCE = 500;

export default {
  name: 'FlowNav',
  props: {
    items: {
      type: Array,
      default: () => []
    },
    currentIndex: {
      type: Number,
      default: 0
    },
    currentStep: {
      type: Number,
      default: 0
    },
    isDebounce: {
      type: Boolean,
      default: false
    },
    navHeight: {
      type: Number,
      default: 0
    }
  },
  computed: {
    localCurrentStep: {
      get () {
        return this.currentStep;
      },
      set (value) {
        this.$emit('update:currentStep', value);
      }
    },
    localIsDebounce: {
      get () {
        return this.isDebounce
      },
      set (value) {
        this.$emit('update:isDebounce', value);
      }
    },
    localCurrentIndex: {
      get () {
        return this.currentIndex;
      },
      set (value) {
        this.$emit('update:currentIndex', value)
      }
    },
    minNavHeight () {
      return this.navHeight 
    }
  },
  methods: {
    handleClick (index, item) {
      setTimeout(() => {
        this.localIsDebounce = false;
      }, DEBOUNCE);
      this.localIsDebounce = true;

      this.localCurrentStep = index;
      this.$emit('active', item);
      this.$emit('clicked', item)
    }
  }
}
</script>

<style scoped>
.flow-nav {
  position: relative;
  margin-left: 15px;
  font-size: 14px;
  max-width: 195px;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 5px;
    background-color: #e2f1f4;
  }
  .flow-nav-list {
    list-style: none;
  }
  .flow-nav-item {
    position: relative;
    padding: 5px 0 5px 15px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    cursor: pointer;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      width: 5px;
      background-color: #e2f1f4;
    }
    &.inactive {
      /* color: transparent; */
      pointer-events: none;
      user-select: none;
    }

    &.active {
      color: #3e87d3;
      font-weight: 700;
      &::before {
        background-color: #3e87d3;
      }
    }
  }
}
</style>