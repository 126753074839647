<template>
  <div class="listContainer">
    <div style="margin-bottom: 10px;">
      <span class="listName">
        {{ name }}   
      </span>
      <LightButton v-if="hasHeaderButton" :icon="headerButtonObject.icon" style="float: inline-end" :title="headerButtonObject.title" @button-click="handleButtonClick"/>
    </div>
    <div>
      <v-row no-gutters>
        <v-col
          v-if="isDeletable || checkbox"
          cols="1"
          width="50px"
          :class="{ removeColumn: isDeletable }"
        >
        </v-col>
        <v-col v-for="header in headers" :key="header" cols="3">
          <div
            class="listHeaders"
            :class="{ headerPadding: !isDeletable && !checkbox }"
          >
            {{ header }}
          </div>
        </v-col>

        <v-col>
          <div class="listHeaders" v-if="isEditable">Edit</div>
        </v-col>
      </v-row>
      <hr
        class="listDivider"
        :class="{ listDividerRecentTickets: !isDeletable }"
      />
    </div>
    <div class="listWrapper">
      <v-list class="list" v-if="items && items.length > 0">
        <v-row
          v-for="(item, index) in localItems"
          :key="index"
          class="listRow"
          :class="{
            isRemoved: item.isDisabled || item.isRemoved,
          }"
          no-gutters
          wrap
          align-content="center"
        >
          <v-col
            cols="1"
            v-if="isDeletable || checkbox"
            width="50px"
            class="listHeaders"
            style="display:flex;align-items:center;"
            :class="{ removeColumn: isDeletable }"
          >
            <RemoveButton
              v-if="isDeletable"
              @click.stop="removeFromList(item)"
            />
            <CheckBox
              v-if="checkbox"
              class="listCheckbox"
              :item="item"
              :checked="getChecked(item)"
              :multiSelect="false"
              :unSelectable="unSelectable"
              @toggle-selected="toggleSelected"
            />
          </v-col>
          <v-col
            v-for="header in headers"
            :key="header"
            class="listItems"
            @click="handleItemClick(item)"
            :class="{ clickable }"
            cols="3"
          >
            <input
              v-if="header === 'Password'"
              :value="item[header]"
              type="password"
              readonly="readonly"
            />

            <FillInBubble
              v-else-if="isEditable && item.isEditing"
              class="listItem-input"
              v-model="item.inputValue"
              :placeholder="item[header]"
            />
            <span v-else class="colText">
              {{ item[header] }}
            </span>
          </v-col>

          <v-col class="listItems" v-if="isEditable">
            <img
              :src="getImageSrc(item)"
              class="edit-icon"
              @click="editFromList(item)"
              :class="{ notClickable: item.isDisabled }"
            />
          </v-col>
        </v-row>
      </v-list>
      <span class="error-message" v-else>{{ errorMessage }}</span>
    </div>
  </div>
</template>

<script>
import RemoveButton from "./Buttons/RemoveButton.vue";
import remixiconUrl from "remixicon/fonts/remixicon.symbol.svg";
import FillInBubble from "./FormComponents/FillInBubble.vue";
import CheckBox from "./FormComponents/CheckBox.vue";
import LightButton from "@/components/base_components/Buttons/LightButton.vue";

export default {
  name: "ListComp",
  props: {
    items: Array,
    headers: Array,
    name: String,
    isEditable: Boolean,
    isDeletable: Boolean,
    unSelectable: { type: Boolean, default: false },
    errorMessage: {
      type: String,
      default: "Items are empty or null!",
    },
    clickable: Boolean,
    singleSelect: Boolean,
    uniqueKey: String,
    checkbox: Boolean,
    hasHeaderButton: {type: Boolean, default: false},
    headerButtonObject: {type: Object, default: () => {}}
  },
  data() {
    return {
      remixiconUrl,
      selectedItems: [],
      singleSelected: {},
    };
  },
  components: { RemoveButton, FillInBubble, CheckBox, LightButton },
  methods: {
    handleButtonClick(title) {
      console.log(title);
      this.$emit("button-click", title);
    },
    removeFromList(item) {
      if (this.isDeletable) {
        this.$emit("remove-item", item);
      } else {
        // TODO: do we really need this else? if so we probably want to replace this with a user alert of somesort. 
        console.log("error: isDeletable not passed in as prop");
      }
    },
    getChecked(item) {
      // If single select is desired behavior, you MUST pass in unique key to compare the object against!!!
      // the unique key is a unique column in the table (i.e in pricebooks table consisting of products, the unique column is productCode)
      if (this.singleSelect) {
        return this.singleSelected
          ? this.singleSelected[this.uniqueKey] === item[this.uniqueKey]
          : this.singleSelected === item;
      } else {
        return this.isSelected(item);
      }
    },
    editFromList(item) {
      if (this.isEditable) {
        if (!item.isEditing) {
          item.isEditing = true;
        } else {
          if (item.inputValue === "") {
            item.isEditing = false;
            item.inputValue = item.Name;
            return;
          } else {
            this.$emit("edit-item", item);
          }
        }
      } else {
        // TODO: do we really need this else? if so we probably want to replace this with a user alert of somesort. 
        console.log("error: isEditable not passed in as prop");
      }
    },
    getImageSrc(item) {
      if (item.isEditing) {
        return require("../../assets/icons/Save.png");
      } else {
        return require("../../assets/icons/Edit.png");
      }
    },
    handleItemClick(item) {
      if (this.clickable) {
        this.$emit("selected-item", item);
      }
    },
    toggleSelected(item) {
      if (this.singleSelect) {
        if (
          this.singleSelected &&
          this.singleSelected[this.uniqueKey] === item[this.uniqueKey]
        ) {
          this.singleSelected = null;
        } else {
          this.singleSelected = item;
        }

        this.$emit("selected-item", this.singleSelected);
      } else {
        const index = this.selectedItems.findIndex((selectedItem) => {
          return this.isEqual(selectedItem, item);
        });

        if (index !== -1) {
          this.selectedItems.splice(index, 1);
        } else {
          this.selectedItems.push(item);
        }
        this.$emit("selected-items", this.selectedItems);
      }
    },
  },
  emits: [
    "remove-item",
    "selected-item",
    "edit-item",
    "selected-items",
    "button-click"
  ],
  computed: {
    localItems() {
      return this.items;
    },
    isSelected() {
      return (item) => {
        return this.selectedItems.some((selItem) => {
          return selItem[this.uniqueKey] === item[this.uniqueKey];
        });
      };
    },
  },
};
</script>

<style scoped>
.list {
  background-color: #ffffff;
  padding: 0px;
}
.listWrapper {
  overflow-y: auto;
  flex: 1;
}
.listRow {
  padding-top: 11px;
  padding-bottom: 11px;
  padding-left: 5px;
}
.headerRow {
  padding-left: 5px;
}
.listRow.isRemoved {
  background-color: #DDE9F4;
}

.listRow.isRemoved:hover {
  background-color: #DDE9F4;
}

.listRow:hover {
  background-color: #f2f6fa;
}
.removeColumn {
  margin-left: 20px;
}
.listContainer {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: #ffffff;
  filter: drop-shadow(1px 1px 1px rgba(39, 29, 122, 0.2));
  padding: 10px;
  padding-left: 15px;
  padding-right: 15px;
}

.listName {
  font-weight: bold;
  font-size: 14pt;
  color: #271d7a;
  padding-bottom: 10px;
  padding-top: 10px;
  padding-left: 5px;
}

.error-message {
  font-size: 10pt;
  color: #271d7a;
  padding-bottom: 10px;
  padding-top: 10px;
  padding-left: 5px;
}
.listHeaders {
  font-weight: 600;
  font-size: 9pt;
  color: #271d7a;
  text-align: left;
  white-space: normal;
  word-wrap: break-word;
}
.headerPadding {
  padding-left: 5px;
}
.listItems,
.listItems input {
  font-weight: 600;
  font-size: 9pt;
  color: #271d7a;
  text-align: left;
  position: relative;
}

.listItem-input {
  height: fit-content;
  position: absolute;
  left: -10px;
}

.save-icon,
.edit-icon {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  cursor: pointer;
}

.edit-icon {
  height: 15px;
}

.edit-icon.notClickable {
  cursor: not-allowed;
  pointer-events: none;
}

.listItems input {
  margin-left: 10px;
}

.listItems.clickable {
  cursor: pointer;
}
.listDivider {
  border-top: 0.5px solid #dde9f4;
  margin-top: 6px;
  margin-bottom: 13px;
  margin-left: auto;
  margin-right: auto;
  width: 98%;
}
.listDividerRecentTickets {
  width: 100%;
}
.removeItemBtn {
  font-size: 10px;
}
.colText {
  overflow-wrap: break-word;
}
.listCheckbox {
  display: inline-block;
  text-align: center;
  width: 100%;
  padding-top: 2px;
}
.listHeaderPadding {
  padding-left: 10px;
}
</style>
