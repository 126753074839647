<template>
  <div class="flowPreviewContainer" :style="{ height: dynamicHeight + 'px' }">
    <div class="flowWizard">
      <FlowNav
        :navHeight="flattenedFlow.length"
        :items="selectedFilteredFlow"
        v-model:currentIndex="currentIndex"
        v-model:currentStep="currentStep"
        v-model:isDebounce="isItemClicked"
        @active="setSectionTitle"
      />
    </div>
    <div class="flowPreview">
      <h2 class="previewHeader" :style="{ paddingTop: '11px' }">Preview</h2>
      <div class="flowContainer">
        <div class="preview-queue-flow-buttons">
          <DropDownMulti
            :sort_options="false"
            :options="queueDropdownOptions"
            @selected-values="updateDropDownQueue($event)"
            placeholder="Select a queue"
            style="margin-right: 20px;"
            class="multi_dropdown"
          />
          <DropDown
            ref="flowRef"
            :options="flowDropdownOptions"
            @input="updateDropDownVal(question, $event)"
            default="Select a flow"
            style="margin-right: 20px;"
          />
          <DropDown
            :options="LESDropdownOptions"
            @input="updateLESDropDownVal($event)"
            :value="{ label: 'LES: No', value: 'false'}"
            ref="LESDropdown"
          />
        </div>
        <div style="padding-top: 20px; margin-bottom: 10px;">
          <h2 class="previewHeader">Flow Settings</h2>
          <div style="display: inline-block; padding-right: 15px;">
            <TextField label="Account Number" v-model="flow_settings_account_number"/>
          </div>
          <div style="display: inline-block;  padding-right: 15px;">
            <TextField label="Ticket Number" v-model="flow_settings_ticket_number"/>
          </div>
          <div style="display: inline-block;">
            <Select label="Queue" :options="queueDropdownOptions" v-model="flow_settings_queue"/>
          </div>
        </div>
        <div class="flowForm" :style="{ height: previewHeaderHeight + 'px' }">
          <FlowForm 
            @updateNav="updateNav"
            @currentData="handleProgressCurrentItem" 
            :sectionTitle="sectionTitle"
            @sectionLabel="setSectionLabelByID"
            :questions="selectedFlow" 
            :clearFlow="clearFlow" 
            @update-downloaded-flow-segment="updateDownloadedFlowSegment"
            v-model:isItemClicked="isItemClicked"
            :LESFlag="LESFlag"
            :account_number="flow_settings_account_number"
            :ticket_number="flow_settings_ticket_number"
            :queue="flow_settings_queue"
            :key="resetKey"
            :flowIndex="flowIndex"
            @updateFlowIndex="updateFlowIndex"
            @updatedSubmissionData="handleSubmissionDataUpdate"
            :bucket="bucket"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import FlowNav from '@/components/LEX/tickets/FlowNav.vue';

import FlowForm from "@/components/CcManagement/Flows/FlowForm.vue";
import DropDown from "@/components/base_components/FormComponents/DropDown.vue";
import DropDownMulti from "@/components/base_components/FormComponents/DropDownMulti.vue";
import TextField from "@/components/base_components/TextFieldComponent.vue";
import Select from "@/components/base_components/SelectComponent.vue";
import { useFlowManagerStore } from "@/stores/ContactCenter/Management/FlowManagerStore.js";
import { useAuthStore } from "@/stores/auth/authStore.js";
import { mapStores, mapActions } from 'pinia';
import { uniq } from '@/helpers/uniqUtils.js';
import { Storage } from "aws-amplify";

export default {
  name: "PreviewView",
  data() {
    return {
      previewHeaderHeight: '0px',
      resetKey: 0,
      flow_settings_account_number: null,
      flow_settings_ticket_number: null,
      flow_settings_queue: null,
      clearFlow: false,
      currentIndex: 0,
      currentQuestion: null,
      previousQuestions: [],
      response: "",
      questionIndex: 0,
      mutatedList: [],
      originalFlows: [],
      sectionTitle: "",
      isItemClicked: false,
      flowsList: [],
      headers: [
        { text: "", value: "checkbox", width: 40, fixed: true },
        { text: "Flow name", value: "flowName" },
        { text: "Number of Questions", value: "questions" },
        { text: "Partners", value: "partners" },
        { text: "", value: "edithover", width: 20 },
      ],
      data: [
        {
          header: true,
          flowName: "FF-170599001",
          questions: "139.60.150.242",
          partners: "FreedomFiberTAs",
        },
        {
          header: true,
          flowName: "FF-170599001",
          questions: "139.60.150.242",
          partners: "FreedomFiberTAs",
        },
      ],
      flowDropdownOptions: [],
      queueDropdownOptions: [],
      LESDropdownOptions: [
        { label: "LES: Yes", value: "true"},
        { label: "LES: No", value: "false"},
      ],
      selectedFlow: null,
      input: {
        name: "Question",
        type: "radio",
        required: false,
        direction: "column",
        outlined: false,
        label: "Select option",
        multiSelect: false,
        options: [
          { label: "Yes", value: "Yes" },
          { label: "No", value: "No" },
        ],
      },
      submissionData: {},
      selectedFilteredFlow: [],
      currentStep: this.currentIndex,
      LESFlag: false,
      flowIndex: 0,
      currentFlowFlattened: [],
      bucket: null

    };
  },
  props: { filterOptionsArray: Array, dynamicHeight: Number },
  computed: {
    ...mapStores(useFlowManagerStore, useAuthStore),
    flattenedFlow () {
      return this.flattenFlow();
    }
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    ...mapActions(useFlowManagerStore, ['loadData', 'getPartners']),
    handleSelectedItems(selectedItems) {
      this.$emit("selected-items", selectedItems);
    },
    handleResize() {
      var previewHeader = document.querySelector('.previewHeader');
      var flowSelector = document.querySelector('.preview-queue-flow-buttons');
      this.previewHeaderHeight = `calc(100% - ${parseInt(previewHeader.offsetHeight) + parseInt(flowSelector.offsetHeight)}px)`;
    },
    handleProgressCurrentItem() {
        // this.currentIndex += 1;
    },
    updateNav() {
      this.$nextTick(() => {

        var questions = document.getElementsByClassName('flowInputLabel');
        var question_labels = [];
        for (var question of questions) {
          if (question.innerText !== "" && !question.classList.value.includes('isLESFlagQuestion')) {
            var temp = {};
            temp.question_id = question.dataset.question_id;
            temp.value = question.innerText;
            question_labels.push(temp);
          }
        }
        this.selectedFilteredFlow = question_labels;
        this.currentIndex = this.selectedFilteredFlow.length - 1;
        this.currentStep = this.selectedFilteredFlow.length - 1;
        let index = this.selectedFilteredFlow.length - 1;
        this.setSectionLabelByID(this.selectedFilteredFlow[index])

      });
    },
    setSectionTitle (titleObj) {
      this.sectionTitle = titleObj.question_id;
    },
    navItemClicked(label) {
      var questions = document.getElementsByClassName('flowInputLabel');
      for (var question of questions) {
        if (question.innerText === label) {
          question.classList.add('activeQuestion');
        } else {
          question.classList.remove('activeQuestion');
        }
      }
    },
    setSectionLabelByID(value) {
      var checkedValue = "";
      if(typeof value == 'object' && Object.keys(value).length > 0) {
        checkedValue = value.question_id;
      } else {
        checkedValue = value;
      }

      var values = this.selectedFilteredFlow.map(obj => obj.question_id);
      const index = uniq(values).findIndex(item => item === checkedValue);

      if (index === -1) {
        return;
      }

      this.currentStep = index;
      // this.currentIndex = index;
    },
    handleCurrentIndex(index) {
      this.currentIndex = index;
    },
    handleSubmissionDataUpdate(data) {
      this.submissionData = data;
    },
    async reOrderFlowDependents(flow_questions) {
      for(var question of flow_questions) {
        if(question.dependents && question.dependents.length > 0 && question.options && question.options.length > 0) {
          var reorderedQuestions = [];
          for(var option of question.options) {
            var dependentQuestions = question.dependents.filter(x => x.dependentOn.toString() == option.value.toString());
            if(dependentQuestions && dependentQuestions.length > 0) {
              reorderedQuestions.push(...dependentQuestions);
            }
          }
          
          if(reorderedQuestions) {
            var adjusted_dependents = await this.reOrderFlowDependents(reorderedQuestions);
            question.dependents = adjusted_dependents;
          } else {
            adjusted_dependents = await this.reOrderFlowDependents(question.dependents);
            question.dependents = adjusted_dependents;
          }
        }
      }
      return flow_questions;
    },
    async updateFlowIndex() {
      var localFlattenedFlow = JSON.parse(JSON.stringify(this.currentFlowFlattened));
      // this.flowIndex = 0;  

      for(var item of localFlattenedFlow) {
        if(item.type == "Display Only" || item.type == "Display In Notes" || item.type == "Flow" || item.type == "Smart Link" || item.type == "Custom Display") {
          continue;
        } else if(item.type == 'LES Flag') {
          this.submissionData[item.id] = this.LESFlag;
          continue;
        }
        if(Object.keys(this.submissionData).includes(item.id)) {
          continue;
        } else {
          var parent_id = item.parent_id;
          var current_question_dependentOn = item.dependentOn;


          if(parent_id) {
            var validParentChain = await this.validateParentQuestions(parent_id, item.id);
            if(!validParentChain) {
              continue;
            }
          }
          
          if(parent_id && (current_question_dependentOn != "" && current_question_dependentOn != null && typeof current_question_dependentOn != "undefined")) {
            var parent_submission_data = this.submissionData[parent_id];
            if(Array.isArray(parent_submission_data) && parent_submission_data.length > 0) {
              var optionArray = parent_submission_data.map(x => x.value.toString());
              if(optionArray.includes(current_question_dependentOn.toString())) {
                question_flow_index = item.flowIndex;
                this.flowIndex = question_flow_index;
                return;
              } else {
                continue;
              }
            } else {
              if(parent_submission_data && current_question_dependentOn && parent_submission_data.toString() == current_question_dependentOn.toString()) {
                question_flow_index = item.flowIndex;
                this.flowIndex = question_flow_index;
                return;
              } else {
                continue;
              }
            }
          } else {
            var question_flow_index = item.flowIndex;
            this.flowIndex = question_flow_index;
            return;
          }
        }
      }

      //Made it through the entire flow, so everything should be shown
      this.flowIndex = localFlattenedFlow.length + 1;
    },
    async validateParentQuestions(parent_id, question_id) {
      var parent = this.currentFlowFlattened.find(x => x.id == parent_id);
      if(parent_id == '') {
        return true;
      }
      if(parent.type == 'Flow') {
        return await this.validateParentQuestions(parent.parent_id, parent.id);
      }
      var current_question = this.currentFlowFlattened.find(x => x.id == question_id);
      var parent_submission_data = this.submissionData[parent_id];
      var current_question_dependentOn = current_question.dependentOn;

      if(typeof parent_submission_data == 'undefined' || parent_submission_data == null) {
        return false;
      }
      if(Array.isArray(parent_submission_data) && parent_submission_data.length > 0) {
        var option_array = parent_submission_data.map(x => x.value.toString());
        if(option_array.includes(current_question_dependentOn.toString())) {
          return await this.validateParentQuestions(parent.parent_id, parent.id); 
        } else {
          return false;
        }
      } else {
        if(parent_submission_data.toString() == current_question_dependentOn.toString()) {
          return await this.validateParentQuestions(parent.parent_id, parent.id);
        } else {
          return false;
        }
      }
    },
    flattenFlow (withDisplays = false) {
      const flatArray = [];

      function recursiveFlatten(items) {
        if (!items) { return }
        for (const item of items) {
          if(withDisplays) {
            flatArray.push(item);
          } else {
            if (
              item.type !== "Display Only" &&
              item.type !== "Display In Notes" &&
              item.type !== "Smart Link" &&
              item.type !== "Custom Display"
            ) {
              flatArray.push(item.description);
            }
          }
          

          if (item.dependents) {
            recursiveFlatten(item.dependents);
          }
        }
      }

      recursiveFlatten(this.selectedFlow);
      return flatArray;
    },
    async reIDFlow(questions, parent_question_id = "") {
      var localCounter = 0;
      var id_prefix = parent_question_id ? `${parent_question_id}_` : "";
      
      for (var question of questions) {
        question.parent_id = parent_question_id ? parent_question_id : "";
        if(question.type == 'Flow') {
          if(parent_question_id) {
            question.id = `${id_prefix}${localCounter}`;
          } else {
            question.id = `Flow_unique_${localCounter}`;
          }
        } else {
          if (question?.id) {
            if(parent_question_id) {
              question.id = `${id_prefix}${localCounter}`;
            } else {
              question.id = `${question.id}_unique_${localCounter}`;
            }
          }
        }

        if(question.dependents && question.dependents.length > 0) {
          question.dependents = await this.reIDFlow(question.dependents, question.id);
        }
        localCounter++;
      }

      return questions;
    },
    async reIndexFlow(flow_questions, flowIndexCounter = 0) {
      var localFlowIndex = flowIndexCounter;
      var localFlowQuestions = JSON.parse(JSON.stringify(flow_questions));
      for(var question of localFlowQuestions) {
        question.flowIndex = localFlowIndex++;

        if(question.dependents && question.dependents.length > 0) {
          var adjusted_dependents = await this.reIndexFlow(question.dependents, localFlowIndex);
          if(adjusted_dependents.flowIndex) {
            localFlowIndex = adjusted_dependents.flowIndex;
          }
          question.dependents = adjusted_dependents.questions;
        }
      }
      return {questions: localFlowQuestions, flowIndex: localFlowIndex};
    },
    async findAndUpdateTopLevelFlowQuestions() {
      for(var question of this.selectedFlow) {
        if(question.type == 'Flow' && this.bucket) {
          const flow_file = await Storage.get(`Flows-${this.bucket}/${question.flow_file}`, { level: "public", download: true, cacheControl: "no-cache" });
          const flow_json = JSON.parse(await flow_file.Body.text());
          var downloaded_questions = flow_json;
          var question_to_find = question;
          downloaded_questions = await this.reOrderFlowDependents(downloaded_questions);
          downloaded_questions = await this.reIDFlow(downloaded_questions, question.id);
          await this.findNestedFlowSegment(this.selectedFlow, downloaded_questions, question_to_find);
        }
      }
    },
    async updateDownloadedFlowSegment(dependents, question) {
      
      var downloaded_questions = dependents;
      var question_to_find = question;
      downloaded_questions = await this.reOrderFlowDependents(downloaded_questions);
      downloaded_questions = await this.reIDFlow(downloaded_questions, question.id);
  
      await this.findNestedFlowSegment(this.selectedFlow, downloaded_questions, question_to_find);
      var results = await this.reIndexFlow(this.selectedFlow);
      this.selectedFlow = results.questions;
      this.currentFlowFlattened = await this.flattenFlow(true);
      this.$nextTick(async() => {
        await this.updateFlowIndex();
      });
    },
    findNestedFlowSegment(dependent_questions, downloaded_questions, question_to_find) {

      for (var question of dependent_questions) {
        if (question?.id == question_to_find?.id) {
          question.dependents = downloaded_questions;
          return;
        } 
        else {
          if (question.dependents) {
            this.findNestedFlowSegment(question.dependents, downloaded_questions, question_to_find);
          }
        }
      }
    },  
    handleFormSubmission(formData) {
      this.$emit("flow-data", formData);
    },
    handleQuestionIndex(questionIndex) {
      this.questionIndex = questionIndex;
    },
    updateDropDownQueue(event) {
      this.flowDropdownOptions = [];
      let selectedValues = event;
      let selected_array = [];

      for (const item of Object.values(selectedValues)) {
        selected_array.push(item.value);
      }

      for (let flow of this.flowsList) {
        if (selected_array.includes(flow.allowed_queues_staging)) {
          this.flowDropdownOptions.push({
            label: flow.flowName,
            value: flow.id
          });
        }
      }
    },
    async updateDropDownVal(q, flow) {
      let selectedFlow = null;
      this.selectedFilteredFlow = [];
      this.currentStep = 0;
      this.currentIndex = 0;
      this.flowIndex = 0;
      this.submissionData = {};
      this.bucket = null;
      this.clearFlow = true;


      for (let flow_item of this.flowsList) {
        if (flow.value == flow_item.id) {
          selectedFlow = flow_item;
        }
      }

      if (selectedFlow) {
        let result = await Storage.get(selectedFlow.s3_staging_bucket_key, { level: "public", download: true, cacheControl: "no-cache" });
        selectedFlow.staging_json = JSON.parse(await result.Body.text());

        this.bucket = selectedFlow.bucket;
        this.selectedFlow = await this.reOrderFlowDependents(selectedFlow.staging_json);
        this.selectedFlow = await this.reIDFlow(this.selectedFlow, "");
        await this.findAndUpdateTopLevelFlowQuestions();
        var results = await this.reIndexFlow(this.selectedFlow);
        this.selectedFlow = results.questions;
        this.currentFlowFlattened = this.flattenFlow(true);
        await this.updateFlowIndex();
        this.updateNav();
      }
    },
    async updateLESDropDownVal(val) {
      if(this.LESFlag.toString() !== val.value) {
        // var currentFlow = this.selectedFlow;
        this.LESFlag = (val.value === 'true');
        this.selectedFilteredFlow = [];
        this.currentStep = 0;
        this.currentIndex = 0;
        this.flowIndex = 0;
        this.submissionData = {};
        // this.bucket = null;
        this.clearFlow = true;
        // this.selectedFlow = currentFlow;
        this.resetKey++;
        await this.updateFlowIndex();
        this.updateNav();
      }
    },
    updateRadioValue(name, option) {
      if (Array.isArray(option)) {
        this.submissionData[name] = option;
      } else {
        this.submissionData[name] = option.value;
      }
    },
  },
  async mounted() {
    window.addEventListener("resize", this.handleResize);
    var previewHeader = document.querySelector('.previewHeader');
    var flowSelector = document.querySelector('.preview-queue-flow-buttons');
    this.previewHeaderHeight = `calc(100% - ${parseInt(previewHeader.offsetHeight) + parseInt(flowSelector.offsetHeight)}px)`;
    this.currentStep = this.currentIndex;
    this.current_user_partner = this.AuthStore.getCurrentDBUser.partner;
    
    let queues = await this.FlowManagerStore.getQueues(this.current_user_partner);

    this.queueDropdownOptions = queues;

    this.queueDropdownOptions = [...this.queueDropdownOptions].sort((a, b) => a.label.localeCompare(b.label));
    this.queueDropdownOptions.unshift(
    {
        label: "Work in Progress",
        value: "Work in Progress",
    },
    {
        label: "Supporting Flow",
        value: "Supporting Flow",
    },
    {
        label: "Overflow",
        value: "Overflow",
    })
    
    this.flowsList = await this.FlowManagerStore.getAllFlows(this.current_user_partner);

    if (this.current_user_partner != "LogicomUSA") {
      for (let flow of this.flowsList) {
        this.flowDropdownOptions.push({
            label: flow.flowName,
            value: flow.id
          });
      }
    }

  },
  watch: {
    clearFlow: {
      handler(newVal) {
        this.$nextTick(() => {
          if (newVal) {
            this.clearFlow = false;
          }
        });
      },
    },
    flowIndex() {
      this.updateNav();
    }
  },
  components: { FlowForm, DropDown, DropDownMulti, FlowNav, TextField, Select },
};
</script>
<style scoped>
.flowWizard {
  width: 40%;
  padding-top: 20px;
  height: 100%;
  background-color: #ffffff;
  overflow-y: auto;
  padding-bottom: 20px;
}
.flowPreviewContainer {
  display: flex;
  padding: 20px;
  width: 80%;
  background-color: #e2f1f4;
}


@media (min-width: 821px) {
  .flowPreviewContainer .preview-queue-flow-buttons {
    display: flex;
  }
}
@media (max-width: 820px) {
  .flowPreviewContainer {
    width: 100%;
  }
  .flowPreviewContainer .preview-queue-flow-buttons {
    display: flex;
    flex-direction: column;
  }
  .flowPreviewContainer .preview-queue-flow-buttons .dropdown.multi_dropdown {
    margin-bottom: 15px;
  }
}
.flowForm {
  background-color: #ffffff;
  position: relative;
  width: 100%;
  height: 99%;
  padding-bottom: 95px;
  box-sizing: border-box;
  padding-top: 15px;
}
.flowSelect {
  width: 50%;
  margin: 0 15px 15px;
}
.flowPreview {
  background-color: #ffffff;
  box-shadow: -5px 0 5px -5px rgba(39, 29, 122, 0.1);
  width: 100%;
  overflow-y: auto;
  height: 100%;
  padding: 5px 16px 20px 16px;
  display: flex;
  flex-direction: column;
}

.flowDropdown {
  width: 70%;
}

.previewHeader {
  padding-top: 5px;

  font-size: 14pt;
  font-weight: bold;
  color: #271d7a;
}

.selectedFlowContainer {
  flex-grow: 1;
  margin-top: 6px;
  display: flex;
  flex-direction: column;
  padding: 10px 20px 20px 20px;
  box-shadow: 0 0 4px rgba(39, 29, 122, 0.2);
}
.textArea {
  margin-top: 10px;
  width: 100%;
  flex-grow: 1;
  resize: none;
  padding: 0px 10px 0px 10px;
  font-size: 10pt;
  color: #271d7a;
  text-align: left;
  font-weight: normal;
  box-shadow: 0 0 4px rgba(39, 29, 122, 0.2);
  cursor: default;
}

.textArea:focus {
  outline: none;
}

:deep(.dropdown) {
  width: fit-content;
  min-width: 150px;
}

.multi_dropdown {
  max-width: 200px;
  margin-top: 0px;
}

.flowContainer {
  height: 100%;
}
</style>
