<template>
  <div
    class="sideDrawer formBackground"
    :class="{
      open: drawerOpen,
      top: topAligned,
    }"
    :style="{ width: dynamicWidth + 'px' }"
  >
    <button class="sideDrawerRemoveButton" @click="handleClose">
      <img
        class="sideDrawerRemoveButton"
        :src="require('@/assets/icons/Remove.png')"
      />
    </button>
    <div class="reviewFormContainer">
      <div class="reviewFormContent">
        <p class="reviewFormTitle">{{ drawerData[0]?.title }}</p>
        <v-form ref="form" @submit.prevent="onSubmit()">
          <div
            v-for="(section, sectionIndex) in drawerData"
            :key="sectionIndex"
          >
            <p
              class="sectionTitle"
              v-if="sectionIndex !== 0"
              :style="{ color: '#5fc0d3' }"
            >
              {{ section.title }}
            </p>
            <template
              v-for="(detail, detailIndex) in section.details"
              :key="detailIndex"
            >
              <v-row v-if="detail?.markedForRemoval !== 0">
                <v-col cols="12" class="sideDrawerInfoCol">
                  <div>
                    <p
                      class="drawerDetailTitle"
                      :style="{
                        color: detail.color,
                        fontWeight: detail.weight,
                      }"
                    >
                      {{ detail.name }}
                    </p>
                  </div>
                </v-col>
              </v-row>
            </template>
            <template> </template>
            <DividerComp
              v-if="sectionIndex != 0 && sectionIndex != drawerData?.length - 1"
              class="my-3"
            />
          </div>
          <v-row :style="{ marginTop: '0px !important' }">
            <v-col cols="12" class="sideDrawerInfoCol">
              <label class="drawerDetailTitle">
                {{ randomizedPhrase }}
              </label>
              <FillInBubble
                :placeholder="randomizedPhrase"
                @input="updateInputVal($event)"
                :isRequired="true"
              /> </v-col
          ></v-row>
          <div class="reviewButtonContainer">
            <DividerComp class="formButtonDivider" />
            <div class="cont">
              <DarkButton :title="drawerData[0]?.button" width="parent" />
            </div>
          </div>
        </v-form>
      </div>
    </div>
  </div>
  <AlertsGroup
    v-model:alerts="alerts"
  />
</template>

<script>
import DividerComp from "@/components/base_components/DividerComp.vue";
import DarkButton from "@/components/base_components/Buttons/DarkButton.vue";
import FillInBubble from "@/components/base_components/FormComponents/FillInBubble.vue";
import AlertsGroup from '@/components/base_components/AlertsGroup.vue';
import { addAlert } from '@/helpers/alertUtils';
import { ERROR } from "@/constants/statuses.constants.js";

export default {
  name: "FormSideDrawer",
  props: {
    drawerOpen: Boolean,
    drawerData: Array,
    width: Number,
    previousSubmissionData: Object,
    numCols: { type: Number, default: 6 },
    topAligned: { type: Boolean, default: true },
  },
  data() {
    return {
      submissionData: {},
      windowHeight: null,
      randomizedPhrase: "",
      confirmPhrase: "",
      alerts: [],
    };
  },
  mounted() {
    window.addEventListener("resize", this.handleResize);
    this.windowHeight = window.innerHeight;
  },

  beforeUnmount() {
    window.removeEventListener("resize", this.handleResize);
  },
  computed: {
    dynamicHeight() {
      return Number(this.windowHeight) - 39;
    },
    dynamicWidth() {
      return 350;
    },
  },
  watch: {
    drawerData() {
      this.submissionData = {};
    },
    windowHeight() {
      this.$nextTick(() => {
        this.dynamicHeight;
      });
    },
    drawerOpen(newValue) {
      if(newValue == true) {
        this.randomizeWord(this.drawerData[0]?.confirmPhrase);
      }
    },
  },
  methods: {
    randomizeWord(word) {
      if (word) {
        var randomizedWord = "";
        for (var i = 0; i < word.length; i++) {
          var randomCase = Math.random() < 0.5 ? "toUpperCase" : "toLowerCase";
          randomizedWord += word[i][randomCase]();
        }
        this.randomizedPhrase = randomizedWord;
        return randomizedWord;
      }
      return "";
    },
    handleClose() {
      this.$emit("close-click");
    },
    updateInputVal(event) {
      if (event.target.value) {
        this.confirmPhrase = event.target.value;
      } else {
        this.confirmPhrase = "";
      }
    },
    onSubmit() {
      if (this.confirmPhrase === this.randomizedPhrase) {
        this.$emit("confirm-action", true);
      } else {
        addAlert(ERROR, `Phrase doesn't match`, this.alerts);
      }
    }
  },
  components: {
    DividerComp,
    DarkButton,
    FillInBubble,
    AlertsGroup,
  },
  emits: ["close-click", "closeClick", "openDrawer"],
};
</script>

<style scoped>
.sideDrawer {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 6;
  bottom: 0;
  background-color: #f4fafa;
  overflow-x: auto;
  transition: transform 0.2s ease;
  transform: translateX(100%);
  display: flex;
  flex-direction: column;
}
.top {
  top: 93px;
}
.sideDrawer.open {
  filter: drop-shadow(5px 5px 5px rgb(39, 29, 122, 1));
  transform: translateX(0);
}
.drawerTitle {
  color: #271e7a;
  font-weight: bold;
  font-size: 14pt;
  padding-top: 5px;
}
.drawerCloseContainer {
  margin-top: auto;
}

.sideDrawerDivider {
  margin-bottom: 20px;
}
.sideDrawerRemoveButton {
  position: absolute;
  top: 0;
  right: 0;
  width: 31px;
  height: 35px;
  padding: 20px 16px 0px 0px;
}
.formBackground {
  background-color: #ffffff;
}
.drawerCloseButton {
  padding: 0px 20px 20px 20px;
}
.reviewFormContainer {
  height: 100%;
  background-color: #ffffff;
}
.reviewFormContent {
  padding: 11px 16px;
}
.reviewButtonContainer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  align-items: center;
  z-index: 5;
  padding-bottom: 16px;
}
.reviewButtonPositionBottom {
  position: absolute;
  padding-bottom: 20px;
}
.sideDrawerInfoCol {
  display: flex;
  flex-direction: column;
}
.drawerDetailTitle {
  font-size: 9pt;
  font-weight: 600;
  color: #271d7a;
  padding-bottom: 5px;
}
.sectionTitle {
  font-size: 12pt;
  font-weight: 600;
  padding-bottom: 5px;
}
.drawerDetailValue {
  font-size: 9pt;
  font-weight: normal;
  color: #271d7a;
}
.cont {
  display: flex;
  width: 50%;
  margin: auto;
}
.reviewFormTitle {
  color: #271e7a;
  font-weight: bold;
  font-size: 14pt;
  padding-top: 5px;
  padding-bottom: 10px;
}
:deep(.formButtonDivider) {
  margin-bottom: 16px;
}
</style>
