<template>
  <div class="quality-audit-container">
    <QualityAuditWizardComp
      class="quality-audit-left"
      :fillInInfoFormData="fillInInfoFormData"
      :fillInInfoValue="fillInInfoValue"
      :formData="formData"
      :auditNumCols="12"
      @dropdown-data="handleSelect"
      @toggle-checkbox="toggleCheckBox"
      :buttonList="buttonList"
      :isMenuClickable="true"
      :fillInRequiredToSave="true"
      @handle-zero-result="handleZeroScore"
      @wizard-data="handleWizardData"
      header="New Quality Audit"
    >
    </QualityAuditWizardComp>

    <QualityAuditTable
      class="quality-audit-right"
      :headers="qualityAuditHeaders"
      :dataList="mutatedDataList"
      :untouched="untouched"
      :fixedWidth="true"
      :failedCompliance="this.failedCompliance"
      :scoreTotal="scoreTotal"
    />
  </div>
  <AlertsGroup
    v-model:alerts="alerts"
  />
</template>

<script>
import QualityAuditWizardComp from "@/components/CcManagement/QualityAudit/QualityAuditWizardComp.vue";
// import MediumBlueButton from "@/components/base_components/Buttons/MediumBlueButton.vue";
import AlertsGroup from '@/components/base_components/AlertsGroup.vue';
import QualityAuditTable from '@/components/CcManagement/QualityAudit/table/QualityAuditTable.vue';
import { addAlert } from '@/helpers/alertUtils';
import { SUCCESS, ERROR, NOTIFICATION} from "@/constants/statuses.constants.js";
import { useQualityAuditStore } from "@/stores/ContactCenter/Management/QualityAuditStore.js";
import { useAuthStore } from "@/stores/auth/authStore.js";
import { mapStores } from 'pinia';

import { Storage } from 'aws-amplify';

export default {
  name: "QualityAudit",
  props: {
    submissionData: Array
  },
  components: {
    QualityAuditWizardComp,
    QualityAuditTable,
    AlertsGroup
    // MediumBlueButton
  },
  data() {
    return {
      scoreTotal: 0,
      alerts: [],
      componentLoaded: false,
      qualityAuditHeaders: [
        { text: "Quality Category", value: "category" },
        { text: "Scale", value: "scale" },
        { text: "Score", value: "score" },
        
      ],
      backendObject: {
        compliance: {
          initial: {
            field_name: "Call Type",
            type: "select",
            options: [
                {label: "Inbound", value: "inbound"},
                {label: "Outbound", value: "outbound"},
            ]
          },
          verification_level: {
            field_name: "Verification Level",
            type: "select",
            options: [
                {label: "2-step", value: "twoStep"},
                {label: "3-step", value: "threeStep"},
            ]
          },
          inbound: [
            {
              field_name: "Verified Full Account Holder's Name",
              value: true,
            },
            {
              field_name: "Verified Full Service Address",
              value: true,
            },
            {
              field_name: "Asked for Permission to Access Account",
              value: true,
            },
          ],
          outbound: [
            {
              field_name: "Statement of Recording",
              value: true,
            },
            {
              field_name: "Verified Full Account Holder's Name",
              value: true,
            },
            {
              field_name: "Verified Full Service Address",
              value: true,
            },
            {
              field_name: "Asked for Permission to Access Account",
              value: true,
            },
          ],
          three_step_inbound: [
            {
              field_name: "Verified Full Account Holder's Name",
              value: true,
            },
            {
              field_name: "Verified Full Service Address",
              value: true,
            },
            {
              field_name: "Verified Account Number or Last 4 Digits of Partner Provided RG or ONT MAC Address",
              value: true,
            },
            {
              field_name: "Asked for Permission to Access Account",
              value: true,
            },
          ],
          three_step_outbound: [
            {
              field_name: "Statement of Recording",
              value: true,
            },
            {
              field_name: "Verified Full Account Holder's Name",
              value: true,
            },
            {
              field_name: "Verified Full Service Address",
              value: true,
            },
            {
              field_name: "Verified Account Number or Last 4 Digits of Partner Provided RG or ONT MAC Address",
              value: true,
            },
            {
              field_name: "Asked for Permission to Access Account",
              value: true,
            },
          ],
          original_inbound: [
            {
              field_name: "Verified Full Account Holder's Name",
              value: true,
            },
            {
              field_name: "Verified Full Service Address",
              value: true,
            },
            {
              field_name: "Asked for Permission to Access Account",
              value: true,
            },
          ],
          original_outbound: [
            {
              field_name: "Statement of Recording",
              value: true,
            },
            {
              field_name: "Verified Full Account Holder's Name",
              value: true,
            },
            {
              field_name: "Verified Full Service Address",
              value: true,
            },
            {
              field_name: "Asked for Permission to Access Account",
              value: true,
            },
          ],
        },
        overall_tone: [
          {
            field_name: "Positive Tone",
            value: true,
            point_value: 4
          },
          {
            field_name: "Polite Through Call",
            value: true,
            point_value: 4
          },
          {
            field_name: "Asking for Member's Assistance instead of Ordering Member Around",
            value: true,
              point_value: 2
          },
          {
            field_name: "Please and Thank You Phrases",
            value: true,
            point_value: 4
          },
          {
            field_name: "Avoiding Dead Air",
            value: true,
            point_value: 4
          },
          {
            field_name: "Background Sound Quality",
            value: true,
            point_value: 3
          },
          {
            field_name: "Ownership Throughout Call",
            value: true,
            point_value: 2
          },
        ],
        strong_greeting_and_ending: [
          {
            field_name: "Greeting - Identify Name",
            value: true,
            point_value: 1
          },
          {
            field_name: "Greeting - Identify Correct Partner",
            value: true,
            point_value: 1
          },
          {
            field_name: "Greeting - Identify Issue/Offer Assistance",
            value: true,
            point_value: 1
          },
          {
            field_name: "Greeting - Empathy Statement",
            value: true,
            point_value: 2
          },
          {
            field_name: "Greeting - Offer Ticket Number",
            value: true,
            point_value: 1
          },
          {
            field_name: "Ending - Recap/Value Statement",
            value: true,
            point_value: 1
          },
          {
            field_name: "Ending - Confident Ending",
            value: true,
            point_value: 2
          },
          {
            field_name: "Ending - Setting Correct Expectations/Positioning",
            value: true,
            point_value: 2
          },
          {
            field_name: "Ending - Branding with Partner Name",
            value: true,
            point_value: 1
          },
        ],
        troubleshooting: [
          {
            field_name: "Member-level Description of Equipment - Limited Tech Jargon",
            value: true,
            point_value: 5
          },
          {
            field_name: "Followed Correct Technical Troubleshooting Process",
            value: true,
            point_value: 10
          },
          {
            field_name: "Rebooted ONT and RG with Member Permission",
            value: true,
            point_value: 5
          },
          {
            field_name: "Best Possible Resolution Reached",
            value: true,
            point_value: 10
          },
          {
            field_name: "Correct Use of Probing Questions",
            value: true,
            point_value: 5
          },
          {
            field_name: "Remained within Scope of Support",
            value: true,
            point_value: 5
          },
        ],
        documentation: [
          {
            field_name: "Used Advanced Support Flow",
            value: true,
            point_value: 5
          },
          {
            field_name: "Followed Flow Steps Correctly",
            value: true,
            point_value: 3
          },
          {
            field_name: "Filled Out Flow Properly and Truthfully",
            value: true,
            point_value: 3
          },
          {
            field_name: "Correct Issue Code",
            value: true,
            point_value: 1
          },
          {
            field_name: "Correct Resolution Code",
            value: true,
            point_value: 1
          },
          {
            field_name: "Correct Escalation Field",
            value: true,
            point_value: 1
          },
          {
            field_name: "Correct Verification Field",
            value: true,
            point_value: 3
          },
          {
            field_name: "Partner Specific Ticket Handling",
            value: true,
            point_value: 3
          },
          {
            field_name: "Professional and Detailed Notes",
            value: true,
            point_value: 5
          },
        ]
      },
      mutatedDataList: [
        {
          category: 'Overall Tone',
          scale: {
            UnSatisfactory: '0-19',
            Satisfactory: '20-23'
          },
          score: null,
          touched: false,
        },
        {
          category: 'Strong Greeting And Ending',
          scale: {
            UnSatisfactory: '0-9',
            Satisfactory: '10-12'
          },
          score: null,
          touched: false,
        },
        {
          category: 'Troubleshooting',
          scale: {
            UnSatisfactory: '0-35',
            Satisfactory: '36-40'
          },
          score: null,
          touched: false,
        },
        {
          category: 'Documentation',
          scale: {
            UnSatisfactory: '0-21',
            Satisfactory: '22-25'
          },
          score: null,
          touched: false,
        },
        {
          category: 'Compliance',
          scale: {
            UnSatisfactory: 'Fail',
            Satisfactory: 'Pass'
          },
          score: null,
          touched: false,
        }
      ],
      fillInInfoFormData: [
        { title: "", button: "" },
        {
          inputs: [
            
            {
              name: "Auditor Name",
              value: "auditor",
              type: "bubble",
              required: true,
              readonly: true,
            },
            {
              name: "Upload",
              value: "file",
              type: "filePicker",
              required: true,
            },
          ],
        },
      ],
      fillInInfoValue: {
        auditor: "",
        file: null,
      },
      buttonList: [
        {
          title: "Back",
          type: "lightButton",
          style: { width: "150px", marginRight: "5%" },
          appear: "last",
          function: "previous",
          autoSave: true,
        },
        {
          title: "Next",
          type: "darkButton",
          style: { width: "150px" },
          appear: "first",
          function: "next",
          autoSave: true,
        },
        {
          title: "Generate",
          type: "darkButton",
          style: { width: "150px" },
          appear: "last",
          position: "lastPage",
          function: "submit",
        },
      ],
      formData: [],
      failedCompliance: false,
      untouched: true,
      employeeOptions: [],
    }
  },

  async created() {

    let dbUser = this.AuthStore.getCurrentDBUser;
    if(Object.keys(dbUser).length > 0) {
      if(dbUser.first_name != null && dbUser.first_name != "" && dbUser.last_name != null && dbUser.last_name != "") {
        let firstName = dbUser.first_name;
        let lastName = dbUser.last_name;
        this.fillInInfoValue.auditor = `${firstName} ${lastName}`;
      } else {
        let currentUser = this.AuthStore.getCurrentUser;
        this.fillInInfoValue.auditor = currentUser.attributes.email;
      }
    } else {
      let currentUser = this.AuthStore.getCurrentUser;
      this.fillInInfoValue.auditor = currentUser.attributes.email;
    }

    let formData = this.processBackendObject(this.backendObject);
    if (formData) {
      formData.unshift({
        title: "Basic Information",
        add: false,
        sections: [
          {
            title: "Basic Information",
            add: false,
            inputs: [
              {
                name: "Name of Employee",
                value: "employee",
                type: "select",
                options: this.employeeOptions,
                required: true,
              },
              {
                name: "Ticket Number",
                value: "ticket_number",
                type: "bubble",
                required: true,
              },
              {
                name: "Date of Call",
                value: "callDate",
                type: "datePicker",
                required: true,
              },
              {
                name: "Date of Audit",
                value: "auditDate",
                type: "datePicker",
                required: true,
              },
            ],
          },
        ],
      },)
      this.formData = formData;
    }
  },

  async mounted() {
    let result = await this.QualityAuditStore.getEmployeeInformation()

    if (Array.isArray(result) && result.length > 0) {
      this.employeeOptions = result.map(employee => {
        return { label: employee.name, value: employee.name };
      });
    }
  },

  watch: {
    employeeOptions: {
      deep: true,
      immediate: true,
      handler(newValue) {
        if (this.formData && this.formData.length > 0) {
          const basicInfoSection = this.formData.find(section => section.title === "Basic Information");
          if (basicInfoSection) {
            const nameOfEmployeeInput = basicInfoSection.sections[0].inputs.find(input => input.name === "Name of Employee");
            if (nameOfEmployeeInput) {
              nameOfEmployeeInput.options = newValue;
            }
          }
        }
      },
    },
  },

  computed: {
    ...mapStores(useQualityAuditStore, useAuthStore),
  },

  methods: {
    toggleCheckBox(section, input, event) {
      let copy = this.mutatedDataList;
      const foundIndex = copy.findIndex(item => {
        if (item.category == section.title) {
          return item;
        }
      });
      let pointValue = this.findPointValueByFieldName(this.backendObject, section.title, input);
      if (foundIndex !== -1 && section.title !== 'Compliance') {
        if(event === true) {
          copy[foundIndex].score += pointValue;
          this.scoreTotal += pointValue;
        } else {
          copy[foundIndex].score -= pointValue;
          this.scoreTotal -= pointValue;
        }
        const scale = copy[foundIndex].scale;
        const unsatisfactoryRange = scale.UnSatisfactory.split('-')
        let range = parseInt(unsatisfactoryRange[1], 10);

        if (copy[foundIndex].score > range) {
          copy[foundIndex].touched = true;
          copy[foundIndex].satisfactory = true;
        } else {
          copy[foundIndex].touched = true;
          copy[foundIndex].satisfactory = false;
        }
        this.untouched = false;
      }
      this.mutatedDataList = copy;
    },
    handleZeroScore(section) {
      let copy = this.mutatedDataList;
      const foundIndex = copy.findIndex(item => item.category === section);
      if (!copy[foundIndex].score) {
        copy[foundIndex].score = 0;
      }
      if (!copy[foundIndex].satisfactory) {
        copy[foundIndex].satisfactory = false;
      }
      copy[foundIndex].touched = true;
      this.untouched = false;
      this.scoreTotal += 0;
      this.mutatedDataList = copy;
    },
    handleSelect(value) {
      if (value.value === 'twoStep') {
        this.backendObject.compliance.inbound = JSON.parse(JSON.stringify(this.backendObject.compliance.original_inbound));
        this.backendObject.compliance.outbound = JSON.parse(JSON.stringify(this.backendObject.compliance.original_outbound));
        if (this.formData) {
          let items = this.formData[1].sections[0].inputs;
          let newInputs = [];
          for (let i=0; i < items.length; i++) {
            if (items[i].value === 'callType' || items[i].value === 'verificationLevel') {
              newInputs.push(items[i])
            }
          }
          this.formData[1].sections[0].inputs = newInputs
        }
      }
      if (value.value === 'threeStep') {
        this.backendObject.compliance.inbound = JSON.parse(JSON.stringify(this.backendObject.compliance.three_step_inbound));
        this.backendObject.compliance.outbound = JSON.parse(JSON.stringify(this.backendObject.compliance.three_step_outbound));
        if (this.formData) {
          let items = this.formData[1].sections[0].inputs;
          let newInputs = [];
          for (let i=0; i < items.length; i++) {
            if (items[i].value === 'callType' || items[i].value === 'verificationLevel') {
              newInputs.push(items[i])
            }
          }
          this.formData[1].sections[0].inputs = newInputs
        }
      }

      if (value.value === 'inbound' || value.value === 'outbound') {
        const titleToFind = 'Compliance';
        const foundIndex = this.formData.findIndex(item => item.title === titleToFind);
        
        if (foundIndex !== -1) {
          const inputsArray = (value.value === 'inbound')
            ? this.backendObject.compliance.inbound
            : this.backendObject.compliance.outbound;
          
          const existingInputs = this.formData[foundIndex].sections[0].inputs;

          const newInputs = inputsArray.map(inputItem => {
            return {
              name: inputItem.field_name,
              value: this.convertToCamelCase(inputItem.field_name),
              type: 'checkbox',
              required: false,
            };
          });
          let newInput = {
            name: this.backendObject.compliance.initial.field_name,
            value: 'callType',
            type: "select",
            options: this.backendObject.compliance.initial.options,
            required: true,
          };

          this.formData[foundIndex].sections[0].inputs = [existingInputs[0], ...newInputs];
          this.formData[foundIndex].sections[0].inputs.splice(1, 0, newInput);
          let commentInput = {
            name: 'Comments',
            value: 'comments',
            type: "textarea",
            required: false,
          };
          this.formData[foundIndex].sections[0].inputs.push(commentInput);
        }
      }
    },
    convertToUnderscore(input) {
      return input
        .replace(/[^\w\s]/g, '') 
        .split(/\s+/)
        .map(word => word.toLowerCase())
        .join('_');
    },
    findPointValueByFieldName(backendObject, sectionName, fieldName) {

      let mutatedObject = backendObject;
      const camelCaseSectionName = this.convertToUnderscore(sectionName);
      let inputArray = mutatedObject[camelCaseSectionName];
      let newArray = []
      for (let i = 0; i < inputArray.length; i++) {
        const obj = inputArray[i];
        const camelCaseFieldName = this.convertToCamelCase(obj.field_name);
        const newObj = {
          ...obj,
          camelCase: camelCaseFieldName
        };
        newArray.push(newObj);
      }
      mutatedObject = newArray;
      if (mutatedObject) {
        const item = mutatedObject.find((item) => item.camelCase === fieldName);
        if (item) {
          return item.point_value;
        }
      return null;
      }
    },

    async handleWizardData(submissionData, subType) {
      if (submissionData.wizard['Compliance']) {
        this.updateComplianceScore(submissionData, 'Compliance', this.backendObject);
      }
      if (subType === "submit") {
        addAlert(NOTIFICATION, "Processing....", this.alerts);
        const audioFile = submissionData.fillInInfo.file;

        if (!audioFile.name.toLowerCase().endsWith('.mp3')) {
          addAlert(ERROR, "The file is not an MP3 file.", this.alerts);
          return;
        }

        let result = await this.QualityAuditStore.addQualityAudit(submissionData, this.mutatedDataList);

        if (result && !result.error) {
          try {
            const audioFile = submissionData.fillInInfo.file;
            const uploadResult = await Storage.put(`quality_audit/audio_files/${audioFile.name}`, audioFile, {
              level: 'public',
              contentType: audioFile.type
            }
            );
            console.log("File uploaded:", uploadResult);
          } catch (uploadError) {
            console.log("File upload failed:", uploadError);
            addAlert(ERROR, "File upload failed: ", this.alerts);
          }
          addAlert(SUCCESS, "Successfully Generated", this.alerts);
          if (result.auditID) {
            this.$router.push({ name: 'Contact Center Management Routed', params: { quality_audit_id: result.auditID } });
          }
          let complianceSection = this.formData.find(item => item.title === 'Compliance');

          let index = this.formData.findIndex(item => item.title === 'Compliance');
          let newInputs = complianceSection.sections[0].inputs.filter(item => item.value === 'callType' || item.value === 'verificationLevel')
          this.formData[index].sections[0].inputs = newInputs;

          let originalArray = this.mutatedDataList;
          const newArray = originalArray.map(item => ({ ...item, score: null, touched: false }));
          this.mutatedDataList = newArray;
          this.scoreTotal = 0;
          this.failedCompliance = false;
          this.untouched = true;
        } else {
          addAlert(ERROR, "Submission Failed: " + (result ? result.message : "Unknown error"), this.alerts);
        }
      }
    },
    
    validateCompliance(submissionData, backendObject) {
      const complianceData = JSON.parse(JSON.stringify(submissionData.wizard['Compliance']));
      const callType = complianceData['callType'];
      const isCallTypeInbound = callType === 'inbound';

      const backendComplianceArray = isCallTypeInbound
        ? backendObject.compliance.inbound
        : backendObject.compliance.outbound;

      const keysToRemove = ['callType', 'verificationLevel', 'comments'];
      for (const key of keysToRemove) {
        if (complianceData[key] !== undefined) {
          delete complianceData[key];
        }
      }
      const keysArray = Object.keys(complianceData);
      if (keysArray.length !== backendComplianceArray.length) {
        return false;
      }
      for (const key of keysArray) {
        if (!complianceData[key]) {
          return false;
        }
      }
      return true;
    },
    updateComplianceScore(submissionData, category, backendObject) {
      const isValidCompliance = this.validateCompliance(submissionData, backendObject);
      this.failedCompliance = !isValidCompliance;
      const copy = this.mutatedDataList;
      const foundIndex = copy.findIndex(item => item.category === category);
      copy[foundIndex].score = this.failedCompliance ? 'Fail' : 'Pass';
      copy[foundIndex].satisfactory = this.failedCompliance ? false : true;
      copy[foundIndex].touched = true;
    },
    convertToTitleCase(input) {
      return input
        .split('_')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
    },
    convertToCamelCase(input) {
      return input
        .replace(/[^a-zA-Z0-9]/g, ' ')
        .split(/\s+/)
        .map((word, index) => {
          if (index === 0) {
            return word.toLowerCase();
          }
          return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
        })
        .join('');
    },
    generateSectionObject(sectionName, items) {
      const section = {
        title: this.convertToTitleCase(sectionName),
        add: false,
        inputs: [],
      };

      for (const item of items) {
        const input = {
          name: item.field_name,
          value: this.convertToCamelCase(item.field_name),
          type: "checkbox",
          required: false,
        };
        section.inputs.push(input);
      }

      return section;
    }, 
    processBackendObject(backendObject) {
      let sections = [];
      let formData = []
      for (const sectionName in backendObject) {
        if (backendObject.hasOwnProperty.call(backendObject, sectionName)) {
          if (sectionName !== 'compliance') {
            const items = backendObject[sectionName];
            let section = this.generateSectionObject(sectionName, items);
            let newInput = {
              name: 'Comments',
              value: 'comments',
              type: "textarea",
              required: false,
            };
            section.inputs.push(newInput)
            sections.push(section);
          } else {
            const items = backendObject[sectionName];
            let section = {
              title: 'Compliance',
              add: false,
              inputs: [],
            };

            
            if (items.verification_level.field_name == "Verification Level") {
              let newInput = {
                name: items.verification_level.field_name,
                value: 'verificationLevel',
                type: "select",
                options: items.verification_level.options,
                required: true,
              };
              section.inputs.push(newInput);
            }
            if (items.initial.field_name == "Call Type") {
              let newInput = {
                name: items.initial.field_name,
                value: 'callType',
                type: "select",
                options: items.initial.options,
                required: true,
              };
              section.inputs.push(newInput);
            }
            sections.push(section)
          }
        }
      }
      for (let i=0; i < sections.length; i++) {
        let correctFormat = {
          title: sections[i].title,
          add: true,
          sections: new Array(sections[i])
        }
        formData.push(correctFormat)
      }
      return formData
    },
  }
}

</script>
<style scoped>
.quality-audit-container {
  height: 100%;
  display: flex;
  /* padding: 20px 0px 20px 20px; */
}


.quality-audit-left {
  flex: 1 60%;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  /* padding: 16px 0px 0px 10px; */
}

:deep(.addedList) {
  display: none !important;
}
:deep(.quality-audit-left .addedList) {
  display: none !important;
}

.quality-audit-right {
  flex: 1 40%;
  display: flex;
  flex-direction: column;
  padding-right: 20px;
  padding-left: 16px;
  margin-top: 20px;
}
.quality-audit-container .buttons button {
  min-height: 30px;
}

@media screen and (max-width: 1199px) {
  /* Add your CSS rules here */
  /* For example: */
  :deep(.container) {
    padding: 20px 0px 20px 0px !important;
  }
  .quality-audit-left {
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    padding: 16px 0px 0px 0px;
  }
  .quality-audit-right {
    width: 100%;
    margin-top: 0px;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    padding: 0px;
    overflow: auto;
  }
  .quality-audit-container {
    display: flex;
    padding: 20px;
    flex-direction: column-reverse;
  }
}
</style>

