<template>
  <div class="SchedulingEmployees">
    <ManagementSideDrawer
      :drawer-open="localDrawer"
      buttonType="Close"
      :numCols="12"
      @close-click="handleCloseClick"
      :drawer-data="getDrawerData"
      @form-submission="handleSubmit"
      :previousSubmissionData="preSubmissionData"
      :readonly="isLocalReadonly"
      :width="505"
    />
    <div class="tableContainer">
      <TableComp
        :headers="tableHeaders"
        :data-list="filteredData"
        background-color="#e2f1f4"
        :single-select="true"
        :select-all="false"
        uniqueKey="id"
        :left-nav-present="true"
        @selected-item="handleSelectedItem"
        @edit-row="handleEditRow"
        @click-row="handleEditRow"
      />
    </div>
    <AlertsGroup
      v-model:alerts="alerts"
    />
  </div>
</template>

<script>
import TableComp from "@/components/base_components/TableComp.vue";
import ManagementSideDrawer from "@/components/Voice/Management/SideDrawer/ManagementSideDrawer.vue";
import AlertsGroup from '@/components/base_components/AlertsGroup.vue';
import { SUCCESS, ERROR, CONFIRM_REMOVE } from "@/constants/statuses.constants.js";
import {
  MISSING_FIELDS
} from '@/constants/alerts.constants.js';
import {
  ADD_EMPLOYEE,
  EDIT_EMPLOYEE,
  REMOVE_EMPLOYEE,
  EMPLOYEE_CREATE_ALERT,
  EMPLOYEE_REMOVE_ALERT,
  EMPLOYEE_UPDATE_ALERT
} from '@/constants/pages/employee.constants.js';
import { addAlert } from '@/helpers/alertUtils';

import { useCCManagementSchedulingStore } from "@/stores/ContactCenter/Management/CCManagementSchedulingStore.js";
import { mapStores } from 'pinia';

export default {
  name: 'EmployeesView',
  components: {
    TableComp,
    ManagementSideDrawer,
    AlertsGroup
  },
  props: {
    drawer: {
      type: Boolean,
      default: false
    },
    create: {
      type: Boolean,
      default: false
    },
    update: {
      type: Boolean,
      default: false
    },
    isRemove: {
      type: Boolean,
      default: false
    },
    remove: {
      type: Boolean,
      default: false
    },
    search: {
      type: String,
      default: ''
    },
    isReadonly: {
      type: Boolean,
      default: false
    }
  },
  data() {
    const sideDrawerForm = [
      {
        name: "Name",
        type: "bubble",
        value: 'name',
        required: true,
      },
      {
        name: "Employee Ticket Number",
        type: "bubble",
        value: 'ticketNumber',
        required: true,
      },
      {
        name: "Email",
        type: 'bubble',
        value: "email",
        required: true,
      },
      {
        name: "Position",
        type: 'bubble',
        value: "position",
        required: true,
      },
      {
        name: "Supervisor",
        type: 'select',
        value: "supervisor",
        options: [
          // { label: "Supervisor 1", value: "supervisor 1" },
          // { label: "Supervisor 2", value: "supervisor 2" },
        ],
        required: true,
      },
      {
        name: "Start time",
        type: 'bubble',
        value: "start_time",
        required: true,
      },
      {
        name: "Terminated",
        type: 'select',
        value: "terminated",
        options: [
          { label: "Yes", value: "yes" },
          { label: "No", value: "no" },
        ],
        required: true,
      },
      {
        name: "Duration",
        type: 'bubble',
        value: "duration",
        default: "10",
        required: true,
      },
      {
        name: "Work Days",
        value: 'work_days',
        type: "multiSelect",
        options: [
          { label: "Sunday", value: "sunday" },
          { label: "Monday", value: "monday" },
          { label: "Tuesday", value: "tuesday" },
          { label: "Wednesday", value: "wednesday" },
          { label: "Thursday", value: "thursday" },
          { label: "Friday", value: "friday" },
          { label: "Saturday", value: "saturday" },
        ],
        required: true,
      },
    ];

    return {
      tableHeaders: [
        { text: "", value: "checkbox", width: 47.9, fixed: true },
        { text: "Name", value: "name" },
        { text: "Employee Ticket #", value: "ticketNumber" },
        { text: "Email", value: "email", width: 100 },
        { text: "Position", value: "position", width: 60  },
        { text: "Supervisor", value: "supervisor" },
        { text: "Start Time", value: 'start_time',  width: 60  },
        { text: "Terminated", value: 'terminated', width: 60 },
        { text: "Date Terminated", value: 'date_terminated', width: 60 },
        { text: "Duration", value: 'duration',  width: 60  },
        { text: "Work Days", value: 'work_days' },
        { text: "", value: "edithover", width: 38.3 }
      ],
      tableData:  [

      ],
      createFormData: [
        { title: ADD_EMPLOYEE, button: ADD_EMPLOYEE, type: "form" },
        {
          title: '',
          inputs: [
            {
              name: "Name",
              type: "bubble",
              value: 'name',
              required: true,
            },
            {
              name: "Employee Ticket Number",
              type: "bubble",
              value: 'ticketNumber',
              required: true,
            },
            { 
              name: "Email",
              type: 'bubble',
              value: "email",
              required: true,
            },
            { 
              name: "Position",
              type: 'bubble',
              value: "position",
              required: true,
            },
            { 
              name: "Supervisor",
              type: 'select',
              value: "supervisor",
              options: [
                // { label: "Supervisor 1", value: "supervisor 1" },
                // { label: "Supervisor 2", value: "supervisor 2" },
              ],
              required: true,
            },
            { 
              name: "Start time",
              type: 'bubble',
              value: "start_time",
              required: true,
            },
            { 
              name: "Duration",
              type: 'bubble',
              value: "duration",
              default: "10",
              required: true,
            },
            { 
              name: "Work Days",
              value: 'work_days',
              type: "multiSelect",
              options: [
                { label: "Sunday", value: "sunday" },
                { label: "Monday", value: "monday" },
                { label: "Tuesday", value: "tuesday" },
                { label: "Wednesday", value: "wednesday" },
                { label: "Thursday", value: "thursday" },
                { label: "Friday", value: "friday" },
                { label: "Saturday", value: "saturday" },
              ],
              required: true,
            },
          ]
        }
      ],
      updateFormData: [
        {
          title: EDIT_EMPLOYEE,
          button: EDIT_EMPLOYEE,
          type: "form" 
        },
        { 
          title: '',
          inputs: [
            {
              name: "Name",
              type: "bubble",
              value: 'name',
              required: true,
            },
            {
              name: "Employee Ticket Number",
              type: "bubble",
              value: 'ticketNumber',
              required: true,
            },
            { 
              name: "Email",
              type: 'bubble',
              value: "email",
              required: true,
              readonly: true
            },
            { 
              name: "Position",
              type: 'bubble',
              value: "position",
              required: true,
            },
            { 
              name: "Supervisor",
              type: 'select',
              value: "supervisor",
              options: [
                // { label: "Supervisor 1", value: "supervisor 1" },
                // { label: "Supervisor 2", value: "supervisor 2" },
              ],
              required: true,
            },
            { 
              name: "Start time",
              type: 'bubble',
              value: "start_time",
              required: true,
            },
            {
              name: "Terminated",
              type: 'select',
              value: "terminated",
              default: 'No',
              options: [
                { label: "Yes", value: "yes" },
                { label: "No", value: "no" },
              ],
              required: true,
            },
            {
              name: "Date Terminated",
              type: 'datePicker',
              value: 'date_terminated',
              required: false,
            },
            {
              name: "Duration",
              type: 'bubble',
              value: "duration",
              default: "10",
              required: true,
            },
            { 
              name: "Work Days",
              value: 'work_days',
              type: "multiSelect",
              options: [
                { label: "Sunday", value: "sunday" },
                { label: "Monday", value: "monday" },
                { label: "Tuesday", value: "tuesday" },
                { label: "Wednesday", value: "wednesday" },
                { label: "Thursday", value: "thursday" },
                { label: "Friday", value: "friday" },
                { label: "Saturday", value: "saturday" },
              ],
              required: true,
            },
          ]
        }
      ],
      removeFormData: [
        { title: REMOVE_EMPLOYEE, button: CONFIRM_REMOVE, type: "form" },
        { title: '', inputs: sideDrawerForm }
      ],
      alerts: [],
      submissionData: {},
      isSelectedRow: false,
    }
  },
  computed: {
    ...mapStores(useCCManagementSchedulingStore),
    isCreateForm: {
      get () {
        return this.create;
      },
      set (create) {
        this.$emit('update:create', create);
      }
    },
    isUpdateForm: {
      get () {
        return this.update;
      },
      set (update) {
        this.$emit('update:update', update);
      }
    },
    isRemoveForm: {
      get () {
        return this.remove;
      },
      set (remove) {
        this.$emit('update:remove', remove);
      }
    },
    localIsRemove: {
      get () {
        return this.isRemove;
      },
      set (isRemove) {
        this.$emit('update:isRemove', isRemove);
      }
    },
    localDrawer: {
      get () {
        return this.drawer;
      },
      set (drawer) {
        this.$emit('update:drawer', drawer)
      }
    },
    isLocalReadonly: {
      get () {
        return this.isReadonly
      },
      set (isReadonly) {
        this.$emit('update:isReadonly', isReadonly)
      }
    },
    filteredData () {
      if (this.searchResult !== '') {
        return this.tableData.filter(item => {
          return Object.values(item).some(fieldValue =>
            String(fieldValue).toLowerCase().includes(this.searchResult.toLowerCase())
          );
        });
      }
      
      return this.tableData
    },
     preSubmissionData () {
      if (this.isCreateForm) {
        return {
          duration: '10'
        }
      }

      return this.submissionData;
    },
    searchResult: {
      get () {
        return this.search;
      },
      set (search) {
        this.$emit('update:search', search);
      }
    },
    getDrawerData () {
      if (this.isCreateForm) {
        return this.createFormData;
      }
      
      if (this.isRemoveForm) {
        return this.removeFormData;
      }

      return this.updateFormData;
    }
  },
  methods: {
    handleSelectedItem(selectedItem) {
      //console.log("selectedItem", selectedItem);
      if (selectedItem !== null) {
        this.localIsRemove = true
        const { id, name, ticketNumber, email, position, supervisor, start_time, terminated, duration, work_days } = selectedItem;
        this.isRemoveForm = true;
        this.submissionData = {
          id,
          name,
          ticketNumber,
          email,
          position,
          supervisor,
          start_time,
          terminated,
          duration,
          work_days: this.formatMuliSelect(work_days)
        };
        this.isSelectedRow = true;
      } else {
        this.localIsRemove = false;
        this.isRemoveForm = false;
        this.isSelectedRow = false;
      }
    },
    handleEditRow(submissionData) {
      const {
        id,
        name,
        ticketNumber,
        email,
        position,
        supervisor,
        start_time,
        terminated,
        date_terminated,
        duration,
        work_days
      } = submissionData;

      this.localDrawer = true;
      this.isRemoveForm = false;
      this.isUpdateForm = true;

      let adjustedDate = null;
      if (date_terminated) {
        const parts = date_terminated.split("-");
        const utcDate = new Date(Date.UTC(parts[0], parts[1] - 1, parts[2]));
        adjustedDate = new Date(utcDate.getTime() + (24 * 60 * 60 * 1000)).toISOString().substr(0, 10);
      }

      this.submissionData = {
        id,
        name,
        ticketNumber,
        email,
        position,
        supervisor,
        start_time,
        terminated,
        date_terminated: adjustedDate,
        duration,
        work_days: this.formatMuliSelect(work_days)
      };
      console.log("editRow submissionData", submissionData);
    },
    handleCloseClick () {
      this.localDrawer = false;
      this.isCreateForm = false;
      this.isUpdateForm = false;
      this.isLocalReadonly = false;
    },
    async handleCreateForm(submissionData) {
      console.log("submissionData", submissionData);
      if (submissionData !== ERROR) {
        const {
          name,
          ticketNumber,
          email,
          position,
          supervisor,
          start_time,
          duration,
          work_days
        } = submissionData;

        let result = await this.CCManagementSchedulingStore.addEmployee(name, ticketNumber, email, position, supervisor, start_time, duration, work_days);
        if (result && !result.error) {
          addAlert(SUCCESS, EMPLOYEE_CREATE_ALERT, this.alerts);
          await this.CCManagementSchedulingStore.getEmployeeInformation();

          this.localDrawer = false;
          if (!this.isSelectedRow) {
            this.submissionData = {};
          }
          this.isCreateForm = false;
        } else {
          addAlert(ERROR, "Failed to add employee", this.alerts);
        }
      } else {
        addAlert(ERROR, MISSING_FIELDS, this.alerts);
      }
    },
    async handleRemoveForm(submissionData) {
      console.log("remove submissionData", submissionData);
      if (submissionData !== ERROR) {
        const { id } = submissionData;

        let result = await this.CCManagementSchedulingStore.removeEmployee(id);

        if (result.error) {
          addAlert(ERROR, result.message, this.alerts);
        } else {
          addAlert(SUCCESS, EMPLOYEE_REMOVE_ALERT, this.alerts);
          await this.CCManagementSchedulingStore.getEmployeeInformation();
          this.localDrawer = false; 
        }
      } else {
        addAlert(ERROR, MISSING_FIELDS, this.alerts);
      }
    },
    async handleUpdateForm(submissionData) {
      console.log("UPDATE SUBMISSIONDATA", submissionData);
      if (submissionData !== ERROR) {
        const {           
          id,
          name,
          ticketNumber,
          email,
          position,
          supervisor,
          start_time,
          terminated,
          duration,
          work_days,
          date_terminated 
        } = submissionData;

        if (terminated.label === 'Yes' && !date_terminated) {
          addAlert(ERROR, 'Please enter the date of termination', this.alerts);
          return;
        }

        let result = await this.CCManagementSchedulingStore.editEmployee(
          id,
          name,
          ticketNumber,
          email,
          position,
          supervisor,
          start_time,
          terminated,
          duration,
          work_days,
          date_terminated
        );

        if (result.error) {
          addAlert(ERROR, result.message, this.alerts);
        } else {
          addAlert(SUCCESS, EMPLOYEE_UPDATE_ALERT, this.alerts);
          await this.CCManagementSchedulingStore.getEmployeeInformation();
          this.localDrawer = false;
          this.isRemoveForm = false;
          this.isLocalReadonly = false;
        }
      } else {
        addAlert(ERROR, MISSING_FIELDS, this.alerts);
      }
    },
    handleSubmit(submissionData) {
      if (this.isCreateForm) {
        this.handleCreateForm(submissionData);
      } else if (this.isRemoveForm) {
        this.handleRemoveForm(submissionData);
      } else if (this.isUpdateForm) {
        this.handleUpdateForm(submissionData);
      }
    },
    formatMuliSelect (item) {
      let result;

      if (Array.isArray(item)) {
        result = this.arrayToString(item);
      } else {
        result = this.stringToArray(item);
      }

      return result;
    },

    formatSelect (item) {
      if (typeof item === 'string') {
        return item
      }

      return item?.label
    },
    arrayToString (arr) {
      return arr.map(item => {
        return item.label
      }).join(', ')
    },
    stringToArray (str) {
      return str.split(',').map(item => {
        return {
          label: item.trim(),
          value: item.toLowerCase().trim()
        }
      });
    }
  },
  async mounted() {
    let supervisorsResult = await this.CCManagementSchedulingStore.getSupervisors();

    let supervisorInputCreate = this.createFormData.find(form => form.inputs).inputs.find(input => input.name === "Supervisor");
    
    if (supervisorInputCreate) {
        supervisorInputCreate.options = supervisorsResult.map(supervisor => {
            return { label: supervisor, value: supervisor };
        });
    }

    let supervisorInputUpdate = this.updateFormData.find(form => form.inputs).inputs.find(input => input.name === "Supervisor");

    if (supervisorInputUpdate) {
        supervisorInputUpdate.options = supervisorsResult.map(supervisor => {
            return { label: supervisor, value: supervisor };
        });
    }

    let result = await this.CCManagementSchedulingStore.getEmployeeInformation();

    if (result) {
      this.tableData = this.CCManagementSchedulingStore.getEmployees.map(employee => {
        return {
          id: employee.id,
          name: employee.name,
          ticketNumber: employee.employee_ticket_number || "N/A",
          email: employee.email,
          position: employee.position,
          supervisor: employee.supervisor,
          start_time: employee.start_time,
          terminated: employee.terminated,
          date_terminated: employee.date_terminated,
          duration: employee.duration,
          work_days: employee.work_days,
        };
      });
      console.log("this.tableData", this.tableData)
    } else {
      console.error("Failed to fetch work partners: ", result);
    }

  },
  watch: {
    'CCManagementSchedulingStore.getEmployees': {
      deep: true,
      handler(newEmployees) {
        this.tableData = newEmployees.map(employee => {
          return {
            id: employee.id,
            name: employee.name,
            ticketNumber: employee.employee_ticket_number || "N/A",
            email: employee.email,
            position: employee.position,
            supervisor: employee.supervisor,
            start_time: employee.start_time,
            terminated: employee.terminated,
            date_terminated: employee.date_terminated,
            duration: employee.duration,
            work_days: employee.work_days,
          };
        });
      },
    },
  },
  unmounted() {
    this.isLocalReadonly = false;
    this.localDrawer = false;
    this.isCreateForm = false;
    this.isUpdateForm = false;
    this.localIsRemove = false;
  },
  beforeUpdate () {
    // TODO: what needs to be done here besides a console.log? 
    console.log(this.submissionData);
  },
  emits: [
    "update:create",
    "update:update",
    "update:remove",
    "update:isRemove",
    "update:drawer",
    "update:isReadonly",
    "update:search"
  ]
}
</script>

<style scoped>
.tableContainer {
  margin-left: 20px;
  margin-top: 20px;
  margin-right: 20px;
}
.SchedulingEmployees {
  flex-grow: 1;
  max-height: calc(100vh - 93px);
  overflow-y: auto;
}
.sideDrawer {
  z-index: 99;
}
:deep(.formContent) {
  min-height: calc(100vh - 164px);
}
.readonly :deep(.dropdownContainer),
.readonly :deep(.inputNormal) {
  user-select: none;
  pointer-events: none;
}
</style>