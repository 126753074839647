<template>
    <div class="alerts-group">
        <TransitionGroup name="list" tag="div" class="alerts-group-wrapper">
            <AlertComponent v-for="({ name, description }, index) in alerts" :name="name" :key="index"
                :description="description" @removed-alert="alertsStore.removeAlert(index)" />
        </TransitionGroup>
    </div>


</template>



<script setup>
import AlertComponent from "@/components/base_components/AlertComponent.vue";
import { computed } from "vue";
import { useAlertsStore } from "@/stores/alertsStore.js"


//Consts
const alertsStore = useAlertsStore();




//Computed Values
const alerts = computed(() => {
    return alertsStore.getAlerts;
});
</script>





<style scoped>
.alerts-group {
  position: fixed;
  top: 110px;
  right: 0;
  padding-bottom: 10px;
  padding-right: 20px;
  z-index: 150;
}
.alerts-group-wrapper {
  display: flex;
  flex-direction: column-reverse;
  gap: 5px;
}
.list-move,
.list-enter-active,
.list-leave-active {
  transition: all 0.3s ease;
}

.list-enter-from,
.list-leave-to {
  opacity: 0;
  transform: translateX(40px);
}
.list-leave-active {
  position: absolute;
}

</style>