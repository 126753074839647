<template>
  <div class="formContainer">
    <div class="formContent">
      <p class="formHeader">{{ currentSection.title }}</p>
      <img
        v-if="removeIcon"
        class="formRemoveIcon"
        :src="require('@/assets/icons/Remove.png')"
        @click="handleClose"
      />
      <div
        v-for="(section, sectionIndex) in currentSection.sections"
        :key="sectionIndex"
      >
        <p class="formSectionTitle">
          {{ section.title }}
        </p>
        <v-form ref="form">
          <div
            class="formInputs"
            :class="{
              formInputsWithScroll: hasScrollbar,
            }"
            ref="formInputs"
          >
            <v-row dense>
              <template v-for="input in section.inputs" :key="input">
                <v-col
                  :cols="input.fullWidth ? 12 : numCols"
                  class="formInputCol px-5"
                >
                  <label
                    class="formInputLabel"
                    v-if="
                      input.type !== 'checkbox' &&
                      input.type != 'dynamicOptions'
                    "
                    >{{ input.name }}
                    <span v-if="input.required" class="required-asterisk"
                      >*</span
                    ></label
                  >
                  <template v-if="input.type === 'select'">
                    <div class="dropdownContainer">
                      <DropDown
                        :name="input.name"
                        :options="input.options"
                        :default="!input.noDefault && getDefaultValue(input)"
                        @input="updateDropDownVal(input, $event, section.title)"
                        :value="
                          input?.options?.find(
                            (option) =>
                              option.value ===
                              submissionData?.[section.title]?.[input.value]
                          ) ||
                          submissionData[section.title]?.[input.value] ||
                          formData?.[1].inputs?.[1]?.options?.find(
                            (option) =>
                              option.value ===
                              submissionData[section.title]?.[input.value]
                          )
                        "
                      />
                    </div>
                  </template>
                  <template v-else-if="input.type === 'multiSelect'">
                    <DropDownMulti
                      :name="input.name"
                      :options="input.options"
                      placeholder="Select Fields"
                      :default="getDefaultValue(input)"
                      @input="
                        updateDropDownVal(input.name, $event, section.title)
                      "
                      :value="submissionData[section.title][input.name]"
                    />
                  </template>
                  <template v-else-if="input.name === 'Subject'">
                    <v-col class="formInputCol" :style="{ padding: '0px' }">
                      <FillInBubble
                        :placeholder="input.name"
                        :isRequired="input.required"
                        @input="updateInputVal(input, $event, section.title)"
                        :value="submissionData[input.name]"
                      />
                      <BulkUpdatePanel class="bulkUpdateText" />
                    </v-col>
                  </template>
                  <template v-else-if="input.type === 'expandableBubble'">
                    {{ submissionData?.[section] }}
                    <ExpandableFillInBubble
                      :placeholder="input.name"
                      :isRequired="input.required"
                      @input="updateInputVal(input, $event, section.title)"
                      :value="
                        submissionData?.[section.title]?.[input.value] ||
                        submissionData?.[section.title]?.[input.name]
                      "
                    />
                  </template>
                  <template v-else-if="input.type === 'radio'">
                    <RadioComp
                      :input="input"
                      :submissionData="submissionData"
                      :section="section.title"
                      @toggle-selected="
                        updateRadioValue(input.name, $event, section.title)
                      "
                      :multiSelect="input.multiSelect"
                    />
                  </template>
                  <template v-else-if="input.type === 'bubble'">
                    <FillInBubble
                      :placeholder="input.name"
                      :isRequired="input.required"
                      @change="updateInputVal(input, $event, section.title)"
                      :value="
                        submissionData?.[section.title]?.[input.value]
                          ? submissionData?.[section.title]?.[input.value]
                          : submissionData?.[section.title]?.[input.name]
                      "
                      :readonly="input.readonly"
                    />
                  </template>
                  <template v-else-if="input.type === 'checkbox'">
                    <div
                      class="formInputCheckbox"
                      :class="{
                        checkboxInRow: !(section.inputs.length % 2),
                      }"
                    >
                    <!-- :Checked comes from submissionData that's matching the input.value -->
                      <QualityAuditCheckBox
                        :multiSelect="false"
                        :unSelectable="false"
                        :checked="this.submissionData?.[section.title]?.[input.value]"
                        :item="input.value"
                        @toggle-selected="toggleCheckBox(section, input.value, $event)"
                      />
                      <label class="formInputLabel pl-2">{{
                        input.name
                      }}</label>
                    </div>
                  </template>
                  <template v-else-if="input.type === 'dateRange'">
                    <DatePicker
                      :allowDateRange="true"
                      :input="input"
                      @date-value="
                        (input, value) =>
                          handleDateChange(input, value, section)
                      "
                    />
                  </template>
                  <template v-else-if="input.type === 'datePicker'">
                    <DatePicker
                      :allowDateRange="false"
                      :input="input"
                      @date-value="
                        (input, value) =>
                          handleDateChange(input, value, section)
                      "
                      :value="submissionData?.[section.title]?.[input.value]"
                    /> </template
                  ><template v-else-if="input.type === 'timePicker'">
                    <TimePicker
                      :allowDateRange="false"
                      :input="input"
                      @date-value="
                        (input, value) =>
                          handleDateChange(input, value, section)
                      "
                      :value="submissionData?.[input.value]"
                      :existingDate="
                        submissionData['dateEST'] ||
                        previousFormValues?.['dateEST']
                      "
                    />
                  </template>
                  <template v-else-if="input.type === 'dynamicOptions'">
                    <DynamicOptions
                      @dOptionsInput="updateOptionsVal(input.type, $event)"
                      :initialOptions="
                        Object.keys(previousFormValues).length
                          ? [...previousFormValues?.['dynamicOptions']]
                          : submissionData?.['dynamicOptions']
                      "
                    />
                  </template>
                  <template v-else-if="input.type === 'button'">
                    <slot
                      name="button"
                      :sendSubmissionData="sendSubmissionData"
                    >
                      <DarkButton> {{ input.name }}</DarkButton>
                    </slot>
                  </template>
                  <template v-else-if="input.type === 'filePicker'">
                    <slot name="file-picker"></slot>
                  </template>
                  <template v-else-if="input.type === 'smartLink'">
                    <SmartLink />
                  </template>
                  <template v-else-if="input.type === 'textarea'">
                    <slot name="textarea">
                      <textarea
                        :readonly="
                          input.readonly !== undefined ? input.readonly : false
                        "
                        :value="
                          submissionData?.[section.title]?.[input.value] ||
                          submissionData?.[section.title]?.[input.name] ||
                          input.text
                        "
                        :placeholder="input.placeholder"
                        class="textareaNormal"
                        rows="7"
                        @change="updateInputVal(input, $event, section.title)"
                      ></textarea>
                    </slot>
                  </template>
                </v-col>
              </template>
              <slot> </slot>
            </v-row>
          </div>

          <MediumBlueButton
            title="Add"
            class="addButton"
            v-if="section.add"
            @click.prevent="
              handleAddItem(
                submissionData?.[section.title],
                section.title,
                section.inputs
              )
            "
          />
          <DividerComp
            class="sectionDivider"
            v-if="currentSection.sections.length - 1 !== sectionIndex"
          />

          <div
            class="formButtonContainer"
            :class="{
              formButtonPositionBottom: true,
              formButtonCentered: true,
            }"
          >
            <DividerComp class="formButtonDivider" />
            <div class="buttons">
              <template v-for="button in buttonList" :key="button.title">
                <LightButton
                  v-if="
                    (button.type === 'lightButton' &&
                      button.title !== 'Back') ||
                    (button.type === 'lightButton' &&
                      button.title === 'Back' &&
                      currentIndex !== 0)
                  "
                  :title="button.title"
                  :style="button.style"
                  @click.prevent="handleButtonClick(button.function)"
                />
                <DarkButton
                  v-if="
                    (button.type === 'darkButton' &&
                      button.appear == 'last' &&
                      button.position === 'lastPage' &&
                      currentIndex === formData.length - 1) ||
                    (button.type === 'darkButton' &&
                      button.appear == 'first' &&
                      currentIndex < formData.length - 1)
                  "
                  :style="button.style"
                  :title="button.title"
                  @click.prevent="
                    handleButtonClick(button.function, button.autoSave)
                  "
                />

                <MediumBlueButton
                  v-if="button.type === 'mediumButton'"
                  :style="button.style"
                  :title="button.title"
                  @click="handleButtonClick(button.function)"
                />
              </template>
            </div>
          </div>
        </v-form>
      </div>
    </div>
  </div>
</template>

<script>
import DividerComp from "./../../base_components/DividerComp.vue";
import DropDown from "./../../base_components/FormComponents/DropDown.vue";
import FillInBubble from "./../../base_components/FormComponents/FillInBubble.vue";
import DarkButton from "./../../base_components/Buttons/DarkButton.vue";
import QualityAuditCheckBox from "./QualityAuditCheckbox.vue";
import DropDownMulti from "./../../base_components/FormComponents/DropDownMulti.vue";
import BulkUpdatePanel from "./../../base_components/Textbox/BulkUpdatePanel.vue";
import DatePicker from "./../../base_components/FormComponents/DatePicker.vue";

// import BillingDatePicker from "@/views/Billing/CustomerManagement/BillingDatePicker.vue";
import ExpandableFillInBubble from "@/components/base_components/ExpandableFillInBubble.vue";
import RadioComp from "./../../base_components/FormComponents/RadioComp.vue";
import DynamicOptions from "@/components/base_components/DynamicOptions.vue";
import TimePicker from "./../../base_components/FormComponents/TimePicker.vue";
import SmartLink from "./../../base_components/FormComponents/SmartLink.vue";
import MediumBlueButton from "./../../base_components/Buttons/MediumBlueButton.vue";
import LightButton from "./../../base_components/Buttons/LightButton.vue";
export default {
  name: "QualityAuditFormCompWizard",
  props: {
    formData: Array,
    title: String,
    numCols: Number,
    removeIcon: Boolean,
    previousFormValues: Object,
    currentIndex: Number,
    buttonList: Array,
    clearWizardForm: Boolean,
  },
  watch: {
    windowHeight() {
      this.$nextTick(() => {
        this.dynamicHeight;
      });
    },

    previousFormValues: {
      deep: true,
      handler(newValues) {
        this.previousFormValuesTriggered = true;
        this.submissionData = { ...newValues };
      },
    },
    clearWizardForm: {
      deep: true,
      handler(newValue) {
        if (newValue) {
          this.submissionData = {};
        }
      },
    },
  },
  computed: {
    currentSection() {
      return this.formData.filter(
        (section, sectionIndex) => sectionIndex == this.currentIndex
      )[0];
    },

    dynamicHeight() {
      return Number(this.windowHeight) - 93;
    },
    dropdownName(input) {
      return `Select a ${input.name}`;
    },
    isFormValid() {
      return this.formData?.every((section) => {
        return section?.sections?.every((section) => {
          return section?.inputs?.every((input) => {
            let value =
              this.submissionData[section.title]?.[input.name] ||
              this.submissionData[section.title]?.[input.value];

            if (input.required) {
              if (!value) {
                this.errorMessage = `${input.name} is missing`;
                return false;
              }
              // let validator =
              //   this.customFormValidators[input.value] ||
              //   this.standardFormValidators[input.validator];

              // if (validator && !validator(value)) {
              //   this.errorMessage = `${input.name} is invalid`;
              //   return false;
              // }
              return true;
            } else {
              return true;
            }
          });
        });
      });
    },
    isSectionValid() {
      return this.currentSection?.sections?.every((section) => {
        return section?.inputs?.every((input) => {
          let value =
            this.submissionData[section.title]?.[input.name] ||
            this.submissionData[section.title]?.[input.value];
          if (input.required) {
            if (!value) {
              this.errorMessage = `${input.name} is missing`;
              return false;
            }

            // let validator =
            //   this.customFormValidators?.[input.value] ||
            //   this.standardFormValidators?.[input.validator];

            // if (validator && !validator(value)) {
            //   this.errorMessage = `${input.name} is invalid`;
            //   return false;
            // }

            return true;
          } else {
            return true;
          }
        });
      });
    },
  },
  data() {
    return {
      submissionData: {},
      emptyArray: [],
      hasScrollbar: false,
      windowHeight: null,
      showDatePicker: false,
      selectedStartDate: "",
      selectedEndDate: "",
      previousFormValuesTriggered: false,
      errorMessage: "",
    };
  },
  methods: {
    handleDateChange(input, value, section) {
      if (!value) {
        return
      } else {
        if (!this.submissionData[section.title]) {
          this.submissionData[section.title] = {}; // Create the section.title object if it doesn't exist
        }
        if (input.value) {
          this.submissionData[section.title][input.value] = value;
        } else {
          this.submissionData[section.title][input.name] = value;
        }
        this.$emit('date-update', section.title, input, value)
      }
     
    },
    toggleCheckBox(section, input, event) {
  
      if (!this.submissionData[section.title]) {
        this.submissionData[section.title] = {};
        this.submissionData[section.title][input] = event
        this.$emit('toggle-checkbox', section, input, event)
      } else {
        this.submissionData[section.title][input] = event
        this.$emit('toggle-checkbox', section, input, event)
      }   
    },
    toggleDatePicker() {
      this.showDatePicker = !this.showDatePicker;
    },
    updateSelectedDate(date) {
      this.selectedStartDate = date;
    },
    handleAddItem(item, section, inputs) {
      let isAddValid = inputs?.every((input) => {
        if (
          !this.submissionData?.[section]?.[input.name] &&
          !this.submissionData?.[section]?.[input.value]
        ) {
          return false;
        }
        return true;
      });
      if (isAddValid) {
        delete this.submissionData[section];
        this.$emit("addListItem", item);
      } else {
        this.$emit("addListItem", "Error");
      }
    },
    handleButtonClick(clickFunction, autoSave) {
      if (clickFunction === "next") {
        if (autoSave) {
          if (this.isSectionValid) {
            this.$emit(
              "submission-data",
              this.submissionData,
              "wizard",
              "autoSave"
            );
          } else {
            this.$emit(
              "submission-data",
              "Error",
              "wizard",
              "save",
              this.errorMessage
            );
          }
        } else {
          this.$emit("nextSection");
        }
      } else if (clickFunction === "previous") {
        this.$emit("previousSection");
      } else if (clickFunction === "save") {
        this.onSave();
      } else if (clickFunction === "submit") {
        this.onSubmit();
      }
    },
    onSubmit() {
      if (this.isFormValid) {
        this.$emit("submission-data", this.submissionData, "wizard", "submit");
      } else {
        this.$emit("submission-data", "Error", "wizard", "submit");
      }
    },
    onSave() {
      this.$emit(
        "submission-data",
        this.submissionData,
        "wizard",
        "save",
        this.errorMessage
      );
    },
    getDefaultValue(input) {
      const vowels = ["a", "e", "i", "o", "u"];

      const inputName = input.name.toLowerCase();

      return vowels.includes(inputName.charAt(0))
        ? `Select an ${inputName}`
        : `Select a ${inputName}`;
    },
    updateDropDownVal(input, event, section) {

      if (!this.submissionData[section]) {
        this.submissionData[section] = {}; // Create the section object if it doesn't exist
      }
      if (section === 'Compliance') {
        for (const input in this.submissionData['Compliance']) {
          if (input !== 'callType' && input !== 'verificationLevel') {
            delete this.submissionData['Compliance'][input]
          }
        }
        if (input.value === 'verificationLevel') {
          delete this.submissionData['Compliance'].callType;
        }
      }
      if (input.value) {
        this.submissionData[section][input.value] = event.value;
        let name = input.value;
        this.$emit("dropdown-data", { name, value: event.value });
      }
      
      // check if this input control's any other dropdown data
      // const currentInputObject = this.formData[1].inputs.find(
      //   (input) => input.name === name
      // );

      // if (currentInputObject.controls) {
      //   const dependent = currentInputObject.controls;
      //   this.submissionData[dependent] = null;
      //   this.$emit("dependent-dropdown-data");
      // }
    },
    updateInputVal(input, event, section) {
      if (!this.submissionData[section]) {
        this.submissionData[section] = {}; // Create the section object if it doesn't exist
      }
      if (event.target.value) {
        if (input.value) {
          this.submissionData[section][input.value] = event.target.value;
        } else {
          this.submissionData[section][input.name] = event.target.value;
        }
        this.$emit("input-data", event.target.value);
      } else {
        if (event.target.innerText) {
          if (input.value) {
            this.submissionData[section][input.value] = event.target.innerText;
          } else {
            this.submissionData[section][input.name] = event.target.innerText;
          }
        } else {
          delete this.submissionData[section][input.name];
          delete this.submissionData[section][input.value];
        }
      }
    },
    handleClose() {
      this.$emit("close-click");
    },
    updateRadioValue(name, option, section) {
      if (!this.submissionData[section]) {
        this.submissionData[section] = {}; // Create the section object if it doesn't exist
      }
      if (Array.isArray(option) && name) {
        this.submissionData[section][name] = option;
      } else {
        if (name) {
          this.submissionData[section][name] = option.value;
        }
      }
    },
    updateOptionsVal(type, value) {
      this.submissionData[type] = value;
    },
    clearForm() {
      this.submissionData = {};
    },
    checkScrollbar() {
      const element = this.$refs.formInputs;
      if (this.windowHeight < element[0].scrollHeight + 200) {
        this.hasScrollbar = true;
      } else {
        this.hasScrollbar = false;
      }
    },
    handleResize() {
      this.windowHeight = window.innerHeight;
      this.checkScrollbar();
    },
  },
  mounted() {
    this.submissionData = { ...this.previousFormValues };
    window.addEventListener("resize", this.handleResize);
    this.windowHeight = window.innerHeight;
    this.checkScrollbar();
  },

  beforeUnmount() {
    window.removeEventListener("resize", this.handleResize);
  },

  updated() {
    this.checkScrollbar();
  },
  components: {
    FillInBubble,
    DividerComp,
    DropDown,
    DarkButton,
    QualityAuditCheckBox,
    DropDownMulti,
    BulkUpdatePanel,
    // BillingDatePicker,
    DatePicker,
    ExpandableFillInBubble,
    RadioComp,
    DynamicOptions,
    TimePicker,
    SmartLink,
    MediumBlueButton,
    LightButton,
  },
  emits: [
    "submission-data",
    "dropdown-data",
    "input-data",
    "toggle-checkbox",
    "date-update",
    "dependent-dropdown-data",
    "submissionDataUpdated",
    "nextSection",
    "previousSection",
    "addListItem",
    "close-click"
  ],
};
</script>

<style scoped>
.sectionDivider {
  margin: 0px 0px 20px 0px;
  width: 95%;
  margin-left: auto;
  margin-right: auto;
}
.checkboxWrapper {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  padding-bottom: 10px;
  padding-left: 16px;
}
.formInputCheckbox {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.checkboxInRow {
  padding-top: 0px;
  padding-left: 0px;
}
.formButtonContainer {
  width: 100%;
  bottom: 0;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  z-index: 5;
}

.formButtonPositionBottom {
  position: absolute;
  padding-bottom: 16px;
}
.formButtonCentered {
  align-items: center;
  text-align: center;
}
.formDarkButton {
  padding: 0px 15px 0px 15px;
}
.formInputCol {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}
.formContainer {
  background-color: #ffffff;
  overflow: hidden;
  height: 100%;
  display: flex;
}
.formContent {
  padding: 11px 0px 11px 0px;
  overflow-y: auto;
  height: 100%;
  flex: 1 70%;
}
.formInputs {
  padding: 11px 5px 11px 5px;
}
.formInputsWithScroll {
  height: calc(100% - 70px);
  padding-bottom: 70px;
}
.formSectionTitle {
  padding-left: 16px;
  font-size: 11pt;
  font-weight: bold;
  color: #3e87d3;
}
.formSectionDivider {
  margin: 11px 11px 25px 11px;
}
.formInputLabel {
  font-size: 9pt;
  font-weight: 600;
  color: #271d7a;
  padding-bottom: 5px;
}
.formHeader {
  padding-top: 5px;
  padding-left: 16px;
  padding-bottom: 10px;
  font-size: 14pt;
  font-weight: bold;
  color: #271d7a;
}
.formRemoveIcon {
  position: absolute;
  cursor: pointer;
  right: 0;
  top: 0;
  height: 35px;
  padding-top: 20px;
  padding-right: 20px;
}

.textareaNormal {
  outline: #2e3e91 solid 0.25pt;
  border-radius: 2px;
  padding-left: 8px;
  padding-top: 2px;
  padding-bottom: 2px;
  font-size: 9pt;
  color: #271d7a;
  background-color: #ffffff;
  height: 150px;
}

.bulkUpdateText {
  margin-top: 30px;
}
.inLineWithInputs {
  padding-left: 35px;
}
.saveButton {
  position: absolute;
  right: 20px;
}
.addButton {
  margin: 0px 20px 20px 35px;
  width: 150px;
}
.buttons {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  padding: 0px 20px 0px 20px;
}
.formButtonDivider {
  width: 100%;
  margin-bottom: 16px;
}
.addedList {
  flex: 1 20%;
  display: flex;
}
</style>
