<template>
  <div
    class="sideDrawer"
    :class="{ open: drawerOpen, formBackground: drawerData[0].type === 'form' }"
    :style="{ width: dynamicWidth + 'px' }"
  >
    <button
      class="sideDrawerRemoveButton"
      @click="handleClose"
      v-if="drawerData[0].title !== 'Filters'"
    >
      <img
        class="sideDrawerRemoveButton"
        :src="require('../../assets/icons/Remove.png')"
      />
    </button>
    <div v-if="drawerData[0].type === 'form'">
      <FormComp
        :formData="drawerData"
        @close-click="handleClose"
        :numCols="6"
        @submission-data="handleFormSubmission"
      />
    </div>
    <div v-else-if="drawerData[0].type === 'bulkUpdate'" class="drawerWrapper">
      <p class="drawerTitle">{{ drawerData[0].title }}</p>
      <div v-for="(section, sectionIndex) in drawerData" :key="sectionIndex">
        <template
          v-for="(input, inputIndex) in section.inputs"
          :key="inputIndex"
        >
          <v-row>
            <v-col v-if="input.show">
              <label class="multiSelectLabel"
                >{{ input.name }}
                <span v-if="input.required" class="required-asterisk"
                  >*</span
                ></label
              >
              <DropDownMulti
                :name="input.name"
                :options="input.options"
                :placeholder="`Select ${input.name.toLowerCase()}`"
                :default="`Select ${input.name.toLowerCase()}`"
                @selected-values="updateDropDownVal(input.name, $event)"
              />
            </v-col>
          </v-row>
        </template>
      </div>
    </div>
    <div v-else-if="drawerData[0].type === 'textToSpeech'" class="textToSpeech">
      <p class="drawerTitle">{{ drawerData[0].title }}</p>
          <v-row>
            <v-col v-if="drawerData[0].audioSource">
              <audio controls>
                  <source :src="drawerData[0].audioSource">
              </audio>
            </v-col>
          </v-row>
    </div>
    <div v-else class="drawerWrapper">
      <p v-if="drawerData[0].title === 'Filters'" class="drawerFilterTitle">
        {{ drawerData[0].title }}
      </p>
      <p v-else class="drawerTitle">{{ drawerData[0].title }}</p>
      <button @click="handleClose" v-if="drawerData[0].title !== 'Filters'">
        <img
          :src="require('../../assets/icons/Remove.png')"
          class="sideDrawerRemoveButton"
        />
      </button>
      <div v-for="(section, sectionIndex) in drawerData" :key="sectionIndex">
        <template v-if="sectionIndex !== 0">
          <template v-if="section.title">
            <p class="drawerSectionTitle">
              {{ section.title }}
            </p>
          </template>
          <v-row dense>
            <template
              v-for="(detail, detailIndex) in section.details"
              :key="detailIndex"
            >
              <template v-if="detail.type === 'info'">
                <v-col cols="6" md="6" class="sideDrawerInfoCol">
                  <div>
                    <p class="drawerDetailTitle">{{ detail.name }}</p>
                    <p class="drawerDetailValue">{{ detail.value }}</p>
                  </div>
                </v-col>
              </template>
              <template v-else-if="detail.type === 'input'">
                <v-col cols="12" md="12" class="sideDrawerInputCol">
                  <label class="sideDrawerInputLabel">
                    {{ detail.name }}
                  </label>
                  <FillInBubble
                    :name="detail.name"
                    :placeholder="detail.placeholder"
                    @input="updateInputVal(detail.value, $event)"
                  />
                </v-col>
              </template>
              <template v-else-if="detail.type === 'select'">
                <v-col cols="12" md="12" class="sideDrawerInputCol">
                  <label class="sideDrawerInputLabel">
                    {{ detail.name }}
                  </label>
                  <DropDown
                    :options="detail.options"
                    :default="detail.default"
                    @input="updateDropDownVal(detail.value, $event)"
                  />
                </v-col>
              </template>
            </template>
            <template v-if="sectionIndex != drawerData.length - 1">
              <DividerComp
                class="sideDrawerSectionDivider"
                style="width: 100%"
              />
            </template>
          </v-row>
        </template>
      </div>
    </div>

    <div class="drawerCloseContainer">
      <div v-if="drawerData[0].button === 'Close'">
        <DividerComp class="sideDrawerDivider" />
        <div class="drawerCloseButton">
          <DarkButton
            :title="drawerData[0].button"
            width="parent"
            @click="handleClose"
          />
        </div>
      </div>
      <div v-else-if="drawerData[0].type !== 'form'">
        <DividerComp class="sideDrawerDivider" />
        <div class="drawerCloseButton">
          <DarkButton
            :title="drawerData[0].button"
            width="parent"
            @click="handleSubmit(drawerData[0].type)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DividerComp from "@/components/base_components/DividerComp.vue";
import DarkButton from "@/components/base_components/Buttons/DarkButton.vue";
import FillInBubble from "@/components/base_components/FormComponents/FillInBubble.vue";
import FormComp from "@/components/base_components/FormComponents/FormComp.vue";
import DropDownMulti from "@/components/base_components/FormComponents/DropDownMulti.vue";
import DropDown from "@/components/base_components/FormComponents/DropDown.vue";

export default {
  name: "SideDrawer",
  props: {
    drawerOpen: Boolean,
    drawerData: Array,
  },
  data() {
    return {
      submissionData: {},
    };
  },
  computed: {
    dynamicWidth() {
      const type = this.drawerData[0].type;
      if (type === "form") {
        return 550;
      } else if (type === "info") {
        return 350;
      } else if (type === "textToSpeech") {
        return 350;
      }
      else {
        return 300;
      }
    },
  },
  watch: {
    drawerData() {
      this.submissionData = {};
    },
  },
  methods: {
    handleClose() {
      this.$emit("close-click");
    },
    handleSubmit(type) {
      this.$emit("submit-click", this.submissionData, type);
    },
    handleFormSubmission(formData) {
      if (formData === "Error") {
        this.$emit("form-submission", "Error");
      } else {
        this.$emit("form-submission", formData);
      }
    },
    updateInputVal(name, event) {
      if (event.target.value) {
        this.submissionData[name] = event.target.value;
      } else {
        delete this.submissionData[name];
      }
    },
    updateDropDownVal(name, value) {
      if (name == "Categories") {
        this.$emit("add-category", value);
      } else {
        this.submissionData[name] = value;
      }
    },
  },
  components: {
    DividerComp,
    DarkButton,
    FillInBubble,
    FormComp,
    DropDownMulti,
    DropDown,
  },
  emits: [
    "close-click",
    "submit-click",
    "form-submission",
    "add-category"
  ]
};
</script>

<style scoped>
.sideDrawer {
  position: absolute;
  top: 89px;
  right: 0;
  z-index: 6;
  bottom: 0;
  background-color: #f4fafa;
  overflow-x: auto;
  transition: transform 0.2s ease;
  transform: translateX(100%);
  display: flex;
  flex-direction: column;
}

.sideDrawerInfoCol {
  display: flex;
  flex-direction: column;
}
.sideDrawerInputCol {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.sideDrawer.open {
  filter: drop-shadow(5px 5px 5px rgb(39, 29, 122, 1));
  transform: translateX(0);
}
.drawerTitle {
  color: #271e7a;
  font-weight: bold;
  font-size: 14pt;
  padding-bottom: 15px;
  padding-top: 5px;
}

.drawerFilterTitle {
  color: #271d7a;
  font-weight: 600;
  font-size: 11pt;
  padding-bottom: 25px;
}
.drawerSectionTitle {
  font-size: 11pt;
  font-weight: bold;
  color: #3e87d3;
  padding-bottom: 15px;
  padding-top: 15px;
}

.drawerDetailTitle {
  font-size: 9pt;
  font-weight: 600;
  color: #271d7a;
  padding-bottom: 5px;
}

.drawerDetailValue {
  font-size: 9pt;
  font-weight: normal;
  color: #271d7a;
}

.formSectionDivider {
  margin: 11px;
}
.drawerCloseContainer {
  margin-top: auto;
}

.sideDrawerDivider {
  margin-bottom: 20px;
}
.sideDrawerSectionDivider {
  margin: 20px -10px 20px -10px;
}
.sideDrawerRemoveButton {
  position: absolute;
  top: 0;
  right: 0;
  width: 31px;
  height: 35px;
  padding: 20px 16px 0px 0px;
}
.drawerWrapper {
  padding: 10px 30px 0px 20px;
}
.sideDrawerInputLabel {
  color: #271d7a;
  font-weight: 500;
  font-size: 10pt;
  padding: 5px 0px 3px 0px;
}
.formBackground {
  background-color: #ffffff;
}
.multiSelectLabel {
  color: #271d7a;
  font-size: 10pt;
  font-weight: 500;
}
.drawerCloseButton {
  padding: 0px 20px 20px 20px;
}

.textToSpeech {
  padding: 10px 30px 0px 20px;
}

</style>
