/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateNonLower48TFRates = /* GraphQL */ `
  subscription OnCreateNonLower48TFRates(
    $filter: ModelSubscriptionNonLower48TFRatesFilterInput
  ) {
    onCreateNonLower48TFRates(filter: $filter) {
      id
      usage_plan_group
      description
      retail_rate
      wholesale_rate
      country
      area_code
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateNonLower48TFRates = /* GraphQL */ `
  subscription OnUpdateNonLower48TFRates(
    $filter: ModelSubscriptionNonLower48TFRatesFilterInput
  ) {
    onUpdateNonLower48TFRates(filter: $filter) {
      id
      usage_plan_group
      description
      retail_rate
      wholesale_rate
      country
      area_code
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteNonLower48TFRates = /* GraphQL */ `
  subscription OnDeleteNonLower48TFRates(
    $filter: ModelSubscriptionNonLower48TFRatesFilterInput
  ) {
    onDeleteNonLower48TFRates(filter: $filter) {
      id
      usage_plan_group
      description
      retail_rate
      wholesale_rate
      country
      area_code
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateApplicationSections = /* GraphQL */ `
  subscription OnCreateApplicationSections(
    $filter: ModelSubscriptionApplicationSectionsFilterInput
  ) {
    onCreateApplicationSections(filter: $filter) {
      id
      icon
      title
      menuItems
      route
      allowed_groups
      order
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateApplicationSections = /* GraphQL */ `
  subscription OnUpdateApplicationSections(
    $filter: ModelSubscriptionApplicationSectionsFilterInput
  ) {
    onUpdateApplicationSections(filter: $filter) {
      id
      icon
      title
      menuItems
      route
      allowed_groups
      order
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteApplicationSections = /* GraphQL */ `
  subscription OnDeleteApplicationSections(
    $filter: ModelSubscriptionApplicationSectionsFilterInput
  ) {
    onDeleteApplicationSections(filter: $filter) {
      id
      icon
      title
      menuItems
      route
      allowed_groups
      order
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateAnalytics = /* GraphQL */ `
  subscription OnCreateAnalytics(
    $filter: ModelSubscriptionAnalyticsFilterInput
  ) {
    onCreateAnalytics(filter: $filter) {
      id
      name
      category
      created_by
      attributes
      availability
      report_layout
      report_objects {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateAnalytics = /* GraphQL */ `
  subscription OnUpdateAnalytics(
    $filter: ModelSubscriptionAnalyticsFilterInput
  ) {
    onUpdateAnalytics(filter: $filter) {
      id
      name
      category
      created_by
      attributes
      availability
      report_layout
      report_objects {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteAnalytics = /* GraphQL */ `
  subscription OnDeleteAnalytics(
    $filter: ModelSubscriptionAnalyticsFilterInput
  ) {
    onDeleteAnalytics(filter: $filter) {
      id
      name
      category
      created_by
      attributes
      availability
      report_layout
      report_objects {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateAnalyticReportObjects = /* GraphQL */ `
  subscription OnCreateAnalyticReportObjects(
    $filter: ModelSubscriptionAnalyticReportObjectsFilterInput
  ) {
    onCreateAnalyticReportObjects(filter: $filter) {
      id
      analytic_id
      title
      table
      chart
      data_source
      data_query
      data_filter
      order
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateAnalyticReportObjects = /* GraphQL */ `
  subscription OnUpdateAnalyticReportObjects(
    $filter: ModelSubscriptionAnalyticReportObjectsFilterInput
  ) {
    onUpdateAnalyticReportObjects(filter: $filter) {
      id
      analytic_id
      title
      table
      chart
      data_source
      data_query
      data_filter
      order
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteAnalyticReportObjects = /* GraphQL */ `
  subscription OnDeleteAnalyticReportObjects(
    $filter: ModelSubscriptionAnalyticReportObjectsFilterInput
  ) {
    onDeleteAnalyticReportObjects(filter: $filter) {
      id
      analytic_id
      title
      table
      chart
      data_source
      data_query
      data_filter
      order
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateDisconnectRequests = /* GraphQL */ `
  subscription OnCreateDisconnectRequests(
    $filter: ModelSubscriptionDisconnectRequestsFilterInput
  ) {
    onCreateDisconnectRequests(filter: $filter) {
      id
      service
      status
      disconnect_date
      requester
      comment
      full_disconnect
      account_number
      partner
      phone_numbers
      ticket_number
      port_out
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateDisconnectRequests = /* GraphQL */ `
  subscription OnUpdateDisconnectRequests(
    $filter: ModelSubscriptionDisconnectRequestsFilterInput
  ) {
    onUpdateDisconnectRequests(filter: $filter) {
      id
      service
      status
      disconnect_date
      requester
      comment
      full_disconnect
      account_number
      partner
      phone_numbers
      ticket_number
      port_out
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteDisconnectRequests = /* GraphQL */ `
  subscription OnDeleteDisconnectRequests(
    $filter: ModelSubscriptionDisconnectRequestsFilterInput
  ) {
    onDeleteDisconnectRequests(filter: $filter) {
      id
      service
      status
      disconnect_date
      requester
      comment
      full_disconnect
      account_number
      partner
      phone_numbers
      ticket_number
      port_out
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateDisconnectEmailRecipient = /* GraphQL */ `
  subscription OnCreateDisconnectEmailRecipient(
    $filter: ModelSubscriptionDisconnectEmailRecipientFilterInput
  ) {
    onCreateDisconnectEmailRecipient(filter: $filter) {
      id
      partner
      request_notification_email
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateDisconnectEmailRecipient = /* GraphQL */ `
  subscription OnUpdateDisconnectEmailRecipient(
    $filter: ModelSubscriptionDisconnectEmailRecipientFilterInput
  ) {
    onUpdateDisconnectEmailRecipient(filter: $filter) {
      id
      partner
      request_notification_email
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteDisconnectEmailRecipient = /* GraphQL */ `
  subscription OnDeleteDisconnectEmailRecipient(
    $filter: ModelSubscriptionDisconnectEmailRecipientFilterInput
  ) {
    onDeleteDisconnectEmailRecipient(filter: $filter) {
      id
      partner
      request_notification_email
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateSegraNumbers = /* GraphQL */ `
  subscription OnCreateSegraNumbers(
    $filter: ModelSubscriptionSegraNumbersFilterInput
  ) {
    onCreateSegraNumbers(filter: $filter) {
      id
      number
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateSegraNumbers = /* GraphQL */ `
  subscription OnUpdateSegraNumbers(
    $filter: ModelSubscriptionSegraNumbersFilterInput
  ) {
    onUpdateSegraNumbers(filter: $filter) {
      id
      number
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteSegraNumbers = /* GraphQL */ `
  subscription OnDeleteSegraNumbers(
    $filter: ModelSubscriptionSegraNumbersFilterInput
  ) {
    onDeleteSegraNumbers(filter: $filter) {
      id
      number
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateUpdateRequests = /* GraphQL */ `
  subscription OnCreateUpdateRequests(
    $filter: ModelSubscriptionUpdateRequestsFilterInput
  ) {
    onCreateUpdateRequests(filter: $filter) {
      id
      account_number
      accountStatus
      customerName
      custType
      connDate
      servDesc
      status
      monthlyRate
      name
      first_name
      last_name
      email
      address1
      city
      state
      zip
      E911Address
      E911City
      E911State
      E911Zip
      telephone
      DataPackage
      VoicePackages
      VideoPackage
      FaxPackage
      transferFlag
      new_phone1
      sipPassword1
      old_account_number
      provisioned
      in_process
      errors
      PIN
      isManagedService
      created_on
      billingFlag
      taxFlag
      accessSFP
      billable
      isXML
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateUpdateRequests = /* GraphQL */ `
  subscription OnUpdateUpdateRequests(
    $filter: ModelSubscriptionUpdateRequestsFilterInput
  ) {
    onUpdateUpdateRequests(filter: $filter) {
      id
      account_number
      accountStatus
      customerName
      custType
      connDate
      servDesc
      status
      monthlyRate
      name
      first_name
      last_name
      email
      address1
      city
      state
      zip
      E911Address
      E911City
      E911State
      E911Zip
      telephone
      DataPackage
      VoicePackages
      VideoPackage
      FaxPackage
      transferFlag
      new_phone1
      sipPassword1
      old_account_number
      provisioned
      in_process
      errors
      PIN
      isManagedService
      created_on
      billingFlag
      taxFlag
      accessSFP
      billable
      isXML
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteUpdateRequests = /* GraphQL */ `
  subscription OnDeleteUpdateRequests(
    $filter: ModelSubscriptionUpdateRequestsFilterInput
  ) {
    onDeleteUpdateRequests(filter: $filter) {
      id
      account_number
      accountStatus
      customerName
      custType
      connDate
      servDesc
      status
      monthlyRate
      name
      first_name
      last_name
      email
      address1
      city
      state
      zip
      E911Address
      E911City
      E911State
      E911Zip
      telephone
      DataPackage
      VoicePackages
      VideoPackage
      FaxPackage
      transferFlag
      new_phone1
      sipPassword1
      old_account_number
      provisioned
      in_process
      errors
      PIN
      isManagedService
      created_on
      billingFlag
      taxFlag
      accessSFP
      billable
      isXML
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateProvisionRequests = /* GraphQL */ `
  subscription OnCreateProvisionRequests(
    $filter: ModelSubscriptionProvisionRequestsFilterInput
  ) {
    onCreateProvisionRequests(filter: $filter) {
      id
      customerName
      account_number
      accountStatus
      name
      first_name
      last_name
      address1
      city
      state
      zip
      telephone
      servDesc
      DataPackage
      email
      custType
      E911Address
      E911City
      E911State
      E911Zip
      VoicePackages
      VideoPackage
      FaxPackage
      monthlyRate
      new_phone1
      ported1
      sipPassword1
      numLines
      DIDs
      DIDdetail
      SONbr
      CallerIDName
      new_phone2
      ported2
      sipPassword2
      features
      CO_Name
      provisioned
      in_process
      errors
      PIN
      isManagedService
      created_on
      billingFlag
      taxFlag
      accessSFP
      billable
      isXML
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateProvisionRequests = /* GraphQL */ `
  subscription OnUpdateProvisionRequests(
    $filter: ModelSubscriptionProvisionRequestsFilterInput
  ) {
    onUpdateProvisionRequests(filter: $filter) {
      id
      customerName
      account_number
      accountStatus
      name
      first_name
      last_name
      address1
      city
      state
      zip
      telephone
      servDesc
      DataPackage
      email
      custType
      E911Address
      E911City
      E911State
      E911Zip
      VoicePackages
      VideoPackage
      FaxPackage
      monthlyRate
      new_phone1
      ported1
      sipPassword1
      numLines
      DIDs
      DIDdetail
      SONbr
      CallerIDName
      new_phone2
      ported2
      sipPassword2
      features
      CO_Name
      provisioned
      in_process
      errors
      PIN
      isManagedService
      created_on
      billingFlag
      taxFlag
      accessSFP
      billable
      isXML
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteProvisionRequests = /* GraphQL */ `
  subscription OnDeleteProvisionRequests(
    $filter: ModelSubscriptionProvisionRequestsFilterInput
  ) {
    onDeleteProvisionRequests(filter: $filter) {
      id
      customerName
      account_number
      accountStatus
      name
      first_name
      last_name
      address1
      city
      state
      zip
      telephone
      servDesc
      DataPackage
      email
      custType
      E911Address
      E911City
      E911State
      E911Zip
      VoicePackages
      VideoPackage
      FaxPackage
      monthlyRate
      new_phone1
      ported1
      sipPassword1
      numLines
      DIDs
      DIDdetail
      SONbr
      CallerIDName
      new_phone2
      ported2
      sipPassword2
      features
      CO_Name
      provisioned
      in_process
      errors
      PIN
      isManagedService
      created_on
      billingFlag
      taxFlag
      accessSFP
      billable
      isXML
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreatePricebookDefaultProducts = /* GraphQL */ `
  subscription OnCreatePricebookDefaultProducts(
    $filter: ModelSubscriptionPricebookDefaultProductsFilterInput
  ) {
    onCreatePricebookDefaultProducts(filter: $filter) {
      id
      PricebookProducts {
        nextToken
        startedAt
        __typename
      }
      user_defined_product
      name
      nickname
      primary_product_code
      secondary_product_code
      unit_price
      wholesale_price
      frequency
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdatePricebookDefaultProducts = /* GraphQL */ `
  subscription OnUpdatePricebookDefaultProducts(
    $filter: ModelSubscriptionPricebookDefaultProductsFilterInput
  ) {
    onUpdatePricebookDefaultProducts(filter: $filter) {
      id
      PricebookProducts {
        nextToken
        startedAt
        __typename
      }
      user_defined_product
      name
      nickname
      primary_product_code
      secondary_product_code
      unit_price
      wholesale_price
      frequency
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeletePricebookDefaultProducts = /* GraphQL */ `
  subscription OnDeletePricebookDefaultProducts(
    $filter: ModelSubscriptionPricebookDefaultProductsFilterInput
  ) {
    onDeletePricebookDefaultProducts(filter: $filter) {
      id
      PricebookProducts {
        nextToken
        startedAt
        __typename
      }
      user_defined_product
      name
      nickname
      primary_product_code
      secondary_product_code
      unit_price
      wholesale_price
      frequency
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreatePricebookProducts = /* GraphQL */ `
  subscription OnCreatePricebookProducts(
    $filter: ModelSubscriptionPricebookProductsFilterInput
  ) {
    onCreatePricebookProducts(filter: $filter) {
      id
      pricebooksID
      unit_price
      wholesale_price
      enabled
      pricebookdefaultproductsID
      pricebook_default_product {
        id
        user_defined_product
        name
        nickname
        primary_product_code
        secondary_product_code
        unit_price
        wholesale_price
        frequency
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdatePricebookProducts = /* GraphQL */ `
  subscription OnUpdatePricebookProducts(
    $filter: ModelSubscriptionPricebookProductsFilterInput
  ) {
    onUpdatePricebookProducts(filter: $filter) {
      id
      pricebooksID
      unit_price
      wholesale_price
      enabled
      pricebookdefaultproductsID
      pricebook_default_product {
        id
        user_defined_product
        name
        nickname
        primary_product_code
        secondary_product_code
        unit_price
        wholesale_price
        frequency
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeletePricebookProducts = /* GraphQL */ `
  subscription OnDeletePricebookProducts(
    $filter: ModelSubscriptionPricebookProductsFilterInput
  ) {
    onDeletePricebookProducts(filter: $filter) {
      id
      pricebooksID
      unit_price
      wholesale_price
      enabled
      pricebookdefaultproductsID
      pricebook_default_product {
        id
        user_defined_product
        name
        nickname
        primary_product_code
        secondary_product_code
        unit_price
        wholesale_price
        frequency
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreatePricebooks = /* GraphQL */ `
  subscription OnCreatePricebooks(
    $filter: ModelSubscriptionPricebooksFilterInput
  ) {
    onCreatePricebooks(filter: $filter) {
      id
      partner
      coop_table_id
      nickname
      partner_account_number
      PricebookProducts {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdatePricebooks = /* GraphQL */ `
  subscription OnUpdatePricebooks(
    $filter: ModelSubscriptionPricebooksFilterInput
  ) {
    onUpdatePricebooks(filter: $filter) {
      id
      partner
      coop_table_id
      nickname
      partner_account_number
      PricebookProducts {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeletePricebooks = /* GraphQL */ `
  subscription OnDeletePricebooks(
    $filter: ModelSubscriptionPricebooksFilterInput
  ) {
    onDeletePricebooks(filter: $filter) {
      id
      partner
      coop_table_id
      nickname
      partner_account_number
      PricebookProducts {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateGroups = /* GraphQL */ `
  subscription OnCreateGroups($filter: ModelSubscriptionGroupsFilterInput) {
    onCreateGroups(filter: $filter) {
      id
      Name
      cognito_name
      Applications {
        nextToken
        startedAt
        __typename
      }
      PageLevelPermissions
      allowed_permissions
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateGroups = /* GraphQL */ `
  subscription OnUpdateGroups($filter: ModelSubscriptionGroupsFilterInput) {
    onUpdateGroups(filter: $filter) {
      id
      Name
      cognito_name
      Applications {
        nextToken
        startedAt
        __typename
      }
      PageLevelPermissions
      allowed_permissions
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteGroups = /* GraphQL */ `
  subscription OnDeleteGroups($filter: ModelSubscriptionGroupsFilterInput) {
    onDeleteGroups(filter: $filter) {
      id
      Name
      cognito_name
      Applications {
        nextToken
        startedAt
        __typename
      }
      PageLevelPermissions
      allowed_permissions
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateGroupApplications = /* GraphQL */ `
  subscription OnCreateGroupApplications(
    $filter: ModelSubscriptionGroupApplicationsFilterInput
  ) {
    onCreateGroupApplications(filter: $filter) {
      id
      group_id
      application_id
      application {
        id
        name
        application_section
        is_child_application
        route
        application_permissions
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      allowed_permissions
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateGroupApplications = /* GraphQL */ `
  subscription OnUpdateGroupApplications(
    $filter: ModelSubscriptionGroupApplicationsFilterInput
  ) {
    onUpdateGroupApplications(filter: $filter) {
      id
      group_id
      application_id
      application {
        id
        name
        application_section
        is_child_application
        route
        application_permissions
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      allowed_permissions
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteGroupApplications = /* GraphQL */ `
  subscription OnDeleteGroupApplications(
    $filter: ModelSubscriptionGroupApplicationsFilterInput
  ) {
    onDeleteGroupApplications(filter: $filter) {
      id
      group_id
      application_id
      application {
        id
        name
        application_section
        is_child_application
        route
        application_permissions
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      allowed_permissions
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateApplications = /* GraphQL */ `
  subscription OnCreateApplications(
    $filter: ModelSubscriptionApplicationsFilterInput
  ) {
    onCreateApplications(filter: $filter) {
      id
      name
      application_section
      is_child_application
      route
      application_permissions
      GroupApplications {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateApplications = /* GraphQL */ `
  subscription OnUpdateApplications(
    $filter: ModelSubscriptionApplicationsFilterInput
  ) {
    onUpdateApplications(filter: $filter) {
      id
      name
      application_section
      is_child_application
      route
      application_permissions
      GroupApplications {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteApplications = /* GraphQL */ `
  subscription OnDeleteApplications(
    $filter: ModelSubscriptionApplicationsFilterInput
  ) {
    onDeleteApplications(filter: $filter) {
      id
      name
      application_section
      is_child_application
      route
      application_permissions
      GroupApplications {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateGeneralLog = /* GraphQL */ `
  subscription OnCreateGeneralLog(
    $filter: ModelSubscriptionGeneralLogFilterInput
  ) {
    onCreateGeneralLog(filter: $filter) {
      id
      script
      msg_type
      app
      message
      msg_date
      msg_time
      partner
      is_error
      error_msg
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateGeneralLog = /* GraphQL */ `
  subscription OnUpdateGeneralLog(
    $filter: ModelSubscriptionGeneralLogFilterInput
  ) {
    onUpdateGeneralLog(filter: $filter) {
      id
      script
      msg_type
      app
      message
      msg_date
      msg_time
      partner
      is_error
      error_msg
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteGeneralLog = /* GraphQL */ `
  subscription OnDeleteGeneralLog(
    $filter: ModelSubscriptionGeneralLogFilterInput
  ) {
    onDeleteGeneralLog(filter: $filter) {
      id
      script
      msg_type
      app
      message
      msg_date
      msg_time
      partner
      is_error
      error_msg
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateCOnames = /* GraphQL */ `
  subscription OnCreateCOnames($filter: ModelSubscriptionCOnamesFilterInput) {
    onCreateCOnames(filter: $filter) {
      id
      CO_Name
      account_id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateCOnames = /* GraphQL */ `
  subscription OnUpdateCOnames($filter: ModelSubscriptionCOnamesFilterInput) {
    onUpdateCOnames(filter: $filter) {
      id
      CO_Name
      account_id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteCOnames = /* GraphQL */ `
  subscription OnDeleteCOnames($filter: ModelSubscriptionCOnamesFilterInput) {
    onDeleteCOnames(filter: $filter) {
      id
      CO_Name
      account_id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateComments = /* GraphQL */ `
  subscription OnCreateComments($filter: ModelSubscriptionCommentsFilterInput) {
    onCreateComments(filter: $filter) {
      id
      port_request_id
      content
      timestamp
      action_required
      action_response
      author
      is_private
      user_id
      port_state
      comment_type
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateComments = /* GraphQL */ `
  subscription OnUpdateComments($filter: ModelSubscriptionCommentsFilterInput) {
    onUpdateComments(filter: $filter) {
      id
      port_request_id
      content
      timestamp
      action_required
      action_response
      author
      is_private
      user_id
      port_state
      comment_type
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteComments = /* GraphQL */ `
  subscription OnDeleteComments($filter: ModelSubscriptionCommentsFilterInput) {
    onDeleteComments(filter: $filter) {
      id
      port_request_id
      content
      timestamp
      action_required
      action_response
      author
      is_private
      user_id
      port_state
      comment_type
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateKazooWebhookAccountIds = /* GraphQL */ `
  subscription OnCreateKazooWebhookAccountIds(
    $filter: ModelSubscriptionKazooWebhookAccountIdsFilterInput
  ) {
    onCreateKazooWebhookAccountIds(filter: $filter) {
      id
      account_id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateKazooWebhookAccountIds = /* GraphQL */ `
  subscription OnUpdateKazooWebhookAccountIds(
    $filter: ModelSubscriptionKazooWebhookAccountIdsFilterInput
  ) {
    onUpdateKazooWebhookAccountIds(filter: $filter) {
      id
      account_id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteKazooWebhookAccountIds = /* GraphQL */ `
  subscription OnDeleteKazooWebhookAccountIds(
    $filter: ModelSubscriptionKazooWebhookAccountIdsFilterInput
  ) {
    onDeleteKazooWebhookAccountIds(filter: $filter) {
      id
      account_id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreatePortOrders = /* GraphQL */ `
  subscription OnCreatePortOrders(
    $filter: ModelSubscriptionPortOrdersFilterInput
  ) {
    onCreatePortOrders(filter: $filter) {
      id
      name
      subscriberType
      submission_form_data
      submission_form_type
      phoneNumberType
      port_request_id
      port_state
      partner
      notification_email
      account_name
      parent_id
      account_id
      externalID
      requested_FOC_date
      port_request_name
      bill_carrier
      loc_number
      winning_carrier
      partialPort
      triggered
      business_name
      first_name
      middle_initial
      last_name
      housePrefix
      houseSuffix
      preDirection
      street_number
      street_address
      street_type
      postDirection
      AddressLine2
      city
      state_code
      zip
      plusFour
      account_number
      pin
      btn
      reference_number
      portNumbers
      accountPortion
      bill_filename
      recent_bill_verification
      loa_filename
      signee_name
      signing_date
      submission_date
      secondaryBtn
      isPortingBTN
      FOC_date
      original_foc_date
      lastOrderNote
      locationID
      CO_Name
      hosted_install
      fractelEmailed
      multi_line_order {
        nextToken
        startedAt
        __typename
      }
      port_order_comments {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdatePortOrders = /* GraphQL */ `
  subscription OnUpdatePortOrders(
    $filter: ModelSubscriptionPortOrdersFilterInput
  ) {
    onUpdatePortOrders(filter: $filter) {
      id
      name
      subscriberType
      submission_form_data
      submission_form_type
      phoneNumberType
      port_request_id
      port_state
      partner
      notification_email
      account_name
      parent_id
      account_id
      externalID
      requested_FOC_date
      port_request_name
      bill_carrier
      loc_number
      winning_carrier
      partialPort
      triggered
      business_name
      first_name
      middle_initial
      last_name
      housePrefix
      houseSuffix
      preDirection
      street_number
      street_address
      street_type
      postDirection
      AddressLine2
      city
      state_code
      zip
      plusFour
      account_number
      pin
      btn
      reference_number
      portNumbers
      accountPortion
      bill_filename
      recent_bill_verification
      loa_filename
      signee_name
      signing_date
      submission_date
      secondaryBtn
      isPortingBTN
      FOC_date
      original_foc_date
      lastOrderNote
      locationID
      CO_Name
      hosted_install
      fractelEmailed
      multi_line_order {
        nextToken
        startedAt
        __typename
      }
      port_order_comments {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeletePortOrders = /* GraphQL */ `
  subscription OnDeletePortOrders(
    $filter: ModelSubscriptionPortOrdersFilterInput
  ) {
    onDeletePortOrders(filter: $filter) {
      id
      name
      subscriberType
      submission_form_data
      submission_form_type
      phoneNumberType
      port_request_id
      port_state
      partner
      notification_email
      account_name
      parent_id
      account_id
      externalID
      requested_FOC_date
      port_request_name
      bill_carrier
      loc_number
      winning_carrier
      partialPort
      triggered
      business_name
      first_name
      middle_initial
      last_name
      housePrefix
      houseSuffix
      preDirection
      street_number
      street_address
      street_type
      postDirection
      AddressLine2
      city
      state_code
      zip
      plusFour
      account_number
      pin
      btn
      reference_number
      portNumbers
      accountPortion
      bill_filename
      recent_bill_verification
      loa_filename
      signee_name
      signing_date
      submission_date
      secondaryBtn
      isPortingBTN
      FOC_date
      original_foc_date
      lastOrderNote
      locationID
      CO_Name
      hosted_install
      fractelEmailed
      multi_line_order {
        nextToken
        startedAt
        __typename
      }
      port_order_comments {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreatePortOrderComments = /* GraphQL */ `
  subscription OnCreatePortOrderComments(
    $filter: ModelSubscriptionPortOrderCommentsFilterInput
  ) {
    onCreatePortOrderComments(filter: $filter) {
      id
      port_order_id
      content
      timestamp
      action_required
      action_response
      author
      is_private
      user_id
      port_state
      comment_type
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdatePortOrderComments = /* GraphQL */ `
  subscription OnUpdatePortOrderComments(
    $filter: ModelSubscriptionPortOrderCommentsFilterInput
  ) {
    onUpdatePortOrderComments(filter: $filter) {
      id
      port_order_id
      content
      timestamp
      action_required
      action_response
      author
      is_private
      user_id
      port_state
      comment_type
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeletePortOrderComments = /* GraphQL */ `
  subscription OnDeletePortOrderComments(
    $filter: ModelSubscriptionPortOrderCommentsFilterInput
  ) {
    onDeletePortOrderComments(filter: $filter) {
      id
      port_order_id
      content
      timestamp
      action_required
      action_response
      author
      is_private
      user_id
      port_state
      comment_type
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateSaveCallCenterProData = /* GraphQL */ `
  subscription OnCreateSaveCallCenterProData(
    $filter: ModelSubscriptionSaveCallCenterProDataFilterInput
  ) {
    onCreateSaveCallCenterProData(filter: $filter) {
      id
      queueName
      timestamp
      dateTime
      eventDesc
      agentName
      callerIdNumber
      callerIdName
      joinPosition
      joinDateTime
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateSaveCallCenterProData = /* GraphQL */ `
  subscription OnUpdateSaveCallCenterProData(
    $filter: ModelSubscriptionSaveCallCenterProDataFilterInput
  ) {
    onUpdateSaveCallCenterProData(filter: $filter) {
      id
      queueName
      timestamp
      dateTime
      eventDesc
      agentName
      callerIdNumber
      callerIdName
      joinPosition
      joinDateTime
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteSaveCallCenterProData = /* GraphQL */ `
  subscription OnDeleteSaveCallCenterProData(
    $filter: ModelSubscriptionSaveCallCenterProDataFilterInput
  ) {
    onDeleteSaveCallCenterProData(filter: $filter) {
      id
      queueName
      timestamp
      dateTime
      eventDesc
      agentName
      callerIdNumber
      callerIdName
      joinPosition
      joinDateTime
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateSwitchQubicleSessions = /* GraphQL */ `
  subscription OnCreateSwitchQubicleSessions(
    $filter: ModelSubscriptionSwitchQubicleSessionsFilterInput
  ) {
    onCreateSwitchQubicleSessions(filter: $filter) {
      id
      event_type
      account_id
      account_name
      event_timestamp
      session_type
      session_id
      pid
      caller_id_number
      caller_id_name
      call_data
      session_life_time
      queue_wait_time
      create_time
      reason
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateSwitchQubicleSessions = /* GraphQL */ `
  subscription OnUpdateSwitchQubicleSessions(
    $filter: ModelSubscriptionSwitchQubicleSessionsFilterInput
  ) {
    onUpdateSwitchQubicleSessions(filter: $filter) {
      id
      event_type
      account_id
      account_name
      event_timestamp
      session_type
      session_id
      pid
      caller_id_number
      caller_id_name
      call_data
      session_life_time
      queue_wait_time
      create_time
      reason
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteSwitchQubicleSessions = /* GraphQL */ `
  subscription OnDeleteSwitchQubicleSessions(
    $filter: ModelSubscriptionSwitchQubicleSessionsFilterInput
  ) {
    onDeleteSwitchQubicleSessions(filter: $filter) {
      id
      event_type
      account_id
      account_name
      event_timestamp
      session_type
      session_id
      pid
      caller_id_number
      caller_id_name
      call_data
      session_life_time
      queue_wait_time
      create_time
      reason
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateSwitchQubicleRecipients = /* GraphQL */ `
  subscription OnCreateSwitchQubicleRecipients(
    $filter: ModelSubscriptionSwitchQubicleRecipientsFilterInput
  ) {
    onCreateSwitchQubicleRecipients(filter: $filter) {
      id
      event_type
      event_timestamp
      reason
      account_id
      displayname
      recipient_id
      queue_name
      queue_id
      caller_id_number
      caller_id_name
      queue_wait_time
      queue_enter_time
      session_id
      session
      session_zone
      talk_time
      queue_offering
      est_wait
      enter_pos
      record_queue
      queue_wrapup_extension_limit
      force_away_on_reject
      away_time
      ring_timeout
      queue_wrapup_extension
      queue_wrapup
      total_calls
      offered_calls
      missed_calls
      avg_call_time
      login_time
      last_action_time
      last_handled_time
      last_idle_time
      input_object
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateSwitchQubicleRecipients = /* GraphQL */ `
  subscription OnUpdateSwitchQubicleRecipients(
    $filter: ModelSubscriptionSwitchQubicleRecipientsFilterInput
  ) {
    onUpdateSwitchQubicleRecipients(filter: $filter) {
      id
      event_type
      event_timestamp
      reason
      account_id
      displayname
      recipient_id
      queue_name
      queue_id
      caller_id_number
      caller_id_name
      queue_wait_time
      queue_enter_time
      session_id
      session
      session_zone
      talk_time
      queue_offering
      est_wait
      enter_pos
      record_queue
      queue_wrapup_extension_limit
      force_away_on_reject
      away_time
      ring_timeout
      queue_wrapup_extension
      queue_wrapup
      total_calls
      offered_calls
      missed_calls
      avg_call_time
      login_time
      last_action_time
      last_handled_time
      last_idle_time
      input_object
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteSwitchQubicleRecipients = /* GraphQL */ `
  subscription OnDeleteSwitchQubicleRecipients(
    $filter: ModelSubscriptionSwitchQubicleRecipientsFilterInput
  ) {
    onDeleteSwitchQubicleRecipients(filter: $filter) {
      id
      event_type
      event_timestamp
      reason
      account_id
      displayname
      recipient_id
      queue_name
      queue_id
      caller_id_number
      caller_id_name
      queue_wait_time
      queue_enter_time
      session_id
      session
      session_zone
      talk_time
      queue_offering
      est_wait
      enter_pos
      record_queue
      queue_wrapup_extension_limit
      force_away_on_reject
      away_time
      ring_timeout
      queue_wrapup_extension
      queue_wrapup
      total_calls
      offered_calls
      missed_calls
      avg_call_time
      login_time
      last_action_time
      last_handled_time
      last_idle_time
      input_object
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateSwitchQubicleQueues = /* GraphQL */ `
  subscription OnCreateSwitchQubicleQueues(
    $filter: ModelSubscriptionSwitchQubicleQueuesFilterInput
  ) {
    onCreateSwitchQubicleQueues(filter: $filter) {
      id
      event_type
      account_id
      queue_id
      event_timestamp
      total_wait_time
      queue_name
      join_position
      join_time
      est_wait_time
      caller_id_number
      caller_id_name
      session_id
      estimated_wait
      average_wait
      longest_wait
      recipient_count
      active_session_count
      total_sessions
      missed_sessions
      abandoned_sessions
      session_data
      input_object
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateSwitchQubicleQueues = /* GraphQL */ `
  subscription OnUpdateSwitchQubicleQueues(
    $filter: ModelSubscriptionSwitchQubicleQueuesFilterInput
  ) {
    onUpdateSwitchQubicleQueues(filter: $filter) {
      id
      event_type
      account_id
      queue_id
      event_timestamp
      total_wait_time
      queue_name
      join_position
      join_time
      est_wait_time
      caller_id_number
      caller_id_name
      session_id
      estimated_wait
      average_wait
      longest_wait
      recipient_count
      active_session_count
      total_sessions
      missed_sessions
      abandoned_sessions
      session_data
      input_object
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteSwitchQubicleQueues = /* GraphQL */ `
  subscription OnDeleteSwitchQubicleQueues(
    $filter: ModelSubscriptionSwitchQubicleQueuesFilterInput
  ) {
    onDeleteSwitchQubicleQueues(filter: $filter) {
      id
      event_type
      account_id
      queue_id
      event_timestamp
      total_wait_time
      queue_name
      join_position
      join_time
      est_wait_time
      caller_id_number
      caller_id_name
      session_id
      estimated_wait
      average_wait
      longest_wait
      recipient_count
      active_session_count
      total_sessions
      missed_sessions
      abandoned_sessions
      session_data
      input_object
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateSwitchCDRs = /* GraphQL */ `
  subscription OnCreateSwitchCDRs(
    $filter: ModelSubscriptionSwitchCDRsFilterInput
  ) {
    onCreateSwitchCDRs(filter: $filter) {
      id
      account_id
      parent_id
      cdr_id
      call_id
      caller_id_number
      caller_id_name
      callee_id_number
      callee_id_name
      duration_seconds
      billing_seconds
      gregorian_timestamp
      hangup_cause
      other_leg_call_id
      owner_id
      to
      from
      revio_id
      external_id
      direction
      request
      authorizing_id
      cost
      dialed_number
      calling_from
      datetime
      unix_timestamp
      unix_timestamp_micro
      rfc_1036
      iso_8601
      iso_8601_combined
      call_type
      rate
      rate_name
      bridge_id
      recording_url
      media_recordings
      media_server
      call_priority
      interaction_id
      reseller_cost
      reseller_call_type
      wholesale_cost
      retail_cost
      robocall_threshold {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateSwitchCDRs = /* GraphQL */ `
  subscription OnUpdateSwitchCDRs(
    $filter: ModelSubscriptionSwitchCDRsFilterInput
  ) {
    onUpdateSwitchCDRs(filter: $filter) {
      id
      account_id
      parent_id
      cdr_id
      call_id
      caller_id_number
      caller_id_name
      callee_id_number
      callee_id_name
      duration_seconds
      billing_seconds
      gregorian_timestamp
      hangup_cause
      other_leg_call_id
      owner_id
      to
      from
      revio_id
      external_id
      direction
      request
      authorizing_id
      cost
      dialed_number
      calling_from
      datetime
      unix_timestamp
      unix_timestamp_micro
      rfc_1036
      iso_8601
      iso_8601_combined
      call_type
      rate
      rate_name
      bridge_id
      recording_url
      media_recordings
      media_server
      call_priority
      interaction_id
      reseller_cost
      reseller_call_type
      wholesale_cost
      retail_cost
      robocall_threshold {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteSwitchCDRs = /* GraphQL */ `
  subscription OnDeleteSwitchCDRs(
    $filter: ModelSubscriptionSwitchCDRsFilterInput
  ) {
    onDeleteSwitchCDRs(filter: $filter) {
      id
      account_id
      parent_id
      cdr_id
      call_id
      caller_id_number
      caller_id_name
      callee_id_number
      callee_id_name
      duration_seconds
      billing_seconds
      gregorian_timestamp
      hangup_cause
      other_leg_call_id
      owner_id
      to
      from
      revio_id
      external_id
      direction
      request
      authorizing_id
      cost
      dialed_number
      calling_from
      datetime
      unix_timestamp
      unix_timestamp_micro
      rfc_1036
      iso_8601
      iso_8601_combined
      call_type
      rate
      rate_name
      bridge_id
      recording_url
      media_recordings
      media_server
      call_priority
      interaction_id
      reseller_cost
      reseller_call_type
      wholesale_cost
      retail_cost
      robocall_threshold {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateSwitch811s = /* GraphQL */ `
  subscription OnCreateSwitch811s(
    $filter: ModelSubscriptionSwitch811sFilterInput
  ) {
    onCreateSwitch811s(filter: $filter) {
      id
      state
      number_811
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateSwitch811s = /* GraphQL */ `
  subscription OnUpdateSwitch811s(
    $filter: ModelSubscriptionSwitch811sFilterInput
  ) {
    onUpdateSwitch811s(filter: $filter) {
      id
      state
      number_811
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteSwitch811s = /* GraphQL */ `
  subscription OnDeleteSwitch811s(
    $filter: ModelSubscriptionSwitch811sFilterInput
  ) {
    onDeleteSwitch811s(filter: $filter) {
      id
      state
      number_811
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreatePartners = /* GraphQL */ `
  subscription OnCreatePartners($filter: ModelSubscriptionPartnersFilterInput) {
    onCreatePartners(filter: $filter) {
      partner
      name
      revio_id
      company_name
      powered_by
      logo
      logo_height
      logo_width
      website_url
      quotes {
        nextToken
        startedAt
        __typename
      }
      switch_parent_id
      switch_parent_account {
        nextToken
        startedAt
        __typename
      }
      switch_child_id
      switch_child_account {
        nextToken
        startedAt
        __typename
      }
      bill_profile_id
      usage_plan_group_id
      phone_number
      email
      coop_table_id
      pricebook {
        nextToken
        startedAt
        __typename
      }
      revio_customer {
        nextToken
        startedAt
        __typename
      }
      wholesale_as_retail
      fusf_custom_tax_exemption
      foc_notification_emails
      fusf_exemption
      has_glds_billing
      has_provisioning
      port_network_email_list
      port_action_email_list
      port_impending_foc_email_list
      queue
      id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdatePartners = /* GraphQL */ `
  subscription OnUpdatePartners($filter: ModelSubscriptionPartnersFilterInput) {
    onUpdatePartners(filter: $filter) {
      partner
      name
      revio_id
      company_name
      powered_by
      logo
      logo_height
      logo_width
      website_url
      quotes {
        nextToken
        startedAt
        __typename
      }
      switch_parent_id
      switch_parent_account {
        nextToken
        startedAt
        __typename
      }
      switch_child_id
      switch_child_account {
        nextToken
        startedAt
        __typename
      }
      bill_profile_id
      usage_plan_group_id
      phone_number
      email
      coop_table_id
      pricebook {
        nextToken
        startedAt
        __typename
      }
      revio_customer {
        nextToken
        startedAt
        __typename
      }
      wholesale_as_retail
      fusf_custom_tax_exemption
      foc_notification_emails
      fusf_exemption
      has_glds_billing
      has_provisioning
      port_network_email_list
      port_action_email_list
      port_impending_foc_email_list
      queue
      id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeletePartners = /* GraphQL */ `
  subscription OnDeletePartners($filter: ModelSubscriptionPartnersFilterInput) {
    onDeletePartners(filter: $filter) {
      partner
      name
      revio_id
      company_name
      powered_by
      logo
      logo_height
      logo_width
      website_url
      quotes {
        nextToken
        startedAt
        __typename
      }
      switch_parent_id
      switch_parent_account {
        nextToken
        startedAt
        __typename
      }
      switch_child_id
      switch_child_account {
        nextToken
        startedAt
        __typename
      }
      bill_profile_id
      usage_plan_group_id
      phone_number
      email
      coop_table_id
      pricebook {
        nextToken
        startedAt
        __typename
      }
      revio_customer {
        nextToken
        startedAt
        __typename
      }
      wholesale_as_retail
      fusf_custom_tax_exemption
      foc_notification_emails
      fusf_exemption
      has_glds_billing
      has_provisioning
      port_network_email_list
      port_action_email_list
      port_impending_foc_email_list
      queue
      id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateManagerPartners = /* GraphQL */ `
  subscription OnCreateManagerPartners(
    $filter: ModelSubscriptionManagerPartnersFilterInput
  ) {
    onCreateManagerPartners(filter: $filter) {
      id
      name
      ticket_queue_name
      category
      voice_category
      contact_center_category
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateManagerPartners = /* GraphQL */ `
  subscription OnUpdateManagerPartners(
    $filter: ModelSubscriptionManagerPartnersFilterInput
  ) {
    onUpdateManagerPartners(filter: $filter) {
      id
      name
      ticket_queue_name
      category
      voice_category
      contact_center_category
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteManagerPartners = /* GraphQL */ `
  subscription OnDeleteManagerPartners(
    $filter: ModelSubscriptionManagerPartnersFilterInput
  ) {
    onDeleteManagerPartners(filter: $filter) {
      id
      name
      ticket_queue_name
      category
      voice_category
      contact_center_category
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateSwitchUsers = /* GraphQL */ `
  subscription OnCreateSwitchUsers(
    $filter: ModelSubscriptionSwitchUsersFilterInput
  ) {
    onCreateSwitchUsers(filter: $filter) {
      id
      username
      email
      first_name
      last_name
      priv_level
      presence_id
      caller_id
      send_email_on_creation
      call_restriction
      contact_list
      dial_plan
      enabled
      hotdesk
      media
      music_on_hold
      profile
      require_password_update
      ringtones
      verified
      vm_to_email_enabled
      account_name
      apps
      ui_help
      appList
      user_id
      account_id
      account {
        id
        ui_restrictions
        ui_flags
        timezone
        ringtones
        reseller_id
        realm
        preflow
        notifications
        notification_preference
        name
        music_on_hold
        language
        is_reseller
        dial_plan
        descendants_count
        created
        contract
        contact
        caller_id
        call_restriction
        metaflows
        parent_id
        account_id
        _read_only
        wnm_allow_additions
        superduper_admin
        enabled
        billing_mode
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      _read_only
      has_avatar
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateSwitchUsers = /* GraphQL */ `
  subscription OnUpdateSwitchUsers(
    $filter: ModelSubscriptionSwitchUsersFilterInput
  ) {
    onUpdateSwitchUsers(filter: $filter) {
      id
      username
      email
      first_name
      last_name
      priv_level
      presence_id
      caller_id
      send_email_on_creation
      call_restriction
      contact_list
      dial_plan
      enabled
      hotdesk
      media
      music_on_hold
      profile
      require_password_update
      ringtones
      verified
      vm_to_email_enabled
      account_name
      apps
      ui_help
      appList
      user_id
      account_id
      account {
        id
        ui_restrictions
        ui_flags
        timezone
        ringtones
        reseller_id
        realm
        preflow
        notifications
        notification_preference
        name
        music_on_hold
        language
        is_reseller
        dial_plan
        descendants_count
        created
        contract
        contact
        caller_id
        call_restriction
        metaflows
        parent_id
        account_id
        _read_only
        wnm_allow_additions
        superduper_admin
        enabled
        billing_mode
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      _read_only
      has_avatar
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteSwitchUsers = /* GraphQL */ `
  subscription OnDeleteSwitchUsers(
    $filter: ModelSubscriptionSwitchUsersFilterInput
  ) {
    onDeleteSwitchUsers(filter: $filter) {
      id
      username
      email
      first_name
      last_name
      priv_level
      presence_id
      caller_id
      send_email_on_creation
      call_restriction
      contact_list
      dial_plan
      enabled
      hotdesk
      media
      music_on_hold
      profile
      require_password_update
      ringtones
      verified
      vm_to_email_enabled
      account_name
      apps
      ui_help
      appList
      user_id
      account_id
      account {
        id
        ui_restrictions
        ui_flags
        timezone
        ringtones
        reseller_id
        realm
        preflow
        notifications
        notification_preference
        name
        music_on_hold
        language
        is_reseller
        dial_plan
        descendants_count
        created
        contract
        contact
        caller_id
        call_restriction
        metaflows
        parent_id
        account_id
        _read_only
        wnm_allow_additions
        superduper_admin
        enabled
        billing_mode
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      _read_only
      has_avatar
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateSwitchMailboxes = /* GraphQL */ `
  subscription OnCreateSwitchMailboxes(
    $filter: ModelSubscriptionSwitchMailboxesFilterInput
  ) {
    onCreateSwitchMailboxes(filter: $filter) {
      id
      mailbox
      name
      owner_id
      media_extension
      notify_email_addresses
      announcement_only
      check_if_owner
      delete_after_notify
      include_message_on_notify
      include_transcription_on_notify
      is_setup
      is_voicemail_ff_rw_enabled
      media
      not_configurable
      oldest_message_first
      require_pin
      save_after_notify
      seek_duration_ms
      skip_envelope
      skip_greeting
      skip_instructions
      transcribe
      vm_message_forward_type
      ui_metadata
      account_id
      account {
        id
        ui_restrictions
        ui_flags
        timezone
        ringtones
        reseller_id
        realm
        preflow
        notifications
        notification_preference
        name
        music_on_hold
        language
        is_reseller
        dial_plan
        descendants_count
        created
        contract
        contact
        caller_id
        call_restriction
        metaflows
        parent_id
        account_id
        _read_only
        wnm_allow_additions
        superduper_admin
        enabled
        billing_mode
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      mailbox_id
      _read_only
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateSwitchMailboxes = /* GraphQL */ `
  subscription OnUpdateSwitchMailboxes(
    $filter: ModelSubscriptionSwitchMailboxesFilterInput
  ) {
    onUpdateSwitchMailboxes(filter: $filter) {
      id
      mailbox
      name
      owner_id
      media_extension
      notify_email_addresses
      announcement_only
      check_if_owner
      delete_after_notify
      include_message_on_notify
      include_transcription_on_notify
      is_setup
      is_voicemail_ff_rw_enabled
      media
      not_configurable
      oldest_message_first
      require_pin
      save_after_notify
      seek_duration_ms
      skip_envelope
      skip_greeting
      skip_instructions
      transcribe
      vm_message_forward_type
      ui_metadata
      account_id
      account {
        id
        ui_restrictions
        ui_flags
        timezone
        ringtones
        reseller_id
        realm
        preflow
        notifications
        notification_preference
        name
        music_on_hold
        language
        is_reseller
        dial_plan
        descendants_count
        created
        contract
        contact
        caller_id
        call_restriction
        metaflows
        parent_id
        account_id
        _read_only
        wnm_allow_additions
        superduper_admin
        enabled
        billing_mode
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      mailbox_id
      _read_only
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteSwitchMailboxes = /* GraphQL */ `
  subscription OnDeleteSwitchMailboxes(
    $filter: ModelSubscriptionSwitchMailboxesFilterInput
  ) {
    onDeleteSwitchMailboxes(filter: $filter) {
      id
      mailbox
      name
      owner_id
      media_extension
      notify_email_addresses
      announcement_only
      check_if_owner
      delete_after_notify
      include_message_on_notify
      include_transcription_on_notify
      is_setup
      is_voicemail_ff_rw_enabled
      media
      not_configurable
      oldest_message_first
      require_pin
      save_after_notify
      seek_duration_ms
      skip_envelope
      skip_greeting
      skip_instructions
      transcribe
      vm_message_forward_type
      ui_metadata
      account_id
      account {
        id
        ui_restrictions
        ui_flags
        timezone
        ringtones
        reseller_id
        realm
        preflow
        notifications
        notification_preference
        name
        music_on_hold
        language
        is_reseller
        dial_plan
        descendants_count
        created
        contract
        contact
        caller_id
        call_restriction
        metaflows
        parent_id
        account_id
        _read_only
        wnm_allow_additions
        superduper_admin
        enabled
        billing_mode
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      mailbox_id
      _read_only
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateSwitchNumbers = /* GraphQL */ `
  subscription OnCreateSwitchNumbers(
    $filter: ModelSubscriptionSwitchNumbersFilterInput
  ) {
    onCreateSwitchNumbers(filter: $filter) {
      id
      number
      state
      prepended
      account_id
      account {
        id
        ui_restrictions
        ui_flags
        timezone
        ringtones
        reseller_id
        realm
        preflow
        notifications
        notification_preference
        name
        music_on_hold
        language
        is_reseller
        dial_plan
        descendants_count
        created
        contract
        contact
        caller_id
        call_restriction
        metaflows
        parent_id
        account_id
        _read_only
        wnm_allow_additions
        superduper_admin
        enabled
        billing_mode
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateSwitchNumbers = /* GraphQL */ `
  subscription OnUpdateSwitchNumbers(
    $filter: ModelSubscriptionSwitchNumbersFilterInput
  ) {
    onUpdateSwitchNumbers(filter: $filter) {
      id
      number
      state
      prepended
      account_id
      account {
        id
        ui_restrictions
        ui_flags
        timezone
        ringtones
        reseller_id
        realm
        preflow
        notifications
        notification_preference
        name
        music_on_hold
        language
        is_reseller
        dial_plan
        descendants_count
        created
        contract
        contact
        caller_id
        call_restriction
        metaflows
        parent_id
        account_id
        _read_only
        wnm_allow_additions
        superduper_admin
        enabled
        billing_mode
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteSwitchNumbers = /* GraphQL */ `
  subscription OnDeleteSwitchNumbers(
    $filter: ModelSubscriptionSwitchNumbersFilterInput
  ) {
    onDeleteSwitchNumbers(filter: $filter) {
      id
      number
      state
      prepended
      account_id
      account {
        id
        ui_restrictions
        ui_flags
        timezone
        ringtones
        reseller_id
        realm
        preflow
        notifications
        notification_preference
        name
        music_on_hold
        language
        is_reseller
        dial_plan
        descendants_count
        created
        contract
        contact
        caller_id
        call_restriction
        metaflows
        parent_id
        account_id
        _read_only
        wnm_allow_additions
        superduper_admin
        enabled
        billing_mode
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateSwitchMedia = /* GraphQL */ `
  subscription OnCreateSwitchMedia(
    $filter: ModelSubscriptionSwitchMediaFilterInput
  ) {
    onCreateSwitchMedia(filter: $filter) {
      id
      account_id
      account {
        id
        ui_restrictions
        ui_flags
        timezone
        ringtones
        reseller_id
        realm
        preflow
        notifications
        notification_preference
        name
        music_on_hold
        language
        is_reseller
        dial_plan
        descendants_count
        created
        contract
        contact
        caller_id
        call_restriction
        metaflows
        parent_id
        account_id
        _read_only
        wnm_allow_additions
        superduper_admin
        enabled
        billing_mode
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      media_object_id
      data
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateSwitchMedia = /* GraphQL */ `
  subscription OnUpdateSwitchMedia(
    $filter: ModelSubscriptionSwitchMediaFilterInput
  ) {
    onUpdateSwitchMedia(filter: $filter) {
      id
      account_id
      account {
        id
        ui_restrictions
        ui_flags
        timezone
        ringtones
        reseller_id
        realm
        preflow
        notifications
        notification_preference
        name
        music_on_hold
        language
        is_reseller
        dial_plan
        descendants_count
        created
        contract
        contact
        caller_id
        call_restriction
        metaflows
        parent_id
        account_id
        _read_only
        wnm_allow_additions
        superduper_admin
        enabled
        billing_mode
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      media_object_id
      data
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteSwitchMedia = /* GraphQL */ `
  subscription OnDeleteSwitchMedia(
    $filter: ModelSubscriptionSwitchMediaFilterInput
  ) {
    onDeleteSwitchMedia(filter: $filter) {
      id
      account_id
      account {
        id
        ui_restrictions
        ui_flags
        timezone
        ringtones
        reseller_id
        realm
        preflow
        notifications
        notification_preference
        name
        music_on_hold
        language
        is_reseller
        dial_plan
        descendants_count
        created
        contract
        contact
        caller_id
        call_restriction
        metaflows
        parent_id
        account_id
        _read_only
        wnm_allow_additions
        superduper_admin
        enabled
        billing_mode
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      media_object_id
      data
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateSwitchDevices = /* GraphQL */ `
  subscription OnCreateSwitchDevices(
    $filter: ModelSubscriptionSwitchDevicesFilterInput
  ) {
    onCreateSwitchDevices(filter: $filter) {
      id
      device_type
      enabled
      name
      sip
      owner_id
      caller_id
      register_overwrite_notify
      mwi_unsolicited_updates
      mwi_unsolicitated_updates
      exclude_from_queues
      contact_list
      call_restriction
      media
      suppress_unregister_notifications
      music_on_hold
      ringtones
      account_id
      account {
        id
        ui_restrictions
        ui_flags
        timezone
        ringtones
        reseller_id
        realm
        preflow
        notifications
        notification_preference
        name
        music_on_hold
        language
        is_reseller
        dial_plan
        descendants_count
        created
        contract
        contact
        caller_id
        call_restriction
        metaflows
        parent_id
        account_id
        _read_only
        wnm_allow_additions
        superduper_admin
        enabled
        billing_mode
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      device_id
      _read_only
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateSwitchDevices = /* GraphQL */ `
  subscription OnUpdateSwitchDevices(
    $filter: ModelSubscriptionSwitchDevicesFilterInput
  ) {
    onUpdateSwitchDevices(filter: $filter) {
      id
      device_type
      enabled
      name
      sip
      owner_id
      caller_id
      register_overwrite_notify
      mwi_unsolicited_updates
      mwi_unsolicitated_updates
      exclude_from_queues
      contact_list
      call_restriction
      media
      suppress_unregister_notifications
      music_on_hold
      ringtones
      account_id
      account {
        id
        ui_restrictions
        ui_flags
        timezone
        ringtones
        reseller_id
        realm
        preflow
        notifications
        notification_preference
        name
        music_on_hold
        language
        is_reseller
        dial_plan
        descendants_count
        created
        contract
        contact
        caller_id
        call_restriction
        metaflows
        parent_id
        account_id
        _read_only
        wnm_allow_additions
        superduper_admin
        enabled
        billing_mode
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      device_id
      _read_only
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteSwitchDevices = /* GraphQL */ `
  subscription OnDeleteSwitchDevices(
    $filter: ModelSubscriptionSwitchDevicesFilterInput
  ) {
    onDeleteSwitchDevices(filter: $filter) {
      id
      device_type
      enabled
      name
      sip
      owner_id
      caller_id
      register_overwrite_notify
      mwi_unsolicited_updates
      mwi_unsolicitated_updates
      exclude_from_queues
      contact_list
      call_restriction
      media
      suppress_unregister_notifications
      music_on_hold
      ringtones
      account_id
      account {
        id
        ui_restrictions
        ui_flags
        timezone
        ringtones
        reseller_id
        realm
        preflow
        notifications
        notification_preference
        name
        music_on_hold
        language
        is_reseller
        dial_plan
        descendants_count
        created
        contract
        contact
        caller_id
        call_restriction
        metaflows
        parent_id
        account_id
        _read_only
        wnm_allow_additions
        superduper_admin
        enabled
        billing_mode
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      device_id
      _read_only
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateSwitchConnectivity = /* GraphQL */ `
  subscription OnCreateSwitchConnectivity(
    $filter: ModelSubscriptionSwitchConnectivityFilterInput
  ) {
    onCreateSwitchConnectivity(filter: $filter) {
      id
      account
      billing_account_id
      DIDs_Unassigned
      servers
      account_id
      switch_account {
        id
        ui_restrictions
        ui_flags
        timezone
        ringtones
        reseller_id
        realm
        preflow
        notifications
        notification_preference
        name
        music_on_hold
        language
        is_reseller
        dial_plan
        descendants_count
        created
        contract
        contact
        caller_id
        call_restriction
        metaflows
        parent_id
        account_id
        _read_only
        wnm_allow_additions
        superduper_admin
        enabled
        billing_mode
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      connectivity_id
      _read_only
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateSwitchConnectivity = /* GraphQL */ `
  subscription OnUpdateSwitchConnectivity(
    $filter: ModelSubscriptionSwitchConnectivityFilterInput
  ) {
    onUpdateSwitchConnectivity(filter: $filter) {
      id
      account
      billing_account_id
      DIDs_Unassigned
      servers
      account_id
      switch_account {
        id
        ui_restrictions
        ui_flags
        timezone
        ringtones
        reseller_id
        realm
        preflow
        notifications
        notification_preference
        name
        music_on_hold
        language
        is_reseller
        dial_plan
        descendants_count
        created
        contract
        contact
        caller_id
        call_restriction
        metaflows
        parent_id
        account_id
        _read_only
        wnm_allow_additions
        superduper_admin
        enabled
        billing_mode
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      connectivity_id
      _read_only
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteSwitchConnectivity = /* GraphQL */ `
  subscription OnDeleteSwitchConnectivity(
    $filter: ModelSubscriptionSwitchConnectivityFilterInput
  ) {
    onDeleteSwitchConnectivity(filter: $filter) {
      id
      account
      billing_account_id
      DIDs_Unassigned
      servers
      account_id
      switch_account {
        id
        ui_restrictions
        ui_flags
        timezone
        ringtones
        reseller_id
        realm
        preflow
        notifications
        notification_preference
        name
        music_on_hold
        language
        is_reseller
        dial_plan
        descendants_count
        created
        contract
        contact
        caller_id
        call_restriction
        metaflows
        parent_id
        account_id
        _read_only
        wnm_allow_additions
        superduper_admin
        enabled
        billing_mode
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      connectivity_id
      _read_only
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateSwitchCallflows = /* GraphQL */ `
  subscription OnCreateSwitchCallflows(
    $filter: ModelSubscriptionSwitchCallflowsFilterInput
  ) {
    onCreateSwitchCallflows(filter: $filter) {
      id
      flow
      numbers
      patterns
      account_id
      account {
        id
        ui_restrictions
        ui_flags
        timezone
        ringtones
        reseller_id
        realm
        preflow
        notifications
        notification_preference
        name
        music_on_hold
        language
        is_reseller
        dial_plan
        descendants_count
        created
        contract
        contact
        caller_id
        call_restriction
        metaflows
        parent_id
        account_id
        _read_only
        wnm_allow_additions
        superduper_admin
        enabled
        billing_mode
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      featurecode
      contact_list
      metadata
      callflow_id
      _read_only
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateSwitchCallflows = /* GraphQL */ `
  subscription OnUpdateSwitchCallflows(
    $filter: ModelSubscriptionSwitchCallflowsFilterInput
  ) {
    onUpdateSwitchCallflows(filter: $filter) {
      id
      flow
      numbers
      patterns
      account_id
      account {
        id
        ui_restrictions
        ui_flags
        timezone
        ringtones
        reseller_id
        realm
        preflow
        notifications
        notification_preference
        name
        music_on_hold
        language
        is_reseller
        dial_plan
        descendants_count
        created
        contract
        contact
        caller_id
        call_restriction
        metaflows
        parent_id
        account_id
        _read_only
        wnm_allow_additions
        superduper_admin
        enabled
        billing_mode
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      featurecode
      contact_list
      metadata
      callflow_id
      _read_only
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteSwitchCallflows = /* GraphQL */ `
  subscription OnDeleteSwitchCallflows(
    $filter: ModelSubscriptionSwitchCallflowsFilterInput
  ) {
    onDeleteSwitchCallflows(filter: $filter) {
      id
      flow
      numbers
      patterns
      account_id
      account {
        id
        ui_restrictions
        ui_flags
        timezone
        ringtones
        reseller_id
        realm
        preflow
        notifications
        notification_preference
        name
        music_on_hold
        language
        is_reseller
        dial_plan
        descendants_count
        created
        contract
        contact
        caller_id
        call_restriction
        metaflows
        parent_id
        account_id
        _read_only
        wnm_allow_additions
        superduper_admin
        enabled
        billing_mode
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      featurecode
      contact_list
      metadata
      callflow_id
      _read_only
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateSwitchAccounts = /* GraphQL */ `
  subscription OnCreateSwitchAccounts(
    $filter: ModelSubscriptionSwitchAccountsFilterInput
  ) {
    onCreateSwitchAccounts(filter: $filter) {
      id
      ui_restrictions
      ui_flags
      timezone
      ringtones
      reseller_id
      realm
      preflow
      notifications
      notification_preference
      name
      music_on_hold
      language
      is_reseller
      dial_plan
      descendants_count
      created
      contract
      contact
      caller_id
      call_restriction
      call_flows {
        nextToken
        startedAt
        __typename
      }
      connectivity {
        nextToken
        startedAt
        __typename
      }
      devices {
        nextToken
        startedAt
        __typename
      }
      media {
        nextToken
        startedAt
        __typename
      }
      numbers {
        nextToken
        startedAt
        __typename
      }
      mail_boxes {
        nextToken
        startedAt
        __typename
      }
      users {
        nextToken
        startedAt
        __typename
      }
      metaflows
      parent_id
      account_id
      children_accounts {
        nextToken
        startedAt
        __typename
      }
      parent_account {
        nextToken
        startedAt
        __typename
      }
      _read_only
      wnm_allow_additions
      superduper_admin
      enabled
      billing_mode
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateSwitchAccounts = /* GraphQL */ `
  subscription OnUpdateSwitchAccounts(
    $filter: ModelSubscriptionSwitchAccountsFilterInput
  ) {
    onUpdateSwitchAccounts(filter: $filter) {
      id
      ui_restrictions
      ui_flags
      timezone
      ringtones
      reseller_id
      realm
      preflow
      notifications
      notification_preference
      name
      music_on_hold
      language
      is_reseller
      dial_plan
      descendants_count
      created
      contract
      contact
      caller_id
      call_restriction
      call_flows {
        nextToken
        startedAt
        __typename
      }
      connectivity {
        nextToken
        startedAt
        __typename
      }
      devices {
        nextToken
        startedAt
        __typename
      }
      media {
        nextToken
        startedAt
        __typename
      }
      numbers {
        nextToken
        startedAt
        __typename
      }
      mail_boxes {
        nextToken
        startedAt
        __typename
      }
      users {
        nextToken
        startedAt
        __typename
      }
      metaflows
      parent_id
      account_id
      children_accounts {
        nextToken
        startedAt
        __typename
      }
      parent_account {
        nextToken
        startedAt
        __typename
      }
      _read_only
      wnm_allow_additions
      superduper_admin
      enabled
      billing_mode
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteSwitchAccounts = /* GraphQL */ `
  subscription OnDeleteSwitchAccounts(
    $filter: ModelSubscriptionSwitchAccountsFilterInput
  ) {
    onDeleteSwitchAccounts(filter: $filter) {
      id
      ui_restrictions
      ui_flags
      timezone
      ringtones
      reseller_id
      realm
      preflow
      notifications
      notification_preference
      name
      music_on_hold
      language
      is_reseller
      dial_plan
      descendants_count
      created
      contract
      contact
      caller_id
      call_restriction
      call_flows {
        nextToken
        startedAt
        __typename
      }
      connectivity {
        nextToken
        startedAt
        __typename
      }
      devices {
        nextToken
        startedAt
        __typename
      }
      media {
        nextToken
        startedAt
        __typename
      }
      numbers {
        nextToken
        startedAt
        __typename
      }
      mail_boxes {
        nextToken
        startedAt
        __typename
      }
      users {
        nextToken
        startedAt
        __typename
      }
      metaflows
      parent_id
      account_id
      children_accounts {
        nextToken
        startedAt
        __typename
      }
      parent_account {
        nextToken
        startedAt
        __typename
      }
      _read_only
      wnm_allow_additions
      superduper_admin
      enabled
      billing_mode
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateRevioServices = /* GraphQL */ `
  subscription OnCreateRevioServices(
    $filter: ModelSubscriptionRevioServicesFilterInput
  ) {
    onCreateRevioServices(filter: $filter) {
      id
      service_id
      customer_id
      provider_id
      service_type_id
      package_id
      number
      pic
      lpic
      provider_account_id
      usage_plan_group_id
      number2
      description
      disconnected_date
      activated_date
      address_id
      addressz_id
      provider_account_number
      contract_start
      contract_end
      created_date
      hunt_group
      circuit_number2
      circuit_foc_date
      notes
      location_routing_number
      fields
      request_service_id
      revio_customer {
        id
        customer_id
        billing_address_id
        service_address_id
        listing_address_id
        parent_customer_id
        parent_customer_id_sort
        account_number
        activated_date
        agent_id
        secondary_agent_id
        close_date
        created_by
        created_date
        updated_date
        email
        is_parent
        registration_code
        security_pin
        sold_by
        source
        status
        auto_debit_enabled
        auto_debit_day
        auto_debit_payment_account_id
        bill_profile_id
        billing_method
        balance_limit
        balance_limit_enabled
        collection_template_id
        contract_end_date
        contract_start_date
        cycle_date
        late_fees_enabled
        payment_terms_days
        numbers
        location_id
        tax_exempt_enabled
        tax_exempt_types
        usage_file_enabled
        usage_file_format
        payment_fees_enabled
        class
        date_of_birth
        language
        tax_id
        voip_switch_id
        suspended
        suspended_date
        culture
        fields
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateRevioServices = /* GraphQL */ `
  subscription OnUpdateRevioServices(
    $filter: ModelSubscriptionRevioServicesFilterInput
  ) {
    onUpdateRevioServices(filter: $filter) {
      id
      service_id
      customer_id
      provider_id
      service_type_id
      package_id
      number
      pic
      lpic
      provider_account_id
      usage_plan_group_id
      number2
      description
      disconnected_date
      activated_date
      address_id
      addressz_id
      provider_account_number
      contract_start
      contract_end
      created_date
      hunt_group
      circuit_number2
      circuit_foc_date
      notes
      location_routing_number
      fields
      request_service_id
      revio_customer {
        id
        customer_id
        billing_address_id
        service_address_id
        listing_address_id
        parent_customer_id
        parent_customer_id_sort
        account_number
        activated_date
        agent_id
        secondary_agent_id
        close_date
        created_by
        created_date
        updated_date
        email
        is_parent
        registration_code
        security_pin
        sold_by
        source
        status
        auto_debit_enabled
        auto_debit_day
        auto_debit_payment_account_id
        bill_profile_id
        billing_method
        balance_limit
        balance_limit_enabled
        collection_template_id
        contract_end_date
        contract_start_date
        cycle_date
        late_fees_enabled
        payment_terms_days
        numbers
        location_id
        tax_exempt_enabled
        tax_exempt_types
        usage_file_enabled
        usage_file_format
        payment_fees_enabled
        class
        date_of_birth
        language
        tax_id
        voip_switch_id
        suspended
        suspended_date
        culture
        fields
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteRevioServices = /* GraphQL */ `
  subscription OnDeleteRevioServices(
    $filter: ModelSubscriptionRevioServicesFilterInput
  ) {
    onDeleteRevioServices(filter: $filter) {
      id
      service_id
      customer_id
      provider_id
      service_type_id
      package_id
      number
      pic
      lpic
      provider_account_id
      usage_plan_group_id
      number2
      description
      disconnected_date
      activated_date
      address_id
      addressz_id
      provider_account_number
      contract_start
      contract_end
      created_date
      hunt_group
      circuit_number2
      circuit_foc_date
      notes
      location_routing_number
      fields
      request_service_id
      revio_customer {
        id
        customer_id
        billing_address_id
        service_address_id
        listing_address_id
        parent_customer_id
        parent_customer_id_sort
        account_number
        activated_date
        agent_id
        secondary_agent_id
        close_date
        created_by
        created_date
        updated_date
        email
        is_parent
        registration_code
        security_pin
        sold_by
        source
        status
        auto_debit_enabled
        auto_debit_day
        auto_debit_payment_account_id
        bill_profile_id
        billing_method
        balance_limit
        balance_limit_enabled
        collection_template_id
        contract_end_date
        contract_start_date
        cycle_date
        late_fees_enabled
        payment_terms_days
        numbers
        location_id
        tax_exempt_enabled
        tax_exempt_types
        usage_file_enabled
        usage_file_format
        payment_fees_enabled
        class
        date_of_birth
        language
        tax_id
        voip_switch_id
        suspended
        suspended_date
        culture
        fields
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateRevioServiceProducts = /* GraphQL */ `
  subscription OnCreateRevioServiceProducts(
    $filter: ModelSubscriptionRevioServiceProductsFilterInput
  ) {
    onCreateRevioServiceProducts(filter: $filter) {
      id
      service_product_id
      customer_id
      product_id
      product_information {
        nextToken
        startedAt
        __typename
      }
      package_id
      service_id
      description
      code_1
      code_2
      rate
      billed_through_date
      canceled_date
      status
      status_date
      status_user_id
      activated_date
      cost
      wholesale_description
      free_start_date
      free_end_date
      quantity
      contract_start_date
      contract_end_date
      created_date
      tax_included
      group_on_bill
      itemized
      fields
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateRevioServiceProducts = /* GraphQL */ `
  subscription OnUpdateRevioServiceProducts(
    $filter: ModelSubscriptionRevioServiceProductsFilterInput
  ) {
    onUpdateRevioServiceProducts(filter: $filter) {
      id
      service_product_id
      customer_id
      product_id
      product_information {
        nextToken
        startedAt
        __typename
      }
      package_id
      service_id
      description
      code_1
      code_2
      rate
      billed_through_date
      canceled_date
      status
      status_date
      status_user_id
      activated_date
      cost
      wholesale_description
      free_start_date
      free_end_date
      quantity
      contract_start_date
      contract_end_date
      created_date
      tax_included
      group_on_bill
      itemized
      fields
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteRevioServiceProducts = /* GraphQL */ `
  subscription OnDeleteRevioServiceProducts(
    $filter: ModelSubscriptionRevioServiceProductsFilterInput
  ) {
    onDeleteRevioServiceProducts(filter: $filter) {
      id
      service_product_id
      customer_id
      product_id
      product_information {
        nextToken
        startedAt
        __typename
      }
      package_id
      service_id
      description
      code_1
      code_2
      rate
      billed_through_date
      canceled_date
      status
      status_date
      status_user_id
      activated_date
      cost
      wholesale_description
      free_start_date
      free_end_date
      quantity
      contract_start_date
      contract_end_date
      created_date
      tax_included
      group_on_bill
      itemized
      fields
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateRevioServiceInventory = /* GraphQL */ `
  subscription OnCreateRevioServiceInventory(
    $filter: ModelSubscriptionRevioServiceInventoryFilterInput
  ) {
    onCreateRevioServiceInventory(filter: $filter) {
      id
      inventory_item_id
      customer_id
      line_id
      line_type
      identifier
      inventory_type
      status
      assigned_date
      assigned_by
      note
      created_date
      user_created_by
      NPA
      NXX
      revio_customer {
        id
        customer_id
        billing_address_id
        service_address_id
        listing_address_id
        parent_customer_id
        parent_customer_id_sort
        account_number
        activated_date
        agent_id
        secondary_agent_id
        close_date
        created_by
        created_date
        updated_date
        email
        is_parent
        registration_code
        security_pin
        sold_by
        source
        status
        auto_debit_enabled
        auto_debit_day
        auto_debit_payment_account_id
        bill_profile_id
        billing_method
        balance_limit
        balance_limit_enabled
        collection_template_id
        contract_end_date
        contract_start_date
        cycle_date
        late_fees_enabled
        payment_terms_days
        numbers
        location_id
        tax_exempt_enabled
        tax_exempt_types
        usage_file_enabled
        usage_file_format
        payment_fees_enabled
        class
        date_of_birth
        language
        tax_id
        voip_switch_id
        suspended
        suspended_date
        culture
        fields
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateRevioServiceInventory = /* GraphQL */ `
  subscription OnUpdateRevioServiceInventory(
    $filter: ModelSubscriptionRevioServiceInventoryFilterInput
  ) {
    onUpdateRevioServiceInventory(filter: $filter) {
      id
      inventory_item_id
      customer_id
      line_id
      line_type
      identifier
      inventory_type
      status
      assigned_date
      assigned_by
      note
      created_date
      user_created_by
      NPA
      NXX
      revio_customer {
        id
        customer_id
        billing_address_id
        service_address_id
        listing_address_id
        parent_customer_id
        parent_customer_id_sort
        account_number
        activated_date
        agent_id
        secondary_agent_id
        close_date
        created_by
        created_date
        updated_date
        email
        is_parent
        registration_code
        security_pin
        sold_by
        source
        status
        auto_debit_enabled
        auto_debit_day
        auto_debit_payment_account_id
        bill_profile_id
        billing_method
        balance_limit
        balance_limit_enabled
        collection_template_id
        contract_end_date
        contract_start_date
        cycle_date
        late_fees_enabled
        payment_terms_days
        numbers
        location_id
        tax_exempt_enabled
        tax_exempt_types
        usage_file_enabled
        usage_file_format
        payment_fees_enabled
        class
        date_of_birth
        language
        tax_id
        voip_switch_id
        suspended
        suspended_date
        culture
        fields
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteRevioServiceInventory = /* GraphQL */ `
  subscription OnDeleteRevioServiceInventory(
    $filter: ModelSubscriptionRevioServiceInventoryFilterInput
  ) {
    onDeleteRevioServiceInventory(filter: $filter) {
      id
      inventory_item_id
      customer_id
      line_id
      line_type
      identifier
      inventory_type
      status
      assigned_date
      assigned_by
      note
      created_date
      user_created_by
      NPA
      NXX
      revio_customer {
        id
        customer_id
        billing_address_id
        service_address_id
        listing_address_id
        parent_customer_id
        parent_customer_id_sort
        account_number
        activated_date
        agent_id
        secondary_agent_id
        close_date
        created_by
        created_date
        updated_date
        email
        is_parent
        registration_code
        security_pin
        sold_by
        source
        status
        auto_debit_enabled
        auto_debit_day
        auto_debit_payment_account_id
        bill_profile_id
        billing_method
        balance_limit
        balance_limit_enabled
        collection_template_id
        contract_end_date
        contract_start_date
        cycle_date
        late_fees_enabled
        payment_terms_days
        numbers
        location_id
        tax_exempt_enabled
        tax_exempt_types
        usage_file_enabled
        usage_file_format
        payment_fees_enabled
        class
        date_of_birth
        language
        tax_id
        voip_switch_id
        suspended
        suspended_date
        culture
        fields
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateRevioProductTypes = /* GraphQL */ `
  subscription OnCreateRevioProductTypes(
    $filter: ModelSubscriptionRevioProductTypesFilterInput
  ) {
    onCreateRevioProductTypes(filter: $filter) {
      id
      product_type_id
      product_type_code
      description
      tax_class_id
      active
      one_time_flag
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateRevioProductTypes = /* GraphQL */ `
  subscription OnUpdateRevioProductTypes(
    $filter: ModelSubscriptionRevioProductTypesFilterInput
  ) {
    onUpdateRevioProductTypes(filter: $filter) {
      id
      product_type_id
      product_type_code
      description
      tax_class_id
      active
      one_time_flag
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteRevioProductTypes = /* GraphQL */ `
  subscription OnDeleteRevioProductTypes(
    $filter: ModelSubscriptionRevioProductTypesFilterInput
  ) {
    onDeleteRevioProductTypes(filter: $filter) {
      id
      product_type_id
      product_type_code
      description
      tax_class_id
      active
      one_time_flag
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateRevioProducts = /* GraphQL */ `
  subscription OnCreateRevioProducts(
    $filter: ModelSubscriptionRevioProductsFilterInput
  ) {
    onCreateRevioProducts(filter: $filter) {
      id
      currency_code
      product_id
      product_type_id
      product_type {
        nextToken
        startedAt
        __typename
      }
      description
      code_1
      code_2
      rate
      cost
      buy_rate
      created_date
      created_by
      active
      creates_order
      provider_id
      bills_in_arrears
      prorates
      prorates_on_disconnect
      customer_class
      long_description
      ledger_code
      free_months
      automatic_expiration_months
      order_completion_billing
      tax_class_id
      tax_code {
        nextToken
        startedAt
        __typename
      }
      wholesale_description
      smart_pricing_id
      bill_profile_ids
      service_type_ids
      fields
      category_id
      is_credit_account_type
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateRevioProducts = /* GraphQL */ `
  subscription OnUpdateRevioProducts(
    $filter: ModelSubscriptionRevioProductsFilterInput
  ) {
    onUpdateRevioProducts(filter: $filter) {
      id
      currency_code
      product_id
      product_type_id
      product_type {
        nextToken
        startedAt
        __typename
      }
      description
      code_1
      code_2
      rate
      cost
      buy_rate
      created_date
      created_by
      active
      creates_order
      provider_id
      bills_in_arrears
      prorates
      prorates_on_disconnect
      customer_class
      long_description
      ledger_code
      free_months
      automatic_expiration_months
      order_completion_billing
      tax_class_id
      tax_code {
        nextToken
        startedAt
        __typename
      }
      wholesale_description
      smart_pricing_id
      bill_profile_ids
      service_type_ids
      fields
      category_id
      is_credit_account_type
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteRevioProducts = /* GraphQL */ `
  subscription OnDeleteRevioProducts(
    $filter: ModelSubscriptionRevioProductsFilterInput
  ) {
    onDeleteRevioProducts(filter: $filter) {
      id
      currency_code
      product_id
      product_type_id
      product_type {
        nextToken
        startedAt
        __typename
      }
      description
      code_1
      code_2
      rate
      cost
      buy_rate
      created_date
      created_by
      active
      creates_order
      provider_id
      bills_in_arrears
      prorates
      prorates_on_disconnect
      customer_class
      long_description
      ledger_code
      free_months
      automatic_expiration_months
      order_completion_billing
      tax_class_id
      tax_code {
        nextToken
        startedAt
        __typename
      }
      wholesale_description
      smart_pricing_id
      bill_profile_ids
      service_type_ids
      fields
      category_id
      is_credit_account_type
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateRevioInventoryTypes = /* GraphQL */ `
  subscription OnCreateRevioInventoryTypes(
    $filter: ModelSubscriptionRevioInventoryTypesFilterInput
  ) {
    onCreateRevioInventoryTypes(filter: $filter) {
      id
      inventory_type_id
      category
      identifier
      name
      requires_product
      description
      status
      format
      ratable
      fields
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateRevioInventoryTypes = /* GraphQL */ `
  subscription OnUpdateRevioInventoryTypes(
    $filter: ModelSubscriptionRevioInventoryTypesFilterInput
  ) {
    onUpdateRevioInventoryTypes(filter: $filter) {
      id
      inventory_type_id
      category
      identifier
      name
      requires_product
      description
      status
      format
      ratable
      fields
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteRevioInventoryTypes = /* GraphQL */ `
  subscription OnDeleteRevioInventoryTypes(
    $filter: ModelSubscriptionRevioInventoryTypesFilterInput
  ) {
    onDeleteRevioInventoryTypes(filter: $filter) {
      id
      inventory_type_id
      category
      identifier
      name
      requires_product
      description
      status
      format
      ratable
      fields
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateRevioDialPrefixRates = /* GraphQL */ `
  subscription OnCreateRevioDialPrefixRates(
    $filter: ModelSubscriptionRevioDialPrefixRatesFilterInput
  ) {
    onCreateRevioDialPrefixRates(filter: $filter) {
      id
      country_id
      country
      dial_prefix
      retail_rate
      wholesale_rate
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateRevioDialPrefixRates = /* GraphQL */ `
  subscription OnUpdateRevioDialPrefixRates(
    $filter: ModelSubscriptionRevioDialPrefixRatesFilterInput
  ) {
    onUpdateRevioDialPrefixRates(filter: $filter) {
      id
      country_id
      country
      dial_prefix
      retail_rate
      wholesale_rate
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteRevioDialPrefixRates = /* GraphQL */ `
  subscription OnDeleteRevioDialPrefixRates(
    $filter: ModelSubscriptionRevioDialPrefixRatesFilterInput
  ) {
    onDeleteRevioDialPrefixRates(filter: $filter) {
      id
      country_id
      country
      dial_prefix
      retail_rate
      wholesale_rate
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateRevioInventoryItems = /* GraphQL */ `
  subscription OnCreateRevioInventoryItems(
    $filter: ModelSubscriptionRevioInventoryItemsFilterInput
  ) {
    onCreateRevioInventoryItems(filter: $filter) {
      id
      inventory_item_id
      inventory_item_type_id
      inventory_type {
        nextToken
        startedAt
        __typename
      }
      identifier
      customer_id
      created_date
      created_by
      status
      inventory_item_unavailable_reason_id
      note
      fields
      revio_customer {
        id
        customer_id
        billing_address_id
        service_address_id
        listing_address_id
        parent_customer_id
        parent_customer_id_sort
        account_number
        activated_date
        agent_id
        secondary_agent_id
        close_date
        created_by
        created_date
        updated_date
        email
        is_parent
        registration_code
        security_pin
        sold_by
        source
        status
        auto_debit_enabled
        auto_debit_day
        auto_debit_payment_account_id
        bill_profile_id
        billing_method
        balance_limit
        balance_limit_enabled
        collection_template_id
        contract_end_date
        contract_start_date
        cycle_date
        late_fees_enabled
        payment_terms_days
        numbers
        location_id
        tax_exempt_enabled
        tax_exempt_types
        usage_file_enabled
        usage_file_format
        payment_fees_enabled
        class
        date_of_birth
        language
        tax_id
        voip_switch_id
        suspended
        suspended_date
        culture
        fields
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateRevioInventoryItems = /* GraphQL */ `
  subscription OnUpdateRevioInventoryItems(
    $filter: ModelSubscriptionRevioInventoryItemsFilterInput
  ) {
    onUpdateRevioInventoryItems(filter: $filter) {
      id
      inventory_item_id
      inventory_item_type_id
      inventory_type {
        nextToken
        startedAt
        __typename
      }
      identifier
      customer_id
      created_date
      created_by
      status
      inventory_item_unavailable_reason_id
      note
      fields
      revio_customer {
        id
        customer_id
        billing_address_id
        service_address_id
        listing_address_id
        parent_customer_id
        parent_customer_id_sort
        account_number
        activated_date
        agent_id
        secondary_agent_id
        close_date
        created_by
        created_date
        updated_date
        email
        is_parent
        registration_code
        security_pin
        sold_by
        source
        status
        auto_debit_enabled
        auto_debit_day
        auto_debit_payment_account_id
        bill_profile_id
        billing_method
        balance_limit
        balance_limit_enabled
        collection_template_id
        contract_end_date
        contract_start_date
        cycle_date
        late_fees_enabled
        payment_terms_days
        numbers
        location_id
        tax_exempt_enabled
        tax_exempt_types
        usage_file_enabled
        usage_file_format
        payment_fees_enabled
        class
        date_of_birth
        language
        tax_id
        voip_switch_id
        suspended
        suspended_date
        culture
        fields
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteRevioInventoryItems = /* GraphQL */ `
  subscription OnDeleteRevioInventoryItems(
    $filter: ModelSubscriptionRevioInventoryItemsFilterInput
  ) {
    onDeleteRevioInventoryItems(filter: $filter) {
      id
      inventory_item_id
      inventory_item_type_id
      inventory_type {
        nextToken
        startedAt
        __typename
      }
      identifier
      customer_id
      created_date
      created_by
      status
      inventory_item_unavailable_reason_id
      note
      fields
      revio_customer {
        id
        customer_id
        billing_address_id
        service_address_id
        listing_address_id
        parent_customer_id
        parent_customer_id_sort
        account_number
        activated_date
        agent_id
        secondary_agent_id
        close_date
        created_by
        created_date
        updated_date
        email
        is_parent
        registration_code
        security_pin
        sold_by
        source
        status
        auto_debit_enabled
        auto_debit_day
        auto_debit_payment_account_id
        bill_profile_id
        billing_method
        balance_limit
        balance_limit_enabled
        collection_template_id
        contract_end_date
        contract_start_date
        cycle_date
        late_fees_enabled
        payment_terms_days
        numbers
        location_id
        tax_exempt_enabled
        tax_exempt_types
        usage_file_enabled
        usage_file_format
        payment_fees_enabled
        class
        date_of_birth
        language
        tax_id
        voip_switch_id
        suspended
        suspended_date
        culture
        fields
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateRevioCharges = /* GraphQL */ `
  subscription OnCreateRevioCharges(
    $filter: ModelSubscriptionRevioChargesFilterInput
  ) {
    onCreateRevioCharges(filter: $filter) {
      id
      charge_id
      product_id
      product_type_id
      customer_id
      bill_id
      service_id
      service_product_id
      is_tax_included
      tax_class_id
      description
      amount
      cost
      product_amount
      is_commision_exempted
      tax_amount
      geocode
      proration
      created_date
      created_by
      is_prorated
      start_date
      end_date
      rate
      quantity
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateRevioCharges = /* GraphQL */ `
  subscription OnUpdateRevioCharges(
    $filter: ModelSubscriptionRevioChargesFilterInput
  ) {
    onUpdateRevioCharges(filter: $filter) {
      id
      charge_id
      product_id
      product_type_id
      customer_id
      bill_id
      service_id
      service_product_id
      is_tax_included
      tax_class_id
      description
      amount
      cost
      product_amount
      is_commision_exempted
      tax_amount
      geocode
      proration
      created_date
      created_by
      is_prorated
      start_date
      end_date
      rate
      quantity
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteRevioCharges = /* GraphQL */ `
  subscription OnDeleteRevioCharges(
    $filter: ModelSubscriptionRevioChargesFilterInput
  ) {
    onDeleteRevioCharges(filter: $filter) {
      id
      charge_id
      product_id
      product_type_id
      customer_id
      bill_id
      service_id
      service_product_id
      is_tax_included
      tax_class_id
      description
      amount
      cost
      product_amount
      is_commision_exempted
      tax_amount
      geocode
      proration
      created_date
      created_by
      is_prorated
      start_date
      end_date
      rate
      quantity
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateCustomerSupportInformation = /* GraphQL */ `
  subscription OnCreateCustomerSupportInformation(
    $filter: ModelSubscriptionCustomerSupportInformationFilterInput
  ) {
    onCreateCustomerSupportInformation(filter: $filter) {
      id
      partner
      partner_information {
        nextToken
        startedAt
        __typename
      }
      external_id
      service_type
      service_status
      electric_serviceman_number
      electric_sub_feeder
      electric_email_ticket
      contact_phone_number
      customer_type
      name
      address
      city
      state
      zip
      email
      is_les
      location_id
      service_package_info
      connection_date
      revio_id
      revio_customer {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateCustomerSupportInformation = /* GraphQL */ `
  subscription OnUpdateCustomerSupportInformation(
    $filter: ModelSubscriptionCustomerSupportInformationFilterInput
  ) {
    onUpdateCustomerSupportInformation(filter: $filter) {
      id
      partner
      partner_information {
        nextToken
        startedAt
        __typename
      }
      external_id
      service_type
      service_status
      electric_serviceman_number
      electric_sub_feeder
      electric_email_ticket
      contact_phone_number
      customer_type
      name
      address
      city
      state
      zip
      email
      is_les
      location_id
      service_package_info
      connection_date
      revio_id
      revio_customer {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteCustomerSupportInformation = /* GraphQL */ `
  subscription OnDeleteCustomerSupportInformation(
    $filter: ModelSubscriptionCustomerSupportInformationFilterInput
  ) {
    onDeleteCustomerSupportInformation(filter: $filter) {
      id
      partner
      partner_information {
        nextToken
        startedAt
        __typename
      }
      external_id
      service_type
      service_status
      electric_serviceman_number
      electric_sub_feeder
      electric_email_ticket
      contact_phone_number
      customer_type
      name
      address
      city
      state
      zip
      email
      is_les
      location_id
      service_package_info
      connection_date
      revio_id
      revio_customer {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateRevioCustomers = /* GraphQL */ `
  subscription OnCreateRevioCustomers(
    $filter: ModelSubscriptionRevioCustomersFilterInput
  ) {
    onCreateRevioCustomers(filter: $filter) {
      id
      customer_id
      billing_address_id
      service_address_id
      listing_address_id
      billing_address {
        nextToken
        startedAt
        __typename
      }
      service_address {
        nextToken
        startedAt
        __typename
      }
      listing_address {
        nextToken
        startedAt
        __typename
      }
      parent_customer_id
      parent_customer_id_sort
      account_number
      activated_date
      agent_id
      secondary_agent_id
      close_date
      created_by
      created_date
      updated_date
      email
      is_parent
      registration_code
      security_pin
      sold_by
      bills {
        nextToken
        startedAt
        __typename
      }
      inventory_items {
        nextToken
        startedAt
        __typename
      }
      services {
        nextToken
        startedAt
        __typename
      }
      service_inventory {
        nextToken
        startedAt
        __typename
      }
      service_products {
        nextToken
        startedAt
        __typename
      }
      source
      status
      auto_debit_enabled
      auto_debit_day
      auto_debit_payment_account_id
      bill_profile_id
      billing_method
      balance_limit
      balance_limit_enabled
      collection_template_id
      contract_end_date
      contract_start_date
      cycle_date
      late_fees_enabled
      payment_terms_days
      numbers
      location_id
      tax_exempt_enabled
      tax_exempt_types
      usage_file_enabled
      usage_file_format
      payment_fees_enabled
      class
      date_of_birth
      language
      tax_id
      voip_switch_id
      suspended
      suspended_date
      culture
      fields
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateRevioCustomers = /* GraphQL */ `
  subscription OnUpdateRevioCustomers(
    $filter: ModelSubscriptionRevioCustomersFilterInput
  ) {
    onUpdateRevioCustomers(filter: $filter) {
      id
      customer_id
      billing_address_id
      service_address_id
      listing_address_id
      billing_address {
        nextToken
        startedAt
        __typename
      }
      service_address {
        nextToken
        startedAt
        __typename
      }
      listing_address {
        nextToken
        startedAt
        __typename
      }
      parent_customer_id
      parent_customer_id_sort
      account_number
      activated_date
      agent_id
      secondary_agent_id
      close_date
      created_by
      created_date
      updated_date
      email
      is_parent
      registration_code
      security_pin
      sold_by
      bills {
        nextToken
        startedAt
        __typename
      }
      inventory_items {
        nextToken
        startedAt
        __typename
      }
      services {
        nextToken
        startedAt
        __typename
      }
      service_inventory {
        nextToken
        startedAt
        __typename
      }
      service_products {
        nextToken
        startedAt
        __typename
      }
      source
      status
      auto_debit_enabled
      auto_debit_day
      auto_debit_payment_account_id
      bill_profile_id
      billing_method
      balance_limit
      balance_limit_enabled
      collection_template_id
      contract_end_date
      contract_start_date
      cycle_date
      late_fees_enabled
      payment_terms_days
      numbers
      location_id
      tax_exempt_enabled
      tax_exempt_types
      usage_file_enabled
      usage_file_format
      payment_fees_enabled
      class
      date_of_birth
      language
      tax_id
      voip_switch_id
      suspended
      suspended_date
      culture
      fields
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteRevioCustomers = /* GraphQL */ `
  subscription OnDeleteRevioCustomers(
    $filter: ModelSubscriptionRevioCustomersFilterInput
  ) {
    onDeleteRevioCustomers(filter: $filter) {
      id
      customer_id
      billing_address_id
      service_address_id
      listing_address_id
      billing_address {
        nextToken
        startedAt
        __typename
      }
      service_address {
        nextToken
        startedAt
        __typename
      }
      listing_address {
        nextToken
        startedAt
        __typename
      }
      parent_customer_id
      parent_customer_id_sort
      account_number
      activated_date
      agent_id
      secondary_agent_id
      close_date
      created_by
      created_date
      updated_date
      email
      is_parent
      registration_code
      security_pin
      sold_by
      bills {
        nextToken
        startedAt
        __typename
      }
      inventory_items {
        nextToken
        startedAt
        __typename
      }
      services {
        nextToken
        startedAt
        __typename
      }
      service_inventory {
        nextToken
        startedAt
        __typename
      }
      service_products {
        nextToken
        startedAt
        __typename
      }
      source
      status
      auto_debit_enabled
      auto_debit_day
      auto_debit_payment_account_id
      bill_profile_id
      billing_method
      balance_limit
      balance_limit_enabled
      collection_template_id
      contract_end_date
      contract_start_date
      cycle_date
      late_fees_enabled
      payment_terms_days
      numbers
      location_id
      tax_exempt_enabled
      tax_exempt_types
      usage_file_enabled
      usage_file_format
      payment_fees_enabled
      class
      date_of_birth
      language
      tax_id
      voip_switch_id
      suspended
      suspended_date
      culture
      fields
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateRevioBills = /* GraphQL */ `
  subscription OnCreateRevioBills(
    $filter: ModelSubscriptionRevioBillsFilterInput
  ) {
    onCreateRevioBills(filter: $filter) {
      id
      bill_id
      parent_bill_id
      bill_type
      print_batch_id
      customer_id
      due_date
      created_date
      amount_due
      invoice_amount
      credit_memo_amount
      print_queue_flag
      printed_date
      generate_downloads
      late_fee_applied_flag
      first_month_flag
      ebpp_queue_flag
      ebpp_sent_date
      itemize_local
      is_final_bill
      note
      bill_print_type_id
      isLive
      cycle_data
      pdf_only_flag
      print_profile_id
      ready_for_pdf_flag
      bill_create_batch_id
      pdf_file_id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateRevioBills = /* GraphQL */ `
  subscription OnUpdateRevioBills(
    $filter: ModelSubscriptionRevioBillsFilterInput
  ) {
    onUpdateRevioBills(filter: $filter) {
      id
      bill_id
      parent_bill_id
      bill_type
      print_batch_id
      customer_id
      due_date
      created_date
      amount_due
      invoice_amount
      credit_memo_amount
      print_queue_flag
      printed_date
      generate_downloads
      late_fee_applied_flag
      first_month_flag
      ebpp_queue_flag
      ebpp_sent_date
      itemize_local
      is_final_bill
      note
      bill_print_type_id
      isLive
      cycle_data
      pdf_only_flag
      print_profile_id
      ready_for_pdf_flag
      bill_create_batch_id
      pdf_file_id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteRevioBills = /* GraphQL */ `
  subscription OnDeleteRevioBills(
    $filter: ModelSubscriptionRevioBillsFilterInput
  ) {
    onDeleteRevioBills(filter: $filter) {
      id
      bill_id
      parent_bill_id
      bill_type
      print_batch_id
      customer_id
      due_date
      created_date
      amount_due
      invoice_amount
      credit_memo_amount
      print_queue_flag
      printed_date
      generate_downloads
      late_fee_applied_flag
      first_month_flag
      ebpp_queue_flag
      ebpp_sent_date
      itemize_local
      is_final_bill
      note
      bill_print_type_id
      isLive
      cycle_data
      pdf_only_flag
      print_profile_id
      ready_for_pdf_flag
      bill_create_batch_id
      pdf_file_id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateRevioBillProfiles = /* GraphQL */ `
  subscription OnCreateRevioBillProfiles(
    $filter: ModelSubscriptionRevioBillProfilesFilterInput
  ) {
    onCreateRevioBillProfiles(filter: $filter) {
      id
      bill_profile_id
      description
      lead_days
      advance_months
      term_days
      term_months
      cycle_type
      cycle_direction
      active
      currency_code
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateRevioBillProfiles = /* GraphQL */ `
  subscription OnUpdateRevioBillProfiles(
    $filter: ModelSubscriptionRevioBillProfilesFilterInput
  ) {
    onUpdateRevioBillProfiles(filter: $filter) {
      id
      bill_profile_id
      description
      lead_days
      advance_months
      term_days
      term_months
      cycle_type
      cycle_direction
      active
      currency_code
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteRevioBillProfiles = /* GraphQL */ `
  subscription OnDeleteRevioBillProfiles(
    $filter: ModelSubscriptionRevioBillProfilesFilterInput
  ) {
    onDeleteRevioBillProfiles(filter: $filter) {
      id
      bill_profile_id
      description
      lead_days
      advance_months
      term_days
      term_months
      cycle_type
      cycle_direction
      active
      currency_code
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateRevioAddresses = /* GraphQL */ `
  subscription OnCreateRevioAddresses(
    $filter: ModelSubscriptionRevioAddressesFilterInput
  ) {
    onCreateRevioAddresses(filter: $filter) {
      id
      address_id
      customer_id
      first_name
      middle_initial
      last_name
      company_name
      line_1
      line_2
      city
      state_or_province
      postal_code
      postal_code_extension
      country_code
      created_date
      modified_date
      is_provider_validated
      is_USPS_validated
      revio_customer {
        id
        customer_id
        billing_address_id
        service_address_id
        listing_address_id
        parent_customer_id
        parent_customer_id_sort
        account_number
        activated_date
        agent_id
        secondary_agent_id
        close_date
        created_by
        created_date
        updated_date
        email
        is_parent
        registration_code
        security_pin
        sold_by
        source
        status
        auto_debit_enabled
        auto_debit_day
        auto_debit_payment_account_id
        bill_profile_id
        billing_method
        balance_limit
        balance_limit_enabled
        collection_template_id
        contract_end_date
        contract_start_date
        cycle_date
        late_fees_enabled
        payment_terms_days
        numbers
        location_id
        tax_exempt_enabled
        tax_exempt_types
        usage_file_enabled
        usage_file_format
        payment_fees_enabled
        class
        date_of_birth
        language
        tax_id
        voip_switch_id
        suspended
        suspended_date
        culture
        fields
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateRevioAddresses = /* GraphQL */ `
  subscription OnUpdateRevioAddresses(
    $filter: ModelSubscriptionRevioAddressesFilterInput
  ) {
    onUpdateRevioAddresses(filter: $filter) {
      id
      address_id
      customer_id
      first_name
      middle_initial
      last_name
      company_name
      line_1
      line_2
      city
      state_or_province
      postal_code
      postal_code_extension
      country_code
      created_date
      modified_date
      is_provider_validated
      is_USPS_validated
      revio_customer {
        id
        customer_id
        billing_address_id
        service_address_id
        listing_address_id
        parent_customer_id
        parent_customer_id_sort
        account_number
        activated_date
        agent_id
        secondary_agent_id
        close_date
        created_by
        created_date
        updated_date
        email
        is_parent
        registration_code
        security_pin
        sold_by
        source
        status
        auto_debit_enabled
        auto_debit_day
        auto_debit_payment_account_id
        bill_profile_id
        billing_method
        balance_limit
        balance_limit_enabled
        collection_template_id
        contract_end_date
        contract_start_date
        cycle_date
        late_fees_enabled
        payment_terms_days
        numbers
        location_id
        tax_exempt_enabled
        tax_exempt_types
        usage_file_enabled
        usage_file_format
        payment_fees_enabled
        class
        date_of_birth
        language
        tax_id
        voip_switch_id
        suspended
        suspended_date
        culture
        fields
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteRevioAddresses = /* GraphQL */ `
  subscription OnDeleteRevioAddresses(
    $filter: ModelSubscriptionRevioAddressesFilterInput
  ) {
    onDeleteRevioAddresses(filter: $filter) {
      id
      address_id
      customer_id
      first_name
      middle_initial
      last_name
      company_name
      line_1
      line_2
      city
      state_or_province
      postal_code
      postal_code_extension
      country_code
      created_date
      modified_date
      is_provider_validated
      is_USPS_validated
      revio_customer {
        id
        customer_id
        billing_address_id
        service_address_id
        listing_address_id
        parent_customer_id
        parent_customer_id_sort
        account_number
        activated_date
        agent_id
        secondary_agent_id
        close_date
        created_by
        created_date
        updated_date
        email
        is_parent
        registration_code
        security_pin
        sold_by
        source
        status
        auto_debit_enabled
        auto_debit_day
        auto_debit_payment_account_id
        bill_profile_id
        billing_method
        balance_limit
        balance_limit_enabled
        collection_template_id
        contract_end_date
        contract_start_date
        cycle_date
        late_fees_enabled
        payment_terms_days
        numbers
        location_id
        tax_exempt_enabled
        tax_exempt_types
        usage_file_enabled
        usage_file_format
        payment_fees_enabled
        class
        date_of_birth
        language
        tax_id
        voip_switch_id
        suspended
        suspended_date
        culture
        fields
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateRevioTaxCodes = /* GraphQL */ `
  subscription OnCreateRevioTaxCodes(
    $filter: ModelSubscriptionRevioTaxCodesFilterInput
  ) {
    onCreateRevioTaxCodes(filter: $filter) {
      id
      tax_class_id
      tax_code
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateRevioTaxCodes = /* GraphQL */ `
  subscription OnUpdateRevioTaxCodes(
    $filter: ModelSubscriptionRevioTaxCodesFilterInput
  ) {
    onUpdateRevioTaxCodes(filter: $filter) {
      id
      tax_class_id
      tax_code
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteRevioTaxCodes = /* GraphQL */ `
  subscription OnDeleteRevioTaxCodes(
    $filter: ModelSubscriptionRevioTaxCodesFilterInput
  ) {
    onDeleteRevioTaxCodes(filter: $filter) {
      id
      tax_class_id
      tax_code
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateXMLRequests = /* GraphQL */ `
  subscription OnCreateXMLRequests(
    $filter: ModelSubscriptionXMLRequestsFilterInput
  ) {
    onCreateXMLRequests(filter: $filter) {
      id
      customerName
      account_number
      accountStatus
      name
      first_name
      last_name
      address1
      city
      state
      zip
      telephone
      servDesc
      DataPackage
      email
      custType
      E911Address
      E911City
      E911State
      E911Zip
      VoicePackages
      VideoPackage
      FaxPackage
      monthlyRate
      new_phone1
      ported1
      sipPassword1
      numLines
      DIDs
      DIDdetail
      SONbr
      msg_type
      CallerIDName
      new_phone2
      ported2
      sipPassword2
      features
      CO_Name
      provisioned
      in_process
      errors
      PIN
      isManagedService
      created_on
      billingFlag
      taxFlag
      accessSFP
      billable
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateXMLRequests = /* GraphQL */ `
  subscription OnUpdateXMLRequests(
    $filter: ModelSubscriptionXMLRequestsFilterInput
  ) {
    onUpdateXMLRequests(filter: $filter) {
      id
      customerName
      account_number
      accountStatus
      name
      first_name
      last_name
      address1
      city
      state
      zip
      telephone
      servDesc
      DataPackage
      email
      custType
      E911Address
      E911City
      E911State
      E911Zip
      VoicePackages
      VideoPackage
      FaxPackage
      monthlyRate
      new_phone1
      ported1
      sipPassword1
      numLines
      DIDs
      DIDdetail
      SONbr
      msg_type
      CallerIDName
      new_phone2
      ported2
      sipPassword2
      features
      CO_Name
      provisioned
      in_process
      errors
      PIN
      isManagedService
      created_on
      billingFlag
      taxFlag
      accessSFP
      billable
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteXMLRequests = /* GraphQL */ `
  subscription OnDeleteXMLRequests(
    $filter: ModelSubscriptionXMLRequestsFilterInput
  ) {
    onDeleteXMLRequests(filter: $filter) {
      id
      customerName
      account_number
      accountStatus
      name
      first_name
      last_name
      address1
      city
      state
      zip
      telephone
      servDesc
      DataPackage
      email
      custType
      E911Address
      E911City
      E911State
      E911Zip
      VoicePackages
      VideoPackage
      FaxPackage
      monthlyRate
      new_phone1
      ported1
      sipPassword1
      numLines
      DIDs
      DIDdetail
      SONbr
      msg_type
      CallerIDName
      new_phone2
      ported2
      sipPassword2
      features
      CO_Name
      provisioned
      in_process
      errors
      PIN
      isManagedService
      created_on
      billingFlag
      taxFlag
      accessSFP
      billable
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateQueues = /* GraphQL */ `
  subscription OnCreateQueues($filter: ModelSubscriptionQueuesFilterInput) {
    onCreateQueues(filter: $filter) {
      id
      queue
      queue_name
      switch_queue_name
      contact_information
      sign_off_information
      flow_information
      overflow_textbox_information
      ticket_subject_values
      queue_cid_spoof_number
      fc_overflow_number
      tools_list
      allowed_groups
      allowed_partners
      tickets {
        nextToken
        startedAt
        __typename
      }
      custom_fields {
        nextToken
        startedAt
        __typename
      }
      rt_queue {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateQueues = /* GraphQL */ `
  subscription OnUpdateQueues($filter: ModelSubscriptionQueuesFilterInput) {
    onUpdateQueues(filter: $filter) {
      id
      queue
      queue_name
      switch_queue_name
      contact_information
      sign_off_information
      flow_information
      overflow_textbox_information
      ticket_subject_values
      queue_cid_spoof_number
      fc_overflow_number
      tools_list
      allowed_groups
      allowed_partners
      tickets {
        nextToken
        startedAt
        __typename
      }
      custom_fields {
        nextToken
        startedAt
        __typename
      }
      rt_queue {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteQueues = /* GraphQL */ `
  subscription OnDeleteQueues($filter: ModelSubscriptionQueuesFilterInput) {
    onDeleteQueues(filter: $filter) {
      id
      queue
      queue_name
      switch_queue_name
      contact_information
      sign_off_information
      flow_information
      overflow_textbox_information
      ticket_subject_values
      queue_cid_spoof_number
      fc_overflow_number
      tools_list
      allowed_groups
      allowed_partners
      tickets {
        nextToken
        startedAt
        __typename
      }
      custom_fields {
        nextToken
        startedAt
        __typename
      }
      rt_queue {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateRTQueue = /* GraphQL */ `
  subscription OnCreateRTQueue($filter: ModelSubscriptionRTQueueFilterInput) {
    onCreateRTQueue(filter: $filter) {
      id
      queue_id
      queue_name
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateRTQueue = /* GraphQL */ `
  subscription OnUpdateRTQueue($filter: ModelSubscriptionRTQueueFilterInput) {
    onUpdateRTQueue(filter: $filter) {
      id
      queue_id
      queue_name
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteRTQueue = /* GraphQL */ `
  subscription OnDeleteRTQueue($filter: ModelSubscriptionRTQueueFilterInput) {
    onDeleteRTQueue(filter: $filter) {
      id
      queue_id
      queue_name
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateQueueCustomFields = /* GraphQL */ `
  subscription OnCreateQueueCustomFields(
    $filter: ModelSubscriptionQueueCustomFieldsFilterInput
  ) {
    onCreateQueueCustomFields(filter: $filter) {
      id
      queue_id
      default_custom_field_id
      default_custom_field {
        id
        field_name
        field_type
        field_values
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      ticket_custom_fields {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateQueueCustomFields = /* GraphQL */ `
  subscription OnUpdateQueueCustomFields(
    $filter: ModelSubscriptionQueueCustomFieldsFilterInput
  ) {
    onUpdateQueueCustomFields(filter: $filter) {
      id
      queue_id
      default_custom_field_id
      default_custom_field {
        id
        field_name
        field_type
        field_values
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      ticket_custom_fields {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteQueueCustomFields = /* GraphQL */ `
  subscription OnDeleteQueueCustomFields(
    $filter: ModelSubscriptionQueueCustomFieldsFilterInput
  ) {
    onDeleteQueueCustomFields(filter: $filter) {
      id
      queue_id
      default_custom_field_id
      default_custom_field {
        id
        field_name
        field_type
        field_values
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      ticket_custom_fields {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateDefaultCustomFields = /* GraphQL */ `
  subscription OnCreateDefaultCustomFields(
    $filter: ModelSubscriptionDefaultCustomFieldsFilterInput
  ) {
    onCreateDefaultCustomFields(filter: $filter) {
      id
      queue_custom_fields {
        nextToken
        startedAt
        __typename
      }
      field_name
      field_type
      field_values
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateDefaultCustomFields = /* GraphQL */ `
  subscription OnUpdateDefaultCustomFields(
    $filter: ModelSubscriptionDefaultCustomFieldsFilterInput
  ) {
    onUpdateDefaultCustomFields(filter: $filter) {
      id
      queue_custom_fields {
        nextToken
        startedAt
        __typename
      }
      field_name
      field_type
      field_values
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteDefaultCustomFields = /* GraphQL */ `
  subscription OnDeleteDefaultCustomFields(
    $filter: ModelSubscriptionDefaultCustomFieldsFilterInput
  ) {
    onDeleteDefaultCustomFields(filter: $filter) {
      id
      queue_custom_fields {
        nextToken
        startedAt
        __typename
      }
      field_name
      field_type
      field_values
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateTicketCustomFields = /* GraphQL */ `
  subscription OnCreateTicketCustomFields(
    $filter: ModelSubscriptionTicketCustomFieldsFilterInput
  ) {
    onCreateTicketCustomFields(filter: $filter) {
      id
      ticket_id
      queue_custom_field_id
      queue_custom_field {
        id
        queue_id
        default_custom_field_id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      set_value
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateTicketCustomFields = /* GraphQL */ `
  subscription OnUpdateTicketCustomFields(
    $filter: ModelSubscriptionTicketCustomFieldsFilterInput
  ) {
    onUpdateTicketCustomFields(filter: $filter) {
      id
      ticket_id
      queue_custom_field_id
      queue_custom_field {
        id
        queue_id
        default_custom_field_id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      set_value
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteTicketCustomFields = /* GraphQL */ `
  subscription OnDeleteTicketCustomFields(
    $filter: ModelSubscriptionTicketCustomFieldsFilterInput
  ) {
    onDeleteTicketCustomFields(filter: $filter) {
      id
      ticket_id
      queue_custom_field_id
      queue_custom_field {
        id
        queue_id
        default_custom_field_id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      set_value
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateTickets = /* GraphQL */ `
  subscription OnCreateTickets($filter: ModelSubscriptionTicketsFilterInput) {
    onCreateTickets(filter: $filter) {
      id
      queue_id
      queue {
        id
        queue
        queue_name
        switch_queue_name
        contact_information
        sign_off_information
        flow_information
        overflow_textbox_information
        ticket_subject_values
        queue_cid_spoof_number
        fc_overflow_number
        tools_list
        allowed_groups
        allowed_partners
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      subject
      description
      status
      time_started
      time_last_contact
      priority
      owner
      owner_group
      creator
      requestor
      requestor_group
      cc
      createdAt
      updatedAt
      contact_information
      special_instructions
      custom_fields {
        nextToken
        startedAt
        __typename
      }
      comments {
        nextToken
        startedAt
        __typename
      }
      activity {
        nextToken
        startedAt
        __typename
      }
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateTickets = /* GraphQL */ `
  subscription OnUpdateTickets($filter: ModelSubscriptionTicketsFilterInput) {
    onUpdateTickets(filter: $filter) {
      id
      queue_id
      queue {
        id
        queue
        queue_name
        switch_queue_name
        contact_information
        sign_off_information
        flow_information
        overflow_textbox_information
        ticket_subject_values
        queue_cid_spoof_number
        fc_overflow_number
        tools_list
        allowed_groups
        allowed_partners
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      subject
      description
      status
      time_started
      time_last_contact
      priority
      owner
      owner_group
      creator
      requestor
      requestor_group
      cc
      createdAt
      updatedAt
      contact_information
      special_instructions
      custom_fields {
        nextToken
        startedAt
        __typename
      }
      comments {
        nextToken
        startedAt
        __typename
      }
      activity {
        nextToken
        startedAt
        __typename
      }
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteTickets = /* GraphQL */ `
  subscription OnDeleteTickets($filter: ModelSubscriptionTicketsFilterInput) {
    onDeleteTickets(filter: $filter) {
      id
      queue_id
      queue {
        id
        queue
        queue_name
        switch_queue_name
        contact_information
        sign_off_information
        flow_information
        overflow_textbox_information
        ticket_subject_values
        queue_cid_spoof_number
        fc_overflow_number
        tools_list
        allowed_groups
        allowed_partners
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      subject
      description
      status
      time_started
      time_last_contact
      priority
      owner
      owner_group
      creator
      requestor
      requestor_group
      cc
      createdAt
      updatedAt
      contact_information
      special_instructions
      custom_fields {
        nextToken
        startedAt
        __typename
      }
      comments {
        nextToken
        startedAt
        __typename
      }
      activity {
        nextToken
        startedAt
        __typename
      }
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateTicketComments = /* GraphQL */ `
  subscription OnCreateTicketComments(
    $filter: ModelSubscriptionTicketCommentsFilterInput
  ) {
    onCreateTicketComments(filter: $filter) {
      id
      ticket_id
      visibility_group
      subject
      content
      createdAt
      updatedAt
      comment_owner
      attachments {
        nextToken
        startedAt
        __typename
      }
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateTicketComments = /* GraphQL */ `
  subscription OnUpdateTicketComments(
    $filter: ModelSubscriptionTicketCommentsFilterInput
  ) {
    onUpdateTicketComments(filter: $filter) {
      id
      ticket_id
      visibility_group
      subject
      content
      createdAt
      updatedAt
      comment_owner
      attachments {
        nextToken
        startedAt
        __typename
      }
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteTicketComments = /* GraphQL */ `
  subscription OnDeleteTicketComments(
    $filter: ModelSubscriptionTicketCommentsFilterInput
  ) {
    onDeleteTicketComments(filter: $filter) {
      id
      ticket_id
      visibility_group
      subject
      content
      createdAt
      updatedAt
      comment_owner
      attachments {
        nextToken
        startedAt
        __typename
      }
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateTicketAttachments = /* GraphQL */ `
  subscription OnCreateTicketAttachments(
    $filter: ModelSubscriptionTicketAttachmentsFilterInput
  ) {
    onCreateTicketAttachments(filter: $filter) {
      id
      ticket_comment_id
      s3_bucket_name
      s3_bucket_key
      file_type
      base64_string
      file_name
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateTicketAttachments = /* GraphQL */ `
  subscription OnUpdateTicketAttachments(
    $filter: ModelSubscriptionTicketAttachmentsFilterInput
  ) {
    onUpdateTicketAttachments(filter: $filter) {
      id
      ticket_comment_id
      s3_bucket_name
      s3_bucket_key
      file_type
      base64_string
      file_name
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteTicketAttachments = /* GraphQL */ `
  subscription OnDeleteTicketAttachments(
    $filter: ModelSubscriptionTicketAttachmentsFilterInput
  ) {
    onDeleteTicketAttachments(filter: $filter) {
      id
      ticket_comment_id
      s3_bucket_name
      s3_bucket_key
      file_type
      base64_string
      file_name
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateTicketActivity = /* GraphQL */ `
  subscription OnCreateTicketActivity(
    $filter: ModelSubscriptionTicketActivityFilterInput
  ) {
    onCreateTicketActivity(filter: $filter) {
      id
      ticket_id
      activity_type
      field_changed
      old_field_value
      new_field_value
      activity_owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateTicketActivity = /* GraphQL */ `
  subscription OnUpdateTicketActivity(
    $filter: ModelSubscriptionTicketActivityFilterInput
  ) {
    onUpdateTicketActivity(filter: $filter) {
      id
      ticket_id
      activity_type
      field_changed
      old_field_value
      new_field_value
      activity_owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteTicketActivity = /* GraphQL */ `
  subscription OnDeleteTicketActivity(
    $filter: ModelSubscriptionTicketActivityFilterInput
  ) {
    onDeleteTicketActivity(filter: $filter) {
      id
      ticket_id
      activity_type
      field_changed
      old_field_value
      new_field_value
      activity_owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateDispatch = /* GraphQL */ `
  subscription OnCreateDispatch($filter: ModelSubscriptionDispatchFilterInput) {
    onCreateDispatch(filter: $filter) {
      id
      work_order
      date_est
      local_time
      local_timezone
      dispatcher
      site
      location
      tech
      tech_number
      job_description
      on_site
      on_time
      comments
      tech_support
      time_in
      time_out
      fft_accepted
      billing_info
      fedex_tracking
      travel_fee
      category
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateDispatch = /* GraphQL */ `
  subscription OnUpdateDispatch($filter: ModelSubscriptionDispatchFilterInput) {
    onUpdateDispatch(filter: $filter) {
      id
      work_order
      date_est
      local_time
      local_timezone
      dispatcher
      site
      location
      tech
      tech_number
      job_description
      on_site
      on_time
      comments
      tech_support
      time_in
      time_out
      fft_accepted
      billing_info
      fedex_tracking
      travel_fee
      category
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteDispatch = /* GraphQL */ `
  subscription OnDeleteDispatch($filter: ModelSubscriptionDispatchFilterInput) {
    onDeleteDispatch(filter: $filter) {
      id
      work_order
      date_est
      local_time
      local_timezone
      dispatcher
      site
      location
      tech
      tech_number
      job_description
      on_site
      on_time
      comments
      tech_support
      time_in
      time_out
      fft_accepted
      billing_info
      fedex_tracking
      travel_fee
      category
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateRestrictedDates = /* GraphQL */ `
  subscription OnCreateRestrictedDates(
    $filter: ModelSubscriptionRestrictedDatesFilterInput
  ) {
    onCreateRestrictedDates(filter: $filter) {
      id
      date
      description
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateRestrictedDates = /* GraphQL */ `
  subscription OnUpdateRestrictedDates(
    $filter: ModelSubscriptionRestrictedDatesFilterInput
  ) {
    onUpdateRestrictedDates(filter: $filter) {
      id
      date
      description
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteRestrictedDates = /* GraphQL */ `
  subscription OnDeleteRestrictedDates(
    $filter: ModelSubscriptionRestrictedDatesFilterInput
  ) {
    onDeleteRestrictedDates(filter: $filter) {
      id
      date
      description
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateQuotes = /* GraphQL */ `
  subscription OnCreateQuotes($filter: ModelSubscriptionQuotesFilterInput) {
    onCreateQuotes(filter: $filter) {
      id
      partner
      date
      companyName
      siteAddress
      billingAddress
      city
      state
      zip
      contactName
      contactPhone
      contactEmail
      billingContact
      billingPhone
      billingEmail
      technicalContact
      technicalPhone
      technicalEmail
      authPerson1
      authPhone1
      authEmail1
      authPerson2
      authPhone2
      authEmail2
      authPerson3
      authPhone3
      authEmail3
      termInMonths
      notes
      deleted
      QuoteItems {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateQuotes = /* GraphQL */ `
  subscription OnUpdateQuotes($filter: ModelSubscriptionQuotesFilterInput) {
    onUpdateQuotes(filter: $filter) {
      id
      partner
      date
      companyName
      siteAddress
      billingAddress
      city
      state
      zip
      contactName
      contactPhone
      contactEmail
      billingContact
      billingPhone
      billingEmail
      technicalContact
      technicalPhone
      technicalEmail
      authPerson1
      authPhone1
      authEmail1
      authPerson2
      authPhone2
      authEmail2
      authPerson3
      authPhone3
      authEmail3
      termInMonths
      notes
      deleted
      QuoteItems {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteQuotes = /* GraphQL */ `
  subscription OnDeleteQuotes($filter: ModelSubscriptionQuotesFilterInput) {
    onDeleteQuotes(filter: $filter) {
      id
      partner
      date
      companyName
      siteAddress
      billingAddress
      city
      state
      zip
      contactName
      contactPhone
      contactEmail
      billingContact
      billingPhone
      billingEmail
      technicalContact
      technicalPhone
      technicalEmail
      authPerson1
      authPhone1
      authEmail1
      authPerson2
      authPhone2
      authEmail2
      authPerson3
      authPhone3
      authEmail3
      termInMonths
      notes
      deleted
      QuoteItems {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateQuoteItems = /* GraphQL */ `
  subscription OnCreateQuoteItems(
    $filter: ModelSubscriptionQuoteItemsFilterInput
  ) {
    onCreateQuoteItems(filter: $filter) {
      id
      quote_id
      product_name
      unit_price
      quantity
      total
      frequency
      custom_item
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateQuoteItems = /* GraphQL */ `
  subscription OnUpdateQuoteItems(
    $filter: ModelSubscriptionQuoteItemsFilterInput
  ) {
    onUpdateQuoteItems(filter: $filter) {
      id
      quote_id
      product_name
      unit_price
      quantity
      total
      frequency
      custom_item
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteQuoteItems = /* GraphQL */ `
  subscription OnDeleteQuoteItems(
    $filter: ModelSubscriptionQuoteItemsFilterInput
  ) {
    onDeleteQuoteItems(filter: $filter) {
      id
      quote_id
      product_name
      unit_price
      quantity
      total
      frequency
      custom_item
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateIconfigTemplates = /* GraphQL */ `
  subscription OnCreateIconfigTemplates(
    $filter: ModelSubscriptionIconfigTemplatesFilterInput
  ) {
    onCreateIconfigTemplates(filter: $filter) {
      id
      template_filename
      partner_id
      s3Key
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateIconfigTemplates = /* GraphQL */ `
  subscription OnUpdateIconfigTemplates(
    $filter: ModelSubscriptionIconfigTemplatesFilterInput
  ) {
    onUpdateIconfigTemplates(filter: $filter) {
      id
      template_filename
      partner_id
      s3Key
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteIconfigTemplates = /* GraphQL */ `
  subscription OnDeleteIconfigTemplates(
    $filter: ModelSubscriptionIconfigTemplatesFilterInput
  ) {
    onDeleteIconfigTemplates(filter: $filter) {
      id
      template_filename
      partner_id
      s3Key
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateIconfigPartners = /* GraphQL */ `
  subscription OnCreateIconfigPartners(
    $filter: ModelSubscriptionIconfigPartnersFilterInput
  ) {
    onCreateIconfigPartners(filter: $filter) {
      id
      name
      status
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateIconfigPartners = /* GraphQL */ `
  subscription OnUpdateIconfigPartners(
    $filter: ModelSubscriptionIconfigPartnersFilterInput
  ) {
    onUpdateIconfigPartners(filter: $filter) {
      id
      name
      status
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteIconfigPartners = /* GraphQL */ `
  subscription OnDeleteIconfigPartners(
    $filter: ModelSubscriptionIconfigPartnersFilterInput
  ) {
    onDeleteIconfigPartners(filter: $filter) {
      id
      name
      status
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateDeregisterMessages = /* GraphQL */ `
  subscription OnCreateDeregisterMessages(
    $filter: ModelSubscriptionDeregisterMessagesFilterInput
  ) {
    onCreateDeregisterMessages(filter: $filter) {
      partner
      message
      account_id
      device_user_id
      device_id
      need_to_process
      id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateDeregisterMessages = /* GraphQL */ `
  subscription OnUpdateDeregisterMessages(
    $filter: ModelSubscriptionDeregisterMessagesFilterInput
  ) {
    onUpdateDeregisterMessages(filter: $filter) {
      partner
      message
      account_id
      device_user_id
      device_id
      need_to_process
      id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteDeregisterMessages = /* GraphQL */ `
  subscription OnDeleteDeregisterMessages(
    $filter: ModelSubscriptionDeregisterMessagesFilterInput
  ) {
    onDeleteDeregisterMessages(filter: $filter) {
      partner
      message
      account_id
      device_user_id
      device_id
      need_to_process
      id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateCarrierRates = /* GraphQL */ `
  subscription OnCreateCarrierRates(
    $filter: ModelSubscriptionCarrierRatesFilterInput
  ) {
    onCreateCarrierRates(filter: $filter) {
      id
      carrier
      npanxx
      direction
      lata
      rate_center
      interstate_rate
      intrastate_rate
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateCarrierRates = /* GraphQL */ `
  subscription OnUpdateCarrierRates(
    $filter: ModelSubscriptionCarrierRatesFilterInput
  ) {
    onUpdateCarrierRates(filter: $filter) {
      id
      carrier
      npanxx
      direction
      lata
      rate_center
      interstate_rate
      intrastate_rate
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteCarrierRates = /* GraphQL */ `
  subscription OnDeleteCarrierRates(
    $filter: ModelSubscriptionCarrierRatesFilterInput
  ) {
    onDeleteCarrierRates(filter: $filter) {
      id
      carrier
      npanxx
      direction
      lata
      rate_center
      interstate_rate
      intrastate_rate
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateRateCenters = /* GraphQL */ `
  subscription OnCreateRateCenters(
    $filter: ModelSubscriptionRateCentersFilterInput
  ) {
    onCreateRateCenters(filter: $filter) {
      id
      npanxx
      carrier_rates_npannx {
        nextToken
        startedAt
        __typename
      }
      npa
      lata
      carrier_rates_lata {
        nextToken
        startedAt
        __typename
      }
      rate_center
      carrier_rates_rate_center {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateRateCenters = /* GraphQL */ `
  subscription OnUpdateRateCenters(
    $filter: ModelSubscriptionRateCentersFilterInput
  ) {
    onUpdateRateCenters(filter: $filter) {
      id
      npanxx
      carrier_rates_npannx {
        nextToken
        startedAt
        __typename
      }
      npa
      lata
      carrier_rates_lata {
        nextToken
        startedAt
        __typename
      }
      rate_center
      carrier_rates_rate_center {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteRateCenters = /* GraphQL */ `
  subscription OnDeleteRateCenters(
    $filter: ModelSubscriptionRateCentersFilterInput
  ) {
    onDeleteRateCenters(filter: $filter) {
      id
      npanxx
      carrier_rates_npannx {
        nextToken
        startedAt
        __typename
      }
      npa
      lata
      carrier_rates_lata {
        nextToken
        startedAt
        __typename
      }
      rate_center
      carrier_rates_rate_center {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateRobocallThresholds = /* GraphQL */ `
  subscription OnCreateRobocallThresholds(
    $filter: ModelSubscriptionRobocallThresholdsFilterInput
  ) {
    onCreateRobocallThresholds(filter: $filter) {
      id
      revio_id
      inbound_minutes_per_call
      outbound_minutes_per_call
      toll_free_inbound_minutes_per_call
      toll_free_outbound_minutes_per_call
      international_outbound_minutes_per_call
      short_inbound_calls_hourly
      short_outbound_calls_hourly
      zero_inbound_calls_hourly
      zero_outbound_calls_hourly
      invalid_inbound_calls_hourly
      invalid_outbound_calls_hourly
      toll_free_inbound_calls_hourly
      international_outbound_calls_hourly
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateRobocallThresholds = /* GraphQL */ `
  subscription OnUpdateRobocallThresholds(
    $filter: ModelSubscriptionRobocallThresholdsFilterInput
  ) {
    onUpdateRobocallThresholds(filter: $filter) {
      id
      revio_id
      inbound_minutes_per_call
      outbound_minutes_per_call
      toll_free_inbound_minutes_per_call
      toll_free_outbound_minutes_per_call
      international_outbound_minutes_per_call
      short_inbound_calls_hourly
      short_outbound_calls_hourly
      zero_inbound_calls_hourly
      zero_outbound_calls_hourly
      invalid_inbound_calls_hourly
      invalid_outbound_calls_hourly
      toll_free_inbound_calls_hourly
      international_outbound_calls_hourly
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteRobocallThresholds = /* GraphQL */ `
  subscription OnDeleteRobocallThresholds(
    $filter: ModelSubscriptionRobocallThresholdsFilterInput
  ) {
    onDeleteRobocallThresholds(filter: $filter) {
      id
      revio_id
      inbound_minutes_per_call
      outbound_minutes_per_call
      toll_free_inbound_minutes_per_call
      toll_free_outbound_minutes_per_call
      international_outbound_minutes_per_call
      short_inbound_calls_hourly
      short_outbound_calls_hourly
      zero_inbound_calls_hourly
      zero_outbound_calls_hourly
      invalid_inbound_calls_hourly
      invalid_outbound_calls_hourly
      toll_free_inbound_calls_hourly
      international_outbound_calls_hourly
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateMultiLineOrders = /* GraphQL */ `
  subscription OnCreateMultiLineOrders(
    $filter: ModelSubscriptionMultiLineOrdersFilterInput
  ) {
    onCreateMultiLineOrders(filter: $filter) {
      id
      port_order_id
      status
      submission_date
      order_type
      install_date
      completed_date
      partner_account_number
      company_name
      site_address
      multi_line_network_information {
        nextToken
        startedAt
        __typename
      }
      multi_line_order_comments {
        nextToken
        startedAt
        __typename
      }
      order_tasks {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateMultiLineOrders = /* GraphQL */ `
  subscription OnUpdateMultiLineOrders(
    $filter: ModelSubscriptionMultiLineOrdersFilterInput
  ) {
    onUpdateMultiLineOrders(filter: $filter) {
      id
      port_order_id
      status
      submission_date
      order_type
      install_date
      completed_date
      partner_account_number
      company_name
      site_address
      multi_line_network_information {
        nextToken
        startedAt
        __typename
      }
      multi_line_order_comments {
        nextToken
        startedAt
        __typename
      }
      order_tasks {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteMultiLineOrders = /* GraphQL */ `
  subscription OnDeleteMultiLineOrders(
    $filter: ModelSubscriptionMultiLineOrdersFilterInput
  ) {
    onDeleteMultiLineOrders(filter: $filter) {
      id
      port_order_id
      status
      submission_date
      order_type
      install_date
      completed_date
      partner_account_number
      company_name
      site_address
      multi_line_network_information {
        nextToken
        startedAt
        __typename
      }
      multi_line_order_comments {
        nextToken
        startedAt
        __typename
      }
      order_tasks {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateMultiLineOrderComments = /* GraphQL */ `
  subscription OnCreateMultiLineOrderComments(
    $filter: ModelSubscriptionMultiLineOrderCommentsFilterInput
  ) {
    onCreateMultiLineOrderComments(filter: $filter) {
      id
      multiline_order_id
      content
      action_required
      action_response
      author
      is_private
      user_id
      order_state
      comment_type
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateMultiLineOrderComments = /* GraphQL */ `
  subscription OnUpdateMultiLineOrderComments(
    $filter: ModelSubscriptionMultiLineOrderCommentsFilterInput
  ) {
    onUpdateMultiLineOrderComments(filter: $filter) {
      id
      multiline_order_id
      content
      action_required
      action_response
      author
      is_private
      user_id
      order_state
      comment_type
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteMultiLineOrderComments = /* GraphQL */ `
  subscription OnDeleteMultiLineOrderComments(
    $filter: ModelSubscriptionMultiLineOrderCommentsFilterInput
  ) {
    onDeleteMultiLineOrderComments(filter: $filter) {
      id
      multiline_order_id
      content
      action_required
      action_response
      author
      is_private
      user_id
      order_state
      comment_type
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateMultiLineOrderNetworkInformation = /* GraphQL */ `
  subscription OnCreateMultiLineOrderNetworkInformation(
    $filter: ModelSubscriptionMultiLineOrderNetworkInformationFilterInput
  ) {
    onCreateMultiLineOrderNetworkInformation(filter: $filter) {
      id
      multiline_order_id
      static_ip
      gateway_ip
      mac_address
      fax_security_info
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateMultiLineOrderNetworkInformation = /* GraphQL */ `
  subscription OnUpdateMultiLineOrderNetworkInformation(
    $filter: ModelSubscriptionMultiLineOrderNetworkInformationFilterInput
  ) {
    onUpdateMultiLineOrderNetworkInformation(filter: $filter) {
      id
      multiline_order_id
      static_ip
      gateway_ip
      mac_address
      fax_security_info
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteMultiLineOrderNetworkInformation = /* GraphQL */ `
  subscription OnDeleteMultiLineOrderNetworkInformation(
    $filter: ModelSubscriptionMultiLineOrderNetworkInformationFilterInput
  ) {
    onDeleteMultiLineOrderNetworkInformation(filter: $filter) {
      id
      multiline_order_id
      static_ip
      gateway_ip
      mac_address
      fax_security_info
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateOrderTeamMembers = /* GraphQL */ `
  subscription OnCreateOrderTeamMembers(
    $filter: ModelSubscriptionOrderTeamMembersFilterInput
  ) {
    onCreateOrderTeamMembers(filter: $filter) {
      id
      name
      email
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateOrderTeamMembers = /* GraphQL */ `
  subscription OnUpdateOrderTeamMembers(
    $filter: ModelSubscriptionOrderTeamMembersFilterInput
  ) {
    onUpdateOrderTeamMembers(filter: $filter) {
      id
      name
      email
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteOrderTeamMembers = /* GraphQL */ `
  subscription OnDeleteOrderTeamMembers(
    $filter: ModelSubscriptionOrderTeamMembersFilterInput
  ) {
    onDeleteOrderTeamMembers(filter: $filter) {
      id
      name
      email
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateOrderTasks = /* GraphQL */ `
  subscription OnCreateOrderTasks(
    $filter: ModelSubscriptionOrderTasksFilterInput
  ) {
    onCreateOrderTasks(filter: $filter) {
      id
      multiline_order_id
      status
      date_completed
      task_name
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateOrderTasks = /* GraphQL */ `
  subscription OnUpdateOrderTasks(
    $filter: ModelSubscriptionOrderTasksFilterInput
  ) {
    onUpdateOrderTasks(filter: $filter) {
      id
      multiline_order_id
      status
      date_completed
      task_name
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteOrderTasks = /* GraphQL */ `
  subscription OnDeleteOrderTasks(
    $filter: ModelSubscriptionOrderTasksFilterInput
  ) {
    onDeleteOrderTasks(filter: $filter) {
      id
      multiline_order_id
      status
      date_completed
      task_name
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateDefaultOrderTasks = /* GraphQL */ `
  subscription OnCreateDefaultOrderTasks(
    $filter: ModelSubscriptionDefaultOrderTasksFilterInput
  ) {
    onCreateDefaultOrderTasks(filter: $filter) {
      id
      task_name
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateDefaultOrderTasks = /* GraphQL */ `
  subscription OnUpdateDefaultOrderTasks(
    $filter: ModelSubscriptionDefaultOrderTasksFilterInput
  ) {
    onUpdateDefaultOrderTasks(filter: $filter) {
      id
      task_name
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteDefaultOrderTasks = /* GraphQL */ `
  subscription OnDeleteDefaultOrderTasks(
    $filter: ModelSubscriptionDefaultOrderTasksFilterInput
  ) {
    onDeleteDefaultOrderTasks(filter: $filter) {
      id
      task_name
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateBConfigDevices = /* GraphQL */ `
  subscription OnCreateBConfigDevices(
    $filter: ModelSubscriptionBConfigDevicesFilterInput
  ) {
    onCreateBConfigDevices(filter: $filter) {
      config_name
      name
      address
      partner
      vendor
      location
      date
      S3Key
      id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateBConfigDevices = /* GraphQL */ `
  subscription OnUpdateBConfigDevices(
    $filter: ModelSubscriptionBConfigDevicesFilterInput
  ) {
    onUpdateBConfigDevices(filter: $filter) {
      config_name
      name
      address
      partner
      vendor
      location
      date
      S3Key
      id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteBConfigDevices = /* GraphQL */ `
  subscription OnDeleteBConfigDevices(
    $filter: ModelSubscriptionBConfigDevicesFilterInput
  ) {
    onDeleteBConfigDevices(filter: $filter) {
      config_name
      name
      address
      partner
      vendor
      location
      date
      S3Key
      id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateTrackableSystems = /* GraphQL */ `
  subscription OnCreateTrackableSystems(
    $filter: ModelSubscriptionTrackableSystemsFilterInput
  ) {
    onCreateTrackableSystems(filter: $filter) {
      id
      trackedItem
      currentStatus
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateTrackableSystems = /* GraphQL */ `
  subscription OnUpdateTrackableSystems(
    $filter: ModelSubscriptionTrackableSystemsFilterInput
  ) {
    onUpdateTrackableSystems(filter: $filter) {
      id
      trackedItem
      currentStatus
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteTrackableSystems = /* GraphQL */ `
  subscription OnDeleteTrackableSystems(
    $filter: ModelSubscriptionTrackableSystemsFilterInput
  ) {
    onDeleteTrackableSystems(filter: $filter) {
      id
      trackedItem
      currentStatus
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateUsers = /* GraphQL */ `
  subscription OnCreateUsers($filter: ModelSubscriptionUsersFilterInput) {
    onCreateUsers(filter: $filter) {
      id
      partner
      username
      first_name
      last_name
      bookmarked_tickets
      favorite_apps
      notifications {
        nextToken
        startedAt
        __typename
      }
      has_subscribed_reports
      subscribed_reports
      favorite_reports
      webrtc_config_s3_key
      workspace_layout
      subscribed_systems
      maintenanceMessages
      emailNotifications
      disabled
      agent_account_id
      agent_recipient_id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateUsers = /* GraphQL */ `
  subscription OnUpdateUsers($filter: ModelSubscriptionUsersFilterInput) {
    onUpdateUsers(filter: $filter) {
      id
      partner
      username
      first_name
      last_name
      bookmarked_tickets
      favorite_apps
      notifications {
        nextToken
        startedAt
        __typename
      }
      has_subscribed_reports
      subscribed_reports
      favorite_reports
      webrtc_config_s3_key
      workspace_layout
      subscribed_systems
      maintenanceMessages
      emailNotifications
      disabled
      agent_account_id
      agent_recipient_id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteUsers = /* GraphQL */ `
  subscription OnDeleteUsers($filter: ModelSubscriptionUsersFilterInput) {
    onDeleteUsers(filter: $filter) {
      id
      partner
      username
      first_name
      last_name
      bookmarked_tickets
      favorite_apps
      notifications {
        nextToken
        startedAt
        __typename
      }
      has_subscribed_reports
      subscribed_reports
      favorite_reports
      webrtc_config_s3_key
      workspace_layout
      subscribed_systems
      maintenanceMessages
      emailNotifications
      disabled
      agent_account_id
      agent_recipient_id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateSiteNotifications = /* GraphQL */ `
  subscription OnCreateSiteNotifications(
    $filter: ModelSubscriptionSiteNotificationsFilterInput
  ) {
    onCreateSiteNotifications(filter: $filter) {
      id
      user_id
      title
      type
      tracked_system
      status
      marked_as_read
      content
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateSiteNotifications = /* GraphQL */ `
  subscription OnUpdateSiteNotifications(
    $filter: ModelSubscriptionSiteNotificationsFilterInput
  ) {
    onUpdateSiteNotifications(filter: $filter) {
      id
      user_id
      title
      type
      tracked_system
      status
      marked_as_read
      content
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteSiteNotifications = /* GraphQL */ `
  subscription OnDeleteSiteNotifications(
    $filter: ModelSubscriptionSiteNotificationsFilterInput
  ) {
    onDeleteSiteNotifications(filter: $filter) {
      id
      user_id
      title
      type
      tracked_system
      status
      marked_as_read
      content
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateEmployees = /* GraphQL */ `
  subscription OnCreateEmployees(
    $filter: ModelSubscriptionEmployeesFilterInput
  ) {
    onCreateEmployees(filter: $filter) {
      id
      name
      email
      position
      employee_ticket_number
      supervisor
      start_time
      terminated
      date_terminated
      duration
      work_days
      discipline_level
      assigned_group
      employee_actions {
        nextToken
        startedAt
        __typename
      }
      voice_infractions {
        nextToken
        startedAt
        __typename
      }
      contact_center_infractions {
        nextToken
        startedAt
        __typename
      }
      audits {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateEmployees = /* GraphQL */ `
  subscription OnUpdateEmployees(
    $filter: ModelSubscriptionEmployeesFilterInput
  ) {
    onUpdateEmployees(filter: $filter) {
      id
      name
      email
      position
      employee_ticket_number
      supervisor
      start_time
      terminated
      date_terminated
      duration
      work_days
      discipline_level
      assigned_group
      employee_actions {
        nextToken
        startedAt
        __typename
      }
      voice_infractions {
        nextToken
        startedAt
        __typename
      }
      contact_center_infractions {
        nextToken
        startedAt
        __typename
      }
      audits {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteEmployees = /* GraphQL */ `
  subscription OnDeleteEmployees(
    $filter: ModelSubscriptionEmployeesFilterInput
  ) {
    onDeleteEmployees(filter: $filter) {
      id
      name
      email
      position
      employee_ticket_number
      supervisor
      start_time
      terminated
      date_terminated
      duration
      work_days
      discipline_level
      assigned_group
      employee_actions {
        nextToken
        startedAt
        __typename
      }
      voice_infractions {
        nextToken
        startedAt
        __typename
      }
      contact_center_infractions {
        nextToken
        startedAt
        __typename
      }
      audits {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateVoiceInfractions = /* GraphQL */ `
  subscription OnCreateVoiceInfractions(
    $filter: ModelSubscriptionVoiceInfractionsFilterInput
  ) {
    onCreateVoiceInfractions(filter: $filter) {
      id
      employee_id
      date
      employee
      infraction
      documentation
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateVoiceInfractions = /* GraphQL */ `
  subscription OnUpdateVoiceInfractions(
    $filter: ModelSubscriptionVoiceInfractionsFilterInput
  ) {
    onUpdateVoiceInfractions(filter: $filter) {
      id
      employee_id
      date
      employee
      infraction
      documentation
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteVoiceInfractions = /* GraphQL */ `
  subscription OnDeleteVoiceInfractions(
    $filter: ModelSubscriptionVoiceInfractionsFilterInput
  ) {
    onDeleteVoiceInfractions(filter: $filter) {
      id
      employee_id
      date
      employee
      infraction
      documentation
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateContactCenterInfractions = /* GraphQL */ `
  subscription OnCreateContactCenterInfractions(
    $filter: ModelSubscriptionContactCenterInfractionsFilterInput
  ) {
    onCreateContactCenterInfractions(filter: $filter) {
      id
      employee_id
      date
      employee_name
      infraction
      scheduled_shift
      clock_in_time
      clock_out_time
      details
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateContactCenterInfractions = /* GraphQL */ `
  subscription OnUpdateContactCenterInfractions(
    $filter: ModelSubscriptionContactCenterInfractionsFilterInput
  ) {
    onUpdateContactCenterInfractions(filter: $filter) {
      id
      employee_id
      date
      employee_name
      infraction
      scheduled_shift
      clock_in_time
      clock_out_time
      details
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteContactCenterInfractions = /* GraphQL */ `
  subscription OnDeleteContactCenterInfractions(
    $filter: ModelSubscriptionContactCenterInfractionsFilterInput
  ) {
    onDeleteContactCenterInfractions(filter: $filter) {
      id
      employee_id
      date
      employee_name
      infraction
      scheduled_shift
      clock_in_time
      clock_out_time
      details
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateEmployeeActions = /* GraphQL */ `
  subscription OnCreateEmployeeActions(
    $filter: ModelSubscriptionEmployeeActionsFilterInput
  ) {
    onCreateEmployeeActions(filter: $filter) {
      id
      employee_id
      employee {
        id
        name
        email
        position
        employee_ticket_number
        supervisor
        start_time
        terminated
        date_terminated
        duration
        work_days
        discipline_level
        assigned_group
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      date
      action
      documentation
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateEmployeeActions = /* GraphQL */ `
  subscription OnUpdateEmployeeActions(
    $filter: ModelSubscriptionEmployeeActionsFilterInput
  ) {
    onUpdateEmployeeActions(filter: $filter) {
      id
      employee_id
      employee {
        id
        name
        email
        position
        employee_ticket_number
        supervisor
        start_time
        terminated
        date_terminated
        duration
        work_days
        discipline_level
        assigned_group
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      date
      action
      documentation
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteEmployeeActions = /* GraphQL */ `
  subscription OnDeleteEmployeeActions(
    $filter: ModelSubscriptionEmployeeActionsFilterInput
  ) {
    onDeleteEmployeeActions(filter: $filter) {
      id
      employee_id
      employee {
        id
        name
        email
        position
        employee_ticket_number
        supervisor
        start_time
        terminated
        date_terminated
        duration
        work_days
        discipline_level
        assigned_group
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      date
      action
      documentation
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateScheduledPartners = /* GraphQL */ `
  subscription OnCreateScheduledPartners(
    $filter: ModelSubscriptionScheduledPartnersFilterInput
  ) {
    onCreateScheduledPartners(filter: $filter) {
      id
      name
      queue_name
      category
      assigned_group
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateScheduledPartners = /* GraphQL */ `
  subscription OnUpdateScheduledPartners(
    $filter: ModelSubscriptionScheduledPartnersFilterInput
  ) {
    onUpdateScheduledPartners(filter: $filter) {
      id
      name
      queue_name
      category
      assigned_group
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteScheduledPartners = /* GraphQL */ `
  subscription OnDeleteScheduledPartners(
    $filter: ModelSubscriptionScheduledPartnersFilterInput
  ) {
    onDeleteScheduledPartners(filter: $filter) {
      id
      name
      queue_name
      category
      assigned_group
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateContactCenterQualityAudits = /* GraphQL */ `
  subscription OnCreateContactCenterQualityAudits(
    $filter: ModelSubscriptionContactCenterQualityAuditsFilterInput
  ) {
    onCreateContactCenterQualityAudits(filter: $filter) {
      id
      employee_id
      employee {
        id
        name
        email
        position
        employee_ticket_number
        supervisor
        start_time
        terminated
        date_terminated
        duration
        work_days
        discipline_level
        assigned_group
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      ticket_number
      date_of_call
      date_of_audit
      name_of_auditor
      call_recording
      call_type
      point_total
      status
      audit_object
      mutated_object
      compliance
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateContactCenterQualityAudits = /* GraphQL */ `
  subscription OnUpdateContactCenterQualityAudits(
    $filter: ModelSubscriptionContactCenterQualityAuditsFilterInput
  ) {
    onUpdateContactCenterQualityAudits(filter: $filter) {
      id
      employee_id
      employee {
        id
        name
        email
        position
        employee_ticket_number
        supervisor
        start_time
        terminated
        date_terminated
        duration
        work_days
        discipline_level
        assigned_group
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      ticket_number
      date_of_call
      date_of_audit
      name_of_auditor
      call_recording
      call_type
      point_total
      status
      audit_object
      mutated_object
      compliance
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteContactCenterQualityAudits = /* GraphQL */ `
  subscription OnDeleteContactCenterQualityAudits(
    $filter: ModelSubscriptionContactCenterQualityAuditsFilterInput
  ) {
    onDeleteContactCenterQualityAudits(filter: $filter) {
      id
      employee_id
      employee {
        id
        name
        email
        position
        employee_ticket_number
        supervisor
        start_time
        terminated
        date_terminated
        duration
        work_days
        discipline_level
        assigned_group
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      ticket_number
      date_of_call
      date_of_audit
      name_of_auditor
      call_recording
      call_type
      point_total
      status
      audit_object
      mutated_object
      compliance
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateContactCenterQualityAuditQuestions = /* GraphQL */ `
  subscription OnCreateContactCenterQualityAuditQuestions(
    $filter: ModelSubscriptionContactCenterQualityAuditQuestionsFilterInput
  ) {
    onCreateContactCenterQualityAuditQuestions(filter: $filter) {
      id
      question
      point_value
      category
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateContactCenterQualityAuditQuestions = /* GraphQL */ `
  subscription OnUpdateContactCenterQualityAuditQuestions(
    $filter: ModelSubscriptionContactCenterQualityAuditQuestionsFilterInput
  ) {
    onUpdateContactCenterQualityAuditQuestions(filter: $filter) {
      id
      question
      point_value
      category
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteContactCenterQualityAuditQuestions = /* GraphQL */ `
  subscription OnDeleteContactCenterQualityAuditQuestions(
    $filter: ModelSubscriptionContactCenterQualityAuditQuestionsFilterInput
  ) {
    onDeleteContactCenterQualityAuditQuestions(filter: $filter) {
      id
      question
      point_value
      category
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateMultiLineProvisionRequests = /* GraphQL */ `
  subscription OnCreateMultiLineProvisionRequests(
    $filter: ModelSubscriptionMultiLineProvisionRequestsFilterInput
  ) {
    onCreateMultiLineProvisionRequests(filter: $filter) {
      id
      partner
      account_number
      first_name
      last_name
      address
      city
      state
      zip
      contact_phone
      contact_email
      customer_type
      caller_id_name
      caller_id_number
      bypass_e911
      phone_numbers
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateMultiLineProvisionRequests = /* GraphQL */ `
  subscription OnUpdateMultiLineProvisionRequests(
    $filter: ModelSubscriptionMultiLineProvisionRequestsFilterInput
  ) {
    onUpdateMultiLineProvisionRequests(filter: $filter) {
      id
      partner
      account_number
      first_name
      last_name
      address
      city
      state
      zip
      contact_phone
      contact_email
      customer_type
      caller_id_name
      caller_id_number
      bypass_e911
      phone_numbers
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteMultiLineProvisionRequests = /* GraphQL */ `
  subscription OnDeleteMultiLineProvisionRequests(
    $filter: ModelSubscriptionMultiLineProvisionRequestsFilterInput
  ) {
    onDeleteMultiLineProvisionRequests(filter: $filter) {
      id
      partner
      account_number
      first_name
      last_name
      address
      city
      state
      zip
      contact_phone
      contact_email
      customer_type
      caller_id_name
      caller_id_number
      bypass_e911
      phone_numbers
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateFlowQuestions = /* GraphQL */ `
  subscription OnCreateFlowQuestions(
    $filter: ModelSubscriptionFlowQuestionsFilterInput
  ) {
    onCreateFlowQuestions(filter: $filter) {
      id
      type
      question
      options
      url
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateFlowQuestions = /* GraphQL */ `
  subscription OnUpdateFlowQuestions(
    $filter: ModelSubscriptionFlowQuestionsFilterInput
  ) {
    onUpdateFlowQuestions(filter: $filter) {
      id
      type
      question
      options
      url
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteFlowQuestions = /* GraphQL */ `
  subscription OnDeleteFlowQuestions(
    $filter: ModelSubscriptionFlowQuestionsFilterInput
  ) {
    onDeleteFlowQuestions(filter: $filter) {
      id
      type
      question
      options
      url
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateFlows = /* GraphQL */ `
  subscription OnCreateFlows($filter: ModelSubscriptionFlowsFilterInput) {
    onCreateFlows(filter: $filter) {
      id
      name
      allowed_queues_staging
      allowed_queues
      s3_staging_bucket_key
      s3_production_bucket_key
      s3_bucket_name
      changed_flag
      removal_flag
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateFlows = /* GraphQL */ `
  subscription OnUpdateFlows($filter: ModelSubscriptionFlowsFilterInput) {
    onUpdateFlows(filter: $filter) {
      id
      name
      allowed_queues_staging
      allowed_queues
      s3_staging_bucket_key
      s3_production_bucket_key
      s3_bucket_name
      changed_flag
      removal_flag
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteFlows = /* GraphQL */ `
  subscription OnDeleteFlows($filter: ModelSubscriptionFlowsFilterInput) {
    onDeleteFlows(filter: $filter) {
      id
      name
      allowed_queues_staging
      allowed_queues
      s3_staging_bucket_key
      s3_production_bucket_key
      s3_bucket_name
      changed_flag
      removal_flag
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateFakeDisconnectRequests = /* GraphQL */ `
  subscription OnCreateFakeDisconnectRequests(
    $filter: ModelSubscriptionFakeDisconnectRequestsFilterInput
  ) {
    onCreateFakeDisconnectRequests(filter: $filter) {
      id
      service
      status
      disconnect_date
      requester
      comment
      full_disconnect
      account_number
      partner
      phone_numbers
      ticket_number
      port_out
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateFakeDisconnectRequests = /* GraphQL */ `
  subscription OnUpdateFakeDisconnectRequests(
    $filter: ModelSubscriptionFakeDisconnectRequestsFilterInput
  ) {
    onUpdateFakeDisconnectRequests(filter: $filter) {
      id
      service
      status
      disconnect_date
      requester
      comment
      full_disconnect
      account_number
      partner
      phone_numbers
      ticket_number
      port_out
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteFakeDisconnectRequests = /* GraphQL */ `
  subscription OnDeleteFakeDisconnectRequests(
    $filter: ModelSubscriptionFakeDisconnectRequestsFilterInput
  ) {
    onDeleteFakeDisconnectRequests(filter: $filter) {
      id
      service
      status
      disconnect_date
      requester
      comment
      full_disconnect
      account_number
      partner
      phone_numbers
      ticket_number
      port_out
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateAgentsRecoveryTimes = /* GraphQL */ `
  subscription OnCreateAgentsRecoveryTimes(
    $filter: ModelSubscriptionAgentsRecoveryTimesFilterInput
  ) {
    onCreateAgentsRecoveryTimes(filter: $filter) {
      id
      recipient_id
      recovery_time
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateAgentsRecoveryTimes = /* GraphQL */ `
  subscription OnUpdateAgentsRecoveryTimes(
    $filter: ModelSubscriptionAgentsRecoveryTimesFilterInput
  ) {
    onUpdateAgentsRecoveryTimes(filter: $filter) {
      id
      recipient_id
      recovery_time
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteAgentsRecoveryTimes = /* GraphQL */ `
  subscription OnDeleteAgentsRecoveryTimes(
    $filter: ModelSubscriptionAgentsRecoveryTimesFilterInput
  ) {
    onDeleteAgentsRecoveryTimes(filter: $filter) {
      id
      recipient_id
      recovery_time
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateCarrierDialPrefixRates = /* GraphQL */ `
  subscription OnCreateCarrierDialPrefixRates(
    $filter: ModelSubscriptionCarrierDialPrefixRatesFilterInput
  ) {
    onCreateCarrierDialPrefixRates(filter: $filter) {
      id
      country
      dial_prefix
      retail_rate
      wholesale_rate
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateCarrierDialPrefixRates = /* GraphQL */ `
  subscription OnUpdateCarrierDialPrefixRates(
    $filter: ModelSubscriptionCarrierDialPrefixRatesFilterInput
  ) {
    onUpdateCarrierDialPrefixRates(filter: $filter) {
      id
      country
      dial_prefix
      retail_rate
      wholesale_rate
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteCarrierDialPrefixRates = /* GraphQL */ `
  subscription OnDeleteCarrierDialPrefixRates(
    $filter: ModelSubscriptionCarrierDialPrefixRatesFilterInput
  ) {
    onDeleteCarrierDialPrefixRates(filter: $filter) {
      id
      country
      dial_prefix
      retail_rate
      wholesale_rate
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
