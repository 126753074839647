<template>
  <div class="quality-audit-table">
    <table>
      <thead class="vue3-easy-data-table__header">
        <tr class="table-header-row">
          <th :colspan="1">Quality Category</th>
          <th :colspan="2">Scale</th>
          <th :colspan="1">Score</th>
        </tr>
        <tr class="table-header-row">
          <th :colspan="1"></th>
          <th :colspan="1" class="">UnSatisfactory</th>
          <th :colspan="1" class="">Satisfactory</th>
          <th :colspan="1"></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in dataList" :key="item.category">
          <td class="table-header-row text-left pl-2">{{ item.category }}</td>
          <td class="table-data-scale-unsatisfactory">{{ item.scale.UnSatisfactory}}</td>
          <td class="table-data-scale-satisfactory">{{ item.scale.Satisfactory}}</td>
          <td :class="[!item.touched ? 'table-header-row' : (item.satisfactory ? 'table-data-scale-satisfactory' : 'table-data-scale-unsatisfactory' )]">{{ item.score }}</td>
        </tr>
        <tr>
          <td class="table-header-row" :colspan="2">100 Possible Points</td>
          <td class="table-header-row" :colspan="1">Total</td>
          <template v-if="untouched && !failedCompliance">
            <td class="table-header-row" :colspan="2">
            </td>
          </template>
          <template v-else-if="!untouched && scoreTotal === 0 && !failedCompliance">
            <td class="table-data-scale-unsatisfactory" :colspan="2">0</td>
          </template>
          <template v-else>
            <td :class="{'table-data-scale-unsatisfactory': (!failedCompliance && scoreTotal === 0) || scoreTotal < 85, 'table-data-scale-satisfactory': scoreTotal >= 85}">
              {{ (failedCompliance || (scoreTotal === 0)) ? '0' : scoreTotal }}
            </td>
          </template>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  props: {
    untouched: Boolean,
    headers: {
      type: Array,
      required: true,
    },
    dataList: {
      type: Array,
      required: true,
    },
    failedCompliance: Boolean,
    scoreTotal: {
      type: [String, Number],
      required: true
    }
  },
  computed: {
    headerColspan() {
      return this.headers.length;
    },
    scaleColspan() {
      return this.headers.some((header) => header.value === 'scale') ? 2 : 1;
    },
  },
};
</script>

<style scoped>
/* Add your custom styles here */
table {
  border-spacing: unset;
}
td, th {
  font-weight: 400;
}
.table-header-row {
  background-color: #fff;
  text-align: center;
}
.table-header-scale {
  background-color :#fca608;
}
.table-data-scale-unsatisfactory {
  background-color :#fca608;
}
.table-data-scale-satisfactory {
  background-color: #3e87d3;
  color: #fff;
}
table th,
table td {
  border: 1px solid black;
  padding: 2px;
  text-align: center;
}
table th {
  font-size: 13px;
}
table td {
  font-size: 12px;
}
</style>

