<template>
  <div>
    <Bar
      :data="chartDataQueue"
      :options="chartOptionsC"
      :plugins="[centerText, labels]"
      :height="200"
      @hover="handleHover"
    ></Bar>
  </div>
</template>
<script setup>
import { Bar } from "vue-chartjs";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);
</script>

<script>
export default {
  name: "HorizontalBarChart",
  props: {
    queue: Object,
    chartData: Array,
  },
  components: { Bar },
  methods: {
    handleHover() {
      // TODO: what needs to be done here besides a console.log? 
      console.log("hi");
    },
  },
  data() {
    return {
      labels: {
        id: "labels",
        order: 0,
        afterDatasetsDraw(chart) {
          let images = [
            "/assets/Available.png",
            "/assets/Urgent Priority.png",
            "/assets/High Priority.png",
          ];

          const { ctx } = chart;
          var xAxis = chart.scales.x;
          var yAxis = chart.scales.y;
          xAxis.ticks.forEach((value, index) => {
            var y = yAxis.getPixelForTick(index);
            var img = document.createElement("img");
            img.src = images[index];
            ctx.drawImage(img, xAxis.left - 115, y - 8, 14, 14);
          });
        },
      },
      centerText: {
        id: "centerText",
        order: 1,
        afterDatasetsDraw(chart) {
          const { ctx } = chart;

          let dataset = chart.data.datasets[0].data;
          let activeElement = chart.getActiveElements()[0];
          let activeIndex = activeElement?.index;
          let activeData = dataset[activeIndex] ? dataset[activeIndex] : "";
          if (activeElement?.datasetIndex == 0) {
            ctx.font = "500 26pt Red Hat Display";
            ctx.fillStyle = "rgba(39, 29, 122, 1)";
            ctx.textAlign = "center";
            ctx.fillText(
              activeData,
              activeElement?.element.x,
              activeElement?.element.y - 10
            );
            ctx.save();
          }
        },
      },
    };
  },
  computed: {
    chartDatac() {
      return this.chartData;
    },
    chartDataQueue() {
      let labels = [];
      let datasets = [
        {
          backgroundColor: [],
          data: [],
          barThickness: 24,
          hoverBorderColor: "#271d7a",
          hoverBorderWidth: 1,
        },
      ];
      for (let i = 0; i < this.chartData.length; i++) {
        labels.push(this.chartData[i].label);
        datasets[0].backgroundColor.push(this.chartData[i].color);
        datasets[0].data.push(this.chartData[i].data);
      }
      return {
        labels: labels,
        datasets: datasets,
      };
    },
    chartOptionsC() {
      let max = Number(this.chartData[0].data);
      for (let i = 1; i < this.chartData.length; i++) {
        if (max < Number(this.chartData[i].data)) {
          max = Number(this.chartData[i].data);
        }
      }

      let total = max + Math.floor(max / 4);
      total = Math.round(total / 10) * 10;

      return {
        indexAxis: "x",
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false,
          },
          title: {
            display: false,
            text: "Chart.js Horizontal Bar Chart",
          },
          tooltip: {
            enabled: false,
          },
        },
        scales: {
          x: {
            grid: {
              display: false,
              drawTicks: false,
            },
            ticks: {
              font: {
                size: 15,
                family: "Red Hat Display",
                weight: 500,
                textAlign: "left",
              },
              autoSkip: false,
              color: "#271d7a",
              maxTicksLimit: 3,
              stepSize: Math.floor(total / 2),
            },
          },
          y: {
            border: {
              display: true,
            },
            grid: {
              display: true,
              drawTicks: true,
            },

            ticks: {
              font: {
                size: 14,
                family: "Red Hat Display",
                weight: 500,
              },
              color: "#271d7a",
              crossAlign: "far",
              padding: 12,
            },
            min: 0,
            max: total,
          },
        },
        layout: {
          padding: {
            top: 30,
            bottom: 0,
            left: 30,
            right: 30,
          },
        },
      };
    },
  },
};
</script>
<style scoped></style>
