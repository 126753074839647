<template>
  <div class="questionContainer">
    <ExpandableFlow
      v-for="(flow, index) in flows"
      :key="index"
      :flow="flow"
      :open="flow.open"
      @toggle="toggleCard(index)"
      @addFlow="handleAddFlow"
    />
  </div>
</template>

<script>
// import ExpandableQuestion from "./ExpandableQuestion.vue";
import ExpandableFlow from "./ExpandableFlow.vue";

export default {
  name: "AvailableQuestionsList",
  props: {
    drawerOpen: Boolean,
    flows: Array,
  },
  data() {
    return {
      submissionData: {},
    };
  },
  watch: {
    drawerData() {
      this.submissionData = {};
    },
  },
  methods: {
    toggleCard(index) {
      this.$emit("toggle", index, "FlowList");
    },
    handleAddFlow(flow) {
      this.$emit("addFlow", flow);
    },
  },
  components: {
    ExpandableFlow,
  },
  emits: [
    "toggle",
    "addFlow"
  ]
};
</script>

<style scoped>
.questionContainer {
  outline: 1px solid #271d7a;
  margin: 10px;
  height: 100%;
  overflow: auto;
}
</style>
