<template>
  <div class="detailCardContainer">
    <div class="contentContainer">
      
      <div class="notificationSubject">
        <!-- <p class="subjectLine">{{ notification.tracked_system }} - {{ notification.title }}</p> -->
        <p class="maintenanceUpdateText" v-if="notification.type == 'Maintenance Update'">{{ notification.tracked_system }} - {{ notification.title }}</p>
        <p class="incidentReportText" v-else-if="notification.type == 'Incident Report'">{{ notification.tracked_system }} - {{ notification.title }}</p>
        <p class="regularText" v-else>{{ notification.tracked_system }} - {{ notification.title }}</p>
        <div class="buttonContainer">
          <LightButton title="Delete" @click="handleDeleteNotification" />
        </div>
      </div>
      <div>
        <div class="notificationHeader">
          <div class="dateInfo">
            <p class="date">{{ computedDate }}</p>
            <p class="time">{{ computedTime }}</p>
          </div>
        </div>
        <p v-if="notification.type == 'Post'" style="padding-left: 10px;">{{ notification.status }}</p>
        <div class="notificationContent">
          <p class="notificationParagraph">
            {{ notification.content  }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LightButton from "../base_components/Buttons/LightButton.vue";

export default {
  name: "NotificationDetail",
  props: { notification: Object },
  computed: {
    computedDate() {
      const isoString = this.notification.createdAt;
      const date = new Date(isoString);
      const month = date.toLocaleString("default", { month: "long" });
      const day = date.getDate();

      return `${month} ${day}`;
    },
    computedTime() {
      const isoString = this.notification.createdAt;
      const date = new Date(isoString);
      let hours = date.getHours();
      const ampm = hours >= 12 ? 'PM' : "AM";

      if (hours > 12) {
        hours -= 12;
      }
      else if (hours === 0) {
        hours = 12;
      }

      const minutes = date.getMinutes();
      const time = `${hours}:${minutes.toString().padStart(2, '0')} ${ampm}`;

      return time;
    },
    computedSystemType() {
      return `${this.notification.type}`;
    },
  },
  components: { LightButton },
  emits: ["delete-notification", "close"],
  methods: {
    handleClickCloseButton() {
      this.$emit("close");
    },
    handleDeleteNotification() {
      console.log("Delete", this.notification);
      this.$emit("delete-notification", this.notification);
    },
  },
  data() {
    return {};
  },
};
</script>

<style scoped>
.detailCardContainer {
  border: 1px solid #271d7a;
  margin: 0 5px;
  width: 95%;
}

.contentContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 0;
  background-color: #ffffff;
}

.contentContainer .close {
  position: absolute;
  right: 10px;
  top: 5px;
  font-size: 24px;
  line-height: 0;
  width: 10px;
  height: 10px;
}
.notificationSubject {
  display: flex;
  align-items: center;
  outline: 1px solid #271d7a;
  padding: 10px;
}
.buttonContainer {
  position: absolute;
  right: 10px;
}
.subjectLine {
  padding-right: 60px;
}

.notificationHeader {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-top: 10px;
  padding: 0 10px;
  background-color: #ffffff;
}

.notificationHeader .close {
  position: absolute;
  right: 10px;
  top: 5px;
  font-size: 24px;
  line-height: 0;
  width: 10px;
  height: 10px;
}

.dateInfo {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding-top: 5px;
}
.date {
  padding-right: 10px;
  color: #271d7a;
  font-weight: 600;
  font-size: 11pt;
  display: flex;
  align-content: center;
}
.time {
  color: #271d7a;
  font-weight: 400;
  font-size: 10pt;
  display: flex;
  align-content: center;
  padding-top: 2px;
}
.notificationContent {
  overflow: hidden;
  padding: 10px 10px 10px 15px;
}
.notificationParagraph {
  height: 100%;
  overflow: hidden;
  color: #271d7a;
  font-weight: 400;
  font-size: 10pt;
}

.maintenanceUpdateText {
  color: orange;
  font-weight: 600;
  font-size: 11pt;
  padding-right: 60px;
}

.incidentReportText {
  color: red;
  font-weight: 600;
  font-size: 11pt;
  padding-right: 60px;
}

.regularText {
  color: #5c95d6;
  font-weight: 600;
  font-size: 11pt;
  padding-right: 60px;
}


</style>
