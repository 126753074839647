<template>
  <div class="flow-questions">
    <FormSideDrawer
      :drawerOpen="addQuestion || editQuestion || copyQuestion || applyAll || removeQuestion"
      buttonType="Close"
      :drawerData="getDrawerData"
      @button-click="handleButtonClick"
      @close-click="handleCloseClick"
      @submissionDataUpdate="handleSubmissionDataUpdate"
      @form-submission="handleFormSubmission"
      @confirm-edit="handleConfirmEdit"
      :previousSubmissionData="submissionData"
      :numCols="12"
      :width="500"
      :top-aligned="false"
      :clearForm="clearForm"
    />
    <div
      class="questionsContainer"
      :style="{ height: dynamicHeight - 20 + 'px' }"
    >
      <TableComp
        :headers="headers"
        :dataList="tableData"
        @selected-item="handleSelectedItem"
        @select-actions="handleSelectedAction"
        backgroundColor="#e2f1f4"
        :filterOptionsArray="filterOptionsArray"
        :select-all="false"
        :leftNavPresent="true"
        :fixed-width="true"
        :single-select="true"
        unique-key="questionID"
        @click-row="handleEditRow"
      ></TableComp>
    </div>
    <AlertsGroup
      v-model:alerts="alerts"
    />
  </div>
</template>
<script>
import AlertsGroup from '@/components/base_components/AlertsGroup.vue';
import FormSideDrawer from "@/components/CcManagement/Flows/FormSideDrawer.vue";
import TableComp from "@/components/CcManagement/Questions/TableComp.vue";
import { useFlowManagerStore } from "@/stores/ContactCenter/Management/FlowManagerStore.js";
import { mapStores, mapActions } from 'pinia';
import { addAlert } from '@/helpers/alertUtils';
import { SUCCESS, ERROR, NOTIFICATION } from "@/constants/statuses.constants.js";

export default {
  name: "QuestionsView",
  data() {
    return {
      clearForm: false,
      headers: [
        { text: "Question ID", value: "questionID" },
        { text: "Question", value: "question" },
        { text: "Options", value: "dynamicOptions" },
        { text: "Question Type", value: "questionType" },
        { text: "Flows Using Question", value: "flowsUsingQuestion"},
        { text: "", value: "edithover", width: 30 },
      ],
      data: [],
      addQuestionDrawerData: [
        {
          title: "Add Question",
          type: "form",
          button: " Add",
          buttonOptions: {
            positionBottom: true,
            centered: true,
            fillToParent: true,
          },
        },
        {
          title: "",
          inputs: [
            {
              name: "Question",
              value: "question",
              type: "textbox",
              defaultColor: "#000000", 
              showColorWheel: false,
              required: true,
            },
            {
              name: "Question Type",
              value: "questionType",
              type: "select",
              required: true,
              options: [
                { label: "Standard Text Field", value: "Standard Text Field" },
                { label: "CheckBox", value: "CheckBox" },
                { label: "Radio Button Fields", value: "Radio Button Fields" },
                { label: "LES Flag", value: "LES Flag" },
                { label: "Display Only", value: "Display Only" },
                { label: "Display In Notes", value: "Display In Notes" },
                { label: "Text Box", value: "Text Box" },
                { label: "Smart Link", value: "Smart Link" },
              ],
            },
          ],
        },
        {
          title: "",
          required: true,
          inputs: [
            { name: "dynamicOptions", type: "dynamicOptions", options: [] },
          ],
          show: false,
        },
        {},
      ],
      originalAddQuestionDrawerData: [
        {
          title: "Add Question",
          type: "form",
          button: " Add",
          buttonOptions: {
            positionBottom: true,
            centered: true,
            fillToParent: true,
          },
        },
        {
          title: "",
          inputs: [
            {
              name: "Question",
              value: "question",
              type: "textbox",
              defaultColor: "#000000", 
              showColorWheel: false,
              required: true,
            },
            {
              name: "Question Type",
              value: "questionType",
              type: "select",
              required: true,
              options: [
                { label: "Standard Text Field", value: "Standard Text Field" },
                { label: "CheckBox", value: "CheckBox" },
                { label: "Radio Button Fields", value: "Radio Button Fields" },
                { label: "LES Flag", value: "LES Flag" },
                { label: "Display Only", value: "Display Only" },
                { label: "Display In Notes", value: "Display In Notes" },
                { label: "Text Box", value: "Text Box" },
                { label: "Smart Link", value: "Smart Link" },
              ],
            },
          ],
        },
        {
          title: "",
          required: true,
          inputs: [
            { name: "dynamicOptions", type: "dynamicOptions", options: [] },
          ],
          show: false,
        },
        {},
      ],
      applyAllDrawerData: [
        {
          title: "Apply Question to Staging Flows",
          type: "form",
          button: "Apply",
          buttonOptions: {
            positionBottom: true,
            centered: true,
            fillToParent: true,
          },
        },
        {
          title: "",
          inputs: [
            {
              name: "Question",
              value: "question",
              type: "displayHTML",
              required: false,
            },
          ],
        },
        {
          title: "",
          inputs: [
            {
              name: "Note",
              value: "note",
              type: "displayHTML",
              required: false,
            },
          ],
        },
      ],
      removeQuestionDrawerData: [
        {
          title: "Remove Question",
          type: "form",
          button: "Remove",
          buttonOptions: {
            positionBottom: true,
            centered: true,
            fillToParent: true,
          },
        },
        {
          title: "",
          inputs: [
            {
              name: "Question",
              value: "question",
              type: "displayHTML",
              required: false,
            },
          ],
        },
      ],
      originalEditQuestionDrawerData: [
        {
          title: "Edit Question",
          type: "form",
          button: " Review",
          buttonOptions: {
            positionBottom: true,
            centered: true,
            fillToParent: true,
          },
        },
        {
          title: "",
          inputs: [
            {
              name: "Question",
              value: "question",
              type: "textbox",
              defaultColor: "#000000", 
              showColorWheel: false,
              required: true,
            },
            {
              name: "Question Type",
              type: "select",
              value: "questionType",
              // disabled: true,
              options: [
                { label: "Standard Text Field", value: "Standard Text Field" },
                { label: "CheckBox", value: "CheckBox" },
                { label: "Radio Button Fields", value: "Radio Button Fields" },
                { label: "LES Flag", value: "LES Flag" },
                { label: "Display Only", value: "Display Only" },
                { label: "Display In Notes", value: "Display In Notes" },
                { label: "Text Box", value: "Text Box" },
                { label: "Smart Link", value: "Smart Link" },
              ],
            },
          ],
        },
        {
          title: "",
          required: true,
          inputs: [
            { name: "dynamicOptions", type: "dynamicOptions", options: [] },
          ],
          show: false,
        },
        {},
      ],
      editQuestionDrawerData: [
        {
          title: "Edit Question",
          type: "form",
          button: " Review",
          buttonOptions: {
            positionBottom: true,
            centered: true,
            fillToParent: true,
          },
        },
        {
          title: "",
          inputs: [
            {
              name: "Question",
              value: "question",
              type: "textbox",
              defaultColor: "#000000", 
              showColorWheel: false,
              required: true,
            },
            {
              name: "Question Type",
              type: "select",
              value: "questionType",
              // disabled: true,
              options: [
                { label: "Standard Text Field", value: "Standard Text Field" },
                { label: "CheckBox", value: "CheckBox" },
                { label: "Radio Button Fields", value: "Radio Button Fields" },
                { label: "LES Flag", value: "LES Flag" },
                { label: "Display Only", value: "Display Only" },
                { label: "Display In Notes", value: "Display In Notes" },
                { label: "Text Box", value: "Text Box" },
                { label: "Smart Link", value: "Smart Link" },
              ],
            },
          ],
        },
        {
          title: "",
          required: true,
          inputs: [
            { name: "dynamicOptions", type: "dynamicOptions", options: [] },
          ],
          show: false,
        },
        {},
      ],
      questionTypes: [
        { label: "Standard Text Field", value: "Standard Text Field" },
        { label: "CheckBox", value: "CheckBox" },
        { label: "Radio Button Fields", value: "Radio Button Fields" },
        { label: "LES Flag", value: "LES Flag" },
        { label: "Display Only", value: "Display Only" },
        { label: "Display In Notes", value: "Display In Notes" },
        { label: "Text Box", value: "Text Box" },
        { label: "Smart Link", value: "Smart Link" },
      ],
      reviewDrawerData: [],
      alerts: [],
      submissionData: {},
      selectedItem: [],
    };
  },
  props: {
    filterOptionsArray: Array,
    dynamicHeight: Number,
    addQuestion: Boolean,
    editQuestion: Boolean,
    searchValue: String,
    copyQuestionItem: Object,
    copyQuestion: Boolean,
    applyAll: Boolean,
    removeQuestion: Boolean,
  },
  methods: {
    ...mapActions(useFlowManagerStore, ['getQuestions', 'getFlows', 'updateFlowQuestions', 'saveFlowQuestions', 'removeFlowQuestions']),
    handleSelectedAction(item, action) {
      if(action.value == "apply") {
        this.handleApplyQuestion(item);
      } else if (action.value == "edit") {
        this.handleEditRow(item, '');
      } else if(action.value == "remove") {
        this.handleRemoveRow(item);
      } else {
        this.handleCopyRow(item);
      }
    },
    applyQuestionToFlows(selectedQuestion) {
      console.log(selectedQuestion);
    },
    handleSelectedItem(selectedItem) {
      this.selectedItem = selectedItem;
      this.$emit("selected-item", selectedItem);
    },
    handleCopyRow(item) {
      var newItem = JSON.parse(JSON.stringify(item));
      this.selectedItem = newItem;
      this.$emit("selected-item", newItem);
      this.$nextTick(() => {
        this.$emit('copy-row');
      });
    },
    handleRemoveRow(item) {
      var newItem = JSON.parse(JSON.stringify(item));

      this.submissionData = {
        question: newItem.question,
        id: newItem.id,
        _version: newItem._version,
      };

      this.selectedItem = newItem;
      this.$emit("selected-item", newItem);

      this.$nextTick(() => {
        this.$emit('remove-question');
      });
    },
    handleApplyQuestion(item) {
      this.$emit("apply-all");

      this.submissionData = {
        question: item.question,
        questionType: item.questionType,
        dynamicOptions: item.dynamicOptions,
        id: item.id,
        _version: item._version,
        flows_list: item.flows_list,
        note: "<p style='font-size: 14px;'>This will overwrite anywhere the question appears in staging flows, with the updated question.  Please wait until it finishes executing before doing anything or closing this drawer.</p>"
      };
    },
    handleEditRow(item, $event) {
      console.log("ITEM: ", item);
      console.log("EVENT: ", $event);
      
      var newItem = JSON.parse(JSON.stringify(item));

      this.submissionData = {
        question: newItem.question,
        questionType: newItem.questionType,
        dynamicOptions: [...newItem.dynamicOptions],
        id: newItem.id,
        _version: newItem._version,
      }



      // this.submissionData = item;
      console.log("HANDLE EDIT ROW");
      console.log("item", newItem);
      this.updateDynamicOptionsObjectIndex(this.editQuestionDrawerData, newItem);
      this.$emit("edit-row", $event);
    },
    async handleFormSubmission(formData) {

      console.log(formData);

      if (formData === "Error") {
        addAlert(ERROR, "Missing Required Values", this.alerts);
      } else {
        if (this.addQuestion || this.copyQuestion) {
          console.log("submitted data add and copy", formData);
          var question = formData.question;
          var questionType = formData.questionType.value;
          var questionOptions = "";
          if(formData.dynamicOptions) {
            questionOptions = formData.dynamicOptions ? JSON.stringify(formData.dynamicOptions) : "";
          }

          if(questionType == "LES Flag") {
            questionOptions = JSON.stringify([{label: true, value: true}, {label: false, value: false}]);
          }

          var questionLink = formData["Enter url"] ? formData["Enter url"] : "";
          await this.saveFlowQuestions(question, questionType, questionOptions, questionLink);
          addAlert(SUCCESS, "Successfully added Question!", this.alerts);
          var initialData = this.FlowManagerStore.fetchQuestions;
          for(var i of initialData) {
            i.options = [
              { label: "Apply All", value: "apply" },
              { label: "Edit", value: "edit" },
              { label: "Copy", value: "copy" },
              { label: "Remove", value: "remove" },
            ]
          }
          this.data = initialData;
          this.submissionData = {};
          this.addQuestionDrawerData = [...this.originalAddQuestionDrawerData];
          this.$emit("toggleDrawer", "all", false);
        } else if (this.editQuestion) {
          var question_type = formData.questionType.value;
          const formInputs = [
            {
              title: this.editQuestion ? "Edit Question" : "Add Question",
              type: "review",
              button: "Acknowledge",
            },
          ];
          let drawerData = this.editQuestion
            ? this.editQuestionDrawerData
            : this.addQuestionDrawerData;

          var adjustedFormData = {...formData};

            if(question_type == "LES Flag") {
              var options = [{label: true, value: true}, {label: false, value: false}];
              if(formData.dynamicOptions) {
                adjustedFormData.dynamicOptions = options;
              } else {
                adjustedFormData = {...formData, ...{dynamicOptions: options}};
              }
            }

          for (const data of drawerData.slice(1, drawerData.length)) {
            if (Array.isArray(data.inputs)) {
              const inputs = [];
              for (const input of data.inputs) {
                let value =
                adjustedFormData[input.name] ||
                adjustedFormData[input.value]?.label ||
                adjustedFormData[input.value];

                //Find label of Question Type if given a value
                const item = this.questionTypes.find(
                  (item) => item.value === value
                );
                if (item) {
                  value = item.label;
                }

                const updatedInput = { ...input, value };
                inputs.push(updatedInput);
              }
              const updatedData = { ...data, inputs };

              formInputs.push(updatedData);
            } else {
              formInputs.push(data);
            }
          }
          
          this.submissionData = adjustedFormData;
          this.reviewDrawerData = formInputs;
        } else if (this.applyAll) {
          addAlert(NOTIFICATION, "Applying Changes", this.alerts);
          var result = await this.FlowManagerStore.applyQuestionToFlows(formData.id);

          if(result.error) {
            addAlert(ERROR, "An Error Occurred", this.alerts);
          } else {
            addAlert(SUCCESS, "Changes applied to Staging Flows", this.alerts);
            var initialData2 = this.FlowManagerStore.fetchQuestions;
            for(var j of initialData2) {
              j.options = [
                { label: "Apply All", value: "apply" },
                { label: "Edit", value: "edit" },
                { label: "Copy", value: "copy" },
                { label: "Remove", value: "remove" },
              ]
            }
            this.data = initialData2;
            this.submissionData = {};
            this.$emit("toggleDrawer", "all", false);
          }
        } else if (this.removeQuestion) {
          var result2 = await this.FlowManagerStore.removeFlowQuestions(formData.id, formData._version);
          if(result2.error) {
            addAlert(ERROR, "An Error Occurred", this.alerts);
          } else {
            addAlert(SUCCESS, "Question Removed", this.alerts);
            var initialData3 = this.FlowManagerStore.fetchQuestions;
            for(var k of initialData3) {
              k.options = [
                { label: "Apply All", value: "apply" },
                { label: "Edit", value: "edit" },
                { label: "Copy", value: "copy" },
                { label: "Remove", value: "remove" },
              ]
            }
            this.data = initialData3;
            this.submissionData = {};
            this.$emit("toggleDrawer", "all", false);
          }
        }
      }
    },
    updateDynamicOptionsObjectIndex(drawerData, formData) {
      const dynamicOptionsObjectIndex = drawerData.findIndex((section) =>
        section?.inputs?.some((input) => input.type === "dynamicOptions")
      );

      let questionType =
        formData["Question Type"] ||
        formData.questionType ||
        formData.questionType?.value;
      questionType = questionType?.value ? questionType.value : questionType;

      if (dynamicOptionsObjectIndex !== -1) {
        drawerData[dynamicOptionsObjectIndex].show =
          questionType === "CheckBox" || questionType === "Radio Button Fields"
      }

      let obj = {
        title: "",
        inputs: [],
        show: true,
      };

      if (
        drawerData[2].title === "Smart Link" &&
        questionType !== "Smart Link"
      ) {
        drawerData.splice(2, 1);
      }

      if (
        questionType === "CheckBox" &&
        formData["dynamicOptions"] &&
        formData["dynamicOptions"].length
      ) {
        obj.inputs = [
          {
            name: formData.question,
            type: "radio",
            options: [...formData["dynamicOptions"]],
            outlined: true,
            centered: true,
            label: "Select all that Apply",
            multiSelect: true,
            required: false,
          },
        ];

        drawerData[drawerData.length - 1] = obj;
      } else if (
        (questionType === "Radio Button Fields") &&
        formData["dynamicOptions"] &&
        formData["dynamicOptions"].length
      ) {
        obj.inputs = [
          {
            name: formData.question,
            type: "radio",
            options: [...formData["dynamicOptions"]],
            outlined: true,
            centered: false,
            label: "",
            multiSelect: false,
            required: false,
          },
        ];

        drawerData[drawerData.length - 1] = obj;
      } else if (questionType === "Standard Text Field") {
        obj.inputs = [
          {
            name: formData.question,
            value: "Standard Text Field",
            type: "bubble",
            test: true,
            required: false,
          },
        ];
        delete formData["dynamicOptions"];
        drawerData[drawerData.length - 1] = obj;
      } else if (questionType === "LES Flag") {
        obj.inputs = [
          {
            name: formData.question,
            type: "radio",
            options: [{label: "true", value: true},{label: "false", value: false}],
            outlined: true,
            centered: false,
            label: "",
            multiSelect: false,
            required: false,
          },
        ];
        delete formData["dynamicOptions"];
        drawerData[drawerData.length - 1] = obj;
      } else if (questionType === "Display Only") {
        obj.inputs = [
          {
            name: formData.question,
            value: "Display Only",
            type: "textarea",
            test: true,
            readonly: false,
            required: false,
          },
        ];
        delete formData["dynamicOptions"];
        drawerData[drawerData.length - 1] = obj;
      } else if (questionType === "Display In Notes") {
        obj.inputs = [
          {
            name: formData.question,
            value: "Display In Notes",
            type: "textarea",
            test: true,
            readonly: false,
            required: false,
          },
        ];
        delete formData["dynamicOptions"];
        drawerData[drawerData.length - 1] = obj;
      } else if (questionType === "Text Box") {
        obj.inputs = [
          {
            name: formData.question,
            value: "textbox",
            type: "textarea",
            test: true,
            readonly: false,
            required: false,
          },
        ];
        delete formData["dynamicOptions"];
        drawerData[drawerData.length - 1] = obj;
      } else if (questionType === "Smart Link") {
        console.log("drawerData[2]", drawerData[2]);
        console.log("formData", formData);
        if (drawerData[2].title !== "Smart Link") {
          const objectToPush = {
            title: "Smart Link",
            required: true,
            inputs: [
              {
                name: "Enter url",
                value: "Smart Link",
                type: "Smart Link",
                url: formData["Enter url"],
                required: true,
              },
            ],
          };
          drawerData.splice(2, 0, objectToPush);
        }
        else{
          drawerData[2].inputs[0].url = formData["Enter url"];
        }
        
        obj.inputs = [
          {
            name: formData.question,
            value: "smartLinkView",
            type: "smartLinkView",
            url: formData["Enter url"],
            test: true,
            required: false,
          },
        ];
        delete formData["dynamicOptions"];
        drawerData[drawerData.length - 1] = obj;
      } else {
        drawerData[drawerData.length - 1] = {};
      }
    },
    handleSubmissionDataUpdate(data) {
      if (this.addQuestion) {
        this.updateDynamicOptionsObjectIndex(this.addQuestionDrawerData, data);
      } else if (this.editQuestion) {

        this.updateDynamicOptionsObjectIndex(this.editQuestionDrawerData, data);
        if(this.submissionData.dynamicOptions && data.dynamicOptions) {
          this.submissionData.dynamicOptions = data.dynamicOptions;
        }
      } else if (this.copyQuestion) {
        this.updateDynamicOptionsObjectIndex(this.addQuestionDrawerData, data);
        if(this.submissionData.dynamicOptions && data.dynamicOptions) {
          this.submissionData.dynamicOptions = data.dynamicOptions;
        }
      }
    },
    handleButtonClick(title) {
      if (title === "Add Question") {
        this.$emit("toggleDrawer", "addQuestion", !this.addQuestion);
      } else if (title === "Back") {
        this.reviewDrawerData = [];
      }
    },
    handleCloseClick() {
      this.submissionData = {};
      this.reviewDrawerData = [];
      this.$emit("toggleDrawer", "all", false);
    },
    async handleConfirmEdit(confirmEdit) {

      console.log("submitted data edit", this.submissionData);
      console.log("edit drawer data edit", this.reviewDrawerData);
      if (confirmEdit && this.editQuestion) {
        
        var id = this.submissionData.id;
        var question = this.submissionData.question;
        var questionType = this.submissionData.questionType.value;
        var questionOptions = "";
        if(this.submissionData.dynamicOptions) {
          questionOptions = this.submissionData.dynamicOptions ? JSON.stringify(this.submissionData.dynamicOptions) : "";
        }
        // else if (this.submissionData.options){
        //   questionOptions = this.submissionData.options ? JSON.stringify(this.submissionData.options) : "";
        // }
        var questionLink = this.submissionData["Enter url"] ? this.submissionData["Enter url"] : "";
        var version = this.submissionData._version;
        await this.updateFlowQuestions(id, question, questionType, questionOptions, questionLink, version);
        addAlert(SUCCESS, "Successfully edited Question!", this.alerts);
        var initialData = this.FlowManagerStore.fetchQuestions;
        for(var i of initialData) {
          i.options = [
            { label: "Apply All", value: "apply" },
            { label: "Edit", value: "edit" },
            { label: "Copy", value: "copy" },
            { label: "Remove", value: "remove" },
          ]
        }
        this.data = initialData;
        this.$emit("toggleDrawer", "editQuestion", !this.editQuestion);
        this.reviewDrawerData = [];
        this.submissionData = {};
      } else if (confirmEdit && (this.copyQuestion || this.addQuestion || this.applyAll)) {
        addAlert(SUCCESS, "Successfully added Question!", this.alerts);
        this.$emit("toggleDrawer", "all", false);
        this.reviewDrawerData = [];
        this.submissionData = {};
      }
    }
  },
  computed: {
    ...mapStores(useFlowManagerStore),
    getDrawerData() {
      if (this.editQuestion) {
        if (this.reviewDrawerData.length) {
          return this.reviewDrawerData;
        } else {
          return this.editQuestionDrawerData;
        }
      } else if (this.copyQuestion) {
        if (this.reviewDrawerData.length) {
          return this.reviewDrawerData;
        } else {
          return this.addQuestionDrawerData;
        }
      } else if(this.applyAll) {
        return this.applyAllDrawerData;
      } else if(this.removeQuestion) {
        return this.removeQuestionDrawerData;
      } else {
        return this.addQuestionDrawerData;
      }
    },
    tableData() {
      return this.data.filter((item) => {
        return Object.values(item).some((value) => {
          if (typeof value === "string") {
            return String(value)
              .toLowerCase()
              .trim()
              .includes(this.searchValue?.toLowerCase().trim());
          } else if (Array.isArray(value) && value !== null) {
            return value.some((val) =>
              String(val?.label || val?.value)
                .toLowerCase()
                .trim()
                .includes(this.searchValue?.toLowerCase().trim())
            );
          }
        });
      });
    },
  },
  beforeUnmount() {
    this.submissionData = {};
    this.reviewDrawerData = [];
    this.$emit("toggleDrawer", "all", false);
  },
  async mounted() {
    await this.getFlows();
    await this.getQuestions();
    var initialData = this.FlowManagerStore.fetchQuestions;
    for(var i of initialData) {
      i.options = [
        { label: "Apply All", value: "apply" },
        { label: "Edit", value: "edit" },
        { label: "Copy", value: "copy" },
        { label: "Remove", value: "remove" },
      ]
    }
    this.data = initialData;
  },
  watch: {
    copyQuestion: {
      immediate: true,
      deep: true,
      handler(question) {
        if (question) {
          let item = this.selectedItem;
          this.submissionData = item;
          if (
            item?.questionType === "CheckBox" ||
            item?.questionType === "Radio Button Fields"
          ) {
            this.updateDynamicOptionsObjectIndex(
              this.addQuestionDrawerData,
              item
            );
          }
        }
      },
    },
    addQuestion: {
      handler(newVal) {
        if (newVal) {
          this.clearForm = true;
          this.submissionData = {};
          this.addQuestionDrawerData = [...this.originalAddQuestionDrawerData];
        }
      },
    },
    editQuestion: {
      handler(newVal) {
        if (newVal == false) {
          this.clearForm = true;
          this.submissionData = {};
          this.editQuestionDrawerData = [...this.originalEditQuestionDrawerData];
        }
      },
    },
    clearForm: {
      handler(newVal) {
        if (newVal) {
          this.clearForm = false;
        }
      },
    }
  },
  components: { TableComp, FormSideDrawer, AlertsGroup },
  emits: ["edit-row", "toggleDrawer", "selected-item"],
};
</script>
<style scoped>
.questionsContainer {
  padding: 20px;
  width: 100%;
  background-color: #e2f1f4;
}
@media (max-width: 820px) {
  .questionsContainer {
    width: 100%;
  }
}
.flow-questions :deep(.radioInputLabel.center) {
  justify-content: flex-start;
  margin-left: 3px;
}

.flow-questions :deep(.radioOptionContainer.center) {
  justify-content: flex-start;
  margin-left: 3px;
}
.flow-questions :deep(.dynamicOptionAdd) {
  margin-left: 50%;
  transform: translateX(-50%);
}

:deep(.dynamicOptionContainer:last-of-type) {
  margin-bottom: 0px;
}

:deep(.customize-table) {
  padding-bottom: 100px;
  background-color: #ffffff;
}
</style>
