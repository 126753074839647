/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createNonLower48TFRates = /* GraphQL */ `
  mutation CreateNonLower48TFRates(
    $input: CreateNonLower48TFRatesInput!
    $condition: ModelNonLower48TFRatesConditionInput
  ) {
    createNonLower48TFRates(input: $input, condition: $condition) {
      id
      usage_plan_group
      description
      retail_rate
      wholesale_rate
      country
      area_code
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateNonLower48TFRates = /* GraphQL */ `
  mutation UpdateNonLower48TFRates(
    $input: UpdateNonLower48TFRatesInput!
    $condition: ModelNonLower48TFRatesConditionInput
  ) {
    updateNonLower48TFRates(input: $input, condition: $condition) {
      id
      usage_plan_group
      description
      retail_rate
      wholesale_rate
      country
      area_code
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteNonLower48TFRates = /* GraphQL */ `
  mutation DeleteNonLower48TFRates(
    $input: DeleteNonLower48TFRatesInput!
    $condition: ModelNonLower48TFRatesConditionInput
  ) {
    deleteNonLower48TFRates(input: $input, condition: $condition) {
      id
      usage_plan_group
      description
      retail_rate
      wholesale_rate
      country
      area_code
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createApplicationSections = /* GraphQL */ `
  mutation CreateApplicationSections(
    $input: CreateApplicationSectionsInput!
    $condition: ModelApplicationSectionsConditionInput
  ) {
    createApplicationSections(input: $input, condition: $condition) {
      id
      icon
      title
      menuItems
      route
      allowed_groups
      order
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateApplicationSections = /* GraphQL */ `
  mutation UpdateApplicationSections(
    $input: UpdateApplicationSectionsInput!
    $condition: ModelApplicationSectionsConditionInput
  ) {
    updateApplicationSections(input: $input, condition: $condition) {
      id
      icon
      title
      menuItems
      route
      allowed_groups
      order
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteApplicationSections = /* GraphQL */ `
  mutation DeleteApplicationSections(
    $input: DeleteApplicationSectionsInput!
    $condition: ModelApplicationSectionsConditionInput
  ) {
    deleteApplicationSections(input: $input, condition: $condition) {
      id
      icon
      title
      menuItems
      route
      allowed_groups
      order
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createAnalytics = /* GraphQL */ `
  mutation CreateAnalytics(
    $input: CreateAnalyticsInput!
    $condition: ModelAnalyticsConditionInput
  ) {
    createAnalytics(input: $input, condition: $condition) {
      id
      name
      category
      created_by
      attributes
      availability
      report_layout
      report_objects {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateAnalytics = /* GraphQL */ `
  mutation UpdateAnalytics(
    $input: UpdateAnalyticsInput!
    $condition: ModelAnalyticsConditionInput
  ) {
    updateAnalytics(input: $input, condition: $condition) {
      id
      name
      category
      created_by
      attributes
      availability
      report_layout
      report_objects {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteAnalytics = /* GraphQL */ `
  mutation DeleteAnalytics(
    $input: DeleteAnalyticsInput!
    $condition: ModelAnalyticsConditionInput
  ) {
    deleteAnalytics(input: $input, condition: $condition) {
      id
      name
      category
      created_by
      attributes
      availability
      report_layout
      report_objects {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createAnalyticReportObjects = /* GraphQL */ `
  mutation CreateAnalyticReportObjects(
    $input: CreateAnalyticReportObjectsInput!
    $condition: ModelAnalyticReportObjectsConditionInput
  ) {
    createAnalyticReportObjects(input: $input, condition: $condition) {
      id
      analytic_id
      title
      table
      chart
      data_source
      data_query
      data_filter
      order
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateAnalyticReportObjects = /* GraphQL */ `
  mutation UpdateAnalyticReportObjects(
    $input: UpdateAnalyticReportObjectsInput!
    $condition: ModelAnalyticReportObjectsConditionInput
  ) {
    updateAnalyticReportObjects(input: $input, condition: $condition) {
      id
      analytic_id
      title
      table
      chart
      data_source
      data_query
      data_filter
      order
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteAnalyticReportObjects = /* GraphQL */ `
  mutation DeleteAnalyticReportObjects(
    $input: DeleteAnalyticReportObjectsInput!
    $condition: ModelAnalyticReportObjectsConditionInput
  ) {
    deleteAnalyticReportObjects(input: $input, condition: $condition) {
      id
      analytic_id
      title
      table
      chart
      data_source
      data_query
      data_filter
      order
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createDisconnectRequests = /* GraphQL */ `
  mutation CreateDisconnectRequests(
    $input: CreateDisconnectRequestsInput!
    $condition: ModelDisconnectRequestsConditionInput
  ) {
    createDisconnectRequests(input: $input, condition: $condition) {
      id
      service
      status
      disconnect_date
      requester
      comment
      full_disconnect
      account_number
      partner
      phone_numbers
      ticket_number
      port_out
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateDisconnectRequests = /* GraphQL */ `
  mutation UpdateDisconnectRequests(
    $input: UpdateDisconnectRequestsInput!
    $condition: ModelDisconnectRequestsConditionInput
  ) {
    updateDisconnectRequests(input: $input, condition: $condition) {
      id
      service
      status
      disconnect_date
      requester
      comment
      full_disconnect
      account_number
      partner
      phone_numbers
      ticket_number
      port_out
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteDisconnectRequests = /* GraphQL */ `
  mutation DeleteDisconnectRequests(
    $input: DeleteDisconnectRequestsInput!
    $condition: ModelDisconnectRequestsConditionInput
  ) {
    deleteDisconnectRequests(input: $input, condition: $condition) {
      id
      service
      status
      disconnect_date
      requester
      comment
      full_disconnect
      account_number
      partner
      phone_numbers
      ticket_number
      port_out
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createDisconnectEmailRecipient = /* GraphQL */ `
  mutation CreateDisconnectEmailRecipient(
    $input: CreateDisconnectEmailRecipientInput!
    $condition: ModelDisconnectEmailRecipientConditionInput
  ) {
    createDisconnectEmailRecipient(input: $input, condition: $condition) {
      id
      partner
      request_notification_email
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateDisconnectEmailRecipient = /* GraphQL */ `
  mutation UpdateDisconnectEmailRecipient(
    $input: UpdateDisconnectEmailRecipientInput!
    $condition: ModelDisconnectEmailRecipientConditionInput
  ) {
    updateDisconnectEmailRecipient(input: $input, condition: $condition) {
      id
      partner
      request_notification_email
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteDisconnectEmailRecipient = /* GraphQL */ `
  mutation DeleteDisconnectEmailRecipient(
    $input: DeleteDisconnectEmailRecipientInput!
    $condition: ModelDisconnectEmailRecipientConditionInput
  ) {
    deleteDisconnectEmailRecipient(input: $input, condition: $condition) {
      id
      partner
      request_notification_email
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createSegraNumbers = /* GraphQL */ `
  mutation CreateSegraNumbers(
    $input: CreateSegraNumbersInput!
    $condition: ModelSegraNumbersConditionInput
  ) {
    createSegraNumbers(input: $input, condition: $condition) {
      id
      number
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateSegraNumbers = /* GraphQL */ `
  mutation UpdateSegraNumbers(
    $input: UpdateSegraNumbersInput!
    $condition: ModelSegraNumbersConditionInput
  ) {
    updateSegraNumbers(input: $input, condition: $condition) {
      id
      number
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteSegraNumbers = /* GraphQL */ `
  mutation DeleteSegraNumbers(
    $input: DeleteSegraNumbersInput!
    $condition: ModelSegraNumbersConditionInput
  ) {
    deleteSegraNumbers(input: $input, condition: $condition) {
      id
      number
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createUpdateRequests = /* GraphQL */ `
  mutation CreateUpdateRequests(
    $input: CreateUpdateRequestsInput!
    $condition: ModelUpdateRequestsConditionInput
  ) {
    createUpdateRequests(input: $input, condition: $condition) {
      id
      account_number
      accountStatus
      customerName
      custType
      connDate
      servDesc
      status
      monthlyRate
      name
      first_name
      last_name
      email
      address1
      city
      state
      zip
      E911Address
      E911City
      E911State
      E911Zip
      telephone
      DataPackage
      VoicePackages
      VideoPackage
      FaxPackage
      transferFlag
      new_phone1
      sipPassword1
      old_account_number
      provisioned
      in_process
      errors
      PIN
      isManagedService
      created_on
      billingFlag
      taxFlag
      accessSFP
      billable
      isXML
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateUpdateRequests = /* GraphQL */ `
  mutation UpdateUpdateRequests(
    $input: UpdateUpdateRequestsInput!
    $condition: ModelUpdateRequestsConditionInput
  ) {
    updateUpdateRequests(input: $input, condition: $condition) {
      id
      account_number
      accountStatus
      customerName
      custType
      connDate
      servDesc
      status
      monthlyRate
      name
      first_name
      last_name
      email
      address1
      city
      state
      zip
      E911Address
      E911City
      E911State
      E911Zip
      telephone
      DataPackage
      VoicePackages
      VideoPackage
      FaxPackage
      transferFlag
      new_phone1
      sipPassword1
      old_account_number
      provisioned
      in_process
      errors
      PIN
      isManagedService
      created_on
      billingFlag
      taxFlag
      accessSFP
      billable
      isXML
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteUpdateRequests = /* GraphQL */ `
  mutation DeleteUpdateRequests(
    $input: DeleteUpdateRequestsInput!
    $condition: ModelUpdateRequestsConditionInput
  ) {
    deleteUpdateRequests(input: $input, condition: $condition) {
      id
      account_number
      accountStatus
      customerName
      custType
      connDate
      servDesc
      status
      monthlyRate
      name
      first_name
      last_name
      email
      address1
      city
      state
      zip
      E911Address
      E911City
      E911State
      E911Zip
      telephone
      DataPackage
      VoicePackages
      VideoPackage
      FaxPackage
      transferFlag
      new_phone1
      sipPassword1
      old_account_number
      provisioned
      in_process
      errors
      PIN
      isManagedService
      created_on
      billingFlag
      taxFlag
      accessSFP
      billable
      isXML
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createProvisionRequests = /* GraphQL */ `
  mutation CreateProvisionRequests(
    $input: CreateProvisionRequestsInput!
    $condition: ModelProvisionRequestsConditionInput
  ) {
    createProvisionRequests(input: $input, condition: $condition) {
      id
      customerName
      account_number
      accountStatus
      name
      first_name
      last_name
      address1
      city
      state
      zip
      telephone
      servDesc
      DataPackage
      email
      custType
      E911Address
      E911City
      E911State
      E911Zip
      VoicePackages
      VideoPackage
      FaxPackage
      monthlyRate
      new_phone1
      ported1
      sipPassword1
      numLines
      DIDs
      DIDdetail
      SONbr
      CallerIDName
      new_phone2
      ported2
      sipPassword2
      features
      CO_Name
      provisioned
      in_process
      errors
      PIN
      isManagedService
      created_on
      billingFlag
      taxFlag
      accessSFP
      billable
      isXML
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateProvisionRequests = /* GraphQL */ `
  mutation UpdateProvisionRequests(
    $input: UpdateProvisionRequestsInput!
    $condition: ModelProvisionRequestsConditionInput
  ) {
    updateProvisionRequests(input: $input, condition: $condition) {
      id
      customerName
      account_number
      accountStatus
      name
      first_name
      last_name
      address1
      city
      state
      zip
      telephone
      servDesc
      DataPackage
      email
      custType
      E911Address
      E911City
      E911State
      E911Zip
      VoicePackages
      VideoPackage
      FaxPackage
      monthlyRate
      new_phone1
      ported1
      sipPassword1
      numLines
      DIDs
      DIDdetail
      SONbr
      CallerIDName
      new_phone2
      ported2
      sipPassword2
      features
      CO_Name
      provisioned
      in_process
      errors
      PIN
      isManagedService
      created_on
      billingFlag
      taxFlag
      accessSFP
      billable
      isXML
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteProvisionRequests = /* GraphQL */ `
  mutation DeleteProvisionRequests(
    $input: DeleteProvisionRequestsInput!
    $condition: ModelProvisionRequestsConditionInput
  ) {
    deleteProvisionRequests(input: $input, condition: $condition) {
      id
      customerName
      account_number
      accountStatus
      name
      first_name
      last_name
      address1
      city
      state
      zip
      telephone
      servDesc
      DataPackage
      email
      custType
      E911Address
      E911City
      E911State
      E911Zip
      VoicePackages
      VideoPackage
      FaxPackage
      monthlyRate
      new_phone1
      ported1
      sipPassword1
      numLines
      DIDs
      DIDdetail
      SONbr
      CallerIDName
      new_phone2
      ported2
      sipPassword2
      features
      CO_Name
      provisioned
      in_process
      errors
      PIN
      isManagedService
      created_on
      billingFlag
      taxFlag
      accessSFP
      billable
      isXML
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createPricebookDefaultProducts = /* GraphQL */ `
  mutation CreatePricebookDefaultProducts(
    $input: CreatePricebookDefaultProductsInput!
    $condition: ModelPricebookDefaultProductsConditionInput
  ) {
    createPricebookDefaultProducts(input: $input, condition: $condition) {
      id
      PricebookProducts {
        nextToken
        startedAt
        __typename
      }
      user_defined_product
      name
      nickname
      primary_product_code
      secondary_product_code
      unit_price
      wholesale_price
      frequency
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updatePricebookDefaultProducts = /* GraphQL */ `
  mutation UpdatePricebookDefaultProducts(
    $input: UpdatePricebookDefaultProductsInput!
    $condition: ModelPricebookDefaultProductsConditionInput
  ) {
    updatePricebookDefaultProducts(input: $input, condition: $condition) {
      id
      PricebookProducts {
        nextToken
        startedAt
        __typename
      }
      user_defined_product
      name
      nickname
      primary_product_code
      secondary_product_code
      unit_price
      wholesale_price
      frequency
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deletePricebookDefaultProducts = /* GraphQL */ `
  mutation DeletePricebookDefaultProducts(
    $input: DeletePricebookDefaultProductsInput!
    $condition: ModelPricebookDefaultProductsConditionInput
  ) {
    deletePricebookDefaultProducts(input: $input, condition: $condition) {
      id
      PricebookProducts {
        nextToken
        startedAt
        __typename
      }
      user_defined_product
      name
      nickname
      primary_product_code
      secondary_product_code
      unit_price
      wholesale_price
      frequency
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createPricebookProducts = /* GraphQL */ `
  mutation CreatePricebookProducts(
    $input: CreatePricebookProductsInput!
    $condition: ModelPricebookProductsConditionInput
  ) {
    createPricebookProducts(input: $input, condition: $condition) {
      id
      pricebooksID
      unit_price
      wholesale_price
      enabled
      pricebookdefaultproductsID
      pricebook_default_product {
        id
        user_defined_product
        name
        nickname
        primary_product_code
        secondary_product_code
        unit_price
        wholesale_price
        frequency
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updatePricebookProducts = /* GraphQL */ `
  mutation UpdatePricebookProducts(
    $input: UpdatePricebookProductsInput!
    $condition: ModelPricebookProductsConditionInput
  ) {
    updatePricebookProducts(input: $input, condition: $condition) {
      id
      pricebooksID
      unit_price
      wholesale_price
      enabled
      pricebookdefaultproductsID
      pricebook_default_product {
        id
        user_defined_product
        name
        nickname
        primary_product_code
        secondary_product_code
        unit_price
        wholesale_price
        frequency
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deletePricebookProducts = /* GraphQL */ `
  mutation DeletePricebookProducts(
    $input: DeletePricebookProductsInput!
    $condition: ModelPricebookProductsConditionInput
  ) {
    deletePricebookProducts(input: $input, condition: $condition) {
      id
      pricebooksID
      unit_price
      wholesale_price
      enabled
      pricebookdefaultproductsID
      pricebook_default_product {
        id
        user_defined_product
        name
        nickname
        primary_product_code
        secondary_product_code
        unit_price
        wholesale_price
        frequency
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createPricebooks = /* GraphQL */ `
  mutation CreatePricebooks(
    $input: CreatePricebooksInput!
    $condition: ModelPricebooksConditionInput
  ) {
    createPricebooks(input: $input, condition: $condition) {
      id
      partner
      coop_table_id
      nickname
      partner_account_number
      PricebookProducts {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updatePricebooks = /* GraphQL */ `
  mutation UpdatePricebooks(
    $input: UpdatePricebooksInput!
    $condition: ModelPricebooksConditionInput
  ) {
    updatePricebooks(input: $input, condition: $condition) {
      id
      partner
      coop_table_id
      nickname
      partner_account_number
      PricebookProducts {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deletePricebooks = /* GraphQL */ `
  mutation DeletePricebooks(
    $input: DeletePricebooksInput!
    $condition: ModelPricebooksConditionInput
  ) {
    deletePricebooks(input: $input, condition: $condition) {
      id
      partner
      coop_table_id
      nickname
      partner_account_number
      PricebookProducts {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createGroups = /* GraphQL */ `
  mutation CreateGroups(
    $input: CreateGroupsInput!
    $condition: ModelGroupsConditionInput
  ) {
    createGroups(input: $input, condition: $condition) {
      id
      Name
      cognito_name
      Applications {
        nextToken
        startedAt
        __typename
      }
      PageLevelPermissions
      allowed_permissions
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateGroups = /* GraphQL */ `
  mutation UpdateGroups(
    $input: UpdateGroupsInput!
    $condition: ModelGroupsConditionInput
  ) {
    updateGroups(input: $input, condition: $condition) {
      id
      Name
      cognito_name
      Applications {
        nextToken
        startedAt
        __typename
      }
      PageLevelPermissions
      allowed_permissions
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteGroups = /* GraphQL */ `
  mutation DeleteGroups(
    $input: DeleteGroupsInput!
    $condition: ModelGroupsConditionInput
  ) {
    deleteGroups(input: $input, condition: $condition) {
      id
      Name
      cognito_name
      Applications {
        nextToken
        startedAt
        __typename
      }
      PageLevelPermissions
      allowed_permissions
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createGroupApplications = /* GraphQL */ `
  mutation CreateGroupApplications(
    $input: CreateGroupApplicationsInput!
    $condition: ModelGroupApplicationsConditionInput
  ) {
    createGroupApplications(input: $input, condition: $condition) {
      id
      group_id
      application_id
      application {
        id
        name
        application_section
        is_child_application
        route
        application_permissions
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      allowed_permissions
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateGroupApplications = /* GraphQL */ `
  mutation UpdateGroupApplications(
    $input: UpdateGroupApplicationsInput!
    $condition: ModelGroupApplicationsConditionInput
  ) {
    updateGroupApplications(input: $input, condition: $condition) {
      id
      group_id
      application_id
      application {
        id
        name
        application_section
        is_child_application
        route
        application_permissions
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      allowed_permissions
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteGroupApplications = /* GraphQL */ `
  mutation DeleteGroupApplications(
    $input: DeleteGroupApplicationsInput!
    $condition: ModelGroupApplicationsConditionInput
  ) {
    deleteGroupApplications(input: $input, condition: $condition) {
      id
      group_id
      application_id
      application {
        id
        name
        application_section
        is_child_application
        route
        application_permissions
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      allowed_permissions
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createApplications = /* GraphQL */ `
  mutation CreateApplications(
    $input: CreateApplicationsInput!
    $condition: ModelApplicationsConditionInput
  ) {
    createApplications(input: $input, condition: $condition) {
      id
      name
      application_section
      is_child_application
      route
      application_permissions
      GroupApplications {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateApplications = /* GraphQL */ `
  mutation UpdateApplications(
    $input: UpdateApplicationsInput!
    $condition: ModelApplicationsConditionInput
  ) {
    updateApplications(input: $input, condition: $condition) {
      id
      name
      application_section
      is_child_application
      route
      application_permissions
      GroupApplications {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteApplications = /* GraphQL */ `
  mutation DeleteApplications(
    $input: DeleteApplicationsInput!
    $condition: ModelApplicationsConditionInput
  ) {
    deleteApplications(input: $input, condition: $condition) {
      id
      name
      application_section
      is_child_application
      route
      application_permissions
      GroupApplications {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createGeneralLog = /* GraphQL */ `
  mutation CreateGeneralLog(
    $input: CreateGeneralLogInput!
    $condition: ModelGeneralLogConditionInput
  ) {
    createGeneralLog(input: $input, condition: $condition) {
      id
      script
      msg_type
      app
      message
      msg_date
      msg_time
      partner
      is_error
      error_msg
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateGeneralLog = /* GraphQL */ `
  mutation UpdateGeneralLog(
    $input: UpdateGeneralLogInput!
    $condition: ModelGeneralLogConditionInput
  ) {
    updateGeneralLog(input: $input, condition: $condition) {
      id
      script
      msg_type
      app
      message
      msg_date
      msg_time
      partner
      is_error
      error_msg
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteGeneralLog = /* GraphQL */ `
  mutation DeleteGeneralLog(
    $input: DeleteGeneralLogInput!
    $condition: ModelGeneralLogConditionInput
  ) {
    deleteGeneralLog(input: $input, condition: $condition) {
      id
      script
      msg_type
      app
      message
      msg_date
      msg_time
      partner
      is_error
      error_msg
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createCOnames = /* GraphQL */ `
  mutation CreateCOnames(
    $input: CreateCOnamesInput!
    $condition: ModelCOnamesConditionInput
  ) {
    createCOnames(input: $input, condition: $condition) {
      id
      CO_Name
      account_id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateCOnames = /* GraphQL */ `
  mutation UpdateCOnames(
    $input: UpdateCOnamesInput!
    $condition: ModelCOnamesConditionInput
  ) {
    updateCOnames(input: $input, condition: $condition) {
      id
      CO_Name
      account_id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteCOnames = /* GraphQL */ `
  mutation DeleteCOnames(
    $input: DeleteCOnamesInput!
    $condition: ModelCOnamesConditionInput
  ) {
    deleteCOnames(input: $input, condition: $condition) {
      id
      CO_Name
      account_id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createComments = /* GraphQL */ `
  mutation CreateComments(
    $input: CreateCommentsInput!
    $condition: ModelCommentsConditionInput
  ) {
    createComments(input: $input, condition: $condition) {
      id
      port_request_id
      content
      timestamp
      action_required
      action_response
      author
      is_private
      user_id
      port_state
      comment_type
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateComments = /* GraphQL */ `
  mutation UpdateComments(
    $input: UpdateCommentsInput!
    $condition: ModelCommentsConditionInput
  ) {
    updateComments(input: $input, condition: $condition) {
      id
      port_request_id
      content
      timestamp
      action_required
      action_response
      author
      is_private
      user_id
      port_state
      comment_type
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteComments = /* GraphQL */ `
  mutation DeleteComments(
    $input: DeleteCommentsInput!
    $condition: ModelCommentsConditionInput
  ) {
    deleteComments(input: $input, condition: $condition) {
      id
      port_request_id
      content
      timestamp
      action_required
      action_response
      author
      is_private
      user_id
      port_state
      comment_type
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createKazooWebhookAccountIds = /* GraphQL */ `
  mutation CreateKazooWebhookAccountIds(
    $input: CreateKazooWebhookAccountIdsInput!
    $condition: ModelKazooWebhookAccountIdsConditionInput
  ) {
    createKazooWebhookAccountIds(input: $input, condition: $condition) {
      id
      account_id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateKazooWebhookAccountIds = /* GraphQL */ `
  mutation UpdateKazooWebhookAccountIds(
    $input: UpdateKazooWebhookAccountIdsInput!
    $condition: ModelKazooWebhookAccountIdsConditionInput
  ) {
    updateKazooWebhookAccountIds(input: $input, condition: $condition) {
      id
      account_id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteKazooWebhookAccountIds = /* GraphQL */ `
  mutation DeleteKazooWebhookAccountIds(
    $input: DeleteKazooWebhookAccountIdsInput!
    $condition: ModelKazooWebhookAccountIdsConditionInput
  ) {
    deleteKazooWebhookAccountIds(input: $input, condition: $condition) {
      id
      account_id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createPortOrders = /* GraphQL */ `
  mutation CreatePortOrders(
    $input: CreatePortOrdersInput!
    $condition: ModelPortOrdersConditionInput
  ) {
    createPortOrders(input: $input, condition: $condition) {
      id
      name
      subscriberType
      submission_form_data
      submission_form_type
      phoneNumberType
      port_request_id
      port_state
      partner
      notification_email
      account_name
      parent_id
      account_id
      externalID
      requested_FOC_date
      port_request_name
      bill_carrier
      loc_number
      winning_carrier
      partialPort
      triggered
      business_name
      first_name
      middle_initial
      last_name
      housePrefix
      houseSuffix
      preDirection
      street_number
      street_address
      street_type
      postDirection
      AddressLine2
      city
      state_code
      zip
      plusFour
      account_number
      pin
      btn
      reference_number
      portNumbers
      accountPortion
      bill_filename
      recent_bill_verification
      loa_filename
      signee_name
      signing_date
      submission_date
      secondaryBtn
      isPortingBTN
      FOC_date
      original_foc_date
      lastOrderNote
      locationID
      CO_Name
      hosted_install
      fractelEmailed
      multi_line_order {
        nextToken
        startedAt
        __typename
      }
      port_order_comments {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updatePortOrders = /* GraphQL */ `
  mutation UpdatePortOrders(
    $input: UpdatePortOrdersInput!
    $condition: ModelPortOrdersConditionInput
  ) {
    updatePortOrders(input: $input, condition: $condition) {
      id
      name
      subscriberType
      submission_form_data
      submission_form_type
      phoneNumberType
      port_request_id
      port_state
      partner
      notification_email
      account_name
      parent_id
      account_id
      externalID
      requested_FOC_date
      port_request_name
      bill_carrier
      loc_number
      winning_carrier
      partialPort
      triggered
      business_name
      first_name
      middle_initial
      last_name
      housePrefix
      houseSuffix
      preDirection
      street_number
      street_address
      street_type
      postDirection
      AddressLine2
      city
      state_code
      zip
      plusFour
      account_number
      pin
      btn
      reference_number
      portNumbers
      accountPortion
      bill_filename
      recent_bill_verification
      loa_filename
      signee_name
      signing_date
      submission_date
      secondaryBtn
      isPortingBTN
      FOC_date
      original_foc_date
      lastOrderNote
      locationID
      CO_Name
      hosted_install
      fractelEmailed
      multi_line_order {
        nextToken
        startedAt
        __typename
      }
      port_order_comments {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deletePortOrders = /* GraphQL */ `
  mutation DeletePortOrders(
    $input: DeletePortOrdersInput!
    $condition: ModelPortOrdersConditionInput
  ) {
    deletePortOrders(input: $input, condition: $condition) {
      id
      name
      subscriberType
      submission_form_data
      submission_form_type
      phoneNumberType
      port_request_id
      port_state
      partner
      notification_email
      account_name
      parent_id
      account_id
      externalID
      requested_FOC_date
      port_request_name
      bill_carrier
      loc_number
      winning_carrier
      partialPort
      triggered
      business_name
      first_name
      middle_initial
      last_name
      housePrefix
      houseSuffix
      preDirection
      street_number
      street_address
      street_type
      postDirection
      AddressLine2
      city
      state_code
      zip
      plusFour
      account_number
      pin
      btn
      reference_number
      portNumbers
      accountPortion
      bill_filename
      recent_bill_verification
      loa_filename
      signee_name
      signing_date
      submission_date
      secondaryBtn
      isPortingBTN
      FOC_date
      original_foc_date
      lastOrderNote
      locationID
      CO_Name
      hosted_install
      fractelEmailed
      multi_line_order {
        nextToken
        startedAt
        __typename
      }
      port_order_comments {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createPortOrderComments = /* GraphQL */ `
  mutation CreatePortOrderComments(
    $input: CreatePortOrderCommentsInput!
    $condition: ModelPortOrderCommentsConditionInput
  ) {
    createPortOrderComments(input: $input, condition: $condition) {
      id
      port_order_id
      content
      timestamp
      action_required
      action_response
      author
      is_private
      user_id
      port_state
      comment_type
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updatePortOrderComments = /* GraphQL */ `
  mutation UpdatePortOrderComments(
    $input: UpdatePortOrderCommentsInput!
    $condition: ModelPortOrderCommentsConditionInput
  ) {
    updatePortOrderComments(input: $input, condition: $condition) {
      id
      port_order_id
      content
      timestamp
      action_required
      action_response
      author
      is_private
      user_id
      port_state
      comment_type
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deletePortOrderComments = /* GraphQL */ `
  mutation DeletePortOrderComments(
    $input: DeletePortOrderCommentsInput!
    $condition: ModelPortOrderCommentsConditionInput
  ) {
    deletePortOrderComments(input: $input, condition: $condition) {
      id
      port_order_id
      content
      timestamp
      action_required
      action_response
      author
      is_private
      user_id
      port_state
      comment_type
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createSaveCallCenterProData = /* GraphQL */ `
  mutation CreateSaveCallCenterProData(
    $input: CreateSaveCallCenterProDataInput!
    $condition: ModelSaveCallCenterProDataConditionInput
  ) {
    createSaveCallCenterProData(input: $input, condition: $condition) {
      id
      queueName
      timestamp
      dateTime
      eventDesc
      agentName
      callerIdNumber
      callerIdName
      joinPosition
      joinDateTime
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateSaveCallCenterProData = /* GraphQL */ `
  mutation UpdateSaveCallCenterProData(
    $input: UpdateSaveCallCenterProDataInput!
    $condition: ModelSaveCallCenterProDataConditionInput
  ) {
    updateSaveCallCenterProData(input: $input, condition: $condition) {
      id
      queueName
      timestamp
      dateTime
      eventDesc
      agentName
      callerIdNumber
      callerIdName
      joinPosition
      joinDateTime
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteSaveCallCenterProData = /* GraphQL */ `
  mutation DeleteSaveCallCenterProData(
    $input: DeleteSaveCallCenterProDataInput!
    $condition: ModelSaveCallCenterProDataConditionInput
  ) {
    deleteSaveCallCenterProData(input: $input, condition: $condition) {
      id
      queueName
      timestamp
      dateTime
      eventDesc
      agentName
      callerIdNumber
      callerIdName
      joinPosition
      joinDateTime
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createSwitchQubicleSessions = /* GraphQL */ `
  mutation CreateSwitchQubicleSessions(
    $input: CreateSwitchQubicleSessionsInput!
    $condition: ModelSwitchQubicleSessionsConditionInput
  ) {
    createSwitchQubicleSessions(input: $input, condition: $condition) {
      id
      event_type
      account_id
      account_name
      event_timestamp
      session_type
      session_id
      pid
      caller_id_number
      caller_id_name
      call_data
      session_life_time
      queue_wait_time
      create_time
      reason
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateSwitchQubicleSessions = /* GraphQL */ `
  mutation UpdateSwitchQubicleSessions(
    $input: UpdateSwitchQubicleSessionsInput!
    $condition: ModelSwitchQubicleSessionsConditionInput
  ) {
    updateSwitchQubicleSessions(input: $input, condition: $condition) {
      id
      event_type
      account_id
      account_name
      event_timestamp
      session_type
      session_id
      pid
      caller_id_number
      caller_id_name
      call_data
      session_life_time
      queue_wait_time
      create_time
      reason
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteSwitchQubicleSessions = /* GraphQL */ `
  mutation DeleteSwitchQubicleSessions(
    $input: DeleteSwitchQubicleSessionsInput!
    $condition: ModelSwitchQubicleSessionsConditionInput
  ) {
    deleteSwitchQubicleSessions(input: $input, condition: $condition) {
      id
      event_type
      account_id
      account_name
      event_timestamp
      session_type
      session_id
      pid
      caller_id_number
      caller_id_name
      call_data
      session_life_time
      queue_wait_time
      create_time
      reason
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createSwitchQubicleRecipients = /* GraphQL */ `
  mutation CreateSwitchQubicleRecipients(
    $input: CreateSwitchQubicleRecipientsInput!
    $condition: ModelSwitchQubicleRecipientsConditionInput
  ) {
    createSwitchQubicleRecipients(input: $input, condition: $condition) {
      id
      event_type
      event_timestamp
      reason
      account_id
      displayname
      recipient_id
      queue_name
      queue_id
      caller_id_number
      caller_id_name
      queue_wait_time
      queue_enter_time
      session_id
      session
      session_zone
      talk_time
      queue_offering
      est_wait
      enter_pos
      record_queue
      queue_wrapup_extension_limit
      force_away_on_reject
      away_time
      ring_timeout
      queue_wrapup_extension
      queue_wrapup
      total_calls
      offered_calls
      missed_calls
      avg_call_time
      login_time
      last_action_time
      last_handled_time
      last_idle_time
      input_object
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateSwitchQubicleRecipients = /* GraphQL */ `
  mutation UpdateSwitchQubicleRecipients(
    $input: UpdateSwitchQubicleRecipientsInput!
    $condition: ModelSwitchQubicleRecipientsConditionInput
  ) {
    updateSwitchQubicleRecipients(input: $input, condition: $condition) {
      id
      event_type
      event_timestamp
      reason
      account_id
      displayname
      recipient_id
      queue_name
      queue_id
      caller_id_number
      caller_id_name
      queue_wait_time
      queue_enter_time
      session_id
      session
      session_zone
      talk_time
      queue_offering
      est_wait
      enter_pos
      record_queue
      queue_wrapup_extension_limit
      force_away_on_reject
      away_time
      ring_timeout
      queue_wrapup_extension
      queue_wrapup
      total_calls
      offered_calls
      missed_calls
      avg_call_time
      login_time
      last_action_time
      last_handled_time
      last_idle_time
      input_object
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteSwitchQubicleRecipients = /* GraphQL */ `
  mutation DeleteSwitchQubicleRecipients(
    $input: DeleteSwitchQubicleRecipientsInput!
    $condition: ModelSwitchQubicleRecipientsConditionInput
  ) {
    deleteSwitchQubicleRecipients(input: $input, condition: $condition) {
      id
      event_type
      event_timestamp
      reason
      account_id
      displayname
      recipient_id
      queue_name
      queue_id
      caller_id_number
      caller_id_name
      queue_wait_time
      queue_enter_time
      session_id
      session
      session_zone
      talk_time
      queue_offering
      est_wait
      enter_pos
      record_queue
      queue_wrapup_extension_limit
      force_away_on_reject
      away_time
      ring_timeout
      queue_wrapup_extension
      queue_wrapup
      total_calls
      offered_calls
      missed_calls
      avg_call_time
      login_time
      last_action_time
      last_handled_time
      last_idle_time
      input_object
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createSwitchQubicleQueues = /* GraphQL */ `
  mutation CreateSwitchQubicleQueues(
    $input: CreateSwitchQubicleQueuesInput!
    $condition: ModelSwitchQubicleQueuesConditionInput
  ) {
    createSwitchQubicleQueues(input: $input, condition: $condition) {
      id
      event_type
      account_id
      queue_id
      event_timestamp
      total_wait_time
      queue_name
      join_position
      join_time
      est_wait_time
      caller_id_number
      caller_id_name
      session_id
      estimated_wait
      average_wait
      longest_wait
      recipient_count
      active_session_count
      total_sessions
      missed_sessions
      abandoned_sessions
      session_data
      input_object
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateSwitchQubicleQueues = /* GraphQL */ `
  mutation UpdateSwitchQubicleQueues(
    $input: UpdateSwitchQubicleQueuesInput!
    $condition: ModelSwitchQubicleQueuesConditionInput
  ) {
    updateSwitchQubicleQueues(input: $input, condition: $condition) {
      id
      event_type
      account_id
      queue_id
      event_timestamp
      total_wait_time
      queue_name
      join_position
      join_time
      est_wait_time
      caller_id_number
      caller_id_name
      session_id
      estimated_wait
      average_wait
      longest_wait
      recipient_count
      active_session_count
      total_sessions
      missed_sessions
      abandoned_sessions
      session_data
      input_object
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteSwitchQubicleQueues = /* GraphQL */ `
  mutation DeleteSwitchQubicleQueues(
    $input: DeleteSwitchQubicleQueuesInput!
    $condition: ModelSwitchQubicleQueuesConditionInput
  ) {
    deleteSwitchQubicleQueues(input: $input, condition: $condition) {
      id
      event_type
      account_id
      queue_id
      event_timestamp
      total_wait_time
      queue_name
      join_position
      join_time
      est_wait_time
      caller_id_number
      caller_id_name
      session_id
      estimated_wait
      average_wait
      longest_wait
      recipient_count
      active_session_count
      total_sessions
      missed_sessions
      abandoned_sessions
      session_data
      input_object
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createSwitchCDRs = /* GraphQL */ `
  mutation CreateSwitchCDRs(
    $input: CreateSwitchCDRsInput!
    $condition: ModelSwitchCDRsConditionInput
  ) {
    createSwitchCDRs(input: $input, condition: $condition) {
      id
      account_id
      parent_id
      cdr_id
      call_id
      caller_id_number
      caller_id_name
      callee_id_number
      callee_id_name
      duration_seconds
      billing_seconds
      gregorian_timestamp
      hangup_cause
      other_leg_call_id
      owner_id
      to
      from
      revio_id
      external_id
      direction
      request
      authorizing_id
      cost
      dialed_number
      calling_from
      datetime
      unix_timestamp
      unix_timestamp_micro
      rfc_1036
      iso_8601
      iso_8601_combined
      call_type
      rate
      rate_name
      bridge_id
      recording_url
      media_recordings
      media_server
      call_priority
      interaction_id
      reseller_cost
      reseller_call_type
      wholesale_cost
      retail_cost
      robocall_threshold {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateSwitchCDRs = /* GraphQL */ `
  mutation UpdateSwitchCDRs(
    $input: UpdateSwitchCDRsInput!
    $condition: ModelSwitchCDRsConditionInput
  ) {
    updateSwitchCDRs(input: $input, condition: $condition) {
      id
      account_id
      parent_id
      cdr_id
      call_id
      caller_id_number
      caller_id_name
      callee_id_number
      callee_id_name
      duration_seconds
      billing_seconds
      gregorian_timestamp
      hangup_cause
      other_leg_call_id
      owner_id
      to
      from
      revio_id
      external_id
      direction
      request
      authorizing_id
      cost
      dialed_number
      calling_from
      datetime
      unix_timestamp
      unix_timestamp_micro
      rfc_1036
      iso_8601
      iso_8601_combined
      call_type
      rate
      rate_name
      bridge_id
      recording_url
      media_recordings
      media_server
      call_priority
      interaction_id
      reseller_cost
      reseller_call_type
      wholesale_cost
      retail_cost
      robocall_threshold {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteSwitchCDRs = /* GraphQL */ `
  mutation DeleteSwitchCDRs(
    $input: DeleteSwitchCDRsInput!
    $condition: ModelSwitchCDRsConditionInput
  ) {
    deleteSwitchCDRs(input: $input, condition: $condition) {
      id
      account_id
      parent_id
      cdr_id
      call_id
      caller_id_number
      caller_id_name
      callee_id_number
      callee_id_name
      duration_seconds
      billing_seconds
      gregorian_timestamp
      hangup_cause
      other_leg_call_id
      owner_id
      to
      from
      revio_id
      external_id
      direction
      request
      authorizing_id
      cost
      dialed_number
      calling_from
      datetime
      unix_timestamp
      unix_timestamp_micro
      rfc_1036
      iso_8601
      iso_8601_combined
      call_type
      rate
      rate_name
      bridge_id
      recording_url
      media_recordings
      media_server
      call_priority
      interaction_id
      reseller_cost
      reseller_call_type
      wholesale_cost
      retail_cost
      robocall_threshold {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createSwitch811s = /* GraphQL */ `
  mutation CreateSwitch811s(
    $input: CreateSwitch811sInput!
    $condition: ModelSwitch811sConditionInput
  ) {
    createSwitch811s(input: $input, condition: $condition) {
      id
      state
      number_811
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateSwitch811s = /* GraphQL */ `
  mutation UpdateSwitch811s(
    $input: UpdateSwitch811sInput!
    $condition: ModelSwitch811sConditionInput
  ) {
    updateSwitch811s(input: $input, condition: $condition) {
      id
      state
      number_811
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteSwitch811s = /* GraphQL */ `
  mutation DeleteSwitch811s(
    $input: DeleteSwitch811sInput!
    $condition: ModelSwitch811sConditionInput
  ) {
    deleteSwitch811s(input: $input, condition: $condition) {
      id
      state
      number_811
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createPartners = /* GraphQL */ `
  mutation CreatePartners(
    $input: CreatePartnersInput!
    $condition: ModelPartnersConditionInput
  ) {
    createPartners(input: $input, condition: $condition) {
      partner
      name
      revio_id
      company_name
      powered_by
      logo
      logo_height
      logo_width
      website_url
      quotes {
        nextToken
        startedAt
        __typename
      }
      switch_parent_id
      switch_parent_account {
        nextToken
        startedAt
        __typename
      }
      switch_child_id
      switch_child_account {
        nextToken
        startedAt
        __typename
      }
      bill_profile_id
      usage_plan_group_id
      phone_number
      email
      coop_table_id
      pricebook {
        nextToken
        startedAt
        __typename
      }
      revio_customer {
        nextToken
        startedAt
        __typename
      }
      wholesale_as_retail
      fusf_custom_tax_exemption
      foc_notification_emails
      fusf_exemption
      has_glds_billing
      has_provisioning
      port_network_email_list
      port_action_email_list
      port_impending_foc_email_list
      queue
      id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updatePartners = /* GraphQL */ `
  mutation UpdatePartners(
    $input: UpdatePartnersInput!
    $condition: ModelPartnersConditionInput
  ) {
    updatePartners(input: $input, condition: $condition) {
      partner
      name
      revio_id
      company_name
      powered_by
      logo
      logo_height
      logo_width
      website_url
      quotes {
        nextToken
        startedAt
        __typename
      }
      switch_parent_id
      switch_parent_account {
        nextToken
        startedAt
        __typename
      }
      switch_child_id
      switch_child_account {
        nextToken
        startedAt
        __typename
      }
      bill_profile_id
      usage_plan_group_id
      phone_number
      email
      coop_table_id
      pricebook {
        nextToken
        startedAt
        __typename
      }
      revio_customer {
        nextToken
        startedAt
        __typename
      }
      wholesale_as_retail
      fusf_custom_tax_exemption
      foc_notification_emails
      fusf_exemption
      has_glds_billing
      has_provisioning
      port_network_email_list
      port_action_email_list
      port_impending_foc_email_list
      queue
      id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deletePartners = /* GraphQL */ `
  mutation DeletePartners(
    $input: DeletePartnersInput!
    $condition: ModelPartnersConditionInput
  ) {
    deletePartners(input: $input, condition: $condition) {
      partner
      name
      revio_id
      company_name
      powered_by
      logo
      logo_height
      logo_width
      website_url
      quotes {
        nextToken
        startedAt
        __typename
      }
      switch_parent_id
      switch_parent_account {
        nextToken
        startedAt
        __typename
      }
      switch_child_id
      switch_child_account {
        nextToken
        startedAt
        __typename
      }
      bill_profile_id
      usage_plan_group_id
      phone_number
      email
      coop_table_id
      pricebook {
        nextToken
        startedAt
        __typename
      }
      revio_customer {
        nextToken
        startedAt
        __typename
      }
      wholesale_as_retail
      fusf_custom_tax_exemption
      foc_notification_emails
      fusf_exemption
      has_glds_billing
      has_provisioning
      port_network_email_list
      port_action_email_list
      port_impending_foc_email_list
      queue
      id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createManagerPartners = /* GraphQL */ `
  mutation CreateManagerPartners(
    $input: CreateManagerPartnersInput!
    $condition: ModelManagerPartnersConditionInput
  ) {
    createManagerPartners(input: $input, condition: $condition) {
      id
      name
      ticket_queue_name
      category
      voice_category
      contact_center_category
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateManagerPartners = /* GraphQL */ `
  mutation UpdateManagerPartners(
    $input: UpdateManagerPartnersInput!
    $condition: ModelManagerPartnersConditionInput
  ) {
    updateManagerPartners(input: $input, condition: $condition) {
      id
      name
      ticket_queue_name
      category
      voice_category
      contact_center_category
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteManagerPartners = /* GraphQL */ `
  mutation DeleteManagerPartners(
    $input: DeleteManagerPartnersInput!
    $condition: ModelManagerPartnersConditionInput
  ) {
    deleteManagerPartners(input: $input, condition: $condition) {
      id
      name
      ticket_queue_name
      category
      voice_category
      contact_center_category
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createSwitchUsers = /* GraphQL */ `
  mutation CreateSwitchUsers(
    $input: CreateSwitchUsersInput!
    $condition: ModelSwitchUsersConditionInput
  ) {
    createSwitchUsers(input: $input, condition: $condition) {
      id
      username
      email
      first_name
      last_name
      priv_level
      presence_id
      caller_id
      send_email_on_creation
      call_restriction
      contact_list
      dial_plan
      enabled
      hotdesk
      media
      music_on_hold
      profile
      require_password_update
      ringtones
      verified
      vm_to_email_enabled
      account_name
      apps
      ui_help
      appList
      user_id
      account_id
      account {
        id
        ui_restrictions
        ui_flags
        timezone
        ringtones
        reseller_id
        realm
        preflow
        notifications
        notification_preference
        name
        music_on_hold
        language
        is_reseller
        dial_plan
        descendants_count
        created
        contract
        contact
        caller_id
        call_restriction
        metaflows
        parent_id
        account_id
        _read_only
        wnm_allow_additions
        superduper_admin
        enabled
        billing_mode
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      _read_only
      has_avatar
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateSwitchUsers = /* GraphQL */ `
  mutation UpdateSwitchUsers(
    $input: UpdateSwitchUsersInput!
    $condition: ModelSwitchUsersConditionInput
  ) {
    updateSwitchUsers(input: $input, condition: $condition) {
      id
      username
      email
      first_name
      last_name
      priv_level
      presence_id
      caller_id
      send_email_on_creation
      call_restriction
      contact_list
      dial_plan
      enabled
      hotdesk
      media
      music_on_hold
      profile
      require_password_update
      ringtones
      verified
      vm_to_email_enabled
      account_name
      apps
      ui_help
      appList
      user_id
      account_id
      account {
        id
        ui_restrictions
        ui_flags
        timezone
        ringtones
        reseller_id
        realm
        preflow
        notifications
        notification_preference
        name
        music_on_hold
        language
        is_reseller
        dial_plan
        descendants_count
        created
        contract
        contact
        caller_id
        call_restriction
        metaflows
        parent_id
        account_id
        _read_only
        wnm_allow_additions
        superduper_admin
        enabled
        billing_mode
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      _read_only
      has_avatar
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteSwitchUsers = /* GraphQL */ `
  mutation DeleteSwitchUsers(
    $input: DeleteSwitchUsersInput!
    $condition: ModelSwitchUsersConditionInput
  ) {
    deleteSwitchUsers(input: $input, condition: $condition) {
      id
      username
      email
      first_name
      last_name
      priv_level
      presence_id
      caller_id
      send_email_on_creation
      call_restriction
      contact_list
      dial_plan
      enabled
      hotdesk
      media
      music_on_hold
      profile
      require_password_update
      ringtones
      verified
      vm_to_email_enabled
      account_name
      apps
      ui_help
      appList
      user_id
      account_id
      account {
        id
        ui_restrictions
        ui_flags
        timezone
        ringtones
        reseller_id
        realm
        preflow
        notifications
        notification_preference
        name
        music_on_hold
        language
        is_reseller
        dial_plan
        descendants_count
        created
        contract
        contact
        caller_id
        call_restriction
        metaflows
        parent_id
        account_id
        _read_only
        wnm_allow_additions
        superduper_admin
        enabled
        billing_mode
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      _read_only
      has_avatar
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createSwitchMailboxes = /* GraphQL */ `
  mutation CreateSwitchMailboxes(
    $input: CreateSwitchMailboxesInput!
    $condition: ModelSwitchMailboxesConditionInput
  ) {
    createSwitchMailboxes(input: $input, condition: $condition) {
      id
      mailbox
      name
      owner_id
      media_extension
      notify_email_addresses
      announcement_only
      check_if_owner
      delete_after_notify
      include_message_on_notify
      include_transcription_on_notify
      is_setup
      is_voicemail_ff_rw_enabled
      media
      not_configurable
      oldest_message_first
      require_pin
      save_after_notify
      seek_duration_ms
      skip_envelope
      skip_greeting
      skip_instructions
      transcribe
      vm_message_forward_type
      ui_metadata
      account_id
      account {
        id
        ui_restrictions
        ui_flags
        timezone
        ringtones
        reseller_id
        realm
        preflow
        notifications
        notification_preference
        name
        music_on_hold
        language
        is_reseller
        dial_plan
        descendants_count
        created
        contract
        contact
        caller_id
        call_restriction
        metaflows
        parent_id
        account_id
        _read_only
        wnm_allow_additions
        superduper_admin
        enabled
        billing_mode
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      mailbox_id
      _read_only
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateSwitchMailboxes = /* GraphQL */ `
  mutation UpdateSwitchMailboxes(
    $input: UpdateSwitchMailboxesInput!
    $condition: ModelSwitchMailboxesConditionInput
  ) {
    updateSwitchMailboxes(input: $input, condition: $condition) {
      id
      mailbox
      name
      owner_id
      media_extension
      notify_email_addresses
      announcement_only
      check_if_owner
      delete_after_notify
      include_message_on_notify
      include_transcription_on_notify
      is_setup
      is_voicemail_ff_rw_enabled
      media
      not_configurable
      oldest_message_first
      require_pin
      save_after_notify
      seek_duration_ms
      skip_envelope
      skip_greeting
      skip_instructions
      transcribe
      vm_message_forward_type
      ui_metadata
      account_id
      account {
        id
        ui_restrictions
        ui_flags
        timezone
        ringtones
        reseller_id
        realm
        preflow
        notifications
        notification_preference
        name
        music_on_hold
        language
        is_reseller
        dial_plan
        descendants_count
        created
        contract
        contact
        caller_id
        call_restriction
        metaflows
        parent_id
        account_id
        _read_only
        wnm_allow_additions
        superduper_admin
        enabled
        billing_mode
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      mailbox_id
      _read_only
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteSwitchMailboxes = /* GraphQL */ `
  mutation DeleteSwitchMailboxes(
    $input: DeleteSwitchMailboxesInput!
    $condition: ModelSwitchMailboxesConditionInput
  ) {
    deleteSwitchMailboxes(input: $input, condition: $condition) {
      id
      mailbox
      name
      owner_id
      media_extension
      notify_email_addresses
      announcement_only
      check_if_owner
      delete_after_notify
      include_message_on_notify
      include_transcription_on_notify
      is_setup
      is_voicemail_ff_rw_enabled
      media
      not_configurable
      oldest_message_first
      require_pin
      save_after_notify
      seek_duration_ms
      skip_envelope
      skip_greeting
      skip_instructions
      transcribe
      vm_message_forward_type
      ui_metadata
      account_id
      account {
        id
        ui_restrictions
        ui_flags
        timezone
        ringtones
        reseller_id
        realm
        preflow
        notifications
        notification_preference
        name
        music_on_hold
        language
        is_reseller
        dial_plan
        descendants_count
        created
        contract
        contact
        caller_id
        call_restriction
        metaflows
        parent_id
        account_id
        _read_only
        wnm_allow_additions
        superduper_admin
        enabled
        billing_mode
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      mailbox_id
      _read_only
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createSwitchNumbers = /* GraphQL */ `
  mutation CreateSwitchNumbers(
    $input: CreateSwitchNumbersInput!
    $condition: ModelSwitchNumbersConditionInput
  ) {
    createSwitchNumbers(input: $input, condition: $condition) {
      id
      number
      state
      prepended
      account_id
      account {
        id
        ui_restrictions
        ui_flags
        timezone
        ringtones
        reseller_id
        realm
        preflow
        notifications
        notification_preference
        name
        music_on_hold
        language
        is_reseller
        dial_plan
        descendants_count
        created
        contract
        contact
        caller_id
        call_restriction
        metaflows
        parent_id
        account_id
        _read_only
        wnm_allow_additions
        superduper_admin
        enabled
        billing_mode
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateSwitchNumbers = /* GraphQL */ `
  mutation UpdateSwitchNumbers(
    $input: UpdateSwitchNumbersInput!
    $condition: ModelSwitchNumbersConditionInput
  ) {
    updateSwitchNumbers(input: $input, condition: $condition) {
      id
      number
      state
      prepended
      account_id
      account {
        id
        ui_restrictions
        ui_flags
        timezone
        ringtones
        reseller_id
        realm
        preflow
        notifications
        notification_preference
        name
        music_on_hold
        language
        is_reseller
        dial_plan
        descendants_count
        created
        contract
        contact
        caller_id
        call_restriction
        metaflows
        parent_id
        account_id
        _read_only
        wnm_allow_additions
        superduper_admin
        enabled
        billing_mode
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteSwitchNumbers = /* GraphQL */ `
  mutation DeleteSwitchNumbers(
    $input: DeleteSwitchNumbersInput!
    $condition: ModelSwitchNumbersConditionInput
  ) {
    deleteSwitchNumbers(input: $input, condition: $condition) {
      id
      number
      state
      prepended
      account_id
      account {
        id
        ui_restrictions
        ui_flags
        timezone
        ringtones
        reseller_id
        realm
        preflow
        notifications
        notification_preference
        name
        music_on_hold
        language
        is_reseller
        dial_plan
        descendants_count
        created
        contract
        contact
        caller_id
        call_restriction
        metaflows
        parent_id
        account_id
        _read_only
        wnm_allow_additions
        superduper_admin
        enabled
        billing_mode
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createSwitchMedia = /* GraphQL */ `
  mutation CreateSwitchMedia(
    $input: CreateSwitchMediaInput!
    $condition: ModelSwitchMediaConditionInput
  ) {
    createSwitchMedia(input: $input, condition: $condition) {
      id
      account_id
      account {
        id
        ui_restrictions
        ui_flags
        timezone
        ringtones
        reseller_id
        realm
        preflow
        notifications
        notification_preference
        name
        music_on_hold
        language
        is_reseller
        dial_plan
        descendants_count
        created
        contract
        contact
        caller_id
        call_restriction
        metaflows
        parent_id
        account_id
        _read_only
        wnm_allow_additions
        superduper_admin
        enabled
        billing_mode
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      media_object_id
      data
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateSwitchMedia = /* GraphQL */ `
  mutation UpdateSwitchMedia(
    $input: UpdateSwitchMediaInput!
    $condition: ModelSwitchMediaConditionInput
  ) {
    updateSwitchMedia(input: $input, condition: $condition) {
      id
      account_id
      account {
        id
        ui_restrictions
        ui_flags
        timezone
        ringtones
        reseller_id
        realm
        preflow
        notifications
        notification_preference
        name
        music_on_hold
        language
        is_reseller
        dial_plan
        descendants_count
        created
        contract
        contact
        caller_id
        call_restriction
        metaflows
        parent_id
        account_id
        _read_only
        wnm_allow_additions
        superduper_admin
        enabled
        billing_mode
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      media_object_id
      data
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteSwitchMedia = /* GraphQL */ `
  mutation DeleteSwitchMedia(
    $input: DeleteSwitchMediaInput!
    $condition: ModelSwitchMediaConditionInput
  ) {
    deleteSwitchMedia(input: $input, condition: $condition) {
      id
      account_id
      account {
        id
        ui_restrictions
        ui_flags
        timezone
        ringtones
        reseller_id
        realm
        preflow
        notifications
        notification_preference
        name
        music_on_hold
        language
        is_reseller
        dial_plan
        descendants_count
        created
        contract
        contact
        caller_id
        call_restriction
        metaflows
        parent_id
        account_id
        _read_only
        wnm_allow_additions
        superduper_admin
        enabled
        billing_mode
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      media_object_id
      data
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createSwitchDevices = /* GraphQL */ `
  mutation CreateSwitchDevices(
    $input: CreateSwitchDevicesInput!
    $condition: ModelSwitchDevicesConditionInput
  ) {
    createSwitchDevices(input: $input, condition: $condition) {
      id
      device_type
      enabled
      name
      sip
      owner_id
      caller_id
      register_overwrite_notify
      mwi_unsolicited_updates
      mwi_unsolicitated_updates
      exclude_from_queues
      contact_list
      call_restriction
      media
      suppress_unregister_notifications
      music_on_hold
      ringtones
      account_id
      account {
        id
        ui_restrictions
        ui_flags
        timezone
        ringtones
        reseller_id
        realm
        preflow
        notifications
        notification_preference
        name
        music_on_hold
        language
        is_reseller
        dial_plan
        descendants_count
        created
        contract
        contact
        caller_id
        call_restriction
        metaflows
        parent_id
        account_id
        _read_only
        wnm_allow_additions
        superduper_admin
        enabled
        billing_mode
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      device_id
      _read_only
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateSwitchDevices = /* GraphQL */ `
  mutation UpdateSwitchDevices(
    $input: UpdateSwitchDevicesInput!
    $condition: ModelSwitchDevicesConditionInput
  ) {
    updateSwitchDevices(input: $input, condition: $condition) {
      id
      device_type
      enabled
      name
      sip
      owner_id
      caller_id
      register_overwrite_notify
      mwi_unsolicited_updates
      mwi_unsolicitated_updates
      exclude_from_queues
      contact_list
      call_restriction
      media
      suppress_unregister_notifications
      music_on_hold
      ringtones
      account_id
      account {
        id
        ui_restrictions
        ui_flags
        timezone
        ringtones
        reseller_id
        realm
        preflow
        notifications
        notification_preference
        name
        music_on_hold
        language
        is_reseller
        dial_plan
        descendants_count
        created
        contract
        contact
        caller_id
        call_restriction
        metaflows
        parent_id
        account_id
        _read_only
        wnm_allow_additions
        superduper_admin
        enabled
        billing_mode
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      device_id
      _read_only
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteSwitchDevices = /* GraphQL */ `
  mutation DeleteSwitchDevices(
    $input: DeleteSwitchDevicesInput!
    $condition: ModelSwitchDevicesConditionInput
  ) {
    deleteSwitchDevices(input: $input, condition: $condition) {
      id
      device_type
      enabled
      name
      sip
      owner_id
      caller_id
      register_overwrite_notify
      mwi_unsolicited_updates
      mwi_unsolicitated_updates
      exclude_from_queues
      contact_list
      call_restriction
      media
      suppress_unregister_notifications
      music_on_hold
      ringtones
      account_id
      account {
        id
        ui_restrictions
        ui_flags
        timezone
        ringtones
        reseller_id
        realm
        preflow
        notifications
        notification_preference
        name
        music_on_hold
        language
        is_reseller
        dial_plan
        descendants_count
        created
        contract
        contact
        caller_id
        call_restriction
        metaflows
        parent_id
        account_id
        _read_only
        wnm_allow_additions
        superduper_admin
        enabled
        billing_mode
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      device_id
      _read_only
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createSwitchConnectivity = /* GraphQL */ `
  mutation CreateSwitchConnectivity(
    $input: CreateSwitchConnectivityInput!
    $condition: ModelSwitchConnectivityConditionInput
  ) {
    createSwitchConnectivity(input: $input, condition: $condition) {
      id
      account
      billing_account_id
      DIDs_Unassigned
      servers
      account_id
      switch_account {
        id
        ui_restrictions
        ui_flags
        timezone
        ringtones
        reseller_id
        realm
        preflow
        notifications
        notification_preference
        name
        music_on_hold
        language
        is_reseller
        dial_plan
        descendants_count
        created
        contract
        contact
        caller_id
        call_restriction
        metaflows
        parent_id
        account_id
        _read_only
        wnm_allow_additions
        superduper_admin
        enabled
        billing_mode
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      connectivity_id
      _read_only
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateSwitchConnectivity = /* GraphQL */ `
  mutation UpdateSwitchConnectivity(
    $input: UpdateSwitchConnectivityInput!
    $condition: ModelSwitchConnectivityConditionInput
  ) {
    updateSwitchConnectivity(input: $input, condition: $condition) {
      id
      account
      billing_account_id
      DIDs_Unassigned
      servers
      account_id
      switch_account {
        id
        ui_restrictions
        ui_flags
        timezone
        ringtones
        reseller_id
        realm
        preflow
        notifications
        notification_preference
        name
        music_on_hold
        language
        is_reseller
        dial_plan
        descendants_count
        created
        contract
        contact
        caller_id
        call_restriction
        metaflows
        parent_id
        account_id
        _read_only
        wnm_allow_additions
        superduper_admin
        enabled
        billing_mode
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      connectivity_id
      _read_only
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteSwitchConnectivity = /* GraphQL */ `
  mutation DeleteSwitchConnectivity(
    $input: DeleteSwitchConnectivityInput!
    $condition: ModelSwitchConnectivityConditionInput
  ) {
    deleteSwitchConnectivity(input: $input, condition: $condition) {
      id
      account
      billing_account_id
      DIDs_Unassigned
      servers
      account_id
      switch_account {
        id
        ui_restrictions
        ui_flags
        timezone
        ringtones
        reseller_id
        realm
        preflow
        notifications
        notification_preference
        name
        music_on_hold
        language
        is_reseller
        dial_plan
        descendants_count
        created
        contract
        contact
        caller_id
        call_restriction
        metaflows
        parent_id
        account_id
        _read_only
        wnm_allow_additions
        superduper_admin
        enabled
        billing_mode
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      connectivity_id
      _read_only
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createSwitchCallflows = /* GraphQL */ `
  mutation CreateSwitchCallflows(
    $input: CreateSwitchCallflowsInput!
    $condition: ModelSwitchCallflowsConditionInput
  ) {
    createSwitchCallflows(input: $input, condition: $condition) {
      id
      flow
      numbers
      patterns
      account_id
      account {
        id
        ui_restrictions
        ui_flags
        timezone
        ringtones
        reseller_id
        realm
        preflow
        notifications
        notification_preference
        name
        music_on_hold
        language
        is_reseller
        dial_plan
        descendants_count
        created
        contract
        contact
        caller_id
        call_restriction
        metaflows
        parent_id
        account_id
        _read_only
        wnm_allow_additions
        superduper_admin
        enabled
        billing_mode
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      featurecode
      contact_list
      metadata
      callflow_id
      _read_only
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateSwitchCallflows = /* GraphQL */ `
  mutation UpdateSwitchCallflows(
    $input: UpdateSwitchCallflowsInput!
    $condition: ModelSwitchCallflowsConditionInput
  ) {
    updateSwitchCallflows(input: $input, condition: $condition) {
      id
      flow
      numbers
      patterns
      account_id
      account {
        id
        ui_restrictions
        ui_flags
        timezone
        ringtones
        reseller_id
        realm
        preflow
        notifications
        notification_preference
        name
        music_on_hold
        language
        is_reseller
        dial_plan
        descendants_count
        created
        contract
        contact
        caller_id
        call_restriction
        metaflows
        parent_id
        account_id
        _read_only
        wnm_allow_additions
        superduper_admin
        enabled
        billing_mode
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      featurecode
      contact_list
      metadata
      callflow_id
      _read_only
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteSwitchCallflows = /* GraphQL */ `
  mutation DeleteSwitchCallflows(
    $input: DeleteSwitchCallflowsInput!
    $condition: ModelSwitchCallflowsConditionInput
  ) {
    deleteSwitchCallflows(input: $input, condition: $condition) {
      id
      flow
      numbers
      patterns
      account_id
      account {
        id
        ui_restrictions
        ui_flags
        timezone
        ringtones
        reseller_id
        realm
        preflow
        notifications
        notification_preference
        name
        music_on_hold
        language
        is_reseller
        dial_plan
        descendants_count
        created
        contract
        contact
        caller_id
        call_restriction
        metaflows
        parent_id
        account_id
        _read_only
        wnm_allow_additions
        superduper_admin
        enabled
        billing_mode
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      featurecode
      contact_list
      metadata
      callflow_id
      _read_only
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createSwitchAccounts = /* GraphQL */ `
  mutation CreateSwitchAccounts(
    $input: CreateSwitchAccountsInput!
    $condition: ModelSwitchAccountsConditionInput
  ) {
    createSwitchAccounts(input: $input, condition: $condition) {
      id
      ui_restrictions
      ui_flags
      timezone
      ringtones
      reseller_id
      realm
      preflow
      notifications
      notification_preference
      name
      music_on_hold
      language
      is_reseller
      dial_plan
      descendants_count
      created
      contract
      contact
      caller_id
      call_restriction
      call_flows {
        nextToken
        startedAt
        __typename
      }
      connectivity {
        nextToken
        startedAt
        __typename
      }
      devices {
        nextToken
        startedAt
        __typename
      }
      media {
        nextToken
        startedAt
        __typename
      }
      numbers {
        nextToken
        startedAt
        __typename
      }
      mail_boxes {
        nextToken
        startedAt
        __typename
      }
      users {
        nextToken
        startedAt
        __typename
      }
      metaflows
      parent_id
      account_id
      children_accounts {
        nextToken
        startedAt
        __typename
      }
      parent_account {
        nextToken
        startedAt
        __typename
      }
      _read_only
      wnm_allow_additions
      superduper_admin
      enabled
      billing_mode
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateSwitchAccounts = /* GraphQL */ `
  mutation UpdateSwitchAccounts(
    $input: UpdateSwitchAccountsInput!
    $condition: ModelSwitchAccountsConditionInput
  ) {
    updateSwitchAccounts(input: $input, condition: $condition) {
      id
      ui_restrictions
      ui_flags
      timezone
      ringtones
      reseller_id
      realm
      preflow
      notifications
      notification_preference
      name
      music_on_hold
      language
      is_reseller
      dial_plan
      descendants_count
      created
      contract
      contact
      caller_id
      call_restriction
      call_flows {
        nextToken
        startedAt
        __typename
      }
      connectivity {
        nextToken
        startedAt
        __typename
      }
      devices {
        nextToken
        startedAt
        __typename
      }
      media {
        nextToken
        startedAt
        __typename
      }
      numbers {
        nextToken
        startedAt
        __typename
      }
      mail_boxes {
        nextToken
        startedAt
        __typename
      }
      users {
        nextToken
        startedAt
        __typename
      }
      metaflows
      parent_id
      account_id
      children_accounts {
        nextToken
        startedAt
        __typename
      }
      parent_account {
        nextToken
        startedAt
        __typename
      }
      _read_only
      wnm_allow_additions
      superduper_admin
      enabled
      billing_mode
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteSwitchAccounts = /* GraphQL */ `
  mutation DeleteSwitchAccounts(
    $input: DeleteSwitchAccountsInput!
    $condition: ModelSwitchAccountsConditionInput
  ) {
    deleteSwitchAccounts(input: $input, condition: $condition) {
      id
      ui_restrictions
      ui_flags
      timezone
      ringtones
      reseller_id
      realm
      preflow
      notifications
      notification_preference
      name
      music_on_hold
      language
      is_reseller
      dial_plan
      descendants_count
      created
      contract
      contact
      caller_id
      call_restriction
      call_flows {
        nextToken
        startedAt
        __typename
      }
      connectivity {
        nextToken
        startedAt
        __typename
      }
      devices {
        nextToken
        startedAt
        __typename
      }
      media {
        nextToken
        startedAt
        __typename
      }
      numbers {
        nextToken
        startedAt
        __typename
      }
      mail_boxes {
        nextToken
        startedAt
        __typename
      }
      users {
        nextToken
        startedAt
        __typename
      }
      metaflows
      parent_id
      account_id
      children_accounts {
        nextToken
        startedAt
        __typename
      }
      parent_account {
        nextToken
        startedAt
        __typename
      }
      _read_only
      wnm_allow_additions
      superduper_admin
      enabled
      billing_mode
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createRevioServices = /* GraphQL */ `
  mutation CreateRevioServices(
    $input: CreateRevioServicesInput!
    $condition: ModelRevioServicesConditionInput
  ) {
    createRevioServices(input: $input, condition: $condition) {
      id
      service_id
      customer_id
      provider_id
      service_type_id
      package_id
      number
      pic
      lpic
      provider_account_id
      usage_plan_group_id
      number2
      description
      disconnected_date
      activated_date
      address_id
      addressz_id
      provider_account_number
      contract_start
      contract_end
      created_date
      hunt_group
      circuit_number2
      circuit_foc_date
      notes
      location_routing_number
      fields
      request_service_id
      revio_customer {
        id
        customer_id
        billing_address_id
        service_address_id
        listing_address_id
        parent_customer_id
        parent_customer_id_sort
        account_number
        activated_date
        agent_id
        secondary_agent_id
        close_date
        created_by
        created_date
        updated_date
        email
        is_parent
        registration_code
        security_pin
        sold_by
        source
        status
        auto_debit_enabled
        auto_debit_day
        auto_debit_payment_account_id
        bill_profile_id
        billing_method
        balance_limit
        balance_limit_enabled
        collection_template_id
        contract_end_date
        contract_start_date
        cycle_date
        late_fees_enabled
        payment_terms_days
        numbers
        location_id
        tax_exempt_enabled
        tax_exempt_types
        usage_file_enabled
        usage_file_format
        payment_fees_enabled
        class
        date_of_birth
        language
        tax_id
        voip_switch_id
        suspended
        suspended_date
        culture
        fields
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateRevioServices = /* GraphQL */ `
  mutation UpdateRevioServices(
    $input: UpdateRevioServicesInput!
    $condition: ModelRevioServicesConditionInput
  ) {
    updateRevioServices(input: $input, condition: $condition) {
      id
      service_id
      customer_id
      provider_id
      service_type_id
      package_id
      number
      pic
      lpic
      provider_account_id
      usage_plan_group_id
      number2
      description
      disconnected_date
      activated_date
      address_id
      addressz_id
      provider_account_number
      contract_start
      contract_end
      created_date
      hunt_group
      circuit_number2
      circuit_foc_date
      notes
      location_routing_number
      fields
      request_service_id
      revio_customer {
        id
        customer_id
        billing_address_id
        service_address_id
        listing_address_id
        parent_customer_id
        parent_customer_id_sort
        account_number
        activated_date
        agent_id
        secondary_agent_id
        close_date
        created_by
        created_date
        updated_date
        email
        is_parent
        registration_code
        security_pin
        sold_by
        source
        status
        auto_debit_enabled
        auto_debit_day
        auto_debit_payment_account_id
        bill_profile_id
        billing_method
        balance_limit
        balance_limit_enabled
        collection_template_id
        contract_end_date
        contract_start_date
        cycle_date
        late_fees_enabled
        payment_terms_days
        numbers
        location_id
        tax_exempt_enabled
        tax_exempt_types
        usage_file_enabled
        usage_file_format
        payment_fees_enabled
        class
        date_of_birth
        language
        tax_id
        voip_switch_id
        suspended
        suspended_date
        culture
        fields
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteRevioServices = /* GraphQL */ `
  mutation DeleteRevioServices(
    $input: DeleteRevioServicesInput!
    $condition: ModelRevioServicesConditionInput
  ) {
    deleteRevioServices(input: $input, condition: $condition) {
      id
      service_id
      customer_id
      provider_id
      service_type_id
      package_id
      number
      pic
      lpic
      provider_account_id
      usage_plan_group_id
      number2
      description
      disconnected_date
      activated_date
      address_id
      addressz_id
      provider_account_number
      contract_start
      contract_end
      created_date
      hunt_group
      circuit_number2
      circuit_foc_date
      notes
      location_routing_number
      fields
      request_service_id
      revio_customer {
        id
        customer_id
        billing_address_id
        service_address_id
        listing_address_id
        parent_customer_id
        parent_customer_id_sort
        account_number
        activated_date
        agent_id
        secondary_agent_id
        close_date
        created_by
        created_date
        updated_date
        email
        is_parent
        registration_code
        security_pin
        sold_by
        source
        status
        auto_debit_enabled
        auto_debit_day
        auto_debit_payment_account_id
        bill_profile_id
        billing_method
        balance_limit
        balance_limit_enabled
        collection_template_id
        contract_end_date
        contract_start_date
        cycle_date
        late_fees_enabled
        payment_terms_days
        numbers
        location_id
        tax_exempt_enabled
        tax_exempt_types
        usage_file_enabled
        usage_file_format
        payment_fees_enabled
        class
        date_of_birth
        language
        tax_id
        voip_switch_id
        suspended
        suspended_date
        culture
        fields
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createRevioServiceProducts = /* GraphQL */ `
  mutation CreateRevioServiceProducts(
    $input: CreateRevioServiceProductsInput!
    $condition: ModelRevioServiceProductsConditionInput
  ) {
    createRevioServiceProducts(input: $input, condition: $condition) {
      id
      service_product_id
      customer_id
      product_id
      product_information {
        nextToken
        startedAt
        __typename
      }
      package_id
      service_id
      description
      code_1
      code_2
      rate
      billed_through_date
      canceled_date
      status
      status_date
      status_user_id
      activated_date
      cost
      wholesale_description
      free_start_date
      free_end_date
      quantity
      contract_start_date
      contract_end_date
      created_date
      tax_included
      group_on_bill
      itemized
      fields
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateRevioServiceProducts = /* GraphQL */ `
  mutation UpdateRevioServiceProducts(
    $input: UpdateRevioServiceProductsInput!
    $condition: ModelRevioServiceProductsConditionInput
  ) {
    updateRevioServiceProducts(input: $input, condition: $condition) {
      id
      service_product_id
      customer_id
      product_id
      product_information {
        nextToken
        startedAt
        __typename
      }
      package_id
      service_id
      description
      code_1
      code_2
      rate
      billed_through_date
      canceled_date
      status
      status_date
      status_user_id
      activated_date
      cost
      wholesale_description
      free_start_date
      free_end_date
      quantity
      contract_start_date
      contract_end_date
      created_date
      tax_included
      group_on_bill
      itemized
      fields
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteRevioServiceProducts = /* GraphQL */ `
  mutation DeleteRevioServiceProducts(
    $input: DeleteRevioServiceProductsInput!
    $condition: ModelRevioServiceProductsConditionInput
  ) {
    deleteRevioServiceProducts(input: $input, condition: $condition) {
      id
      service_product_id
      customer_id
      product_id
      product_information {
        nextToken
        startedAt
        __typename
      }
      package_id
      service_id
      description
      code_1
      code_2
      rate
      billed_through_date
      canceled_date
      status
      status_date
      status_user_id
      activated_date
      cost
      wholesale_description
      free_start_date
      free_end_date
      quantity
      contract_start_date
      contract_end_date
      created_date
      tax_included
      group_on_bill
      itemized
      fields
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createRevioServiceInventory = /* GraphQL */ `
  mutation CreateRevioServiceInventory(
    $input: CreateRevioServiceInventoryInput!
    $condition: ModelRevioServiceInventoryConditionInput
  ) {
    createRevioServiceInventory(input: $input, condition: $condition) {
      id
      inventory_item_id
      customer_id
      line_id
      line_type
      identifier
      inventory_type
      status
      assigned_date
      assigned_by
      note
      created_date
      user_created_by
      NPA
      NXX
      revio_customer {
        id
        customer_id
        billing_address_id
        service_address_id
        listing_address_id
        parent_customer_id
        parent_customer_id_sort
        account_number
        activated_date
        agent_id
        secondary_agent_id
        close_date
        created_by
        created_date
        updated_date
        email
        is_parent
        registration_code
        security_pin
        sold_by
        source
        status
        auto_debit_enabled
        auto_debit_day
        auto_debit_payment_account_id
        bill_profile_id
        billing_method
        balance_limit
        balance_limit_enabled
        collection_template_id
        contract_end_date
        contract_start_date
        cycle_date
        late_fees_enabled
        payment_terms_days
        numbers
        location_id
        tax_exempt_enabled
        tax_exempt_types
        usage_file_enabled
        usage_file_format
        payment_fees_enabled
        class
        date_of_birth
        language
        tax_id
        voip_switch_id
        suspended
        suspended_date
        culture
        fields
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateRevioServiceInventory = /* GraphQL */ `
  mutation UpdateRevioServiceInventory(
    $input: UpdateRevioServiceInventoryInput!
    $condition: ModelRevioServiceInventoryConditionInput
  ) {
    updateRevioServiceInventory(input: $input, condition: $condition) {
      id
      inventory_item_id
      customer_id
      line_id
      line_type
      identifier
      inventory_type
      status
      assigned_date
      assigned_by
      note
      created_date
      user_created_by
      NPA
      NXX
      revio_customer {
        id
        customer_id
        billing_address_id
        service_address_id
        listing_address_id
        parent_customer_id
        parent_customer_id_sort
        account_number
        activated_date
        agent_id
        secondary_agent_id
        close_date
        created_by
        created_date
        updated_date
        email
        is_parent
        registration_code
        security_pin
        sold_by
        source
        status
        auto_debit_enabled
        auto_debit_day
        auto_debit_payment_account_id
        bill_profile_id
        billing_method
        balance_limit
        balance_limit_enabled
        collection_template_id
        contract_end_date
        contract_start_date
        cycle_date
        late_fees_enabled
        payment_terms_days
        numbers
        location_id
        tax_exempt_enabled
        tax_exempt_types
        usage_file_enabled
        usage_file_format
        payment_fees_enabled
        class
        date_of_birth
        language
        tax_id
        voip_switch_id
        suspended
        suspended_date
        culture
        fields
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteRevioServiceInventory = /* GraphQL */ `
  mutation DeleteRevioServiceInventory(
    $input: DeleteRevioServiceInventoryInput!
    $condition: ModelRevioServiceInventoryConditionInput
  ) {
    deleteRevioServiceInventory(input: $input, condition: $condition) {
      id
      inventory_item_id
      customer_id
      line_id
      line_type
      identifier
      inventory_type
      status
      assigned_date
      assigned_by
      note
      created_date
      user_created_by
      NPA
      NXX
      revio_customer {
        id
        customer_id
        billing_address_id
        service_address_id
        listing_address_id
        parent_customer_id
        parent_customer_id_sort
        account_number
        activated_date
        agent_id
        secondary_agent_id
        close_date
        created_by
        created_date
        updated_date
        email
        is_parent
        registration_code
        security_pin
        sold_by
        source
        status
        auto_debit_enabled
        auto_debit_day
        auto_debit_payment_account_id
        bill_profile_id
        billing_method
        balance_limit
        balance_limit_enabled
        collection_template_id
        contract_end_date
        contract_start_date
        cycle_date
        late_fees_enabled
        payment_terms_days
        numbers
        location_id
        tax_exempt_enabled
        tax_exempt_types
        usage_file_enabled
        usage_file_format
        payment_fees_enabled
        class
        date_of_birth
        language
        tax_id
        voip_switch_id
        suspended
        suspended_date
        culture
        fields
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createRevioProductTypes = /* GraphQL */ `
  mutation CreateRevioProductTypes(
    $input: CreateRevioProductTypesInput!
    $condition: ModelRevioProductTypesConditionInput
  ) {
    createRevioProductTypes(input: $input, condition: $condition) {
      id
      product_type_id
      product_type_code
      description
      tax_class_id
      active
      one_time_flag
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateRevioProductTypes = /* GraphQL */ `
  mutation UpdateRevioProductTypes(
    $input: UpdateRevioProductTypesInput!
    $condition: ModelRevioProductTypesConditionInput
  ) {
    updateRevioProductTypes(input: $input, condition: $condition) {
      id
      product_type_id
      product_type_code
      description
      tax_class_id
      active
      one_time_flag
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteRevioProductTypes = /* GraphQL */ `
  mutation DeleteRevioProductTypes(
    $input: DeleteRevioProductTypesInput!
    $condition: ModelRevioProductTypesConditionInput
  ) {
    deleteRevioProductTypes(input: $input, condition: $condition) {
      id
      product_type_id
      product_type_code
      description
      tax_class_id
      active
      one_time_flag
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createRevioProducts = /* GraphQL */ `
  mutation CreateRevioProducts(
    $input: CreateRevioProductsInput!
    $condition: ModelRevioProductsConditionInput
  ) {
    createRevioProducts(input: $input, condition: $condition) {
      id
      currency_code
      product_id
      product_type_id
      product_type {
        nextToken
        startedAt
        __typename
      }
      description
      code_1
      code_2
      rate
      cost
      buy_rate
      created_date
      created_by
      active
      creates_order
      provider_id
      bills_in_arrears
      prorates
      prorates_on_disconnect
      customer_class
      long_description
      ledger_code
      free_months
      automatic_expiration_months
      order_completion_billing
      tax_class_id
      tax_code {
        nextToken
        startedAt
        __typename
      }
      wholesale_description
      smart_pricing_id
      bill_profile_ids
      service_type_ids
      fields
      category_id
      is_credit_account_type
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateRevioProducts = /* GraphQL */ `
  mutation UpdateRevioProducts(
    $input: UpdateRevioProductsInput!
    $condition: ModelRevioProductsConditionInput
  ) {
    updateRevioProducts(input: $input, condition: $condition) {
      id
      currency_code
      product_id
      product_type_id
      product_type {
        nextToken
        startedAt
        __typename
      }
      description
      code_1
      code_2
      rate
      cost
      buy_rate
      created_date
      created_by
      active
      creates_order
      provider_id
      bills_in_arrears
      prorates
      prorates_on_disconnect
      customer_class
      long_description
      ledger_code
      free_months
      automatic_expiration_months
      order_completion_billing
      tax_class_id
      tax_code {
        nextToken
        startedAt
        __typename
      }
      wholesale_description
      smart_pricing_id
      bill_profile_ids
      service_type_ids
      fields
      category_id
      is_credit_account_type
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteRevioProducts = /* GraphQL */ `
  mutation DeleteRevioProducts(
    $input: DeleteRevioProductsInput!
    $condition: ModelRevioProductsConditionInput
  ) {
    deleteRevioProducts(input: $input, condition: $condition) {
      id
      currency_code
      product_id
      product_type_id
      product_type {
        nextToken
        startedAt
        __typename
      }
      description
      code_1
      code_2
      rate
      cost
      buy_rate
      created_date
      created_by
      active
      creates_order
      provider_id
      bills_in_arrears
      prorates
      prorates_on_disconnect
      customer_class
      long_description
      ledger_code
      free_months
      automatic_expiration_months
      order_completion_billing
      tax_class_id
      tax_code {
        nextToken
        startedAt
        __typename
      }
      wholesale_description
      smart_pricing_id
      bill_profile_ids
      service_type_ids
      fields
      category_id
      is_credit_account_type
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createRevioInventoryTypes = /* GraphQL */ `
  mutation CreateRevioInventoryTypes(
    $input: CreateRevioInventoryTypesInput!
    $condition: ModelRevioInventoryTypesConditionInput
  ) {
    createRevioInventoryTypes(input: $input, condition: $condition) {
      id
      inventory_type_id
      category
      identifier
      name
      requires_product
      description
      status
      format
      ratable
      fields
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateRevioInventoryTypes = /* GraphQL */ `
  mutation UpdateRevioInventoryTypes(
    $input: UpdateRevioInventoryTypesInput!
    $condition: ModelRevioInventoryTypesConditionInput
  ) {
    updateRevioInventoryTypes(input: $input, condition: $condition) {
      id
      inventory_type_id
      category
      identifier
      name
      requires_product
      description
      status
      format
      ratable
      fields
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteRevioInventoryTypes = /* GraphQL */ `
  mutation DeleteRevioInventoryTypes(
    $input: DeleteRevioInventoryTypesInput!
    $condition: ModelRevioInventoryTypesConditionInput
  ) {
    deleteRevioInventoryTypes(input: $input, condition: $condition) {
      id
      inventory_type_id
      category
      identifier
      name
      requires_product
      description
      status
      format
      ratable
      fields
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createRevioDialPrefixRates = /* GraphQL */ `
  mutation CreateRevioDialPrefixRates(
    $input: CreateRevioDialPrefixRatesInput!
    $condition: ModelRevioDialPrefixRatesConditionInput
  ) {
    createRevioDialPrefixRates(input: $input, condition: $condition) {
      id
      country_id
      country
      dial_prefix
      retail_rate
      wholesale_rate
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateRevioDialPrefixRates = /* GraphQL */ `
  mutation UpdateRevioDialPrefixRates(
    $input: UpdateRevioDialPrefixRatesInput!
    $condition: ModelRevioDialPrefixRatesConditionInput
  ) {
    updateRevioDialPrefixRates(input: $input, condition: $condition) {
      id
      country_id
      country
      dial_prefix
      retail_rate
      wholesale_rate
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteRevioDialPrefixRates = /* GraphQL */ `
  mutation DeleteRevioDialPrefixRates(
    $input: DeleteRevioDialPrefixRatesInput!
    $condition: ModelRevioDialPrefixRatesConditionInput
  ) {
    deleteRevioDialPrefixRates(input: $input, condition: $condition) {
      id
      country_id
      country
      dial_prefix
      retail_rate
      wholesale_rate
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createRevioInventoryItems = /* GraphQL */ `
  mutation CreateRevioInventoryItems(
    $input: CreateRevioInventoryItemsInput!
    $condition: ModelRevioInventoryItemsConditionInput
  ) {
    createRevioInventoryItems(input: $input, condition: $condition) {
      id
      inventory_item_id
      inventory_item_type_id
      inventory_type {
        nextToken
        startedAt
        __typename
      }
      identifier
      customer_id
      created_date
      created_by
      status
      inventory_item_unavailable_reason_id
      note
      fields
      revio_customer {
        id
        customer_id
        billing_address_id
        service_address_id
        listing_address_id
        parent_customer_id
        parent_customer_id_sort
        account_number
        activated_date
        agent_id
        secondary_agent_id
        close_date
        created_by
        created_date
        updated_date
        email
        is_parent
        registration_code
        security_pin
        sold_by
        source
        status
        auto_debit_enabled
        auto_debit_day
        auto_debit_payment_account_id
        bill_profile_id
        billing_method
        balance_limit
        balance_limit_enabled
        collection_template_id
        contract_end_date
        contract_start_date
        cycle_date
        late_fees_enabled
        payment_terms_days
        numbers
        location_id
        tax_exempt_enabled
        tax_exempt_types
        usage_file_enabled
        usage_file_format
        payment_fees_enabled
        class
        date_of_birth
        language
        tax_id
        voip_switch_id
        suspended
        suspended_date
        culture
        fields
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateRevioInventoryItems = /* GraphQL */ `
  mutation UpdateRevioInventoryItems(
    $input: UpdateRevioInventoryItemsInput!
    $condition: ModelRevioInventoryItemsConditionInput
  ) {
    updateRevioInventoryItems(input: $input, condition: $condition) {
      id
      inventory_item_id
      inventory_item_type_id
      inventory_type {
        nextToken
        startedAt
        __typename
      }
      identifier
      customer_id
      created_date
      created_by
      status
      inventory_item_unavailable_reason_id
      note
      fields
      revio_customer {
        id
        customer_id
        billing_address_id
        service_address_id
        listing_address_id
        parent_customer_id
        parent_customer_id_sort
        account_number
        activated_date
        agent_id
        secondary_agent_id
        close_date
        created_by
        created_date
        updated_date
        email
        is_parent
        registration_code
        security_pin
        sold_by
        source
        status
        auto_debit_enabled
        auto_debit_day
        auto_debit_payment_account_id
        bill_profile_id
        billing_method
        balance_limit
        balance_limit_enabled
        collection_template_id
        contract_end_date
        contract_start_date
        cycle_date
        late_fees_enabled
        payment_terms_days
        numbers
        location_id
        tax_exempt_enabled
        tax_exempt_types
        usage_file_enabled
        usage_file_format
        payment_fees_enabled
        class
        date_of_birth
        language
        tax_id
        voip_switch_id
        suspended
        suspended_date
        culture
        fields
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteRevioInventoryItems = /* GraphQL */ `
  mutation DeleteRevioInventoryItems(
    $input: DeleteRevioInventoryItemsInput!
    $condition: ModelRevioInventoryItemsConditionInput
  ) {
    deleteRevioInventoryItems(input: $input, condition: $condition) {
      id
      inventory_item_id
      inventory_item_type_id
      inventory_type {
        nextToken
        startedAt
        __typename
      }
      identifier
      customer_id
      created_date
      created_by
      status
      inventory_item_unavailable_reason_id
      note
      fields
      revio_customer {
        id
        customer_id
        billing_address_id
        service_address_id
        listing_address_id
        parent_customer_id
        parent_customer_id_sort
        account_number
        activated_date
        agent_id
        secondary_agent_id
        close_date
        created_by
        created_date
        updated_date
        email
        is_parent
        registration_code
        security_pin
        sold_by
        source
        status
        auto_debit_enabled
        auto_debit_day
        auto_debit_payment_account_id
        bill_profile_id
        billing_method
        balance_limit
        balance_limit_enabled
        collection_template_id
        contract_end_date
        contract_start_date
        cycle_date
        late_fees_enabled
        payment_terms_days
        numbers
        location_id
        tax_exempt_enabled
        tax_exempt_types
        usage_file_enabled
        usage_file_format
        payment_fees_enabled
        class
        date_of_birth
        language
        tax_id
        voip_switch_id
        suspended
        suspended_date
        culture
        fields
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createRevioCharges = /* GraphQL */ `
  mutation CreateRevioCharges(
    $input: CreateRevioChargesInput!
    $condition: ModelRevioChargesConditionInput
  ) {
    createRevioCharges(input: $input, condition: $condition) {
      id
      charge_id
      product_id
      product_type_id
      customer_id
      bill_id
      service_id
      service_product_id
      is_tax_included
      tax_class_id
      description
      amount
      cost
      product_amount
      is_commision_exempted
      tax_amount
      geocode
      proration
      created_date
      created_by
      is_prorated
      start_date
      end_date
      rate
      quantity
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateRevioCharges = /* GraphQL */ `
  mutation UpdateRevioCharges(
    $input: UpdateRevioChargesInput!
    $condition: ModelRevioChargesConditionInput
  ) {
    updateRevioCharges(input: $input, condition: $condition) {
      id
      charge_id
      product_id
      product_type_id
      customer_id
      bill_id
      service_id
      service_product_id
      is_tax_included
      tax_class_id
      description
      amount
      cost
      product_amount
      is_commision_exempted
      tax_amount
      geocode
      proration
      created_date
      created_by
      is_prorated
      start_date
      end_date
      rate
      quantity
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteRevioCharges = /* GraphQL */ `
  mutation DeleteRevioCharges(
    $input: DeleteRevioChargesInput!
    $condition: ModelRevioChargesConditionInput
  ) {
    deleteRevioCharges(input: $input, condition: $condition) {
      id
      charge_id
      product_id
      product_type_id
      customer_id
      bill_id
      service_id
      service_product_id
      is_tax_included
      tax_class_id
      description
      amount
      cost
      product_amount
      is_commision_exempted
      tax_amount
      geocode
      proration
      created_date
      created_by
      is_prorated
      start_date
      end_date
      rate
      quantity
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createCustomerSupportInformation = /* GraphQL */ `
  mutation CreateCustomerSupportInformation(
    $input: CreateCustomerSupportInformationInput!
    $condition: ModelCustomerSupportInformationConditionInput
  ) {
    createCustomerSupportInformation(input: $input, condition: $condition) {
      id
      partner
      partner_information {
        nextToken
        startedAt
        __typename
      }
      external_id
      service_type
      service_status
      electric_serviceman_number
      electric_sub_feeder
      electric_email_ticket
      contact_phone_number
      customer_type
      name
      address
      city
      state
      zip
      email
      is_les
      location_id
      service_package_info
      connection_date
      revio_id
      revio_customer {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateCustomerSupportInformation = /* GraphQL */ `
  mutation UpdateCustomerSupportInformation(
    $input: UpdateCustomerSupportInformationInput!
    $condition: ModelCustomerSupportInformationConditionInput
  ) {
    updateCustomerSupportInformation(input: $input, condition: $condition) {
      id
      partner
      partner_information {
        nextToken
        startedAt
        __typename
      }
      external_id
      service_type
      service_status
      electric_serviceman_number
      electric_sub_feeder
      electric_email_ticket
      contact_phone_number
      customer_type
      name
      address
      city
      state
      zip
      email
      is_les
      location_id
      service_package_info
      connection_date
      revio_id
      revio_customer {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteCustomerSupportInformation = /* GraphQL */ `
  mutation DeleteCustomerSupportInformation(
    $input: DeleteCustomerSupportInformationInput!
    $condition: ModelCustomerSupportInformationConditionInput
  ) {
    deleteCustomerSupportInformation(input: $input, condition: $condition) {
      id
      partner
      partner_information {
        nextToken
        startedAt
        __typename
      }
      external_id
      service_type
      service_status
      electric_serviceman_number
      electric_sub_feeder
      electric_email_ticket
      contact_phone_number
      customer_type
      name
      address
      city
      state
      zip
      email
      is_les
      location_id
      service_package_info
      connection_date
      revio_id
      revio_customer {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createRevioCustomers = /* GraphQL */ `
  mutation CreateRevioCustomers(
    $input: CreateRevioCustomersInput!
    $condition: ModelRevioCustomersConditionInput
  ) {
    createRevioCustomers(input: $input, condition: $condition) {
      id
      customer_id
      billing_address_id
      service_address_id
      listing_address_id
      billing_address {
        nextToken
        startedAt
        __typename
      }
      service_address {
        nextToken
        startedAt
        __typename
      }
      listing_address {
        nextToken
        startedAt
        __typename
      }
      parent_customer_id
      parent_customer_id_sort
      account_number
      activated_date
      agent_id
      secondary_agent_id
      close_date
      created_by
      created_date
      updated_date
      email
      is_parent
      registration_code
      security_pin
      sold_by
      bills {
        nextToken
        startedAt
        __typename
      }
      inventory_items {
        nextToken
        startedAt
        __typename
      }
      services {
        nextToken
        startedAt
        __typename
      }
      service_inventory {
        nextToken
        startedAt
        __typename
      }
      service_products {
        nextToken
        startedAt
        __typename
      }
      source
      status
      auto_debit_enabled
      auto_debit_day
      auto_debit_payment_account_id
      bill_profile_id
      billing_method
      balance_limit
      balance_limit_enabled
      collection_template_id
      contract_end_date
      contract_start_date
      cycle_date
      late_fees_enabled
      payment_terms_days
      numbers
      location_id
      tax_exempt_enabled
      tax_exempt_types
      usage_file_enabled
      usage_file_format
      payment_fees_enabled
      class
      date_of_birth
      language
      tax_id
      voip_switch_id
      suspended
      suspended_date
      culture
      fields
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateRevioCustomers = /* GraphQL */ `
  mutation UpdateRevioCustomers(
    $input: UpdateRevioCustomersInput!
    $condition: ModelRevioCustomersConditionInput
  ) {
    updateRevioCustomers(input: $input, condition: $condition) {
      id
      customer_id
      billing_address_id
      service_address_id
      listing_address_id
      billing_address {
        nextToken
        startedAt
        __typename
      }
      service_address {
        nextToken
        startedAt
        __typename
      }
      listing_address {
        nextToken
        startedAt
        __typename
      }
      parent_customer_id
      parent_customer_id_sort
      account_number
      activated_date
      agent_id
      secondary_agent_id
      close_date
      created_by
      created_date
      updated_date
      email
      is_parent
      registration_code
      security_pin
      sold_by
      bills {
        nextToken
        startedAt
        __typename
      }
      inventory_items {
        nextToken
        startedAt
        __typename
      }
      services {
        nextToken
        startedAt
        __typename
      }
      service_inventory {
        nextToken
        startedAt
        __typename
      }
      service_products {
        nextToken
        startedAt
        __typename
      }
      source
      status
      auto_debit_enabled
      auto_debit_day
      auto_debit_payment_account_id
      bill_profile_id
      billing_method
      balance_limit
      balance_limit_enabled
      collection_template_id
      contract_end_date
      contract_start_date
      cycle_date
      late_fees_enabled
      payment_terms_days
      numbers
      location_id
      tax_exempt_enabled
      tax_exempt_types
      usage_file_enabled
      usage_file_format
      payment_fees_enabled
      class
      date_of_birth
      language
      tax_id
      voip_switch_id
      suspended
      suspended_date
      culture
      fields
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteRevioCustomers = /* GraphQL */ `
  mutation DeleteRevioCustomers(
    $input: DeleteRevioCustomersInput!
    $condition: ModelRevioCustomersConditionInput
  ) {
    deleteRevioCustomers(input: $input, condition: $condition) {
      id
      customer_id
      billing_address_id
      service_address_id
      listing_address_id
      billing_address {
        nextToken
        startedAt
        __typename
      }
      service_address {
        nextToken
        startedAt
        __typename
      }
      listing_address {
        nextToken
        startedAt
        __typename
      }
      parent_customer_id
      parent_customer_id_sort
      account_number
      activated_date
      agent_id
      secondary_agent_id
      close_date
      created_by
      created_date
      updated_date
      email
      is_parent
      registration_code
      security_pin
      sold_by
      bills {
        nextToken
        startedAt
        __typename
      }
      inventory_items {
        nextToken
        startedAt
        __typename
      }
      services {
        nextToken
        startedAt
        __typename
      }
      service_inventory {
        nextToken
        startedAt
        __typename
      }
      service_products {
        nextToken
        startedAt
        __typename
      }
      source
      status
      auto_debit_enabled
      auto_debit_day
      auto_debit_payment_account_id
      bill_profile_id
      billing_method
      balance_limit
      balance_limit_enabled
      collection_template_id
      contract_end_date
      contract_start_date
      cycle_date
      late_fees_enabled
      payment_terms_days
      numbers
      location_id
      tax_exempt_enabled
      tax_exempt_types
      usage_file_enabled
      usage_file_format
      payment_fees_enabled
      class
      date_of_birth
      language
      tax_id
      voip_switch_id
      suspended
      suspended_date
      culture
      fields
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createRevioBills = /* GraphQL */ `
  mutation CreateRevioBills(
    $input: CreateRevioBillsInput!
    $condition: ModelRevioBillsConditionInput
  ) {
    createRevioBills(input: $input, condition: $condition) {
      id
      bill_id
      parent_bill_id
      bill_type
      print_batch_id
      customer_id
      due_date
      created_date
      amount_due
      invoice_amount
      credit_memo_amount
      print_queue_flag
      printed_date
      generate_downloads
      late_fee_applied_flag
      first_month_flag
      ebpp_queue_flag
      ebpp_sent_date
      itemize_local
      is_final_bill
      note
      bill_print_type_id
      isLive
      cycle_data
      pdf_only_flag
      print_profile_id
      ready_for_pdf_flag
      bill_create_batch_id
      pdf_file_id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateRevioBills = /* GraphQL */ `
  mutation UpdateRevioBills(
    $input: UpdateRevioBillsInput!
    $condition: ModelRevioBillsConditionInput
  ) {
    updateRevioBills(input: $input, condition: $condition) {
      id
      bill_id
      parent_bill_id
      bill_type
      print_batch_id
      customer_id
      due_date
      created_date
      amount_due
      invoice_amount
      credit_memo_amount
      print_queue_flag
      printed_date
      generate_downloads
      late_fee_applied_flag
      first_month_flag
      ebpp_queue_flag
      ebpp_sent_date
      itemize_local
      is_final_bill
      note
      bill_print_type_id
      isLive
      cycle_data
      pdf_only_flag
      print_profile_id
      ready_for_pdf_flag
      bill_create_batch_id
      pdf_file_id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteRevioBills = /* GraphQL */ `
  mutation DeleteRevioBills(
    $input: DeleteRevioBillsInput!
    $condition: ModelRevioBillsConditionInput
  ) {
    deleteRevioBills(input: $input, condition: $condition) {
      id
      bill_id
      parent_bill_id
      bill_type
      print_batch_id
      customer_id
      due_date
      created_date
      amount_due
      invoice_amount
      credit_memo_amount
      print_queue_flag
      printed_date
      generate_downloads
      late_fee_applied_flag
      first_month_flag
      ebpp_queue_flag
      ebpp_sent_date
      itemize_local
      is_final_bill
      note
      bill_print_type_id
      isLive
      cycle_data
      pdf_only_flag
      print_profile_id
      ready_for_pdf_flag
      bill_create_batch_id
      pdf_file_id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createRevioBillProfiles = /* GraphQL */ `
  mutation CreateRevioBillProfiles(
    $input: CreateRevioBillProfilesInput!
    $condition: ModelRevioBillProfilesConditionInput
  ) {
    createRevioBillProfiles(input: $input, condition: $condition) {
      id
      bill_profile_id
      description
      lead_days
      advance_months
      term_days
      term_months
      cycle_type
      cycle_direction
      active
      currency_code
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateRevioBillProfiles = /* GraphQL */ `
  mutation UpdateRevioBillProfiles(
    $input: UpdateRevioBillProfilesInput!
    $condition: ModelRevioBillProfilesConditionInput
  ) {
    updateRevioBillProfiles(input: $input, condition: $condition) {
      id
      bill_profile_id
      description
      lead_days
      advance_months
      term_days
      term_months
      cycle_type
      cycle_direction
      active
      currency_code
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteRevioBillProfiles = /* GraphQL */ `
  mutation DeleteRevioBillProfiles(
    $input: DeleteRevioBillProfilesInput!
    $condition: ModelRevioBillProfilesConditionInput
  ) {
    deleteRevioBillProfiles(input: $input, condition: $condition) {
      id
      bill_profile_id
      description
      lead_days
      advance_months
      term_days
      term_months
      cycle_type
      cycle_direction
      active
      currency_code
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createRevioAddresses = /* GraphQL */ `
  mutation CreateRevioAddresses(
    $input: CreateRevioAddressesInput!
    $condition: ModelRevioAddressesConditionInput
  ) {
    createRevioAddresses(input: $input, condition: $condition) {
      id
      address_id
      customer_id
      first_name
      middle_initial
      last_name
      company_name
      line_1
      line_2
      city
      state_or_province
      postal_code
      postal_code_extension
      country_code
      created_date
      modified_date
      is_provider_validated
      is_USPS_validated
      revio_customer {
        id
        customer_id
        billing_address_id
        service_address_id
        listing_address_id
        parent_customer_id
        parent_customer_id_sort
        account_number
        activated_date
        agent_id
        secondary_agent_id
        close_date
        created_by
        created_date
        updated_date
        email
        is_parent
        registration_code
        security_pin
        sold_by
        source
        status
        auto_debit_enabled
        auto_debit_day
        auto_debit_payment_account_id
        bill_profile_id
        billing_method
        balance_limit
        balance_limit_enabled
        collection_template_id
        contract_end_date
        contract_start_date
        cycle_date
        late_fees_enabled
        payment_terms_days
        numbers
        location_id
        tax_exempt_enabled
        tax_exempt_types
        usage_file_enabled
        usage_file_format
        payment_fees_enabled
        class
        date_of_birth
        language
        tax_id
        voip_switch_id
        suspended
        suspended_date
        culture
        fields
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateRevioAddresses = /* GraphQL */ `
  mutation UpdateRevioAddresses(
    $input: UpdateRevioAddressesInput!
    $condition: ModelRevioAddressesConditionInput
  ) {
    updateRevioAddresses(input: $input, condition: $condition) {
      id
      address_id
      customer_id
      first_name
      middle_initial
      last_name
      company_name
      line_1
      line_2
      city
      state_or_province
      postal_code
      postal_code_extension
      country_code
      created_date
      modified_date
      is_provider_validated
      is_USPS_validated
      revio_customer {
        id
        customer_id
        billing_address_id
        service_address_id
        listing_address_id
        parent_customer_id
        parent_customer_id_sort
        account_number
        activated_date
        agent_id
        secondary_agent_id
        close_date
        created_by
        created_date
        updated_date
        email
        is_parent
        registration_code
        security_pin
        sold_by
        source
        status
        auto_debit_enabled
        auto_debit_day
        auto_debit_payment_account_id
        bill_profile_id
        billing_method
        balance_limit
        balance_limit_enabled
        collection_template_id
        contract_end_date
        contract_start_date
        cycle_date
        late_fees_enabled
        payment_terms_days
        numbers
        location_id
        tax_exempt_enabled
        tax_exempt_types
        usage_file_enabled
        usage_file_format
        payment_fees_enabled
        class
        date_of_birth
        language
        tax_id
        voip_switch_id
        suspended
        suspended_date
        culture
        fields
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteRevioAddresses = /* GraphQL */ `
  mutation DeleteRevioAddresses(
    $input: DeleteRevioAddressesInput!
    $condition: ModelRevioAddressesConditionInput
  ) {
    deleteRevioAddresses(input: $input, condition: $condition) {
      id
      address_id
      customer_id
      first_name
      middle_initial
      last_name
      company_name
      line_1
      line_2
      city
      state_or_province
      postal_code
      postal_code_extension
      country_code
      created_date
      modified_date
      is_provider_validated
      is_USPS_validated
      revio_customer {
        id
        customer_id
        billing_address_id
        service_address_id
        listing_address_id
        parent_customer_id
        parent_customer_id_sort
        account_number
        activated_date
        agent_id
        secondary_agent_id
        close_date
        created_by
        created_date
        updated_date
        email
        is_parent
        registration_code
        security_pin
        sold_by
        source
        status
        auto_debit_enabled
        auto_debit_day
        auto_debit_payment_account_id
        bill_profile_id
        billing_method
        balance_limit
        balance_limit_enabled
        collection_template_id
        contract_end_date
        contract_start_date
        cycle_date
        late_fees_enabled
        payment_terms_days
        numbers
        location_id
        tax_exempt_enabled
        tax_exempt_types
        usage_file_enabled
        usage_file_format
        payment_fees_enabled
        class
        date_of_birth
        language
        tax_id
        voip_switch_id
        suspended
        suspended_date
        culture
        fields
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createRevioTaxCodes = /* GraphQL */ `
  mutation CreateRevioTaxCodes(
    $input: CreateRevioTaxCodesInput!
    $condition: ModelRevioTaxCodesConditionInput
  ) {
    createRevioTaxCodes(input: $input, condition: $condition) {
      id
      tax_class_id
      tax_code
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateRevioTaxCodes = /* GraphQL */ `
  mutation UpdateRevioTaxCodes(
    $input: UpdateRevioTaxCodesInput!
    $condition: ModelRevioTaxCodesConditionInput
  ) {
    updateRevioTaxCodes(input: $input, condition: $condition) {
      id
      tax_class_id
      tax_code
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteRevioTaxCodes = /* GraphQL */ `
  mutation DeleteRevioTaxCodes(
    $input: DeleteRevioTaxCodesInput!
    $condition: ModelRevioTaxCodesConditionInput
  ) {
    deleteRevioTaxCodes(input: $input, condition: $condition) {
      id
      tax_class_id
      tax_code
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createXMLRequests = /* GraphQL */ `
  mutation CreateXMLRequests(
    $input: CreateXMLRequestsInput!
    $condition: ModelXMLRequestsConditionInput
  ) {
    createXMLRequests(input: $input, condition: $condition) {
      id
      customerName
      account_number
      accountStatus
      name
      first_name
      last_name
      address1
      city
      state
      zip
      telephone
      servDesc
      DataPackage
      email
      custType
      E911Address
      E911City
      E911State
      E911Zip
      VoicePackages
      VideoPackage
      FaxPackage
      monthlyRate
      new_phone1
      ported1
      sipPassword1
      numLines
      DIDs
      DIDdetail
      SONbr
      msg_type
      CallerIDName
      new_phone2
      ported2
      sipPassword2
      features
      CO_Name
      provisioned
      in_process
      errors
      PIN
      isManagedService
      created_on
      billingFlag
      taxFlag
      accessSFP
      billable
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateXMLRequests = /* GraphQL */ `
  mutation UpdateXMLRequests(
    $input: UpdateXMLRequestsInput!
    $condition: ModelXMLRequestsConditionInput
  ) {
    updateXMLRequests(input: $input, condition: $condition) {
      id
      customerName
      account_number
      accountStatus
      name
      first_name
      last_name
      address1
      city
      state
      zip
      telephone
      servDesc
      DataPackage
      email
      custType
      E911Address
      E911City
      E911State
      E911Zip
      VoicePackages
      VideoPackage
      FaxPackage
      monthlyRate
      new_phone1
      ported1
      sipPassword1
      numLines
      DIDs
      DIDdetail
      SONbr
      msg_type
      CallerIDName
      new_phone2
      ported2
      sipPassword2
      features
      CO_Name
      provisioned
      in_process
      errors
      PIN
      isManagedService
      created_on
      billingFlag
      taxFlag
      accessSFP
      billable
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteXMLRequests = /* GraphQL */ `
  mutation DeleteXMLRequests(
    $input: DeleteXMLRequestsInput!
    $condition: ModelXMLRequestsConditionInput
  ) {
    deleteXMLRequests(input: $input, condition: $condition) {
      id
      customerName
      account_number
      accountStatus
      name
      first_name
      last_name
      address1
      city
      state
      zip
      telephone
      servDesc
      DataPackage
      email
      custType
      E911Address
      E911City
      E911State
      E911Zip
      VoicePackages
      VideoPackage
      FaxPackage
      monthlyRate
      new_phone1
      ported1
      sipPassword1
      numLines
      DIDs
      DIDdetail
      SONbr
      msg_type
      CallerIDName
      new_phone2
      ported2
      sipPassword2
      features
      CO_Name
      provisioned
      in_process
      errors
      PIN
      isManagedService
      created_on
      billingFlag
      taxFlag
      accessSFP
      billable
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createQueues = /* GraphQL */ `
  mutation CreateQueues(
    $input: CreateQueuesInput!
    $condition: ModelQueuesConditionInput
  ) {
    createQueues(input: $input, condition: $condition) {
      id
      queue
      queue_name
      switch_queue_name
      contact_information
      sign_off_information
      flow_information
      overflow_textbox_information
      ticket_subject_values
      queue_cid_spoof_number
      fc_overflow_number
      tools_list
      allowed_groups
      allowed_partners
      tickets {
        nextToken
        startedAt
        __typename
      }
      custom_fields {
        nextToken
        startedAt
        __typename
      }
      rt_queue {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateQueues = /* GraphQL */ `
  mutation UpdateQueues(
    $input: UpdateQueuesInput!
    $condition: ModelQueuesConditionInput
  ) {
    updateQueues(input: $input, condition: $condition) {
      id
      queue
      queue_name
      switch_queue_name
      contact_information
      sign_off_information
      flow_information
      overflow_textbox_information
      ticket_subject_values
      queue_cid_spoof_number
      fc_overflow_number
      tools_list
      allowed_groups
      allowed_partners
      tickets {
        nextToken
        startedAt
        __typename
      }
      custom_fields {
        nextToken
        startedAt
        __typename
      }
      rt_queue {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteQueues = /* GraphQL */ `
  mutation DeleteQueues(
    $input: DeleteQueuesInput!
    $condition: ModelQueuesConditionInput
  ) {
    deleteQueues(input: $input, condition: $condition) {
      id
      queue
      queue_name
      switch_queue_name
      contact_information
      sign_off_information
      flow_information
      overflow_textbox_information
      ticket_subject_values
      queue_cid_spoof_number
      fc_overflow_number
      tools_list
      allowed_groups
      allowed_partners
      tickets {
        nextToken
        startedAt
        __typename
      }
      custom_fields {
        nextToken
        startedAt
        __typename
      }
      rt_queue {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createRTQueue = /* GraphQL */ `
  mutation CreateRTQueue(
    $input: CreateRTQueueInput!
    $condition: ModelRTQueueConditionInput
  ) {
    createRTQueue(input: $input, condition: $condition) {
      id
      queue_id
      queue_name
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateRTQueue = /* GraphQL */ `
  mutation UpdateRTQueue(
    $input: UpdateRTQueueInput!
    $condition: ModelRTQueueConditionInput
  ) {
    updateRTQueue(input: $input, condition: $condition) {
      id
      queue_id
      queue_name
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteRTQueue = /* GraphQL */ `
  mutation DeleteRTQueue(
    $input: DeleteRTQueueInput!
    $condition: ModelRTQueueConditionInput
  ) {
    deleteRTQueue(input: $input, condition: $condition) {
      id
      queue_id
      queue_name
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createQueueCustomFields = /* GraphQL */ `
  mutation CreateQueueCustomFields(
    $input: CreateQueueCustomFieldsInput!
    $condition: ModelQueueCustomFieldsConditionInput
  ) {
    createQueueCustomFields(input: $input, condition: $condition) {
      id
      queue_id
      default_custom_field_id
      default_custom_field {
        id
        field_name
        field_type
        field_values
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      ticket_custom_fields {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateQueueCustomFields = /* GraphQL */ `
  mutation UpdateQueueCustomFields(
    $input: UpdateQueueCustomFieldsInput!
    $condition: ModelQueueCustomFieldsConditionInput
  ) {
    updateQueueCustomFields(input: $input, condition: $condition) {
      id
      queue_id
      default_custom_field_id
      default_custom_field {
        id
        field_name
        field_type
        field_values
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      ticket_custom_fields {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteQueueCustomFields = /* GraphQL */ `
  mutation DeleteQueueCustomFields(
    $input: DeleteQueueCustomFieldsInput!
    $condition: ModelQueueCustomFieldsConditionInput
  ) {
    deleteQueueCustomFields(input: $input, condition: $condition) {
      id
      queue_id
      default_custom_field_id
      default_custom_field {
        id
        field_name
        field_type
        field_values
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      ticket_custom_fields {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createDefaultCustomFields = /* GraphQL */ `
  mutation CreateDefaultCustomFields(
    $input: CreateDefaultCustomFieldsInput!
    $condition: ModelDefaultCustomFieldsConditionInput
  ) {
    createDefaultCustomFields(input: $input, condition: $condition) {
      id
      queue_custom_fields {
        nextToken
        startedAt
        __typename
      }
      field_name
      field_type
      field_values
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateDefaultCustomFields = /* GraphQL */ `
  mutation UpdateDefaultCustomFields(
    $input: UpdateDefaultCustomFieldsInput!
    $condition: ModelDefaultCustomFieldsConditionInput
  ) {
    updateDefaultCustomFields(input: $input, condition: $condition) {
      id
      queue_custom_fields {
        nextToken
        startedAt
        __typename
      }
      field_name
      field_type
      field_values
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteDefaultCustomFields = /* GraphQL */ `
  mutation DeleteDefaultCustomFields(
    $input: DeleteDefaultCustomFieldsInput!
    $condition: ModelDefaultCustomFieldsConditionInput
  ) {
    deleteDefaultCustomFields(input: $input, condition: $condition) {
      id
      queue_custom_fields {
        nextToken
        startedAt
        __typename
      }
      field_name
      field_type
      field_values
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createTicketCustomFields = /* GraphQL */ `
  mutation CreateTicketCustomFields(
    $input: CreateTicketCustomFieldsInput!
    $condition: ModelTicketCustomFieldsConditionInput
  ) {
    createTicketCustomFields(input: $input, condition: $condition) {
      id
      ticket_id
      queue_custom_field_id
      queue_custom_field {
        id
        queue_id
        default_custom_field_id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      set_value
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateTicketCustomFields = /* GraphQL */ `
  mutation UpdateTicketCustomFields(
    $input: UpdateTicketCustomFieldsInput!
    $condition: ModelTicketCustomFieldsConditionInput
  ) {
    updateTicketCustomFields(input: $input, condition: $condition) {
      id
      ticket_id
      queue_custom_field_id
      queue_custom_field {
        id
        queue_id
        default_custom_field_id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      set_value
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteTicketCustomFields = /* GraphQL */ `
  mutation DeleteTicketCustomFields(
    $input: DeleteTicketCustomFieldsInput!
    $condition: ModelTicketCustomFieldsConditionInput
  ) {
    deleteTicketCustomFields(input: $input, condition: $condition) {
      id
      ticket_id
      queue_custom_field_id
      queue_custom_field {
        id
        queue_id
        default_custom_field_id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      set_value
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createTickets = /* GraphQL */ `
  mutation CreateTickets(
    $input: CreateTicketsInput!
    $condition: ModelTicketsConditionInput
  ) {
    createTickets(input: $input, condition: $condition) {
      id
      queue_id
      queue {
        id
        queue
        queue_name
        switch_queue_name
        contact_information
        sign_off_information
        flow_information
        overflow_textbox_information
        ticket_subject_values
        queue_cid_spoof_number
        fc_overflow_number
        tools_list
        allowed_groups
        allowed_partners
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      subject
      description
      status
      time_started
      time_last_contact
      priority
      owner
      owner_group
      creator
      requestor
      requestor_group
      cc
      createdAt
      updatedAt
      contact_information
      special_instructions
      custom_fields {
        nextToken
        startedAt
        __typename
      }
      comments {
        nextToken
        startedAt
        __typename
      }
      activity {
        nextToken
        startedAt
        __typename
      }
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateTickets = /* GraphQL */ `
  mutation UpdateTickets(
    $input: UpdateTicketsInput!
    $condition: ModelTicketsConditionInput
  ) {
    updateTickets(input: $input, condition: $condition) {
      id
      queue_id
      queue {
        id
        queue
        queue_name
        switch_queue_name
        contact_information
        sign_off_information
        flow_information
        overflow_textbox_information
        ticket_subject_values
        queue_cid_spoof_number
        fc_overflow_number
        tools_list
        allowed_groups
        allowed_partners
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      subject
      description
      status
      time_started
      time_last_contact
      priority
      owner
      owner_group
      creator
      requestor
      requestor_group
      cc
      createdAt
      updatedAt
      contact_information
      special_instructions
      custom_fields {
        nextToken
        startedAt
        __typename
      }
      comments {
        nextToken
        startedAt
        __typename
      }
      activity {
        nextToken
        startedAt
        __typename
      }
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteTickets = /* GraphQL */ `
  mutation DeleteTickets(
    $input: DeleteTicketsInput!
    $condition: ModelTicketsConditionInput
  ) {
    deleteTickets(input: $input, condition: $condition) {
      id
      queue_id
      queue {
        id
        queue
        queue_name
        switch_queue_name
        contact_information
        sign_off_information
        flow_information
        overflow_textbox_information
        ticket_subject_values
        queue_cid_spoof_number
        fc_overflow_number
        tools_list
        allowed_groups
        allowed_partners
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      subject
      description
      status
      time_started
      time_last_contact
      priority
      owner
      owner_group
      creator
      requestor
      requestor_group
      cc
      createdAt
      updatedAt
      contact_information
      special_instructions
      custom_fields {
        nextToken
        startedAt
        __typename
      }
      comments {
        nextToken
        startedAt
        __typename
      }
      activity {
        nextToken
        startedAt
        __typename
      }
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createTicketComments = /* GraphQL */ `
  mutation CreateTicketComments(
    $input: CreateTicketCommentsInput!
    $condition: ModelTicketCommentsConditionInput
  ) {
    createTicketComments(input: $input, condition: $condition) {
      id
      ticket_id
      visibility_group
      subject
      content
      createdAt
      updatedAt
      comment_owner
      attachments {
        nextToken
        startedAt
        __typename
      }
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateTicketComments = /* GraphQL */ `
  mutation UpdateTicketComments(
    $input: UpdateTicketCommentsInput!
    $condition: ModelTicketCommentsConditionInput
  ) {
    updateTicketComments(input: $input, condition: $condition) {
      id
      ticket_id
      visibility_group
      subject
      content
      createdAt
      updatedAt
      comment_owner
      attachments {
        nextToken
        startedAt
        __typename
      }
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteTicketComments = /* GraphQL */ `
  mutation DeleteTicketComments(
    $input: DeleteTicketCommentsInput!
    $condition: ModelTicketCommentsConditionInput
  ) {
    deleteTicketComments(input: $input, condition: $condition) {
      id
      ticket_id
      visibility_group
      subject
      content
      createdAt
      updatedAt
      comment_owner
      attachments {
        nextToken
        startedAt
        __typename
      }
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createTicketAttachments = /* GraphQL */ `
  mutation CreateTicketAttachments(
    $input: CreateTicketAttachmentsInput!
    $condition: ModelTicketAttachmentsConditionInput
  ) {
    createTicketAttachments(input: $input, condition: $condition) {
      id
      ticket_comment_id
      s3_bucket_name
      s3_bucket_key
      file_type
      base64_string
      file_name
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateTicketAttachments = /* GraphQL */ `
  mutation UpdateTicketAttachments(
    $input: UpdateTicketAttachmentsInput!
    $condition: ModelTicketAttachmentsConditionInput
  ) {
    updateTicketAttachments(input: $input, condition: $condition) {
      id
      ticket_comment_id
      s3_bucket_name
      s3_bucket_key
      file_type
      base64_string
      file_name
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteTicketAttachments = /* GraphQL */ `
  mutation DeleteTicketAttachments(
    $input: DeleteTicketAttachmentsInput!
    $condition: ModelTicketAttachmentsConditionInput
  ) {
    deleteTicketAttachments(input: $input, condition: $condition) {
      id
      ticket_comment_id
      s3_bucket_name
      s3_bucket_key
      file_type
      base64_string
      file_name
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createTicketActivity = /* GraphQL */ `
  mutation CreateTicketActivity(
    $input: CreateTicketActivityInput!
    $condition: ModelTicketActivityConditionInput
  ) {
    createTicketActivity(input: $input, condition: $condition) {
      id
      ticket_id
      activity_type
      field_changed
      old_field_value
      new_field_value
      activity_owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateTicketActivity = /* GraphQL */ `
  mutation UpdateTicketActivity(
    $input: UpdateTicketActivityInput!
    $condition: ModelTicketActivityConditionInput
  ) {
    updateTicketActivity(input: $input, condition: $condition) {
      id
      ticket_id
      activity_type
      field_changed
      old_field_value
      new_field_value
      activity_owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteTicketActivity = /* GraphQL */ `
  mutation DeleteTicketActivity(
    $input: DeleteTicketActivityInput!
    $condition: ModelTicketActivityConditionInput
  ) {
    deleteTicketActivity(input: $input, condition: $condition) {
      id
      ticket_id
      activity_type
      field_changed
      old_field_value
      new_field_value
      activity_owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createDispatch = /* GraphQL */ `
  mutation CreateDispatch(
    $input: CreateDispatchInput!
    $condition: ModelDispatchConditionInput
  ) {
    createDispatch(input: $input, condition: $condition) {
      id
      work_order
      date_est
      local_time
      local_timezone
      dispatcher
      site
      location
      tech
      tech_number
      job_description
      on_site
      on_time
      comments
      tech_support
      time_in
      time_out
      fft_accepted
      billing_info
      fedex_tracking
      travel_fee
      category
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateDispatch = /* GraphQL */ `
  mutation UpdateDispatch(
    $input: UpdateDispatchInput!
    $condition: ModelDispatchConditionInput
  ) {
    updateDispatch(input: $input, condition: $condition) {
      id
      work_order
      date_est
      local_time
      local_timezone
      dispatcher
      site
      location
      tech
      tech_number
      job_description
      on_site
      on_time
      comments
      tech_support
      time_in
      time_out
      fft_accepted
      billing_info
      fedex_tracking
      travel_fee
      category
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteDispatch = /* GraphQL */ `
  mutation DeleteDispatch(
    $input: DeleteDispatchInput!
    $condition: ModelDispatchConditionInput
  ) {
    deleteDispatch(input: $input, condition: $condition) {
      id
      work_order
      date_est
      local_time
      local_timezone
      dispatcher
      site
      location
      tech
      tech_number
      job_description
      on_site
      on_time
      comments
      tech_support
      time_in
      time_out
      fft_accepted
      billing_info
      fedex_tracking
      travel_fee
      category
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createRestrictedDates = /* GraphQL */ `
  mutation CreateRestrictedDates(
    $input: CreateRestrictedDatesInput!
    $condition: ModelRestrictedDatesConditionInput
  ) {
    createRestrictedDates(input: $input, condition: $condition) {
      id
      date
      description
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateRestrictedDates = /* GraphQL */ `
  mutation UpdateRestrictedDates(
    $input: UpdateRestrictedDatesInput!
    $condition: ModelRestrictedDatesConditionInput
  ) {
    updateRestrictedDates(input: $input, condition: $condition) {
      id
      date
      description
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteRestrictedDates = /* GraphQL */ `
  mutation DeleteRestrictedDates(
    $input: DeleteRestrictedDatesInput!
    $condition: ModelRestrictedDatesConditionInput
  ) {
    deleteRestrictedDates(input: $input, condition: $condition) {
      id
      date
      description
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createQuotes = /* GraphQL */ `
  mutation CreateQuotes(
    $input: CreateQuotesInput!
    $condition: ModelQuotesConditionInput
  ) {
    createQuotes(input: $input, condition: $condition) {
      id
      partner
      date
      companyName
      siteAddress
      billingAddress
      city
      state
      zip
      contactName
      contactPhone
      contactEmail
      billingContact
      billingPhone
      billingEmail
      technicalContact
      technicalPhone
      technicalEmail
      authPerson1
      authPhone1
      authEmail1
      authPerson2
      authPhone2
      authEmail2
      authPerson3
      authPhone3
      authEmail3
      termInMonths
      notes
      deleted
      QuoteItems {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateQuotes = /* GraphQL */ `
  mutation UpdateQuotes(
    $input: UpdateQuotesInput!
    $condition: ModelQuotesConditionInput
  ) {
    updateQuotes(input: $input, condition: $condition) {
      id
      partner
      date
      companyName
      siteAddress
      billingAddress
      city
      state
      zip
      contactName
      contactPhone
      contactEmail
      billingContact
      billingPhone
      billingEmail
      technicalContact
      technicalPhone
      technicalEmail
      authPerson1
      authPhone1
      authEmail1
      authPerson2
      authPhone2
      authEmail2
      authPerson3
      authPhone3
      authEmail3
      termInMonths
      notes
      deleted
      QuoteItems {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteQuotes = /* GraphQL */ `
  mutation DeleteQuotes(
    $input: DeleteQuotesInput!
    $condition: ModelQuotesConditionInput
  ) {
    deleteQuotes(input: $input, condition: $condition) {
      id
      partner
      date
      companyName
      siteAddress
      billingAddress
      city
      state
      zip
      contactName
      contactPhone
      contactEmail
      billingContact
      billingPhone
      billingEmail
      technicalContact
      technicalPhone
      technicalEmail
      authPerson1
      authPhone1
      authEmail1
      authPerson2
      authPhone2
      authEmail2
      authPerson3
      authPhone3
      authEmail3
      termInMonths
      notes
      deleted
      QuoteItems {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createQuoteItems = /* GraphQL */ `
  mutation CreateQuoteItems(
    $input: CreateQuoteItemsInput!
    $condition: ModelQuoteItemsConditionInput
  ) {
    createQuoteItems(input: $input, condition: $condition) {
      id
      quote_id
      product_name
      unit_price
      quantity
      total
      frequency
      custom_item
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateQuoteItems = /* GraphQL */ `
  mutation UpdateQuoteItems(
    $input: UpdateQuoteItemsInput!
    $condition: ModelQuoteItemsConditionInput
  ) {
    updateQuoteItems(input: $input, condition: $condition) {
      id
      quote_id
      product_name
      unit_price
      quantity
      total
      frequency
      custom_item
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteQuoteItems = /* GraphQL */ `
  mutation DeleteQuoteItems(
    $input: DeleteQuoteItemsInput!
    $condition: ModelQuoteItemsConditionInput
  ) {
    deleteQuoteItems(input: $input, condition: $condition) {
      id
      quote_id
      product_name
      unit_price
      quantity
      total
      frequency
      custom_item
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createIconfigTemplates = /* GraphQL */ `
  mutation CreateIconfigTemplates(
    $input: CreateIconfigTemplatesInput!
    $condition: ModelIconfigTemplatesConditionInput
  ) {
    createIconfigTemplates(input: $input, condition: $condition) {
      id
      template_filename
      partner_id
      s3Key
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateIconfigTemplates = /* GraphQL */ `
  mutation UpdateIconfigTemplates(
    $input: UpdateIconfigTemplatesInput!
    $condition: ModelIconfigTemplatesConditionInput
  ) {
    updateIconfigTemplates(input: $input, condition: $condition) {
      id
      template_filename
      partner_id
      s3Key
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteIconfigTemplates = /* GraphQL */ `
  mutation DeleteIconfigTemplates(
    $input: DeleteIconfigTemplatesInput!
    $condition: ModelIconfigTemplatesConditionInput
  ) {
    deleteIconfigTemplates(input: $input, condition: $condition) {
      id
      template_filename
      partner_id
      s3Key
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createIconfigPartners = /* GraphQL */ `
  mutation CreateIconfigPartners(
    $input: CreateIconfigPartnersInput!
    $condition: ModelIconfigPartnersConditionInput
  ) {
    createIconfigPartners(input: $input, condition: $condition) {
      id
      name
      status
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateIconfigPartners = /* GraphQL */ `
  mutation UpdateIconfigPartners(
    $input: UpdateIconfigPartnersInput!
    $condition: ModelIconfigPartnersConditionInput
  ) {
    updateIconfigPartners(input: $input, condition: $condition) {
      id
      name
      status
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteIconfigPartners = /* GraphQL */ `
  mutation DeleteIconfigPartners(
    $input: DeleteIconfigPartnersInput!
    $condition: ModelIconfigPartnersConditionInput
  ) {
    deleteIconfigPartners(input: $input, condition: $condition) {
      id
      name
      status
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createDeregisterMessages = /* GraphQL */ `
  mutation CreateDeregisterMessages(
    $input: CreateDeregisterMessagesInput!
    $condition: ModelDeregisterMessagesConditionInput
  ) {
    createDeregisterMessages(input: $input, condition: $condition) {
      partner
      message
      account_id
      device_user_id
      device_id
      need_to_process
      id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateDeregisterMessages = /* GraphQL */ `
  mutation UpdateDeregisterMessages(
    $input: UpdateDeregisterMessagesInput!
    $condition: ModelDeregisterMessagesConditionInput
  ) {
    updateDeregisterMessages(input: $input, condition: $condition) {
      partner
      message
      account_id
      device_user_id
      device_id
      need_to_process
      id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteDeregisterMessages = /* GraphQL */ `
  mutation DeleteDeregisterMessages(
    $input: DeleteDeregisterMessagesInput!
    $condition: ModelDeregisterMessagesConditionInput
  ) {
    deleteDeregisterMessages(input: $input, condition: $condition) {
      partner
      message
      account_id
      device_user_id
      device_id
      need_to_process
      id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createCarrierRates = /* GraphQL */ `
  mutation CreateCarrierRates(
    $input: CreateCarrierRatesInput!
    $condition: ModelCarrierRatesConditionInput
  ) {
    createCarrierRates(input: $input, condition: $condition) {
      id
      carrier
      npanxx
      direction
      lata
      rate_center
      interstate_rate
      intrastate_rate
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateCarrierRates = /* GraphQL */ `
  mutation UpdateCarrierRates(
    $input: UpdateCarrierRatesInput!
    $condition: ModelCarrierRatesConditionInput
  ) {
    updateCarrierRates(input: $input, condition: $condition) {
      id
      carrier
      npanxx
      direction
      lata
      rate_center
      interstate_rate
      intrastate_rate
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteCarrierRates = /* GraphQL */ `
  mutation DeleteCarrierRates(
    $input: DeleteCarrierRatesInput!
    $condition: ModelCarrierRatesConditionInput
  ) {
    deleteCarrierRates(input: $input, condition: $condition) {
      id
      carrier
      npanxx
      direction
      lata
      rate_center
      interstate_rate
      intrastate_rate
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createRateCenters = /* GraphQL */ `
  mutation CreateRateCenters(
    $input: CreateRateCentersInput!
    $condition: ModelRateCentersConditionInput
  ) {
    createRateCenters(input: $input, condition: $condition) {
      id
      npanxx
      carrier_rates_npannx {
        nextToken
        startedAt
        __typename
      }
      npa
      lata
      carrier_rates_lata {
        nextToken
        startedAt
        __typename
      }
      rate_center
      carrier_rates_rate_center {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateRateCenters = /* GraphQL */ `
  mutation UpdateRateCenters(
    $input: UpdateRateCentersInput!
    $condition: ModelRateCentersConditionInput
  ) {
    updateRateCenters(input: $input, condition: $condition) {
      id
      npanxx
      carrier_rates_npannx {
        nextToken
        startedAt
        __typename
      }
      npa
      lata
      carrier_rates_lata {
        nextToken
        startedAt
        __typename
      }
      rate_center
      carrier_rates_rate_center {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteRateCenters = /* GraphQL */ `
  mutation DeleteRateCenters(
    $input: DeleteRateCentersInput!
    $condition: ModelRateCentersConditionInput
  ) {
    deleteRateCenters(input: $input, condition: $condition) {
      id
      npanxx
      carrier_rates_npannx {
        nextToken
        startedAt
        __typename
      }
      npa
      lata
      carrier_rates_lata {
        nextToken
        startedAt
        __typename
      }
      rate_center
      carrier_rates_rate_center {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createRobocallThresholds = /* GraphQL */ `
  mutation CreateRobocallThresholds(
    $input: CreateRobocallThresholdsInput!
    $condition: ModelRobocallThresholdsConditionInput
  ) {
    createRobocallThresholds(input: $input, condition: $condition) {
      id
      revio_id
      inbound_minutes_per_call
      outbound_minutes_per_call
      toll_free_inbound_minutes_per_call
      toll_free_outbound_minutes_per_call
      international_outbound_minutes_per_call
      short_inbound_calls_hourly
      short_outbound_calls_hourly
      zero_inbound_calls_hourly
      zero_outbound_calls_hourly
      invalid_inbound_calls_hourly
      invalid_outbound_calls_hourly
      toll_free_inbound_calls_hourly
      international_outbound_calls_hourly
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateRobocallThresholds = /* GraphQL */ `
  mutation UpdateRobocallThresholds(
    $input: UpdateRobocallThresholdsInput!
    $condition: ModelRobocallThresholdsConditionInput
  ) {
    updateRobocallThresholds(input: $input, condition: $condition) {
      id
      revio_id
      inbound_minutes_per_call
      outbound_minutes_per_call
      toll_free_inbound_minutes_per_call
      toll_free_outbound_minutes_per_call
      international_outbound_minutes_per_call
      short_inbound_calls_hourly
      short_outbound_calls_hourly
      zero_inbound_calls_hourly
      zero_outbound_calls_hourly
      invalid_inbound_calls_hourly
      invalid_outbound_calls_hourly
      toll_free_inbound_calls_hourly
      international_outbound_calls_hourly
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteRobocallThresholds = /* GraphQL */ `
  mutation DeleteRobocallThresholds(
    $input: DeleteRobocallThresholdsInput!
    $condition: ModelRobocallThresholdsConditionInput
  ) {
    deleteRobocallThresholds(input: $input, condition: $condition) {
      id
      revio_id
      inbound_minutes_per_call
      outbound_minutes_per_call
      toll_free_inbound_minutes_per_call
      toll_free_outbound_minutes_per_call
      international_outbound_minutes_per_call
      short_inbound_calls_hourly
      short_outbound_calls_hourly
      zero_inbound_calls_hourly
      zero_outbound_calls_hourly
      invalid_inbound_calls_hourly
      invalid_outbound_calls_hourly
      toll_free_inbound_calls_hourly
      international_outbound_calls_hourly
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createMultiLineOrders = /* GraphQL */ `
  mutation CreateMultiLineOrders(
    $input: CreateMultiLineOrdersInput!
    $condition: ModelMultiLineOrdersConditionInput
  ) {
    createMultiLineOrders(input: $input, condition: $condition) {
      id
      port_order_id
      status
      submission_date
      order_type
      install_date
      completed_date
      partner_account_number
      company_name
      site_address
      multi_line_network_information {
        nextToken
        startedAt
        __typename
      }
      multi_line_order_comments {
        nextToken
        startedAt
        __typename
      }
      order_tasks {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateMultiLineOrders = /* GraphQL */ `
  mutation UpdateMultiLineOrders(
    $input: UpdateMultiLineOrdersInput!
    $condition: ModelMultiLineOrdersConditionInput
  ) {
    updateMultiLineOrders(input: $input, condition: $condition) {
      id
      port_order_id
      status
      submission_date
      order_type
      install_date
      completed_date
      partner_account_number
      company_name
      site_address
      multi_line_network_information {
        nextToken
        startedAt
        __typename
      }
      multi_line_order_comments {
        nextToken
        startedAt
        __typename
      }
      order_tasks {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteMultiLineOrders = /* GraphQL */ `
  mutation DeleteMultiLineOrders(
    $input: DeleteMultiLineOrdersInput!
    $condition: ModelMultiLineOrdersConditionInput
  ) {
    deleteMultiLineOrders(input: $input, condition: $condition) {
      id
      port_order_id
      status
      submission_date
      order_type
      install_date
      completed_date
      partner_account_number
      company_name
      site_address
      multi_line_network_information {
        nextToken
        startedAt
        __typename
      }
      multi_line_order_comments {
        nextToken
        startedAt
        __typename
      }
      order_tasks {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createMultiLineOrderComments = /* GraphQL */ `
  mutation CreateMultiLineOrderComments(
    $input: CreateMultiLineOrderCommentsInput!
    $condition: ModelMultiLineOrderCommentsConditionInput
  ) {
    createMultiLineOrderComments(input: $input, condition: $condition) {
      id
      multiline_order_id
      content
      action_required
      action_response
      author
      is_private
      user_id
      order_state
      comment_type
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateMultiLineOrderComments = /* GraphQL */ `
  mutation UpdateMultiLineOrderComments(
    $input: UpdateMultiLineOrderCommentsInput!
    $condition: ModelMultiLineOrderCommentsConditionInput
  ) {
    updateMultiLineOrderComments(input: $input, condition: $condition) {
      id
      multiline_order_id
      content
      action_required
      action_response
      author
      is_private
      user_id
      order_state
      comment_type
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteMultiLineOrderComments = /* GraphQL */ `
  mutation DeleteMultiLineOrderComments(
    $input: DeleteMultiLineOrderCommentsInput!
    $condition: ModelMultiLineOrderCommentsConditionInput
  ) {
    deleteMultiLineOrderComments(input: $input, condition: $condition) {
      id
      multiline_order_id
      content
      action_required
      action_response
      author
      is_private
      user_id
      order_state
      comment_type
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createMultiLineOrderNetworkInformation = /* GraphQL */ `
  mutation CreateMultiLineOrderNetworkInformation(
    $input: CreateMultiLineOrderNetworkInformationInput!
    $condition: ModelMultiLineOrderNetworkInformationConditionInput
  ) {
    createMultiLineOrderNetworkInformation(
      input: $input
      condition: $condition
    ) {
      id
      multiline_order_id
      static_ip
      gateway_ip
      mac_address
      fax_security_info
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateMultiLineOrderNetworkInformation = /* GraphQL */ `
  mutation UpdateMultiLineOrderNetworkInformation(
    $input: UpdateMultiLineOrderNetworkInformationInput!
    $condition: ModelMultiLineOrderNetworkInformationConditionInput
  ) {
    updateMultiLineOrderNetworkInformation(
      input: $input
      condition: $condition
    ) {
      id
      multiline_order_id
      static_ip
      gateway_ip
      mac_address
      fax_security_info
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteMultiLineOrderNetworkInformation = /* GraphQL */ `
  mutation DeleteMultiLineOrderNetworkInformation(
    $input: DeleteMultiLineOrderNetworkInformationInput!
    $condition: ModelMultiLineOrderNetworkInformationConditionInput
  ) {
    deleteMultiLineOrderNetworkInformation(
      input: $input
      condition: $condition
    ) {
      id
      multiline_order_id
      static_ip
      gateway_ip
      mac_address
      fax_security_info
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createOrderTeamMembers = /* GraphQL */ `
  mutation CreateOrderTeamMembers(
    $input: CreateOrderTeamMembersInput!
    $condition: ModelOrderTeamMembersConditionInput
  ) {
    createOrderTeamMembers(input: $input, condition: $condition) {
      id
      name
      email
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateOrderTeamMembers = /* GraphQL */ `
  mutation UpdateOrderTeamMembers(
    $input: UpdateOrderTeamMembersInput!
    $condition: ModelOrderTeamMembersConditionInput
  ) {
    updateOrderTeamMembers(input: $input, condition: $condition) {
      id
      name
      email
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteOrderTeamMembers = /* GraphQL */ `
  mutation DeleteOrderTeamMembers(
    $input: DeleteOrderTeamMembersInput!
    $condition: ModelOrderTeamMembersConditionInput
  ) {
    deleteOrderTeamMembers(input: $input, condition: $condition) {
      id
      name
      email
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createOrderTasks = /* GraphQL */ `
  mutation CreateOrderTasks(
    $input: CreateOrderTasksInput!
    $condition: ModelOrderTasksConditionInput
  ) {
    createOrderTasks(input: $input, condition: $condition) {
      id
      multiline_order_id
      status
      date_completed
      task_name
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateOrderTasks = /* GraphQL */ `
  mutation UpdateOrderTasks(
    $input: UpdateOrderTasksInput!
    $condition: ModelOrderTasksConditionInput
  ) {
    updateOrderTasks(input: $input, condition: $condition) {
      id
      multiline_order_id
      status
      date_completed
      task_name
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteOrderTasks = /* GraphQL */ `
  mutation DeleteOrderTasks(
    $input: DeleteOrderTasksInput!
    $condition: ModelOrderTasksConditionInput
  ) {
    deleteOrderTasks(input: $input, condition: $condition) {
      id
      multiline_order_id
      status
      date_completed
      task_name
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createDefaultOrderTasks = /* GraphQL */ `
  mutation CreateDefaultOrderTasks(
    $input: CreateDefaultOrderTasksInput!
    $condition: ModelDefaultOrderTasksConditionInput
  ) {
    createDefaultOrderTasks(input: $input, condition: $condition) {
      id
      task_name
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateDefaultOrderTasks = /* GraphQL */ `
  mutation UpdateDefaultOrderTasks(
    $input: UpdateDefaultOrderTasksInput!
    $condition: ModelDefaultOrderTasksConditionInput
  ) {
    updateDefaultOrderTasks(input: $input, condition: $condition) {
      id
      task_name
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteDefaultOrderTasks = /* GraphQL */ `
  mutation DeleteDefaultOrderTasks(
    $input: DeleteDefaultOrderTasksInput!
    $condition: ModelDefaultOrderTasksConditionInput
  ) {
    deleteDefaultOrderTasks(input: $input, condition: $condition) {
      id
      task_name
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createBConfigDevices = /* GraphQL */ `
  mutation CreateBConfigDevices(
    $input: CreateBConfigDevicesInput!
    $condition: ModelBConfigDevicesConditionInput
  ) {
    createBConfigDevices(input: $input, condition: $condition) {
      config_name
      name
      address
      partner
      vendor
      location
      date
      S3Key
      id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateBConfigDevices = /* GraphQL */ `
  mutation UpdateBConfigDevices(
    $input: UpdateBConfigDevicesInput!
    $condition: ModelBConfigDevicesConditionInput
  ) {
    updateBConfigDevices(input: $input, condition: $condition) {
      config_name
      name
      address
      partner
      vendor
      location
      date
      S3Key
      id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteBConfigDevices = /* GraphQL */ `
  mutation DeleteBConfigDevices(
    $input: DeleteBConfigDevicesInput!
    $condition: ModelBConfigDevicesConditionInput
  ) {
    deleteBConfigDevices(input: $input, condition: $condition) {
      config_name
      name
      address
      partner
      vendor
      location
      date
      S3Key
      id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createTrackableSystems = /* GraphQL */ `
  mutation CreateTrackableSystems(
    $input: CreateTrackableSystemsInput!
    $condition: ModelTrackableSystemsConditionInput
  ) {
    createTrackableSystems(input: $input, condition: $condition) {
      id
      trackedItem
      currentStatus
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateTrackableSystems = /* GraphQL */ `
  mutation UpdateTrackableSystems(
    $input: UpdateTrackableSystemsInput!
    $condition: ModelTrackableSystemsConditionInput
  ) {
    updateTrackableSystems(input: $input, condition: $condition) {
      id
      trackedItem
      currentStatus
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteTrackableSystems = /* GraphQL */ `
  mutation DeleteTrackableSystems(
    $input: DeleteTrackableSystemsInput!
    $condition: ModelTrackableSystemsConditionInput
  ) {
    deleteTrackableSystems(input: $input, condition: $condition) {
      id
      trackedItem
      currentStatus
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createUsers = /* GraphQL */ `
  mutation CreateUsers(
    $input: CreateUsersInput!
    $condition: ModelUsersConditionInput
  ) {
    createUsers(input: $input, condition: $condition) {
      id
      partner
      username
      first_name
      last_name
      bookmarked_tickets
      favorite_apps
      notifications {
        nextToken
        startedAt
        __typename
      }
      has_subscribed_reports
      subscribed_reports
      favorite_reports
      webrtc_config_s3_key
      workspace_layout
      subscribed_systems
      maintenanceMessages
      emailNotifications
      disabled
      agent_account_id
      agent_recipient_id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateUsers = /* GraphQL */ `
  mutation UpdateUsers(
    $input: UpdateUsersInput!
    $condition: ModelUsersConditionInput
  ) {
    updateUsers(input: $input, condition: $condition) {
      id
      partner
      username
      first_name
      last_name
      bookmarked_tickets
      favorite_apps
      notifications {
        nextToken
        startedAt
        __typename
      }
      has_subscribed_reports
      subscribed_reports
      favorite_reports
      webrtc_config_s3_key
      workspace_layout
      subscribed_systems
      maintenanceMessages
      emailNotifications
      disabled
      agent_account_id
      agent_recipient_id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteUsers = /* GraphQL */ `
  mutation DeleteUsers(
    $input: DeleteUsersInput!
    $condition: ModelUsersConditionInput
  ) {
    deleteUsers(input: $input, condition: $condition) {
      id
      partner
      username
      first_name
      last_name
      bookmarked_tickets
      favorite_apps
      notifications {
        nextToken
        startedAt
        __typename
      }
      has_subscribed_reports
      subscribed_reports
      favorite_reports
      webrtc_config_s3_key
      workspace_layout
      subscribed_systems
      maintenanceMessages
      emailNotifications
      disabled
      agent_account_id
      agent_recipient_id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createSiteNotifications = /* GraphQL */ `
  mutation CreateSiteNotifications(
    $input: CreateSiteNotificationsInput!
    $condition: ModelSiteNotificationsConditionInput
  ) {
    createSiteNotifications(input: $input, condition: $condition) {
      id
      user_id
      title
      type
      tracked_system
      status
      marked_as_read
      content
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateSiteNotifications = /* GraphQL */ `
  mutation UpdateSiteNotifications(
    $input: UpdateSiteNotificationsInput!
    $condition: ModelSiteNotificationsConditionInput
  ) {
    updateSiteNotifications(input: $input, condition: $condition) {
      id
      user_id
      title
      type
      tracked_system
      status
      marked_as_read
      content
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteSiteNotifications = /* GraphQL */ `
  mutation DeleteSiteNotifications(
    $input: DeleteSiteNotificationsInput!
    $condition: ModelSiteNotificationsConditionInput
  ) {
    deleteSiteNotifications(input: $input, condition: $condition) {
      id
      user_id
      title
      type
      tracked_system
      status
      marked_as_read
      content
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createEmployees = /* GraphQL */ `
  mutation CreateEmployees(
    $input: CreateEmployeesInput!
    $condition: ModelEmployeesConditionInput
  ) {
    createEmployees(input: $input, condition: $condition) {
      id
      name
      email
      position
      employee_ticket_number
      supervisor
      start_time
      terminated
      date_terminated
      duration
      work_days
      discipline_level
      assigned_group
      employee_actions {
        nextToken
        startedAt
        __typename
      }
      voice_infractions {
        nextToken
        startedAt
        __typename
      }
      contact_center_infractions {
        nextToken
        startedAt
        __typename
      }
      audits {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateEmployees = /* GraphQL */ `
  mutation UpdateEmployees(
    $input: UpdateEmployeesInput!
    $condition: ModelEmployeesConditionInput
  ) {
    updateEmployees(input: $input, condition: $condition) {
      id
      name
      email
      position
      employee_ticket_number
      supervisor
      start_time
      terminated
      date_terminated
      duration
      work_days
      discipline_level
      assigned_group
      employee_actions {
        nextToken
        startedAt
        __typename
      }
      voice_infractions {
        nextToken
        startedAt
        __typename
      }
      contact_center_infractions {
        nextToken
        startedAt
        __typename
      }
      audits {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteEmployees = /* GraphQL */ `
  mutation DeleteEmployees(
    $input: DeleteEmployeesInput!
    $condition: ModelEmployeesConditionInput
  ) {
    deleteEmployees(input: $input, condition: $condition) {
      id
      name
      email
      position
      employee_ticket_number
      supervisor
      start_time
      terminated
      date_terminated
      duration
      work_days
      discipline_level
      assigned_group
      employee_actions {
        nextToken
        startedAt
        __typename
      }
      voice_infractions {
        nextToken
        startedAt
        __typename
      }
      contact_center_infractions {
        nextToken
        startedAt
        __typename
      }
      audits {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createVoiceInfractions = /* GraphQL */ `
  mutation CreateVoiceInfractions(
    $input: CreateVoiceInfractionsInput!
    $condition: ModelVoiceInfractionsConditionInput
  ) {
    createVoiceInfractions(input: $input, condition: $condition) {
      id
      employee_id
      date
      employee
      infraction
      documentation
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateVoiceInfractions = /* GraphQL */ `
  mutation UpdateVoiceInfractions(
    $input: UpdateVoiceInfractionsInput!
    $condition: ModelVoiceInfractionsConditionInput
  ) {
    updateVoiceInfractions(input: $input, condition: $condition) {
      id
      employee_id
      date
      employee
      infraction
      documentation
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteVoiceInfractions = /* GraphQL */ `
  mutation DeleteVoiceInfractions(
    $input: DeleteVoiceInfractionsInput!
    $condition: ModelVoiceInfractionsConditionInput
  ) {
    deleteVoiceInfractions(input: $input, condition: $condition) {
      id
      employee_id
      date
      employee
      infraction
      documentation
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createContactCenterInfractions = /* GraphQL */ `
  mutation CreateContactCenterInfractions(
    $input: CreateContactCenterInfractionsInput!
    $condition: ModelContactCenterInfractionsConditionInput
  ) {
    createContactCenterInfractions(input: $input, condition: $condition) {
      id
      employee_id
      date
      employee_name
      infraction
      scheduled_shift
      clock_in_time
      clock_out_time
      details
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateContactCenterInfractions = /* GraphQL */ `
  mutation UpdateContactCenterInfractions(
    $input: UpdateContactCenterInfractionsInput!
    $condition: ModelContactCenterInfractionsConditionInput
  ) {
    updateContactCenterInfractions(input: $input, condition: $condition) {
      id
      employee_id
      date
      employee_name
      infraction
      scheduled_shift
      clock_in_time
      clock_out_time
      details
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteContactCenterInfractions = /* GraphQL */ `
  mutation DeleteContactCenterInfractions(
    $input: DeleteContactCenterInfractionsInput!
    $condition: ModelContactCenterInfractionsConditionInput
  ) {
    deleteContactCenterInfractions(input: $input, condition: $condition) {
      id
      employee_id
      date
      employee_name
      infraction
      scheduled_shift
      clock_in_time
      clock_out_time
      details
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createEmployeeActions = /* GraphQL */ `
  mutation CreateEmployeeActions(
    $input: CreateEmployeeActionsInput!
    $condition: ModelEmployeeActionsConditionInput
  ) {
    createEmployeeActions(input: $input, condition: $condition) {
      id
      employee_id
      employee {
        id
        name
        email
        position
        employee_ticket_number
        supervisor
        start_time
        terminated
        date_terminated
        duration
        work_days
        discipline_level
        assigned_group
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      date
      action
      documentation
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateEmployeeActions = /* GraphQL */ `
  mutation UpdateEmployeeActions(
    $input: UpdateEmployeeActionsInput!
    $condition: ModelEmployeeActionsConditionInput
  ) {
    updateEmployeeActions(input: $input, condition: $condition) {
      id
      employee_id
      employee {
        id
        name
        email
        position
        employee_ticket_number
        supervisor
        start_time
        terminated
        date_terminated
        duration
        work_days
        discipline_level
        assigned_group
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      date
      action
      documentation
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteEmployeeActions = /* GraphQL */ `
  mutation DeleteEmployeeActions(
    $input: DeleteEmployeeActionsInput!
    $condition: ModelEmployeeActionsConditionInput
  ) {
    deleteEmployeeActions(input: $input, condition: $condition) {
      id
      employee_id
      employee {
        id
        name
        email
        position
        employee_ticket_number
        supervisor
        start_time
        terminated
        date_terminated
        duration
        work_days
        discipline_level
        assigned_group
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      date
      action
      documentation
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createScheduledPartners = /* GraphQL */ `
  mutation CreateScheduledPartners(
    $input: CreateScheduledPartnersInput!
    $condition: ModelScheduledPartnersConditionInput
  ) {
    createScheduledPartners(input: $input, condition: $condition) {
      id
      name
      queue_name
      category
      assigned_group
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateScheduledPartners = /* GraphQL */ `
  mutation UpdateScheduledPartners(
    $input: UpdateScheduledPartnersInput!
    $condition: ModelScheduledPartnersConditionInput
  ) {
    updateScheduledPartners(input: $input, condition: $condition) {
      id
      name
      queue_name
      category
      assigned_group
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteScheduledPartners = /* GraphQL */ `
  mutation DeleteScheduledPartners(
    $input: DeleteScheduledPartnersInput!
    $condition: ModelScheduledPartnersConditionInput
  ) {
    deleteScheduledPartners(input: $input, condition: $condition) {
      id
      name
      queue_name
      category
      assigned_group
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createContactCenterQualityAudits = /* GraphQL */ `
  mutation CreateContactCenterQualityAudits(
    $input: CreateContactCenterQualityAuditsInput!
    $condition: ModelContactCenterQualityAuditsConditionInput
  ) {
    createContactCenterQualityAudits(input: $input, condition: $condition) {
      id
      employee_id
      employee {
        id
        name
        email
        position
        employee_ticket_number
        supervisor
        start_time
        terminated
        date_terminated
        duration
        work_days
        discipline_level
        assigned_group
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      ticket_number
      date_of_call
      date_of_audit
      name_of_auditor
      call_recording
      call_type
      point_total
      status
      audit_object
      mutated_object
      compliance
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateContactCenterQualityAudits = /* GraphQL */ `
  mutation UpdateContactCenterQualityAudits(
    $input: UpdateContactCenterQualityAuditsInput!
    $condition: ModelContactCenterQualityAuditsConditionInput
  ) {
    updateContactCenterQualityAudits(input: $input, condition: $condition) {
      id
      employee_id
      employee {
        id
        name
        email
        position
        employee_ticket_number
        supervisor
        start_time
        terminated
        date_terminated
        duration
        work_days
        discipline_level
        assigned_group
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      ticket_number
      date_of_call
      date_of_audit
      name_of_auditor
      call_recording
      call_type
      point_total
      status
      audit_object
      mutated_object
      compliance
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteContactCenterQualityAudits = /* GraphQL */ `
  mutation DeleteContactCenterQualityAudits(
    $input: DeleteContactCenterQualityAuditsInput!
    $condition: ModelContactCenterQualityAuditsConditionInput
  ) {
    deleteContactCenterQualityAudits(input: $input, condition: $condition) {
      id
      employee_id
      employee {
        id
        name
        email
        position
        employee_ticket_number
        supervisor
        start_time
        terminated
        date_terminated
        duration
        work_days
        discipline_level
        assigned_group
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      ticket_number
      date_of_call
      date_of_audit
      name_of_auditor
      call_recording
      call_type
      point_total
      status
      audit_object
      mutated_object
      compliance
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createContactCenterQualityAuditQuestions = /* GraphQL */ `
  mutation CreateContactCenterQualityAuditQuestions(
    $input: CreateContactCenterQualityAuditQuestionsInput!
    $condition: ModelContactCenterQualityAuditQuestionsConditionInput
  ) {
    createContactCenterQualityAuditQuestions(
      input: $input
      condition: $condition
    ) {
      id
      question
      point_value
      category
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateContactCenterQualityAuditQuestions = /* GraphQL */ `
  mutation UpdateContactCenterQualityAuditQuestions(
    $input: UpdateContactCenterQualityAuditQuestionsInput!
    $condition: ModelContactCenterQualityAuditQuestionsConditionInput
  ) {
    updateContactCenterQualityAuditQuestions(
      input: $input
      condition: $condition
    ) {
      id
      question
      point_value
      category
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteContactCenterQualityAuditQuestions = /* GraphQL */ `
  mutation DeleteContactCenterQualityAuditQuestions(
    $input: DeleteContactCenterQualityAuditQuestionsInput!
    $condition: ModelContactCenterQualityAuditQuestionsConditionInput
  ) {
    deleteContactCenterQualityAuditQuestions(
      input: $input
      condition: $condition
    ) {
      id
      question
      point_value
      category
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createMultiLineProvisionRequests = /* GraphQL */ `
  mutation CreateMultiLineProvisionRequests(
    $input: CreateMultiLineProvisionRequestsInput!
    $condition: ModelMultiLineProvisionRequestsConditionInput
  ) {
    createMultiLineProvisionRequests(input: $input, condition: $condition) {
      id
      partner
      account_number
      first_name
      last_name
      address
      city
      state
      zip
      contact_phone
      contact_email
      customer_type
      caller_id_name
      caller_id_number
      bypass_e911
      phone_numbers
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateMultiLineProvisionRequests = /* GraphQL */ `
  mutation UpdateMultiLineProvisionRequests(
    $input: UpdateMultiLineProvisionRequestsInput!
    $condition: ModelMultiLineProvisionRequestsConditionInput
  ) {
    updateMultiLineProvisionRequests(input: $input, condition: $condition) {
      id
      partner
      account_number
      first_name
      last_name
      address
      city
      state
      zip
      contact_phone
      contact_email
      customer_type
      caller_id_name
      caller_id_number
      bypass_e911
      phone_numbers
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteMultiLineProvisionRequests = /* GraphQL */ `
  mutation DeleteMultiLineProvisionRequests(
    $input: DeleteMultiLineProvisionRequestsInput!
    $condition: ModelMultiLineProvisionRequestsConditionInput
  ) {
    deleteMultiLineProvisionRequests(input: $input, condition: $condition) {
      id
      partner
      account_number
      first_name
      last_name
      address
      city
      state
      zip
      contact_phone
      contact_email
      customer_type
      caller_id_name
      caller_id_number
      bypass_e911
      phone_numbers
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createFlowQuestions = /* GraphQL */ `
  mutation CreateFlowQuestions(
    $input: CreateFlowQuestionsInput!
    $condition: ModelFlowQuestionsConditionInput
  ) {
    createFlowQuestions(input: $input, condition: $condition) {
      id
      type
      question
      options
      url
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateFlowQuestions = /* GraphQL */ `
  mutation UpdateFlowQuestions(
    $input: UpdateFlowQuestionsInput!
    $condition: ModelFlowQuestionsConditionInput
  ) {
    updateFlowQuestions(input: $input, condition: $condition) {
      id
      type
      question
      options
      url
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteFlowQuestions = /* GraphQL */ `
  mutation DeleteFlowQuestions(
    $input: DeleteFlowQuestionsInput!
    $condition: ModelFlowQuestionsConditionInput
  ) {
    deleteFlowQuestions(input: $input, condition: $condition) {
      id
      type
      question
      options
      url
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createFlows = /* GraphQL */ `
  mutation CreateFlows(
    $input: CreateFlowsInput!
    $condition: ModelFlowsConditionInput
  ) {
    createFlows(input: $input, condition: $condition) {
      id
      name
      allowed_queues_staging
      allowed_queues
      s3_staging_bucket_key
      s3_production_bucket_key
      s3_bucket_name
      changed_flag
      removal_flag
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateFlows = /* GraphQL */ `
  mutation UpdateFlows(
    $input: UpdateFlowsInput!
    $condition: ModelFlowsConditionInput
  ) {
    updateFlows(input: $input, condition: $condition) {
      id
      name
      allowed_queues_staging
      allowed_queues
      s3_staging_bucket_key
      s3_production_bucket_key
      s3_bucket_name
      changed_flag
      removal_flag
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteFlows = /* GraphQL */ `
  mutation DeleteFlows(
    $input: DeleteFlowsInput!
    $condition: ModelFlowsConditionInput
  ) {
    deleteFlows(input: $input, condition: $condition) {
      id
      name
      allowed_queues_staging
      allowed_queues
      s3_staging_bucket_key
      s3_production_bucket_key
      s3_bucket_name
      changed_flag
      removal_flag
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createFakeDisconnectRequests = /* GraphQL */ `
  mutation CreateFakeDisconnectRequests(
    $input: CreateFakeDisconnectRequestsInput!
    $condition: ModelFakeDisconnectRequestsConditionInput
  ) {
    createFakeDisconnectRequests(input: $input, condition: $condition) {
      id
      service
      status
      disconnect_date
      requester
      comment
      full_disconnect
      account_number
      partner
      phone_numbers
      ticket_number
      port_out
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateFakeDisconnectRequests = /* GraphQL */ `
  mutation UpdateFakeDisconnectRequests(
    $input: UpdateFakeDisconnectRequestsInput!
    $condition: ModelFakeDisconnectRequestsConditionInput
  ) {
    updateFakeDisconnectRequests(input: $input, condition: $condition) {
      id
      service
      status
      disconnect_date
      requester
      comment
      full_disconnect
      account_number
      partner
      phone_numbers
      ticket_number
      port_out
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteFakeDisconnectRequests = /* GraphQL */ `
  mutation DeleteFakeDisconnectRequests(
    $input: DeleteFakeDisconnectRequestsInput!
    $condition: ModelFakeDisconnectRequestsConditionInput
  ) {
    deleteFakeDisconnectRequests(input: $input, condition: $condition) {
      id
      service
      status
      disconnect_date
      requester
      comment
      full_disconnect
      account_number
      partner
      phone_numbers
      ticket_number
      port_out
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createAgentsRecoveryTimes = /* GraphQL */ `
  mutation CreateAgentsRecoveryTimes(
    $input: CreateAgentsRecoveryTimesInput!
    $condition: ModelAgentsRecoveryTimesConditionInput
  ) {
    createAgentsRecoveryTimes(input: $input, condition: $condition) {
      id
      recipient_id
      recovery_time
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateAgentsRecoveryTimes = /* GraphQL */ `
  mutation UpdateAgentsRecoveryTimes(
    $input: UpdateAgentsRecoveryTimesInput!
    $condition: ModelAgentsRecoveryTimesConditionInput
  ) {
    updateAgentsRecoveryTimes(input: $input, condition: $condition) {
      id
      recipient_id
      recovery_time
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteAgentsRecoveryTimes = /* GraphQL */ `
  mutation DeleteAgentsRecoveryTimes(
    $input: DeleteAgentsRecoveryTimesInput!
    $condition: ModelAgentsRecoveryTimesConditionInput
  ) {
    deleteAgentsRecoveryTimes(input: $input, condition: $condition) {
      id
      recipient_id
      recovery_time
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createCarrierDialPrefixRates = /* GraphQL */ `
  mutation CreateCarrierDialPrefixRates(
    $input: CreateCarrierDialPrefixRatesInput!
    $condition: ModelCarrierDialPrefixRatesConditionInput
  ) {
    createCarrierDialPrefixRates(input: $input, condition: $condition) {
      id
      country
      dial_prefix
      retail_rate
      wholesale_rate
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateCarrierDialPrefixRates = /* GraphQL */ `
  mutation UpdateCarrierDialPrefixRates(
    $input: UpdateCarrierDialPrefixRatesInput!
    $condition: ModelCarrierDialPrefixRatesConditionInput
  ) {
    updateCarrierDialPrefixRates(input: $input, condition: $condition) {
      id
      country
      dial_prefix
      retail_rate
      wholesale_rate
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteCarrierDialPrefixRates = /* GraphQL */ `
  mutation DeleteCarrierDialPrefixRates(
    $input: DeleteCarrierDialPrefixRatesInput!
    $condition: ModelCarrierDialPrefixRatesConditionInput
  ) {
    deleteCarrierDialPrefixRates(input: $input, condition: $condition) {
      id
      country
      dial_prefix
      retail_rate
      wholesale_rate
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
