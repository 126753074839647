<template>
  <div class="menubar">
    <TiptapItem v-bind="item" v-for="(item, index) in items" :key="index" />
  </div>
</template>

<script>
import TiptapItem from "./ToolbarItem.vue";

export default {
  components: {
    TiptapItem,
  },
  name: "tiptapMenubar",
  props: {
    editor: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      items: [
        {
          icon: "bold",
          title: "Bold",
          action: () => this.editor.chain().focus().toggleBold().run(),
          isActive: () => this.editor.isActive("bold"),
        },
        {
          icon: "italic",
          title: "Italic",
          action: () => this.editor.chain().focus().toggleItalic().run(),
          isActive: () => this.editor.isActive("italic"),
        },

        {
          icon: "underline",
          title: "Underline",
          action: () => this.editor.chain().focus().toggleUnderline().run(),
          isActive: () => this.editor.isActive("underline"),
        },
        {
          icon: "font-color",
          title: "Font Color",
          isColorPicker: true,
          editor: this.editor,
          actionType: "color"
        },
         {
          icon: "font-color",
          title: "highlight",
          isColorPicker: true,
          editor: this.editor,
          actionType: "highlight"
        },
        {
          icon: "list-unordered",
          title: "Bullets",
          isColorPicker: false,
          action: () => this.editor.chain().focus().toggleBulletList().run(),
          isActive: () => this.editor.isActive('bulletList'),
        },
        {
          icon: "h-1",
          title: "H1",
          isColorPicker: false,
          action: () => this.editor.chain().focus().toggleHeading({ level: 1 }).run(),
          isActive: () => this.editor.isActive("heading", { level: 1 }),
        },
        {
          icon: "h-2",
          title: "H2",
          isColorPicker: false,
          action: () => this.editor.chain().focus().toggleHeading({ level: 2 }).run(),
          isActive: () => this.editor.isActive("heading", { level: 2 }),
        },
        {
          icon: "h-3",
          title: "H3",
          isColorPicker: false,
          action: () => this.editor.chain().focus().toggleHeading({ level: 3 }).run(),
          isActive: () => this.editor.isActive("heading", { level: 3 }),
        },
        {
          icon: "h-4",
          title: "H4",
          isColorPicker: false,
          action: () => this.editor.chain().focus().toggleHeading({ level: 4 }).run(),
          isActive: () => this.editor.isActive("heading", { level: 4 }),
        },
        {
          icon: "h-5",
          title: "H5",
          isColorPicker: false,
          action: () => this.editor.chain().focus().toggleHeading({ level: 5 }).run(),
          isActive: () => this.editor.isActive("heading", { level: 5 }),
        },
        {
          icon: "h-6",
          title: "H6",
          isColorPicker: false,
          action: () => this.editor.chain().focus().toggleHeading({ level: 6 }).run(),
          isActive: () => this.editor.isActive("heading", { level: 6 }),
        },
        {
          icon: "text",
          title: "Paragraph",
          isColorPicker: false,
          action: () => this.editor.chain().focus().setParagraph().run(),
          isActive: () => this.editor.isActive("paragraph"),
        }
      ],
    };
  },
};
</script>

<style scoped>

</style>
