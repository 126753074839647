/**
 * Recursively clones an object or array, creating a deep copy.
 *
 * @param {*} value - The value to be cloned.
 * @returns {*} - The deep cloned value.
 */

export function cloneDeep(value) {
  if (value === null || typeof value !== 'object') {
    return value;
  }

  if (Array.isArray(value)) {
    const clonedArray = [];
    for (let i = 0; i < value.length; i++) {
      clonedArray[i] = cloneDeep(value[i]);
    }
    return clonedArray;
  }

  const clonedObject = {};
  for (const key in value) {
    if (Object.prototype.hasOwnProperty.call(value, key)) {
      clonedObject[key] = cloneDeep(value[key]);
    }
  }
  return clonedObject;
}