<template>
  <div class="leftNavContainer">
    <template v-for="(item, index) in items" :key="index">
      <SelectPages
        :category="item.category"
        :description="item.description"
        class="selectPages"
        @select-page="handleSelectedPage"
        :isSelected="category === item.category && !nestedCategory"
      />
      <DividerComp style="margin-left: 10px" />
      <template v-if="item.options && item.category === category">
        <div
          v-for="(option, optionIndex) in item.options"
          :key="optionIndex"
          class="nestedOption"
        >
          <SelectPages
            :category="option.category"
            :description="option.description"
            class="selectPagesNested"
            @select-page="handleNestedPage"
            :isSelected="nestedCategory === option.category"
          />
          <DividerComp style="margin-left: 10px" />
        </div>
      </template>
    </template>
  </div>
</template>

<script>
import SelectPages from "../SelectPages.vue";
import DividerComp from "../DividerComp.vue";
export default {
  name: "LeftNavigation",
  props: {
    items: Array,
    startCategory: String,
    nestedStartCategory: String,
  },
  data() {
    return {
      category: "",
      nestedCategory: "",
    };
  },
  created() {
    this.category = this.startCategory;
    this.nestedCategory = this.nestedStartCategory;
  },
  methods: {
    handleSelectedPage(category) {
      this.category = category;
      this.nestedCategory = "";
      this.$emit("selected-page", category);
    },
    handleNestedPage(category) {
      this.nestedCategory = category;
      this.$emit("selected-nested-page", category);
    },
  },
  watch: {
    nestedStartCategory(newValue) {
      this.nestedCategory = newValue;
    },
    startCategory(newValue) {
      this.category = newValue;
    }
  },
  components: {
    SelectPages,
    DividerComp,
  },
  emits: [
    "selected-page",
    "selected-nested-page"
  ]
};
</script>
<style scoped>
.leftNavContainer {
  background-color: #ffffff;
  padding: 10px 15px 10px 10px;
}
.contentContainer {
  display: flex;
}
.leftNavContent {
  display: flex;
}
.rightAlignedButtons {
  display: flex;
  gap: 10px;
  padding-right: 10px;
}

.selectPages {
  margin-left: 10px;
  margin-top: 2px;
  margin-bottom: 2px;
  width: 215px;
}

.selectPagesNested {
  margin-left: 10px;
  margin-top: 2px;
  margin-bottom: 2px;
  width: 197px;
}

.nestedOption {
  margin-left: 18px;
}
</style>
