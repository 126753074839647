<template>
  <div v-if="currentCall?.status === 'active'">
    <div class="activeCallHeader">
      <img
        :src="require('@/assets/icons/call_white.png')"
        class="activeCallIcon"
        @click="handleActiveClick"
      />
      <h1 class="activeCallText" @click="handleActiveClick">
        Active Call
        <span :style="{ paddingLeft: '10px' }">
          ({{ currentCall["Total Call Duration"] }})
        </span>
      </h1>
      <img
        :src="muted ? require('@/assets/icons/unmute_combined.png') : require('@/assets/icons/mute_combined.png') "
        class="mute-icon"
        @click="handleMute"/>
    </div>
    <h2 class="settingsActiveText">Settings</h2>
  </div>
  <div v-else class="enterNumberBoxContainer">
    <img
      :src="require('@/assets/icons/settings_dkblue.png')"
      class="settingsIcon"
    />
    <h1 class="settingsHeader">Settings</h1>
  </div>

  <div class="settingsContainer">
    <div class="row" :style="{ paddingBottom: '10px' }">
      <div class="column">
        <label class="settingsLabel">Microphone</label>
        <Dropdown
          :options="microphoneList"
          :id="microphoneList"
          ref="filterDropdown"
          @input="updateDropDownVal($event)"
        />
      </div>
    </div>
    <div class="row">
      <div class="column">
        <label class="settingsLabel">Speaker</label>
        <Dropdown
          :options="speakerList"
          :id="speakerList"
          ref="filterDropdown"
          @input="updateDropDownVal($event)"
        />
      </div>
    </div>
  </div>
  <div
    class="activeCallButtonContainer"
    v-if="currentCall?.status === 'active'"
  >
    <DividerComp class="activeCallDivider" />
    <div class="hangupButtonContainer">
      <LightButton
        class="hangupButton"
        icon="hangup.png"
        :style="{
          height: '35px',
          backgroundColor: '#e78535',
          color: 'white',
        }"
        @click="handleHangup"
      />
    </div>
  </div>
</template>

<script>
import Dropdown from "./DropDown.vue";
import DividerComp from "@/components/base_components/DividerComp.vue";
import LightButton from "../LightButtonWebRTC.vue";
import { useWebRTCStore } from "@/stores/webRTC/webRTCStore.js";
import { useAuthStore } from "@/stores/auth/authStore.js";
import { mapActions, mapStores } from "pinia";

export default {
  name: "SettingsComp",
  props: { currentCall: Object },
  emits: ["navigate"],
  components: { Dropdown, LightButton, DividerComp },
  data() {
    return {
      number: "",
      microphoneList: [],
      speakerList: [],
    };
  },
  computed: {
    ...mapStores(useWebRTCStore),
    ...mapStores(useAuthStore),
    muted() {
      return this.WebRTCStore.getMuteState;
    }
  },
  methods: {
    ...mapActions(useWebRTCStore, ["changeSelectedLayout", "toggleMuted"]),
    handleActiveClick() {
      this.$emit("navigate", "active");
    },
    handleMute(){
      this.WebRTCStore.toggleMuted();
    },
    addInputMediaDevice(device) {
      this.microphoneList.push(device);
    },
    addOutputMediaDevice(device) {
      this.speakerList.push(device);
    },
    updateDropDownVal(event){
      let media_devices = this.webphone.getMediaDevices();
      media_devices.changeDevice(event.deviceKind, event.deviceID);
    }
  },
  created() {
    this.webphone = this.WebRTCStore.getLibWebPhone;
    if(!this.webphone){
      this.WebRTCStore.connectToWebRTC_phone(this.AuthStore.getCurrentUser.username);
      this.webphone = this.WebRTCStore.getLibWebPhone;
    }

    // media devices
    let media_devices = this.webphone.getMediaDevices();
    let audio_input_devices = media_devices._availableDevices.audioinput;
    let audio_output_devices = media_devices._availableDevices.audiooutput;
    
    for(var input_device of audio_input_devices){
      let device_info = {};
      device_info.label = input_device.label;
      device_info.value = input_device.id;
      device_info.deviceKind = "audioinput"; 
      device_info.deviceID = input_device.id;
      this.addInputMediaDevice(device_info);
    }

    for(var output_device of audio_output_devices){
      let device_info = {};
      device_info.label = output_device.label;
      device_info.value = output_device.id;
      device_info.deviceKind = "audiooutput"; 
      device_info.deviceID = output_device.id;
      this.addOutputMediaDevice(device_info);
    }
  }
};
</script>

<style scoped>
.settingsContainer {
  padding: 10px 20px 0px 20px;
  background-color: #f8f9fc;
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: auto;
  height: 100%;
}
.enterNumberBoxContainer {
  margin-top: 25px;
  margin-bottom: 25px;
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
}
.settingsIcon {
  width: 30px;
  margin-right: 20px;
  margin-left: 15px;
  text-align: center;
}
.settingsHeader {
  color: #271d7a;
  font-size: 20px;
  font-weight: 500;
}
.row {
  display: flex;
  flex-direction: row;
}
.column {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.settingsLabel {
  color: #271d7a;
  font-weight: 500;
  font-size: 12pt;
  padding: 10px 0px 7px 0px;
}
.settingsActiveText {
  background-color: #f8f9fc;
  font-size: 17px;
  font-weight: 500;
  color: #271d7a;
  padding: 10px 0px 0px 20px;
}
.activeCallHeader {
  padding-top: 25px;
  padding-bottom: 25px;
  background-color: #e78535;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.activeCallHeader:hover {
  outline: 0.25px solid #271d7a;
  cursor: pointer;
}
.activeCallIcon {
  height: 30px;
  width: 24px;
  margin-right: 20px;
  margin-left: 20px;
  text-align: center;
}
.activeCallText {
  color: #ffffff;
  font-size: 19px;
  font-weight: 500;
}
.activeCallButtonContainer {
  margin: auto auto 0 auto;
  padding-bottom: 30px;
  width: 100%;
  background-color: #f8f9fc;
  flex: 1;
}
.hangupButtonContainer {
  display: flex;
  /* padding-top: 20px; */
}
.hangupButton {
  width: 90%;
  display: flex;
  margin: auto;
}
.activeCallDivider {
  width: 100%;
  margin-bottom: 25px;
}
.mute-icon {
  height: 35px;
  margin-right: 15px;
}
</style>
