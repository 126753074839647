/
<template>
  <button class="mediumButton" :class="{ disabled }">
    <img :src="require('@/assets/icons/Phone.png')" class="callButtonImg" />
  </button>
</template>

<script>
export default {
  name: "MediumButton",
  props: {
    title: String,
    disabled: Boolean,
  },
};
</script>

<style scoped>
.callButtonImg {
  width: 18px;
}
.mediumButton {
  width: 100%;
  height: 35px;
  padding: 5px 20px 5px 20px;
  background-color: #3e87d3;
  font-size: 10pt;
  font-weight: 600;
  filter: drop-shadow(1px 1px 1px rgba(39, 29, 122, 0.1));
  color: white;
  border-radius: 2px;
}
.mediumButton:hover {
  outline: #271d7a solid 1pt;
}
.mediumButton:active {
  padding: 5px 20px 5px 20px;
  background-color: #3556a6;
  outline: #2e3e91 solid 0.25pt;
  filter: none;
  box-shadow: 0 0 4px rgba(43, 45, 132, 0.6) inset;
  color: white;
}

.mediumButton.disabled {
  opacity: 0.5;
  pointer-events: none;
}
</style>
