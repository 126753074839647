<template>
  <div
    class="dropdown"
    :tabindex="0"
    :class="{ dropdownOpen: open }"
    @blur="handleBlur"
  >
    <div class="selected" :class="{ open: open }" @click="open = !open">
      <span v-if="value.length" class="showItemsSpan">
        <template v-for="(item, index) in value" :key="index">
          {{ item.label }}
          <span v-if="index !== value.length - 1"> ,&nbsp;</span>
        </template></span
      >
      <span v-else>{{ placeholder }}</span>
      <img
        v-if="open"
        src="../../../assets/icons/caret_open_dkblue.png"
        class="dropdownCaret"
      />
      <img
        v-else
        src="../../../assets/icons/caret_closed_dkblue.png"
        class="dropdownCaret"
      />
    </div>
    <div class="items" :class="{ selectHide: !open }">
      <div v-for="(option, i) of options" :key="i" class="option" @click="toggleOption(option)">
        <div class="optionLabel">
          <span class="optionText">{{ option.label }}</span>
          <span class="dropDownMultiCheckbox" @click.stop>
            <CheckBox
              :item="option"
              :checked="isSelected(option)"
              @toggle-selected="toggleOption"
              @mousedown.prevent
            />
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CheckBox from "./CheckBox.vue";

export default {
  name: "ManagementDropDownMulti",
  props: {
    options: Array,
    placeholder: String,
    value: {
      type: Array,
      default: () => []
    },
    preselectedItems: {
      type: Array,
      default: () => []
    },
    input: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      open: false,
      selected: [],
    };
  },
  computed: {
    localPreselect: {
      get () {
        return this.preselectedItems
      },
      set (value) {
        this.$emit("selected-values", this.input, value);
      }
    }
  },
  mounted() {
    if (this.preselectedItems && this.preselectedItems.length) {
      this.selected = [...this.preselectedItems];
    }
  },
  methods: {
    toggleOption(option) {
      let hasChanged = false;
      if (this.preselectedItems.length) {
        const index = this.preselectedItems.findIndex(item => item.value === option.value);
        if (index > -1) {
          this.localPreselect.splice(index, 1);
          hasChanged = true;
        } else {
          this.localPreselect.push(option);
          hasChanged = true;
        }
      } else {
        const index = this.selected.indexOf(option);
        if (index > -1) {
          this.selected.splice(index, 1);
          hasChanged = true;
        } else {
          this.selected.push(option);
          hasChanged = true;
        }
      }

      if (hasChanged) {
        this.$emit("selected-values", this.input, this.selected);
       
        this.$emit("user-interacted");
      }
    },
    // toggleOption(option) {
    //   if (this.preselectedItems.length) {
    //     const index = this.preselectedItems.findIndex(item => item.value === option.value);
    //     if (index > -1) {
    //       this.localPreselect.splice(index, 1);
    //     } else {
    //       this.localPreselect.push(option);
    //     }
    //   } else {
    //     const index = this.selected.indexOf(option);
    //     if (index > -1) {
    //       this.selected.splice(index, 1);
    //     } else {
    //       this.selected.push(option);
    //     }
    //     this.$emit("selected-values", this.input, this.selected);
    //   }
    // },
    isSelected(option) {
      if (this.value === undefined) {
        return false;
      }
      return this.value.some((item) => item.value === option.value);
    },
    handleBlur(event) {
      if (!event.relatedTarget) {
        this.open = false;
      }
    },
  },
  components: {
    CheckBox,
  },
  emits: [
    "selected-values",
    "user-interacted"
  ]
};
</script>

<style scoped>
.open {
  background-color: #eff4f9;
}

.selected {
  padding: 4px 0px 4px 8px;
}
.dropdown {
  position: relative;
  text-align: left;
  font-size: 9pt;
  z-index: 4;
  outline: 0.25px solid #2e3e91;
  border-radius: 1px;
  /* padding: 4px 0px 4px 8px; */
  color: #271d7a;
  margin-top: 6px;
  background-color: #ffffff;
}
.dropdownOpen {
  outline: 1.25px solid #271d7a;
  background-color: #eff4f9;
  z-index: 5;
}

.dropdown .items {
  color: #ffffff;
  overflow: hidden;
  /* position: absolute; */
  background-color: #ffffff;
  left: 0;
  right: 0;
  z-index: 1;
}

.dropdown .items div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #271d7a;
  cursor: pointer;
  user-select: none;
}
.items {
  margin-top: 3px;
  outline: 0.25px solid #271d7a;
  filter: drop-shadow(1px 1px 1px rgba(39, 29, 122, 0.1));
}
.dropdown:hover {
  cursor: pointer;
}

.dropdown .items div:hover {
  background-color: #eff4f9;
}

.selectHide {
  display: none;
}
.dropdownCaret {
  position: absolute;
  right: 0;
  top: 0;
  width: 20px;
  padding-top: 10px;
  padding-right: 7px;
}

.optionText {
  margin-left: 8px;
}
.dropDownMultiCheckbox {
  margin-top: 6px;
  margin-right: 5px;
  width: 20px;
}

.optionLabel {
  display: flex;
  align-items: center;
  width: 100%;
}

.showItemsSpan {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  width: 80% !important;
  display: block;
}
</style>
