/**
 * Converts a date string to the specified format.
 *
 * @param {string} date - The date string to be formatted.
 * @returns {string} The formatted date string based on the detected format.
 */
export function formatTableDate(date) {
  if (/^\d{4}-\d{2}-\d{2}$/.test(date)) {
    return date;
  } else if (/^\d{2}\/\d{2}\/\d{4}$/.test(date)) {
    const [month, day, year] = date.split('/');
    return `${year}-${month}-${day}`;
  } else if (/^\d{2}\.\d{2}\.\d{4}$/.test(date)) {
    const [day, month, year] = date.split('.');
    return `${year}-${month}-${day}`;
  } else {
    throw new Error('Invalid date format. Supported formats are "yyyy-mm-dd", "mm/dd/yyyy", and "dd.mm.yyyy".');
  }
}