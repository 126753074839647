/**
 * Adds an alert to the given list of alerts if the description is not already present.
 *
 * @param {string} name - The name of the alert.
 * @param {string} description - The description of the alert.
 * @param {Array.<{ id: number, name: string, description: string }>} alerts - The array of existing alerts.
 * @returns {void}
 */

export function addAlert(name, description, alerts, disapperTimeout = 5000, checkForDuplicates = false,) {
  const alertObject = {
    id: alerts.length,
    name,
    description,
  };

  if (!checkForDuplicates || !alerts.some(alert => alert.description === description)) {
    alerts.push(alertObject);

    if (disapperTimeout != 0) {
      // Set timeout to remove the alert after the specified duration
      setTimeout(() => {
        removeAlert(alerts, alertObject.id);
      }, disapperTimeout);
    }
  }
}

// Function to remove an alert by ID
function removeAlert(alerts, id) {
  const index = alerts.findIndex(alert => alert.id === id);
  if (index !== -1) {
    alerts.splice(index, 1);
  }
}