<template>
  <div
    @mouseover="toggleHoverBox"
    @mouseleave="turnoffHoverBox"
    class="statusDropdown"
    :tabindex="0"
    @blur="open = false"
    :class="{ statusDropdownOpen: open }"
  >
    <div
      class="selected"
      :class="{ open: open, [statusDropdownStyle]: true }"
    >
      <p class="selectedText">
        {{ selected.label ? selected.label : selected }}
      </p>
    </div>
    <div v-if="showHoverBox" style="position:absolute">
      <p>{{ fullAgentStatus }}</p>
    </div>
  </div>
</template>
<script setup></script>
<script scoped>
import { useAgentStore } from "@/stores/agentStore.js";
import { mapStores } from 'pinia';

export default {
  name: "StatusComp",
  props: {
    default: String,
    value: {
      type: String,
      default: null,
    },
    stopwatch: Object,
  },
  data() {
    return {
      selected: null,
      open: false,
      showHoverBox: false,
      fullAgentStatus: null
    };
  },
  computed: {
    ...mapStores(useAgentStore),
    options() {
      return [
        { label: "Logged out", value: "not_logged_in" },
        { label: "Available", value: "Ready" },
        { label: "Away", value: "Away" },
        { label: "Away - AFK", value: "Away_AFK" },
        { label: "Away - Lunch", value: "Away_Lunch" },
        { label: "Away - Meeting", value: "Away_Meeting" },
        { label: "Away - One-on-One", value: "Away_One-on_One" },
        { label: "Away - Training", value: "Away_Training" },
        { label: "On a call", value: "onAcall" },
        { label: "On a external call", value: "onAExternalCall"},
        { label: "Recovery", value: "Recovery" }
      ];
    },
    statusDropdownStyle() {
      if (this.selected.value === "Ready") {
        return "Ready";
      } else if (this.selected.value === "not_logged_in") {
        return "not_logged_in";
      } else if (this.selected.value === "Away") {
        return "Away";
      } else if (this.selected.value === "onAcall") {
        return "onAcall";
      } else if (this.selected.value === "onAExternalCall") {
        return "onAExternalCall"
      }
      else if (this.selected.value === "Recovery") {
        return "Recovery";
      }
      return "Away";
    },
  },
  methods: {
    toggleHoverBox() {
      this.fullAgentStatus = this.AgentStore.getFullAgentStatus;
      this.showHoverBox = true;
    },
    turnoffHoverBox() {
      this.showHoverBox = false;
    },
  },
  watch: {
    value(newValue) {
      if (newValue === null) {
        this.selected = this.default
          ? this.default
          : this.options.length > 0
          ? this.options[0]
          : null;
      }
      else {
        console.log(newValue);

        if (newValue.includes("On-A-Call")) {
          this.selected = {label: newValue, value: "onAcall"};
        }
        else if (newValue === "Ready") {
          this.selected = { label: "Available", value: "Ready" };
        }
        else if (newValue === "Away") {
          this.selected = { label: "Away", value: "Away" };
        }
        else if (newValue === "Away - AFK") {
          this.selected = { label: "Away - AFK", value: "Away_AFK" };
        }
        else if (newValue === "Away - Lunch") {
          this.selected = { label: "Away - Lunch", value: "Away_Lunch" };
        }
        else if (newValue === "Away - Meeting") {
          this.selected = { label: "Away - Meeting", value: "Away_Meeting" };
        }
        else if (newValue === "Away - One-on-One") {
          this.selected = { label: "Away - One-on-One", value: "Away_One-on_One" };
        }
        else if (newValue === "Away - Training") {
          this.selected = { label: "Away - Training", value: "Away_Training" };
        }
        else if (newValue === "onAExternalCall") {
          this.selected = { label: "On a external call", value: "onAExternalCall" };
        }
        else if (newValue === "Recovery") {
          this.selected = { label: "Recovery", value: "Recovery" };
        }
        else if (newValue === "not_logged_in") {
          this.selected = { label: "Logged out", value: "not_logged_in" };
        }
        else if (newValue.includes("Offer")) {
          this.selected = { label: newValue, value: "offer" };
        }
      }
    },
    selected(newValue) {
      this.$emit("status-change", newValue.value);
    },
  },
  created() {
    if (this.value.includes("On-A-Call")) {
      this.selected = {label: this.value, value: "onAcall"};
    }
    else if (this.value === "Ready") {
      this.selected = { label: "Available", value: "Ready" };
    }
    else if (this.value === "Away") {
      this.selected = { label: "Away", value: "Away" };
    }
    else if (this.value === "Away - AFK") {
      this.selected = { label: "Away - AFK", value: "Away_AFK" };
    }
    else if (this.value === "Away - Lunch") {
      this.selected = { label: "Away - Lunch", value: "Away_Lunch" };
    }
    else if (this.value === "Away - Meeting") {
      this.selected = { label: "Away - Meeting", value: "Away_Meeting" };
    }
    else if (this.value === "Away - One-on-One") {
      this.selected = { label: "Away - One-on-One", value: "Away_One-on_One" };
    }
    else if (this.value === "Away - Training") {
      this.selected = { label: "Away - Training", value: "Away_Training" };
    }
    else if (this.value === "onAExternalCall") {
      this.selected = { label: "On a external call", value: "onAExternalCall" };
    }
    else if (this.value === "Recovery") {
      this.selected = { label: "Recovery", value: "Recovery" };
    }
    else if (this.value === "not_logged_in") {
      this.selected = { label: "Logged out", value: "not_logged_in" };
    }
    else if (this.value.includes("Offer")) {
      this.selected = { label: this.value, value: "offer" };
    }
  },
  emits: [
    "input", "status-change"
  ]
};
</script>

<style scoped>
.Ready {
  border: 1px solid #add38d;
  background-color: #add38d !important;
  color: black !important;
  filter: drop-shadow(1px 1px 1px rgba(173, 211, 141, 1));
}
.not_logged_in {
  border: 1px solid #271d7a;
  color: black !important;
  filter: drop-shadow(1px 1px 1px rgba(39, 29, 122, 1));
}
.Away {
  border: 1px solid #eee253;
  background-color: #eee253 !important;
  color: black !important;
  filter: drop-shadow(1px 1px 1px rgba(238, 226, 83, 1));
}

.offer {
  border: 1px solid #eee253;
  background-color: #eee253 !important;
  color: black !important;
  filter: drop-shadow(1px 1px 1px rgba(238, 226, 83, 1));
}
.onAcall {
  border: 1px solid #e87928;
  background-color: #e87928 !important;
  color: black !important;
  filter: drop-shadow(1px 1px 1px rgba(232, 121, 40, 1));
}
.onAExternalCall {
  border: 1px solid #e87928;
  background-color: #e87928 !important;
  color: black !important;
  filter: drop-shadow(1px 1px 1px rgba(232, 121, 40, 1));
}

.Recovery {
  border: 1px solid #8f35f1;
  background-color: #8f35f1 !important;
  color: black !important;
  filter: drop-shadow(1px 1px 1px rgb(143, 53, 241));
}

.open {
  background-color: #eff4f9;
}
.statusDropdown {
  position: relative;
  text-align: left;
  font-size: 10pt;
  background-color: #ffffff;
  font-weight: 500;
  z-index: 999;
}
.statusDropdownOpen {
  z-index: 999;
}
.statusDropdown > div {
  background-color: #ffffff;
  z-index: 999;
}

.statusDropdown .selected {
  position: relative;
  border-radius: 1px;
  color: #271d7a;
  padding-left: 10px;
  /* cursor: pointer; */
  user-select: none;
  padding: 2px 25px 2px 25px;
  height: 25px;
  z-index: 999;
  width: auto;
}
.selectedText {
  text-align: center;
}

.statusDropdown .selected:after {
  position: absolute;
  top: 22px;
  right: 10px;
  z-index: 999;
}

.statusDropdown .items {
  color: #ffffff;
  overflow: hidden;
  position: absolute;
  background-color: #ffffff;
  left: 0;
  right: 0;
  z-index: 999;
  width: 250px;
}

.statusDropdown .items div {
  color: #271d7a;
  padding-left: 1em;
  cursor: pointer;
  user-select: none;
}

.statusDropdown .items div:hover {
  background-color: #eff4f9;
}
.items {
  filter: drop-shadow(1px 1px 1px rgba(39, 173, 122, 0.2));
}

.selectHide {
  display: none;
}
.statusDropdownCaret {
  position: absolute;
  right: 0;
  top: 9px;
  width: 23px;
  padding-right: 10px;
}
</style>
