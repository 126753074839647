import { defineStore } from "pinia";
import { useAuthStore } from "@/stores/auth/authStore.js";
import { Auth } from "aws-amplify";
import { API } from "aws-amplify";
import * as queries from "@/graphql/queries.js";
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api";
import { queryGraphQL } from "@/helpers/dbUtil.js";



export const useQueueDashboardStore = defineStore("QueueDashboard", {
    state: () => ({
        queueData: [],
        queue_members: {}
       
    }),
    getters: {
        getQueueData(state) {
            return state.queueData
        }
    },
    actions: {
        async initializeQueueSocket() {
            const api_name = "switch";
            const path = `/get_auth_token`;

            const userAuth = `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`;

            const myInit = {
                headers: {
                    Authorization: userAuth,
                    "Content-Type": "application/json"
                },
                body: {}
            }

            let result_json = await API.put(api_name, path, myInit);


            const authStore = useAuthStore();
            const websocket_url = authStore.getSwitchWebSocketUrl;

            var DashboardSocket = new WebSocket(websocket_url);

            //TODO: Make Socket Connection
            DashboardSocket.onopen = function () {
                //A subscription must include a valid pair of account_id / auth_token, and must include the binding that you want to listen to.
                var queuesSubscription = {
                    action: 'subscribe',
                    auth_token: result_json.data.auth_token,
                    data: {
                        account_id: '519b73ddf6c2ba5688acc12e9d0c01d4',
                        binding: 'qubicle.queue',
                    }
                };

                
                //bindings we might want: qubicle.recipient, dashboard.callcenter_pro, qubicle.queue
                //The data flowing through sockets must be a String, so we cast the JSON into a String
                var queuesSubscriptionString = JSON.stringify(queuesSubscription);
                //Once we properly configured our subscription, we can send the corresponding string to the WebSocket, which will notify the system that we want to subscribe to an event
                DashboardSocket.send(queuesSubscriptionString);
            };


            return DashboardSocket;
        },
        async setQueueList() {
            var options = {
                query: queries.listQueues,
                variables: {
                    limit: 1000
                },
                authMode: GRAPHQL_AUTH_MODE.API_KEY
            }

            try {
                var listQueuesResult = await queryGraphQL(options);
                // console.log("LIST QUEUES RESULT", listQueuesResult);
            }
            catch (error) {
                console.log(error);
            }

            this.queue_list = listQueuesResult.data.listQueues.items;


            //Pull back initial Switch queues
            var api_name = "switch";
            var path = `/accounts_frontend/519b73ddf6c2ba5688acc12e9d0c01d4/qubicle_queues`;

            var userAuth = `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`;

            var myInit = {
                headers: {
                    Authorization: userAuth,
                    "Content-Type": "application/json"
                },
            }

            let result_json = await API.get(api_name, path, myInit);
            var queues = result_json.data.data;
     
            await Promise.all(queues.map(async (queue) => {
                var queueFound = false;

                api_name = "switch";
                path = `/accounts_frontend/519b73ddf6c2ba5688acc12e9d0c01d4/qubicle_queues/${queue.id}/status`;

                userAuth = `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`;

                myInit = {
                    headers: {
                        Authorization: userAuth,
                        "Content-Type": "application/json"
                    },
                }

                result_json = await API.get(api_name, path, myInit);



                api_name = "switch";
                path = `/accounts_frontend/519b73ddf6c2ba5688acc12e9d0c01d4/qubicle_queues/${queue.id}`;

                userAuth = `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`;

                myInit = {
                    headers: {
                        Authorization: userAuth,
                        "Content-Type": "application/json"
                    },
                }

                var queueMemberResult = await API.get(api_name, path, myInit);

                this.queue_members[queue.id] = { members: queueMemberResult.data.data.members, name: queueMemberResult.data.data.name};

                var agentsOnPhone = 0;
                var agentsAway = result_json.data.data.active_recipient_count - result_json.data.data.available_recipient_count;
                var outboundCallsCount = await this.getQueueOutboundCalls(queue.name.trim());
                var callsHandedToOverflowCount = await this.getQueueOverflowCalls(queue.name.trim());
                var queueTicketInformation = await this.getQueueTicketsForToday(queue.name);


                var queue_object = {
                    queueName: queue.name, //Valid Data
                    answeredCalls: result_json.data.data.stats.total_sessions - (result_json.data.data.stats.missed_sessions + result_json.data.data.stats.abandoned_sessions), //Valid Data
                    callsWaitingAndWaitTime: { first: result_json.data.data.stats.active_session_count, second: this.toHHMMSS(result_json.data.data.stats.estimated_wait) }, //First not Valid, Second Valid
                    inboundAndOutboundCalls: { first: result_json.data.data.stats.total_sessions, second: outboundCallsCount }, //Not Valid
                    missedAndAbandonedCalls: { first: result_json.data.data.stats.missed_sessions, second: result_json.data.data.stats.abandoned_sessions }, //Valid Data
                    callsHandedToOverflow: callsHandedToOverflowCount, //Not Valid
                    newTickets: queueTicketInformation.new_tickets_count,
                    // newTickets: 2,
                    logicomUSAAndOverflowTickets: { first: `${queueTicketInformation.logicomTickets}%`, second: `${queueTicketInformation.overflowTickets}` },
                    ticketsEscalated: { first: queueTicketInformation.internal_internet_escalations, second: queueTicketInformation.external_internet_escalations }, //Not Valid
                    csrEscalations: queueTicketInformation.open_csr_escalations, //Not Valid
                    agentsReady: result_json.data.data.available_recipient_count, //Available-Recipient-Count: Count of currently available recipients (ready to take a call) - I Think its Valid
                    agentsOnPhoneAndAway: { first: agentsOnPhone, second: agentsAway }, //First: I think valid, Second: I think valid
                    callsWaitingInQueue: result_json.data.data.stats.active_session_count, //Not Valid
                    averageWaitTime: this.toHHMMSS(result_json.data.data.stats.average_wait), //Valid
                    longestHoldTimeCurrent: `00:00`, //Dont know how to get
                    longestHoldTimeDay: this.toHHMMSS(result_json.data.data.stats.estimated_wait), //dont know how to get
                    callbacksScheduled: 7, //dont know how to get
                    "Average Recovery Time": `00:16`, //dont know how to get
                    current_queue_sessions: [],
                }

                if (this.queueData.length) {
                    this.queueData.forEach((queue, index) => {
                        if (queue.queueName == queue.name) {
                            queueFound = true;
                            this.queueData[index] = queue_object;
                        }
                    });
                }

                if (!queueFound) {
                    this.queueData.push(queue_object);
                }
            }));
        },
        async getQueueOutboundCalls(queue) {
            var rightNow = new Date();
            var startOfDay = new Date(rightNow.getFullYear(), rightNow.getMonth(), rightNow.getDate(), 0, 0, 0);
            startOfDay.setHours(0, 0, 0, 0);

            var spoofed_numbers = "";
            for (var queue_object of this.queue_list) {
                if (queue == queue_object.switch_queue_name) {
                    if (queue_object.queue_cid_spoof_number) {
                        spoofed_numbers = queue_object.queue_cid_spoof_number.split(",");
                        break;
                    }
                }
            }


            var totalOutboundCalls = 0;



            for (var number of spoofed_numbers) {
                var options = {
                    query: queries.searchSwitchCDRs,
                    variables: {
                        limit: 1000,
                        filter: {
                            createdAt: {
                                gt: startOfDay.toISOString()
                            },
                            dialed_number: {
                                wildcard: `*${number[0]}*`
                            }
                        }
                    },
                    authMode: GRAPHQL_AUTH_MODE.API_KEY
                }
                try {
                    var outBoundCallsResult = await queryGraphQL(options);
                    // console.log("OUTBOUND CALLS RESULT", outBoundCallsResult);
                    var total = outBoundCallsResult.data.searchSwitchCDRs.total;
                    totalOutboundCalls += total;
                }
                catch (error) {
                    console.log(error);
                }
            }

            return totalOutboundCalls;
        },
        async getQueueOverflowCalls(queue) {
            var rightNow = new Date();
            var startOfDay = new Date(rightNow.getFullYear(), rightNow.getMonth(), rightNow.getDate(), 0, 0, 0);
            startOfDay.setHours(0, 0, 0, 0);



            var fc_overflow_number = "";
            for (var queue_object of this.queue_list) {
                if (queue == queue_object.switch_queue_name) {
                    if (queue_object.fc_overflow_number) {
                        fc_overflow_number = queue_object.fc_overflow_number.split(",");
                        break;
                    }
                }
            }

            var totalOverflowCalls = 0;

            var options = "";
            if (queue == "Retail Customers") {
                options = {
                    query: queries.searchSwitchCDRs,
                    variables: {
                        limit: 1000,
                        filter: {
                            createdAt: {
                                gt: startOfDay.toISOString()
                            },
                            calling_from: {
                                wildcard: `*${fc_overflow_number}*`
                            },
                            to: { wildcard: "*firstcall.s.logicom.center*" },
                            from: { wildcard: "*firstcall.s.logicom.center*" }
                        }
                    },
                    authMode: GRAPHQL_AUTH_MODE.API_KEY
                }
            }
            else {
                options = {
                    query: queries.searchSwitchCDRs,
                    variables: {
                        limit: 1000,
                        filter: {
                            createdAt: {
                                gt: startOfDay.toISOString()
                            },
                            dialed_number: {
                                wildcard: `*${fc_overflow_number}*`
                            },
                            to: { wildcard: "*firstcall.s.logicom.center*" },
                            from: { wildcard: "*firstcall.s.logicom.center*" }
                        }
                    },
                    authMode: GRAPHQL_AUTH_MODE.API_KEY
                }
            }

            try {
                var overflowCallsResult = await queryGraphQL(options);
                // console.log("OVERFLOW CALLS RESULT", overflowCallsResult);
                var total = overflowCallsResult.data.searchSwitchCDRs.total;
                totalOverflowCalls += total;
            }
            catch (error) {
                console.log(error);
            }

            return totalOverflowCalls;
        },
        async getQueueTicketsForToday(event_queue) {
            var new_tickets_count = 0;
            var external_internet_escalations = 0;
            var internal_internet_escalations = 0;
            var open_csr_escalations = 0;
            var callBacksScheduled = 0;
            var logicomTickets = 0;
            var overflowTickets = 0;

            var rightNow = new Date();
            var startOfDay = new Date(rightNow.getFullYear(), rightNow.getMonth(), rightNow.getDate(), 0, 0, 0);
            var twoHoursBefore = rightNow.setHours(rightNow.getHours() - 2);

            const searchTickets = /* GraphQL */ `
            query SearchTickets(
                $filter: SearchableTicketsFilterInput
                $sort: [SearchableTicketsSortInput]
                $limit: Int
                $nextToken: String
                $from: Int
                $aggregates: [SearchableTicketsAggregationInput]
            ) {
                searchTickets(
                filter: $filter
                sort: $sort
                limit: $limit
                nextToken: $nextToken
                from: $from
                aggregates: $aggregates
                ) {
                items {
                    id
                    queue_id
                    queue {
                    id
                    queue
                    queue_name
                    switch_queue_name
                    contact_information
                    sign_off_information
                    flow_information
                    ticket_subject_values
                    tools_list
                    createdAt
                    updatedAt
                    _version
                    _deleted
                    _lastChangedAt
                    __typename
                    }
                    subject
                    description
                    status
                    time_started
                    time_last_contact
                    priority
                    owner
                    owner_group
                    creator
                    requestor
                    requestor_group
                    cc
                    createdAt
                    updatedAt
                    contact_information
                    custom_fields {
                    items {
                        set_value
                        queue_custom_field {
                        default_custom_field {
                            field_name
                        }
                        }
                    }
                    }
                    comments {
                    nextToken
                    startedAt
                    __typename
                    }
                    activity {
                    items {
                        activity_type
                        field_changed
                        new_field_value
                        old_field_value
                    }
                    }
                    _version
                    _deleted
                    _lastChangedAt
                    __typename
                }
                nextToken
                total
                aggregateItems {
                    name
                    result {
                    ... on SearchableAggregateScalarResult {
                        value
                    }
                    ... on SearchableAggregateBucketResult {
                        buckets {
                        key
                        doc_count
                        __typename
                        }
                    }
                    }
                    __typename
                }
                __typename
                }
            }
            `;


            //Grabbing New Tickets
            for (var queue of this.queue_list) {
                if (queue.switch_queue_name == event_queue.trim()) { //event.data.queue_name
                    var options = {
                        limit: 1000,
                        query: searchTickets,
                        variables: {
                            filter: {
                                queue_id: { eq: queue.id },
                                createdAt: { gt: startOfDay.toISOString() }
                            }
                        },
                        authMode: GRAPHQL_AUTH_MODE.API_KEY
                    }
                    var listQueuesResult = await API.graphql(options);

                    var queue_tickets = listQueuesResult.data.searchTickets.items;
                }
            }

            if (queue_tickets) {
                for (var ticket of queue_tickets) {
                    //New Created Tickets
                    if (Date.parse(ticket.createdAt) > twoHoursBefore) {
                        new_tickets_count++;
                    }

                    var ticket_custom_fields = ticket.custom_fields.items;

                    //Ticket Escalations Count
                    for (var custom_field of ticket_custom_fields) {
                        if (custom_field.queue_custom_field.default_custom_field.field_name == "Escalation") {
                            if (custom_field.set_value == "External Standard - Internet") {
                                external_internet_escalations++;
                            }
                            else if (custom_field.set_value == "Internal Internet") {
                                internal_internet_escalations++;
                            }

                            if (custom_field.set_value && ticket.status == "Open" && ticket.owner != "Nobody") {
                                open_csr_escalations++;
                            }
                        }


                        if (custom_field.queue_custom_field.default_custom_field.field_name == "Callback") {
                            if (custom_field.set_value != "") {
                                callBacksScheduled++;
                            }
                        }
                    }


                    if (ticket.owner.includes("logicomusa")) {
                        logicomTickets++;
                    }
                    else if (ticket.owner.includes("firstcall")) {
                        overflowTickets++;
                    }
                }
            }


            var totalTickets = logicomTickets + overflowTickets;

            var logicomTicketsPercentage = 0;
            var overflowTicketsPercentage = 0;
            if (totalTickets != 0) {
                logicomTicketsPercentage = logicomTickets / totalTickets * 100;
                overflowTicketsPercentage = overflowTickets / totalTickets * 100;
            }

            var return_object = {
                new_tickets_count: new_tickets_count,
                external_internet_escalations: external_internet_escalations,
                internal_internet_escalations: internal_internet_escalations,
                open_csr_escalations: open_csr_escalations,
                callBacksScheduled: callBacksScheduled,
                overflowTickets: overflowTicketsPercentage,
                logicomTickets: logicomTicketsPercentage,
            }

            return return_object;
        },
        async handleQueueEvent(event) {
            var queueFound = false;
            var agentsOnPhone = 0;
            var agentsAway = event.data.active_recipient_count - event.data.available_recipient_count;
            // var queue_id = event.data.queue_id;

            // var agents_array = this.queue_members[queue_id].members;

            // for (var agent of this.agents_table_data) {
            //     if (agent.recipient_id in agents_array) {
            //         if (agent.status.first == "On-A-Call" || agent.status.first == "On-External-Call") {
            //             agentsOnPhone++;
            //         }
            //     }
            // }

            console.log(event);

            agentsAway = agentsAway - agentsOnPhone;
            var outboundCallsCount = await this.getQueueOutboundCalls(event.data.queue_name.trim());
            var callsHandedToOverflowCount = await this.getQueueOverflowCalls(event.data.queue_name.trim());
            var queueTicketInformation = await this.getQueueTicketsForToday(event.data.queue_name);

            var queue_object = {
                queueName: event.data.queue_name, //Valid Data
                answeredCalls: event.data.stats.total_sessions - (event.data.stats.missed_sessions + event.data.stats.abandoned_sessions), //Valid Data
                callsWaitingAndWaitTime: { first: event.data.stats.active_session_count, second: this.toHHMMSS(event.data.stats.estimated_wait) }, //First not Valid, Second Valid
                inboundAndOutboundCalls: { first: event.data.stats.total_sessions, second: outboundCallsCount }, //Not Valid
                missedAndAbandonedCalls: { first: event.data.stats.missed_sessions, second: event.data.stats.abandoned_sessions }, //Valid Data
                callsHandedToOverflow: callsHandedToOverflowCount, //Not Valid
                newTickets: queueTicketInformation.new_tickets_count,
                logicomUSAAndOverflowTickets: { first: `${queueTicketInformation.logicomTickets}%`, second: `${queueTicketInformation.overflowTickets}` },
                ticketsEscalated: { first: queueTicketInformation.internal_internet_escalations, second: queueTicketInformation.external_internet_escalations }, //Not Valid
                csrEscalations: queueTicketInformation.open_csr_escalations, //Not Valid
                agentsReady: event.data.available_recipient_count, //Available-Recipient-Count: Count of currently available recipients (ready to take a call) - I Think its Valid
                agentsOnPhoneAndAway: { first: agentsOnPhone, second: agentsAway }, //First: I think valid, Second: I think valid
                callsWaitingInQueue: event.data.stats.active_session_count, //Not Valid
                averageWaitTime: this.toHHMMSS(event.data.stats.average_wait), //Valid
                longestHoldTimeCurrent: `00:00`, //Dont know how to get
                longestHoldTimeDay: this.toHHMMSS(event.data.stats.estimated_wait), //dont know how to get
                callbacksScheduled: queueTicketInformation.callBacksScheduled, //dont know how to get
                "Average Recovery Time": `00:16`, //dont know how to get
                current_queue_sessions: [],
            }

            // Check
            if (this.queueData.length) {
                this.queueData.forEach((queue, index) => {
                    if (queue.queueName == event.data.queue_name) {
                        queueFound = true;
                        this.queueData[index] = queue_object;
                    }
                });
            }

            if (!queueFound) {
                this.queueData.push(queue_object);
            }
        },
        toHHMMSS(secs) {
            var sec_num = parseInt(secs, 10)
            var hours = Math.floor(sec_num / 3600)
            var minutes = Math.floor(sec_num / 60) % 60
            var seconds = sec_num % 60

            return [hours, minutes, seconds]
                .map(v => v < 10 ? "0" + v : v)
                .filter((v, i) => v !== "00" || i > 0)
                .join(":")
        },
        resetQueueData() {
            this.queueData = [];
            this.queue_members = {};
        }
    }
});