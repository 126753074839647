<template>
    <v-container class="main_container">
        <v-row>
            <v-col>
                <v-progress-linear :class="[isLoading ? showLoader : '', hideLoader]" color="#271d7a" indeterminate></v-progress-linear>
            </v-col>
        </v-row>
        <v-row :class="loginContainerMarginControl">
            <v-col align="center" class="imageContainer">
                <img id="login_logo_image" src="@/assets/loginpage_logo.png" />
            </v-col>
        </v-row>
        <v-row>
            <v-col class="welcomeTextContainer">
                <span id="login_welcome_text">Welcome to LEX Connect</span>
            </v-col>
        </v-row>
        <v-window :touch="false" v-model="auth_step">
            <v-window-item value="LOGIN">
                <v-row>
                    <v-col class="sectionHeadingContainer">
                        <span id="login_section_heading">User Login</span>
                    </v-col>
                </v-row>
                <form>
                    <v-row>
                        <v-col class="textfieldContainer">
                            <TextFieldComponent label="Username" size="large" autocomplete="email" v-model="username" />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col class="textfieldContainer">
                            <TextFieldComponent label="Password" size="large" autocomplete="current-password" type="password" v-model="password" />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col class="buttonContainer">
                                <ButtonComponent class="buttonSizeOverride" button_type="dark" title="Login" @click="handleLogin"/>
                        </v-col>
                    </v-row>
                </form>
                <v-row>
                    <v-col class="bottomTextContainer">
                        <span class="bottomText" @click="changeScreen('FORGOT_PASSWORD')">Forgot Password?</span>
                    </v-col>
                </v-row>
            </v-window-item>
            <v-window-item value="SMS_MFA">
                <v-row>
                    <v-col class="sectionHeadingContainer">
                        <span id="login_section_heading">SMS Authentication Code</span>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="textContainer">
                        <p class="regularText">If code is not working please click "Resend Code" to receive a new code.</p>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="buttonContainer">
                        <DarkButton class="buttonSizeOverride" title="Resend Code" @click="resendSMSCode"/>
                    </v-col>
                </v-row>
                <form>
                    <v-row>
                        <v-col class="textfieldContainer">
                            <TextFieldComponent size="large" label="Code" v-model="totp_code" />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col class="buttonContainer">
                            <ButtonComponent class="buttonSizeOverride" button_type="dark" title="Submit" @click="handleTOTPCode"/>
                        </v-col>
                    </v-row>
                </form>
                <v-row>
                    <v-col class="checkboxContainer">
                        <CheckboxComponent v-model="remember_device"/>
                        <span class="rememberMeText">Remember Me</span>
                    </v-col>
                </v-row>
            </v-window-item>
            <v-window-item value="SOFTWARE_TOKEN_MFA">
                <v-row>
                    <v-col class="sectionHeadingContainer">
                        <span id="login_section_heading">Authenticator Code</span>
                    </v-col>
                </v-row>
                <form>
                    <v-row>
                        <v-col class="textfieldContainer">
                            <TextFieldComponent size="large" label="Code" v-model="totp_code" />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col class="buttonContainer">
                            <ButtonComponent class="buttonSizeOverride" button_type="dark" title="Submit" @click="handleTOTPCode"/>
                        </v-col>
                    </v-row>
                </form>
                <v-row>
                    <v-col class="checkboxContainer">
                        <CheckboxComponent v-model="remember_device"/>
                        <span class="rememberMeText">Remember Me</span>
                    </v-col>
                </v-row>
            </v-window-item>
            <v-window-item value="NEW_PASSWORD_REQUIRED">
                <v-row>
                    <v-col class="sectionHeadingContainer">
                        <span id="login_section_heading">New Password Required</span>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="textfieldContainer">
                        <TextFieldComponent size="large" :renderPasswordTooltip="true" label="New Password" type="password" v-model="force_new_password" />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="textfieldContainer">
                        <TextFieldComponent size="large" label="Confirm Password" type="password" v-model="confirm_force_new_password" />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="buttonContainer">
                        <DarkButton title="Submit" class="buttonSizeOverride" @click="handleForcePasswordChange" />
                    </v-col>
                </v-row>
            </v-window-item>
            <v-window-item value="SETUP_SOFTWARE_MFA">
                <v-row>
                    <v-col class="sectionHeadingContainer">
                        <span id="login_section_heading">Setup Authenticator</span>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="qrCodeContainer">
                        <QRCodeVue3 :width="200" :height="200" :dotsOptions="dotsOptions" :value="setup_totp_qr_url" />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="buttonContainer">
                        <DarkButton class="buttonSizeOverride" style="width: 100%" title="Copy Setup Key to Clipboard" @click="copySoftwareSetupKey()" />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="textContainer">
                        <p class="regularText">After setting up your authenticator app using the setup key above, enter the code generated below.</p>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="textfieldContainer">
                        <TextFieldComponent size="large" label="Code" v-model="verify_software_token_code" />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="buttonContainer">
                        <DarkButton title="Submit" class="buttonSizeOverride" @click="verifySoftwareTokenCode" />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="bottomTextContainer">
                        <span class="bottomText" @click="changeScreen('CHOOSE_MFA_PREFERENCE')">Back</span>
                    </v-col>
                </v-row>
            </v-window-item>
            <v-window-item value="SETUP_SMS_MFA">
                <v-row>
                    <v-col class="sectionHeadingContainer">
                        <span id="login_section_heading">Setup SMS Authentication</span>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="textfieldContainer">
                        <TextFieldComponent size="large" label="Phone Number" v-model="phone_number" />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="buttonContainer">
                        <DarkButton title="Send Code" class="buttonSizeOverride" @click="addPhoneNumberAndSendVerification" />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="textContainer">
                        <p class="regularText">If code is not working please click "Send Code" again to receive a new code.</p>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="textfieldContainer">
                        <TextFieldComponent size="large" label="Code Received" v-model="sms_totp_code" />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="buttonContainer">
                        <DarkButton title="Submit" class="buttonSizeOverride" @click="verifySMSCode" />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="bottomTextContainer">
                        <span class="bottomText" @click="changeScreen('CHOOSE_MFA_PREFERENCE')">Back</span>
                    </v-col>
                </v-row>
            </v-window-item>
            <v-window-item value="CHOOSE_MFA_PREFERENCE">
                <v-row>
                    <v-col class="sectionHeadingContainer">
                        <span id="login_section_heading">Choose MFA Type</span>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="selectContainer">
                        <SelectComponent size="large" placeholder="Choose MFA Type" :options="MFA_TYPE_OPTIONS" label="MFA Type" v-model="mfa_type"/>
                    </v-col>
                </v-row>
                <v-row style="margin-bottom: 2%;">
                    <v-col class="buttonContainer">
                        <DarkButton class="buttonSizeOverride" title="Submit" @click="handleChooseMFAPreference" />
                    </v-col>
                </v-row>
            </v-window-item>
            <v-window-item value="FORGOT_PASSWORD">
                <v-row>
                    <v-col class="sectionHeadingContainer">
                        <span id="login_section_heading">Password Reset</span>
                        <p class="regularText">Please enter your username to reset your password.</p>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="textfieldContainer">
                        <TextFieldComponent label="Username" size="large" v-model="username_for_password_reset" />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="buttonContainer">
                        <DarkButton title="Reset Password" class="buttonSizeOverride" style="width: 50%;" @click="sendPasswordReset" />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="textContainer">
                        <p class="regularText">Remember your password?</p>
                        <span class="bottomText" style="float: left;" @click="changeScreen('LOGIN')">Sign In</span>
                    </v-col>
                </v-row>
            </v-window-item>
            <v-window-item value="RESET_PASSWORD">
                <v-row>
                    <v-col class="sectionHeadingContainer">
                        <span id="login_section_heading">Reset Password</span>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="textContainer">
                        <p class="regularText">Please check your inbox for an email with the subject "LEX Connect Verification Code" and enter the code below.</p>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="textfieldContainer">
                        <TextFieldComponent label="Code" size="large" v-model="reset_password_code" />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="textfieldContainer">
                        <TextFieldComponent label="New Password" :renderPasswordTooltip="true" size="large" type="password" v-model="new_pass_for_reset" />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="textfieldContainer">
                        <TextFieldComponent label="Confirm Password" size="large" type="password" v-model="confirm_pass_for_reset" />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="buttonContainer">
                        <DarkButton class="buttonSizeOverride" title="Submit" @click="confirmPasswordReset" />
                    </v-col>
                </v-row>
            </v-window-item>
        </v-window>
    </v-container>
</template>



<script setup>
import { ref, computed } from 'vue';
import TextFieldComponent from '../base_components/TextFieldComponent.vue';
import DarkButton from '../base_components/Buttons/DarkButton.vue';
import { Auth } from 'aws-amplify';
import { useAlertsStore } from "@/stores/alertsStore.js";
import { useAuthStore } from '@/stores/auth/authStore';
import QRCodeVue3 from "qrcode-vue3";
import SelectComponent from '../base_components/SelectComponent.vue';
import CheckboxComponent from '../base_components/CheckboxComponent.vue';
import ButtonComponent from '../base_components/Buttons/ButtonComponent.vue';


//General Variables
const alertsStore = useAlertsStore();
const authStore = useAuthStore();
const auth_step = ref('LOGIN');
const current_user = ref(null);
const mfa_type = ref(null);
const isLoading = ref(false);
const hideLoader = ref('hideLoader');
const showLoader = ref('showLoader');

//Reset Password Variables
const username_for_password_reset = ref(null);
const reset_password_code = ref(null);
const new_pass_for_reset = ref(null);
const confirm_pass_for_reset = ref(null);

//Force Password Change Variables
const force_new_password = ref(null);
const confirm_force_new_password = ref(null);


//Sign in Variables
const username = ref(null);
const password = ref(null);
const totp_code = ref(null);


//SMS Setup Variables
const phone_number = ref(null);
const sms_totp_code = ref(null);

//Software MFA Variables
const verify_software_token_code = ref(null);
const setup_totp_code = ref(null);
const setup_totp_qr_url = ref(null);

//Remember Device Variables
const remember_device = ref(false);

const dotsOptions = {
    type: 'square',
    color: '#271d7a',
}

const MFA_TYPE_OPTIONS = [
    {label: "Authenticator Application", value: "SETUP_SOFTWARE_MFA"},
    {label: "SMS", value: "SETUP_SMS_MFA"}
]



//Computed

const loginContainerMarginControl = computed(() => {
    if (auth_step.value == 'RESET_PASSWORD' || auth_step.value == 'SETUP_SMS_MFA') {
        return 'TenPercentContainerMargin';
    }
    else if (auth_step.value == 'SETUP_SOFTWARE_MFA') {
        return 'ZeroPercentContainerMargin';
    }
    else {
        return "defaultContainerMargin";
    }
});




//Functions
function changeScreen(STEP) {
    auth_step.value = STEP;
}


async function handleLogin() { //Handles the Sign in Page - Redirects The User Depending on the challange returned **NEXT_STEP**

    console.log("HANDLE LOGIN");

    if (!username.value) { alertsStore.addAlert("Error", `Username cannot be empty.`); return; }
    if (!password.value) { alertsStore.addAlert("Error", `Password cannot be empty.`); return; }

    isLoading.value = true;

    const signInData = {
        username: username.value,
        password: password.value
    }


    try {
        const sign_in_return = await Auth.signIn(signInData);

        current_user.value = sign_in_return;
        const NEXT_STEP = sign_in_return.challengeName;
        const PREFERRED_MFA = sign_in_return.preferredMFA;

        console.log("sign_in_return: ", sign_in_return);

        if (!NEXT_STEP && PREFERRED_MFA === 'NOMFA') { //User Force Changed their Password but left before setting up MFA.
            changeScreen("CHOOSE_MFA_PREFERENCE");
        }
        else if (!NEXT_STEP && PREFERRED_MFA !== 'NOMFA' && sign_in_return.signInUserSession) { //User Device Remembered / Skip MFA AUTH
            await authStore.setUserAuthStatus(true);
        }
        else {
            changeScreen(NEXT_STEP);
        }
    }
    catch (error) {
        alertsStore.addAlert("Error", "Incorrect username or password."); 
    }

    isLoading.value = false;
}

async function handleTOTPCode() { //Handles the MFA Code Confirm - SMS OR Authenticator Application

    console.log("handle totp code");

    if (!totp_code.value) { alertsStore.addAlert("Error", `Code cannot be empty.`); return; }

    isLoading.value = true;

    //Input: Current User Object, TOTP CODE, The MFA Method Used.

    try {
        const confirmSignInResult = await Auth.confirmSignIn(current_user.value, totp_code.value, auth_step.value);
        await Auth.setPreferredMFA(current_user.value, auth_step.value);

        if (remember_device.value) {
            await Auth.rememberDevice();
        }

        if (confirmSignInResult?.signInUserSession) {
            await authStore.setUserAuthStatus(true);
        }
    }
    catch (error) {
        alertsStore.addAlert("Error", "Code is Invalid.");
    }

    isLoading.value = false;
}

async function handleForcePasswordChange() { //Handles Force Change Password Step for New Users
    if (force_new_password.value != confirm_force_new_password.value) { alertsStore.addAlert("Error", `Passwords do not match.`); return; }

    isLoading.value = true;

    console.log("force password change");

    try {
        const force_password_result = await Auth.completeNewPassword(current_user.value, force_new_password.value);
        console.log("force_password_result: ", force_password_result);

        if (force_password_result.signInUserSession) {
            changeScreen("CHOOSE_MFA_PREFERENCE");
        }
    }
    catch ({ message }) {
        alertsStore.addAlert("Error", "Password does not match policy.");
    }

    isLoading.value = false;
}

async function verifySoftwareTokenCode() { //Verifies the setup of Authenticator App - If Successfull we are good to login the user.
    if (!verify_software_token_code.value) {alertsStore.addAlert("Error", `Code cannot be empty.`); return;}

    isLoading.value = true;

    try {
        const verify_totp_token_result = await Auth.verifyTotpToken(current_user.value, verify_software_token_code.value);

        console.log("verify_totp_token_result: ", verify_totp_token_result);

        if (verify_totp_token_result.Status === 'SUCCESS') {
            const preffered_mfa_result = await Auth.setPreferredMFA(current_user.value, "SOFTWARE_TOKEN_MFA");

            if (preffered_mfa_result === 'SUCCESS') {
                await authStore.setUserAuthStatus(true);
            }
        }
    }
    catch(error) {
        alertsStore.addAlert("Error", "Code is Invalid.");
    }

    isLoading.value = false;
}

async function handleChooseMFAPreference() { //Handles Choosing what MFA the New User will be using.
    if (!mfa_type.value) { alertsStore.addAlert("Error", `MFA Type Cannot be empty.`); return; }  

    if (mfa_type.value === 'SETUP_SOFTWARE_MFA') {
        setup_totp_code.value = await Auth.setupTOTP(current_user.value);
        setup_totp_qr_url.value = `otpauth://totp/LEXConnect:${username.value}?secret=${setup_totp_code.value}&issuer=LEXConnect`;
        changeScreen(mfa_type.value);
    }
    else if (mfa_type.value === 'SETUP_SMS_MFA') {
        changeScreen(mfa_type.value);
    }
}

async function addPhoneNumberAndSendVerification() { //Adds Phone Number to User and send verification message
    if (!phone_number.value) { alertsStore.addAlert("Error", `Phone Number cannot be empty`); return; }

    let formatted_phone_number = "+1"  + phone_number.value.replace("-", "").trim();

    try {
        await Auth.updateUserAttributes(current_user.value, {
            phone_number: formatted_phone_number
        });

        const verify_phone_result = await Auth.verifyUserAttribute(current_user.value, 'phone_number');

        if (verify_phone_result === 'SUCCESS') {
            alertsStore.addAlert("Notification", "SMS Code Sent.");
        }
    }
    catch(error) {
        alertsStore.addAlert("Error", "Invalid Phone Number.");
        return;
    }
}

async function verifySMSCode() { //Handles Verification of SMS Code send to user. If successful we are good to login the user.
    if (!sms_totp_code.value) { alertsStore.addAlert("Error", `Code cannot be empty.`); return; }

    try {
        const verifySMSCodeResult = await Auth.verifyUserAttributeSubmit(current_user.value, 'phone_number', sms_totp_code.value);
        console.log("verifySMSCodeResult: ", verifySMSCodeResult);

        if (verifySMSCodeResult === 'SUCCESS') {
            const preffered_mfa_result = await Auth.setPreferredMFA(current_user.value, "SMS_MFA");

            console.log("preffered_mfa_result", preffered_mfa_result);

            if (preffered_mfa_result == 'SUCCESS') {
                await authStore.setUserAuthStatus(true);
            }
        }
    }
    catch(error) {
        alertsStore.addAlert("Error", "Code is Invalid");
        return;
    }
}

async function sendPasswordReset() { //Initiate Password Reset
    if (!username_for_password_reset.value) { alertsStore.addAlert("Error", `Username cannot be empty.`); return; }


    try {
        await Auth.forgotPassword(username_for_password_reset.value);
        alertsStore.addAlert("Notification", "Reset Password Code Sent.");
        changeScreen("RESET_PASSWORD");
    }
    catch(error) {
        alertsStore.addAlert("Error", "Failed to send Password Reset Code.");
        return;
    }
}

async function confirmPasswordReset() { //Confirm Password Reset
    if (new_pass_for_reset.value != confirm_pass_for_reset.value) { alertsStore.addAlert("Error", `Passwords do not match.`); return; }

    try {
        const forgotPasswordSubmitResult = await Auth.forgotPasswordSubmit(username_for_password_reset.value, reset_password_code.value, new_pass_for_reset.value);

        if (forgotPasswordSubmitResult === 'SUCCESS') {
            changeScreen("LOGIN");
        }
    }
    catch({ message }) {
        alertsStore.addAlert("Error", "Password does not match policy."); 
        return;
    }
}

async function copySoftwareSetupKey() {
    navigator.clipboard.writeText(setup_totp_code.value);
}


async function resendSMSCode() {
    const signInData = {
        username: username.value,
        password: password.value
    }

    try {
        current_user.value = await Auth.signIn(signInData);
    }
    catch(error) {
        alertsStore.addAlert("Error", "Failed to Resend Code."); 
    }
}


</script>




<style scoped>
.imageContainer {
    margin-left: 20%;
    margin-right: 20%;
}

.welcomeTextContainer {
    margin-left: 20%;
    margin-right: 20%;
}

.sectionHeadingContainer {
    margin-top: 5%;
    margin-left: 20%;
    margin-right: 20%;
}

.textfieldContainer {
    margin-left: 20%;
    margin-right: 25%;
    margin-bottom: 1%;
}

.textContainer {
    margin-left: 20%;
    margin-right: 25%;
}

.buttonContainer {
    margin-left: 20%;
    margin-right: 25%;
}

.setupTotpCodeContainer {
    margin-left: 20%;
    margin-right: 20%;
}

.bottomTextContainer {
    margin-left: 20%;
    margin-right: 25%;
}

.qrCodeContainer {
    margin-left:  17%;
    margin-right: 20%;
}

.selectContainer {
    margin-left: 20%;
    margin-right: 25%;
}

.checkboxContainer {
    margin-left: 20%;
    margin-right: 20%;
}

.bottomText {
    float: right;
    border: 1px solid #fff;
    color: #3e87d3;
    font-weight: 500;
    font-size: 20px;
    font-family: "Red Hat Display", sans-serif;
    cursor: pointer;
}

.rememberMeText {
    padding-left: 2%;
    color: #271d7a;
    font-weight: 500;
    font-size: 18px;
    font-family: "Red Hat Display", sans-serif;
}

#login_welcome_text {
    font-weight: 500;
    font-family: "Red Hat Display", sans-serif;
    color: #271d7a;
    font-size: 30px;
    background-color: #ffffff;
}

#login_logo_image {
    max-width: 100%;
}

#login_section_heading {
    font-size: 28px;
    font-weight: 700;
    font-family: "Red Hat Display", sans-serif;
    color: #3e87d3;
    background-color: #fff;
}

.hideLoader {
    visibility: hidden;
}

.showLoader {
    visibility: visible;
}


.regularText {
    color: #271d7a;
    font-family: "Red Hat Display", sans-serif;
    font-weight: 400;
    font-size: 20px;
}


@media screen and (max-width: 960px) {
    .main_container {
        width: auto;
        margin-top: 10%;
        margin-right: 5%;
        margin-left: 5%;
        margin-bottom: 5%;
        padding-bottom: 5%;
        background-color: #fff;
    }
}


.buttonSizeOverride {
    width: 40%;
    height: 42px;
    font-size: 14pt;
    font-weight: 500;
}

.defaultContainerMargin {
    margin-top: 15% !important;
}

.TenPercentContainerMargin {
    margin-top: 10% !important;
}

.ZeroPercentContainerMargin {
    margin-top: 0% !important;
}




</style>