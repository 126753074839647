<template>
  <div class="flowsContainer">
    <div :style="{ height: PROPS.dynamicHeight - 100 + 'px' }">
      <TableComponent ref="AgentDashboardTable" :table_headers="headers" :table_data="agentData" :hide_footer="true"
        :sortBy="sortBy" :sortType="sortType" :actionControlBarActive="true" :searchableColumns="true">


        <!-- Right Side Table Actions Template -->
        <template v-slot:rightSideActions>
          <div>
            <span title="Switch Socket is Connecting" class="dot" style="background-color: yellow;"
              v-if="AgentSocketStatus == '0'"></span>
            <span title="Switch Socket is Connected" class="dot" style="background-color: green;"
              v-if="AgentSocketStatus == '1'"></span>
            <span title="Switch Socket is Closing" class="dot" style="background-color: orange;"
              v-if="AgentSocketStatus == '2'"></span>
            <span title="Switch Socket is Closed" class="dot" style="background-color: red;"
              v-if="AgentSocketStatus == '3'"></span>
          </div>
        </template>

        <!-- Header Templates -->
        <template #header-searchable_status>
          <!-- This Slot is here to make the column searchable -->
        </template>

        <template #item-searchable_status>
          <!-- This Slot is here to make the column searchable -->
        </template>

        <!-- Item Templates -->
        <template #item-status="item">
          <div style="padding-top: 5px; padding-bottom: 5px">
            <img :src="require(`@/assets/icons/${getColumnIcons('Status', item.status)[0]
              }`)
              " class="tableIcons" />
            <span v-html="item.status.first"></span>
          </div>
          <div style="padding-bottom: 5px;">
            <img :src="require(`@/assets/icons/${getColumnIcons('Status')[1]
              }`)
              " class="tableIcons" />
            <StopWatchComp :seconds="item.status.second" :status="item.status.first" />
          </div>
        </template>
        <template #item-outboundCalls="item">
          <div>
            <img :src="require(`@/assets/icons/${getColumnIcons('Outbound Calls')
              }`)
              " class="tableIcons" />
            <span v-html="item.outboundCalls"></span>
          </div>
        </template>
        <template #item-answeredCalls="item">
          <div>
            <img :src="require(`@/assets/icons/${getColumnIcons('Answered Calls')
              }`)
              " class="tableIcons" />
            <span v-html="item.answeredCalls"></span>
          </div>
        </template>
        <template #item-missedCalls="item">
          <div>
            <img :src="require(`@/assets/icons/${getColumnIcons('Missed Calls')
              }`)
              " class="tableIcons" />
            <span v-html="item.missedCalls"></span>
          </div>
        </template>
        <template #item-abandonedCalls="item">
          <div>
            <img :src="require(`@/assets/icons/${getColumnIcons('Abandoned Calls')
              }`)
              " class="tableIcons" />
            <span v-html="item.abandonedCalls"></span>
          </div>
        </template>
        <template #item-ticketsResolved="item">
          <div style="padding-bottom: 5px;">
            <img :src="require(`@/assets/icons/${getColumnIcons('Tickets Resolved')[0]
              }`)
              " class="tableIcons" />
            <span>{{ item.ticketsResolved.first }}</span>
          </div>
          <div>
            <img :src="require(`@/assets/icons/${getColumnIcons('Tickets Resolved')[1]
              }`)
              " class="tableIcons" />
            <span>{{ item.ticketsResolved.second }}</span>
          </div>
        </template>
        <template #item-ticketsEscalated="item">
          <div style="padding-bottom: 5px;">
            <img :src="require(`@/assets/icons/${getColumnIcons('Tickets Escalated')[0]
              }`)
              " class="tableIcons" />
            <span>{{ item.ticketsEscalated.first }}</span>
          </div>
          <div>
            <img :src="require(`@/assets/icons/${getColumnIcons('Tickets Escalated')[1]
              }`)
              " class="tableIcons" />
            <span>{{ item.ticketsEscalated.second }}</span>
          </div>
        </template>
      </TableComponent>
    </div>
  </div>
</template>



<script setup>
import TableComponent from "@/components/base_components/TableComponent.vue";
import { useAgentDashboardStore } from "@/stores/ContactCenter/Management/AgentDashboardStore.js";
import { ref, onMounted, computed, defineProps, onUnmounted } from 'vue';
import StopWatchComp from "@/components/CcManagement/StopWatchComp.vue"

//Props
const PROPS = defineProps({
  dynamicHeight: Number
});

//Constants
const AgentDashboardStore = useAgentDashboardStore();
const AgentSocket = ref(null);
const sortBy = "Agent Name";
const sortType = "asc";
const AgentSocketStatus = ref(3);
const AGENT_SOCKET_TIMER = ref(null);
const AgentDashboardTable = ref(null);

var headers = [
  { text: "Agent Name", value: "agentName", sortable: true },
  { text: "Status", value: "status" },
  { text: "searchable_status", value: "searchable_status", sortable: true },
  {
    text: "Calls during Current Shift",
    value: "callsCurrentShift",
  },
  {
    text: "Outbound Calls",
    value: "outboundCalls",
  },
  {
    text: "Answered Calls",
    value: "answeredCalls",
  },
  {
    text: "Missed Calls",
    value: "missedCalls",
  },
  {
    text: "Abandoned Calls",
    value: "abandonedCalls",
  },
  {
    text: "Tickets Resolved",
    value: "ticketsResolved"
  },
  {
    text: "Tickets Escalated",
    value: "ticketsEscalated"
  },
  {
    text: "Average Recovery Time",
    value: "averageRecoveryTime",
  },
];



//Computed
const agentData = computed(() => {
  return AgentDashboardStore.getAgentData
});

//Life Cycle Hooks
onMounted(async () => {
  AgentDashboardTable.value.toggleTableLoadingSpinner();

  AgentSocket.value = await AgentDashboardStore.initializeAgentSocket();
  AgentSocketStatus.value = AgentSocket.value.readyState;

  AGENT_SOCKET_TIMER.value = setInterval(async () => {
    AgentSocketStatus.value = AgentSocket.value.readyState;

    if (AgentSocketStatus.value === 3) {
      AgentSocket.value = await AgentDashboardStore.initializeAgentSocket();
    }
  }, 5000);


  try {
    await AgentDashboardStore.setAgentList();
    await setupAgentSocketListeners();
    AgentDashboardTable.value.toggleTableLoadingSpinner();
  }
  catch (err) {
    console.error("Error initializing Agent Dashboard data:", err);
  }
});

onUnmounted(async () => {
  AgentDashboardStore.resetAgentData();

  if (AgentSocket.value != null) {
    AgentSocket.value.close();
  }

  if (AGENT_SOCKET_TIMER.value != null) {
    clearInterval(AGENT_SOCKET_TIMER.value);
  }
});


//Functions
function getColumnIcons(header, value) {
  if (header === "Status") {
    let statusImg;
    if (value?.first === "Away") {
      statusImg = "High Priority.png";
    } else if (value?.first === "Ready") {
      statusImg = "Available.png";
    } else if (value?.first === "Logged Out") {
      statusImg = "Medium Priority.png";
    } else if (value?.first.includes("On-A-Call") || value?.first === "On-External-Call") {
      statusImg = "Urgent Priority.png";
    }
    else { //TODO: Need more images for different statuses
      statusImg = "High Priority.png";
    }
    return [statusImg, "Status Time.png"];
  } else if (
    header === "Outbound and Answered Calls" ||
    header === "Inbound and Outbound Calls"
  ) {
    return ["Outbound.png", "Inbound.png"];
  } else if (header === "Missed and Abandoned Calls") {
    return ["Missed.png", "Abandonded.png"];
  } else if (header === "Tickets Resolved") {
    return ["Created Tickets.png", "Resolved Tickets.png"];
  } else if (header === "Tickets Escalated") {
    return ["Internal.png", "External.png"];
  } else if (header === "Agents On Phone & Away") {
    return ["Urgent Priority.png", "High Priority.png"];
  } else if (header === "LogicomUSA & Overflow Tickets") {
    return ["LogicomUSA.png", "Overflow.png"];
  } else if (header === "Calls Waiting & Wait Time") {
    return ["callswaiting.png", "waittime_larger.png"];
  } else if (header === "Missed Calls") {
    return "Missed.png";
  } else if (header === "Abandoned Calls") {
    return "Abandonded.png";
  } else if (header === "Outbound Calls") {
    return "Outbound.png";
  } else if (header === "Answered Calls") {
    return "Inbound.png";
  }
  return null;
}

async function setupAgentSocketListeners() {
  try {
    AgentSocket.value.onmessage = async function (e) {
      var event = JSON.parse(e.data);

      if (event.name == "ready") {
        await AgentDashboardStore.handleAgentEvent(event);
      }
      else if (event.name == "away") {
        await AgentDashboardStore.handleAgentEvent(event);
      }
      else if (event.name == "wrapup_complete") {
        await AgentDashboardStore.handleAgentEvent(event);
      }
      else if (event.name == "wrapup_start") {
        await AgentDashboardStore.handleAgentEvent(event);
      }
      else if (event.name == "delivered") {
        await AgentDashboardStore.handleAgentEvent(event);
      }
      else if (event.name == "external_call_start") {
        await AgentDashboardStore.handleAgentEvent(event);
      }
      else if (event.name == "external_call_end") {
        await AgentDashboardStore.handleAgentEvent(event);
      }
      else if (event.name == "offer") {
        await AgentDashboardStore.handleAgentEvent(event);
      }
    }.bind(this);
  }
  catch (err) {
    console.log(err);
  }
}
</script>


<style scoped>
.flowsContainer {
  padding: 20px;
  width: 100%;
  background-color: #e2f1f4;
  height: 100%;
}

.tableIcons {
  width: 28px;
  padding-right: 15px;
}

.tableText {
  color: #271d7a;
  font-size: 12pt;
}

.dot {
  height: 25px;
  width: 25px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
}
</style>
