<template>
  <div class="editor-bulkupdate" v-if="editor">
    <Toolbar class="toolbar-bulkupdate" 
      :editor="editor" 
      :showColorWheel="showColorWheel"
    />
    <editor-content
      class="editor-content-bulk-panel"
      :editor="editor"
      :style="editorStyle"
    />
    <h1>{{ result }}</h1>
  </div>
</template>

<script>
import Highlight from "@tiptap/extension-highlight";
import TaskItem from "@tiptap/extension-task-item";
import TaskList from "@tiptap/extension-task-list";
import StarterKit from "@tiptap/starter-kit";
import Underline from "@tiptap/extension-underline";
import Placeholder from "@tiptap/extension-placeholder";
import Color from "@tiptap/extension-color";
import { Editor, EditorContent } from "@tiptap/vue-3";

import Toolbar from "./Toolbar.vue";
import TextStyle from "@tiptap/extension-text-style";

export default {
  name:'Questions_BulkPanel',
  components: {
    EditorContent,
    Toolbar,
  },

  data() {
    return {
      editor: null,
      result: null,
    };
  },
  props: {
    defaultColor: {
      type: String,
      default: '#000000' 
    },
    showColorWheel: {
      type: Boolean,
      default: false 
    },
    content: String,
  },
  computed: {
    editorStyle() {
      return {
        color: this.defaultColor
      };
    }
  },
  mounted() {
    this.editor = new Editor({
      extensions: [
        StarterKit,
        Highlight.configure({ multicolor: true }),
        TaskList,
        TaskItem,
        Underline,
        TextStyle,
        Placeholder.configure({
          placeholder: "Begin typing your message here...",
        }),
        Color.configure({
          types: ["textStyle"],
        }),
      ],
    });
    this.editor.on('update', ({editor}) => {
      this.$emit('update-value', editor.getHTML());
    });
    this.editor.commands.setContent(this.content);
  },

  methods: {
    handleCc(data) {
      console.log(data);
    },
  },
  watch: {
    content(newValue) {
      let html = this.editor.getHTML();
      if(html != newValue) {
        this.editor.commands.setContent(newValue);
      }
    }
  },

  beforeUnmount() {
    try {
      this.editor.destroy();
    } catch(error) {
      console.log(error);
    }
  },
};
</script>

<style scoped>
:deep(.editor-content-bulk-panel .ProseMirror) {
  height: 150px;
  padding: 1rem 2rem;
  font-size: 12pt;
  overflow: auto;
}

:deep(.ProseMirror:focus) {
  outline: none;
}

.sender-header.hide {
  display: none;
}

:deep(.editor-content-bulk-panel .ProseMirror p.is-editor-empty:first-child::before) {
  color: #b1cdea;
  content: attr(data-placeholder);
  float: left;
  font-size: 12pt;
  height: 0;
  pointer-events: none;
}

.editor-bulkupdate {
  outline: 0.25pt solid #271d7a;
  overflow: visible;
}

.subject-header {
  background-color: #396fba;
  color: #ffffff;
  font-size: 10pt;
  padding: 10px;
  display: flex;
  justify-content: space-between;
}

.sender-header {
  border-bottom: 0.25pt solid #dde9f4;
}

.toolbar-bulkupdate {
  border: 1px solid #dde9f4;
  background-color: #ecf2f8;
  padding-left: 1rem;
  padding-top: 5px;
  padding-bottom: 5px;
}

.bottom-toolbar-bulkupdate {
  background-color: #ecf2f8;
  border-top: 1px solid #dde9f4;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 30px;
}
</style>
