<template>
  <div class="leftNavTopBar">
    <div class="leftNavContent">
      <div class="drawer-left-control">
        <div
          v-for="(item, index) in leftAlignedButtons" 
          :key="index" 
        >
          <template v-if="item.title == 'drawer_left'">
            <LightButton
              :style="{ marginRight: '20px' }"
              :icon="item.iconOpen"
              :title="item.title"
              :active="false"
              @button-click="handleButtonClick"
            />
          </template>
        </div>
      </div>
      <div class="leftNavTitle">
        {{ title }}
      </div>
      <div class="leftAlignedButtons" :style="{ paddingLeft: leftPadding }">
        <div v-for="(item, index) in leftAlignedButtons" :key="index">
          <template v-if="item.title == 'AlternateOptions'">
            <AlternateOptions
              :options="item.options"
              :label="item.label"
              :default="selectedLayout"
              class="AlternateOptions"
              @input="handleLayoutChange"
            />
          </template>
          <template v-else-if="item.title == 'Search'">
            <SearchBar @search-value="handleSearchValue" />
          </template>
        </div>
      </div>
    </div>

    <slot></slot>

    <div class="rightAlignedButtons">
      <template v-for="(item, index) in rightAlignedButtons" :key="index">
        <template v-if="item.title == 'drawer'">
          <template v-if="drawerOpen">
            <LightButton
              :title="item.title"
              :icon="item.iconClose"
              @button-click="handleButtonClick"
              :active="true"
            />
          </template>
          <template v-else>
            <LightButton
              :title="item.title"
              :icon="item.iconOpen"
              @button-click="handleButtonClick"
              :active="false"
            />
          </template>
        </template>
        <template v-else-if="item.title == 'AlternateOptions'">
          <AlternateOptions
            :options="item.options"
            :label="item.label"
            :default="selectedLayout"
            class="AlternateOptions"
            @input="handleLayoutChange"
          />
        </template>
        <template v-else-if="item.title == 'Search'">
          <SearchBar
            @search-value="handleSearchValue"
            :style="{ marginTop: '-1px' }"
          />
        </template>
        <template v-else-if="item.title == 'Bookmark'">
          <template v-if="bookmarked">
            <img
              class="bookmarkButton"
              v-bind:src="require(`@/assets/icons/${item.iconOpen}`)"
              @click="handleButtonClick(item.title)"
              style="cursor: pointer"
            />
          </template>
          <template v-else>
            <img
              class="bookmarkButton"
              v-bind:src="require(`@/assets/icons/${item.iconClose}`)"
              @click="handleButtonClick(item.title)"
              style="cursor: pointer"
            />
          </template>
        </template>
        <template v-else-if="item.title == 'Status'">
          <slot name="status"></slot>
        </template>
        <template v-else>
          <LightButton
            :title="item.title"
            :icon="item.icon"
            @button-click="handleButtonClick"
          />
        </template>
      </template>
    </div>
  </div>
</template>

<script>
import AlternateOptions from "@/components/base_components/AlternateOptions.vue";
import LightButton from "@/components/base_components/Buttons/LightButton.vue";
import SearchBar from "@/components/base_components/SearchBar.vue";
export default {
  name: "SecondaryNavBarOverview",
  props: {
    title: String,
    drawerOpen: Boolean,
    selectedPage: String,
    selectedLayout: String,
    leftAlignedButtons: Array,
    rightAlignedButtons: Array,
    bookmarked: Boolean,
    leftPadding: { type: String, default: "0" },
  },
  methods: {
    handleButtonClick(title) {
      this.$emit("button-click", title);
    },
    handleLayoutChange(option) {
      this.$emit("layout-change", option);
    },
    handleSearchValue(search) {
      this.$emit("search-value", search);
    },
  },
  components: {
    LightButton,
    SearchBar,
    AlternateOptions,
  },
  emits: [
    "button-click",
    "layout-change",
    "search-value"
  ]
};
</script>
<style scoped>
.bookmarkButton {
  width: 15px;
  height: 23px;
  padding-top: 3px;
  margin-right: 5px;
}
.leftNavTopBar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 7px 3px 8px 10px;
  background-color: #f4fafa;
  color: #271d7a;
  font-weight: 600;
  font-size: 12pt;
  filter: drop-shadow(1px 1px 1px rgba(39, 29, 122, 0.2));
  position: relative;
  z-index: 7;
  height: 43px;
}

.leftNavTitle {
  color: #271d7a;
  font-weight: 500;
  font-size: 12pt;
}

.leftNavContent {
  display: flex;
}
.rightAlignedButtons {
  display: flex;
  gap: 10px;
  padding-right: 10px;
}

.leftAlignedButtons {
  display: flex;
  gap: 15px;
  padding-left: 15px;
}
.drawer-left-control .lightButtonText {
  display: none;
}
.drawer-left-control .lightButtonIcon {
  margin-right: 0;
}
</style>
