<template>
    <EasyDataTable
      table-class-name="customize-table"
      ref="dataTable"
      :headers="headers"
      :items="dataList"
      :rows-per-page="20"
      theme-color="#2e3e91"
      :style="dynamicWidth"
      hide-footer
      :filter-options="filterOptionsArray"
      :body-row-class-name="bodyRowColors"
      @click-row="handleClickRow"
    >
      
      <template #item-dynamicOptions="item">
        {{ item.dynamicOptions.map((obj) => obj.value).join(", ") }}</template
      >
      <!-- <template #item-checkbox="item">
        <CheckBox
          class="tableCheckbox"
          :item="item"
          :checked="getChecked(item)"
          :multiSelect="true"
          @toggle-selected="toggleSelected"
        />
      </template>  -->
      <template #item-message="item">
        <span
          >{{ getMessage(item) }} &nbsp;
          <span
            @click="handleExpand(item)"
            class="expand-link"
            v-if="JSON.stringify(expandedItem) == JSON.stringify(item)"
            >Collapse</span
          >
          <span
            @click="handleExpand(item)"
            class="expand-link"
            v-else-if="item.message.length > 50"
            >...</span
          ></span
        >&nbsp;
      </template>
      <template #item-subject="item">
        <div @click="getClickLogic(item)" class="clickableSubject">
          {{ item.subject }}
        </div>
      </template>
      <template #item-priority="item">
        <div class="priorityContainer">
          <img
            :src="require(`@/assets/icons/${item.priority} Priority.png`)"
            class="priorityColorImg"
          />
          {{ item.priority }}
        </div>
      </template>
      <template #item-created="item">
        <div class="priorityContainer">{{ item.created }} ago</div>
      </template>
      <template #item-updated="item">
        <div class="priorityContainer">{{ item.updated }} ago</div>
      </template>
      <!-- <template #header-checkbox="item" v-if="selectAll">
        <CheckBox
          class="headerCheckbox"
          :item="item"
          :checked="isSelected(item)"
          :multiSelect="true"
          @toggle-selected="toggleSelectAll"
        />
      </template> -->
      <template #header-edit>
        <img
          :src="require(`@/assets/icons/edit_white.png`)"
          class="headerEditIcon"
        />
      </template>
          <!-- <button class="item-edit-button" @click="$emit('edit-row', item)">
        <img :src="require(`@/assets/icons/Edit.png`)" class="headerEditIcon" />
      </button> -->
      <!-- <template #header-partners_label_staging="header">
        <div>
          <div style="width: 100%;">{{ header.text }}</div>
          <div>
            <DropDown
              class="partner_dropdown"
              default="All"
              :options="sortedQueues"
              @input="$emit('partner-selected', $event)"
              :sort_options=false
            />
          </div>
        </div>
        
      </template> -->
      <template #item-edithover="item">
        <div class="buttonWrapper">
          <DropDown
           :ref="item.id"
           :id="item"
           default="Select"
           :options="item.options"
           @input="option => handleSelect(option, item)"
           class="actionDropdown"
          />
          <DarkButton @click="performAction(item)">
            Submit
          </DarkButton>
        </div>
  
      </template>
    </EasyDataTable>
    <div class="customize-footer" :style="dynamicWidthFooter">
      <v-sheet class="d-flex mb-6" :style="{ backgroundColor: backgroundColor }">
        <v-sheet
          class="mt-2 pt-2 me-auto"
          :style="{ backgroundColor: backgroundColor }"
          ><div
            class="customize-index"
            :style="{ backgroundColor: backgroundColor }"
          >
            {{
              currentPageFirstIndex === 1 && currentPageLastIndex === 0
                ? 'No entries found'
                : `Showing ${currentPageFirstIndex} to ${currentPageLastIndex} of ${clientItemsLength} entries`
            }}
          </div></v-sheet
        >
        <v-sheet
          class="mt-2 pt-2 pr-2"
          :style="{ backgroundColor: backgroundColor }"
          ><div
            class="customize-buttons"
            :style="{ backgroundColor: backgroundColor }"
          >
            <span
              v-for="paginationNumber in paginationList"
              :key="{ paginationNumber }"
              class="customize-button"
              :class="{ pagActive: paginationNumber === currentPaginationNumber }"
              @click="updatePage(paginationNumber)"
            >
              <button class="px-2" :disabled="isNumberInvalid(paginationNumber)">
                {{ paginationNumber }}
              </button>
            </span>
          </div></v-sheet
        >
        <v-sheet
          class="pt-2 pr-1 pagList"
          :style="{ backgroundColor: backgroundColor }"
        >
          <div class="customize-pagination">
            <button @click="prevPage" :disabled="isFirstPage">
              <img
                class="tablePagButton"
                v-bind:src="require(`@/assets/icons/Table - back.png`)"
              />
            </button>
            <button class="" @click="nextPage" :disabled="isLastPage">
              <img
                class="tablePagButton"
                v-bind:src="require(`@/assets/icons/Table - next.png`)"
              />
            </button></div
        ></v-sheet>
      </v-sheet>
    </div>
  </template>
  
  <script setup>
  import { computed, ref } from "vue";
  //import CheckBox from "@/components/base_components/FormComponents/CheckBox.vue";
  import DropDown from "@/components/base_components/FormComponents/DropDown.vue";
  import DarkButton from "@/components/base_components/Buttons/DarkButton.vue";
  
  
  // $ref dataTable
  const dataTable = ref();
  
  const getPaginationList = (current, number) => {
    let result = [];
    if (number <= 5) {
      for (let i = 1; i <= number; i++) {
        result.push(i);
      }
    } else if (current <= 2 || number - current < 2) {
      for (let i = 1; i <= 3; i++) {
        result.push(i);
      }
      result.push("...");
      for (let i = number - 2; i <= number; i++) {
        result.push(i);
      }
    } else if (current < 5) {
      for (let i = 1; i <= 5; i++) {
        result.push(i);
      }
      result.push("...", number);
    } else if (number - current < 5) {
      result.push(1, "...");
      for (let i = number - 4; i <= number; i++) {
        result.push(i);
      }
    } else {
      result.push(1, "...");
      for (let i = current - 1; i <= current + 1; i++) {
        result.push(i);
      }
      result.push("...", number);
    }
  
    return result;
  };
  
  // index related
  const currentPageFirstIndex = computed(
    () => dataTable.value?.currentPageFirstIndex
  );
  const currentPageLastIndex = computed(
    () => dataTable.value?.currentPageLastIndex
  );
  const clientItemsLength = computed(() => dataTable.value?.clientItemsLength);
  
  const paginationList = computed(() =>
    getPaginationList(
      dataTable.value?.currentPaginationNumber,
      dataTable.value?.maxPaginationNumber
    )
  );
  const currentPaginationNumber = computed(
    () => dataTable.value?.currentPaginationNumber
  );
  
  const isFirstPage = computed(() => dataTable.value?.isFirstPage);
  const isLastPage = computed(() => dataTable.value?.isLastPage);
  
  const nextPage = () => {
    dataTable.value.nextPage();
  };
  const prevPage = () => {
    dataTable.value.prevPage();
  };
  const updatePage = (paginationNumber) => {
    dataTable.value.updatePage(paginationNumber);
  };
  </script>
  
  <script>
  export default {
    name: "TableComp",
    emits: [
      "select-actions",
      "selected-items",
      "selected-item",
      "edit-row",
      "navigate",
      "click-row",
    ],
    props: {
      headers: Array,
      dataList: Array,
      backgroundColor: String,
      tableBackgroundColor: { type: String, default: '#ffffff' },
      filterOptionsArray: Array,
      selectAll: Boolean,
      leftNavPresent: { type: Boolean, default: false },
      fixedWidth: { type: Boolean, default: false },
      clearSelectedItems: Boolean,
      clearSingleSelected: Boolean,
      singleSelect: Boolean,
      uniqueKey: String,
      currentView: String,
      partners: Array,
    },
      data: function () {
          return {
              selectedItems: [],
              singleSelected: null,
              expandedItem: null,
              selectedAction: "",
              minWidth: "350",
              maxWidth: "1200",
              windowWidth: window.innerWidth,
              clickTimeout: null,
              options: [
                  { label: "Review", value: "review" },
                  { label: "Edit", value: "edit" },
                  { label: "Publish", value: "publish" },
              ],
          };
      },
      watch: {
          windowWidth() {
              this.$nextTick(() => {
                  this.dynamicWidth;
              });
          },
          clearSelectedItems(newVal) {
              if (newVal) {
                  this.selectedItems = [];
              }
          },
          clearSingleSelected(newVal) {
              if (newVal) {
                  this.singleSelected = null;
                  this.$emit("selected-item", this.singleSelected);
              }
          },
          dataList: {
            handler(newValue) {
              if (newValue) {
                let span = document.querySelector("span.customize-button");
                if (span) {
                  let button = span.querySelector("button");
                  button.click();
                }
              }
            },
            deep: true
          },
      },
    computed: {
    //   sortedQueues() {
    //     var sideArray = [];
    //     var newArray = [];
    //     if(this.partners.length > 0) {
    //       for(var item of this.partners) {
    //         if(item.label == 'All') {
    //           newArray.push(item);
    //         } else {
    //           sideArray.push(item);
    //         }
    //       }
  
    //       sideArray = sideArray.sort((a,b) => a.label.localeCompare(b.label));
    //       newArray.push(...sideArray);
    //     }
  
    //     return newArray;
    //   },
      dynamicWidth() {
        if (this.fixedWidth) {
          return {};
        }
  
        const totalColumns = this.headers.length;
        let width;
  
        if (totalColumns < 3) {
          width = 300;
        } else {
          if (this.leftNavPresent) {
            width = Number(this.windowWidth) - 400;
          } else {
            width = Number(this.windowWidth) - 100;
          }
        }
        
        return { width: width + "px", backgroundColor: this.tableBackgroundColor };
      },
      dynamicWidthFooter() {
        if (this.fixedWidth) {
          return {};
        }
  
        const totalColumns = this.headers.length;
        let width;
  
        if (totalColumns < 3) {
          width = 500;
        } else {
          if (this.leftNavPresent) {
            width = Number(this.windowWidth) - 400;
          } else {
            width = Number(this.windowWidth) - 100;
          }
        }
        return { width: width + "px" };
      },
  
      isSelected() {
        return (item) => {
          return this.selectedItems.some((selItem) =>
            this.isEqual(selItem, item)
          );
        };
      },
      getMessage() {
        return (item) => {
          return JSON.stringify(this.expandedItem) == JSON.stringify(item)
            ? item.message
            : `${item.message.slice(0, 50)}`;
        };
      },
      getClickLogic() {
        return (item) => {
          if (this.currentView === "tickets") {
            this.$router.push(`/tickets/${item.ticketNumber}`);
          } else if (this.currentView === "LEX") {
            this.$emit("navigate", item, "history");
          }
        };
      },
    },
    mounted() {
      window.addEventListener("resize", this.handleResize);
    },
    beforeUnmount() {
      window.removeEventListener("resize", this.handleResize);
    },
      methods: {
          handleSelect(selectedOption, item) {
              // Directly set the selected action on the item
              item.selectedAction = selectedOption.value;
              // Vue 3 is reactive by default for such changes
          },

          performAction(item) {
              // Emit the action and the item to the parent
              this.$emit('select-action', { item, action: item.selectedAction });
          },
      isEqual(item1, item2) {
        const keys1 = Object.keys(item1);
        const keys2 = Object.keys(item2); //has added key value from datatable so subtract length by 1 to compare
  
        if (keys1.length !== keys2.length - 1 && keys1.length !== keys2.length) {
          return false;
        }
        for (let i = 0; i < keys1.length; i++) {
          const key = keys1[i];
          if (item1[key] !== item2[key]) {
            return false;
          }
        }
        return true;
      },
      handleClickRow (data, e) {
        if (e.detail === 2) {
          this.$emit('click-row', data);
        }
      },
      getChecked(item) {
        // If single select is desired behavior, you MUST pass in unique key to compare the object against!!!
        // the unique key is a unique column in the table (i.e in pricebooks table consisting of products, the unique column is productCode)
        if (this.singleSelect) {
          return this.singleSelected
            ? this.singleSelected[this.uniqueKey] === item[this.uniqueKey]
            : this.singleSelected === item;
        } else {
          return this.isSelected(item);
        }
      },
      handleExpand(item) {
        if (JSON.stringify(this.expandedItem) == JSON.stringify(item)) {
          this.expandedItem = null;
        } else {
          this.expandedItem = item;
        }
      },
      handleResize() {
        this.windowWidth = window.innerWidth;
      },
      toggleSelected(item) {
        if (this.singleSelect) {
          if (
            this.singleSelected &&
            this.singleSelected[this.uniqueKey] === item[this.uniqueKey]
          ) {
            this.singleSelected = null;
          } else {
            this.singleSelected = item;
          }
  
          this.$emit("selected-item", this.singleSelected);
        } else {
          const index = this.selectedItems.findIndex((selectedItem) => {
            return this.isEqual(selectedItem, item);
          });
  
          if (index !== -1) {
            this.selectedItems.splice(index, 1);
          } else {
            this.selectedItems.push(item);
          }
          this.$emit("selected-items", this.selectedItems);
        }
      },
      isNumberInvalid(num) {
        return isNaN(num);
      },
      toggleSelectAll() {
        this.dataList.forEach((item) => {
          const index = this.selectedItems.indexOf(item);
          if (index !== -1) {
            this.selectedItems.splice(index, 1);
          } else {
            this.selectedItems.push(item);
          }
        });
        this.$emit("selected-items", this.selectedItems);
      },
      bodyRowColors(item) {
        if (item.color === "dark blue") {
          return "darkBlue";
        } else if (item.color === "light blue") {
          return "lightBlue";
        } else if (item.color === "medium blue") {
          return "mediumBlue";
        } else if (item.color === "yellow") {
          return "yellow";
        } else if (item.color === "orange") {
          return "orange";
        }
      },
    },
    components: { DropDown, DarkButton},
  };
  </script>
  
  <style scoped>
  
  .partner_dropdown {
    min-width: 170px;
  }
  .buttonWrapper {
    display: flex;
  }
  .actionDropdown {
    margin-left: -8px;
    margin-right: 10px;
    min-width: 100px;
  }
  
  :deep(.actionDropdown > .items) {
    min-width: 100px;
    z-index: 10;
  }
  .lightBlue {
    --easy-table-body-row-background-color: #5fc0d3;
  }
  .darkBlue {
    --easy-table-body-row-background-color: #271d7a;
    --easy-table-body-row-font-color: #ffffff;
  }
  .mediumBlue {
    --easy-table-body-row-background-color: #3e87d3;
  }
  .white {
    --easy-table-body-row-background-color: #ffffff;
  }
  .yellow {
    --easy-table-body-row-background-color: #eee253;
  }
  .orange {
    --easy-table-body-row-background-color: #e87928;
  }
  .clickableSubject {
    font-weight: bold;
    cursor: pointer;
  }
  
  .tableCheckbox .headerCheckbox {
    text-align: center;
    width: 100%;
    padding-top: 3px;
    padding-left: 2px;
  }
  
  .tablePagButton {
    width: 32px;
    height: 32px;
    filter: drop-shadow(1px 1px 1px rgba(39, 29, 122, 0.2));
  }
  .pagList {
    margin-top: 3px;
  }
  .footerBackground {
    background-color: #e2f1f4;
  }
  
  .pagActive {
    background-color: #ffffff;
    filter: drop-shadow(1px 1px 1px rgba(39, 29, 122, 0.2));
    padding-top: 4px;
    padding-bottom: 4px;
  }
  .priorityColorImg {
    padding-right: 7px;
  }
  .headerEditIcon {
    width: 15px;
  }
  .priorityContainer {
    display: flex;
    align-items: center;
  }
  .customize-index {
    color: #271d7a;
  }
  .customize-buttons {
    color: #271d7a;
    font-size: 10pt;
    background-color: hsl(0, 0%, 100%);
  }
  .customize-footer {
    margin-left: auto;
    margin-right: auto;
  }
  
  @media (min-width: 1200px) {
    .customize-table {
      --easy-table-body-row-height: 20px;
      --easy-table-body-row-font-size: 10pt;
      --easy-table-header-font-size: 10pt;
      --easy-table-header-height: 40px;
    }
    .customize-index {
      font-size: 10pt;
    }
    .priorityColorImg {
      width: 20px;
      margin-bottom: 2px;
    }
  }
  
  @media (min-width: 992px) and (max-width: 1200px) {
    .customize-table {
      --easy-table-body-row-height: 15px;
      --easy-table-body-row-font-size: 9pt;
      --easy-table-header-font-size: 9pt;
      --easy-table-header-height: 40px;
    }
    .customize-index {
      font-size: 9pt;
    }
    .priorityColorImg {
      width: 18px;
    }
  }
  @media (min-width: 768px) and (max-width: 992px) {
    .customize-table {
      --easy-table-body-row-height: 10px;
      --easy-table-body-row-font-size: 8pt;
      --easy-table-header-font-size: 8pt;
      --easy-table-header-height: 35px;
    }
    .customize-index {
      font-size: 8pt;
    }
    .priorityColorImg {
      width: 15px;
    }
  }
  @media (max-width: 768px) {
    .customize-table {
      --easy-table-body-row-height: 10px;
      --easy-table-body-row-font-size: 7pt;
      --easy-table-header-font-size: 7pt;
      --easy-table-header-height: 30px;
    }
    .customize-index {
      font-size: 8pt;
    }
    .priorityColorImg {
      width: 15px;
      margin-bottom: 3px;
    }
  }
  .customize-table {
    margin-left: auto;
    margin-right: auto;
  
    max-height: 95%;
  
    filter: drop-shadow(1px 1px 1px rgba(39, 29, 122, 0.2));
  
    --easy-table-header-font-color: #ffffff;
    --easy-table-header-background-color: #5c95d6;
    --easy-table-header-item-padding: 8px 21px;
  
    --easy-table-body-row-font-color: #271d7a;
    --easy-table-body-row-background-color: #ffffff;
    --easy-table-body-row-hover-background-color: #eff4f9;
    --easy-table-row-border: 0.5pt solid #dde9f4;
  
    --easy-table-body-item-padding: 8px 21px;
  
    --easy-table-body-row-hover-font-color: #271d7a;
    --8bb50160: 20px !important;
  }
  
  .item-edit-button {
    padding-top: 5px;
    opacity: 0;
  }
  .customize-table tr:hover .item-edit-button {
    opacity: 1;
  }
  
  .expand-link {
    color: #271d7a;
    font-weight: bold;
    cursor: pointer;
  }
  .expand-link:hover {
    opacity: 0.7;
  }
  
  .customize-table :deep(thead) {
    z-index: 11;
  }
  </style>
  