<template>
  <button class="darkButton" :class="getStyle" @click="handleButtonClick">
    <slot name="left-icon"></slot>
    <slot>{{ title }}</slot>
    <slot name="right-icon"></slot>
  </button>
</template>

<script>
export default {
  name: "DarkButton",
  props: {
    title: String,
    width: String,
    disabled: Boolean,
  },
  methods: {
    handleButtonClick() {
      this.$emit("button-click", this.title);
    },
  },
  computed: {
    getStyle() {
      let className = "fitToText";
      if (this.width === "parent") {
        className = "fillParentWidth";
      }

      if (this.disabled) {
        className = className + " " + "disabled";
      }
      return className;
      
    },
  },
  emits: [
    "button-click"
  ]
};
</script>

<style scoped>
.darkButton {
  padding: 5px;
  background-color: #2b2d84;
  color: white !important;
  font-size: 10pt;
  font-weight: 600;
  filter: drop-shadow(1px 1px 1px rgba(39, 29, 122, 0.1));
  border-radius: 2px;
}

.fillParentWidth {
  width: 100% !important;
}
.fitToText {
  padding-left: 20px;
  padding-right: 20px;
}

.darkButton:hover {
  outline: #84b1e0 solid 1pt;
}

.darkButton:active {
  padding: 5px;
  background-color: #84b1e0;
  outline: #2b2d84 solid 0.25pt;
  padding-left: 20px;
  padding-right: 20px;
  font-size: 10pt;
  font-weight: 600;
  box-shadow: 0 0 4px rgba(57, 111, 186, 0.6) inset;
  filter: none;
  color: white;
}

.darkButton.disabled {
  opacity: 0.5;
  pointer-events: none;
}
</style>
