import { defineStore } from 'pinia'
import { API } from 'aws-amplify';
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api";
import { useGeneralStore } from '@/stores/generalStore.js';
import * as queries from '@/graphql/queries.js';
import * as mutations from '@/graphql/mutations.js';


export const useStatusNotificationStore = defineStore('StatusNotification', {
    state: () => ({
        notifications: [],
        has_unread_notifications: false,
        tracked_systems: [],
    }),
    getters: {
        getNotifications(state) {
            return state.notifications;
        },
        getHasUnreadNotifications(state) {
            return state.has_unread_notifications;
        },
        getTrackedSystems(state) {
            return state.tracked_systems;
        }
    },
    actions: {
        compareDates(dateStr1, dateStr2) {
            const date1 = new Date(dateStr1);
            const date2 = new Date(dateStr2);
            return date1 - date2;
        },
        async getInitialUserNotifications(user_id) {
            try {
                var hasData = true;
                var nextToken = null;
                var notificationsData = [];

                while (hasData) {
                    var options = {
                        query: queries.listSiteNotifications,
                        variables: {
                            filter: {
                                user_id: {
                                    eq: user_id
                                },
                                _deleted: {
                                    ne: true
                                }
                            },
                            limit: 2000
                        },
                        authMode: GRAPHQL_AUTH_MODE.API_KEY
                    }

                    if (nextToken != null) {
                        options.variables.nextToken = nextToken;
                    }

                    var notificationsResult = await API.graphql(options);

                    if (notificationsResult.data.listSiteNotifications.nextToken != null) {
                        nextToken = notificationsResult.data.listSiteNotifications.nextToken;

                        for (const item of notificationsResult.data.listSiteNotifications.items) {
                            notificationsData.push(item);
                        }
                    }
                    else {
                        for (const item of notificationsResult.data.listSiteNotifications.items) {
                            notificationsData.push(item);
                        }
                        hasData = false;
                    }
                }

                notificationsData.sort((a, b) => this.compareDates(b.createdAt, a.createdAt));
                this.notifications = notificationsData;


                const unreadNotificationIndex = this.notifications.findIndex(notification => notification.marked_as_read == false);
                if (unreadNotificationIndex != -1) {
                    this.has_unread_notifications = true;
                }

            }
            catch (error) {
                alert(error)
            }
        },
        handleNewNotification(notification) {
            this.notifications.push(notification.value.data.onCreateSiteNotifications);
            this.notifications.sort((a, b) => this.compareDates(b.createdAt, a.createdAt));
            this.has_unread_notifications = true;
        },
        async createNotification(submissionData) {
            const generalStore = useGeneralStore();


            try {
                var hasData = true;
                var nextToken = null;
                var usersData = [];

                while (hasData) {
                    var options = {
                        query: queries.listUsers,
                        variables: {
                            limit: 2000
                        },
                        authMode: GRAPHQL_AUTH_MODE.API_KEY
                    }

                    if (nextToken != null) {
                        options.variables.nextToken = nextToken;
                    }

                    var usersResult = await API.graphql(options);

                    if (usersResult.data.listUsers.nextToken != null) {
                        nextToken = usersResult.data.listUsers.nextToken;

                        for (const item of usersResult.data.listUsers.items) {
                            usersData.push(item);
                        }
                    }
                    else {
                        for (const item of usersResult.data.listUsers.items) {
                            usersData.push(item);
                        }
                        hasData = false;
                    }
                }

                await Promise.all(usersData.map(async (user) => {
                    var users_subscribed_systems = JSON.parse(user.subscribed_systems);
                    var onlyMaintenanceMessages = user.maintenanceMessages;
                    var emailNotifications = user.emailNotifications;
                    var to = "";
                    var from = "";
                    var subject = "";
                    var body = "";

                    var trackedSystemIndex = -1;
                    if (users_subscribed_systems) {
                        trackedSystemIndex = users_subscribed_systems.findIndex(trackedSystem => trackedSystem.systemName == submissionData.trackedSystem.value);
                    }
                    if (users_subscribed_systems && trackedSystemIndex != -1) {
                        if (onlyMaintenanceMessages == "Y") {
                            if (submissionData.notificationType.value == "Maintenance Update") {
                                options = {
                                    query: mutations.createSiteNotifications,
                                    variables: {
                                        input: {
                                            user_id: user.id,
                                            content: submissionData.message,
                                            status: submissionData?.status ? submissionData.status.value : "",
                                            title: "New Notification",
                                            tracked_system: submissionData.trackedSystem.value,
                                            type: submissionData.notificationType.value,
                                            marked_as_read: false
                                        }
                                    },
                                    authMode: GRAPHQL_AUTH_MODE.API_KEY
                                }
                                await API.graphql(options);

                                if (emailNotifications == "Y") {
                                    to = user.username;
                                    from = "no-reply@lexconnect.cloud";
                                    subject = `${submissionData.trackedSystem.value}: ${submissionData.notificationType.value} - New Notification`;
                                    
                                    if (submissionData.notificationType.value == "Post") {
                                        body += `Status: ${submissionData.status.value}<br><br>`;
                                    }
                                    
                                    body += submissionData.message;
                                    generalStore.sendMail(to, from, subject, body)
                                }
                            }
                            else {
                                return;
                            }
                        }
                        else {
                            options = {
                                query: mutations.createSiteNotifications,
                                variables: {
                                    input: {
                                        user_id: user.id,
                                        content: submissionData.message,
                                        status: submissionData?.status ? submissionData.status.value : "",
                                        title: "New Notification",
                                        tracked_system: submissionData.trackedSystem.value,
                                        type: submissionData.notificationType.value,
                                        marked_as_read: false
                                    }
                                },
                                authMode: GRAPHQL_AUTH_MODE.API_KEY
                            }
        
                            await API.graphql(options);

                            if (emailNotifications == "Y") {
                                to = user.username;
                                from = "no-reply@lexconnect.cloud";
                                subject = `${submissionData.trackedSystem.value}: ${submissionData.notificationType.value} - New Notification`;
                                
                                if (submissionData.notificationType.value == "Post") {
                                    body += `Status: ${submissionData.status.value}<br><br>`;
                                }
                                
                                body += submissionData.message;
                                generalStore.sendMail(to, from, subject, body)
                            }
                        }
                    }
                    else {
                        return;
                    }
                }));
            }
            catch (error) {
                console.log(error);
            }
        },
        async markNotificationAsRead(notification) {

            if (!notification.marked_as_read) {
                var options = {
                    query: mutations.updateSiteNotifications,
                    variables: {
                        input: {
                            id: notification.id,
                            marked_as_read: true,
                            _version: notification._version
                        },
                        limit: 2000
                    },
                    authMode: GRAPHQL_AUTH_MODE.API_KEY
                }
                await API.graphql(options);

                const openedNotificationIndex = this.notifications.findIndex(notifObject => notifObject.id == notification.id);
                if (openedNotificationIndex != -1) {
                    this.notifications[openedNotificationIndex].marked_as_read = true;
                    this.notifications[openedNotificationIndex]._version = this.notifications[openedNotificationIndex]._version + 1;
                }

                //Check Again if there are any unread notifications still
                // this.has_unread_notifications = false;
                const unreadNotificationIndex = this.notifications.findIndex(notification => notification.marked_as_read == false);
                if (unreadNotificationIndex != -1) {
                    this.has_unread_notifications = true;
                }
                else {
                    this.has_unread_notifications = false;
                }
            }
        },
        async deleteNotification(notification) {

            const deletedNotificationIndex = this.notifications.findIndex(notifObject => notifObject.id == notification.id);

            if (deletedNotificationIndex != -1) {
                this.notifications.splice(deletedNotificationIndex, 1);
            }

            var options = {
                query: mutations.deleteSiteNotifications,
                variables: {
                    input: {
                        id: notification.id,
                        _version: notification._version
                    },
                    limit: 2000
                },
                authMode: GRAPHQL_AUTH_MODE.API_KEY
            }

            await API.graphql(options);
        },
        async getTrackableSystems() {
            try {
                var hasData = true;
                var nextToken = null;
                var trackedSystemData = [];

                while (hasData) {
                    var options = {
                        query: queries.listTrackableSystems,
                        variables: {
                            limit: 2000
                        },
                        authMode: GRAPHQL_AUTH_MODE.API_KEY
                    }

                    if (nextToken != null) {
                        options.variables.nextToken = nextToken;
                    }

                    var trackedSystemResult = await API.graphql(options);

                    if (trackedSystemResult.data.listTrackableSystems.nextToken != null) {
                        nextToken = trackedSystemResult.data.listTrackableSystems.nextToken;

                        for (const item of trackedSystemResult.data.listTrackableSystems.items) {
                            trackedSystemData.push(item);
                        }
                    }
                    else {
                        for (const item of trackedSystemResult.data.listTrackableSystems.items) {
                            trackedSystemData.push(item);
                        }
                        hasData = false;
                    }
                }


                for (var system of trackedSystemData) {
                    this.tracked_systems.push({
                        label: system.trackedItem,
                        value: system.trackedItem
                    });
                }

            }
            catch(error) {
                console.log(error);
            }
        }
    }
});