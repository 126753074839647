<template>
  <h1 class="header">Ticket Escalations</h1>
  <VerticalBarChart
    v-if="chartData.length > 0"
    :style="{ paddingLeft: '10px' }"
    :queue="queue"
    :chartData="chartData"
  />
</template>

<script>
import VerticalBarChart from "./VerticalBarChart.vue";
export default {
  name: "dashboardCard",
  props: {
    queue: Object,
  },
  data() {
    return {
      chartData: [],
    }
  },
  components: { VerticalBarChart },
  mounted() {

    console.log(this.queue);
    this.chartData = [
        {
          label: "Internal",
          color: "#3e87d3",
          data: this.queue.ticketsEscalated.first,
        },
        {
          label: "External",
          color: "#271d7a",
          data: this.queue.ticketsEscalated.second,
        },
        {
          label: "CSR",
          color: "#add38d",
          data: this.queue.csrEscalations,
        },
      ];

    console.log(this.chartData);
  }
};
</script>
<style scoped>
.header {
  padding: 10px 15px 0px 15px;
  font-size: 11pt;
  color: #271d7a;
}
</style>
