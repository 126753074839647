<template>
  <div>
    <Doughnut
      :data="chartDataQueue"
      :options="chartOptions"
      :plugins="[centerText]"
      :width="160"
      :height="160"
      @hover="handleHover"
    ></Doughnut>
  </div>
</template>
<script setup>
import { Doughnut } from "vue-chartjs";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";

ChartJS.register(ArcElement, Tooltip, Legend);
</script>

<script>
export default {
  name: "DoughnutChart",
  props: {
    queue: Object,
  },
  components: { Doughnut },
  methods: {
    handleHover() {
      // TODO: what needs to be done here besides a console.log? 
      console.log("hi");
    },
  },
  data() {
    return {
      chartOptions: {
        responsive: false,
        maintainAspectRatio: false,
        cutout: "85%",
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            enabled: false,
          },
        },
      },
      centerText: {
        id: "centerText",
        afterDatasetsDraw(chart) {
          const {
            ctx,
            chartArea: { top, width, height },
          } = chart;

          let dataset = chart.data.datasets[0].data;
          let activeIndex = chart.getActiveElements()[0]?.index;

          let activeData = dataset[activeIndex]
            ? dataset[activeIndex] + " %"
            : "";
          let activeLabel = chart.data.labels[activeIndex] || "";

          ctx.font = "500 26pt Red Hat Display";
          ctx.fillStyle = "rgba(39, 29, 122, 1)";
          ctx.textAlign = "center";
          ctx.fillText(activeData, width / 2, height / 2 + top);

          const maxWidthLabel = 125;
          let yLabel = height / 2 + top + 22;
          let wordsLabel = activeLabel.split(' ');
          let lineLabel = '';

          ctx.font = "12pt Red Hat Display";

          for (let i = 0; i < wordsLabel.length; i++) {
            const testLineLabel = lineLabel + wordsLabel[i] + ' ';
            const testWidthLabel = ctx.measureText(testLineLabel).width;

            if (testWidthLabel > maxWidthLabel && i > 0) {
              ctx.fillText(lineLabel, width / 2, yLabel);
              lineLabel = wordsLabel[i] + ' ';
              yLabel += 20;
            } else {
              lineLabel = testLineLabel;
            }
          }
          ctx.fillText(lineLabel, width / 2, yLabel);

          ctx.save();
        },
      },
    };
  },
  computed: {
    chartDataQueue() {
      var sum = parseFloat(this.queue.answeredCalls) + parseFloat(this.queue.missedAndAbandonedCalls.first) + parseFloat(this.queue.missedAndAbandonedCalls.second) + parseFloat(this.queue.callsHandedToOverflow);
      var answeredCalls = Math.round((parseFloat(this.queue.answeredCalls) / sum) * 100);
      var missedCalls = Math.round((parseFloat(this.queue.missedAndAbandonedCalls.first) / sum) * 100);
      var abandonedCalls = Math.round((parseFloat(this.queue.missedAndAbandonedCalls.second) / sum) * 100);
      var handedToOverflow = Math.round((parseFloat(this.queue.callsHandedToOverflow) / sum) * 100);

      return {
        labels: [
          "Answered Calls",
          "Missed Calls",
          "Abandoned Calls",
          "Calls Handed to Overflow",
        ],
        datasets: [
          {
            backgroundColor: ["#add38d", "#e87928", "#eade53", "#271d7a"],
            data: [
              answeredCalls,
              missedCalls,
              abandonedCalls,
              handedToOverflow
            ],
            hoverBackgroundColor: ["#add38d", "#e87928", "#eade53", "#271d7a"],

            hoverBorderColor: "#271d7a",
            hoverBorderWidth: 1,
          },
        ],

        hoverOffset: 4,
      };
    },
  },
};
</script>
<style scoped></style>
