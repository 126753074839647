<template>
  <div class="file-upload-wrapper">
    <input type="file" ref="fileInput" class="file-input" @change="onFileSelected" />
    <button class="custom-file-upload" @click="handleButtonClick">{{ buttonText }}</button>
    <p class="selected-file-text" v-if="selectedFile">Selected file: {{ selectedFile.name }}</p>
  </div>
</template>

<script>
export default {
  name: "ClickableMediumBlueButton",
  data() {
    return {
      selectedFile: null,
    };
  },
  computed: {
    buttonText() {
      return this.selectedFile ? "Change File" : "Upload File";
    },
  },
  methods: {
    onFileSelected() {
      this.selectedFile = this.$refs.fileInput.files[0] || null;
      this.$emit("file-selected", this.selectedFile);
    },
    handleButtonClick(event) {
      // Trigger the input file dialog when the button is clicked
      event.preventDefault();
      this.$refs.fileInput.click();
    },
    resetFileInput() {
      this.selectedFile = null;
      // Clear the selected file in the input element
      this.$refs.fileInput.value = "";
    },
  },
  emits: [
    "file-selected"
  ]
};
</script>

<style scoped>
/* You can style the button as you like using CSS */
.file-upload-wrapper {
  position: relative;
  display: inline-block;
}

.file-input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
  z-index: 1;
}
.selected-file-text {
  font-size: 9pt;
  font-weight: 600;
  color: #271d7a;
  padding-top: 5px;
  padding-bottom: 5px;
}
.custom-file-upload {
  padding: 5px 20px 5px 20px;
  background-color: #3e87d3;
  font-size: 10pt;
  font-weight: 600;
  filter: drop-shadow(1px 1px 1px rgba(39, 29, 122, 0.1));
  color: white;
  border-radius: 2px;
}
</style>
  
  