import { defineStore } from 'pinia'
import { useGeneralStore } from '@/stores/generalStore.js'
import { API } from "aws-amplify";
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api";

export const useAccountNotificationStore = defineStore('AccountNotification', {
    dependencies: [
        useGeneralStore
    ],
    state: () => ({
        currentQueueInformationValues: {},
        partnerOptions: [],
        notAllowedArray: ["FirstCall", "Legacy", "PRINCETON", "Sandbox", "SoundCom", "Wholesale", "LogicomUSA"],
        currentInformationType: '',
        currentPartner: ''
    }), 
    getters: {
        getPartnerOptions(state) {
            return state.partnerOptions
        },
        getInformationType(state) {
            return state.currentInformationType
        },
        getPartner(state) {
            return state.currentPartner
        }
    },
    actions: {
        getSpecificInformation(partner = this.currentPartner, type = this.currentInformationType) {
            try {
                if(Object.keys(this.currentQueueInformationValues).indexOf(partner) == -1) {
                    return {error: true, message: `Information Not Available for Selected Partner`};    
                }
                var information = this.currentQueueInformationValues[partner][type];    
                return {error: false, message: information || ''};
            } catch(error) {
                return {error: true, message: 'Unable to Fetch Current Information'}
            }
        },
        async fetchData() {
            var error_message = 'Unable to Get Information';
            try {
                const generalStore = useGeneralStore();
                var partnerResults = await generalStore.fetchSomePartners({notAllowedArray: this.notAllowedArray});
                if(partnerResults.error) {
                    return {error: true, message: error_message};
                }

                var allowedPartners = Object.keys(await generalStore.getPartnerInformation);
                if(allowedPartners.length == 0) {
                    return {error: true, message: error_message};
                }
                var filterObj = {
                    or:[],
                    _deleted: {ne: true}
                }
                for(var item of allowedPartners) {
                    filterObj["or"].push({"queue": {'eq': item}});
                }

                filterObj["or"].push({"queue": {'eq': 'Default'}});
                filterObj["or"].push({"queue": {'eq': 'Retail Sites'}});
                
                var hasData = true;
                var nextToken = null;
                var queuesData = [];

                var listQuery = `query ListQueues(
                    $filter: ModelQueuesFilterInput
                    $limit: Int
                    $nextToken: String
                  ) {
                    listQueues(filter: $filter, limit: $limit, nextToken: $nextToken) {
                      items {
                        id
                        queue
                        queue_name
                        contact_information
                        overflow_textbox_information
                        ticket_subject_values
                        tools_list
                        _version
                      }
                      nextToken
                      startedAt
                    }
                  }
                `;

                

                while (hasData) {
                    var options = {
                        query: listQuery,
                        variables: {
                            filter: filterObj,
                            limit: 2000
                        },
                        authMode: GRAPHQL_AUTH_MODE.API_KEY
                    }

                    if (nextToken != null) {
                        options.variables.nextToken = nextToken;
                    }

                    var queues = await API.graphql(options);

                    for (const item of queues.data.listQueues.items) {
                        queuesData.push(item);
                    }

                    if (queues.data.listQueues.nextToken != null) {
                        nextToken = queues.data.listQueues.nextToken;
                    }
                    else {
                        hasData = false;
                    }
                } 
                this.partnerOptions = [];
                var unsortedPartnerOptions = [];
                for(let queueObj of queuesData) {
                    this.currentQueueInformationValues[queueObj.queue] = queueObj;
                    unsortedPartnerOptions.push({label: queueObj.queue_name, value: queueObj.queue});
                }

                unsortedPartnerOptions.sort((a, b) => {
                    const nameA = a.label.toLowerCase();
                    const nameB = b.label.toLowerCase();
                  
                    if (nameA < nameB) {
                      return -1;
                    }
                    if (nameA > nameB) {
                      return 1;
                    }
                    return 0;
                });

                this.partnerOptions = [...unsortedPartnerOptions];
                return {error: false, message: 'Ok'};
            }
            catch (error) {
                return {error: true, message: error_message};
            }
        },
        async saveData(fields = {}) {
            var error_message = "Information didn't save.";
            
            if(fields.partner == "" || typeof fields.partner == undefined) {
                return {error: true, message: error_message};
            }

            try {
                if(Object.keys(this.currentQueueInformationValues).indexOf(fields.partner) == -1) {
                    return {error: true, message: error_message};    
                }

                var record_id = this.currentQueueInformationValues[fields.partner]["id"];
                var record_version = this.currentQueueInformationValues[fields.partner]["_version"];


                if(record_id == "" || typeof record_id == undefined || record_version == "" || typeof record_version == undefined) {
                    return {error: true, message: error_message};
                }

                var updateMutation = /* GraphQL */ `
                    mutation UpdateQueues($input: UpdateQueuesInput!) {
                        updateQueues(input: $input) {
                            id
                            queue
                            queue_name
                            contact_information
                            overflow_textbox_information
                            tools_list
                            ticket_subject_values
                            _version
                        }
                    }
              `;

                var options = {
                    query: updateMutation,
                    variables: {
                        input: {
                            _version: record_version,
                            id: record_id
                        }
                    },
                    authMode: GRAPHQL_AUTH_MODE.API_KEY
                }

                if(Object.prototype.hasOwnProperty.call(fields, 'contact_information')) {
                    options.variables.input['contact_information'] = fields.contact_information;
                }

                if(Object.prototype.hasOwnProperty.call(fields, 'overflow_textbox_information')) {
                    options.variables.input['overflow_textbox_information'] = fields.overflow_textbox_information;
                }

                if(Object.prototype.hasOwnProperty.call(fields,'ticket_subject_values')) {
                    options.variables.input['ticket_subject_values'] = fields.ticket_subject_values;
                }

                if(Object.prototype.hasOwnProperty.call(fields,'tools_list')) {
                    options.variables.input['tools_list'] = fields.tools_list;
                }
                
                var result = await API.graphql(options);
                if (result.errors) {
                    return {error: true, message: error_message};
                }

                this.currentQueueInformationValues = this.fetchData();

                return {error: false, message: 'Information Updated'};
                
            }
            catch (error) {
                return {'error': true, message: error};
            }
        }
    },
})

