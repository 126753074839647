/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getNonLower48TFRates = /* GraphQL */ `
  query GetNonLower48TFRates($id: ID!) {
    getNonLower48TFRates(id: $id) {
      id
      usage_plan_group
      description
      retail_rate
      wholesale_rate
      country
      area_code
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listNonLower48TFRates = /* GraphQL */ `
  query ListNonLower48TFRates(
    $filter: ModelNonLower48TFRatesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNonLower48TFRates(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        usage_plan_group
        description
        retail_rate
        wholesale_rate
        country
        area_code
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncNonLower48TFRates = /* GraphQL */ `
  query SyncNonLower48TFRates(
    $filter: ModelNonLower48TFRatesFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncNonLower48TFRates(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        usage_plan_group
        description
        retail_rate
        wholesale_rate
        country
        area_code
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const nonLower48TFRatesByUsage_plan_group = /* GraphQL */ `
  query NonLower48TFRatesByUsage_plan_group(
    $usage_plan_group: String!
    $sortDirection: ModelSortDirection
    $filter: ModelNonLower48TFRatesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    nonLower48TFRatesByUsage_plan_group(
      usage_plan_group: $usage_plan_group
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        usage_plan_group
        description
        retail_rate
        wholesale_rate
        country
        area_code
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getApplicationSections = /* GraphQL */ `
  query GetApplicationSections($id: ID!) {
    getApplicationSections(id: $id) {
      id
      icon
      title
      menuItems
      route
      allowed_groups
      order
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listApplicationSections = /* GraphQL */ `
  query ListApplicationSections(
    $filter: ModelApplicationSectionsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listApplicationSections(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        icon
        title
        menuItems
        route
        allowed_groups
        order
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncApplicationSections = /* GraphQL */ `
  query SyncApplicationSections(
    $filter: ModelApplicationSectionsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncApplicationSections(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        icon
        title
        menuItems
        route
        allowed_groups
        order
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getAnalytics = /* GraphQL */ `
  query GetAnalytics($id: ID!) {
    getAnalytics(id: $id) {
      id
      name
      category
      created_by
      attributes
      availability
      report_layout
      report_objects {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listAnalytics = /* GraphQL */ `
  query ListAnalytics(
    $filter: ModelAnalyticsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAnalytics(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        category
        created_by
        attributes
        availability
        report_layout
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncAnalytics = /* GraphQL */ `
  query SyncAnalytics(
    $filter: ModelAnalyticsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncAnalytics(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        category
        created_by
        attributes
        availability
        report_layout
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getAnalyticReportObjects = /* GraphQL */ `
  query GetAnalyticReportObjects($id: ID!) {
    getAnalyticReportObjects(id: $id) {
      id
      analytic_id
      title
      table
      chart
      data_source
      data_query
      data_filter
      order
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listAnalyticReportObjects = /* GraphQL */ `
  query ListAnalyticReportObjects(
    $filter: ModelAnalyticReportObjectsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAnalyticReportObjects(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        analytic_id
        title
        table
        chart
        data_source
        data_query
        data_filter
        order
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncAnalyticReportObjects = /* GraphQL */ `
  query SyncAnalyticReportObjects(
    $filter: ModelAnalyticReportObjectsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncAnalyticReportObjects(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        analytic_id
        title
        table
        chart
        data_source
        data_query
        data_filter
        order
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const analyticReportObjectsByAnalytic_id = /* GraphQL */ `
  query AnalyticReportObjectsByAnalytic_id(
    $analytic_id: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelAnalyticReportObjectsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    analyticReportObjectsByAnalytic_id(
      analytic_id: $analytic_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        analytic_id
        title
        table
        chart
        data_source
        data_query
        data_filter
        order
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getDisconnectRequests = /* GraphQL */ `
  query GetDisconnectRequests($id: ID!) {
    getDisconnectRequests(id: $id) {
      id
      service
      status
      disconnect_date
      requester
      comment
      full_disconnect
      account_number
      partner
      phone_numbers
      ticket_number
      port_out
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listDisconnectRequests = /* GraphQL */ `
  query ListDisconnectRequests(
    $filter: ModelDisconnectRequestsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDisconnectRequests(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        service
        status
        disconnect_date
        requester
        comment
        full_disconnect
        account_number
        partner
        phone_numbers
        ticket_number
        port_out
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncDisconnectRequests = /* GraphQL */ `
  query SyncDisconnectRequests(
    $filter: ModelDisconnectRequestsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncDisconnectRequests(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        service
        status
        disconnect_date
        requester
        comment
        full_disconnect
        account_number
        partner
        phone_numbers
        ticket_number
        port_out
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const disconnectRequestsByAccount_numberAndPartner = /* GraphQL */ `
  query DisconnectRequestsByAccount_numberAndPartner(
    $account_number: String!
    $partner: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDisconnectRequestsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    disconnectRequestsByAccount_numberAndPartner(
      account_number: $account_number
      partner: $partner
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        service
        status
        disconnect_date
        requester
        comment
        full_disconnect
        account_number
        partner
        phone_numbers
        ticket_number
        port_out
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const searchDisconnectRequests = /* GraphQL */ `
  query SearchDisconnectRequests(
    $filter: SearchableDisconnectRequestsFilterInput
    $sort: [SearchableDisconnectRequestsSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableDisconnectRequestsAggregationInput]
  ) {
    searchDisconnectRequests(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        service
        status
        disconnect_date
        requester
        comment
        full_disconnect
        account_number
        partner
        phone_numbers
        ticket_number
        port_out
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
              __typename
            }
          }
        }
        __typename
      }
      __typename
    }
  }
`;
export const getDisconnectEmailRecipient = /* GraphQL */ `
  query GetDisconnectEmailRecipient($id: ID!) {
    getDisconnectEmailRecipient(id: $id) {
      id
      partner
      request_notification_email
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listDisconnectEmailRecipients = /* GraphQL */ `
  query ListDisconnectEmailRecipients(
    $filter: ModelDisconnectEmailRecipientFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDisconnectEmailRecipients(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        partner
        request_notification_email
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncDisconnectEmailRecipients = /* GraphQL */ `
  query SyncDisconnectEmailRecipients(
    $filter: ModelDisconnectEmailRecipientFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncDisconnectEmailRecipients(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        partner
        request_notification_email
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getSegraNumbers = /* GraphQL */ `
  query GetSegraNumbers($id: ID!) {
    getSegraNumbers(id: $id) {
      id
      number
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listSegraNumbers = /* GraphQL */ `
  query ListSegraNumbers(
    $filter: ModelSegraNumbersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSegraNumbers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        number
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncSegraNumbers = /* GraphQL */ `
  query SyncSegraNumbers(
    $filter: ModelSegraNumbersFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncSegraNumbers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        number
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getUpdateRequests = /* GraphQL */ `
  query GetUpdateRequests($id: ID!) {
    getUpdateRequests(id: $id) {
      id
      account_number
      accountStatus
      customerName
      custType
      connDate
      servDesc
      status
      monthlyRate
      name
      first_name
      last_name
      email
      address1
      city
      state
      zip
      E911Address
      E911City
      E911State
      E911Zip
      telephone
      DataPackage
      VoicePackages
      VideoPackage
      FaxPackage
      transferFlag
      new_phone1
      sipPassword1
      old_account_number
      provisioned
      in_process
      errors
      PIN
      isManagedService
      created_on
      billingFlag
      taxFlag
      accessSFP
      billable
      isXML
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listUpdateRequests = /* GraphQL */ `
  query ListUpdateRequests(
    $filter: ModelUpdateRequestsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUpdateRequests(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        account_number
        accountStatus
        customerName
        custType
        connDate
        servDesc
        status
        monthlyRate
        name
        first_name
        last_name
        email
        address1
        city
        state
        zip
        E911Address
        E911City
        E911State
        E911Zip
        telephone
        DataPackage
        VoicePackages
        VideoPackage
        FaxPackage
        transferFlag
        new_phone1
        sipPassword1
        old_account_number
        provisioned
        in_process
        errors
        PIN
        isManagedService
        created_on
        billingFlag
        taxFlag
        accessSFP
        billable
        isXML
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncUpdateRequests = /* GraphQL */ `
  query SyncUpdateRequests(
    $filter: ModelUpdateRequestsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncUpdateRequests(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        account_number
        accountStatus
        customerName
        custType
        connDate
        servDesc
        status
        monthlyRate
        name
        first_name
        last_name
        email
        address1
        city
        state
        zip
        E911Address
        E911City
        E911State
        E911Zip
        telephone
        DataPackage
        VoicePackages
        VideoPackage
        FaxPackage
        transferFlag
        new_phone1
        sipPassword1
        old_account_number
        provisioned
        in_process
        errors
        PIN
        isManagedService
        created_on
        billingFlag
        taxFlag
        accessSFP
        billable
        isXML
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const updateRequestsByCustomerNameAndAccount_number = /* GraphQL */ `
  query UpdateRequestsByCustomerNameAndAccount_number(
    $customerName: String!
    $account_number: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUpdateRequestsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    updateRequestsByCustomerNameAndAccount_number(
      customerName: $customerName
      account_number: $account_number
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        account_number
        accountStatus
        customerName
        custType
        connDate
        servDesc
        status
        monthlyRate
        name
        first_name
        last_name
        email
        address1
        city
        state
        zip
        E911Address
        E911City
        E911State
        E911Zip
        telephone
        DataPackage
        VoicePackages
        VideoPackage
        FaxPackage
        transferFlag
        new_phone1
        sipPassword1
        old_account_number
        provisioned
        in_process
        errors
        PIN
        isManagedService
        created_on
        billingFlag
        taxFlag
        accessSFP
        billable
        isXML
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const searchUpdateRequests = /* GraphQL */ `
  query SearchUpdateRequests(
    $filter: SearchableUpdateRequestsFilterInput
    $sort: [SearchableUpdateRequestsSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableUpdateRequestsAggregationInput]
  ) {
    searchUpdateRequests(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        account_number
        accountStatus
        customerName
        custType
        connDate
        servDesc
        status
        monthlyRate
        name
        first_name
        last_name
        email
        address1
        city
        state
        zip
        E911Address
        E911City
        E911State
        E911Zip
        telephone
        DataPackage
        VoicePackages
        VideoPackage
        FaxPackage
        transferFlag
        new_phone1
        sipPassword1
        old_account_number
        provisioned
        in_process
        errors
        PIN
        isManagedService
        created_on
        billingFlag
        taxFlag
        accessSFP
        billable
        isXML
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
              __typename
            }
          }
        }
        __typename
      }
      __typename
    }
  }
`;
export const getProvisionRequests = /* GraphQL */ `
  query GetProvisionRequests($id: ID!) {
    getProvisionRequests(id: $id) {
      id
      customerName
      account_number
      accountStatus
      name
      first_name
      last_name
      address1
      city
      state
      zip
      telephone
      servDesc
      DataPackage
      email
      custType
      E911Address
      E911City
      E911State
      E911Zip
      VoicePackages
      VideoPackage
      FaxPackage
      monthlyRate
      new_phone1
      ported1
      sipPassword1
      numLines
      DIDs
      DIDdetail
      SONbr
      CallerIDName
      new_phone2
      ported2
      sipPassword2
      features
      CO_Name
      provisioned
      in_process
      errors
      PIN
      isManagedService
      created_on
      billingFlag
      taxFlag
      accessSFP
      billable
      isXML
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listProvisionRequests = /* GraphQL */ `
  query ListProvisionRequests(
    $filter: ModelProvisionRequestsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProvisionRequests(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        customerName
        account_number
        accountStatus
        name
        first_name
        last_name
        address1
        city
        state
        zip
        telephone
        servDesc
        DataPackage
        email
        custType
        E911Address
        E911City
        E911State
        E911Zip
        VoicePackages
        VideoPackage
        FaxPackage
        monthlyRate
        new_phone1
        ported1
        sipPassword1
        numLines
        DIDs
        DIDdetail
        SONbr
        CallerIDName
        new_phone2
        ported2
        sipPassword2
        features
        CO_Name
        provisioned
        in_process
        errors
        PIN
        isManagedService
        created_on
        billingFlag
        taxFlag
        accessSFP
        billable
        isXML
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncProvisionRequests = /* GraphQL */ `
  query SyncProvisionRequests(
    $filter: ModelProvisionRequestsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncProvisionRequests(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        customerName
        account_number
        accountStatus
        name
        first_name
        last_name
        address1
        city
        state
        zip
        telephone
        servDesc
        DataPackage
        email
        custType
        E911Address
        E911City
        E911State
        E911Zip
        VoicePackages
        VideoPackage
        FaxPackage
        monthlyRate
        new_phone1
        ported1
        sipPassword1
        numLines
        DIDs
        DIDdetail
        SONbr
        CallerIDName
        new_phone2
        ported2
        sipPassword2
        features
        CO_Name
        provisioned
        in_process
        errors
        PIN
        isManagedService
        created_on
        billingFlag
        taxFlag
        accessSFP
        billable
        isXML
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const provisionRequestsByCustomerNameAndAccount_number = /* GraphQL */ `
  query ProvisionRequestsByCustomerNameAndAccount_number(
    $customerName: String!
    $account_number: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelProvisionRequestsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    provisionRequestsByCustomerNameAndAccount_number(
      customerName: $customerName
      account_number: $account_number
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        customerName
        account_number
        accountStatus
        name
        first_name
        last_name
        address1
        city
        state
        zip
        telephone
        servDesc
        DataPackage
        email
        custType
        E911Address
        E911City
        E911State
        E911Zip
        VoicePackages
        VideoPackage
        FaxPackage
        monthlyRate
        new_phone1
        ported1
        sipPassword1
        numLines
        DIDs
        DIDdetail
        SONbr
        CallerIDName
        new_phone2
        ported2
        sipPassword2
        features
        CO_Name
        provisioned
        in_process
        errors
        PIN
        isManagedService
        created_on
        billingFlag
        taxFlag
        accessSFP
        billable
        isXML
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const searchProvisionRequests = /* GraphQL */ `
  query SearchProvisionRequests(
    $filter: SearchableProvisionRequestsFilterInput
    $sort: [SearchableProvisionRequestsSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableProvisionRequestsAggregationInput]
  ) {
    searchProvisionRequests(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        customerName
        account_number
        accountStatus
        name
        first_name
        last_name
        address1
        city
        state
        zip
        telephone
        servDesc
        DataPackage
        email
        custType
        E911Address
        E911City
        E911State
        E911Zip
        VoicePackages
        VideoPackage
        FaxPackage
        monthlyRate
        new_phone1
        ported1
        sipPassword1
        numLines
        DIDs
        DIDdetail
        SONbr
        CallerIDName
        new_phone2
        ported2
        sipPassword2
        features
        CO_Name
        provisioned
        in_process
        errors
        PIN
        isManagedService
        created_on
        billingFlag
        taxFlag
        accessSFP
        billable
        isXML
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
              __typename
            }
          }
        }
        __typename
      }
      __typename
    }
  }
`;
export const getPricebookDefaultProducts = /* GraphQL */ `
  query GetPricebookDefaultProducts($id: ID!) {
    getPricebookDefaultProducts(id: $id) {
      id
      PricebookProducts {
        nextToken
        startedAt
        __typename
      }
      user_defined_product
      name
      nickname
      primary_product_code
      secondary_product_code
      unit_price
      wholesale_price
      frequency
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listPricebookDefaultProducts = /* GraphQL */ `
  query ListPricebookDefaultProducts(
    $filter: ModelPricebookDefaultProductsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPricebookDefaultProducts(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        user_defined_product
        name
        nickname
        primary_product_code
        secondary_product_code
        unit_price
        wholesale_price
        frequency
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncPricebookDefaultProducts = /* GraphQL */ `
  query SyncPricebookDefaultProducts(
    $filter: ModelPricebookDefaultProductsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncPricebookDefaultProducts(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        user_defined_product
        name
        nickname
        primary_product_code
        secondary_product_code
        unit_price
        wholesale_price
        frequency
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const pricebookDefaultProductsByPrimary_product_codeAndName = /* GraphQL */ `
  query PricebookDefaultProductsByPrimary_product_codeAndName(
    $primary_product_code: String!
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPricebookDefaultProductsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    pricebookDefaultProductsByPrimary_product_codeAndName(
      primary_product_code: $primary_product_code
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        user_defined_product
        name
        nickname
        primary_product_code
        secondary_product_code
        unit_price
        wholesale_price
        frequency
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getPricebookProducts = /* GraphQL */ `
  query GetPricebookProducts($id: ID!) {
    getPricebookProducts(id: $id) {
      id
      pricebooksID
      unit_price
      wholesale_price
      enabled
      pricebookdefaultproductsID
      pricebook_default_product {
        id
        user_defined_product
        name
        nickname
        primary_product_code
        secondary_product_code
        unit_price
        wholesale_price
        frequency
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listPricebookProducts = /* GraphQL */ `
  query ListPricebookProducts(
    $filter: ModelPricebookProductsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPricebookProducts(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        pricebooksID
        unit_price
        wholesale_price
        enabled
        pricebookdefaultproductsID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncPricebookProducts = /* GraphQL */ `
  query SyncPricebookProducts(
    $filter: ModelPricebookProductsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncPricebookProducts(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        pricebooksID
        unit_price
        wholesale_price
        enabled
        pricebookdefaultproductsID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const pricebookProductsByPricebooksID = /* GraphQL */ `
  query PricebookProductsByPricebooksID(
    $pricebooksID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelPricebookProductsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    pricebookProductsByPricebooksID(
      pricebooksID: $pricebooksID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        pricebooksID
        unit_price
        wholesale_price
        enabled
        pricebookdefaultproductsID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const pricebookProductsByPricebookdefaultproductsID = /* GraphQL */ `
  query PricebookProductsByPricebookdefaultproductsID(
    $pricebookdefaultproductsID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelPricebookProductsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    pricebookProductsByPricebookdefaultproductsID(
      pricebookdefaultproductsID: $pricebookdefaultproductsID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        pricebooksID
        unit_price
        wholesale_price
        enabled
        pricebookdefaultproductsID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getPricebooks = /* GraphQL */ `
  query GetPricebooks($id: ID!) {
    getPricebooks(id: $id) {
      id
      partner
      coop_table_id
      nickname
      partner_account_number
      PricebookProducts {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listPricebooks = /* GraphQL */ `
  query ListPricebooks(
    $filter: ModelPricebooksFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPricebooks(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        partner
        coop_table_id
        nickname
        partner_account_number
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncPricebooks = /* GraphQL */ `
  query SyncPricebooks(
    $filter: ModelPricebooksFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncPricebooks(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        partner
        coop_table_id
        nickname
        partner_account_number
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const pricebooksByPartnerAndPartner_account_number = /* GraphQL */ `
  query PricebooksByPartnerAndPartner_account_number(
    $partner: String!
    $partner_account_number: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPricebooksFilterInput
    $limit: Int
    $nextToken: String
  ) {
    pricebooksByPartnerAndPartner_account_number(
      partner: $partner
      partner_account_number: $partner_account_number
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        partner
        coop_table_id
        nickname
        partner_account_number
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const pricebooksByCoop_table_id = /* GraphQL */ `
  query PricebooksByCoop_table_id(
    $coop_table_id: String!
    $sortDirection: ModelSortDirection
    $filter: ModelPricebooksFilterInput
    $limit: Int
    $nextToken: String
  ) {
    pricebooksByCoop_table_id(
      coop_table_id: $coop_table_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        partner
        coop_table_id
        nickname
        partner_account_number
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const pricebooksByPartner_account_number = /* GraphQL */ `
  query PricebooksByPartner_account_number(
    $partner_account_number: String!
    $sortDirection: ModelSortDirection
    $filter: ModelPricebooksFilterInput
    $limit: Int
    $nextToken: String
  ) {
    pricebooksByPartner_account_number(
      partner_account_number: $partner_account_number
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        partner
        coop_table_id
        nickname
        partner_account_number
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getGroups = /* GraphQL */ `
  query GetGroups($id: ID!) {
    getGroups(id: $id) {
      id
      Name
      cognito_name
      Applications {
        nextToken
        startedAt
        __typename
      }
      PageLevelPermissions
      allowed_permissions
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listGroups = /* GraphQL */ `
  query ListGroups(
    $filter: ModelGroupsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGroups(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        Name
        cognito_name
        PageLevelPermissions
        allowed_permissions
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncGroups = /* GraphQL */ `
  query SyncGroups(
    $filter: ModelGroupsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncGroups(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        Name
        cognito_name
        PageLevelPermissions
        allowed_permissions
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getGroupApplications = /* GraphQL */ `
  query GetGroupApplications($id: ID!) {
    getGroupApplications(id: $id) {
      id
      group_id
      application_id
      application {
        id
        name
        application_section
        is_child_application
        route
        application_permissions
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      allowed_permissions
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listGroupApplications = /* GraphQL */ `
  query ListGroupApplications(
    $filter: ModelGroupApplicationsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGroupApplications(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group_id
        application_id
        allowed_permissions
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncGroupApplications = /* GraphQL */ `
  query SyncGroupApplications(
    $filter: ModelGroupApplicationsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncGroupApplications(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        group_id
        application_id
        allowed_permissions
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const groupApplicationsByGroup_id = /* GraphQL */ `
  query GroupApplicationsByGroup_id(
    $group_id: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelGroupApplicationsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    groupApplicationsByGroup_id(
      group_id: $group_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group_id
        application_id
        allowed_permissions
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const groupApplicationsByApplication_id = /* GraphQL */ `
  query GroupApplicationsByApplication_id(
    $application_id: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelGroupApplicationsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    groupApplicationsByApplication_id(
      application_id: $application_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group_id
        application_id
        allowed_permissions
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getApplications = /* GraphQL */ `
  query GetApplications($id: ID!) {
    getApplications(id: $id) {
      id
      name
      application_section
      is_child_application
      route
      application_permissions
      GroupApplications {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listApplications = /* GraphQL */ `
  query ListApplications(
    $filter: ModelApplicationsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listApplications(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        application_section
        is_child_application
        route
        application_permissions
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncApplications = /* GraphQL */ `
  query SyncApplications(
    $filter: ModelApplicationsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncApplications(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        application_section
        is_child_application
        route
        application_permissions
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getGeneralLog = /* GraphQL */ `
  query GetGeneralLog($id: ID!) {
    getGeneralLog(id: $id) {
      id
      script
      msg_type
      app
      message
      msg_date
      msg_time
      partner
      is_error
      error_msg
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listGeneralLogs = /* GraphQL */ `
  query ListGeneralLogs(
    $filter: ModelGeneralLogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGeneralLogs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        script
        msg_type
        app
        message
        msg_date
        msg_time
        partner
        is_error
        error_msg
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncGeneralLogs = /* GraphQL */ `
  query SyncGeneralLogs(
    $filter: ModelGeneralLogFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncGeneralLogs(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        script
        msg_type
        app
        message
        msg_date
        msg_time
        partner
        is_error
        error_msg
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const searchGeneralLogs = /* GraphQL */ `
  query SearchGeneralLogs(
    $filter: SearchableGeneralLogFilterInput
    $sort: [SearchableGeneralLogSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableGeneralLogAggregationInput]
  ) {
    searchGeneralLogs(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        script
        msg_type
        app
        message
        msg_date
        msg_time
        partner
        is_error
        error_msg
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
              __typename
            }
          }
        }
        __typename
      }
      __typename
    }
  }
`;
export const getCOnames = /* GraphQL */ `
  query GetCOnames($id: ID!) {
    getCOnames(id: $id) {
      id
      CO_Name
      account_id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listCOnames = /* GraphQL */ `
  query ListCOnames(
    $filter: ModelCOnamesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCOnames(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        CO_Name
        account_id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncCOnames = /* GraphQL */ `
  query SyncCOnames(
    $filter: ModelCOnamesFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncCOnames(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        CO_Name
        account_id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getComments = /* GraphQL */ `
  query GetComments($id: ID!) {
    getComments(id: $id) {
      id
      port_request_id
      content
      timestamp
      action_required
      action_response
      author
      is_private
      user_id
      port_state
      comment_type
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listComments = /* GraphQL */ `
  query ListComments(
    $filter: ModelCommentsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listComments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        port_request_id
        content
        timestamp
        action_required
        action_response
        author
        is_private
        user_id
        port_state
        comment_type
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncComments = /* GraphQL */ `
  query SyncComments(
    $filter: ModelCommentsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncComments(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        port_request_id
        content
        timestamp
        action_required
        action_response
        author
        is_private
        user_id
        port_state
        comment_type
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getKazooWebhookAccountIds = /* GraphQL */ `
  query GetKazooWebhookAccountIds($id: ID!) {
    getKazooWebhookAccountIds(id: $id) {
      id
      account_id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listKazooWebhookAccountIds = /* GraphQL */ `
  query ListKazooWebhookAccountIds(
    $filter: ModelKazooWebhookAccountIdsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listKazooWebhookAccountIds(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        account_id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncKazooWebhookAccountIds = /* GraphQL */ `
  query SyncKazooWebhookAccountIds(
    $filter: ModelKazooWebhookAccountIdsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncKazooWebhookAccountIds(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        account_id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getPortOrders = /* GraphQL */ `
  query GetPortOrders($id: ID!) {
    getPortOrders(id: $id) {
      id
      name
      subscriberType
      submission_form_data
      submission_form_type
      phoneNumberType
      port_request_id
      port_state
      partner
      notification_email
      account_name
      parent_id
      account_id
      externalID
      requested_FOC_date
      port_request_name
      bill_carrier
      loc_number
      winning_carrier
      partialPort
      triggered
      business_name
      first_name
      middle_initial
      last_name
      housePrefix
      houseSuffix
      preDirection
      street_number
      street_address
      street_type
      postDirection
      AddressLine2
      city
      state_code
      zip
      plusFour
      account_number
      pin
      btn
      reference_number
      portNumbers
      accountPortion
      bill_filename
      recent_bill_verification
      loa_filename
      signee_name
      signing_date
      submission_date
      secondaryBtn
      isPortingBTN
      FOC_date
      original_foc_date
      lastOrderNote
      locationID
      CO_Name
      hosted_install
      fractelEmailed
      multi_line_order {
        nextToken
        startedAt
        __typename
      }
      port_order_comments {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listPortOrders = /* GraphQL */ `
  query ListPortOrders(
    $filter: ModelPortOrdersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPortOrders(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        subscriberType
        submission_form_data
        submission_form_type
        phoneNumberType
        port_request_id
        port_state
        partner
        notification_email
        account_name
        parent_id
        account_id
        externalID
        requested_FOC_date
        port_request_name
        bill_carrier
        loc_number
        winning_carrier
        partialPort
        triggered
        business_name
        first_name
        middle_initial
        last_name
        housePrefix
        houseSuffix
        preDirection
        street_number
        street_address
        street_type
        postDirection
        AddressLine2
        city
        state_code
        zip
        plusFour
        account_number
        pin
        btn
        reference_number
        portNumbers
        accountPortion
        bill_filename
        recent_bill_verification
        loa_filename
        signee_name
        signing_date
        submission_date
        secondaryBtn
        isPortingBTN
        FOC_date
        original_foc_date
        lastOrderNote
        locationID
        CO_Name
        hosted_install
        fractelEmailed
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncPortOrders = /* GraphQL */ `
  query SyncPortOrders(
    $filter: ModelPortOrdersFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncPortOrders(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        subscriberType
        submission_form_data
        submission_form_type
        phoneNumberType
        port_request_id
        port_state
        partner
        notification_email
        account_name
        parent_id
        account_id
        externalID
        requested_FOC_date
        port_request_name
        bill_carrier
        loc_number
        winning_carrier
        partialPort
        triggered
        business_name
        first_name
        middle_initial
        last_name
        housePrefix
        houseSuffix
        preDirection
        street_number
        street_address
        street_type
        postDirection
        AddressLine2
        city
        state_code
        zip
        plusFour
        account_number
        pin
        btn
        reference_number
        portNumbers
        accountPortion
        bill_filename
        recent_bill_verification
        loa_filename
        signee_name
        signing_date
        submission_date
        secondaryBtn
        isPortingBTN
        FOC_date
        original_foc_date
        lastOrderNote
        locationID
        CO_Name
        hosted_install
        fractelEmailed
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const portOrdersByName = /* GraphQL */ `
  query PortOrdersByName(
    $name: String!
    $sortDirection: ModelSortDirection
    $filter: ModelPortOrdersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    portOrdersByName(
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        subscriberType
        submission_form_data
        submission_form_type
        phoneNumberType
        port_request_id
        port_state
        partner
        notification_email
        account_name
        parent_id
        account_id
        externalID
        requested_FOC_date
        port_request_name
        bill_carrier
        loc_number
        winning_carrier
        partialPort
        triggered
        business_name
        first_name
        middle_initial
        last_name
        housePrefix
        houseSuffix
        preDirection
        street_number
        street_address
        street_type
        postDirection
        AddressLine2
        city
        state_code
        zip
        plusFour
        account_number
        pin
        btn
        reference_number
        portNumbers
        accountPortion
        bill_filename
        recent_bill_verification
        loa_filename
        signee_name
        signing_date
        submission_date
        secondaryBtn
        isPortingBTN
        FOC_date
        original_foc_date
        lastOrderNote
        locationID
        CO_Name
        hosted_install
        fractelEmailed
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const portOrdersByPartner = /* GraphQL */ `
  query PortOrdersByPartner(
    $partner: String!
    $sortDirection: ModelSortDirection
    $filter: ModelPortOrdersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    portOrdersByPartner(
      partner: $partner
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        subscriberType
        submission_form_data
        submission_form_type
        phoneNumberType
        port_request_id
        port_state
        partner
        notification_email
        account_name
        parent_id
        account_id
        externalID
        requested_FOC_date
        port_request_name
        bill_carrier
        loc_number
        winning_carrier
        partialPort
        triggered
        business_name
        first_name
        middle_initial
        last_name
        housePrefix
        houseSuffix
        preDirection
        street_number
        street_address
        street_type
        postDirection
        AddressLine2
        city
        state_code
        zip
        plusFour
        account_number
        pin
        btn
        reference_number
        portNumbers
        accountPortion
        bill_filename
        recent_bill_verification
        loa_filename
        signee_name
        signing_date
        submission_date
        secondaryBtn
        isPortingBTN
        FOC_date
        original_foc_date
        lastOrderNote
        locationID
        CO_Name
        hosted_install
        fractelEmailed
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const portOrdersByFOC_date = /* GraphQL */ `
  query PortOrdersByFOC_date(
    $FOC_date: AWSDate!
    $sortDirection: ModelSortDirection
    $filter: ModelPortOrdersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    portOrdersByFOC_date(
      FOC_date: $FOC_date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        subscriberType
        submission_form_data
        submission_form_type
        phoneNumberType
        port_request_id
        port_state
        partner
        notification_email
        account_name
        parent_id
        account_id
        externalID
        requested_FOC_date
        port_request_name
        bill_carrier
        loc_number
        winning_carrier
        partialPort
        triggered
        business_name
        first_name
        middle_initial
        last_name
        housePrefix
        houseSuffix
        preDirection
        street_number
        street_address
        street_type
        postDirection
        AddressLine2
        city
        state_code
        zip
        plusFour
        account_number
        pin
        btn
        reference_number
        portNumbers
        accountPortion
        bill_filename
        recent_bill_verification
        loa_filename
        signee_name
        signing_date
        submission_date
        secondaryBtn
        isPortingBTN
        FOC_date
        original_foc_date
        lastOrderNote
        locationID
        CO_Name
        hosted_install
        fractelEmailed
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const searchPortOrders = /* GraphQL */ `
  query SearchPortOrders(
    $filter: SearchablePortOrdersFilterInput
    $sort: [SearchablePortOrdersSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchablePortOrdersAggregationInput]
  ) {
    searchPortOrders(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        name
        subscriberType
        submission_form_data
        submission_form_type
        phoneNumberType
        port_request_id
        port_state
        partner
        notification_email
        account_name
        parent_id
        account_id
        externalID
        requested_FOC_date
        port_request_name
        bill_carrier
        loc_number
        winning_carrier
        partialPort
        triggered
        business_name
        first_name
        middle_initial
        last_name
        housePrefix
        houseSuffix
        preDirection
        street_number
        street_address
        street_type
        postDirection
        AddressLine2
        city
        state_code
        zip
        plusFour
        account_number
        pin
        btn
        reference_number
        portNumbers
        accountPortion
        bill_filename
        recent_bill_verification
        loa_filename
        signee_name
        signing_date
        submission_date
        secondaryBtn
        isPortingBTN
        FOC_date
        original_foc_date
        lastOrderNote
        locationID
        CO_Name
        hosted_install
        fractelEmailed
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
              __typename
            }
          }
        }
        __typename
      }
      __typename
    }
  }
`;
export const getPortOrderComments = /* GraphQL */ `
  query GetPortOrderComments($id: ID!) {
    getPortOrderComments(id: $id) {
      id
      port_order_id
      content
      timestamp
      action_required
      action_response
      author
      is_private
      user_id
      port_state
      comment_type
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listPortOrderComments = /* GraphQL */ `
  query ListPortOrderComments(
    $filter: ModelPortOrderCommentsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPortOrderComments(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        port_order_id
        content
        timestamp
        action_required
        action_response
        author
        is_private
        user_id
        port_state
        comment_type
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncPortOrderComments = /* GraphQL */ `
  query SyncPortOrderComments(
    $filter: ModelPortOrderCommentsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncPortOrderComments(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        port_order_id
        content
        timestamp
        action_required
        action_response
        author
        is_private
        user_id
        port_state
        comment_type
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const portOrderCommentsByPort_order_id = /* GraphQL */ `
  query PortOrderCommentsByPort_order_id(
    $port_order_id: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelPortOrderCommentsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    portOrderCommentsByPort_order_id(
      port_order_id: $port_order_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        port_order_id
        content
        timestamp
        action_required
        action_response
        author
        is_private
        user_id
        port_state
        comment_type
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getSaveCallCenterProData = /* GraphQL */ `
  query GetSaveCallCenterProData($id: ID!) {
    getSaveCallCenterProData(id: $id) {
      id
      queueName
      timestamp
      dateTime
      eventDesc
      agentName
      callerIdNumber
      callerIdName
      joinPosition
      joinDateTime
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listSaveCallCenterProData = /* GraphQL */ `
  query ListSaveCallCenterProData(
    $filter: ModelSaveCallCenterProDataFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSaveCallCenterProData(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        queueName
        timestamp
        dateTime
        eventDesc
        agentName
        callerIdNumber
        callerIdName
        joinPosition
        joinDateTime
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncSaveCallCenterProData = /* GraphQL */ `
  query SyncSaveCallCenterProData(
    $filter: ModelSaveCallCenterProDataFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncSaveCallCenterProData(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        queueName
        timestamp
        dateTime
        eventDesc
        agentName
        callerIdNumber
        callerIdName
        joinPosition
        joinDateTime
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getSwitchQubicleSessions = /* GraphQL */ `
  query GetSwitchQubicleSessions($id: ID!) {
    getSwitchQubicleSessions(id: $id) {
      id
      event_type
      account_id
      account_name
      event_timestamp
      session_type
      session_id
      pid
      caller_id_number
      caller_id_name
      call_data
      session_life_time
      queue_wait_time
      create_time
      reason
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listSwitchQubicleSessions = /* GraphQL */ `
  query ListSwitchQubicleSessions(
    $filter: ModelSwitchQubicleSessionsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSwitchQubicleSessions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        event_type
        account_id
        account_name
        event_timestamp
        session_type
        session_id
        pid
        caller_id_number
        caller_id_name
        call_data
        session_life_time
        queue_wait_time
        create_time
        reason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncSwitchQubicleSessions = /* GraphQL */ `
  query SyncSwitchQubicleSessions(
    $filter: ModelSwitchQubicleSessionsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncSwitchQubicleSessions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        event_type
        account_id
        account_name
        event_timestamp
        session_type
        session_id
        pid
        caller_id_number
        caller_id_name
        call_data
        session_life_time
        queue_wait_time
        create_time
        reason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getSwitchQubicleRecipients = /* GraphQL */ `
  query GetSwitchQubicleRecipients($id: ID!) {
    getSwitchQubicleRecipients(id: $id) {
      id
      event_type
      event_timestamp
      reason
      account_id
      displayname
      recipient_id
      queue_name
      queue_id
      caller_id_number
      caller_id_name
      queue_wait_time
      queue_enter_time
      session_id
      session
      session_zone
      talk_time
      queue_offering
      est_wait
      enter_pos
      record_queue
      queue_wrapup_extension_limit
      force_away_on_reject
      away_time
      ring_timeout
      queue_wrapup_extension
      queue_wrapup
      total_calls
      offered_calls
      missed_calls
      avg_call_time
      login_time
      last_action_time
      last_handled_time
      last_idle_time
      input_object
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listSwitchQubicleRecipients = /* GraphQL */ `
  query ListSwitchQubicleRecipients(
    $filter: ModelSwitchQubicleRecipientsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSwitchQubicleRecipients(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        event_type
        event_timestamp
        reason
        account_id
        displayname
        recipient_id
        queue_name
        queue_id
        caller_id_number
        caller_id_name
        queue_wait_time
        queue_enter_time
        session_id
        session
        session_zone
        talk_time
        queue_offering
        est_wait
        enter_pos
        record_queue
        queue_wrapup_extension_limit
        force_away_on_reject
        away_time
        ring_timeout
        queue_wrapup_extension
        queue_wrapup
        total_calls
        offered_calls
        missed_calls
        avg_call_time
        login_time
        last_action_time
        last_handled_time
        last_idle_time
        input_object
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncSwitchQubicleRecipients = /* GraphQL */ `
  query SyncSwitchQubicleRecipients(
    $filter: ModelSwitchQubicleRecipientsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncSwitchQubicleRecipients(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        event_type
        event_timestamp
        reason
        account_id
        displayname
        recipient_id
        queue_name
        queue_id
        caller_id_number
        caller_id_name
        queue_wait_time
        queue_enter_time
        session_id
        session
        session_zone
        talk_time
        queue_offering
        est_wait
        enter_pos
        record_queue
        queue_wrapup_extension_limit
        force_away_on_reject
        away_time
        ring_timeout
        queue_wrapup_extension
        queue_wrapup
        total_calls
        offered_calls
        missed_calls
        avg_call_time
        login_time
        last_action_time
        last_handled_time
        last_idle_time
        input_object
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const searchSwitchQubicleRecipients = /* GraphQL */ `
  query SearchSwitchQubicleRecipients(
    $filter: SearchableSwitchQubicleRecipientsFilterInput
    $sort: [SearchableSwitchQubicleRecipientsSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableSwitchQubicleRecipientsAggregationInput]
  ) {
    searchSwitchQubicleRecipients(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        event_type
        event_timestamp
        reason
        account_id
        displayname
        recipient_id
        queue_name
        queue_id
        caller_id_number
        caller_id_name
        queue_wait_time
        queue_enter_time
        session_id
        session
        session_zone
        talk_time
        queue_offering
        est_wait
        enter_pos
        record_queue
        queue_wrapup_extension_limit
        force_away_on_reject
        away_time
        ring_timeout
        queue_wrapup_extension
        queue_wrapup
        total_calls
        offered_calls
        missed_calls
        avg_call_time
        login_time
        last_action_time
        last_handled_time
        last_idle_time
        input_object
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
              __typename
            }
          }
        }
        __typename
      }
      __typename
    }
  }
`;
export const getSwitchQubicleQueues = /* GraphQL */ `
  query GetSwitchQubicleQueues($id: ID!) {
    getSwitchQubicleQueues(id: $id) {
      id
      event_type
      account_id
      queue_id
      event_timestamp
      total_wait_time
      queue_name
      join_position
      join_time
      est_wait_time
      caller_id_number
      caller_id_name
      session_id
      estimated_wait
      average_wait
      longest_wait
      recipient_count
      active_session_count
      total_sessions
      missed_sessions
      abandoned_sessions
      session_data
      input_object
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listSwitchQubicleQueues = /* GraphQL */ `
  query ListSwitchQubicleQueues(
    $filter: ModelSwitchQubicleQueuesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSwitchQubicleQueues(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        event_type
        account_id
        queue_id
        event_timestamp
        total_wait_time
        queue_name
        join_position
        join_time
        est_wait_time
        caller_id_number
        caller_id_name
        session_id
        estimated_wait
        average_wait
        longest_wait
        recipient_count
        active_session_count
        total_sessions
        missed_sessions
        abandoned_sessions
        session_data
        input_object
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncSwitchQubicleQueues = /* GraphQL */ `
  query SyncSwitchQubicleQueues(
    $filter: ModelSwitchQubicleQueuesFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncSwitchQubicleQueues(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        event_type
        account_id
        queue_id
        event_timestamp
        total_wait_time
        queue_name
        join_position
        join_time
        est_wait_time
        caller_id_number
        caller_id_name
        session_id
        estimated_wait
        average_wait
        longest_wait
        recipient_count
        active_session_count
        total_sessions
        missed_sessions
        abandoned_sessions
        session_data
        input_object
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const searchSwitchQubicleQueues = /* GraphQL */ `
  query SearchSwitchQubicleQueues(
    $filter: SearchableSwitchQubicleQueuesFilterInput
    $sort: [SearchableSwitchQubicleQueuesSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableSwitchQubicleQueuesAggregationInput]
  ) {
    searchSwitchQubicleQueues(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        event_type
        account_id
        queue_id
        event_timestamp
        total_wait_time
        queue_name
        join_position
        join_time
        est_wait_time
        caller_id_number
        caller_id_name
        session_id
        estimated_wait
        average_wait
        longest_wait
        recipient_count
        active_session_count
        total_sessions
        missed_sessions
        abandoned_sessions
        session_data
        input_object
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
              __typename
            }
          }
        }
        __typename
      }
      __typename
    }
  }
`;
export const getSwitchCDRs = /* GraphQL */ `
  query GetSwitchCDRs($id: ID!) {
    getSwitchCDRs(id: $id) {
      id
      account_id
      parent_id
      cdr_id
      call_id
      caller_id_number
      caller_id_name
      callee_id_number
      callee_id_name
      duration_seconds
      billing_seconds
      gregorian_timestamp
      hangup_cause
      other_leg_call_id
      owner_id
      to
      from
      revio_id
      external_id
      direction
      request
      authorizing_id
      cost
      dialed_number
      calling_from
      datetime
      unix_timestamp
      unix_timestamp_micro
      rfc_1036
      iso_8601
      iso_8601_combined
      call_type
      rate
      rate_name
      bridge_id
      recording_url
      media_recordings
      media_server
      call_priority
      interaction_id
      reseller_cost
      reseller_call_type
      wholesale_cost
      retail_cost
      robocall_threshold {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listSwitchCDRs = /* GraphQL */ `
  query ListSwitchCDRs(
    $filter: ModelSwitchCDRsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSwitchCDRs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        account_id
        parent_id
        cdr_id
        call_id
        caller_id_number
        caller_id_name
        callee_id_number
        callee_id_name
        duration_seconds
        billing_seconds
        gregorian_timestamp
        hangup_cause
        other_leg_call_id
        owner_id
        to
        from
        revio_id
        external_id
        direction
        request
        authorizing_id
        cost
        dialed_number
        calling_from
        datetime
        unix_timestamp
        unix_timestamp_micro
        rfc_1036
        iso_8601
        iso_8601_combined
        call_type
        rate
        rate_name
        bridge_id
        recording_url
        media_recordings
        media_server
        call_priority
        interaction_id
        reseller_cost
        reseller_call_type
        wholesale_cost
        retail_cost
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncSwitchCDRs = /* GraphQL */ `
  query SyncSwitchCDRs(
    $filter: ModelSwitchCDRsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncSwitchCDRs(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        account_id
        parent_id
        cdr_id
        call_id
        caller_id_number
        caller_id_name
        callee_id_number
        callee_id_name
        duration_seconds
        billing_seconds
        gregorian_timestamp
        hangup_cause
        other_leg_call_id
        owner_id
        to
        from
        revio_id
        external_id
        direction
        request
        authorizing_id
        cost
        dialed_number
        calling_from
        datetime
        unix_timestamp
        unix_timestamp_micro
        rfc_1036
        iso_8601
        iso_8601_combined
        call_type
        rate
        rate_name
        bridge_id
        recording_url
        media_recordings
        media_server
        call_priority
        interaction_id
        reseller_cost
        reseller_call_type
        wholesale_cost
        retail_cost
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const switchCDRsByAccount_idAndDatetime = /* GraphQL */ `
  query SwitchCDRsByAccount_idAndDatetime(
    $account_id: String!
    $datetime: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSwitchCDRsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    switchCDRsByAccount_idAndDatetime(
      account_id: $account_id
      datetime: $datetime
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        account_id
        parent_id
        cdr_id
        call_id
        caller_id_number
        caller_id_name
        callee_id_number
        callee_id_name
        duration_seconds
        billing_seconds
        gregorian_timestamp
        hangup_cause
        other_leg_call_id
        owner_id
        to
        from
        revio_id
        external_id
        direction
        request
        authorizing_id
        cost
        dialed_number
        calling_from
        datetime
        unix_timestamp
        unix_timestamp_micro
        rfc_1036
        iso_8601
        iso_8601_combined
        call_type
        rate
        rate_name
        bridge_id
        recording_url
        media_recordings
        media_server
        call_priority
        interaction_id
        reseller_cost
        reseller_call_type
        wholesale_cost
        retail_cost
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const switchCDRsByParent_idAndDatetime = /* GraphQL */ `
  query SwitchCDRsByParent_idAndDatetime(
    $parent_id: String!
    $datetime: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSwitchCDRsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    switchCDRsByParent_idAndDatetime(
      parent_id: $parent_id
      datetime: $datetime
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        account_id
        parent_id
        cdr_id
        call_id
        caller_id_number
        caller_id_name
        callee_id_number
        callee_id_name
        duration_seconds
        billing_seconds
        gregorian_timestamp
        hangup_cause
        other_leg_call_id
        owner_id
        to
        from
        revio_id
        external_id
        direction
        request
        authorizing_id
        cost
        dialed_number
        calling_from
        datetime
        unix_timestamp
        unix_timestamp_micro
        rfc_1036
        iso_8601
        iso_8601_combined
        call_type
        rate
        rate_name
        bridge_id
        recording_url
        media_recordings
        media_server
        call_priority
        interaction_id
        reseller_cost
        reseller_call_type
        wholesale_cost
        retail_cost
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const switchCDRsByCdr_id = /* GraphQL */ `
  query SwitchCDRsByCdr_id(
    $cdr_id: String!
    $sortDirection: ModelSortDirection
    $filter: ModelSwitchCDRsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    switchCDRsByCdr_id(
      cdr_id: $cdr_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        account_id
        parent_id
        cdr_id
        call_id
        caller_id_number
        caller_id_name
        callee_id_number
        callee_id_name
        duration_seconds
        billing_seconds
        gregorian_timestamp
        hangup_cause
        other_leg_call_id
        owner_id
        to
        from
        revio_id
        external_id
        direction
        request
        authorizing_id
        cost
        dialed_number
        calling_from
        datetime
        unix_timestamp
        unix_timestamp_micro
        rfc_1036
        iso_8601
        iso_8601_combined
        call_type
        rate
        rate_name
        bridge_id
        recording_url
        media_recordings
        media_server
        call_priority
        interaction_id
        reseller_cost
        reseller_call_type
        wholesale_cost
        retail_cost
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const switchCDRsByCall_id = /* GraphQL */ `
  query SwitchCDRsByCall_id(
    $call_id: String!
    $sortDirection: ModelSortDirection
    $filter: ModelSwitchCDRsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    switchCDRsByCall_id(
      call_id: $call_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        account_id
        parent_id
        cdr_id
        call_id
        caller_id_number
        caller_id_name
        callee_id_number
        callee_id_name
        duration_seconds
        billing_seconds
        gregorian_timestamp
        hangup_cause
        other_leg_call_id
        owner_id
        to
        from
        revio_id
        external_id
        direction
        request
        authorizing_id
        cost
        dialed_number
        calling_from
        datetime
        unix_timestamp
        unix_timestamp_micro
        rfc_1036
        iso_8601
        iso_8601_combined
        call_type
        rate
        rate_name
        bridge_id
        recording_url
        media_recordings
        media_server
        call_priority
        interaction_id
        reseller_cost
        reseller_call_type
        wholesale_cost
        retail_cost
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const switchCDRsByRevio_idAndDatetime = /* GraphQL */ `
  query SwitchCDRsByRevio_idAndDatetime(
    $revio_id: String!
    $datetime: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSwitchCDRsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    switchCDRsByRevio_idAndDatetime(
      revio_id: $revio_id
      datetime: $datetime
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        account_id
        parent_id
        cdr_id
        call_id
        caller_id_number
        caller_id_name
        callee_id_number
        callee_id_name
        duration_seconds
        billing_seconds
        gregorian_timestamp
        hangup_cause
        other_leg_call_id
        owner_id
        to
        from
        revio_id
        external_id
        direction
        request
        authorizing_id
        cost
        dialed_number
        calling_from
        datetime
        unix_timestamp
        unix_timestamp_micro
        rfc_1036
        iso_8601
        iso_8601_combined
        call_type
        rate
        rate_name
        bridge_id
        recording_url
        media_recordings
        media_server
        call_priority
        interaction_id
        reseller_cost
        reseller_call_type
        wholesale_cost
        retail_cost
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const switchCDRsByDatetime = /* GraphQL */ `
  query SwitchCDRsByDatetime(
    $datetime: AWSDateTime!
    $sortDirection: ModelSortDirection
    $filter: ModelSwitchCDRsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    switchCDRsByDatetime(
      datetime: $datetime
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        account_id
        parent_id
        cdr_id
        call_id
        caller_id_number
        caller_id_name
        callee_id_number
        callee_id_name
        duration_seconds
        billing_seconds
        gregorian_timestamp
        hangup_cause
        other_leg_call_id
        owner_id
        to
        from
        revio_id
        external_id
        direction
        request
        authorizing_id
        cost
        dialed_number
        calling_from
        datetime
        unix_timestamp
        unix_timestamp_micro
        rfc_1036
        iso_8601
        iso_8601_combined
        call_type
        rate
        rate_name
        bridge_id
        recording_url
        media_recordings
        media_server
        call_priority
        interaction_id
        reseller_cost
        reseller_call_type
        wholesale_cost
        retail_cost
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const switchCDRsByUnix_timestamp = /* GraphQL */ `
  query SwitchCDRsByUnix_timestamp(
    $unix_timestamp: AWSTimestamp!
    $sortDirection: ModelSortDirection
    $filter: ModelSwitchCDRsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    switchCDRsByUnix_timestamp(
      unix_timestamp: $unix_timestamp
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        account_id
        parent_id
        cdr_id
        call_id
        caller_id_number
        caller_id_name
        callee_id_number
        callee_id_name
        duration_seconds
        billing_seconds
        gregorian_timestamp
        hangup_cause
        other_leg_call_id
        owner_id
        to
        from
        revio_id
        external_id
        direction
        request
        authorizing_id
        cost
        dialed_number
        calling_from
        datetime
        unix_timestamp
        unix_timestamp_micro
        rfc_1036
        iso_8601
        iso_8601_combined
        call_type
        rate
        rate_name
        bridge_id
        recording_url
        media_recordings
        media_server
        call_priority
        interaction_id
        reseller_cost
        reseller_call_type
        wholesale_cost
        retail_cost
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const switchCDRsByInteraction_id = /* GraphQL */ `
  query SwitchCDRsByInteraction_id(
    $interaction_id: String!
    $sortDirection: ModelSortDirection
    $filter: ModelSwitchCDRsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    switchCDRsByInteraction_id(
      interaction_id: $interaction_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        account_id
        parent_id
        cdr_id
        call_id
        caller_id_number
        caller_id_name
        callee_id_number
        callee_id_name
        duration_seconds
        billing_seconds
        gregorian_timestamp
        hangup_cause
        other_leg_call_id
        owner_id
        to
        from
        revio_id
        external_id
        direction
        request
        authorizing_id
        cost
        dialed_number
        calling_from
        datetime
        unix_timestamp
        unix_timestamp_micro
        rfc_1036
        iso_8601
        iso_8601_combined
        call_type
        rate
        rate_name
        bridge_id
        recording_url
        media_recordings
        media_server
        call_priority
        interaction_id
        reseller_cost
        reseller_call_type
        wholesale_cost
        retail_cost
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const searchSwitchCDRs = /* GraphQL */ `
  query SearchSwitchCDRs(
    $filter: SearchableSwitchCDRsFilterInput
    $sort: [SearchableSwitchCDRsSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableSwitchCDRsAggregationInput]
  ) {
    searchSwitchCDRs(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        account_id
        parent_id
        cdr_id
        call_id
        caller_id_number
        caller_id_name
        callee_id_number
        callee_id_name
        duration_seconds
        billing_seconds
        gregorian_timestamp
        hangup_cause
        other_leg_call_id
        owner_id
        to
        from
        revio_id
        external_id
        direction
        request
        authorizing_id
        cost
        dialed_number
        calling_from
        datetime
        unix_timestamp
        unix_timestamp_micro
        rfc_1036
        iso_8601
        iso_8601_combined
        call_type
        rate
        rate_name
        bridge_id
        recording_url
        media_recordings
        media_server
        call_priority
        interaction_id
        reseller_cost
        reseller_call_type
        wholesale_cost
        retail_cost
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
              __typename
            }
          }
        }
        __typename
      }
      __typename
    }
  }
`;
export const getSwitch811s = /* GraphQL */ `
  query GetSwitch811s($id: ID!) {
    getSwitch811s(id: $id) {
      id
      state
      number_811
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listSwitch811s = /* GraphQL */ `
  query ListSwitch811s(
    $filter: ModelSwitch811sFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSwitch811s(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        state
        number_811
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncSwitch811s = /* GraphQL */ `
  query SyncSwitch811s(
    $filter: ModelSwitch811sFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncSwitch811s(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        state
        number_811
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getPartners = /* GraphQL */ `
  query GetPartners($id: ID!) {
    getPartners(id: $id) {
      partner
      name
      revio_id
      company_name
      powered_by
      logo
      logo_height
      logo_width
      website_url
      quotes {
        nextToken
        startedAt
        __typename
      }
      switch_parent_id
      switch_parent_account {
        nextToken
        startedAt
        __typename
      }
      switch_child_id
      switch_child_account {
        nextToken
        startedAt
        __typename
      }
      bill_profile_id
      usage_plan_group_id
      phone_number
      email
      coop_table_id
      pricebook {
        nextToken
        startedAt
        __typename
      }
      revio_customer {
        nextToken
        startedAt
        __typename
      }
      wholesale_as_retail
      fusf_custom_tax_exemption
      foc_notification_emails
      fusf_exemption
      has_glds_billing
      has_provisioning
      port_network_email_list
      port_action_email_list
      port_impending_foc_email_list
      queue
      id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listPartners = /* GraphQL */ `
  query ListPartners(
    $filter: ModelPartnersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPartners(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        partner
        name
        revio_id
        company_name
        powered_by
        logo
        logo_height
        logo_width
        website_url
        switch_parent_id
        switch_child_id
        bill_profile_id
        usage_plan_group_id
        phone_number
        email
        coop_table_id
        wholesale_as_retail
        fusf_custom_tax_exemption
        foc_notification_emails
        fusf_exemption
        has_glds_billing
        has_provisioning
        port_network_email_list
        port_action_email_list
        port_impending_foc_email_list
        queue
        id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncPartners = /* GraphQL */ `
  query SyncPartners(
    $filter: ModelPartnersFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncPartners(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        partner
        name
        revio_id
        company_name
        powered_by
        logo
        logo_height
        logo_width
        website_url
        switch_parent_id
        switch_child_id
        bill_profile_id
        usage_plan_group_id
        phone_number
        email
        coop_table_id
        wholesale_as_retail
        fusf_custom_tax_exemption
        foc_notification_emails
        fusf_exemption
        has_glds_billing
        has_provisioning
        port_network_email_list
        port_action_email_list
        port_impending_foc_email_list
        queue
        id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const partnersByPartner = /* GraphQL */ `
  query PartnersByPartner(
    $partner: String!
    $sortDirection: ModelSortDirection
    $filter: ModelPartnersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    partnersByPartner(
      partner: $partner
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        partner
        name
        revio_id
        company_name
        powered_by
        logo
        logo_height
        logo_width
        website_url
        switch_parent_id
        switch_child_id
        bill_profile_id
        usage_plan_group_id
        phone_number
        email
        coop_table_id
        wholesale_as_retail
        fusf_custom_tax_exemption
        foc_notification_emails
        fusf_exemption
        has_glds_billing
        has_provisioning
        port_network_email_list
        port_action_email_list
        port_impending_foc_email_list
        queue
        id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getManagerPartners = /* GraphQL */ `
  query GetManagerPartners($id: ID!) {
    getManagerPartners(id: $id) {
      id
      name
      ticket_queue_name
      category
      voice_category
      contact_center_category
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listManagerPartners = /* GraphQL */ `
  query ListManagerPartners(
    $filter: ModelManagerPartnersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listManagerPartners(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        ticket_queue_name
        category
        voice_category
        contact_center_category
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncManagerPartners = /* GraphQL */ `
  query SyncManagerPartners(
    $filter: ModelManagerPartnersFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncManagerPartners(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        ticket_queue_name
        category
        voice_category
        contact_center_category
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getSwitchUsers = /* GraphQL */ `
  query GetSwitchUsers($id: ID!) {
    getSwitchUsers(id: $id) {
      id
      username
      email
      first_name
      last_name
      priv_level
      presence_id
      caller_id
      send_email_on_creation
      call_restriction
      contact_list
      dial_plan
      enabled
      hotdesk
      media
      music_on_hold
      profile
      require_password_update
      ringtones
      verified
      vm_to_email_enabled
      account_name
      apps
      ui_help
      appList
      user_id
      account_id
      account {
        id
        ui_restrictions
        ui_flags
        timezone
        ringtones
        reseller_id
        realm
        preflow
        notifications
        notification_preference
        name
        music_on_hold
        language
        is_reseller
        dial_plan
        descendants_count
        created
        contract
        contact
        caller_id
        call_restriction
        metaflows
        parent_id
        account_id
        _read_only
        wnm_allow_additions
        superduper_admin
        enabled
        billing_mode
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      _read_only
      has_avatar
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listSwitchUsers = /* GraphQL */ `
  query ListSwitchUsers(
    $filter: ModelSwitchUsersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSwitchUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        username
        email
        first_name
        last_name
        priv_level
        presence_id
        caller_id
        send_email_on_creation
        call_restriction
        contact_list
        dial_plan
        enabled
        hotdesk
        media
        music_on_hold
        profile
        require_password_update
        ringtones
        verified
        vm_to_email_enabled
        account_name
        apps
        ui_help
        appList
        user_id
        account_id
        _read_only
        has_avatar
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncSwitchUsers = /* GraphQL */ `
  query SyncSwitchUsers(
    $filter: ModelSwitchUsersFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncSwitchUsers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        username
        email
        first_name
        last_name
        priv_level
        presence_id
        caller_id
        send_email_on_creation
        call_restriction
        contact_list
        dial_plan
        enabled
        hotdesk
        media
        music_on_hold
        profile
        require_password_update
        ringtones
        verified
        vm_to_email_enabled
        account_name
        apps
        ui_help
        appList
        user_id
        account_id
        _read_only
        has_avatar
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const switchUsersByUser_id = /* GraphQL */ `
  query SwitchUsersByUser_id(
    $user_id: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelSwitchUsersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    switchUsersByUser_id(
      user_id: $user_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        username
        email
        first_name
        last_name
        priv_level
        presence_id
        caller_id
        send_email_on_creation
        call_restriction
        contact_list
        dial_plan
        enabled
        hotdesk
        media
        music_on_hold
        profile
        require_password_update
        ringtones
        verified
        vm_to_email_enabled
        account_name
        apps
        ui_help
        appList
        user_id
        account_id
        _read_only
        has_avatar
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const switchUsersByAccount_id = /* GraphQL */ `
  query SwitchUsersByAccount_id(
    $account_id: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelSwitchUsersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    switchUsersByAccount_id(
      account_id: $account_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        username
        email
        first_name
        last_name
        priv_level
        presence_id
        caller_id
        send_email_on_creation
        call_restriction
        contact_list
        dial_plan
        enabled
        hotdesk
        media
        music_on_hold
        profile
        require_password_update
        ringtones
        verified
        vm_to_email_enabled
        account_name
        apps
        ui_help
        appList
        user_id
        account_id
        _read_only
        has_avatar
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getSwitchMailboxes = /* GraphQL */ `
  query GetSwitchMailboxes($id: ID!) {
    getSwitchMailboxes(id: $id) {
      id
      mailbox
      name
      owner_id
      media_extension
      notify_email_addresses
      announcement_only
      check_if_owner
      delete_after_notify
      include_message_on_notify
      include_transcription_on_notify
      is_setup
      is_voicemail_ff_rw_enabled
      media
      not_configurable
      oldest_message_first
      require_pin
      save_after_notify
      seek_duration_ms
      skip_envelope
      skip_greeting
      skip_instructions
      transcribe
      vm_message_forward_type
      ui_metadata
      account_id
      account {
        id
        ui_restrictions
        ui_flags
        timezone
        ringtones
        reseller_id
        realm
        preflow
        notifications
        notification_preference
        name
        music_on_hold
        language
        is_reseller
        dial_plan
        descendants_count
        created
        contract
        contact
        caller_id
        call_restriction
        metaflows
        parent_id
        account_id
        _read_only
        wnm_allow_additions
        superduper_admin
        enabled
        billing_mode
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      mailbox_id
      _read_only
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listSwitchMailboxes = /* GraphQL */ `
  query ListSwitchMailboxes(
    $filter: ModelSwitchMailboxesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSwitchMailboxes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        mailbox
        name
        owner_id
        media_extension
        notify_email_addresses
        announcement_only
        check_if_owner
        delete_after_notify
        include_message_on_notify
        include_transcription_on_notify
        is_setup
        is_voicemail_ff_rw_enabled
        media
        not_configurable
        oldest_message_first
        require_pin
        save_after_notify
        seek_duration_ms
        skip_envelope
        skip_greeting
        skip_instructions
        transcribe
        vm_message_forward_type
        ui_metadata
        account_id
        mailbox_id
        _read_only
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncSwitchMailboxes = /* GraphQL */ `
  query SyncSwitchMailboxes(
    $filter: ModelSwitchMailboxesFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncSwitchMailboxes(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        mailbox
        name
        owner_id
        media_extension
        notify_email_addresses
        announcement_only
        check_if_owner
        delete_after_notify
        include_message_on_notify
        include_transcription_on_notify
        is_setup
        is_voicemail_ff_rw_enabled
        media
        not_configurable
        oldest_message_first
        require_pin
        save_after_notify
        seek_duration_ms
        skip_envelope
        skip_greeting
        skip_instructions
        transcribe
        vm_message_forward_type
        ui_metadata
        account_id
        mailbox_id
        _read_only
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const switchMailboxesByOwner_id = /* GraphQL */ `
  query SwitchMailboxesByOwner_id(
    $owner_id: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelSwitchMailboxesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    switchMailboxesByOwner_id(
      owner_id: $owner_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        mailbox
        name
        owner_id
        media_extension
        notify_email_addresses
        announcement_only
        check_if_owner
        delete_after_notify
        include_message_on_notify
        include_transcription_on_notify
        is_setup
        is_voicemail_ff_rw_enabled
        media
        not_configurable
        oldest_message_first
        require_pin
        save_after_notify
        seek_duration_ms
        skip_envelope
        skip_greeting
        skip_instructions
        transcribe
        vm_message_forward_type
        ui_metadata
        account_id
        mailbox_id
        _read_only
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const switchMailboxesByAccount_id = /* GraphQL */ `
  query SwitchMailboxesByAccount_id(
    $account_id: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelSwitchMailboxesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    switchMailboxesByAccount_id(
      account_id: $account_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        mailbox
        name
        owner_id
        media_extension
        notify_email_addresses
        announcement_only
        check_if_owner
        delete_after_notify
        include_message_on_notify
        include_transcription_on_notify
        is_setup
        is_voicemail_ff_rw_enabled
        media
        not_configurable
        oldest_message_first
        require_pin
        save_after_notify
        seek_duration_ms
        skip_envelope
        skip_greeting
        skip_instructions
        transcribe
        vm_message_forward_type
        ui_metadata
        account_id
        mailbox_id
        _read_only
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const switchMailboxesByMailbox_id = /* GraphQL */ `
  query SwitchMailboxesByMailbox_id(
    $mailbox_id: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelSwitchMailboxesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    switchMailboxesByMailbox_id(
      mailbox_id: $mailbox_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        mailbox
        name
        owner_id
        media_extension
        notify_email_addresses
        announcement_only
        check_if_owner
        delete_after_notify
        include_message_on_notify
        include_transcription_on_notify
        is_setup
        is_voicemail_ff_rw_enabled
        media
        not_configurable
        oldest_message_first
        require_pin
        save_after_notify
        seek_duration_ms
        skip_envelope
        skip_greeting
        skip_instructions
        transcribe
        vm_message_forward_type
        ui_metadata
        account_id
        mailbox_id
        _read_only
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getSwitchNumbers = /* GraphQL */ `
  query GetSwitchNumbers($id: ID!) {
    getSwitchNumbers(id: $id) {
      id
      number
      state
      prepended
      account_id
      account {
        id
        ui_restrictions
        ui_flags
        timezone
        ringtones
        reseller_id
        realm
        preflow
        notifications
        notification_preference
        name
        music_on_hold
        language
        is_reseller
        dial_plan
        descendants_count
        created
        contract
        contact
        caller_id
        call_restriction
        metaflows
        parent_id
        account_id
        _read_only
        wnm_allow_additions
        superduper_admin
        enabled
        billing_mode
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listSwitchNumbers = /* GraphQL */ `
  query ListSwitchNumbers(
    $filter: ModelSwitchNumbersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSwitchNumbers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        number
        state
        prepended
        account_id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncSwitchNumbers = /* GraphQL */ `
  query SyncSwitchNumbers(
    $filter: ModelSwitchNumbersFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncSwitchNumbers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        number
        state
        prepended
        account_id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const switchNumbersByNumber = /* GraphQL */ `
  query SwitchNumbersByNumber(
    $number: String!
    $sortDirection: ModelSortDirection
    $filter: ModelSwitchNumbersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    switchNumbersByNumber(
      number: $number
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        number
        state
        prepended
        account_id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const switchNumbersByAccount_id = /* GraphQL */ `
  query SwitchNumbersByAccount_id(
    $account_id: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelSwitchNumbersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    switchNumbersByAccount_id(
      account_id: $account_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        number
        state
        prepended
        account_id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getSwitchMedia = /* GraphQL */ `
  query GetSwitchMedia($id: ID!) {
    getSwitchMedia(id: $id) {
      id
      account_id
      account {
        id
        ui_restrictions
        ui_flags
        timezone
        ringtones
        reseller_id
        realm
        preflow
        notifications
        notification_preference
        name
        music_on_hold
        language
        is_reseller
        dial_plan
        descendants_count
        created
        contract
        contact
        caller_id
        call_restriction
        metaflows
        parent_id
        account_id
        _read_only
        wnm_allow_additions
        superduper_admin
        enabled
        billing_mode
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      media_object_id
      data
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listSwitchMedias = /* GraphQL */ `
  query ListSwitchMedias(
    $filter: ModelSwitchMediaFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSwitchMedias(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        account_id
        media_object_id
        data
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncSwitchMedias = /* GraphQL */ `
  query SyncSwitchMedias(
    $filter: ModelSwitchMediaFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncSwitchMedias(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        account_id
        media_object_id
        data
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const switchMediasByAccount_id = /* GraphQL */ `
  query SwitchMediasByAccount_id(
    $account_id: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelSwitchMediaFilterInput
    $limit: Int
    $nextToken: String
  ) {
    switchMediasByAccount_id(
      account_id: $account_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        account_id
        media_object_id
        data
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const switchMediasByMedia_object_id = /* GraphQL */ `
  query SwitchMediasByMedia_object_id(
    $media_object_id: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelSwitchMediaFilterInput
    $limit: Int
    $nextToken: String
  ) {
    switchMediasByMedia_object_id(
      media_object_id: $media_object_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        account_id
        media_object_id
        data
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getSwitchDevices = /* GraphQL */ `
  query GetSwitchDevices($id: ID!) {
    getSwitchDevices(id: $id) {
      id
      device_type
      enabled
      name
      sip
      owner_id
      caller_id
      register_overwrite_notify
      mwi_unsolicited_updates
      mwi_unsolicitated_updates
      exclude_from_queues
      contact_list
      call_restriction
      media
      suppress_unregister_notifications
      music_on_hold
      ringtones
      account_id
      account {
        id
        ui_restrictions
        ui_flags
        timezone
        ringtones
        reseller_id
        realm
        preflow
        notifications
        notification_preference
        name
        music_on_hold
        language
        is_reseller
        dial_plan
        descendants_count
        created
        contract
        contact
        caller_id
        call_restriction
        metaflows
        parent_id
        account_id
        _read_only
        wnm_allow_additions
        superduper_admin
        enabled
        billing_mode
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      device_id
      _read_only
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listSwitchDevices = /* GraphQL */ `
  query ListSwitchDevices(
    $filter: ModelSwitchDevicesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSwitchDevices(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        device_type
        enabled
        name
        sip
        owner_id
        caller_id
        register_overwrite_notify
        mwi_unsolicited_updates
        mwi_unsolicitated_updates
        exclude_from_queues
        contact_list
        call_restriction
        media
        suppress_unregister_notifications
        music_on_hold
        ringtones
        account_id
        device_id
        _read_only
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncSwitchDevices = /* GraphQL */ `
  query SyncSwitchDevices(
    $filter: ModelSwitchDevicesFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncSwitchDevices(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        device_type
        enabled
        name
        sip
        owner_id
        caller_id
        register_overwrite_notify
        mwi_unsolicited_updates
        mwi_unsolicitated_updates
        exclude_from_queues
        contact_list
        call_restriction
        media
        suppress_unregister_notifications
        music_on_hold
        ringtones
        account_id
        device_id
        _read_only
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const switchDevicesByAccount_id = /* GraphQL */ `
  query SwitchDevicesByAccount_id(
    $account_id: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelSwitchDevicesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    switchDevicesByAccount_id(
      account_id: $account_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        device_type
        enabled
        name
        sip
        owner_id
        caller_id
        register_overwrite_notify
        mwi_unsolicited_updates
        mwi_unsolicitated_updates
        exclude_from_queues
        contact_list
        call_restriction
        media
        suppress_unregister_notifications
        music_on_hold
        ringtones
        account_id
        device_id
        _read_only
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const switchDevicesByDevice_id = /* GraphQL */ `
  query SwitchDevicesByDevice_id(
    $device_id: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelSwitchDevicesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    switchDevicesByDevice_id(
      device_id: $device_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        device_type
        enabled
        name
        sip
        owner_id
        caller_id
        register_overwrite_notify
        mwi_unsolicited_updates
        mwi_unsolicitated_updates
        exclude_from_queues
        contact_list
        call_restriction
        media
        suppress_unregister_notifications
        music_on_hold
        ringtones
        account_id
        device_id
        _read_only
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const searchSwitchDevices = /* GraphQL */ `
  query SearchSwitchDevices(
    $filter: SearchableSwitchDevicesFilterInput
    $sort: [SearchableSwitchDevicesSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableSwitchDevicesAggregationInput]
  ) {
    searchSwitchDevices(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        device_type
        enabled
        name
        sip
        owner_id
        caller_id
        register_overwrite_notify
        mwi_unsolicited_updates
        mwi_unsolicitated_updates
        exclude_from_queues
        contact_list
        call_restriction
        media
        suppress_unregister_notifications
        music_on_hold
        ringtones
        account_id
        device_id
        _read_only
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
              __typename
            }
          }
        }
        __typename
      }
      __typename
    }
  }
`;
export const getSwitchConnectivity = /* GraphQL */ `
  query GetSwitchConnectivity($id: ID!) {
    getSwitchConnectivity(id: $id) {
      id
      account
      billing_account_id
      DIDs_Unassigned
      servers
      account_id
      switch_account {
        id
        ui_restrictions
        ui_flags
        timezone
        ringtones
        reseller_id
        realm
        preflow
        notifications
        notification_preference
        name
        music_on_hold
        language
        is_reseller
        dial_plan
        descendants_count
        created
        contract
        contact
        caller_id
        call_restriction
        metaflows
        parent_id
        account_id
        _read_only
        wnm_allow_additions
        superduper_admin
        enabled
        billing_mode
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      connectivity_id
      _read_only
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listSwitchConnectivities = /* GraphQL */ `
  query ListSwitchConnectivities(
    $filter: ModelSwitchConnectivityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSwitchConnectivities(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        account
        billing_account_id
        DIDs_Unassigned
        servers
        account_id
        connectivity_id
        _read_only
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncSwitchConnectivities = /* GraphQL */ `
  query SyncSwitchConnectivities(
    $filter: ModelSwitchConnectivityFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncSwitchConnectivities(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        account
        billing_account_id
        DIDs_Unassigned
        servers
        account_id
        connectivity_id
        _read_only
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const switchConnectivitiesByAccount_id = /* GraphQL */ `
  query SwitchConnectivitiesByAccount_id(
    $account_id: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelSwitchConnectivityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    switchConnectivitiesByAccount_id(
      account_id: $account_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        account
        billing_account_id
        DIDs_Unassigned
        servers
        account_id
        connectivity_id
        _read_only
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const switchConnectivitiesByConnectivity_id = /* GraphQL */ `
  query SwitchConnectivitiesByConnectivity_id(
    $connectivity_id: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelSwitchConnectivityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    switchConnectivitiesByConnectivity_id(
      connectivity_id: $connectivity_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        account
        billing_account_id
        DIDs_Unassigned
        servers
        account_id
        connectivity_id
        _read_only
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getSwitchCallflows = /* GraphQL */ `
  query GetSwitchCallflows($id: ID!) {
    getSwitchCallflows(id: $id) {
      id
      flow
      numbers
      patterns
      account_id
      account {
        id
        ui_restrictions
        ui_flags
        timezone
        ringtones
        reseller_id
        realm
        preflow
        notifications
        notification_preference
        name
        music_on_hold
        language
        is_reseller
        dial_plan
        descendants_count
        created
        contract
        contact
        caller_id
        call_restriction
        metaflows
        parent_id
        account_id
        _read_only
        wnm_allow_additions
        superduper_admin
        enabled
        billing_mode
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      featurecode
      contact_list
      metadata
      callflow_id
      _read_only
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listSwitchCallflows = /* GraphQL */ `
  query ListSwitchCallflows(
    $filter: ModelSwitchCallflowsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSwitchCallflows(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        flow
        numbers
        patterns
        account_id
        featurecode
        contact_list
        metadata
        callflow_id
        _read_only
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncSwitchCallflows = /* GraphQL */ `
  query SyncSwitchCallflows(
    $filter: ModelSwitchCallflowsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncSwitchCallflows(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        flow
        numbers
        patterns
        account_id
        featurecode
        contact_list
        metadata
        callflow_id
        _read_only
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const switchCallflowsByNumbers = /* GraphQL */ `
  query SwitchCallflowsByNumbers(
    $numbers: String!
    $sortDirection: ModelSortDirection
    $filter: ModelSwitchCallflowsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    switchCallflowsByNumbers(
      numbers: $numbers
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        flow
        numbers
        patterns
        account_id
        featurecode
        contact_list
        metadata
        callflow_id
        _read_only
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const switchCallflowsByAccount_id = /* GraphQL */ `
  query SwitchCallflowsByAccount_id(
    $account_id: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelSwitchCallflowsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    switchCallflowsByAccount_id(
      account_id: $account_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        flow
        numbers
        patterns
        account_id
        featurecode
        contact_list
        metadata
        callflow_id
        _read_only
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const switchCallflowsByCallflow_id = /* GraphQL */ `
  query SwitchCallflowsByCallflow_id(
    $callflow_id: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelSwitchCallflowsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    switchCallflowsByCallflow_id(
      callflow_id: $callflow_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        flow
        numbers
        patterns
        account_id
        featurecode
        contact_list
        metadata
        callflow_id
        _read_only
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getSwitchAccounts = /* GraphQL */ `
  query GetSwitchAccounts($id: ID!) {
    getSwitchAccounts(id: $id) {
      id
      ui_restrictions
      ui_flags
      timezone
      ringtones
      reseller_id
      realm
      preflow
      notifications
      notification_preference
      name
      music_on_hold
      language
      is_reseller
      dial_plan
      descendants_count
      created
      contract
      contact
      caller_id
      call_restriction
      call_flows {
        nextToken
        startedAt
        __typename
      }
      connectivity {
        nextToken
        startedAt
        __typename
      }
      devices {
        nextToken
        startedAt
        __typename
      }
      media {
        nextToken
        startedAt
        __typename
      }
      numbers {
        nextToken
        startedAt
        __typename
      }
      mail_boxes {
        nextToken
        startedAt
        __typename
      }
      users {
        nextToken
        startedAt
        __typename
      }
      metaflows
      parent_id
      account_id
      children_accounts {
        nextToken
        startedAt
        __typename
      }
      parent_account {
        nextToken
        startedAt
        __typename
      }
      _read_only
      wnm_allow_additions
      superduper_admin
      enabled
      billing_mode
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listSwitchAccounts = /* GraphQL */ `
  query ListSwitchAccounts(
    $filter: ModelSwitchAccountsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSwitchAccounts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        ui_restrictions
        ui_flags
        timezone
        ringtones
        reseller_id
        realm
        preflow
        notifications
        notification_preference
        name
        music_on_hold
        language
        is_reseller
        dial_plan
        descendants_count
        created
        contract
        contact
        caller_id
        call_restriction
        metaflows
        parent_id
        account_id
        _read_only
        wnm_allow_additions
        superduper_admin
        enabled
        billing_mode
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncSwitchAccounts = /* GraphQL */ `
  query SyncSwitchAccounts(
    $filter: ModelSwitchAccountsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncSwitchAccounts(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        ui_restrictions
        ui_flags
        timezone
        ringtones
        reseller_id
        realm
        preflow
        notifications
        notification_preference
        name
        music_on_hold
        language
        is_reseller
        dial_plan
        descendants_count
        created
        contract
        contact
        caller_id
        call_restriction
        metaflows
        parent_id
        account_id
        _read_only
        wnm_allow_additions
        superduper_admin
        enabled
        billing_mode
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const switchAccountsByParent_id = /* GraphQL */ `
  query SwitchAccountsByParent_id(
    $parent_id: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelSwitchAccountsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    switchAccountsByParent_id(
      parent_id: $parent_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        ui_restrictions
        ui_flags
        timezone
        ringtones
        reseller_id
        realm
        preflow
        notifications
        notification_preference
        name
        music_on_hold
        language
        is_reseller
        dial_plan
        descendants_count
        created
        contract
        contact
        caller_id
        call_restriction
        metaflows
        parent_id
        account_id
        _read_only
        wnm_allow_additions
        superduper_admin
        enabled
        billing_mode
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const switchAccountsByAccount_id = /* GraphQL */ `
  query SwitchAccountsByAccount_id(
    $account_id: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelSwitchAccountsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    switchAccountsByAccount_id(
      account_id: $account_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        ui_restrictions
        ui_flags
        timezone
        ringtones
        reseller_id
        realm
        preflow
        notifications
        notification_preference
        name
        music_on_hold
        language
        is_reseller
        dial_plan
        descendants_count
        created
        contract
        contact
        caller_id
        call_restriction
        metaflows
        parent_id
        account_id
        _read_only
        wnm_allow_additions
        superduper_admin
        enabled
        billing_mode
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getRevioServices = /* GraphQL */ `
  query GetRevioServices($id: ID!) {
    getRevioServices(id: $id) {
      id
      service_id
      customer_id
      provider_id
      service_type_id
      package_id
      number
      pic
      lpic
      provider_account_id
      usage_plan_group_id
      number2
      description
      disconnected_date
      activated_date
      address_id
      addressz_id
      provider_account_number
      contract_start
      contract_end
      created_date
      hunt_group
      circuit_number2
      circuit_foc_date
      notes
      location_routing_number
      fields
      request_service_id
      revio_customer {
        id
        customer_id
        billing_address_id
        service_address_id
        listing_address_id
        parent_customer_id
        parent_customer_id_sort
        account_number
        activated_date
        agent_id
        secondary_agent_id
        close_date
        created_by
        created_date
        updated_date
        email
        is_parent
        registration_code
        security_pin
        sold_by
        source
        status
        auto_debit_enabled
        auto_debit_day
        auto_debit_payment_account_id
        bill_profile_id
        billing_method
        balance_limit
        balance_limit_enabled
        collection_template_id
        contract_end_date
        contract_start_date
        cycle_date
        late_fees_enabled
        payment_terms_days
        numbers
        location_id
        tax_exempt_enabled
        tax_exempt_types
        usage_file_enabled
        usage_file_format
        payment_fees_enabled
        class
        date_of_birth
        language
        tax_id
        voip_switch_id
        suspended
        suspended_date
        culture
        fields
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listRevioServices = /* GraphQL */ `
  query ListRevioServices(
    $filter: ModelRevioServicesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRevioServices(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        service_id
        customer_id
        provider_id
        service_type_id
        package_id
        number
        pic
        lpic
        provider_account_id
        usage_plan_group_id
        number2
        description
        disconnected_date
        activated_date
        address_id
        addressz_id
        provider_account_number
        contract_start
        contract_end
        created_date
        hunt_group
        circuit_number2
        circuit_foc_date
        notes
        location_routing_number
        fields
        request_service_id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncRevioServices = /* GraphQL */ `
  query SyncRevioServices(
    $filter: ModelRevioServicesFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncRevioServices(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        service_id
        customer_id
        provider_id
        service_type_id
        package_id
        number
        pic
        lpic
        provider_account_id
        usage_plan_group_id
        number2
        description
        disconnected_date
        activated_date
        address_id
        addressz_id
        provider_account_number
        contract_start
        contract_end
        created_date
        hunt_group
        circuit_number2
        circuit_foc_date
        notes
        location_routing_number
        fields
        request_service_id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const revioServicesByService_id = /* GraphQL */ `
  query RevioServicesByService_id(
    $service_id: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelRevioServicesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    revioServicesByService_id(
      service_id: $service_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        service_id
        customer_id
        provider_id
        service_type_id
        package_id
        number
        pic
        lpic
        provider_account_id
        usage_plan_group_id
        number2
        description
        disconnected_date
        activated_date
        address_id
        addressz_id
        provider_account_number
        contract_start
        contract_end
        created_date
        hunt_group
        circuit_number2
        circuit_foc_date
        notes
        location_routing_number
        fields
        request_service_id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const revioServicesByCustomer_id = /* GraphQL */ `
  query RevioServicesByCustomer_id(
    $customer_id: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelRevioServicesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    revioServicesByCustomer_id(
      customer_id: $customer_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        service_id
        customer_id
        provider_id
        service_type_id
        package_id
        number
        pic
        lpic
        provider_account_id
        usage_plan_group_id
        number2
        description
        disconnected_date
        activated_date
        address_id
        addressz_id
        provider_account_number
        contract_start
        contract_end
        created_date
        hunt_group
        circuit_number2
        circuit_foc_date
        notes
        location_routing_number
        fields
        request_service_id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const revioServicesByNumber = /* GraphQL */ `
  query RevioServicesByNumber(
    $number: String!
    $sortDirection: ModelSortDirection
    $filter: ModelRevioServicesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    revioServicesByNumber(
      number: $number
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        service_id
        customer_id
        provider_id
        service_type_id
        package_id
        number
        pic
        lpic
        provider_account_id
        usage_plan_group_id
        number2
        description
        disconnected_date
        activated_date
        address_id
        addressz_id
        provider_account_number
        contract_start
        contract_end
        created_date
        hunt_group
        circuit_number2
        circuit_foc_date
        notes
        location_routing_number
        fields
        request_service_id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getRevioServiceProducts = /* GraphQL */ `
  query GetRevioServiceProducts($id: ID!) {
    getRevioServiceProducts(id: $id) {
      id
      service_product_id
      customer_id
      product_id
      product_information {
        nextToken
        startedAt
        __typename
      }
      package_id
      service_id
      description
      code_1
      code_2
      rate
      billed_through_date
      canceled_date
      status
      status_date
      status_user_id
      activated_date
      cost
      wholesale_description
      free_start_date
      free_end_date
      quantity
      contract_start_date
      contract_end_date
      created_date
      tax_included
      group_on_bill
      itemized
      fields
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listRevioServiceProducts = /* GraphQL */ `
  query ListRevioServiceProducts(
    $filter: ModelRevioServiceProductsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRevioServiceProducts(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        service_product_id
        customer_id
        product_id
        package_id
        service_id
        description
        code_1
        code_2
        rate
        billed_through_date
        canceled_date
        status
        status_date
        status_user_id
        activated_date
        cost
        wholesale_description
        free_start_date
        free_end_date
        quantity
        contract_start_date
        contract_end_date
        created_date
        tax_included
        group_on_bill
        itemized
        fields
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncRevioServiceProducts = /* GraphQL */ `
  query SyncRevioServiceProducts(
    $filter: ModelRevioServiceProductsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncRevioServiceProducts(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        service_product_id
        customer_id
        product_id
        package_id
        service_id
        description
        code_1
        code_2
        rate
        billed_through_date
        canceled_date
        status
        status_date
        status_user_id
        activated_date
        cost
        wholesale_description
        free_start_date
        free_end_date
        quantity
        contract_start_date
        contract_end_date
        created_date
        tax_included
        group_on_bill
        itemized
        fields
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const revioServiceProductsByService_product_id = /* GraphQL */ `
  query RevioServiceProductsByService_product_id(
    $service_product_id: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelRevioServiceProductsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    revioServiceProductsByService_product_id(
      service_product_id: $service_product_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        service_product_id
        customer_id
        product_id
        package_id
        service_id
        description
        code_1
        code_2
        rate
        billed_through_date
        canceled_date
        status
        status_date
        status_user_id
        activated_date
        cost
        wholesale_description
        free_start_date
        free_end_date
        quantity
        contract_start_date
        contract_end_date
        created_date
        tax_included
        group_on_bill
        itemized
        fields
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const revioServiceProductsByCustomer_id = /* GraphQL */ `
  query RevioServiceProductsByCustomer_id(
    $customer_id: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelRevioServiceProductsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    revioServiceProductsByCustomer_id(
      customer_id: $customer_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        service_product_id
        customer_id
        product_id
        package_id
        service_id
        description
        code_1
        code_2
        rate
        billed_through_date
        canceled_date
        status
        status_date
        status_user_id
        activated_date
        cost
        wholesale_description
        free_start_date
        free_end_date
        quantity
        contract_start_date
        contract_end_date
        created_date
        tax_included
        group_on_bill
        itemized
        fields
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const revioServiceProductsByService_id = /* GraphQL */ `
  query RevioServiceProductsByService_id(
    $service_id: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelRevioServiceProductsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    revioServiceProductsByService_id(
      service_id: $service_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        service_product_id
        customer_id
        product_id
        package_id
        service_id
        description
        code_1
        code_2
        rate
        billed_through_date
        canceled_date
        status
        status_date
        status_user_id
        activated_date
        cost
        wholesale_description
        free_start_date
        free_end_date
        quantity
        contract_start_date
        contract_end_date
        created_date
        tax_included
        group_on_bill
        itemized
        fields
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getRevioServiceInventory = /* GraphQL */ `
  query GetRevioServiceInventory($id: ID!) {
    getRevioServiceInventory(id: $id) {
      id
      inventory_item_id
      customer_id
      line_id
      line_type
      identifier
      inventory_type
      status
      assigned_date
      assigned_by
      note
      created_date
      user_created_by
      NPA
      NXX
      revio_customer {
        id
        customer_id
        billing_address_id
        service_address_id
        listing_address_id
        parent_customer_id
        parent_customer_id_sort
        account_number
        activated_date
        agent_id
        secondary_agent_id
        close_date
        created_by
        created_date
        updated_date
        email
        is_parent
        registration_code
        security_pin
        sold_by
        source
        status
        auto_debit_enabled
        auto_debit_day
        auto_debit_payment_account_id
        bill_profile_id
        billing_method
        balance_limit
        balance_limit_enabled
        collection_template_id
        contract_end_date
        contract_start_date
        cycle_date
        late_fees_enabled
        payment_terms_days
        numbers
        location_id
        tax_exempt_enabled
        tax_exempt_types
        usage_file_enabled
        usage_file_format
        payment_fees_enabled
        class
        date_of_birth
        language
        tax_id
        voip_switch_id
        suspended
        suspended_date
        culture
        fields
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listRevioServiceInventories = /* GraphQL */ `
  query ListRevioServiceInventories(
    $filter: ModelRevioServiceInventoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRevioServiceInventories(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        inventory_item_id
        customer_id
        line_id
        line_type
        identifier
        inventory_type
        status
        assigned_date
        assigned_by
        note
        created_date
        user_created_by
        NPA
        NXX
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncRevioServiceInventories = /* GraphQL */ `
  query SyncRevioServiceInventories(
    $filter: ModelRevioServiceInventoryFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncRevioServiceInventories(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        inventory_item_id
        customer_id
        line_id
        line_type
        identifier
        inventory_type
        status
        assigned_date
        assigned_by
        note
        created_date
        user_created_by
        NPA
        NXX
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const revioServiceInventoriesByInventory_item_id = /* GraphQL */ `
  query RevioServiceInventoriesByInventory_item_id(
    $inventory_item_id: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelRevioServiceInventoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    revioServiceInventoriesByInventory_item_id(
      inventory_item_id: $inventory_item_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        inventory_item_id
        customer_id
        line_id
        line_type
        identifier
        inventory_type
        status
        assigned_date
        assigned_by
        note
        created_date
        user_created_by
        NPA
        NXX
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const revioServiceInventoriesByCustomer_id = /* GraphQL */ `
  query RevioServiceInventoriesByCustomer_id(
    $customer_id: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelRevioServiceInventoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    revioServiceInventoriesByCustomer_id(
      customer_id: $customer_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        inventory_item_id
        customer_id
        line_id
        line_type
        identifier
        inventory_type
        status
        assigned_date
        assigned_by
        note
        created_date
        user_created_by
        NPA
        NXX
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const revioServiceInventoriesByLine_id = /* GraphQL */ `
  query RevioServiceInventoriesByLine_id(
    $line_id: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelRevioServiceInventoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    revioServiceInventoriesByLine_id(
      line_id: $line_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        inventory_item_id
        customer_id
        line_id
        line_type
        identifier
        inventory_type
        status
        assigned_date
        assigned_by
        note
        created_date
        user_created_by
        NPA
        NXX
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getRevioProductTypes = /* GraphQL */ `
  query GetRevioProductTypes($id: ID!) {
    getRevioProductTypes(id: $id) {
      id
      product_type_id
      product_type_code
      description
      tax_class_id
      active
      one_time_flag
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listRevioProductTypes = /* GraphQL */ `
  query ListRevioProductTypes(
    $filter: ModelRevioProductTypesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRevioProductTypes(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        product_type_id
        product_type_code
        description
        tax_class_id
        active
        one_time_flag
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncRevioProductTypes = /* GraphQL */ `
  query SyncRevioProductTypes(
    $filter: ModelRevioProductTypesFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncRevioProductTypes(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        product_type_id
        product_type_code
        description
        tax_class_id
        active
        one_time_flag
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const revioProductTypesByProduct_type_id = /* GraphQL */ `
  query RevioProductTypesByProduct_type_id(
    $product_type_id: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelRevioProductTypesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    revioProductTypesByProduct_type_id(
      product_type_id: $product_type_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        product_type_id
        product_type_code
        description
        tax_class_id
        active
        one_time_flag
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getRevioProducts = /* GraphQL */ `
  query GetRevioProducts($id: ID!) {
    getRevioProducts(id: $id) {
      id
      currency_code
      product_id
      product_type_id
      product_type {
        nextToken
        startedAt
        __typename
      }
      description
      code_1
      code_2
      rate
      cost
      buy_rate
      created_date
      created_by
      active
      creates_order
      provider_id
      bills_in_arrears
      prorates
      prorates_on_disconnect
      customer_class
      long_description
      ledger_code
      free_months
      automatic_expiration_months
      order_completion_billing
      tax_class_id
      tax_code {
        nextToken
        startedAt
        __typename
      }
      wholesale_description
      smart_pricing_id
      bill_profile_ids
      service_type_ids
      fields
      category_id
      is_credit_account_type
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listRevioProducts = /* GraphQL */ `
  query ListRevioProducts(
    $filter: ModelRevioProductsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRevioProducts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        currency_code
        product_id
        product_type_id
        description
        code_1
        code_2
        rate
        cost
        buy_rate
        created_date
        created_by
        active
        creates_order
        provider_id
        bills_in_arrears
        prorates
        prorates_on_disconnect
        customer_class
        long_description
        ledger_code
        free_months
        automatic_expiration_months
        order_completion_billing
        tax_class_id
        wholesale_description
        smart_pricing_id
        bill_profile_ids
        service_type_ids
        fields
        category_id
        is_credit_account_type
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncRevioProducts = /* GraphQL */ `
  query SyncRevioProducts(
    $filter: ModelRevioProductsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncRevioProducts(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        currency_code
        product_id
        product_type_id
        description
        code_1
        code_2
        rate
        cost
        buy_rate
        created_date
        created_by
        active
        creates_order
        provider_id
        bills_in_arrears
        prorates
        prorates_on_disconnect
        customer_class
        long_description
        ledger_code
        free_months
        automatic_expiration_months
        order_completion_billing
        tax_class_id
        wholesale_description
        smart_pricing_id
        bill_profile_ids
        service_type_ids
        fields
        category_id
        is_credit_account_type
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const revioProductsByProduct_id = /* GraphQL */ `
  query RevioProductsByProduct_id(
    $product_id: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelRevioProductsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    revioProductsByProduct_id(
      product_id: $product_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        currency_code
        product_id
        product_type_id
        description
        code_1
        code_2
        rate
        cost
        buy_rate
        created_date
        created_by
        active
        creates_order
        provider_id
        bills_in_arrears
        prorates
        prorates_on_disconnect
        customer_class
        long_description
        ledger_code
        free_months
        automatic_expiration_months
        order_completion_billing
        tax_class_id
        wholesale_description
        smart_pricing_id
        bill_profile_ids
        service_type_ids
        fields
        category_id
        is_credit_account_type
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const revioProductsByCode_1 = /* GraphQL */ `
  query RevioProductsByCode_1(
    $code_1: String!
    $sortDirection: ModelSortDirection
    $filter: ModelRevioProductsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    revioProductsByCode_1(
      code_1: $code_1
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        currency_code
        product_id
        product_type_id
        description
        code_1
        code_2
        rate
        cost
        buy_rate
        created_date
        created_by
        active
        creates_order
        provider_id
        bills_in_arrears
        prorates
        prorates_on_disconnect
        customer_class
        long_description
        ledger_code
        free_months
        automatic_expiration_months
        order_completion_billing
        tax_class_id
        wholesale_description
        smart_pricing_id
        bill_profile_ids
        service_type_ids
        fields
        category_id
        is_credit_account_type
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getRevioInventoryTypes = /* GraphQL */ `
  query GetRevioInventoryTypes($id: ID!) {
    getRevioInventoryTypes(id: $id) {
      id
      inventory_type_id
      category
      identifier
      name
      requires_product
      description
      status
      format
      ratable
      fields
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listRevioInventoryTypes = /* GraphQL */ `
  query ListRevioInventoryTypes(
    $filter: ModelRevioInventoryTypesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRevioInventoryTypes(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        inventory_type_id
        category
        identifier
        name
        requires_product
        description
        status
        format
        ratable
        fields
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncRevioInventoryTypes = /* GraphQL */ `
  query SyncRevioInventoryTypes(
    $filter: ModelRevioInventoryTypesFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncRevioInventoryTypes(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        inventory_type_id
        category
        identifier
        name
        requires_product
        description
        status
        format
        ratable
        fields
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const revioInventoryTypesByInventory_type_id = /* GraphQL */ `
  query RevioInventoryTypesByInventory_type_id(
    $inventory_type_id: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelRevioInventoryTypesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    revioInventoryTypesByInventory_type_id(
      inventory_type_id: $inventory_type_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        inventory_type_id
        category
        identifier
        name
        requires_product
        description
        status
        format
        ratable
        fields
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getRevioDialPrefixRates = /* GraphQL */ `
  query GetRevioDialPrefixRates($id: ID!) {
    getRevioDialPrefixRates(id: $id) {
      id
      country_id
      country
      dial_prefix
      retail_rate
      wholesale_rate
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listRevioDialPrefixRates = /* GraphQL */ `
  query ListRevioDialPrefixRates(
    $filter: ModelRevioDialPrefixRatesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRevioDialPrefixRates(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        country_id
        country
        dial_prefix
        retail_rate
        wholesale_rate
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncRevioDialPrefixRates = /* GraphQL */ `
  query SyncRevioDialPrefixRates(
    $filter: ModelRevioDialPrefixRatesFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncRevioDialPrefixRates(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        country_id
        country
        dial_prefix
        retail_rate
        wholesale_rate
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const revioDialPrefixRatesByDial_prefix = /* GraphQL */ `
  query RevioDialPrefixRatesByDial_prefix(
    $dial_prefix: String!
    $sortDirection: ModelSortDirection
    $filter: ModelRevioDialPrefixRatesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    revioDialPrefixRatesByDial_prefix(
      dial_prefix: $dial_prefix
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        country_id
        country
        dial_prefix
        retail_rate
        wholesale_rate
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const searchRevioDialPrefixRates = /* GraphQL */ `
  query SearchRevioDialPrefixRates(
    $filter: SearchableRevioDialPrefixRatesFilterInput
    $sort: [SearchableRevioDialPrefixRatesSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableRevioDialPrefixRatesAggregationInput]
  ) {
    searchRevioDialPrefixRates(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        country_id
        country
        dial_prefix
        retail_rate
        wholesale_rate
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
              __typename
            }
          }
        }
        __typename
      }
      __typename
    }
  }
`;
export const getRevioInventoryItems = /* GraphQL */ `
  query GetRevioInventoryItems($id: ID!) {
    getRevioInventoryItems(id: $id) {
      id
      inventory_item_id
      inventory_item_type_id
      inventory_type {
        nextToken
        startedAt
        __typename
      }
      identifier
      customer_id
      created_date
      created_by
      status
      inventory_item_unavailable_reason_id
      note
      fields
      revio_customer {
        id
        customer_id
        billing_address_id
        service_address_id
        listing_address_id
        parent_customer_id
        parent_customer_id_sort
        account_number
        activated_date
        agent_id
        secondary_agent_id
        close_date
        created_by
        created_date
        updated_date
        email
        is_parent
        registration_code
        security_pin
        sold_by
        source
        status
        auto_debit_enabled
        auto_debit_day
        auto_debit_payment_account_id
        bill_profile_id
        billing_method
        balance_limit
        balance_limit_enabled
        collection_template_id
        contract_end_date
        contract_start_date
        cycle_date
        late_fees_enabled
        payment_terms_days
        numbers
        location_id
        tax_exempt_enabled
        tax_exempt_types
        usage_file_enabled
        usage_file_format
        payment_fees_enabled
        class
        date_of_birth
        language
        tax_id
        voip_switch_id
        suspended
        suspended_date
        culture
        fields
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listRevioInventoryItems = /* GraphQL */ `
  query ListRevioInventoryItems(
    $filter: ModelRevioInventoryItemsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRevioInventoryItems(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        inventory_item_id
        inventory_item_type_id
        identifier
        customer_id
        created_date
        created_by
        status
        inventory_item_unavailable_reason_id
        note
        fields
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncRevioInventoryItems = /* GraphQL */ `
  query SyncRevioInventoryItems(
    $filter: ModelRevioInventoryItemsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncRevioInventoryItems(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        inventory_item_id
        inventory_item_type_id
        identifier
        customer_id
        created_date
        created_by
        status
        inventory_item_unavailable_reason_id
        note
        fields
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const revioInventoryItemsByInventory_item_id = /* GraphQL */ `
  query RevioInventoryItemsByInventory_item_id(
    $inventory_item_id: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelRevioInventoryItemsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    revioInventoryItemsByInventory_item_id(
      inventory_item_id: $inventory_item_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        inventory_item_id
        inventory_item_type_id
        identifier
        customer_id
        created_date
        created_by
        status
        inventory_item_unavailable_reason_id
        note
        fields
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const revioInventoryItemsByIdentifier = /* GraphQL */ `
  query RevioInventoryItemsByIdentifier(
    $identifier: String!
    $sortDirection: ModelSortDirection
    $filter: ModelRevioInventoryItemsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    revioInventoryItemsByIdentifier(
      identifier: $identifier
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        inventory_item_id
        inventory_item_type_id
        identifier
        customer_id
        created_date
        created_by
        status
        inventory_item_unavailable_reason_id
        note
        fields
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const revioInventoryItemsByCustomer_id = /* GraphQL */ `
  query RevioInventoryItemsByCustomer_id(
    $customer_id: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelRevioInventoryItemsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    revioInventoryItemsByCustomer_id(
      customer_id: $customer_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        inventory_item_id
        inventory_item_type_id
        identifier
        customer_id
        created_date
        created_by
        status
        inventory_item_unavailable_reason_id
        note
        fields
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getRevioCharges = /* GraphQL */ `
  query GetRevioCharges($id: ID!) {
    getRevioCharges(id: $id) {
      id
      charge_id
      product_id
      product_type_id
      customer_id
      bill_id
      service_id
      service_product_id
      is_tax_included
      tax_class_id
      description
      amount
      cost
      product_amount
      is_commision_exempted
      tax_amount
      geocode
      proration
      created_date
      created_by
      is_prorated
      start_date
      end_date
      rate
      quantity
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listRevioCharges = /* GraphQL */ `
  query ListRevioCharges(
    $filter: ModelRevioChargesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRevioCharges(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        charge_id
        product_id
        product_type_id
        customer_id
        bill_id
        service_id
        service_product_id
        is_tax_included
        tax_class_id
        description
        amount
        cost
        product_amount
        is_commision_exempted
        tax_amount
        geocode
        proration
        created_date
        created_by
        is_prorated
        start_date
        end_date
        rate
        quantity
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncRevioCharges = /* GraphQL */ `
  query SyncRevioCharges(
    $filter: ModelRevioChargesFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncRevioCharges(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        charge_id
        product_id
        product_type_id
        customer_id
        bill_id
        service_id
        service_product_id
        is_tax_included
        tax_class_id
        description
        amount
        cost
        product_amount
        is_commision_exempted
        tax_amount
        geocode
        proration
        created_date
        created_by
        is_prorated
        start_date
        end_date
        rate
        quantity
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const revioChargesByCharge_id = /* GraphQL */ `
  query RevioChargesByCharge_id(
    $charge_id: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelRevioChargesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    revioChargesByCharge_id(
      charge_id: $charge_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        charge_id
        product_id
        product_type_id
        customer_id
        bill_id
        service_id
        service_product_id
        is_tax_included
        tax_class_id
        description
        amount
        cost
        product_amount
        is_commision_exempted
        tax_amount
        geocode
        proration
        created_date
        created_by
        is_prorated
        start_date
        end_date
        rate
        quantity
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getCustomerSupportInformation = /* GraphQL */ `
  query GetCustomerSupportInformation($id: ID!) {
    getCustomerSupportInformation(id: $id) {
      id
      partner
      partner_information {
        nextToken
        startedAt
        __typename
      }
      external_id
      service_type
      service_status
      electric_serviceman_number
      electric_sub_feeder
      electric_email_ticket
      contact_phone_number
      customer_type
      name
      address
      city
      state
      zip
      email
      is_les
      location_id
      service_package_info
      connection_date
      revio_id
      revio_customer {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listCustomerSupportInformations = /* GraphQL */ `
  query ListCustomerSupportInformations(
    $filter: ModelCustomerSupportInformationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCustomerSupportInformations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        partner
        external_id
        service_type
        service_status
        electric_serviceman_number
        electric_sub_feeder
        electric_email_ticket
        contact_phone_number
        customer_type
        name
        address
        city
        state
        zip
        email
        is_les
        location_id
        service_package_info
        connection_date
        revio_id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncCustomerSupportInformations = /* GraphQL */ `
  query SyncCustomerSupportInformations(
    $filter: ModelCustomerSupportInformationFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncCustomerSupportInformations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        partner
        external_id
        service_type
        service_status
        electric_serviceman_number
        electric_sub_feeder
        electric_email_ticket
        contact_phone_number
        customer_type
        name
        address
        city
        state
        zip
        email
        is_les
        location_id
        service_package_info
        connection_date
        revio_id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const customerSupportInformationsByPartner = /* GraphQL */ `
  query CustomerSupportInformationsByPartner(
    $partner: String!
    $sortDirection: ModelSortDirection
    $filter: ModelCustomerSupportInformationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    customerSupportInformationsByPartner(
      partner: $partner
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        partner
        external_id
        service_type
        service_status
        electric_serviceman_number
        electric_sub_feeder
        electric_email_ticket
        contact_phone_number
        customer_type
        name
        address
        city
        state
        zip
        email
        is_les
        location_id
        service_package_info
        connection_date
        revio_id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const customerSupportInformationsByExternal_idAndPartner = /* GraphQL */ `
  query CustomerSupportInformationsByExternal_idAndPartner(
    $external_id: ID!
    $partner: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCustomerSupportInformationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    customerSupportInformationsByExternal_idAndPartner(
      external_id: $external_id
      partner: $partner
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        partner
        external_id
        service_type
        service_status
        electric_serviceman_number
        electric_sub_feeder
        electric_email_ticket
        contact_phone_number
        customer_type
        name
        address
        city
        state
        zip
        email
        is_les
        location_id
        service_package_info
        connection_date
        revio_id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const customerSupportInformationsByRevio_id = /* GraphQL */ `
  query CustomerSupportInformationsByRevio_id(
    $revio_id: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelCustomerSupportInformationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    customerSupportInformationsByRevio_id(
      revio_id: $revio_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        partner
        external_id
        service_type
        service_status
        electric_serviceman_number
        electric_sub_feeder
        electric_email_ticket
        contact_phone_number
        customer_type
        name
        address
        city
        state
        zip
        email
        is_les
        location_id
        service_package_info
        connection_date
        revio_id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const searchCustomerSupportInformations = /* GraphQL */ `
  query SearchCustomerSupportInformations(
    $filter: SearchableCustomerSupportInformationFilterInput
    $sort: [SearchableCustomerSupportInformationSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableCustomerSupportInformationAggregationInput]
  ) {
    searchCustomerSupportInformations(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        partner
        external_id
        service_type
        service_status
        electric_serviceman_number
        electric_sub_feeder
        electric_email_ticket
        contact_phone_number
        customer_type
        name
        address
        city
        state
        zip
        email
        is_les
        location_id
        service_package_info
        connection_date
        revio_id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
              __typename
            }
          }
        }
        __typename
      }
      __typename
    }
  }
`;
export const getRevioCustomers = /* GraphQL */ `
  query GetRevioCustomers($id: ID!) {
    getRevioCustomers(id: $id) {
      id
      customer_id
      billing_address_id
      service_address_id
      listing_address_id
      billing_address {
        nextToken
        startedAt
        __typename
      }
      service_address {
        nextToken
        startedAt
        __typename
      }
      listing_address {
        nextToken
        startedAt
        __typename
      }
      parent_customer_id
      parent_customer_id_sort
      account_number
      activated_date
      agent_id
      secondary_agent_id
      close_date
      created_by
      created_date
      updated_date
      email
      is_parent
      registration_code
      security_pin
      sold_by
      bills {
        nextToken
        startedAt
        __typename
      }
      inventory_items {
        nextToken
        startedAt
        __typename
      }
      services {
        nextToken
        startedAt
        __typename
      }
      service_inventory {
        nextToken
        startedAt
        __typename
      }
      service_products {
        nextToken
        startedAt
        __typename
      }
      source
      status
      auto_debit_enabled
      auto_debit_day
      auto_debit_payment_account_id
      bill_profile_id
      billing_method
      balance_limit
      balance_limit_enabled
      collection_template_id
      contract_end_date
      contract_start_date
      cycle_date
      late_fees_enabled
      payment_terms_days
      numbers
      location_id
      tax_exempt_enabled
      tax_exempt_types
      usage_file_enabled
      usage_file_format
      payment_fees_enabled
      class
      date_of_birth
      language
      tax_id
      voip_switch_id
      suspended
      suspended_date
      culture
      fields
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listRevioCustomers = /* GraphQL */ `
  query ListRevioCustomers(
    $filter: ModelRevioCustomersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRevioCustomers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        customer_id
        billing_address_id
        service_address_id
        listing_address_id
        parent_customer_id
        parent_customer_id_sort
        account_number
        activated_date
        agent_id
        secondary_agent_id
        close_date
        created_by
        created_date
        updated_date
        email
        is_parent
        registration_code
        security_pin
        sold_by
        source
        status
        auto_debit_enabled
        auto_debit_day
        auto_debit_payment_account_id
        bill_profile_id
        billing_method
        balance_limit
        balance_limit_enabled
        collection_template_id
        contract_end_date
        contract_start_date
        cycle_date
        late_fees_enabled
        payment_terms_days
        numbers
        location_id
        tax_exempt_enabled
        tax_exempt_types
        usage_file_enabled
        usage_file_format
        payment_fees_enabled
        class
        date_of_birth
        language
        tax_id
        voip_switch_id
        suspended
        suspended_date
        culture
        fields
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncRevioCustomers = /* GraphQL */ `
  query SyncRevioCustomers(
    $filter: ModelRevioCustomersFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncRevioCustomers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        customer_id
        billing_address_id
        service_address_id
        listing_address_id
        parent_customer_id
        parent_customer_id_sort
        account_number
        activated_date
        agent_id
        secondary_agent_id
        close_date
        created_by
        created_date
        updated_date
        email
        is_parent
        registration_code
        security_pin
        sold_by
        source
        status
        auto_debit_enabled
        auto_debit_day
        auto_debit_payment_account_id
        bill_profile_id
        billing_method
        balance_limit
        balance_limit_enabled
        collection_template_id
        contract_end_date
        contract_start_date
        cycle_date
        late_fees_enabled
        payment_terms_days
        numbers
        location_id
        tax_exempt_enabled
        tax_exempt_types
        usage_file_enabled
        usage_file_format
        payment_fees_enabled
        class
        date_of_birth
        language
        tax_id
        voip_switch_id
        suspended
        suspended_date
        culture
        fields
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const revioCustomersByCustomer_id = /* GraphQL */ `
  query RevioCustomersByCustomer_id(
    $customer_id: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelRevioCustomersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    revioCustomersByCustomer_id(
      customer_id: $customer_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        customer_id
        billing_address_id
        service_address_id
        listing_address_id
        parent_customer_id
        parent_customer_id_sort
        account_number
        activated_date
        agent_id
        secondary_agent_id
        close_date
        created_by
        created_date
        updated_date
        email
        is_parent
        registration_code
        security_pin
        sold_by
        source
        status
        auto_debit_enabled
        auto_debit_day
        auto_debit_payment_account_id
        bill_profile_id
        billing_method
        balance_limit
        balance_limit_enabled
        collection_template_id
        contract_end_date
        contract_start_date
        cycle_date
        late_fees_enabled
        payment_terms_days
        numbers
        location_id
        tax_exempt_enabled
        tax_exempt_types
        usage_file_enabled
        usage_file_format
        payment_fees_enabled
        class
        date_of_birth
        language
        tax_id
        voip_switch_id
        suspended
        suspended_date
        culture
        fields
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const revioCustomersByParent_customer_idAndAccount_number = /* GraphQL */ `
  query RevioCustomersByParent_customer_idAndAccount_number(
    $parent_customer_id: ID!
    $account_number: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelRevioCustomersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    revioCustomersByParent_customer_idAndAccount_number(
      parent_customer_id: $parent_customer_id
      account_number: $account_number
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        customer_id
        billing_address_id
        service_address_id
        listing_address_id
        parent_customer_id
        parent_customer_id_sort
        account_number
        activated_date
        agent_id
        secondary_agent_id
        close_date
        created_by
        created_date
        updated_date
        email
        is_parent
        registration_code
        security_pin
        sold_by
        source
        status
        auto_debit_enabled
        auto_debit_day
        auto_debit_payment_account_id
        bill_profile_id
        billing_method
        balance_limit
        balance_limit_enabled
        collection_template_id
        contract_end_date
        contract_start_date
        cycle_date
        late_fees_enabled
        payment_terms_days
        numbers
        location_id
        tax_exempt_enabled
        tax_exempt_types
        usage_file_enabled
        usage_file_format
        payment_fees_enabled
        class
        date_of_birth
        language
        tax_id
        voip_switch_id
        suspended
        suspended_date
        culture
        fields
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const revioCustomersByParent_customer_id_sort = /* GraphQL */ `
  query RevioCustomersByParent_customer_id_sort(
    $parent_customer_id_sort: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelRevioCustomersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    revioCustomersByParent_customer_id_sort(
      parent_customer_id_sort: $parent_customer_id_sort
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        customer_id
        billing_address_id
        service_address_id
        listing_address_id
        parent_customer_id
        parent_customer_id_sort
        account_number
        activated_date
        agent_id
        secondary_agent_id
        close_date
        created_by
        created_date
        updated_date
        email
        is_parent
        registration_code
        security_pin
        sold_by
        source
        status
        auto_debit_enabled
        auto_debit_day
        auto_debit_payment_account_id
        bill_profile_id
        billing_method
        balance_limit
        balance_limit_enabled
        collection_template_id
        contract_end_date
        contract_start_date
        cycle_date
        late_fees_enabled
        payment_terms_days
        numbers
        location_id
        tax_exempt_enabled
        tax_exempt_types
        usage_file_enabled
        usage_file_format
        payment_fees_enabled
        class
        date_of_birth
        language
        tax_id
        voip_switch_id
        suspended
        suspended_date
        culture
        fields
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const revioCustomersByAccount_number = /* GraphQL */ `
  query RevioCustomersByAccount_number(
    $account_number: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelRevioCustomersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    revioCustomersByAccount_number(
      account_number: $account_number
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        customer_id
        billing_address_id
        service_address_id
        listing_address_id
        parent_customer_id
        parent_customer_id_sort
        account_number
        activated_date
        agent_id
        secondary_agent_id
        close_date
        created_by
        created_date
        updated_date
        email
        is_parent
        registration_code
        security_pin
        sold_by
        source
        status
        auto_debit_enabled
        auto_debit_day
        auto_debit_payment_account_id
        bill_profile_id
        billing_method
        balance_limit
        balance_limit_enabled
        collection_template_id
        contract_end_date
        contract_start_date
        cycle_date
        late_fees_enabled
        payment_terms_days
        numbers
        location_id
        tax_exempt_enabled
        tax_exempt_types
        usage_file_enabled
        usage_file_format
        payment_fees_enabled
        class
        date_of_birth
        language
        tax_id
        voip_switch_id
        suspended
        suspended_date
        culture
        fields
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const revioCustomersByStatus = /* GraphQL */ `
  query RevioCustomersByStatus(
    $status: String!
    $sortDirection: ModelSortDirection
    $filter: ModelRevioCustomersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    revioCustomersByStatus(
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        customer_id
        billing_address_id
        service_address_id
        listing_address_id
        parent_customer_id
        parent_customer_id_sort
        account_number
        activated_date
        agent_id
        secondary_agent_id
        close_date
        created_by
        created_date
        updated_date
        email
        is_parent
        registration_code
        security_pin
        sold_by
        source
        status
        auto_debit_enabled
        auto_debit_day
        auto_debit_payment_account_id
        bill_profile_id
        billing_method
        balance_limit
        balance_limit_enabled
        collection_template_id
        contract_end_date
        contract_start_date
        cycle_date
        late_fees_enabled
        payment_terms_days
        numbers
        location_id
        tax_exempt_enabled
        tax_exempt_types
        usage_file_enabled
        usage_file_format
        payment_fees_enabled
        class
        date_of_birth
        language
        tax_id
        voip_switch_id
        suspended
        suspended_date
        culture
        fields
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const revioCustomersByVoip_switch_id = /* GraphQL */ `
  query RevioCustomersByVoip_switch_id(
    $voip_switch_id: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelRevioCustomersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    revioCustomersByVoip_switch_id(
      voip_switch_id: $voip_switch_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        customer_id
        billing_address_id
        service_address_id
        listing_address_id
        parent_customer_id
        parent_customer_id_sort
        account_number
        activated_date
        agent_id
        secondary_agent_id
        close_date
        created_by
        created_date
        updated_date
        email
        is_parent
        registration_code
        security_pin
        sold_by
        source
        status
        auto_debit_enabled
        auto_debit_day
        auto_debit_payment_account_id
        bill_profile_id
        billing_method
        balance_limit
        balance_limit_enabled
        collection_template_id
        contract_end_date
        contract_start_date
        cycle_date
        late_fees_enabled
        payment_terms_days
        numbers
        location_id
        tax_exempt_enabled
        tax_exempt_types
        usage_file_enabled
        usage_file_format
        payment_fees_enabled
        class
        date_of_birth
        language
        tax_id
        voip_switch_id
        suspended
        suspended_date
        culture
        fields
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getRevioBills = /* GraphQL */ `
  query GetRevioBills($id: ID!) {
    getRevioBills(id: $id) {
      id
      bill_id
      parent_bill_id
      bill_type
      print_batch_id
      customer_id
      due_date
      created_date
      amount_due
      invoice_amount
      credit_memo_amount
      print_queue_flag
      printed_date
      generate_downloads
      late_fee_applied_flag
      first_month_flag
      ebpp_queue_flag
      ebpp_sent_date
      itemize_local
      is_final_bill
      note
      bill_print_type_id
      isLive
      cycle_data
      pdf_only_flag
      print_profile_id
      ready_for_pdf_flag
      bill_create_batch_id
      pdf_file_id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listRevioBills = /* GraphQL */ `
  query ListRevioBills(
    $filter: ModelRevioBillsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRevioBills(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        bill_id
        parent_bill_id
        bill_type
        print_batch_id
        customer_id
        due_date
        created_date
        amount_due
        invoice_amount
        credit_memo_amount
        print_queue_flag
        printed_date
        generate_downloads
        late_fee_applied_flag
        first_month_flag
        ebpp_queue_flag
        ebpp_sent_date
        itemize_local
        is_final_bill
        note
        bill_print_type_id
        isLive
        cycle_data
        pdf_only_flag
        print_profile_id
        ready_for_pdf_flag
        bill_create_batch_id
        pdf_file_id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncRevioBills = /* GraphQL */ `
  query SyncRevioBills(
    $filter: ModelRevioBillsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncRevioBills(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        bill_id
        parent_bill_id
        bill_type
        print_batch_id
        customer_id
        due_date
        created_date
        amount_due
        invoice_amount
        credit_memo_amount
        print_queue_flag
        printed_date
        generate_downloads
        late_fee_applied_flag
        first_month_flag
        ebpp_queue_flag
        ebpp_sent_date
        itemize_local
        is_final_bill
        note
        bill_print_type_id
        isLive
        cycle_data
        pdf_only_flag
        print_profile_id
        ready_for_pdf_flag
        bill_create_batch_id
        pdf_file_id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const revioBillsByBill_id = /* GraphQL */ `
  query RevioBillsByBill_id(
    $bill_id: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelRevioBillsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    revioBillsByBill_id(
      bill_id: $bill_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        bill_id
        parent_bill_id
        bill_type
        print_batch_id
        customer_id
        due_date
        created_date
        amount_due
        invoice_amount
        credit_memo_amount
        print_queue_flag
        printed_date
        generate_downloads
        late_fee_applied_flag
        first_month_flag
        ebpp_queue_flag
        ebpp_sent_date
        itemize_local
        is_final_bill
        note
        bill_print_type_id
        isLive
        cycle_data
        pdf_only_flag
        print_profile_id
        ready_for_pdf_flag
        bill_create_batch_id
        pdf_file_id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const revioBillsByCustomer_id = /* GraphQL */ `
  query RevioBillsByCustomer_id(
    $customer_id: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelRevioBillsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    revioBillsByCustomer_id(
      customer_id: $customer_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        bill_id
        parent_bill_id
        bill_type
        print_batch_id
        customer_id
        due_date
        created_date
        amount_due
        invoice_amount
        credit_memo_amount
        print_queue_flag
        printed_date
        generate_downloads
        late_fee_applied_flag
        first_month_flag
        ebpp_queue_flag
        ebpp_sent_date
        itemize_local
        is_final_bill
        note
        bill_print_type_id
        isLive
        cycle_data
        pdf_only_flag
        print_profile_id
        ready_for_pdf_flag
        bill_create_batch_id
        pdf_file_id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getRevioBillProfiles = /* GraphQL */ `
  query GetRevioBillProfiles($id: ID!) {
    getRevioBillProfiles(id: $id) {
      id
      bill_profile_id
      description
      lead_days
      advance_months
      term_days
      term_months
      cycle_type
      cycle_direction
      active
      currency_code
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listRevioBillProfiles = /* GraphQL */ `
  query ListRevioBillProfiles(
    $filter: ModelRevioBillProfilesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRevioBillProfiles(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        bill_profile_id
        description
        lead_days
        advance_months
        term_days
        term_months
        cycle_type
        cycle_direction
        active
        currency_code
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncRevioBillProfiles = /* GraphQL */ `
  query SyncRevioBillProfiles(
    $filter: ModelRevioBillProfilesFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncRevioBillProfiles(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        bill_profile_id
        description
        lead_days
        advance_months
        term_days
        term_months
        cycle_type
        cycle_direction
        active
        currency_code
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const revioBillProfilesByBill_profile_id = /* GraphQL */ `
  query RevioBillProfilesByBill_profile_id(
    $bill_profile_id: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelRevioBillProfilesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    revioBillProfilesByBill_profile_id(
      bill_profile_id: $bill_profile_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        bill_profile_id
        description
        lead_days
        advance_months
        term_days
        term_months
        cycle_type
        cycle_direction
        active
        currency_code
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getRevioAddresses = /* GraphQL */ `
  query GetRevioAddresses($id: ID!) {
    getRevioAddresses(id: $id) {
      id
      address_id
      customer_id
      first_name
      middle_initial
      last_name
      company_name
      line_1
      line_2
      city
      state_or_province
      postal_code
      postal_code_extension
      country_code
      created_date
      modified_date
      is_provider_validated
      is_USPS_validated
      revio_customer {
        id
        customer_id
        billing_address_id
        service_address_id
        listing_address_id
        parent_customer_id
        parent_customer_id_sort
        account_number
        activated_date
        agent_id
        secondary_agent_id
        close_date
        created_by
        created_date
        updated_date
        email
        is_parent
        registration_code
        security_pin
        sold_by
        source
        status
        auto_debit_enabled
        auto_debit_day
        auto_debit_payment_account_id
        bill_profile_id
        billing_method
        balance_limit
        balance_limit_enabled
        collection_template_id
        contract_end_date
        contract_start_date
        cycle_date
        late_fees_enabled
        payment_terms_days
        numbers
        location_id
        tax_exempt_enabled
        tax_exempt_types
        usage_file_enabled
        usage_file_format
        payment_fees_enabled
        class
        date_of_birth
        language
        tax_id
        voip_switch_id
        suspended
        suspended_date
        culture
        fields
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listRevioAddresses = /* GraphQL */ `
  query ListRevioAddresses(
    $filter: ModelRevioAddressesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRevioAddresses(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        address_id
        customer_id
        first_name
        middle_initial
        last_name
        company_name
        line_1
        line_2
        city
        state_or_province
        postal_code
        postal_code_extension
        country_code
        created_date
        modified_date
        is_provider_validated
        is_USPS_validated
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncRevioAddresses = /* GraphQL */ `
  query SyncRevioAddresses(
    $filter: ModelRevioAddressesFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncRevioAddresses(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        address_id
        customer_id
        first_name
        middle_initial
        last_name
        company_name
        line_1
        line_2
        city
        state_or_province
        postal_code
        postal_code_extension
        country_code
        created_date
        modified_date
        is_provider_validated
        is_USPS_validated
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const revioAddressesByAddress_id = /* GraphQL */ `
  query RevioAddressesByAddress_id(
    $address_id: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelRevioAddressesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    revioAddressesByAddress_id(
      address_id: $address_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        address_id
        customer_id
        first_name
        middle_initial
        last_name
        company_name
        line_1
        line_2
        city
        state_or_province
        postal_code
        postal_code_extension
        country_code
        created_date
        modified_date
        is_provider_validated
        is_USPS_validated
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getRevioTaxCodes = /* GraphQL */ `
  query GetRevioTaxCodes($id: ID!) {
    getRevioTaxCodes(id: $id) {
      id
      tax_class_id
      tax_code
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listRevioTaxCodes = /* GraphQL */ `
  query ListRevioTaxCodes(
    $filter: ModelRevioTaxCodesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRevioTaxCodes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        tax_class_id
        tax_code
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncRevioTaxCodes = /* GraphQL */ `
  query SyncRevioTaxCodes(
    $filter: ModelRevioTaxCodesFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncRevioTaxCodes(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        tax_class_id
        tax_code
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const revioTaxCodesByTax_class_id = /* GraphQL */ `
  query RevioTaxCodesByTax_class_id(
    $tax_class_id: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelRevioTaxCodesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    revioTaxCodesByTax_class_id(
      tax_class_id: $tax_class_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tax_class_id
        tax_code
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getXMLRequests = /* GraphQL */ `
  query GetXMLRequests($id: ID!) {
    getXMLRequests(id: $id) {
      id
      customerName
      account_number
      accountStatus
      name
      first_name
      last_name
      address1
      city
      state
      zip
      telephone
      servDesc
      DataPackage
      email
      custType
      E911Address
      E911City
      E911State
      E911Zip
      VoicePackages
      VideoPackage
      FaxPackage
      monthlyRate
      new_phone1
      ported1
      sipPassword1
      numLines
      DIDs
      DIDdetail
      SONbr
      msg_type
      CallerIDName
      new_phone2
      ported2
      sipPassword2
      features
      CO_Name
      provisioned
      in_process
      errors
      PIN
      isManagedService
      created_on
      billingFlag
      taxFlag
      accessSFP
      billable
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listXMLRequests = /* GraphQL */ `
  query ListXMLRequests(
    $filter: ModelXMLRequestsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listXMLRequests(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        customerName
        account_number
        accountStatus
        name
        first_name
        last_name
        address1
        city
        state
        zip
        telephone
        servDesc
        DataPackage
        email
        custType
        E911Address
        E911City
        E911State
        E911Zip
        VoicePackages
        VideoPackage
        FaxPackage
        monthlyRate
        new_phone1
        ported1
        sipPassword1
        numLines
        DIDs
        DIDdetail
        SONbr
        msg_type
        CallerIDName
        new_phone2
        ported2
        sipPassword2
        features
        CO_Name
        provisioned
        in_process
        errors
        PIN
        isManagedService
        created_on
        billingFlag
        taxFlag
        accessSFP
        billable
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncXMLRequests = /* GraphQL */ `
  query SyncXMLRequests(
    $filter: ModelXMLRequestsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncXMLRequests(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        customerName
        account_number
        accountStatus
        name
        first_name
        last_name
        address1
        city
        state
        zip
        telephone
        servDesc
        DataPackage
        email
        custType
        E911Address
        E911City
        E911State
        E911Zip
        VoicePackages
        VideoPackage
        FaxPackage
        monthlyRate
        new_phone1
        ported1
        sipPassword1
        numLines
        DIDs
        DIDdetail
        SONbr
        msg_type
        CallerIDName
        new_phone2
        ported2
        sipPassword2
        features
        CO_Name
        provisioned
        in_process
        errors
        PIN
        isManagedService
        created_on
        billingFlag
        taxFlag
        accessSFP
        billable
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const xMLRequestsByCustomerNameAndAccount_number = /* GraphQL */ `
  query XMLRequestsByCustomerNameAndAccount_number(
    $customerName: String!
    $account_number: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelXMLRequestsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    xMLRequestsByCustomerNameAndAccount_number(
      customerName: $customerName
      account_number: $account_number
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        customerName
        account_number
        accountStatus
        name
        first_name
        last_name
        address1
        city
        state
        zip
        telephone
        servDesc
        DataPackage
        email
        custType
        E911Address
        E911City
        E911State
        E911Zip
        VoicePackages
        VideoPackage
        FaxPackage
        monthlyRate
        new_phone1
        ported1
        sipPassword1
        numLines
        DIDs
        DIDdetail
        SONbr
        msg_type
        CallerIDName
        new_phone2
        ported2
        sipPassword2
        features
        CO_Name
        provisioned
        in_process
        errors
        PIN
        isManagedService
        created_on
        billingFlag
        taxFlag
        accessSFP
        billable
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getQueues = /* GraphQL */ `
  query GetQueues($id: ID!) {
    getQueues(id: $id) {
      id
      queue
      queue_name
      switch_queue_name
      contact_information
      sign_off_information
      flow_information
      overflow_textbox_information
      ticket_subject_values
      queue_cid_spoof_number
      fc_overflow_number
      tools_list
      allowed_groups
      allowed_partners
      tickets {
        nextToken
        startedAt
        __typename
      }
      custom_fields {
        nextToken
        startedAt
        __typename
      }
      rt_queue {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listQueues = /* GraphQL */ `
  query ListQueues(
    $filter: ModelQueuesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listQueues(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        queue
        queue_name
        switch_queue_name
        contact_information
        sign_off_information
        flow_information
        overflow_textbox_information
        ticket_subject_values
        queue_cid_spoof_number
        fc_overflow_number
        tools_list
        allowed_groups
        allowed_partners
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncQueues = /* GraphQL */ `
  query SyncQueues(
    $filter: ModelQueuesFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncQueues(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        queue
        queue_name
        switch_queue_name
        contact_information
        sign_off_information
        flow_information
        overflow_textbox_information
        ticket_subject_values
        queue_cid_spoof_number
        fc_overflow_number
        tools_list
        allowed_groups
        allowed_partners
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const queuesByQueue = /* GraphQL */ `
  query QueuesByQueue(
    $queue: String!
    $sortDirection: ModelSortDirection
    $filter: ModelQueuesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    queuesByQueue(
      queue: $queue
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        queue
        queue_name
        switch_queue_name
        contact_information
        sign_off_information
        flow_information
        overflow_textbox_information
        ticket_subject_values
        queue_cid_spoof_number
        fc_overflow_number
        tools_list
        allowed_groups
        allowed_partners
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getRTQueue = /* GraphQL */ `
  query GetRTQueue($id: ID!) {
    getRTQueue(id: $id) {
      id
      queue_id
      queue_name
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listRTQueues = /* GraphQL */ `
  query ListRTQueues(
    $filter: ModelRTQueueFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRTQueues(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        queue_id
        queue_name
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncRTQueues = /* GraphQL */ `
  query SyncRTQueues(
    $filter: ModelRTQueueFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncRTQueues(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        queue_id
        queue_name
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const rTQueuesByQueue_id = /* GraphQL */ `
  query RTQueuesByQueue_id(
    $queue_id: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelRTQueueFilterInput
    $limit: Int
    $nextToken: String
  ) {
    rTQueuesByQueue_id(
      queue_id: $queue_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        queue_id
        queue_name
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getQueueCustomFields = /* GraphQL */ `
  query GetQueueCustomFields($id: ID!) {
    getQueueCustomFields(id: $id) {
      id
      queue_id
      default_custom_field_id
      default_custom_field {
        id
        field_name
        field_type
        field_values
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      ticket_custom_fields {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listQueueCustomFields = /* GraphQL */ `
  query ListQueueCustomFields(
    $filter: ModelQueueCustomFieldsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listQueueCustomFields(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        queue_id
        default_custom_field_id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncQueueCustomFields = /* GraphQL */ `
  query SyncQueueCustomFields(
    $filter: ModelQueueCustomFieldsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncQueueCustomFields(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        queue_id
        default_custom_field_id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const queueCustomFieldsByQueue_id = /* GraphQL */ `
  query QueueCustomFieldsByQueue_id(
    $queue_id: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelQueueCustomFieldsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    queueCustomFieldsByQueue_id(
      queue_id: $queue_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        queue_id
        default_custom_field_id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const queueCustomFieldsByDefault_custom_field_id = /* GraphQL */ `
  query QueueCustomFieldsByDefault_custom_field_id(
    $default_custom_field_id: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelQueueCustomFieldsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    queueCustomFieldsByDefault_custom_field_id(
      default_custom_field_id: $default_custom_field_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        queue_id
        default_custom_field_id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getDefaultCustomFields = /* GraphQL */ `
  query GetDefaultCustomFields($id: ID!) {
    getDefaultCustomFields(id: $id) {
      id
      queue_custom_fields {
        nextToken
        startedAt
        __typename
      }
      field_name
      field_type
      field_values
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listDefaultCustomFields = /* GraphQL */ `
  query ListDefaultCustomFields(
    $filter: ModelDefaultCustomFieldsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDefaultCustomFields(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        field_name
        field_type
        field_values
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncDefaultCustomFields = /* GraphQL */ `
  query SyncDefaultCustomFields(
    $filter: ModelDefaultCustomFieldsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncDefaultCustomFields(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        field_name
        field_type
        field_values
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getTicketCustomFields = /* GraphQL */ `
  query GetTicketCustomFields($id: ID!) {
    getTicketCustomFields(id: $id) {
      id
      ticket_id
      queue_custom_field_id
      queue_custom_field {
        id
        queue_id
        default_custom_field_id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      set_value
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listTicketCustomFields = /* GraphQL */ `
  query ListTicketCustomFields(
    $filter: ModelTicketCustomFieldsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTicketCustomFields(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        ticket_id
        queue_custom_field_id
        set_value
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncTicketCustomFields = /* GraphQL */ `
  query SyncTicketCustomFields(
    $filter: ModelTicketCustomFieldsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncTicketCustomFields(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        ticket_id
        queue_custom_field_id
        set_value
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const ticketCustomFieldsByTicket_id = /* GraphQL */ `
  query TicketCustomFieldsByTicket_id(
    $ticket_id: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelTicketCustomFieldsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ticketCustomFieldsByTicket_id(
      ticket_id: $ticket_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        ticket_id
        queue_custom_field_id
        set_value
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const ticketCustomFieldsByQueue_custom_field_id = /* GraphQL */ `
  query TicketCustomFieldsByQueue_custom_field_id(
    $queue_custom_field_id: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelTicketCustomFieldsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ticketCustomFieldsByQueue_custom_field_id(
      queue_custom_field_id: $queue_custom_field_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        ticket_id
        queue_custom_field_id
        set_value
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getTickets = /* GraphQL */ `
  query GetTickets($id: ID!) {
    getTickets(id: $id) {
      id
      queue_id
      queue {
        id
        queue
        queue_name
        switch_queue_name
        contact_information
        sign_off_information
        flow_information
        overflow_textbox_information
        ticket_subject_values
        queue_cid_spoof_number
        fc_overflow_number
        tools_list
        allowed_groups
        allowed_partners
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      subject
      description
      status
      time_started
      time_last_contact
      priority
      owner
      owner_group
      creator
      requestor
      requestor_group
      cc
      createdAt
      updatedAt
      contact_information
      special_instructions
      custom_fields {
        nextToken
        startedAt
        __typename
      }
      comments {
        nextToken
        startedAt
        __typename
      }
      activity {
        nextToken
        startedAt
        __typename
      }
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listTickets = /* GraphQL */ `
  query ListTickets(
    $filter: ModelTicketsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTickets(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        queue_id
        subject
        description
        status
        time_started
        time_last_contact
        priority
        owner
        owner_group
        creator
        requestor
        requestor_group
        cc
        createdAt
        updatedAt
        contact_information
        special_instructions
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncTickets = /* GraphQL */ `
  query SyncTickets(
    $filter: ModelTicketsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncTickets(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        queue_id
        subject
        description
        status
        time_started
        time_last_contact
        priority
        owner
        owner_group
        creator
        requestor
        requestor_group
        cc
        createdAt
        updatedAt
        contact_information
        special_instructions
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const ticketsByQueue_idAndUpdatedAt = /* GraphQL */ `
  query TicketsByQueue_idAndUpdatedAt(
    $queue_id: ID!
    $updatedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTicketsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ticketsByQueue_idAndUpdatedAt(
      queue_id: $queue_id
      updatedAt: $updatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        queue_id
        subject
        description
        status
        time_started
        time_last_contact
        priority
        owner
        owner_group
        creator
        requestor
        requestor_group
        cc
        createdAt
        updatedAt
        contact_information
        special_instructions
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const ticketsByStatusAndCreatedAt = /* GraphQL */ `
  query TicketsByStatusAndCreatedAt(
    $status: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTicketsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ticketsByStatusAndCreatedAt(
      status: $status
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        queue_id
        subject
        description
        status
        time_started
        time_last_contact
        priority
        owner
        owner_group
        creator
        requestor
        requestor_group
        cc
        createdAt
        updatedAt
        contact_information
        special_instructions
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const ticketsByOwner = /* GraphQL */ `
  query TicketsByOwner(
    $owner: String!
    $sortDirection: ModelSortDirection
    $filter: ModelTicketsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ticketsByOwner(
      owner: $owner
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        queue_id
        subject
        description
        status
        time_started
        time_last_contact
        priority
        owner
        owner_group
        creator
        requestor
        requestor_group
        cc
        createdAt
        updatedAt
        contact_information
        special_instructions
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const searchTickets = /* GraphQL */ `
  query SearchTickets(
    $filter: SearchableTicketsFilterInput
    $sort: [SearchableTicketsSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableTicketsAggregationInput]
  ) {
    searchTickets(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        queue_id
        subject
        description
        status
        time_started
        time_last_contact
        priority
        owner
        owner_group
        creator
        requestor
        requestor_group
        cc
        createdAt
        updatedAt
        contact_information
        special_instructions
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
              __typename
            }
          }
        }
        __typename
      }
      __typename
    }
  }
`;
export const getTicketComments = /* GraphQL */ `
  query GetTicketComments($id: ID!) {
    getTicketComments(id: $id) {
      id
      ticket_id
      visibility_group
      subject
      content
      createdAt
      updatedAt
      comment_owner
      attachments {
        nextToken
        startedAt
        __typename
      }
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listTicketComments = /* GraphQL */ `
  query ListTicketComments(
    $filter: ModelTicketCommentsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTicketComments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        ticket_id
        visibility_group
        subject
        content
        createdAt
        updatedAt
        comment_owner
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncTicketComments = /* GraphQL */ `
  query SyncTicketComments(
    $filter: ModelTicketCommentsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncTicketComments(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        ticket_id
        visibility_group
        subject
        content
        createdAt
        updatedAt
        comment_owner
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const ticketCommentsByTicket_id = /* GraphQL */ `
  query TicketCommentsByTicket_id(
    $ticket_id: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelTicketCommentsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ticketCommentsByTicket_id(
      ticket_id: $ticket_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        ticket_id
        visibility_group
        subject
        content
        createdAt
        updatedAt
        comment_owner
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getTicketAttachments = /* GraphQL */ `
  query GetTicketAttachments($id: ID!) {
    getTicketAttachments(id: $id) {
      id
      ticket_comment_id
      s3_bucket_name
      s3_bucket_key
      file_type
      base64_string
      file_name
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listTicketAttachments = /* GraphQL */ `
  query ListTicketAttachments(
    $filter: ModelTicketAttachmentsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTicketAttachments(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        ticket_comment_id
        s3_bucket_name
        s3_bucket_key
        file_type
        base64_string
        file_name
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncTicketAttachments = /* GraphQL */ `
  query SyncTicketAttachments(
    $filter: ModelTicketAttachmentsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncTicketAttachments(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        ticket_comment_id
        s3_bucket_name
        s3_bucket_key
        file_type
        base64_string
        file_name
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const ticketAttachmentsByTicket_comment_id = /* GraphQL */ `
  query TicketAttachmentsByTicket_comment_id(
    $ticket_comment_id: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelTicketAttachmentsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ticketAttachmentsByTicket_comment_id(
      ticket_comment_id: $ticket_comment_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        ticket_comment_id
        s3_bucket_name
        s3_bucket_key
        file_type
        base64_string
        file_name
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getTicketActivity = /* GraphQL */ `
  query GetTicketActivity($id: ID!) {
    getTicketActivity(id: $id) {
      id
      ticket_id
      activity_type
      field_changed
      old_field_value
      new_field_value
      activity_owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listTicketActivities = /* GraphQL */ `
  query ListTicketActivities(
    $filter: ModelTicketActivityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTicketActivities(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        ticket_id
        activity_type
        field_changed
        old_field_value
        new_field_value
        activity_owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncTicketActivities = /* GraphQL */ `
  query SyncTicketActivities(
    $filter: ModelTicketActivityFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncTicketActivities(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        ticket_id
        activity_type
        field_changed
        old_field_value
        new_field_value
        activity_owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const ticketActivitiesByTicket_id = /* GraphQL */ `
  query TicketActivitiesByTicket_id(
    $ticket_id: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelTicketActivityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ticketActivitiesByTicket_id(
      ticket_id: $ticket_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        ticket_id
        activity_type
        field_changed
        old_field_value
        new_field_value
        activity_owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const ticketActivitiesByField_changed = /* GraphQL */ `
  query TicketActivitiesByField_changed(
    $field_changed: String!
    $sortDirection: ModelSortDirection
    $filter: ModelTicketActivityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ticketActivitiesByField_changed(
      field_changed: $field_changed
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        ticket_id
        activity_type
        field_changed
        old_field_value
        new_field_value
        activity_owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getDispatch = /* GraphQL */ `
  query GetDispatch($id: ID!) {
    getDispatch(id: $id) {
      id
      work_order
      date_est
      local_time
      local_timezone
      dispatcher
      site
      location
      tech
      tech_number
      job_description
      on_site
      on_time
      comments
      tech_support
      time_in
      time_out
      fft_accepted
      billing_info
      fedex_tracking
      travel_fee
      category
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listDispatches = /* GraphQL */ `
  query ListDispatches(
    $filter: ModelDispatchFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDispatches(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        work_order
        date_est
        local_time
        local_timezone
        dispatcher
        site
        location
        tech
        tech_number
        job_description
        on_site
        on_time
        comments
        tech_support
        time_in
        time_out
        fft_accepted
        billing_info
        fedex_tracking
        travel_fee
        category
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncDispatches = /* GraphQL */ `
  query SyncDispatches(
    $filter: ModelDispatchFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncDispatches(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        work_order
        date_est
        local_time
        local_timezone
        dispatcher
        site
        location
        tech
        tech_number
        job_description
        on_site
        on_time
        comments
        tech_support
        time_in
        time_out
        fft_accepted
        billing_info
        fedex_tracking
        travel_fee
        category
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const searchDispatches = /* GraphQL */ `
  query SearchDispatches(
    $filter: SearchableDispatchFilterInput
    $sort: [SearchableDispatchSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableDispatchAggregationInput]
  ) {
    searchDispatches(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        work_order
        date_est
        local_time
        local_timezone
        dispatcher
        site
        location
        tech
        tech_number
        job_description
        on_site
        on_time
        comments
        tech_support
        time_in
        time_out
        fft_accepted
        billing_info
        fedex_tracking
        travel_fee
        category
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
              __typename
            }
          }
        }
        __typename
      }
      __typename
    }
  }
`;
export const getRestrictedDates = /* GraphQL */ `
  query GetRestrictedDates($id: ID!) {
    getRestrictedDates(id: $id) {
      id
      date
      description
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listRestrictedDates = /* GraphQL */ `
  query ListRestrictedDates(
    $filter: ModelRestrictedDatesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRestrictedDates(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        date
        description
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncRestrictedDates = /* GraphQL */ `
  query SyncRestrictedDates(
    $filter: ModelRestrictedDatesFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncRestrictedDates(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        date
        description
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getQuotes = /* GraphQL */ `
  query GetQuotes($id: ID!) {
    getQuotes(id: $id) {
      id
      partner
      date
      companyName
      siteAddress
      billingAddress
      city
      state
      zip
      contactName
      contactPhone
      contactEmail
      billingContact
      billingPhone
      billingEmail
      technicalContact
      technicalPhone
      technicalEmail
      authPerson1
      authPhone1
      authEmail1
      authPerson2
      authPhone2
      authEmail2
      authPerson3
      authPhone3
      authEmail3
      termInMonths
      notes
      deleted
      QuoteItems {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listQuotes = /* GraphQL */ `
  query ListQuotes(
    $filter: ModelQuotesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listQuotes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        partner
        date
        companyName
        siteAddress
        billingAddress
        city
        state
        zip
        contactName
        contactPhone
        contactEmail
        billingContact
        billingPhone
        billingEmail
        technicalContact
        technicalPhone
        technicalEmail
        authPerson1
        authPhone1
        authEmail1
        authPerson2
        authPhone2
        authEmail2
        authPerson3
        authPhone3
        authEmail3
        termInMonths
        notes
        deleted
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncQuotes = /* GraphQL */ `
  query SyncQuotes(
    $filter: ModelQuotesFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncQuotes(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        partner
        date
        companyName
        siteAddress
        billingAddress
        city
        state
        zip
        contactName
        contactPhone
        contactEmail
        billingContact
        billingPhone
        billingEmail
        technicalContact
        technicalPhone
        technicalEmail
        authPerson1
        authPhone1
        authEmail1
        authPerson2
        authPhone2
        authEmail2
        authPerson3
        authPhone3
        authEmail3
        termInMonths
        notes
        deleted
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const quotesByPartner = /* GraphQL */ `
  query QuotesByPartner(
    $partner: String!
    $sortDirection: ModelSortDirection
    $filter: ModelQuotesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    quotesByPartner(
      partner: $partner
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        partner
        date
        companyName
        siteAddress
        billingAddress
        city
        state
        zip
        contactName
        contactPhone
        contactEmail
        billingContact
        billingPhone
        billingEmail
        technicalContact
        technicalPhone
        technicalEmail
        authPerson1
        authPhone1
        authEmail1
        authPerson2
        authPhone2
        authEmail2
        authPerson3
        authPhone3
        authEmail3
        termInMonths
        notes
        deleted
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const searchQuotes = /* GraphQL */ `
  query SearchQuotes(
    $filter: SearchableQuotesFilterInput
    $sort: [SearchableQuotesSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableQuotesAggregationInput]
  ) {
    searchQuotes(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        partner
        date
        companyName
        siteAddress
        billingAddress
        city
        state
        zip
        contactName
        contactPhone
        contactEmail
        billingContact
        billingPhone
        billingEmail
        technicalContact
        technicalPhone
        technicalEmail
        authPerson1
        authPhone1
        authEmail1
        authPerson2
        authPhone2
        authEmail2
        authPerson3
        authPhone3
        authEmail3
        termInMonths
        notes
        deleted
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
              __typename
            }
          }
        }
        __typename
      }
      __typename
    }
  }
`;
export const getQuoteItems = /* GraphQL */ `
  query GetQuoteItems($id: ID!) {
    getQuoteItems(id: $id) {
      id
      quote_id
      product_name
      unit_price
      quantity
      total
      frequency
      custom_item
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listQuoteItems = /* GraphQL */ `
  query ListQuoteItems(
    $filter: ModelQuoteItemsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listQuoteItems(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        quote_id
        product_name
        unit_price
        quantity
        total
        frequency
        custom_item
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncQuoteItems = /* GraphQL */ `
  query SyncQuoteItems(
    $filter: ModelQuoteItemsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncQuoteItems(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        quote_id
        product_name
        unit_price
        quantity
        total
        frequency
        custom_item
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const quoteItemsByQuote_id = /* GraphQL */ `
  query QuoteItemsByQuote_id(
    $quote_id: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelQuoteItemsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    quoteItemsByQuote_id(
      quote_id: $quote_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        quote_id
        product_name
        unit_price
        quantity
        total
        frequency
        custom_item
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getIconfigTemplates = /* GraphQL */ `
  query GetIconfigTemplates($id: ID!) {
    getIconfigTemplates(id: $id) {
      id
      template_filename
      partner_id
      s3Key
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listIconfigTemplates = /* GraphQL */ `
  query ListIconfigTemplates(
    $filter: ModelIconfigTemplatesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listIconfigTemplates(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        template_filename
        partner_id
        s3Key
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncIconfigTemplates = /* GraphQL */ `
  query SyncIconfigTemplates(
    $filter: ModelIconfigTemplatesFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncIconfigTemplates(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        template_filename
        partner_id
        s3Key
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const iconfigTemplatesByPartner_id = /* GraphQL */ `
  query IconfigTemplatesByPartner_id(
    $partner_id: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelIconfigTemplatesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    iconfigTemplatesByPartner_id(
      partner_id: $partner_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        template_filename
        partner_id
        s3Key
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getIconfigPartners = /* GraphQL */ `
  query GetIconfigPartners($id: ID!) {
    getIconfigPartners(id: $id) {
      id
      name
      status
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listIconfigPartners = /* GraphQL */ `
  query ListIconfigPartners(
    $filter: ModelIconfigPartnersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listIconfigPartners(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        status
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncIconfigPartners = /* GraphQL */ `
  query SyncIconfigPartners(
    $filter: ModelIconfigPartnersFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncIconfigPartners(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        status
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getDeregisterMessages = /* GraphQL */ `
  query GetDeregisterMessages($id: ID!) {
    getDeregisterMessages(id: $id) {
      partner
      message
      account_id
      device_user_id
      device_id
      need_to_process
      id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listDeregisterMessages = /* GraphQL */ `
  query ListDeregisterMessages(
    $filter: ModelDeregisterMessagesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDeregisterMessages(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        partner
        message
        account_id
        device_user_id
        device_id
        need_to_process
        id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncDeregisterMessages = /* GraphQL */ `
  query SyncDeregisterMessages(
    $filter: ModelDeregisterMessagesFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncDeregisterMessages(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        partner
        message
        account_id
        device_user_id
        device_id
        need_to_process
        id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const deregisterMessagesByNeed_to_process = /* GraphQL */ `
  query DeregisterMessagesByNeed_to_process(
    $need_to_process: String!
    $sortDirection: ModelSortDirection
    $filter: ModelDeregisterMessagesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    deregisterMessagesByNeed_to_process(
      need_to_process: $need_to_process
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        partner
        message
        account_id
        device_user_id
        device_id
        need_to_process
        id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getCarrierRates = /* GraphQL */ `
  query GetCarrierRates($id: ID!) {
    getCarrierRates(id: $id) {
      id
      carrier
      npanxx
      direction
      lata
      rate_center
      interstate_rate
      intrastate_rate
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listCarrierRates = /* GraphQL */ `
  query ListCarrierRates(
    $filter: ModelCarrierRatesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCarrierRates(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        carrier
        npanxx
        direction
        lata
        rate_center
        interstate_rate
        intrastate_rate
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncCarrierRates = /* GraphQL */ `
  query SyncCarrierRates(
    $filter: ModelCarrierRatesFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncCarrierRates(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        carrier
        npanxx
        direction
        lata
        rate_center
        interstate_rate
        intrastate_rate
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const carrierRatesByNpanxx = /* GraphQL */ `
  query CarrierRatesByNpanxx(
    $npanxx: String!
    $sortDirection: ModelSortDirection
    $filter: ModelCarrierRatesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    carrierRatesByNpanxx(
      npanxx: $npanxx
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        carrier
        npanxx
        direction
        lata
        rate_center
        interstate_rate
        intrastate_rate
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const carrierRatesByLata = /* GraphQL */ `
  query CarrierRatesByLata(
    $lata: String!
    $sortDirection: ModelSortDirection
    $filter: ModelCarrierRatesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    carrierRatesByLata(
      lata: $lata
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        carrier
        npanxx
        direction
        lata
        rate_center
        interstate_rate
        intrastate_rate
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const carrierRatesByRate_center = /* GraphQL */ `
  query CarrierRatesByRate_center(
    $rate_center: String!
    $sortDirection: ModelSortDirection
    $filter: ModelCarrierRatesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    carrierRatesByRate_center(
      rate_center: $rate_center
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        carrier
        npanxx
        direction
        lata
        rate_center
        interstate_rate
        intrastate_rate
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const searchCarrierRates = /* GraphQL */ `
  query SearchCarrierRates(
    $filter: SearchableCarrierRatesFilterInput
    $sort: [SearchableCarrierRatesSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableCarrierRatesAggregationInput]
  ) {
    searchCarrierRates(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        carrier
        npanxx
        direction
        lata
        rate_center
        interstate_rate
        intrastate_rate
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
              __typename
            }
          }
        }
        __typename
      }
      __typename
    }
  }
`;
export const getRateCenters = /* GraphQL */ `
  query GetRateCenters($id: ID!) {
    getRateCenters(id: $id) {
      id
      npanxx
      carrier_rates_npannx {
        nextToken
        startedAt
        __typename
      }
      npa
      lata
      carrier_rates_lata {
        nextToken
        startedAt
        __typename
      }
      rate_center
      carrier_rates_rate_center {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listRateCenters = /* GraphQL */ `
  query ListRateCenters(
    $filter: ModelRateCentersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRateCenters(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        npanxx
        npa
        lata
        rate_center
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncRateCenters = /* GraphQL */ `
  query SyncRateCenters(
    $filter: ModelRateCentersFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncRateCenters(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        npanxx
        npa
        lata
        rate_center
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getRobocallThresholds = /* GraphQL */ `
  query GetRobocallThresholds($id: ID!) {
    getRobocallThresholds(id: $id) {
      id
      revio_id
      inbound_minutes_per_call
      outbound_minutes_per_call
      toll_free_inbound_minutes_per_call
      toll_free_outbound_minutes_per_call
      international_outbound_minutes_per_call
      short_inbound_calls_hourly
      short_outbound_calls_hourly
      zero_inbound_calls_hourly
      zero_outbound_calls_hourly
      invalid_inbound_calls_hourly
      invalid_outbound_calls_hourly
      toll_free_inbound_calls_hourly
      international_outbound_calls_hourly
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listRobocallThresholds = /* GraphQL */ `
  query ListRobocallThresholds(
    $filter: ModelRobocallThresholdsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRobocallThresholds(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        revio_id
        inbound_minutes_per_call
        outbound_minutes_per_call
        toll_free_inbound_minutes_per_call
        toll_free_outbound_minutes_per_call
        international_outbound_minutes_per_call
        short_inbound_calls_hourly
        short_outbound_calls_hourly
        zero_inbound_calls_hourly
        zero_outbound_calls_hourly
        invalid_inbound_calls_hourly
        invalid_outbound_calls_hourly
        toll_free_inbound_calls_hourly
        international_outbound_calls_hourly
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncRobocallThresholds = /* GraphQL */ `
  query SyncRobocallThresholds(
    $filter: ModelRobocallThresholdsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncRobocallThresholds(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        revio_id
        inbound_minutes_per_call
        outbound_minutes_per_call
        toll_free_inbound_minutes_per_call
        toll_free_outbound_minutes_per_call
        international_outbound_minutes_per_call
        short_inbound_calls_hourly
        short_outbound_calls_hourly
        zero_inbound_calls_hourly
        zero_outbound_calls_hourly
        invalid_inbound_calls_hourly
        invalid_outbound_calls_hourly
        toll_free_inbound_calls_hourly
        international_outbound_calls_hourly
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const robocallThresholdsByRevio_id = /* GraphQL */ `
  query RobocallThresholdsByRevio_id(
    $revio_id: String!
    $sortDirection: ModelSortDirection
    $filter: ModelRobocallThresholdsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    robocallThresholdsByRevio_id(
      revio_id: $revio_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        revio_id
        inbound_minutes_per_call
        outbound_minutes_per_call
        toll_free_inbound_minutes_per_call
        toll_free_outbound_minutes_per_call
        international_outbound_minutes_per_call
        short_inbound_calls_hourly
        short_outbound_calls_hourly
        zero_inbound_calls_hourly
        zero_outbound_calls_hourly
        invalid_inbound_calls_hourly
        invalid_outbound_calls_hourly
        toll_free_inbound_calls_hourly
        international_outbound_calls_hourly
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getMultiLineOrders = /* GraphQL */ `
  query GetMultiLineOrders($id: ID!) {
    getMultiLineOrders(id: $id) {
      id
      port_order_id
      status
      submission_date
      order_type
      install_date
      completed_date
      partner_account_number
      company_name
      site_address
      multi_line_network_information {
        nextToken
        startedAt
        __typename
      }
      multi_line_order_comments {
        nextToken
        startedAt
        __typename
      }
      order_tasks {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listMultiLineOrders = /* GraphQL */ `
  query ListMultiLineOrders(
    $filter: ModelMultiLineOrdersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMultiLineOrders(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        port_order_id
        status
        submission_date
        order_type
        install_date
        completed_date
        partner_account_number
        company_name
        site_address
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncMultiLineOrders = /* GraphQL */ `
  query SyncMultiLineOrders(
    $filter: ModelMultiLineOrdersFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncMultiLineOrders(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        port_order_id
        status
        submission_date
        order_type
        install_date
        completed_date
        partner_account_number
        company_name
        site_address
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const multiLineOrdersByPort_order_id = /* GraphQL */ `
  query MultiLineOrdersByPort_order_id(
    $port_order_id: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelMultiLineOrdersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    multiLineOrdersByPort_order_id(
      port_order_id: $port_order_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        port_order_id
        status
        submission_date
        order_type
        install_date
        completed_date
        partner_account_number
        company_name
        site_address
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const searchMultiLineOrders = /* GraphQL */ `
  query SearchMultiLineOrders(
    $filter: SearchableMultiLineOrdersFilterInput
    $sort: [SearchableMultiLineOrdersSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableMultiLineOrdersAggregationInput]
  ) {
    searchMultiLineOrders(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        port_order_id
        status
        submission_date
        order_type
        install_date
        completed_date
        partner_account_number
        company_name
        site_address
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
              __typename
            }
          }
        }
        __typename
      }
      __typename
    }
  }
`;
export const getMultiLineOrderComments = /* GraphQL */ `
  query GetMultiLineOrderComments($id: ID!) {
    getMultiLineOrderComments(id: $id) {
      id
      multiline_order_id
      content
      action_required
      action_response
      author
      is_private
      user_id
      order_state
      comment_type
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listMultiLineOrderComments = /* GraphQL */ `
  query ListMultiLineOrderComments(
    $filter: ModelMultiLineOrderCommentsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMultiLineOrderComments(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        multiline_order_id
        content
        action_required
        action_response
        author
        is_private
        user_id
        order_state
        comment_type
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncMultiLineOrderComments = /* GraphQL */ `
  query SyncMultiLineOrderComments(
    $filter: ModelMultiLineOrderCommentsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncMultiLineOrderComments(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        multiline_order_id
        content
        action_required
        action_response
        author
        is_private
        user_id
        order_state
        comment_type
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const multiLineOrderCommentsByMultiline_order_id = /* GraphQL */ `
  query MultiLineOrderCommentsByMultiline_order_id(
    $multiline_order_id: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelMultiLineOrderCommentsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    multiLineOrderCommentsByMultiline_order_id(
      multiline_order_id: $multiline_order_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        multiline_order_id
        content
        action_required
        action_response
        author
        is_private
        user_id
        order_state
        comment_type
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getMultiLineOrderNetworkInformation = /* GraphQL */ `
  query GetMultiLineOrderNetworkInformation($id: ID!) {
    getMultiLineOrderNetworkInformation(id: $id) {
      id
      multiline_order_id
      static_ip
      gateway_ip
      mac_address
      fax_security_info
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listMultiLineOrderNetworkInformations = /* GraphQL */ `
  query ListMultiLineOrderNetworkInformations(
    $filter: ModelMultiLineOrderNetworkInformationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMultiLineOrderNetworkInformations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        multiline_order_id
        static_ip
        gateway_ip
        mac_address
        fax_security_info
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncMultiLineOrderNetworkInformations = /* GraphQL */ `
  query SyncMultiLineOrderNetworkInformations(
    $filter: ModelMultiLineOrderNetworkInformationFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncMultiLineOrderNetworkInformations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        multiline_order_id
        static_ip
        gateway_ip
        mac_address
        fax_security_info
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const multiLineOrderNetworkInformationsByMultiline_order_id = /* GraphQL */ `
  query MultiLineOrderNetworkInformationsByMultiline_order_id(
    $multiline_order_id: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelMultiLineOrderNetworkInformationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    multiLineOrderNetworkInformationsByMultiline_order_id(
      multiline_order_id: $multiline_order_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        multiline_order_id
        static_ip
        gateway_ip
        mac_address
        fax_security_info
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getOrderTeamMembers = /* GraphQL */ `
  query GetOrderTeamMembers($id: ID!) {
    getOrderTeamMembers(id: $id) {
      id
      name
      email
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listOrderTeamMembers = /* GraphQL */ `
  query ListOrderTeamMembers(
    $filter: ModelOrderTeamMembersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrderTeamMembers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        email
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncOrderTeamMembers = /* GraphQL */ `
  query SyncOrderTeamMembers(
    $filter: ModelOrderTeamMembersFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncOrderTeamMembers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        email
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getOrderTasks = /* GraphQL */ `
  query GetOrderTasks($id: ID!) {
    getOrderTasks(id: $id) {
      id
      multiline_order_id
      status
      date_completed
      task_name
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listOrderTasks = /* GraphQL */ `
  query ListOrderTasks(
    $filter: ModelOrderTasksFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrderTasks(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        multiline_order_id
        status
        date_completed
        task_name
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncOrderTasks = /* GraphQL */ `
  query SyncOrderTasks(
    $filter: ModelOrderTasksFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncOrderTasks(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        multiline_order_id
        status
        date_completed
        task_name
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const orderTasksByMultiline_order_id = /* GraphQL */ `
  query OrderTasksByMultiline_order_id(
    $multiline_order_id: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelOrderTasksFilterInput
    $limit: Int
    $nextToken: String
  ) {
    orderTasksByMultiline_order_id(
      multiline_order_id: $multiline_order_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        multiline_order_id
        status
        date_completed
        task_name
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getDefaultOrderTasks = /* GraphQL */ `
  query GetDefaultOrderTasks($id: ID!) {
    getDefaultOrderTasks(id: $id) {
      id
      task_name
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listDefaultOrderTasks = /* GraphQL */ `
  query ListDefaultOrderTasks(
    $filter: ModelDefaultOrderTasksFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDefaultOrderTasks(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        task_name
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncDefaultOrderTasks = /* GraphQL */ `
  query SyncDefaultOrderTasks(
    $filter: ModelDefaultOrderTasksFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncDefaultOrderTasks(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        task_name
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getBConfigDevices = /* GraphQL */ `
  query GetBConfigDevices($id: ID!) {
    getBConfigDevices(id: $id) {
      config_name
      name
      address
      partner
      vendor
      location
      date
      S3Key
      id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listBConfigDevices = /* GraphQL */ `
  query ListBConfigDevices(
    $filter: ModelBConfigDevicesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBConfigDevices(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        config_name
        name
        address
        partner
        vendor
        location
        date
        S3Key
        id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncBConfigDevices = /* GraphQL */ `
  query SyncBConfigDevices(
    $filter: ModelBConfigDevicesFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncBConfigDevices(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        config_name
        name
        address
        partner
        vendor
        location
        date
        S3Key
        id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getTrackableSystems = /* GraphQL */ `
  query GetTrackableSystems($id: ID!) {
    getTrackableSystems(id: $id) {
      id
      trackedItem
      currentStatus
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listTrackableSystems = /* GraphQL */ `
  query ListTrackableSystems(
    $filter: ModelTrackableSystemsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTrackableSystems(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        trackedItem
        currentStatus
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncTrackableSystems = /* GraphQL */ `
  query SyncTrackableSystems(
    $filter: ModelTrackableSystemsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncTrackableSystems(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        trackedItem
        currentStatus
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getUsers = /* GraphQL */ `
  query GetUsers($id: ID!) {
    getUsers(id: $id) {
      id
      partner
      username
      first_name
      last_name
      bookmarked_tickets
      favorite_apps
      notifications {
        nextToken
        startedAt
        __typename
      }
      has_subscribed_reports
      subscribed_reports
      favorite_reports
      webrtc_config_s3_key
      workspace_layout
      subscribed_systems
      maintenanceMessages
      emailNotifications
      disabled
      agent_account_id
      agent_recipient_id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUsersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        partner
        username
        first_name
        last_name
        bookmarked_tickets
        favorite_apps
        has_subscribed_reports
        subscribed_reports
        favorite_reports
        webrtc_config_s3_key
        workspace_layout
        subscribed_systems
        maintenanceMessages
        emailNotifications
        disabled
        agent_account_id
        agent_recipient_id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncUsers = /* GraphQL */ `
  query SyncUsers(
    $filter: ModelUsersFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncUsers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        partner
        username
        first_name
        last_name
        bookmarked_tickets
        favorite_apps
        has_subscribed_reports
        subscribed_reports
        favorite_reports
        webrtc_config_s3_key
        workspace_layout
        subscribed_systems
        maintenanceMessages
        emailNotifications
        disabled
        agent_account_id
        agent_recipient_id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const usersByHas_subscribed_reports = /* GraphQL */ `
  query UsersByHas_subscribed_reports(
    $has_subscribed_reports: String!
    $sortDirection: ModelSortDirection
    $filter: ModelUsersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    usersByHas_subscribed_reports(
      has_subscribed_reports: $has_subscribed_reports
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        partner
        username
        first_name
        last_name
        bookmarked_tickets
        favorite_apps
        has_subscribed_reports
        subscribed_reports
        favorite_reports
        webrtc_config_s3_key
        workspace_layout
        subscribed_systems
        maintenanceMessages
        emailNotifications
        disabled
        agent_account_id
        agent_recipient_id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getSiteNotifications = /* GraphQL */ `
  query GetSiteNotifications($id: ID!) {
    getSiteNotifications(id: $id) {
      id
      user_id
      title
      type
      tracked_system
      status
      marked_as_read
      content
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listSiteNotifications = /* GraphQL */ `
  query ListSiteNotifications(
    $filter: ModelSiteNotificationsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSiteNotifications(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        user_id
        title
        type
        tracked_system
        status
        marked_as_read
        content
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncSiteNotifications = /* GraphQL */ `
  query SyncSiteNotifications(
    $filter: ModelSiteNotificationsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncSiteNotifications(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        user_id
        title
        type
        tracked_system
        status
        marked_as_read
        content
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const siteNotificationsByUser_id = /* GraphQL */ `
  query SiteNotificationsByUser_id(
    $user_id: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelSiteNotificationsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    siteNotificationsByUser_id(
      user_id: $user_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        user_id
        title
        type
        tracked_system
        status
        marked_as_read
        content
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getEmployees = /* GraphQL */ `
  query GetEmployees($id: ID!) {
    getEmployees(id: $id) {
      id
      name
      email
      position
      employee_ticket_number
      supervisor
      start_time
      terminated
      date_terminated
      duration
      work_days
      discipline_level
      assigned_group
      employee_actions {
        nextToken
        startedAt
        __typename
      }
      voice_infractions {
        nextToken
        startedAt
        __typename
      }
      contact_center_infractions {
        nextToken
        startedAt
        __typename
      }
      audits {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listEmployees = /* GraphQL */ `
  query ListEmployees(
    $filter: ModelEmployeesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEmployees(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        email
        position
        employee_ticket_number
        supervisor
        start_time
        terminated
        date_terminated
        duration
        work_days
        discipline_level
        assigned_group
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncEmployees = /* GraphQL */ `
  query SyncEmployees(
    $filter: ModelEmployeesFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncEmployees(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        email
        position
        employee_ticket_number
        supervisor
        start_time
        terminated
        date_terminated
        duration
        work_days
        discipline_level
        assigned_group
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const employeesByAssigned_group = /* GraphQL */ `
  query EmployeesByAssigned_group(
    $assigned_group: String!
    $sortDirection: ModelSortDirection
    $filter: ModelEmployeesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    employeesByAssigned_group(
      assigned_group: $assigned_group
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        email
        position
        employee_ticket_number
        supervisor
        start_time
        terminated
        date_terminated
        duration
        work_days
        discipline_level
        assigned_group
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getVoiceInfractions = /* GraphQL */ `
  query GetVoiceInfractions($id: ID!) {
    getVoiceInfractions(id: $id) {
      id
      employee_id
      date
      employee
      infraction
      documentation
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listVoiceInfractions = /* GraphQL */ `
  query ListVoiceInfractions(
    $filter: ModelVoiceInfractionsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVoiceInfractions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        employee_id
        date
        employee
        infraction
        documentation
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncVoiceInfractions = /* GraphQL */ `
  query SyncVoiceInfractions(
    $filter: ModelVoiceInfractionsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncVoiceInfractions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        employee_id
        date
        employee
        infraction
        documentation
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const voiceInfractionsByEmployee_id = /* GraphQL */ `
  query VoiceInfractionsByEmployee_id(
    $employee_id: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelVoiceInfractionsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    voiceInfractionsByEmployee_id(
      employee_id: $employee_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        employee_id
        date
        employee
        infraction
        documentation
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getContactCenterInfractions = /* GraphQL */ `
  query GetContactCenterInfractions($id: ID!) {
    getContactCenterInfractions(id: $id) {
      id
      employee_id
      date
      employee_name
      infraction
      scheduled_shift
      clock_in_time
      clock_out_time
      details
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listContactCenterInfractions = /* GraphQL */ `
  query ListContactCenterInfractions(
    $filter: ModelContactCenterInfractionsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listContactCenterInfractions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        employee_id
        date
        employee_name
        infraction
        scheduled_shift
        clock_in_time
        clock_out_time
        details
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncContactCenterInfractions = /* GraphQL */ `
  query SyncContactCenterInfractions(
    $filter: ModelContactCenterInfractionsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncContactCenterInfractions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        employee_id
        date
        employee_name
        infraction
        scheduled_shift
        clock_in_time
        clock_out_time
        details
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const contactCenterInfractionsByEmployee_id = /* GraphQL */ `
  query ContactCenterInfractionsByEmployee_id(
    $employee_id: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelContactCenterInfractionsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    contactCenterInfractionsByEmployee_id(
      employee_id: $employee_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        employee_id
        date
        employee_name
        infraction
        scheduled_shift
        clock_in_time
        clock_out_time
        details
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getEmployeeActions = /* GraphQL */ `
  query GetEmployeeActions($id: ID!) {
    getEmployeeActions(id: $id) {
      id
      employee_id
      employee {
        id
        name
        email
        position
        employee_ticket_number
        supervisor
        start_time
        terminated
        date_terminated
        duration
        work_days
        discipline_level
        assigned_group
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      date
      action
      documentation
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listEmployeeActions = /* GraphQL */ `
  query ListEmployeeActions(
    $filter: ModelEmployeeActionsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEmployeeActions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        employee_id
        date
        action
        documentation
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncEmployeeActions = /* GraphQL */ `
  query SyncEmployeeActions(
    $filter: ModelEmployeeActionsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncEmployeeActions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        employee_id
        date
        action
        documentation
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const employeeActionsByEmployee_id = /* GraphQL */ `
  query EmployeeActionsByEmployee_id(
    $employee_id: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelEmployeeActionsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    employeeActionsByEmployee_id(
      employee_id: $employee_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        employee_id
        date
        action
        documentation
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getScheduledPartners = /* GraphQL */ `
  query GetScheduledPartners($id: ID!) {
    getScheduledPartners(id: $id) {
      id
      name
      queue_name
      category
      assigned_group
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listScheduledPartners = /* GraphQL */ `
  query ListScheduledPartners(
    $filter: ModelScheduledPartnersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listScheduledPartners(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        queue_name
        category
        assigned_group
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncScheduledPartners = /* GraphQL */ `
  query SyncScheduledPartners(
    $filter: ModelScheduledPartnersFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncScheduledPartners(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        queue_name
        category
        assigned_group
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getContactCenterQualityAudits = /* GraphQL */ `
  query GetContactCenterQualityAudits($id: ID!) {
    getContactCenterQualityAudits(id: $id) {
      id
      employee_id
      employee {
        id
        name
        email
        position
        employee_ticket_number
        supervisor
        start_time
        terminated
        date_terminated
        duration
        work_days
        discipline_level
        assigned_group
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      ticket_number
      date_of_call
      date_of_audit
      name_of_auditor
      call_recording
      call_type
      point_total
      status
      audit_object
      mutated_object
      compliance
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listContactCenterQualityAudits = /* GraphQL */ `
  query ListContactCenterQualityAudits(
    $filter: ModelContactCenterQualityAuditsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listContactCenterQualityAudits(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        employee_id
        ticket_number
        date_of_call
        date_of_audit
        name_of_auditor
        call_recording
        call_type
        point_total
        status
        audit_object
        mutated_object
        compliance
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncContactCenterQualityAudits = /* GraphQL */ `
  query SyncContactCenterQualityAudits(
    $filter: ModelContactCenterQualityAuditsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncContactCenterQualityAudits(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        employee_id
        ticket_number
        date_of_call
        date_of_audit
        name_of_auditor
        call_recording
        call_type
        point_total
        status
        audit_object
        mutated_object
        compliance
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const contactCenterQualityAuditsByEmployee_id = /* GraphQL */ `
  query ContactCenterQualityAuditsByEmployee_id(
    $employee_id: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelContactCenterQualityAuditsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    contactCenterQualityAuditsByEmployee_id(
      employee_id: $employee_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        employee_id
        ticket_number
        date_of_call
        date_of_audit
        name_of_auditor
        call_recording
        call_type
        point_total
        status
        audit_object
        mutated_object
        compliance
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getContactCenterQualityAuditQuestions = /* GraphQL */ `
  query GetContactCenterQualityAuditQuestions($id: ID!) {
    getContactCenterQualityAuditQuestions(id: $id) {
      id
      question
      point_value
      category
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listContactCenterQualityAuditQuestions = /* GraphQL */ `
  query ListContactCenterQualityAuditQuestions(
    $filter: ModelContactCenterQualityAuditQuestionsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listContactCenterQualityAuditQuestions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        question
        point_value
        category
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncContactCenterQualityAuditQuestions = /* GraphQL */ `
  query SyncContactCenterQualityAuditQuestions(
    $filter: ModelContactCenterQualityAuditQuestionsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncContactCenterQualityAuditQuestions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        question
        point_value
        category
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getMultiLineProvisionRequests = /* GraphQL */ `
  query GetMultiLineProvisionRequests($id: ID!) {
    getMultiLineProvisionRequests(id: $id) {
      id
      partner
      account_number
      first_name
      last_name
      address
      city
      state
      zip
      contact_phone
      contact_email
      customer_type
      caller_id_name
      caller_id_number
      bypass_e911
      phone_numbers
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listMultiLineProvisionRequests = /* GraphQL */ `
  query ListMultiLineProvisionRequests(
    $filter: ModelMultiLineProvisionRequestsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMultiLineProvisionRequests(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        partner
        account_number
        first_name
        last_name
        address
        city
        state
        zip
        contact_phone
        contact_email
        customer_type
        caller_id_name
        caller_id_number
        bypass_e911
        phone_numbers
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncMultiLineProvisionRequests = /* GraphQL */ `
  query SyncMultiLineProvisionRequests(
    $filter: ModelMultiLineProvisionRequestsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncMultiLineProvisionRequests(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        partner
        account_number
        first_name
        last_name
        address
        city
        state
        zip
        contact_phone
        contact_email
        customer_type
        caller_id_name
        caller_id_number
        bypass_e911
        phone_numbers
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const searchMultiLineProvisionRequests = /* GraphQL */ `
  query SearchMultiLineProvisionRequests(
    $filter: SearchableMultiLineProvisionRequestsFilterInput
    $sort: [SearchableMultiLineProvisionRequestsSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableMultiLineProvisionRequestsAggregationInput]
  ) {
    searchMultiLineProvisionRequests(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        partner
        account_number
        first_name
        last_name
        address
        city
        state
        zip
        contact_phone
        contact_email
        customer_type
        caller_id_name
        caller_id_number
        bypass_e911
        phone_numbers
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
              __typename
            }
          }
        }
        __typename
      }
      __typename
    }
  }
`;
export const getFlowQuestions = /* GraphQL */ `
  query GetFlowQuestions($id: ID!) {
    getFlowQuestions(id: $id) {
      id
      type
      question
      options
      url
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listFlowQuestions = /* GraphQL */ `
  query ListFlowQuestions(
    $filter: ModelFlowQuestionsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFlowQuestions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        type
        question
        options
        url
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncFlowQuestions = /* GraphQL */ `
  query SyncFlowQuestions(
    $filter: ModelFlowQuestionsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncFlowQuestions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        type
        question
        options
        url
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getFlows = /* GraphQL */ `
  query GetFlows($id: ID!) {
    getFlows(id: $id) {
      id
      name
      allowed_queues_staging
      allowed_queues
      s3_staging_bucket_key
      s3_production_bucket_key
      s3_bucket_name
      changed_flag
      removal_flag
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listFlows = /* GraphQL */ `
  query ListFlows(
    $filter: ModelFlowsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFlows(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        allowed_queues_staging
        allowed_queues
        s3_staging_bucket_key
        s3_production_bucket_key
        s3_bucket_name
        changed_flag
        removal_flag
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncFlows = /* GraphQL */ `
  query SyncFlows(
    $filter: ModelFlowsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncFlows(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        allowed_queues_staging
        allowed_queues
        s3_staging_bucket_key
        s3_production_bucket_key
        s3_bucket_name
        changed_flag
        removal_flag
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getFakeDisconnectRequests = /* GraphQL */ `
  query GetFakeDisconnectRequests($id: ID!) {
    getFakeDisconnectRequests(id: $id) {
      id
      service
      status
      disconnect_date
      requester
      comment
      full_disconnect
      account_number
      partner
      phone_numbers
      ticket_number
      port_out
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listFakeDisconnectRequests = /* GraphQL */ `
  query ListFakeDisconnectRequests(
    $filter: ModelFakeDisconnectRequestsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFakeDisconnectRequests(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        service
        status
        disconnect_date
        requester
        comment
        full_disconnect
        account_number
        partner
        phone_numbers
        ticket_number
        port_out
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncFakeDisconnectRequests = /* GraphQL */ `
  query SyncFakeDisconnectRequests(
    $filter: ModelFakeDisconnectRequestsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncFakeDisconnectRequests(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        service
        status
        disconnect_date
        requester
        comment
        full_disconnect
        account_number
        partner
        phone_numbers
        ticket_number
        port_out
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const searchFakeDisconnectRequests = /* GraphQL */ `
  query SearchFakeDisconnectRequests(
    $filter: SearchableFakeDisconnectRequestsFilterInput
    $sort: [SearchableFakeDisconnectRequestsSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableFakeDisconnectRequestsAggregationInput]
  ) {
    searchFakeDisconnectRequests(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        service
        status
        disconnect_date
        requester
        comment
        full_disconnect
        account_number
        partner
        phone_numbers
        ticket_number
        port_out
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
              __typename
            }
          }
        }
        __typename
      }
      __typename
    }
  }
`;
export const getAgentsRecoveryTimes = /* GraphQL */ `
  query GetAgentsRecoveryTimes($id: ID!) {
    getAgentsRecoveryTimes(id: $id) {
      id
      recipient_id
      recovery_time
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listAgentsRecoveryTimes = /* GraphQL */ `
  query ListAgentsRecoveryTimes(
    $filter: ModelAgentsRecoveryTimesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAgentsRecoveryTimes(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        recipient_id
        recovery_time
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncAgentsRecoveryTimes = /* GraphQL */ `
  query SyncAgentsRecoveryTimes(
    $filter: ModelAgentsRecoveryTimesFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncAgentsRecoveryTimes(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        recipient_id
        recovery_time
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const searchAgentsRecoveryTimes = /* GraphQL */ `
  query SearchAgentsRecoveryTimes(
    $filter: SearchableAgentsRecoveryTimesFilterInput
    $sort: [SearchableAgentsRecoveryTimesSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableAgentsRecoveryTimesAggregationInput]
  ) {
    searchAgentsRecoveryTimes(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        recipient_id
        recovery_time
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
              __typename
            }
          }
        }
        __typename
      }
      __typename
    }
  }
`;
export const getCarrierDialPrefixRates = /* GraphQL */ `
  query GetCarrierDialPrefixRates($id: ID!) {
    getCarrierDialPrefixRates(id: $id) {
      id
      country
      dial_prefix
      retail_rate
      wholesale_rate
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listCarrierDialPrefixRates = /* GraphQL */ `
  query ListCarrierDialPrefixRates(
    $filter: ModelCarrierDialPrefixRatesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCarrierDialPrefixRates(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        country
        dial_prefix
        retail_rate
        wholesale_rate
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncCarrierDialPrefixRates = /* GraphQL */ `
  query SyncCarrierDialPrefixRates(
    $filter: ModelCarrierDialPrefixRatesFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncCarrierDialPrefixRates(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        country
        dial_prefix
        retail_rate
        wholesale_rate
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const carrierDialPrefixRatesByDial_prefix = /* GraphQL */ `
  query CarrierDialPrefixRatesByDial_prefix(
    $dial_prefix: String!
    $sortDirection: ModelSortDirection
    $filter: ModelCarrierDialPrefixRatesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    carrierDialPrefixRatesByDial_prefix(
      dial_prefix: $dial_prefix
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        country
        dial_prefix
        retail_rate
        wholesale_rate
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const searchCarrierDialPrefixRates = /* GraphQL */ `
  query SearchCarrierDialPrefixRates(
    $filter: SearchableCarrierDialPrefixRatesFilterInput
    $sort: [SearchableCarrierDialPrefixRatesSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableCarrierDialPrefixRatesAggregationInput]
  ) {
    searchCarrierDialPrefixRates(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        country
        dial_prefix
        retail_rate
        wholesale_rate
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
              __typename
            }
          }
        }
        __typename
      }
      __typename
    }
  }
`;
