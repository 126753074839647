import { defineStore } from 'pinia'
import { API } from "aws-amplify";
import * as queries from "@/graphql/queries.js";
import * as mutations from "@/graphql/mutations.js";
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api";

export const useFavoritesStore = defineStore('Favorites', {
    state: () => ({
        favorites: [],
        apps: [],
        allApps: [],
    }),
    getters: {
        getFavorites(state) {
            return state.favorites
        },
        getApps(state) {
            return state.apps
        },
        getAllApps(state) {
            return state.allApps
        }
    },
    actions: {
        async fetchfavoriteAppsPage(user) {
            let error_message = "Unable to fetch favorite page Apps";
            try {
                var hasData = true;
                var nextToken = null;
                var transformedData = [];
                const usedKeys = new Set();

                const fetchAppSectionsOptions = {
                    query: queries.listApplicationSections,
                    authMode: GRAPHQL_AUTH_MODE.API_KEY
                };
                const appSectionsResponse = await API.graphql(fetchAppSectionsOptions);
                const appSections = appSectionsResponse.data.listApplicationSections.items;

                const nameToLabelMap = {};
                appSections.forEach(section => {
                    nameToLabelMap[section.title] = section.title; 
                    if (section.menuItems) {
                        const parsedMenuItems = JSON.parse(section.menuItems);
                        parsedMenuItems.forEach(menuItem => {
                            nameToLabelMap[menuItem.name] = `${section.title} - ${menuItem.name}`;  
                        });
                    }
                });

                while (hasData) {
                    var options = {
                        query: queries.listUsers,
                        variables: {
                            filter: {
                                id: { eq: user },
                                _deleted: { ne: true },
                            },
                            limit: 2000
                        },
                        authMode: GRAPHQL_AUTH_MODE.API_KEY
                    }

                    if (nextToken != null) {
                        options.variables.nextToken = nextToken;
                    }

                    var result = await API.graphql(options);

                    for (const item of result.data.listUsers.items) {
                        try {
                            const parsedFavoriteApps = JSON.parse(item.favorite_apps);
                            for (const favoriteApp of parsedFavoriteApps) {
                                let uniqueKey;
                                do {
                                    uniqueKey = Math.floor(Math.random() * 90 + 10);
                                } while (usedKeys.has(uniqueKey));
                                usedKeys.add(uniqueKey);
        
                                const appLabel = nameToLabelMap[favoriteApp.name] || favoriteApp.name; 
        
                                transformedData.push({
                                    key: uniqueKey,
                                    Page: appLabel
                                });
                            }
                        } catch (error) {
                            console.error("Failed to parse favorite_apps:", error);
                        }
                    }

                    if (result.data.listUsers.nextToken != null) {
                        nextToken = result.data.listUsers.nextToken;
                    }
                    else {
                        hasData = false;
                    }
                }

                this.favorites = transformedData;
                return this.favorites;
            }
            catch (error) {
                console.error("ERROR", error);
                return { error: true, message: error_message }
            }
        },
        async removeUnauthorizedApps(userId, groups) {
            const error_message = 'Unable to Remove Unauthorized Apps';
            try {
                const fetchOptions = {
                    query: queries.listUsers,
                    variables: {
                        filter: { id: { eq: userId } },
                    },
                    authMode: GRAPHQL_AUTH_MODE.API_KEY
                };
                
                const fetchResponse = await API.graphql(fetchOptions);
                const userData = fetchResponse.data.listUsers.items[0];
                let existingFavoriteApps = JSON.parse(userData.favorite_apps) || [];
        
                const fetchAppSectionsOptions = {
                    query: queries.listApplicationSections,
                    authMode: GRAPHQL_AUTH_MODE.API_KEY
                };
                const appSectionsResponse = await API.graphql(fetchAppSectionsOptions);
                const appSections = appSectionsResponse.data.listApplicationSections.items;
        
                let appGroupMap = {};
        
                for (const section of appSections) {
                    const allowedGroups = JSON.parse(section.allowed_groups);
                    const menuItems = section.menuItems ? JSON.parse(section.menuItems) : [];
        
                    appGroupMap[section.Title] = allowedGroups;
        
                    for (const menuItem of menuItems) {
                        appGroupMap[menuItem.name] = allowedGroups;
                    }
                }
        
                let updatedFavoriteApps = [];
        
                for (const app of existingFavoriteApps) {
                    const allowedGroups = appGroupMap[app.name] || [];
        
                    for (const group of allowedGroups) {
                        if (groups.includes(group)) {
                            updatedFavoriteApps.push(app);
                            break;
                        }
                    }
                }
        
                const favoriteAppsJSON = JSON.stringify(updatedFavoriteApps);
                const updateOptions = {
                    query: mutations.updateUsers,
                    variables: {
                        input: {
                            id: userId,
                            favorite_apps: favoriteAppsJSON,
                            _version: userData._version
                        }
                    },
                    authMode: GRAPHQL_AUTH_MODE.API_KEY
                };
                
                const updateResponse = await API.graphql(updateOptions);
        
                if (!updateResponse.data.updateUsers.id) {
                    return { error: true, message: error_message };
                }
        
                this.apps = updatedFavoriteApps;
                return { error: false, message: 'Successfully updated favorite apps' };
        
            } catch (error) {
                console.error("Failed to update favorite apps:", error);
                return { error: true, message: error_message };
            }
        },  
        async removeApp(itemName, userId) {
            const originalName = itemName.split(' - ').pop();
            var error_message = 'Unable to Remove App';
            try {
                const fetchOptions = {
                    query: queries.listUsers,
                    variables: {
                        filter: { id: { eq: userId } },
                    },
                    authMode: GRAPHQL_AUTH_MODE.API_KEY
                };
                const fetchResponse = await API.graphql(fetchOptions);
                const user = fetchResponse.data.listUsers.items[0];

                if (!user) {
                    console.error("User not found");
                    return { error: true, message: 'User not found' };
                }

                const _version = user._version;

                let existingFavoriteApps = JSON.parse(user.favorite_apps) || [];

                const updatedFavoriteApps = existingFavoriteApps.filter(app => app.name !== originalName);

                const favoriteAppsJSON = JSON.stringify(updatedFavoriteApps);

                const updateOptions = {
                    query: mutations.updateUsers,
                    variables: {
                        input: {
                            id: userId,
                            favorite_apps: favoriteAppsJSON,
                            _version: _version
                        }
                    },
                    authMode: GRAPHQL_AUTH_MODE.API_KEY
                };

                const updateResponse = await API.graphql(updateOptions);

                if (!updateResponse.data.updateUsers.id) {
                    return { error: true, message: error_message };
                }

                this.apps = this.apps.filter(app => app.name !== originalName);

                return { error: false, message: 'Successfully removed app' };

            } catch (error) {
                console.error("Failed to remove app:", error);
                return { error: true, message: error_message };
            }
        },
        async updateFavoriteApp(user, applications) {
            var error_message = 'Unable to Update Date';
            try {
                const fetchOptions = {
                    query: queries.listUsers,
                    variables: {
                        filter: { id: { eq: user } },
                    },
                    authMode: GRAPHQL_AUTH_MODE.API_KEY
                };
        
                const fetchResponse = await API.graphql(fetchOptions);
                const item = fetchResponse.data.listUsers.items[0];
        
                if (!item) {
                    return { error: true, message: 'User not found' };
                }
        
                const _version = item._version;
        
                let existingFavoriteApps = [];
                try {
                    existingFavoriteApps = JSON.parse(item.favorite_apps) || [];
                } catch (error) {
                    console.log("Starting fresh no apps");
                }
        
                let favoriteAppsToUpdate = [...existingFavoriteApps];
        
                for (const app of applications) {
                    const queryTitle = app.label.includes(' - ') ? app.label.split(' - ')[0] : app.label;
                    const appFetchOptions = {
                        query: queries.listApplicationSections,
                        variables: {
                            filter: { title: { eq: queryTitle } }
                        },
                        authMode: GRAPHQL_AUTH_MODE.API_KEY
                    };
        
                    const appFetchResponse = await API.graphql(appFetchOptions);
                    const applicationSections = appFetchResponse.data.listApplicationSections.items;
        
                    if (!applicationSections.length) {
                        return { error: true, message: 'Application not found' };
                    }
        
                    for (const application of applicationSections) {
                        if (application.route !== "empty") {
                            if (favoriteAppsToUpdate.some(favApp => favApp.name === application.title)) {
                                return { error: true, message: 'App Already Favorited' };
                            }
                            favoriteAppsToUpdate.push({
                                name: application.title,
                                route: application.route
                            });
                        } else {
                            let parsedMenuItems = JSON.parse(application.menuItems);
                            if (app.label.includes(' - ')) {
                                const menuItem = parsedMenuItems.find(item => `${application.title} - ${item.name}` === app.label);
                                if (menuItem) {
                                    if (favoriteAppsToUpdate.some(favApp => favApp.name === menuItem.name)) {
                                        return { error: true, message: 'App Already Favorited' };
                                    }
                                    favoriteAppsToUpdate.push({
                                        name: menuItem.name,
                                        route: menuItem.route
                                    });
                                }
                            }
                        }
                    }
                }
        
                const favoriteAppsJSON = JSON.stringify(favoriteAppsToUpdate);
        
                const updateOptions = {
                    query: mutations.updateUsers,
                    variables: {
                        input: {
                            id: user,
                            favorite_apps: favoriteAppsJSON,
                            _version: _version
                        }
                    },
                    authMode: GRAPHQL_AUTH_MODE.API_KEY
                };
        
                const updateResponse = await API.graphql(updateOptions);
        
                if (!updateResponse.data.updateUsers.id) {
                    return { error: true, message: error_message };
                }
        
                this.apps = favoriteAppsToUpdate;
                return { error: false, message: 'Ok' };
        
            } catch (error) {
                console.error("Failed to update date:", error);
                return { error: true, message: error_message };
            }
        },
        async updateAllowedFavoriteApps(user, applications) {
            var error_message = 'Unable to Update Date';
            try {
                const fetchOptions = {
                    query: queries.listUsers,
                    variables: {
                        filter: { id: { eq: user } },
                    },
                    authMode: GRAPHQL_AUTH_MODE.API_KEY
                };
        
                const fetchResponse = await API.graphql(fetchOptions);
                const item = fetchResponse.data.listUsers.items[0];
        
                if (!item) {
                    return { error: true, message: 'User not found' };
                }
        
                const _version = item._version;
        
                const updateOptions = {
                    query: mutations.updateUsers,
                    variables: {
                        input: {
                            id: user,
                            favorite_apps: JSON.stringify(applications),
                            _version: _version
                        }
                    },
                    authMode: GRAPHQL_AUTH_MODE.API_KEY
                };
        
                const updateResponse = await API.graphql(updateOptions);
        
                if (!updateResponse.data.updateUsers.id) {
                    return { error: true, message: error_message };
                }
        
                this.apps = applications;
                return { error: false, message: 'Ok' };
        
            } catch (error) {
                console.error("Failed to update date:", error);
                return { error: true, message: error_message };
            }
        },
        async fetchAllApps() {
            let error_message = "Unable to fetch all Apps";
            try {
                var hasData = true;
                var nextToken = null;
                var data = [];

                while (hasData) {
                    var options = {
                        query: queries.listApplicationSections,
                        variables: {
                            filter: {
                                _deleted: { ne: true },
                            },
                            limit: 2000
                        },
                        authMode: GRAPHQL_AUTH_MODE.API_KEY
                    }

                    if (nextToken != null) {
                        options.variables.nextToken = nextToken;
                    }

                    var result = await API.graphql(options);

                    for (const item of result.data.listApplicationSections.items) {
                        if (item.title === "Favorites" || item.title === "Workspace") {
                            continue;
                        }
                    
                        if (item.route === "empty") {
                            let menuItems = JSON.parse(item.menuItems);
                            menuItems.forEach(menuItem => {
                                menuItem.parentTitle = item.title;
                            });
                            data.push({
                                isParent: true,
                                title: item.title,
                                children: menuItems,
                            });
                        } else {
                            data.push({
                                isParent: false,
                                title: item.title,
                                route: item.route
                            });
                        }
                    }

                    if (result.data.listApplicationSections.nextToken != null) {
                        nextToken = result.data.listApplicationSections.nextToken;
                    }
                    else {
                        hasData = false;
                    }
                }

                this.allApps = data
                return this.allApps;
            }
            catch (error) {
                console.error("ERROR", error);
                return { error: true, message: error_message }
            }
        }, 
        async fetchfavoriteApps(user) {
            let error_message = "Unable to fetch favorite apps";
            try {
                var hasData = true;
                var nextToken = null;
                var transformedData = [];

                while (hasData) {
                    var options = {
                        query: queries.listUsers,
                        variables: {
                            filter: {
                                id: { eq: user },
                                _deleted: { ne: true },
                            },
                            limit: 2000
                        },
                        authMode: GRAPHQL_AUTH_MODE.API_KEY
                    }

                    if (nextToken != null) {
                        options.variables.nextToken = nextToken;
                    }

                    var result = await API.graphql(options);

                    for (const item of result.data.listUsers.items) {
                        try {
                            const parsedFavoriteApps = JSON.parse(item.favorite_apps);
                            for (const favoriteApp of parsedFavoriteApps) {
                                transformedData.push({
                                    name: favoriteApp.name,
                                    route: favoriteApp.route
                                });
                            }
                        } catch (error) {
                            console.error("Failed to parse favorite_apps:", error);
                        }
                    }

                    if (result.data.listUsers.nextToken != null) {
                        nextToken = result.data.listUsers.nextToken;
                    }
                    else {
                        hasData = false;
                    }
                }

                this.apps = transformedData;
                return this.apps;
            }
            catch (error) {
                console.error("ERROR", error);
                return { error: true, message: error_message }
            }
        }
    },
})