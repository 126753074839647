<template>
  <div v-if="currentCall?.status === 'active'" @click="handleActiveClick">
    <div class="activeCallHeader" @click="handleActiveClick">
      <img
        :src="require('@/assets/icons/call_white.png')"
        class="activeCallIcon"
      />
      <h1 class="activeCallText">
        Active Call
        <span :style="{ paddingLeft: '10px' }">
          ({{ currentCall["Total Call Duration"] }})
        </span>
      </h1>
      <img
      :src="muted ? require('@/assets/icons/unmute_combined.png') : require('@/assets/icons/mute_combined.png') "
      class="mute-icon"
      @click="handleMute"/>
    </div>
    <h2 class="callsOnHoldActiveText">Calls on Hold</h2>
  </div>
  <div v-else class="enterNumberBoxContainer">
    <img
      :src="require('@/assets/icons/callsonhold_dkblue.png')"
      class="callsOnHoldIcon"
    />
    <h1 class="callsOnHoldHeader">Calls on Hold</h1>
  </div>

  <div class="callsOnHoldContainer">
    <template v-for="(call, index) in callsOnHold" :key="index">
      <CallsOnHoldCard
        :call="call"
        @selectedCall="handleSelectedCall"
        :isSelected="call == selectedCall"
      />
    </template>
  </div>
  <div
    class="activeCallButtonContainer"
    v-if="currentCall?.status === 'active'"
  >
    <DividerComp class="activeCallDivider" />
    <div class="activeButtonContainer">
     
          <LightButton
            icon="hold.png"
            title="Hold"
            :style="{ height: '35px', width: '80px' }"
            @click="handleHold"
          />
          <LightButton
            icon="transfer.png"
            title="Transfer"
            :style="{ height: '35px', width: '90px' }"
            @click="handleTransfer"
          />
          <LightButton
            icon="transfer.png"
            title="Join"
            :style="{ height: '35px', width: '80px' }"
            @click="handleJoin"
          />
    </div>
    <div class="hangupButtonContainer">
      <LightButton
        class="hangupButton"
        icon="hangup.png"
        :style="{
          height: '35px',
          backgroundColor: '#e78535',
          color: 'white',
        }"
        @click="handleHangup"
      />
    </div>
  </div>
  <div v-else class="callButtonContainer">
    <DividerComp class="callDivider" />
    <CallsOnHoldButton
      class="callButton"
      @click="handleCallClick"
      :icon="selectedCall ? 'call_white.png' : 'call_dkblue.png'"
      :active="selectedCall"
    />
  </div>
</template>

<script>
import CallsOnHoldCard from "./CallsOnHoldCard.vue";
import CallsOnHoldButton from "./CallsOnHoldButton";
import DividerComp from "@/components/base_components/DividerComp.vue";
import LightButton from "../LightButtonWebRTC.vue";
import { useWebRTCStore } from "@/stores/webRTC/webRTCStore.js";
import { mapActions, mapStores } from "pinia";
export default {
  name: "CallsOnHoldComp",
  props: { currentCall: Object },
  emits: ["navigate", "updateOnHold"],
  components: { CallsOnHoldCard, CallsOnHoldButton, DividerComp, LightButton },
  data() {
    return {
      number: "",
      selectedCall: null,
      callsOnHold: [],
    };
  },
  computed: {
    ...mapStores(useWebRTCStore),

    muted() {
      return this.WebRTCStore.getMuteState;
    }
  },
  methods: {
    ...mapActions(useWebRTCStore, ["changeNumber", "toggleMuted"]),
    handleCallClick() {
      if(this.selectedCall && this.selectedCall.call.isOnHold()){
        if(this.selectedCall.call.isPrimary() && this.selectedCall.call.isOnHold() && this.selectedCall.call.isEstablished()){
          this.selectedCall.call._primary = false;
        }
        this.selectedCall.call._setPrimary();
        this.WebRTCStore.setCurrentCallCallObj(this.selectedCall.call);

        if(this.WebRTCStore.getCallsOnHold[this.selectedCall.call._id]){
          this.WebRTCStore.removeCallOnHold(this.selectedCall.call._id);
          this.$emit('updateOnHold');
          // TODO: need to figure out how to make the CallsOnHoldComp.vue update its list
        }
        this.WebRTCStore.setCurrentCallStatus("active");
        this.WebRTCStore.changeSelectedPage("active");
      }
    },
    handleMute(){
      this.WebRTCStore.toggleMuted();
    },
    handleSelectedCall(call) {
      if (call === this.selectedCall) {
        this.selectedCall = null;
      } else {
        this.selectedCall = call;
      }
    },
    handleActiveClick() {
      this.$emit("navigate", "active");
    },
    handleHold(){
      let current_call = this.WebRTCStore.getCurrentCallObject;
      if(current_call){
        current_call._clearPrimary();
      } else {
        this.$emit('updateOnHold');
      }
    },
    handleTransfer(){
      if(this.selectedCall){
        let current_call = this.WebRTCStore.getCurrentCallObject;
        if(current_call){
          let call_sesion = current_call._getSession();
          let call_on_hold_obj = this.WebRTCStore.getCallsOnHold[this.selectedCall.id];
          if(call_on_hold_obj){
            call_on_hold_obj.call.warmTransfer(current_call.remoteIdentity(true)._uri._user, call_sesion);
          }
        }
      }
    },
    handleJoin() {
      if (this.selectedCall) {
        console.log("Handling Join");
        let current_call = this.WebRTCStore.getCurrentCallObject;
        let call_on_hold = this.WebRTCStore.getCallsOnHold[this.selectedCall.id];

        console.log("Current Call: ", current_call);
        console.log("Call on Hold: ", call_on_hold);

        if (current_call) {
          let current_call_session = current_call._getSession();
          let call_on_hold_session = this.WebRTCStore.getCallsOnHold[this.selectedCall.id].call._getSession();

          if (call_on_hold_session) {
            var session_object = {};
            session_object[call_on_hold_session._id] = call_on_hold_session;
            session_object[current_call_session._id] = current_call_session;
            current_call.createConference(session_object);
            current_call._setPrimary();
            call_on_hold.call._setPrimary();

            var current_caller_identity = current_call.remoteIdentity(true);
            var call_on_hold_identity = call_on_hold.call.remoteIdentity(true);

            // let identity = call.remoteIdentity(true);
            // set the webRTCStore values
            this.WebRTCStore.setCurrentCallCallerID(`${current_caller_identity._uri._user} & ${call_on_hold_identity._uri._user}`);
            var callerIDString = "";

            if (current_caller_identity.display_name && call_on_hold_identity.display_name) {
              callerIDString = `${current_caller_identity.display_name} & ${call_on_hold_identity.display_name}`;
            }

            this.WebRTCStore.setCurrentCallCallerName(callerIDString);
            let formatted_date_time = new Date().toLocaleString().replace(/(.*) (\d+:\d+:\d+)/, '$1 - $2').replace(",", "");
            this.WebRTCStore.addUpdateCallHistory(current_call._id, "outgoing", formatted_date_time, callerIDString, `${current_caller_identity._uri._user} & ${call_on_hold_identity._uri._user}`);
            this.WebRTCStore.changeSelectedPage("active");
            this.WebRTCStore.removeCallOnHold(this.selectedCall.id);
            this.WebRTCStore.setCurrentCallType("Conference");
            this.WebRTCStore.setJoinedCallObj(call_on_hold.call);
          }
        }
      }
    },  
    handleHangup() {
      this.WebRTCStore.hangupCurrentCall();
      this.$emit('updateOnHold');
    },
    updateCallsOnHoldArray(){
      let calls_on_hold = this.WebRTCStore.getCallsOnHold;

      this.callsOnHold = [];
      for(let call_on_hold_id in calls_on_hold){
        let call_info = calls_on_hold[call_on_hold_id];
        let call_on_hold = {
          id: call_info.id, 
          caller: call_info.caller ? call_info.caller : call_info.number,
          time: call_info.time,
          duration: call_info.duration,
          audio: "", // TODO: needs an mp3 in the future
          type: call_info.missed ? "missed" : call_info.direction,
          call: call_info.call,
        };

        this.callsOnHold.push(call_on_hold);
      }
      
      this.$emit('updateOnHold');
    }
  },
  mounted() {
    this.updateCallsOnHoldArray();
    this.WebRTCStore.$subscribe((mutation, state) => {
      if (Object.keys(state.callsOnHold).length === 0) {
        this.callsOnHold = [];
      }
    });
  },
};
</script>

<style scoped>
.callsOnHoldContainer {
  padding: 10px 10px 0px 10px;
  background-color: #f8f9fc;
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: auto;
  height: 100%;
}
.enterNumberBoxContainer {
  margin-top: 25px;
  margin-bottom: 25px;
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
}
.callsOnHoldIcon {
  height: 32px;
  width: 26px;
  margin-right: 20px;
  margin-left: 20px;
  text-align: center;
}
.callsOnHoldHeader {
  color: #271d7a;
  font-size: 19px;
  font-weight: 500;
}
.callButtonContainer {
  margin: auto auto 0 auto;
  padding-bottom: 30px;
  width: 100%;
  background-color: #f8f9fc;
}
.callDivider {
  width: 100%;
  margin-bottom: 30px;
}
.callButton {
  width: 90%;
  margin: auto;
}
.callsOnHoldActiveText {
  background-color: #f8f9fc;
  font-size: 17px;
  font-weight: 500;
  color: #271d7a;
  padding: 10px 0px 0px 20px;
}
.activeCallHeader {
  padding-top: 25px;
  padding-bottom: 25px;
  background-color: #e78535;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.activeCallHeader:hover {
  outline: 0.25px solid #271d7a;
  cursor: pointer;
}
.activeCallIcon {
  height: 30px;
  width: 24px;
  margin-right: 20px;
  margin-left: 20px;
  text-align: center;
}
.activeCallText {
  color: #ffffff;
  font-size: 19px;
  font-weight: 500;
}
.activeCallButtonContainer {
  margin: auto auto 0 auto;
  padding-bottom: 30px;
  width: 100%;
  background-color: #f8f9fc;
  flex: 1;
}
.activeButtonContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0px 15px 0px 15px;
}
.hangupButtonContainer {
  display: flex;
  padding-top: 20px;
}
.hangupButton {
  width: 90%;
  display: flex;
  margin: auto;
}
.activeCallDivider {
  width: 100%;
  margin-bottom: 25px;
}
.mute-icon {
  height: 35px;
  margin-right: 15px;
}
</style>
