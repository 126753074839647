<template>
  <div class="editorFormParent">
    <FormComp 
      ref="createQuestionForm"
      :form-data="addQuestionDrawerData" 
      :buttonOptions="{
        positionBottom: true,
        centered: false,
        fillToParent: false,
        divider: true
      }" 
      :numCols="12" 
      @submission-data="handleFormSubmission"
      @submissionDataUpdated="handleSubmissionDataUpdate" 
      :previousFormValues="submissionData"
      :clearForm="clearForm"
    >
    <template v-slot:submit-button>
      <div class="submit-container">
        <div class="submit-left-container">
          <DarkButton > Create Question </DarkButton>
        </div>

        <LightButton @click.prevent="handleClearForm" class="resetButton">
          Reset
        </LightButton>
      </div>
    </template>
    </FormComp>
  </div>
  <AlertsGroup v-model:alerts="alerts" />
</template>

<script>
import FormComp from "@/components/CcManagement/Flows/FormComp.vue";
import { SUCCESS, ERROR } from "@/constants/statuses.constants.js";
import AlertsGroup from "@/components/base_components/AlertsGroup.vue";
import { addAlert } from '@/helpers/alertUtils';
import LightButton from "@/components/base_components/Buttons/LightButton.vue";
import DarkButton from "@/components/base_components/Buttons/DarkButton.vue";
import { useFlowManagerStore } from "@/stores/ContactCenter/Management/FlowManagerStore.js";
import { mapActions } from 'pinia';

export default {
  name: "CreateQuestionEditorView",
  props: {
    drawerOpen: Boolean,
    questions: Array,
    toggle: Boolean
  },
  data() {
    return {
      alerts: [],
      clearForm: false,
      submissionData: {},
      addQuestionDrawerData: [
        {
          type: "form",
          button: " Add",
          buttonOptions: {
            positionBottom: true,
            centered: true,
            fillToParent: true,
          },
        },
        {
          title: "",
          inputs: [
            {
              name: "Question",
              value: "question",
              type: "textbox",
              required: true,
              fullWidth: true,
            },
            {
              name: "Question Type",
              value: "questionType",
              type: "select",
              required: true,
              fullWidth: true,
              options: [
                { label: "Standard Text Field", value: "Standard Text Field" },
                { label: "CheckBox", value: "CheckBox" },
                { label: "Radio Button Fields", value: "Radio Button Fields" },
                { label: "LES Flag", value: "LES Flag" },
                { label: "Display Only", value: "Display Only" },
                { label: "Display In Notes", value: "Display In Notes" },
                { label: "Text Box", value: "Text Box" },
                { label: "Smart Link", value: "Smart Link" },
              ],
            },
          ],
        },
        {
          title: "",
          required: true,
          inputs: [
            { name: "dynamicOptions", type: "dynamicOptions", options: [] },
          ],
          show: false,
        },
        {},
      ],
      originalAddQuestionDrawerData: [
        {
          type: "form",
          button: " Add",
          buttonOptions: {
            positionBottom: true,
            centered: true,
            fillToParent: true,
          },
        },
        {
          title: "",
          inputs: [
            {
              name: "Question",
              value: "question",
              type: "textbox",
              required: true,
              fullWidth: true,
            },
            {
              name: "Question Type",
              value: "questionType",
              type: "select",
              required: true,
              fullWidth: true,
              options: [
                { label: "Standard Text Field", value: "Standard Text Field" },
                { label: "CheckBox", value: "CheckBox" },
                { label: "Radio Button Fields", value: "Radio Button Fields" },
                { label: "LES Flag", value: "LES Flag" },
                { label: "Display Only", value: "Display Only" },
                { label: "Display In Notes", value: "Display In Notes" },
                { label: "Text Box", value: "Text Box" },
                { label: "Smart Link", value: "Smart Link" },
              ],
            },
          ],
        },
        {
          title: "",
          required: true,
          inputs: [
            { name: "dynamicOptions", type: "dynamicOptions", options: [] },
          ],
          show: false,
        },
        {},
      ],
    };
  },
  watch: {
    drawerData() {
      this.submissionData = {};
    },
    toggle(newVal) {
      if(!newVal) {
        this.alerts = [];
      }
    }
  },
  methods: {
    ...mapActions(useFlowManagerStore, ['saveFlowQuestions']),
    handleClearForm() {
      this.clearForm = true;
    },
    async handleFormSubmission(formData) {
      if (formData === "Error") {
        addAlert(ERROR, 'Missing Required Values', this.alerts);
      } else {
        var question = formData.question;
        var questionType = formData.questionType.value;
        var questionOptions = "";
        if (formData.dynamicOptions) {
          questionOptions = formData.dynamicOptions ? JSON.stringify(formData.dynamicOptions) : "";
        }

        if(questionType == "LES Flag") {
          questionOptions = JSON.stringify([{label: true, value: true}, {label: false, value: false}]);
        }

        var questionLink = formData["Enter url"] ? formData["Enter url"] : "";
        await this.saveFlowQuestions(question, questionType, questionOptions, questionLink);
        addAlert(SUCCESS, 'Successfully added Question', this.alerts);
        this.$emit("question-created");
        this.clearForm = true;
        this.submissionData = {};
        this.addQuestionDrawerData = [...this.originalAddQuestionDrawerData];
      }
    },
    handleSubmissionDataUpdate(data) {
      this.clearForm = false;
      this.updateDynamicOptionsObjectIndex(this.addQuestionDrawerData, data);
    },
    updateDynamicOptionsObjectIndex(drawerData, formData) {
      const dynamicOptionsObjectIndex = drawerData.findIndex((section) =>
        section?.inputs?.some((input) => input.type === "dynamicOptions")
      );

      let questionType =
        formData["Question Type"] ||
        formData.questionType ||
        formData.questionType?.value;
      questionType = questionType?.value ? questionType.value : questionType;

      if (dynamicOptionsObjectIndex !== -1) {
        drawerData[dynamicOptionsObjectIndex].show =
          questionType === "CheckBox" || questionType === "Radio Button Fields";
      }

      let obj = {
        title: "",
        inputs: [],
        show: true,
      };

      if (
        drawerData[2].title === "Smart Link" &&
        questionType !== "Smart Link"
      ) {
        drawerData.splice(2, 1);
      }

      if (
        questionType === "CheckBox" &&
        formData["dynamicOptions"] &&
        formData["dynamicOptions"].length
      ) {
        obj.inputs = [
          {
            name: formData.question,
            type: "radio",
            options: formData["dynamicOptions"],
            outlined: true,
            centered: true,
            label: "Select all that Apply",
            multiSelect: true,
            required: false,
          },
        ];

        drawerData[drawerData.length - 1] = obj;
      } else if (
        (questionType === "Radio Button Fields") &&
        formData["dynamicOptions"] &&
        formData["dynamicOptions"].length
      ) {
        obj.inputs = [
          {
            name: formData.question,
            type: "radio",
            options: formData["dynamicOptions"],
            outlined: true,
            centered: false,
            label: "",
            multiSelect: false,
            required: false,
          },
        ];

        drawerData[drawerData.length - 1] = obj;
      } else if (questionType === "Standard Text Field") {
        obj.inputs = [
          {
            name: formData.question,
            value: "Standard Text Field",
            type: "bubble",
            test: true,
            required: false,
          },
        ];
        delete formData["dynamicOptions"];
        drawerData[drawerData.length - 1] = obj;
      } else if (questionType === "LES Flag") {
        obj.inputs = [
          {
            name: formData.question,
            type: "radio",
            options: [{label: "true", value: true},{label: "false", value: false}],
            outlined: true,
            centered: false,
            label: "",
            multiSelect: false,
            required: false,
          },
        ];
        delete formData["dynamicOptions"];
        drawerData[drawerData.length - 1] = obj;
      } else if (questionType === "Display Only") {
        obj.inputs = [
          {
            name: formData.question,
            value: "Display Only",
            type: "textarea",
            test: true,
            readonly: false,
            required: false,
          },
        ];
        delete formData["dynamicOptions"];
        drawerData[drawerData.length - 1] = obj;
      } else if (questionType === "Display In Notes") {
        obj.inputs = [
          {
            name: formData.question,
            value: "Display In Notes",
            type: "textarea",
            test: true,
            readonly: false,
            required: false,
          },
        ];
        delete formData["dynamicOptions"];
        drawerData[drawerData.length - 1] = obj;
      } else if (questionType === "Text Box") {
        obj.inputs = [
          {
            name: formData.question,
            value: "textbox",
            type: "textarea",
            test: true,
            readonly: false,
            required: false,
          },
        ];
        delete formData["dynamicOptions"];
        drawerData[drawerData.length - 1] = obj;
      } else if (questionType === "Smart Link") {
        if (drawerData[2].title !== "Smart Link") {
          const objectToPush = {
            title: "Smart Link",
            required: true,
            inputs: [
              {
                name: "Enter url",
                value: "Smart Link",
                type: "Smart Link",
                url: formData["Enter url"],
                required: true,
              },
            ],
          };
          drawerData.splice(2, 0, objectToPush);
        }
        else{
          drawerData[2].inputs[0].url = formData["Enter url"];
        }
        
        obj.inputs = [
          {
            name: formData.question,
            value: "smartLinkView",
            type: "smartLinkView",
            url: formData["Enter url"],
            test: true,
            required: false,
          },
        ];
        delete formData["dynamicOptions"];
        drawerData[drawerData.length - 1] = obj;
      } else {
        drawerData[drawerData.length - 1] = {};
      }
    },
  },
  components: {
    FormComp,
    LightButton,
    DarkButton,
    AlertsGroup
  },
  emits: [
    "toggle",
    "question-created"
  ]
};
</script>

<style scoped>
  html {
    overflow-y: auto !important;
  }

  :deep(.editor-content-bulk-panel .ProseMirror) {
    overflow: auto;
  }

  :deep(.formContainer) {
    padding-bottom: 50px;
  }

  .resetButton {
    background-color: #EBF1F7;
  }
  .submit-container {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    width: 100%;
    padding: 0px 16px;
  }

  .submit-left-container {
    display: flex;
    gap: 20px;
  }

  .editorFormParent {
    height: 100%;
  }
</style>