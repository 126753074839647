<template>  
    <div class="container" v-bind:key="queue" :style="{ height: dynamicHeight - 20 + 'px' }">
      <div class="dashboard">        
        <DashboardCard :queue="queue" />
      </div>
      <div class="rowContainer">
        <div class="callTotals"><CallTotalsCard :queue="queue" /></div>
        <div class="agents"><AgentsCard :queue="queue" /></div>
      </div>
      <div class="rowContainer">
        <div class="ticketsCreated"><TicketsCreatedCard :queue="queue" /></div>
        <div class="ticketEscalations">
          <TicketEscalationsCard :queue="queue" />
        </div>
        <div class="currentCallsInQueue">
          <CurrentCallsInQueueCard :queue="queue" />
        </div>
      </div>
    </div>
  </template>
  <script>
  import DashboardCard from "./Dashboard/DashboardCardRealTime.vue";
  import CallTotalsCard from "./CallTotals/CallTotalsCardRealTime.vue";
  import AgentsCard from "./Agents/AgentsCardRealTime.vue";
  import TicketsCreatedCard from "./TicketsCreated/TicketsCreatedCardRealTime.vue";
  import TicketEscalationsCard from "./TicketEscalations/TicketEscalationsCardRealTime.vue";
  import CurrentCallsInQueueCard from "./CurrentCallsInQueue/CurrentCallsInQueueCard.vue";
  export default {
    name: "RealTimeQueueDash",
    data() {
      return {
        selectedQueue: "",
        headers: [
          { name: "Queue Name", width: 150, value: "queueName" },
          {
            name: "Calls Waiting & Wait Time",
            width: 120,
            value: "callsWaitingAndWaitTime",
          },
          { name: "Agents Ready", width: 100, value: "agentsReady" },
          {
            name: "Agents On Phone & Away",
            width: 150,
            value: "agentsOnPhoneAndAway",
          },
          { name: "New Tickets", width: 100, value: "newTickets" },
          {
            name: "LogicomUSA & Overflow Tickets",
            width: 120,
            value: "logicomUSAAndOverflowTickets",
          },
          { name: "Tickets Escalated", width: 120, value: "ticketsEscalated" },
          { name: "Open CSR Escalations", width: 100, value: "csrEscalations" },
          { name: "Answered Calls", width: 100, value: "answeredCalls" },
          {
            name: "Inbound and Outbound Calls",
            width: 160,
            value: "inboundAndOutboundCalls",
          },
          {
            name: "Missed and Abandoned Calls",
            width: 160,
            value: "missedAndAbandonedCalls",
          },
          {
            name: "Calls Handed to Overflow",
            width: 120,
            value: "callsHandedToOverflow",
          },
          {
            name: "Calls Waiting in Queue",
            width: 120,
            value: "callsWaitingInQueue",
          },
          { name: "Average Wait Time", width: 120, value: "averageWaitTime" },
          {
            name: "Longest Hold Time - Current",
            width: 140,
            value: "longestHoldTimeCurrent",
          },
          {
            name: "Longest Hold Time - Day",
            width: 120,
            value: "longestHoldTimeDay",
          },
          {
            name: "Callbacks Scheduled",
            width: 120,
            value: "callbacksScheduled",
          },
        ],
        changed: [],
      };
    },
    props: {
      filterOptionsArray: Array,
      dynamicHeight: Number,
      markedForRemoval: Array,
      unmarkForRemoval: Array,
      headersToDisplay: Array,
      queues: Array,
      queueSelected: String,
      queue: Object
    },
    methods: {
      handleSelectedItems(selectedItems) {
        this.$emit("selected-items", selectedItems);
      },
      handleNavigate(queue) {
        this.selectedQueue = queue;
      },
    },
    mounted() {
      // console.log(this.queueSelected);
      // console.log(this.queues);
    },
    computed: {
      // filtertedDashboardData() {
      //   for (var queue of this.queues) {
      //     if (queue.queueName == this.queueSelected) {
      //       return [queue];
      //     }
      //   }
      //   return [];  
      // },
      // filteredHeadersList() {
      //   if (this.headersToDisplay !== undefined && this.headersToDispla y.length) {
      //     return this.headers.filter((header) => {
      //       return (
      //         this.headersToDisplay.includes(header.value) ||
      //         header.value == "queueName"
      //       );
      //     });
      //   } else {
      //     return this.headers;
      //   }
      // },
    },
    watch: {
      //  
    },
    components: {
      DashboardCard,
      CallTotalsCard,
      AgentsCard,
      TicketsCreatedCard,
      TicketEscalationsCard,
      CurrentCallsInQueueCard
    },
    emits: [
      "selected-items"
    ]
  };
  </script>
  <style scoped>
  .flowsContainer {
    padding: 20px;
    width: 100%;
    background-color: #e2f1f4;
  }
  .dashboard {
    background-color: #ffffff;
    margin-bottom: 10px;
    padding: 10px 10px 30px;
    filter: drop-shadow(1px 1px 1px rgb(39, 29, 122, 0.2));
  }
  .rowContainer {
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
    height: 257px;
  }
  .callTotals {
    width: 50%;
    background-color: #ffffff;
    margin-right: 10px;
    filter: drop-shadow(1px 1px 1px rgb(39, 29, 122, 0.2));
  }
  .agents {
    width: 50%;
    background-color: #ffffff;
    filter: drop-shadow(1px 1px 1px rgb(39, 29, 122, 0.2));
  }
  .ticketsCreated {
    width: 35%;
    background-color: #ffffff;
    margin-right: 10px;
    filter: drop-shadow(1px 1px 1px rgb(39, 29, 122, 0.2));
  }
  .ticketEscalations {
    padding-bottom: 30px;
    width: 30%;
    background-color: #ffffff;
    filter: drop-shadow(1px 1px 1px rgb(39, 29, 122, 0.2));
  }
  .container {
    display: flex;
    flex-direction: column;
    overflow: auto;
    height: 100%;
    padding-right: 10px;
  }
  @media (max-width: 1330px) {
    .container {
      flex-direction: column;
    }
    .rowContainer {
      flex-direction: column;
      margin-bottom: 0;
      height: 257px;
    }
    .callTotals,
    .agents,
    .ticketsCreated,
    .ticketEscalations {
      width: 99%;
      margin-bottom: 10px;
    }
    .dashboard {
      margin-right: 1%;
    }
  }
  </style>
  