<template>
  <div class="alerts-group">
    <TransitionGroup
      name="list"
      tag="div"
      class="alerts-group-wrapper"
    >
      <AlertComp
        v-for="({ name, description }, index) in alerts"
        :name="name"
        :key="index"
        :description="description"
        @removed-alert="handleRemoveAlert(index)"
      />
    </TransitionGroup>
  </div>
</template>

<script>
import AlertComp from "@/components/base_components/AlertComp.vue";

export default {
  name: 'AlertsGroup',
  components: {
    AlertComp
  },
  props: {
    alerts: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    localAlerts: {
      get () {
        return this.alerts;
      },
      set (value) {
        this.$emit('update:alerts', value);
      }
    }
  },
  methods: {
    handleRemoveAlert(index) {
      this.localAlerts.splice(index, 1);
    },
  },
  emits: [
    "update:alerts"
  ]
}
</script>

<style
  scoped
>
.alerts-group {
  position: fixed;
  top: 110px;
  right: 0;
  padding-bottom: 10px;
  padding-right: 20px;
  z-index: 150;
}
.alerts-group-wrapper {
  display: flex;
  flex-direction: column-reverse;
  gap: 5px;
}
.list-move,
.list-enter-active,
.list-leave-active {
  transition: all 0.3s ease;
}

.list-enter-from,
.list-leave-to {
  opacity: 0;
  transform: translateX(40px);
}
.list-leave-active {
  position: absolute;
}
</style>