<template>
  <div class="editor" v-if="editor">
  
    <Toolbar class="toolbar" 
      :editor="editor" 
      @blur="handleBlur" 
      :showColorWheel="showColorWheel"
    />
    <editor-content class="editor-content" 
      :editor="editor" 
      :v-model="content"
      :style="editorStyle"
    />
    <h1>{{ result }}</h1>
    <BottomToolbar v-if="showBottomToolbar" class="bottom-toolbar-bulkupdate" hideSubmitButtons/>
  </div>
</template>

<script>
import Highlight from "@tiptap/extension-highlight";
import TaskItem from "@tiptap/extension-task-item";
import TaskList from "@tiptap/extension-task-list";
import StarterKit from "@tiptap/starter-kit";
import Underline from "@tiptap/extension-underline";
import Placeholder from "@tiptap/extension-placeholder";
import Color from "@tiptap/extension-color";
import Heading from "@tiptap/extension-heading";
import BulletList from "@tiptap/extension-bullet-list";
import { Editor, EditorContent } from "@tiptap/vue-3";

import Toolbar from "./subComponents/Toolbar.vue";
import BottomToolbar from "./subComponents/BottomToolbar.vue";
import TextStyle from "@tiptap/extension-text-style";

export default {
  name: "TextBox",
  components: {
    EditorContent,
    Toolbar,
    BottomToolbar,
  },

  data() {
    return {
      editor: null,
      result: null,
      hideCC: true,
      hideBCC: true,
    };
  },
  props: {
    defaultColor: {
      type: String,
      default: '#000000' 
    },
    showColorWheel: {
      type: Boolean,
      default: false 
    },
    subjectLine: String,
    showBottomToolbar: {
      type: Boolean,
      default: true
    },
    content: {
      type: String,
      default: "",
    },
  },
  emits: ["update-value"],
  computed: {
    editorStyle() {
      return {
        color: this.defaultColor,
      };
    }
  },
  watch: {
    content(newVal) {
      //console.log('New content received in TextBox:', newVal)
      if (this.editor && newVal !== this.editor.getHTML()) {
        this.editor.commands.setContent(newVal);
      }
    }
  },
  mounted() {
    //console.log('Mounted TextBox with content:', this.content);
    this.editor = new Editor({
      extensions: [
        StarterKit,
        Highlight.configure({ multicolor: true }),
        TaskList,
        TaskItem,
        Underline,
        TextStyle,
        Heading,
        Placeholder.configure({
          placeholder: "Begin typing your message here...",
        }),
        Color.configure({
          types: ["textStyle"],
        }),
        BulletList
      ],
    });
    this.editor.on('update', ({ editor }) => {
      this.$emit('update-value', editor.getHTML());
    })

    this.editor.commands.setContent(this.content);
  },

  methods: {
    handleCc(data) {
      // TODO: what needs to be done here besides a console.log? 
      console.log(data);
    },
  },

  beforeUnmount() {
    this.editor.destroy();
  },
};
</script>

<style scoped>
.editor-content :deep(.ProseMirror) {
  height: 258px;
  padding: 2rem;
  font-size: 12pt;
  background-color: #ffffff;
}

:deep(.ProseMirror:focus) {
  outline: none;
}


:deep(.ProseMirror p.is-editor-empty:first-child::before) {
  color: #b1cdea;
  content: attr(data-placeholder);
  float: left;
  font-size: 12pt;
  height: 100%;
  pointer-events: none;
}

.editor {
  /* border-radius: 7pt; */
  outline: 0.25pt solid #271d7a;
  overflow: hidden;
  min-height: 300px;
  max-height: 300px;
}

:deep(.editor-content .ProseMirror) {
  overflow: auto;
}
.toolbar {
  border: 1px solid #dde9f4;
  background-color: #ecf2f8;
  padding-left: 1rem;
  padding-top: 5px;
  padding-bottom: 5px;
}
</style>
