import { API } from "aws-amplify";


export async function queryGraphQL(options, retries = 0) {
    try {
        // console.log(options);
        var result = await API.graphql(options);
        return result;
    }
    catch(e) {
        if (e.errors[0].errorType === "ConditionalCheckFailedException") {
            console.log("Duplicate ID, exiting.");
            return {success: false, error: "duplicate_id"};
        }
        else if (retries < 16) {
            console.log(result);
            console.log(e);
            console.log(`Retrying queryGraphQL, retries = ${retries}`);
            console.log(options);
            await new Promise(resolve => setTimeout(resolve, 200));
            result = await queryGraphQL(options, ++retries);
            return result;
        }
        else {
            console.log(e);
            console.log("WE TRIED 16 TIMES AND IT DIDN'T WORK");
            throw e;
        }
    }
}