import { createRouter, createWebHashHistory } from 'vue-router'
import { Auth } from 'aws-amplify';
import login from '../views/auth/loginView.vue'
// import iconfig from '../views/Voice/iConfig/iconfigView.vue'
// import ticketSystem from '../views/ticket_system/TicketsView.vue'
import textToSpeech from '../views/textToSpeech/textToSpeechView.vue'
import intRateLookup from '../views/Billing/internationalRatesLookup/IntRateLookupView.vue'
import carrierRates from '../views/SwitchOps/carrierRates/CarrierRatesView.vue'
import taxCalculator from '../views/Billing/TaxCalculator/TaxCalculatorView.vue'
import AccountLimits from "@/views/SwitchOps/AccountLimits/AccountLimits.vue";
// import TicketsOpenView from "@/views/ticket_system/TicketsOpenView.vue";
import CcManagementView from "@/views/ContactCenter/CcManagement/CcManagementView.vue";
import { useAuthStore } from "@/stores/auth/authStore.js";
// import { employeeActionsByEmployee_id } from '@/graphql/queries';

const routes = [
  {
    path: '/',
    name: 'Login',
    component: login,
    beforeEnter: async () => {
      try {
        await Auth.currentAuthenticatedUser();

        const authStore = useAuthStore();
        await authStore.setDefaultApplicationPath();
        var path = await authStore.getDefaultApplicationPath || "/admin";  //default to admin if no path is found - for now
        router.push(path);
      }
      catch(err) {
        //do nothing
      }
    },
  },
  // {
  //   path: '/iconfig',
  //   name: 'IConfig',
  //   component: iconfig,
  //   meta: {
  //     requiresAuth: true
  //   }
  // },
  {
    path: '/tickets/:id?',
    name: 'Tickets',
    component: () =>
      import(
        /* webpackChunkName: "Lex" */ "@/views/ContactCenter/LEX/LexView.vue"
      ),
    meta: {
      requiresAuth: true,
      name: "Tickets"
    },
  },
  {
    path: '/textToSpeech',
    name: 'Text-to-Speech Converter',
    component: textToSpeech,
    meta: {
      requiresAuth: true,
      name: "Text-to-Speech Converter"
    }
  },
  {
    path: '/internationalRatesLookup',
    name: 'International Rates Lookup',
    component: intRateLookup,
    meta: {
      requiresAuth: true,
      name: "International Rates Lookup"
    }
  },
  {
    path: '/carrierRates',
    name: 'Carrier Rates',
    component: carrierRates,
    meta: {
      requiresAuth: true,
      name: "Carrier Rates"
    }
  },
  {
    path: '/taxCalculator',
    name: 'Tax Calculator',
    component: taxCalculator,
    meta: {
      requiresAuth: true,
      name: "Tax Calculator"
    }
  },
  {
    path: '/accountLimits',
    name: 'Account Limits',
    component: AccountLimits,
    meta: {
      requiresAuth: true,
      name: "Account Limits"
    }
  },
  {
    path: '/contactCenterManagement',
    name: 'Contact Center Management',
    component: CcManagementView,
    meta: {
      requiresAuth: true,
      name: "Contact Center Management"
    }
  },
  {
    path: '/contactCenterManagement/qualityAudit/:quality_audit_id',
    name: 'Contact Center Management Routed',
    component: CcManagementView,
    meta: {
      requiresAuth: true,
      name: "Contact Center Management"
    }
  },
  {
    path: "/lex",
    name: "LEX",
    component: () =>
      import(
        /* webpackChunkName: "Lex" */ "@/views/ContactCenter/LEX/LexView.vue"
      ),
    meta: {
      requiresAuth: true,
      name: "LEX"
    }
  },
  {
    path: "/flowPreview",
    name: "Flow Preview",
    component: () =>
      import(
        /* webpackChunkName: "Preview Flow" */ "@/views/ContactCenter/FlowPreview/FlowPreview.vue"
      ),
    meta: {
      requiresAuth: true,
      name: "Flow Preview"
    }
  },
  {
    path: "/analytics",
    name: "Analytics",
    component: () =>
      import(
        /* webpackChunkName: "CcManagement" */ "@/views/Analytics/AnalyticsView.vue"
      ),
    meta: {
      requiresAuth: true,
      name: "Analytics"
    }
  },
  {
    path: "/admin",
    name: "Admin",
    component: () =>
      import(
        /* webpackChunkName: "CcManagement" */ "@/views/admin/AdminView.vue"
      ),
    meta: {
      requiresAuth: true,
      name: "Admin"
    }
  },
  {
    path: "/CustomerManagement",
    name: "Customer Management",
    component: () => import(/* webpackChunkName: "Customer Management" */ '@/views/Billing/CustomerManagement/CustomerManagementView.vue'),
    meta: {
      requiresAuth: true,
      name: "Customer Management"
    }
  },
  {
    path: "/CustomerManagement/:partner/:account_number",
    name: "Services and Disconnects",
    component: () => import(/* webpackChunkName: "Customer Management" */ '@/views/Billing/CustomerManagement/pages/ServicesAndDisconnects.vue'),
    meta: {
      requiresAuth: true,
      name: "Customer Management"
    }
  },
  {
    path: "/TrafficStudyProcessing",
    name: "Traffic Study Processing",
    component: () => import(/* webpackChunkName: "CcManagement" */ "@/views/Billing/TrafficStudyProcessing/TrafficStudyProcessing.vue"),
    meta: {
      requiresAuth: true,
      name: "Traffic Study Processing"
    }
  },
  {
    path: "/Pricebooks",
    name: "Pricebook",
    component: () => import(/* webpackChunkName: "CcManagement" */ "@/views/Billing/Pricebooks/pricebooksView.vue"),
    meta: {
      requiresAuth: true,
      name: "Pricebook"
    }
  },
  {
    path: "/BConfig",
    name: "bConfig",
    component: () => import(/* webpackChunkName: "CcManagement" */ "@/views/Voice/bConfig/BConfigView.vue"),
    meta: {
      requiresAuth: true,
      name: "bConfig"
    }
  },
  {
    path: "/IConfig",
    name: "iConfig",
    component: () => import(/* webpackChunkName: "CcManagement" */ "@/views/Voice/iConfig/iconfigView.vue"),
    meta: {
      requiresAuth: true,
      name: "iConfig",
    }
  },
  {
    path: "/E911",
    name: "E911",
    component: () => import(/* webpackChunkName: "CcManagement" */ "@/views/Voice/E911/E911View.vue"),
    meta: {
      requiresAuth: true,
      name: "E911"
    }
  },
  {
    path: "/voiceManagement",
    name: "Management",
    component: () => import(/* webpackChunkName: "CcManagement" */ "@/views/Voice/Management/ManagementView.vue"),
    meta: {
      requiresAuth: true,
      name: "Management"
    }
  },
  {
    path: "/Requests",
    name: "Requests",
    component: () => import(/* webpackChunkName: "CcManagement" */ "@/views/Voice/Porting/PortingView.vue"),
    meta: {
      requiresAuth: true,
      name: "Requests"
    }
  },
  {
    path: "/Provisioning",
    name: "Provisioning",
    component: () => import(/* webpackChunkName: "CcManagement" */ "@/views/Voice/Provisioning/VoiceProvisioningView.vue"),
    meta: {
      requiresAuth: true,
      name: "Provisioning"
    }
  },
  {
    path: "/workspace",
    name: "My Workspace",
    component: () => import(/* webpackChunkName: "Workspace" */ '@/views/Workspace/Workspace.vue'),
  },
  {
    path: "/NumberManagement",
    name: "Number Management",
    component: () => import(/* webpackChunkName: "Carrier Rate" */ "@/views/SwitchOps/NumberManagement/NumberManagementView.vue"),
    meta: {
      requiresAuth: true,
      name: "Number Management"
    }
  },
  {
    path: "/dispatchSchedule",
    name: "Dispatch Schedule",
    component: () => import(/* webpackChunkName: "Carrier Rate" */ "@/views/LES/DispatchSchedule/DispatchScheduleView.vue"),
    meta: {
      requiresAuth: true,
      name: "Dispatch Schedule"
    }
  },
  {
    path: "/quotes",
    name: "Quotes",
    component: () => import(/* webpackChunkName: "Carrier Rate" */ "@/views/LES/Quotes/QuotesView.vue"),
    meta: {
      requiresAuth: true,
      name: "Quotes"
    }
  },
  {
    path: "/lexPortal",
    name: "Switch Support",
    component: () => import(/* webpackChunkName: "Carrier Rate" */ "@/views/LEX/LexPortalView.vue"),
    meta: {
      requiresAuth: true,
      name: "Switch Support"
    }
  },
  {
    path: "/info_all.cgi",
    redirect: to => {
      return { path: '/tickets', query: {SubscriberId: to.query.SubscriberId, SubId: to.query.SubId}}
    }
  },
  {
    path: "/ticketReports.cgi",
    redirect: to => {
      return { path: '/tickets', query: {SubscriberId: to.query.SubscriberId, SubId: to.query.SubscriberId}}
    }
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
});


// Router Hooks
router.beforeEach(async (to, from, next) => {
  const authStore = useAuthStore();
  authStore.pushToLocationArray(to);

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    try {
      await Auth.currentAuthenticatedUser();
      const toLocation = to.meta.name;

      var availableGroups = authStore.getUsersGroups;
     
      if (!availableGroups) {
        await authStore.fetchUsersGroups();
        availableGroups = authStore.getUsersGroups;
      }


      // console.log("ROUTER BEFORE EACH");
      // console.log('TO', to);
      // console.log("AVAILABLE GROUPS", availableGroups);


      for (var groups of availableGroups) {
        for (var application of groups.Applications.items) {
          //console.log("application: ", application.application.name);

          //Handle same name apps in different sections
          if (`${application.application.application_section} ${application.application.name}` === toLocation) {
            next();
            return;
          }

          if (application.application.name === toLocation) {
            next();
            return;
          }
        }
      }

      next("/");
    }
    catch(err) {
      console.log(err);
      next("/");
    }
  }
  else {
    next();
  }
});


export{
  router
};
