import { defineStore } from "pinia";
import { useAuthStore } from "@/stores/auth/authStore.js";
import { Auth } from "aws-amplify";
import { API } from "aws-amplify";
import * as queries from "@/graphql/queries.js";
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api";
import { queryGraphQL } from "@/helpers/dbUtil.js";



export const useAgentDashboardStore = defineStore("AgentDashboard", {
    state: () => ({
        agentData: [],
        agent_list: []
    }),
    getters: {
        getAgentData(state) {
            return state.agentData
        }
    },
    actions: {
        async initializeAgentSocket() {
            const api_name = "switch";
            const path = `/get_auth_token`;

            const userAuth = `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`;

            const myInit = {
                headers: {
                    Authorization: userAuth,
                    "Content-Type": "application/json"
                },
                body: {}
            }

            let result_json = await API.put(api_name, path, myInit);


            const authStore = useAuthStore();
            const websocket_url = authStore.getSwitchWebSocketUrl;

            var AgentSocket = new WebSocket(websocket_url);

            //TODO: Make Socket Connection
            AgentSocket.onopen = function () {
                //A subscription must include a valid pair of account_id / auth_token, and must include the binding that you want to listen to.
                var recipientSubscription = {
                    action: 'subscribe',
                    auth_token: result_json.data.auth_token,
                    data: {
                        account_id: '519b73ddf6c2ba5688acc12e9d0c01d4',
                        binding: 'qubicle.recipient',
                    }
                };


                //bindings we might want: qubicle.recipient, dashboard.callcenter_pro, qubicle.queue
                //The data flowing through sockets must be a String, so we cast the JSON into a String
                var recipientSubscriptionString = JSON.stringify(recipientSubscription);
                //Once we properly configured our subscription, we can send the corresponding string to the WebSocket, which will notify the system that we want to subscribe to an event
                AgentSocket.send(recipientSubscriptionString);
            };


            return AgentSocket;
        },
        async setAgentList() {
            var api_name = "switch";
            var path = `/accounts_frontend/519b73ddf6c2ba5688acc12e9d0c01d4/qubicle_recipients`;

            var userAuth = `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`;

            var myInit = {
                headers: {
                    Authorization: userAuth,
                    "Content-Type": "application/json"
                },
            }

            let result_json = await API.get(api_name, path, myInit);

            var usersData = await this.retrieveAllContactCenterUsers();


            var agents = result_json.data.data;
            var agent_ids = [];

            for (var agent of agents) {
                var presence_id = "";
                for (var user of usersData) {
                    if (user.user_id == agent.id) {
                        presence_id = user.presence_id;
                    }
                }

                const supervisorName = await this.fetchSupervisors(agent.display_name);

                this.agent_list[agent.id] = { display_name: agent.display_name, email: agent.name, presence_id: presence_id, supervisor: supervisorName};
                agent_ids.push(agent.id);
            }

            api_name = "switch";
            path = `/accounts_frontend/519b73ddf6c2ba5688acc12e9d0c01d4/qubicle_recipients/status`;

            userAuth = `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`;

            myInit = {
                headers: {
                    Authorization: userAuth,
                    "Content-Type": "application/json"
                },
                body: {
                    data: {
                        recipient_ids: agent_ids
                    }
                }
            }

            result_json = await API.post(api_name, path, myInit);

            var agent_statuses = result_json.data.data;

            console.log("Agent Statuses: ", agent_statuses);

            if (this.agentData.length == 0) {
                for (const [key, value] of Object.entries(agent_statuses)) {
                    var availability_state = value.availability_state;

                    if (value == "not_logged_in") {
                        availability_state = "Logged Out";
                        continue; //TODO: REMOVE THIS TO ADD LOGGED OUT AGENTS: NEEDED FOR SECOND RELEASE
                    }

                    let agent_status;
                    if (availability_state === "On-A-Call" && value.handling_call && value.handling_call.queue_name) {
                        let callerId = value.handling_call.caller_id_num ? `</br>${value.handling_call.caller_id_num}` : "";
                        agent_status = `On-A-Call</br>${value.handling_call.queue_name}${callerId}`;
                    } else {
                        agent_status = availability_state; 
                    }                                   

                    var gregorian_offset = 62167219200;
                    var timeInCurrentStatus = Math.floor(Date.now() / 1000) - (value.stats.last_action_time - gregorian_offset);
                    var abandoned_calls = value.stats.offered_calls - (value.stats.total_calls + value.stats.missed_calls);

                    var agent_tickets = await this.getAgentsTicketsForToday(key);
                    var outboundCallsCount = await this.getAgentOutboundCalls(key, this.agent_list[key].presence_id, (value.stats.login_time - gregorian_offset));
                    var average_recovery_time = await this.getAgentAverageRecoveryTime(key);

                    var ticketResolvedPercent = 0;
                    var ticketEscalatedPercent = 0;
                    if (agent_tickets.totalTickets != 0) {
                        ticketResolvedPercent = Number(agent_tickets.resolvedTickets) / Number(agent_tickets.totalTickets) * 100;
                        ticketEscalatedPercent = Number(agent_tickets.escalatedTickets) / Number(agent_tickets.totalTickets) * 100;
                    }

                    this.agentData.push({
                        recipient_id: key,
                        agentName: this.agent_list[key].display_name,
                        agentEmail: this.agent_list[key].email,
                        status: { first: agent_status, second: timeInCurrentStatus },
                        callsCurrentShift: value.stats.offered_calls,
                        outboundCalls: outboundCallsCount,
                        answeredCalls: value.stats.total_calls,
                        missedCalls: value.stats.missed_calls,
                        abandonedCalls: abandoned_calls,
                        ticketsResolved: { first: agent_tickets.resolvedTickets, second: `${ticketResolvedPercent}%` },
                        ticketsEscalated: { first: agent_tickets.escalatedTickets, second: `${ticketEscalatedPercent}%` },
                        averageRecoveryTime: average_recovery_time,
                        supervisor: this.agent_list[key].supervisor || "N/A",
                        searchable_status: agent_status
                    });
                }
            }

            // Sort the data
            // this.agentData.sort((a, b) => a.agentName.localeCompare(b.agentName));

            console.log("Agent Data: ", this.agentData);

            //console.log("this.agents_table_data", this.agents_table_data);
           
            // this.agents_unchanged_data = [...this.agents_table_data].sort((a, b) => a.agentName.localeCompare(b.agentName));

            // this.setAgentFilters();
        },
        async retrieveAllContactCenterUsers() {
            var hasData = true;
            var nextToken = null;
            var usersData = [];


            while (hasData) {
                var options = {
                    query: queries.listSwitchUsers,
                    variables: {
                        limit: 1000,
                        filter: {
                            account_id: {
                                eq: "519b73ddf6c2ba5688acc12e9d0c01d4" //Contact Center
                            },
                        }
                    },
                    authMode: GRAPHQL_AUTH_MODE.API_KEY
                }

                if (nextToken != null) {
                    options.variables.nextToken = nextToken;
                }

                var users = await API.graphql(options);

                if (users.data.listSwitchUsers.nextToken != null) {
                    nextToken = users.data.listSwitchUsers.nextToken;

                    for (const item of users.data.listSwitchUsers.items) {
                        usersData.push(item);
                    }
                }
                else {
                    hasData = false;

                    for (const item of users.data.listSwitchUsers.items) {
                        usersData.push(item);
                    }
                }
            }

            return usersData;
        },
        async fetchSupervisors(agentName = null) {
            var error_message = "Unable to Get Supervisors";
            try {
                var hasData = true;
                var nextToken = null;
                var supervisorsSet = new Set();
        
                const normalizedAgentName = agentName ? agentName.toLowerCase().replace(/\s+/g, '') : null;
        
                while (hasData) {
                    var options = {
                        query: queries.listEmployees,
                        variables: {
                            filter: {
                                _deleted: { ne: true },
                                assigned_group: { eq: "Contact Center" }
                            },
                            limit: 2000
                        },
                        authMode: GRAPHQL_AUTH_MODE.API_KEY
                    }
        
                    if (nextToken != null) {
                        options.variables.nextToken = nextToken;
                    }
        
                    var result = await API.graphql(options);
        
                    for (const item of result.data.listEmployees.items) {
                        const itemNameNormalized = item.name.toLowerCase().replace(/\s+/g, '');
        
                        if (normalizedAgentName && itemNameNormalized === normalizedAgentName) {
                            return item.supervisor || "N/A";
                        }
                        if (!normalizedAgentName && item.supervisor && !supervisorsSet.has(item.supervisor)) {
                            supervisorsSet.add(item.supervisor);
                        }
                    }
        
                    if (result.data.listEmployees.nextToken != null) {
                        nextToken = result.data.listEmployees.nextToken;
                    } else {
                        hasData = false;
                    }
                }
        
                if (normalizedAgentName) {
                    return "N/A";
                }
                
                return Array.from(supervisorsSet);
            }
            catch (error) {
                return { error: true, message: error_message };
            }
        },  
        async getAgentsTicketsForToday(recipient_id) {
            var rightNow = new Date();
            var startOfDay = new Date(rightNow.getFullYear(), rightNow.getMonth(), rightNow.getDate(), 0, 0, 0);
            startOfDay.setHours(0, 0, 0, 0);
            var agent_email = this.agent_list[recipient_id].email;


            const searchTickets = /* GraphQL */ `
            query SearchTickets(
                $filter: SearchableTicketsFilterInput
                $sort: [SearchableTicketsSortInput]
                $limit: Int
                $nextToken: String
                $from: Int
                $aggregates: [SearchableTicketsAggregationInput]
            ) {
                searchTickets(
                filter: $filter
                sort: $sort
                limit: $limit
                nextToken: $nextToken
                from: $from
                aggregates: $aggregates
                ) {
                items {
                    id
                    queue_id
                    queue {
                    id
                    queue
                    queue_name
                    switch_queue_name
                    contact_information
                    sign_off_information
                    flow_information
                    ticket_subject_values
                    tools_list
                    createdAt
                    updatedAt
                    _version
                    _deleted
                    _lastChangedAt
                    __typename
                    }
                    subject
                    description
                    status
                    time_started
                    time_last_contact
                    priority
                    owner
                    owner_group
                    creator
                    requestor
                    requestor_group
                    cc
                    createdAt
                    updatedAt
                    contact_information
                    custom_fields {
                    items {
                        set_value
                        queue_custom_field {
                        default_custom_field {
                            field_name
                        }
                        }
                    }
                    }
                    comments {
                    nextToken
                    startedAt
                    __typename
                    }
                    activity {
                    items {
                        activity_type
                        field_changed
                        new_field_value
                        old_field_value
                    }
                    }
                    _version
                    _deleted
                    _lastChangedAt
                    __typename
                }
                nextToken
                total
                aggregateItems {
                    name
                    result {
                    ... on SearchableAggregateScalarResult {
                        value
                    }
                    ... on SearchableAggregateBucketResult {
                        buckets {
                        key
                        doc_count
                        __typename
                        }
                    }
                    }
                    __typename
                }
                __typename
                }
            }
            `;


            var options = {
                query: searchTickets,
                variables: {
                    limit: 1000,
                    filter: {
                        createdAt: {
                            gt: startOfDay.toISOString()
                        },
                        owner: {
                          eq: agent_email
                        }
                    }
                },
                authMode: GRAPHQL_AUTH_MODE.API_KEY
            }
            try {
                var ticketsResult = await queryGraphQL(options);
                // console.log("TICKETS RESULT", ticketsResult);
            }
            catch (error) {
                console.log(error);
            }

            var tickets = ticketsResult.data.searchTickets.items;

            var resolvedTickets = 0;
            var escalatedTickets = 0;
            var totalTickets = ticketsResult.data.searchTickets.total;
            for (var ticket of tickets) {
                if (ticket.status == "Resolved") {
                    resolvedTickets++;
                }

                var custom_fields = ticket.custom_fields?.items;


                if (custom_fields) {
                    for (var custom_field of custom_fields) {
                        if (custom_field.queue_custom_field.default_custom_field.field_name == "Escalation" && custom_field.set_value != "") {
                            escalatedTickets++;
                        }
                    }
                }
            }




            return { resolvedTickets: resolvedTickets, escalatedTickets: escalatedTickets, totalTickets: totalTickets };
        },  
        async getAgentOutboundCalls(recipient_id, presence_id, loggedInTime) {
            var api_name = "switch";
            var path = `/accounts_frontend/519b73ddf6c2ba5688acc12e9d0c01d4/cdrs/get_user_specific`;

            var userAuth = `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`;

            var myInit = {
                headers: {
                    Authorization: userAuth,
                    "Content-Type": "application/json"
                },
                body: {
                    user_id: recipient_id,
                    query: {
                        created_from: loggedInTime,
                        created_to: loggedInTime + 86400,
                    }
                }
            }

            var result_json = await API.post(api_name, path, myInit);
            var agent_calls = result_json.data.data;

            var outboundCallsCount = 0;
            for (var agent_call of agent_calls) {
                if (agent_call.caller_id_number == presence_id) {
                    outboundCallsCount++;
                }
            }

            return outboundCallsCount;
        },
        async getAgentAverageRecoveryTime(recipient_id) {
            var rightNow = new Date();
            var startOfDay = new Date(rightNow.getFullYear(), rightNow.getMonth() - 3, rightNow.getDate(), 0, 0, 0);
            var hasData = true;
            var nextToken = null;
            var recipient_events = [];

            while (hasData) {
                var options = {
                    query: queries.searchAgentsRecoveryTimes,
                    variables: {
                        filter: {
                            recipient_id: { eq: recipient_id},
                            createdAt: { gt: startOfDay.toISOString()}
                        },
                    },
                    authMode: GRAPHQL_AUTH_MODE.API_KEY
                }

                if (nextToken != null) {
                    options.variables.nextToken = nextToken;
                }

                var result = await API.graphql(options);

                if (result.data.searchAgentsRecoveryTimes.nextToken != null) {
                    nextToken = result.data.searchAgentsRecoveryTimes.nextToken;

                    for (const item of result.data.searchAgentsRecoveryTimes.items) {
                        recipient_events.push(item);
                    }
                }
                else {
                    hasData = false;

                    for (const item of result.data.searchAgentsRecoveryTimes.items) {
                        recipient_events.push(item);
                    }
                }
            }

            var countOfRecoveryEvents = 0;
            var totalRecoveryTime = 0;

            for (var event of recipient_events) {
                totalRecoveryTime += event.recovery_time;
                countOfRecoveryEvents++;
            }

            var average_recovery_time = 0;
            if (totalRecoveryTime != 0) {
                average_recovery_time = Math.ceil(totalRecoveryTime / countOfRecoveryEvents);
            }

            return this.toHHMMSS(average_recovery_time);        
        },
        toHHMMSS(secs) {
            var sec_num = parseInt(secs, 10)
            var hours = Math.floor(sec_num / 3600)
            var minutes = Math.floor(sec_num / 60) % 60
            var seconds = sec_num % 60

            return [hours, minutes, seconds]
                .map(v => v < 10 ? "0" + v : v)
                .filter((v, i) => v !== "00" || i > 0)
                .join(":")
        },
        async handleAgentEvent(event) {
            //console.log("agent event", event);
            var agentFound = false;
            var agent_status = event.data.availability_state;
            var gregorian_offset = 62167219200;

            if (event.name == "offer") {
                let callIdNum = event.data.caller_id_num ? `<br>${event.data.caller_id_num}` : "";
                let queueName = event.data.queue_name ? `<br>From ${event.data.queue_name}` : "";
                agent_status = `Call Offered${queueName}${callIdNum}`;
            }
            else if (event.name == "wrapup_start") {
                agent_status = "Wrapup-Time";
            }
            else if (event.name == "delivered") {
                let callType = "Inbound";
                let callIdNum = event.data.caller_id_num ? `<br>${event.data.caller_id_num}` : "";
                let queueName = event.data.queue_name ? `<br>${event.data.queue_name}` : ""; 
                agent_status = `On-A-Call<br>${callType}${queueName}${callIdNum}`;
            }
            else if (event.name == "external_call_start") {
                let callType = "Outbound";
                let callIdNum = event.data.caller_id_num ? `<br>${event.data.caller_id_num}` : "";
                let queueName = event.data.queue_name ? `<br>${event.data.queue_name}` : ""; 
                agent_status = `On-A-Call<br>${callType}${queueName}${callIdNum}`;
            }
            else if (event.name == "away") {
                let reason = event.data.reason;
                agent_status = `Away - ${reason}`;
            }
            else if (event.data.state == "away") {
                agent_status = "Away";
            }
            else if (event.data.state == "ready") {
                agent_status = "Ready";
            }                           

            var agent_tickets = await this.getAgentsTicketsForToday(event.data.recipient_id);
            var outboundCallsCount = await this.getAgentOutboundCalls(event.data.recipient_id, this.agent_list[event.data.recipient_id].presence_id, (event.data.stats.login_time - gregorian_offset));
            var average_recovery_time = await this.getAgentAverageRecoveryTime(event.data.recipient_id);

            var ticketResolvedPercent = 0;
            var ticketEscalatedPercent = 0;
            if (agent_tickets.totalTickets != 0) {
                ticketResolvedPercent = (Number(agent_tickets.resolvedTickets) / Number(agent_tickets.totalTickets) * 100).toFixed(2);
                ticketEscalatedPercent = (Number(agent_tickets.escalatedTickets) / Number(agent_tickets.totalTickets) * 100).toFixed(2);
            }

            if (this.agentData.length) {
                this.agentData.forEach((agent, index) => {
                    if (agent.recipient_id == event.data.recipient_id) {
                        agentFound = true;

                        this.agentData[index] = {
                            recipient_id: event.data.recipient_id,
                            agentName: this.agent_list[event.data.recipient_id].display_name,
                            agentEmail: this.agent_list[event.data.recipient_id].email,
                            status: { first: agent_status, second: 0 },
                            searchable_status: agent_status,
                            callsCurrentShift: event.data.stats.offered_calls,
                            outboundCalls: outboundCallsCount, //TODO: Need to track this through a table 
                            answeredCalls: event.data.stats.total_calls,
                            missedCalls: event.data.stats.missed_calls,
                            abandonedCalls: event.data.stats.missed_calls,
                            ticketsResolved: { first: agent_tickets.resolvedTickets, second: `${ticketResolvedPercent}%` },
                            ticketsEscalated: { first: agent_tickets.escalatedTickets, second: `${ticketEscalatedPercent}%` },
                            averageRecoveryTime: average_recovery_time, //TODO: How to track this data?
                            supervisor: "Supervisor 1", //TODO: How to track this data?
                        };
                    }
                });
            }


            if (!agentFound) {
                this.agentData.push({
                    recipient_id: event.data.recipient_id,
                    agentName: this.agent_list[event.data.recipient_id].display_name,
                    agentEmail: this.agent_list[event.data.recipient_id].email,
                    status: { first: agent_status, second: 0 },
                    searchable_status: agent_status,
                    callsCurrentShift: event.data.stats.offered_calls,
                    outboundCalls: outboundCallsCount, //TODO: Need to track this through a table 
                    answeredCalls: event.data.stats.total_calls,
                    missedCalls: event.data.stats.missed_calls,
                    abandonedCalls: event.data.stats.missed_calls,
                    ticketsResolved: { first: agent_tickets.resolvedTickets, second: `${ticketResolvedPercent}%` },
                    ticketsEscalated: { first: agent_tickets.escalatedTickets, second: `${ticketEscalatedPercent}%` },
                    averageRecoveryTime: "00:00:24", //TODO: How to track this data?
                    supervisor: "Supervisor 1", //TODO: How to track this data?
                });
            }
        },
        resetAgentData() {
            this.agentData = [];
            this.agent_list = [];
        }
    }
});