<template>
    <SecondaryNavBar
      title="SwitchOps - Carrier Rates"
      @button-click="handleButtonClick"
      :rightAlignedButtons="getNavButtons"
    />
    <div class="carrierRatesBackground">
      <div class="carrierRatesContainer">
        <div class="carrierRatesContentContainer">
          <div class="carrierRatesPageContent">
            <div class="carrierFormContainer">
              <FormComp
                :form-data="carrierRatesData"
                :buttonOptions="{
                  positionBottom: false,
                  centered: false,
                  fillToParent: false,
                  divider: true
                }"
                :numCols="4"
                @submission-data="handleFormSubmission"
              />
            </div>
            <TableComp
              v-if="showTable"
              :headers="headers"
              :dataList="data"
              :filterOptionsArray="filterOptionsArray"
              :fixedWidth="true"
              backgroundColor="#e2f1f4"
              @click-row="handleClickRow"
            />
          </div>
          <AlertsGroup
            v-model:alerts="alerts"
          />
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { mapStores, mapActions } from 'pinia';
  import { useCarrierRatesStore } from "@/stores/SwitchOps/CarrierRates/carrierRatesStore.js";
  import { useHelpStore } from '@/stores/Help/helpStore.js';
  import FormComp from "@/components/base_components/FormComponents/FormComp.vue";
  import SecondaryNavBar from "@/components/base_components/Navigation/SecondaryNavBar.vue";
  import TableComp from "@/components/base_components/TableComp.vue";
  import AlertsGroup from "@/components/base_components/AlertsGroup.vue";
  import { downloadCSV } from "@/helpers/csvUtils";
  import { addAlert } from '@/helpers/alertUtils';
  import { ERROR } from '@/constants/statuses.constants';

  export default {
    name: "CarrierRatesView",
    components: {
      SecondaryNavBar,
      FormComp,
      TableComp,
      AlertsGroup,
    },
    data() {
      return {
        windowHeight: null,
        showTable: false,
        alerts: [],
        filterOptionsArray: [],
        carrierRatesData: [
          { title: "Carrier Rates", type: "form", button: "Search" },
          {
            title: "",
  
            inputs: [
              { name: "NPANXX", type: "bubble", required: false },
              { name: "LATA", type: "bubble", required: false },
              { name: "Rate Center", type: "bubble", required: false },
            ],
          },
        ],
        headers: [
          { text: "NPANXX", value: "npanxx" },
          { text: "Carrier", value: "carrier" },
          { text: "LATA", value: "lata" },
          { text: "Direction", value: "direction" },
          { text: "Rate Center", value: "rateCenter" },
          { text: "Interstate Rate", value: "interstateRate" },
          { text: "Intrastate Rate", value: "intrastateRate" },
        ],
        data: [

        ],
      };
    },
    async mounted() {
      window.addEventListener("resize", this.handleResize);
      this.windowHeight = window.innerHeight;
    },
    created() {
      this.HelpManagementStore.setPage('SwitchOps / Carrier Rates');
    },
    beforeUnmount() {
      window.removeEventListener("resize", this.handleResize);
    },
    computed: {
      ...mapStores(useCarrierRatesStore, useHelpStore),
      getNavButtons() {
        if (this.showTable) {
          return [{ title: "Download", icon: "Export.png" }];
        }
        return [];
      },
    },
    methods: {
      ...mapActions(useCarrierRatesStore, ['fetchCarrierRates']),
      ...mapActions(useHelpStore, ['setPage']),
      handleResize() {
        this.windowHeight = window.innerHeight;
      },
      handleButtonClick(title) {
        if (title === "Download") {
          downloadCSV(
            this.headers,
            this.data,
            this.filterOptionsArray,
            "CarrierRates"
          );
        }
      },
      isValidForm(formData){
        let valid = true;
        let npRegEx = /^\d{6}$/;
        let lataRegEx = /^\d{3}$/;
        if(formData["NPANXX"] && !npRegEx.test(formData["NPANXX"])){
          addAlert(ERROR, "NPANXX Field must be 6 digits.", this.alerts);
          valid = false;
        }
        if(formData["LATA"] && !lataRegEx.test(formData["LATA"])){
          addAlert(ERROR, "LATA Field must be 3 digits.", this.alerts);
          valid = false;
        }
        return valid;
      },
      handleFormSubmission(formData) {
        if (formData === "Error" || Object.keys(formData).length === 0) {
          addAlert(ERROR, "Please enter a Search Value", this.alerts);
        } else {
          if(this.isValidForm(formData) === true) {
              //APPLY FILTERS
              this.filterOptionsArray = [];
    
              //get header value from header text
              let headerVal;
              for (let key in formData) {
                for (const header of this.headers) {
                  if (header.text == key) {
                    headerVal = header.value;
                  }
                }

                const value = formData[key];
                if (value) {
                  this.filterOptionsArray.push({
                    field: headerVal,
                    comparison: headerVal === "created" ? "<" : "=",
                    criteria: value,
                  });
                }
              }
              this.showTable = true;

              this.fetchCarrierRates(formData['NPANXX'], formData['LATA'], formData['Rate Center']).then(() => {
                this.data = this.CarrierRatesStore.getCarrierRates;
              });
          }
        }
      },
      handleClickRow (data) {
        console.log(data);
      }
    },
  };
  </script>
  
  <style scoped>
  html {
    overflow-y: auto !important;
  }
  .carrierRatesBackground {
    background-color: #e2f1f4;
    min-height: calc(100vh - 93px);
  }
  .carrierRatesContainer {
    display: flex;
    width: 85%;
    padding-left: 15px;
  }
  .carrierRatesPageContent {
    flex-grow: 1;
  }
  .carrierRatesContentContainer {
    display: flex;
    flex-grow: 1;
    position: relative;
  }
  .carrierFormContainer {
    margin: 20px 0px 16px 0px;
    filter: drop-shadow(1px 1px 1px rgb(39, 29, 122, 0.2));
  }
  .formContainer {
    padding-bottom: 0;
  }
  @media (max-width: 820px) {
    .carrierRatesContainer {
      width: 100%;
      padding: 0px;
    }
    .carrierRatesPageContent {
      padding: 20px;
      overflow: auto !important;
    }
    .carrierRatesContentContainer {
      overflow: auto !important;
    }
  }
  </style>
  