const PUBLISHED = 'Published';
const SUCCESS = 'Success';
const ERROR = 'Error';
const UPDATE = 'Update';
const CONFIRM_REMOVE = 'Confirm Remove';
const CLOSE = 'Close';
const ADD = 'Add';
const REMOVE = 'Remove';
const VIEW = 'View';
const DRAWER = 'drawer';
const APPLY = 'Apply';
const DRAWER_LEFT = 'drawer_left';
const EXPORT_TO_PDF = 'Export to PDF';
const SEARCH = 'Search';
const OPEN = 'Open';
const COLLAPSE = 'Collapse';
const DOWNLOAD = 'Download';
const NOTIFICATION = 'Notification';

export {
  PUBLISHED,
  SUCCESS,
  ERROR,
  UPDATE,
  CONFIRM_REMOVE,
  CLOSE,
  ADD,
  REMOVE,
  VIEW,
  DRAWER,
  DRAWER_LEFT,
  EXPORT_TO_PDF,
  SEARCH,
  OPEN,
  APPLY,
  COLLAPSE,
  DOWNLOAD,
  NOTIFICATION
}