<template>
  <div class="reviewFormContainer">
    <div class="reviewFormContent">
      <p class="reviewFormTitle">{{ drawerData[0].title }}</p>
      <v-form ref="form" @submit.prevent="onSubmit()">
        <div v-for="(section, sectionIndex) in drawerData" :key="sectionIndex">
          <template
            v-for="(input, inputIndex) in section.inputs"
            :key="inputIndex"
          >
            <v-row>
              <v-col cols="12" class="sideDrawerInfoCol">
                <div>
                  <p
                    class="drawerDetailTitle"
                    v-if="input.type != 'dynamicOptions'"
                  >
                    <!-- {{ input.name }} -->
                    <span v-html="input.name"></span>
                  </p>
                  <template v-if="Array.isArray(input.value)">
                    <p class="drawerDetailTitle" v-if="section?.show">
                      Options
                    </p>
                    <p
                      class="drawerDetailValue"
                      v-for="(option, index) in input.value"
                      :key="index"
                    >
                      {{ index + 1 }}) {{ option.value }}
                    </p>
                  </template>
                  <template v-else>
                    <p class="drawerDetailValue" v-if="!input.test">
                      <!-- {{ input.value }} -->
                      <span v-html="input.value"></span>
                    </p>
                  </template>
                </div>
              </v-col>
            </v-row>
          </template>
          <template
            v-if="sectionIndex != 0 && section.type === 'dynamicOptions'"
          >
            <DividerComp class="my-3"
          /></template>
        </div>
        <v-row
          :style="{ marginTop: '0px !important' }"
          v-if="drawerData[0].confirmPhrase"
        >
          <v-col cols="12" class="sideDrawerInfoCol">
            <label class="drawerDetailTitle"> Enter phrase to update... </label>
            <label class="drawerDetailTitle">
              Phrase:
              <span :style="{ fontWeight: '700' }">This is permanent </span>
            </label>
            <FillInBubble
              placeholder="This is permanent"
              @input="updateInputVal($event)"
              :isRequired="true"
            /> </v-col
        ></v-row>
        <div class="reviewButtonContainer">
          <DividerComp class="formButtonDivider" />
          <div
            class="cont"
            :style="{ width: this.buttonOptions.width || '100%' }"
          >
            <DarkButton
              :title="drawerData[0].button"
              width="parent"
              :style="{ marginRight: '10px' }"
            />
            <LightButton
              title="Back"
              :style="{ height: '29px' }"
              @button-click="$emit('button-click', $event)"
              v-if="drawerData[0].backButton"
            />
          </div>
        </div>
      </v-form>
    </div>
  </div>
  <AlertsGroup
    v-model:alerts="alerts"
  />
</template>
  
  <script>
  import DividerComp from "@/components/base_components/DividerComp.vue";
  import DarkButton from "@/components/base_components/Buttons/DarkButton.vue";
  import LightButton from "@/components/base_components/Buttons/LightButton.vue";
  import FillInBubble from "@/components/base_components/FormComponents/FillInBubble.vue";
  import AlertsGroup from '@/components/base_components/AlertsGroup.vue';
  import { addAlert } from '@/helpers/alertUtils';
  import { ERROR } from "@/constants/statuses.constants.js";

  
  export default {
    name: "ReviewForm",
    data() {
      return {
        options: [{ value: "" }],
        confirmPhrase: "",
        alerts: [],
      };
    },
    props: {
      drawerData: Array,
      buttonOptions: {
        type: Object,
        default() {
          return {
            positionBottom: true,
            centered: true,
            fillToParent: true,
            width: "100%",
          };
        },
      },
    },
    methods: {
      updateInputVal(event) {
        if (event.target.value) {
          this.confirmPhrase = event.target.value;
        } else {
          this.confirmPhrase = "";
        }
      },
      onSubmit() {
        if (
          (this.drawerData[0].confirmPhrase &&
            this.confirmPhrase === "This is permanent") ||
          !this.drawerData[0].confirmPhrase
        ) {
          this.$emit("confirm-review", true);
        } else {
          addAlert(ERROR, "Phrase doesn't match", this.alerts);
        }
      }
    },
    components: { DarkButton, LightButton, DividerComp, FillInBubble, AlertsGroup },
    emits: [
      "button-click",
      "confirm-review"
    ]
  };
  </script>
  
  <style scoped>
  .reviewFormContainer {
    height: 100%;
    background-color: #ffffff;
  }
  .reviewFormContent {
    padding: 11px 16px;
  }
  .reviewButtonContainer {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    align-items: center;
    padding: 8px;
    z-index: 5;
    padding-bottom: 16px;
  }
  .reviewButtonPositionBottom {
    position: absolute;
    padding-bottom: 20px;
  }
  .sideDrawerInfoCol {
    display: flex;
    flex-direction: column;
  }
  .drawerDetailTitle {
    font-size: 9pt;
    font-weight: 600;
    color: #271d7a;
    padding-bottom: 5px;
  }
  
  .drawerDetailValue {
    font-size: 9pt;
    font-weight: normal;
    color: #271d7a;
  }
  .cont {
    display: flex;
    flex-direction: row;
  }
  .reviewFormTitle {
    color: #271e7a;
    font-weight: bold;
    font-size: 14pt;
    padding-top: 5px;
    padding-bottom: 10px;
  }
  :deep(.formButtonDivider) {
    margin-bottom: 16px;
  }
  </style>