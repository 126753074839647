<template>
  <div
    class="sideDrawer"
    :class="{
      open: localDrawerOpen,
      formBackground:
        drawerData[0].type === 'form' || drawerData[0].type === 'review',
    }"
    :style="{ width: dynamicWidth + 'px' }"
  >
    <div class="drawerWrapper">
      <div class="filterHeader">
        <p v-if="drawerData[0].title === 'Filters'" class="drawerFilterTitle">
          {{ drawerData[0].title }}
        </p>
        <button class="drawerFilterTitle" @click=clearAllInputs()>Clear all</button>
      </div>
      <div v-for="(section, sectionIndex) in drawerData" :key="sectionIndex">
        <template v-if="sectionIndex !== 0">
          <template v-if="section.title">
            <p class="drawerSectionTitle">
              {{ section.title }}
            </p>
          </template>
          <v-row dense>
            <template
              v-for="(detail, detailIndex) in section.details"
              :key="detailIndex"
            >
              <template v-if="detail.type === 'info'">
                <v-col cols="6" md="6" class="sideDrawerInfoCol">
                  <div>
                    <p class="drawerDetailTitle">{{ detail.name }}</p>
                    <p class="drawerDetailValue">{{ detail.value }}</p>
                  </div>
                </v-col>
              </template>
              <template v-else-if="detail.type === 'input'">
                <v-col cols="12" md="12" class="sideDrawerInputCol">
                  <label class="sideDrawerInputLabel">
                    {{ detail.name }}
                  </label>
                  <FillInBubble
                    :value="submissionData[detail.value]"
                    :name="detail.name"
                    class="filterSideDrawInputs"
                    :id="detail.value"
                    :placeholder="detail.placeholder"
                    @input="updateInputVal(detail.value, $event)"
                  />
                </v-col>
              </template>
              <template v-else-if="detail.type === 'checkbox'">
                <div class="sideDrawerCheckboxCol">
                  <div class="checkboxWrapper">
                    <label class="formInputLabel">
                      <Checkbox
                        :item="submissionData[detail.name]"
                        :checked="detail.checked"
                        @toggle-selected="updateCheckbox(detail.value)"
                      />
                      {{ detail.name }}
                    </label>
                  </div>
                </div>
              </template>
              <template v-else-if="detail.type === 'datePicker'">
                <v-col cols="12" md="12" class="sideDrawerInputCol">
                  <label class="sideDrawerInputLabel">
                    {{ detail.name }}
                  </label>
                  <DatePicker
                    :allowDateRange="false"
                    :input="detail"
                    @date-value="handleDateChange"
                    :value="submissionData?.[detail.value]"
                  />
                </v-col>
              </template>
              <template v-else-if="detail.type === 'select'">
                <v-col cols="12" md="12" class="sideDrawerInputCol">
                  <label class="sideDrawerInputLabel">
                    {{ detail.name }}
                  </label>
                  <DropDown
                    :options="detail.options"
                    :id="detail.value"
                    ref="filterDropdown"
                    :default="detail.default"
                    @input="updateDropDownVal(detail.value, $event)"
                    :value="
                      detail?.options?.find(
                        (option) =>
                          option.value === submissionData?.[detail.name]
                      ) ||
                      detail?.options?.find(
                        (option) =>
                          option.value === submissionData?.[detail.value]
                      ) ||
                      submissionData[detail.name] ||
                      formData?.[1].inputs?.[1]?.options?.find(
                        (option) =>
                          option.value === submissionData[detail.value]
                      ) ||
                      submissionData[detail.value] ||
                      formData?.[1].inputs?.[1]?.options?.find(
                        (option) =>
                          option.value === submissionData[detail.value]
                      ) 
                    "
                  />
                </v-col>
              </template>
            </template>
            <template v-if="sectionIndex != drawerData.length - 1">
              <DividerComp
                class="sideDrawerSectionDivider"
                style="width: 100%"
              />
            </template>
            <div
              class="sideDrawerInputResetWrapper"
              v-if="isFilterApplied"
            >
              <template
                v-for="(_, detailKey) in submissionData"
                :key="detailKey"
              >
                <v-col cols="12" md="12" class="sideDrawerInputResetCol">
                  <button @click="deleteFilter(detailKey)" class="removeInputValue">
                    <img
                      :src="require('../../../assets/icons/Remove.png')"
                      class="deleteInputIcon"
                    />
                    <p class="deleteInputValue">{{ filterLabel(detailKey) }} </p>
                  </button>
                </v-col>
              </template>
            </div>
          </v-row>
        </template>
      </div>
    </div>

    <div class="drawerCloseContainer">
      <div v-if="drawerData[0].button === 'Close'">
        <DividerComp class="sideDrawerDivider" />
        <div class="drawerCloseButton">
          <DarkButton
            :title="drawerData[0].button"
            width="parent"
            @click="handleClose"
          />
        </div>
      </div>
      <div
        v-else-if="
          drawerData[0].type !== 'form' && drawerData[0].type !== 'review'
        "
      >
        <DividerComp class="sideDrawerDivider" />
        <div class="drawerCloseButton">
          <DarkButton
            :title="drawerData[0].button"
            width="parent"
            @click="handleSubmit(drawerData[0].type)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DividerComp from "@/components/base_components/DividerComp.vue";
import DarkButton from "@/components/base_components/Buttons/DarkButton.vue";
import FillInBubble from "@/components/base_components/FormComponents/FillInBubble.vue";
import DropDown from "@/components/base_components/FormComponents/DropDown.vue";
import Checkbox from '@/components/base_components/FormComponents/CheckBox.vue';
import DatePicker from "@/components/base_components/FormComponents/DatePicker.vue";
import { cloneDeep } from '@/helpers/cloneDeepUtils';


export default {
  name: "SideDrawer",
  props: {
    drawerOpen: Boolean,
    drawerData: {
      type: Array,
      default: () => []
    },
    width: Number,
    previousSubmissionData: Object,
    numCols: { type: Number, default: 6 },
  },
  data() {
    return {
      submissionData: {},
      isFilterApplied: false,
      prevSubmissionData: {},
      formData: null
    };
  },
  computed: {
    dynamicWidth() {
      return 300;
    },
    localDrawerOpen: {
      get () {
        return this.drawerOpen
      },
      set (value) {
        this.$emit('update:drawerOpen', value)
      }
    }
  },
  watch: {
    drawerData() {
      this.submissionData = {};
    },
    localDrawerOpen () {
      if (this.isFilterApplied) {
        this.submissionData = cloneDeep(this.prevSubmissionData );
      }
    }
  },
  methods: {
    handleClose() {
      this.$emit("close-click");
    },
    handleSubmit(type) {
      console.log("Submit with: ", this.submissionData, type);
      this.$emit("submit-click", this.submissionData, type);
      this.localDrawerOpen = false;
      this.isFilterApplied = Object.keys(this.submissionData).length > 0;
      if (this.isFilterApplied) {
        this.prevSubmissionData = cloneDeep(this.submissionData);
      }
    },
    handleFormSubmission(formData) {
      if (formData === "Error") {
        this.$emit("form-submission", "Error");
      } else {
        this.$emit("form-submission", formData);
      }
    },
    updateInputVal(name, event) {
      if (event.target.value) {
        this.submissionData[name] = event.target.value;
      } else {
        delete this.submissionData[name];
      }
    },
    updateCheckbox(name) {
      this.submissionData[name] = !this.submissionData[name];
      this.$emit('updateCheckbox', this.submissionData)
    },
    deleteInputVal(detail) {
      var input = document.querySelector(`#${detail.value}`);
      if (input) {
        input.value = '';
        if (detail.type === 'select') {
          this.$refs.filterDropdown[0].clearSelection();
        }
      }
      delete this.submissionData[detail.value];
    },
    clearAllInputs() {
      let inputs = document.querySelectorAll('.filterSideDrawInputs');
      for (var i = 0; i < inputs.length; i++) {
        inputs[i].value = '';
      }
      if (this.$refs.filterDropdown) {
        this.$refs.filterDropdown[0].clearSelection();
      }
      
      this.submissionData = {};
    },
    updateDropDownMultiVal(name, input) {
      if (name == "Categories") {
        const values = input.map((obj) => obj.label);
        this.$emit("add-category", values);
      } else {
        const values = input.map((obj) => obj.value);
        this.submissionData[name] = values;
      }
    },
    updateDropDownVal(name, input) {
      this.submissionData[name] = input.value;
    },
    handleSubmissionDataUpdate(data) {
      console.log(data)
      this.$emit("submissionDataUpdate", data);
    },
    handleDateChange(input, value) {
      if (input.value) {
        this.submissionData[input.value] = value;
      } else {
        this.submissionData[input.name] = value;
      }
    },
    filterLabel (selectedItem) {
      const index = this.drawerData[1].details.findIndex(item => item.value === selectedItem);
      return this.drawerData[1].details[index].name;
    },
    deleteFilter (key) {
      const index = this.drawerData[1].details.findIndex(item => item.value === key);
      if (this.drawerData[1].details[index].type === 'select') {
        this.$refs.filterDropdown[0].clearSelection();
      }
      delete this.submissionData[key];
    }
  },
  components: {
    DividerComp,
    DarkButton,
    FillInBubble,
    DropDown,
    Checkbox,
    DatePicker
  },
  emits: [
    "update:drawerOpen",
    "close-click",
    "submit-click",
    "form-submission",
    "updateCheckbox",
    "add-category",
    "submissionDataUpdate"
  ]
};
</script>

<style scoped>
.sideDrawer {
  position: absolute;
  top: 93px;
  right: 0;
  z-index: 6;
  bottom: 0;
  background-color: #f4fafa;
  overflow-x: auto;
  transition: transform 0.2s ease;
  transform: translateX(100%);

  display: flex;
  flex-direction: column;
}

.sideDrawerInfoCol {
  display: flex;
  flex-direction: column;
}
.sideDrawerInputCol {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.sideDrawerCheckboxCol {
  display: flex;
  flex-direction: row-reverse;
  gap: 10px;
}

.sideDrawerInputResetCol {
  width: 100%;
  display: flex;
  padding-left: 5px;
  padding-top: 0px;
  font-weight: 500;
}
.sideDrawerInputResetWrapper {
  margin-top: 15px;
}

.sideDrawer.open {
  transform: translateX(0);
  filter: drop-shadow(5px 5px 5px rgb(39, 29, 122, 1));
}
.filterHeader {
  display: flex;
  justify-content: space-between;
}
.filterHeader button {
  font-size: 10.5pt;
}
.drawerFilterTitle {
  color: #271d7a;
  font-weight: 600;
  font-size: 11pt;
  padding-bottom: 25px;
  padding-top: 7px;
}
.drawerSectionTitle {
  font-size: 11pt;
  font-weight: bold;
  color: #3e87d3;
  padding-bottom: 15px;
}

.drawerDetailTitle {
  font-size: 9pt;
  font-weight: 600;
  color: #271d7a;
  padding-bottom: 5px;
}

.drawerDetailValue {
  font-size: 9pt;
  font-weight: normal;
  color: #271d7a;
}

.drawerCloseContainer {
  margin-top: auto;
}

.sideDrawerDivider {
  margin-bottom: 20px;
}
.sideDrawerSectionDivider {
  margin: 20px -10px 20px -10px;
}
.drawerWrapper {
  padding: 10px 20px 0px 20px;
}
.sideDrawerInputLabel {
  color: #271d7a;
  font-weight: 500;
  font-size: 10pt;
  padding: 5px 0px 3px 0px;
}
.deleteInputValue {
  color: #271d7a;
}
.deleteInputIcon {
  height: 13px;
  padding-right: 13px;
}
.formBackground {
  background-color: #ffffff;
}
.drawerCloseButton {
  padding: 0px 20px 20px 20px;
}
.removeInputValue {
  display: flex;
  align-items: center;
}
</style>
