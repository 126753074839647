<template>
  <div class="questionContainer">
    <ExpandableQuestion
      v-for="(question, index) in questions"
      :key="index"
      :question="question"
      :open="question.open"
      @toggle="toggleCard(index)"
      @addQuestion="handleAddQuestion"
    />
  </div>
</template>

<script>
import ExpandableQuestion from "./ExpandableQuestion.vue";

export default {
  name: "AvailableQuestionsList",
  props: {
    drawerOpen: Boolean,
    questions: Array,
  },
  data() {
    return {
      submissionData: {},
    };
  },
  watch: {
    drawerData() {
      this.submissionData = {};
    },
  },
  methods: {
    toggleCard(index) {
      this.$emit("toggle", index);
    },
    handleAddQuestion(question) {
      this.$emit("addQuestion", question);
    },
  },
  components: {
    ExpandableQuestion,
  },
  emits: [
    "toggle",
    "addQuestion"
  ]
};
</script>

<style scoped>
.questionContainer {
  outline: 1px solid #271d7a;
  margin: 10px;
  height: 100%;
  overflow: auto;
}
</style>
