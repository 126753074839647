<template>
  <div class="form_container">
    <FormComp 
      :formData="formData" 
      :buttonOptions="{ positionBottom: false, centered: false, fillToParent: false, divider: true }" 
      title="View Account Limits"
      @submission-data="retrieveAccountData"
    />
  </div>
  <div class="tableContainer" style="display: none;">
    <TableComp
      ref="OutputTable"
      :headers="headers"
      :dataList="tableItems"
      @click-row="handleClickRow"
    />    
  </div>
  <AlertsGroup
    v-model:alerts="alerts"
  />
</template>

<script>
  import FormComp from "@/components/base_components/FormComponents/FormComp.vue";
  import TableComp from "@/components/base_components/TableComp.vue";
  import AlertsGroup from "@/components/base_components/AlertsGroup.vue";
  import { addAlert } from '@/helpers/alertUtils';
  import { ERROR, SUCCESS } from '@/constants/statuses.constants';
  import { useAccountLimitsStore } from "@/stores/SwitchOps/AccountLimits/accountLimitsStore.js";
  import { mapActions, mapStores } from 'pinia';
  export default {
    name: "ViewAccountLimits",
    data() {
      return {
        alerts: [],
        tableItems: [],
        headers: [
          {text: "", value: "column1"},
          {text: "", value: "column2"}
        ],
        formData: [
          {
            title: "View Account Limits",
            button: "View Limits" 
          },
          {
            inputs: [
              { name: "Account ID", type: "bubble", required: true },
            ]
          }
        ]
      }
    },
    components: {
      FormComp,
      TableComp,
      AlertsGroup
    },
    computed: {
      ...mapStores(useAccountLimitsStore),
    },
    methods: {
      ...mapActions(useAccountLimitsStore, ['fetchLimits']),
    handleRemoveAlert(id) {
      const index = this.alerts.findIndex((alert) => alert.id === id);
      if (index !== -1) {
        this.alerts.splice(index, 1);
      }
    },
    handleFormSubmission(formData) {
      if (formData === "Error") {
        addAlert(ERROR, 'Missing Required Values', this.alerts);
      } else {
        addAlert(SUCCESS, 'Successfully Got Limits!', this.alerts);
        this.drawer = false;
        this.addDevice = false;
        this.addDevice = false;
      }
    },
      async retrieveAccountData(formData) {
        //Hide the table
        document.getElementsByClassName('tableContainer')[0].style.display="none";
        if (formData === "Error") {
          addAlert(ERROR, 'Missing Required Values', this.alerts);
        } else {
          this.tableItems = [];
          var result = await this.fetchLimits(formData["Account ID"]);

          if(result.error) {
            addAlert(ERROR, 'Unable to Retrieve Limits', this.alerts);
          } else {
            var returnedData = this.AccountLimitsStore.getlimitInformation;

            var returnedTableItems = [
              {"column1":"TwoWay Trunks:", "column2": returnedData.twoway_trunks},
              {"column1":"Inbound Trunks:", "column2": returnedData.inbound_trunks},
              {"column1":"Outbound Trunks:", "column2": returnedData.outbound_trunks},
              {"column1":"Resource-Consuming Calls:", "column2": returnedData.resource_consuming_calls},
              {"column1":"Allow Prepay:", "column2": returnedData.allow_prepay},
            ]
            this.tableItems = returnedTableItems;
            //Unhide the table component

            document.querySelector('.tableContainer').getElementsByClassName('customize-footer')[0].style.visibility="hidden";
            document.querySelector('.tableContainer').getElementsByClassName('customize-table')[0].style['margin-left']="0";
            document.getElementsByClassName('tableContainer')[0].style.display="block";
        }
        
        }  
      },
      handleClickRow (data) {
        console.log(data);
      }
    }
  }
</script>

<style scoped>
.form_container, .tableContainer {
  padding: 20px;
  background-color: #e2f1f4;
  max-width: 50%;
}

.form_container {
  padding-top: 20px;
  padding-bottom: 16px;
}

.tableContainer {
  padding-top: 0;
}

@media (max-width: 768px) {
  .form_container, .tableContainer {
    max-width: none;
    padding-left: 12px;
    padding-right: 12px;
  }
}
div.tableContainer div.customize-footer {
  display: none;
}
.formContainer {
  padding-bottom: 0;
}
</style>