<template>
  <div
    class="dropdown"
    :tabindex="0"
    :class="{ dropdownOpen: open }"
    @blur="handleBlur"
  >
    <div class="selected" :class="{ open: open }" @click="open = !open">
      <span v-if="selected.length" class="showItemsSpan">
        <template v-for="(item, index) in selected" :key="index">
          {{ item.label }}
          <span v-if="index !== selected.length - 1"> ,&nbsp;</span>
        </template></span
      >
      <span v-else>{{ placeholder }}</span>
      <img
        v-if="open"
        src="../../../assets/icons/caret_open_dkblue.png"
        class="dropdownCaret"
      />
      <img
        v-else
        src="../../../assets/icons/caret_closed_dkblue.png"
        class="dropdownCaret"
      />
    </div>
    <div class="items" :class="{ selectHide: !open }">
      <div v-for="(option, i) of options" :key="i"  class="option" @click="toggleOption(option)">
        <div class="optionLabel">
          <span class="optionText">{{ option.label }}</span>
          <span class="dropDownMultiCheckbox" @click.stop>
            <CheckBox
              :item="option"
              @toggle-selected="toggleOption"
              @mousedown.prevent
              :checked="isSelected(option)"
            />
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CheckBox from "@/components/base_components/FormComponents/CheckBox.vue";

export default {
  name: "MultiSelectDropDown",
  props: {
    options: Array,
    placeholder: String,
    value: [Object, Array],
  },
  data() {
    return {
      selected: [],
      open: false,
    };
  },
  methods: {
    toggleOption(option) {
      const index = this.deepIndexOf(option);
      if (index > -1) {
        this.selected.splice(index, 1);
      } else {
        this.selected.push(option);
      }
      this.$emit("selected-values", this.selected);
    },
    isSelected(option) {
      return this.selected.some((item) => {
        return item.label === option.label && item.value === option.value;
      });
    },
    deepIndexOf(option) {
      for (let i = 0; i < this.selected.length; i++) {
        const item = this.selected[i];
        if (item.label === option.label && item.value === option.value) {
          return i;
        }
      }
      return -1;
    },
    handleBlur(event) {
      if (!event.relatedTarget) {
        this.open = false;
      }
    },
  },
  components: {
    CheckBox,
  },
  watch: {
    value: {
      immediate: true,
      handler(newValue) {
        if (Array.isArray(newValue) && newValue.length > 0) {
          this.selected = newValue;
        } else {
          this.selected = [];
        }
      },
    },
  },
  emits: [
    "selected-values"
  ]
};
</script>

<style scoped>
.open {
  background-color: #eff4f9;
}
.dropdown {
  position: relative;
  text-align: left;
  font-size: 9pt;
  z-index: 4;
  outline: 0.25px solid #2e3e91;
  border-radius: 1px;
  padding: 4px 0px 4px 8px;
  color: #271d7a;
  margin-top: 6px;
  background-color: #ffffff;
}
.dropdownOpen {
  outline: 1.25px solid #271d7a;
  background-color: #eff4f9;
  z-index: 5;
}

.dropdown .items {
  color: #ffffff;
  overflow: hidden;
  position: absolute;
  background-color: #ffffff;
  left: 0;
  right: 0;
  z-index: 1;
}

.dropdown .items div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #271d7a;
  cursor: pointer;
  user-select: none;
}
.items {
  margin-top: 3px;
  outline: 0.25px solid #271d7a;
  filter: drop-shadow(1px 1px 1px rgba(39, 29, 122, 0.1));
}
.dropdown:hover {
  cursor: pointer;
}

.dropdown .items div:hover {
  background-color: #eff4f9;
}

.selectHide {
  display: none;
}
.dropdownCaret {
  position: absolute;
  right: 0;
  top: 0;
  width: 20px;
  padding-top: 10px;
  padding-right: 7px;
}

.optionText {
  margin-left: 8px;
}
.dropDownMultiCheckbox {
  margin-top: 6px;
  margin-right: 5px;
  width: 20px;
}

.optionLabel {
  display: flex;
  align-items: center;
  width: 100%;
}

.showItemsSpan {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 80% !important;
  display: block;
}
</style>
