<template>
  <div
    class="expandableCard"
    :tabindex="0"
    :class="{ expandableCardOpen: open }"
  >
    <div class="selected" :class="{ open: open }" @click="toggleCard">
      <img
        v-if="open"
        src="@/assets/icons/caret_open_dkblue.png"
        class="expandableCardCaret"
      />
      <img
        v-else
        src="@/assets/icons/caret_closed_dkblue.png"
        class="expandableCardCaret"
      />
      <span
        class="expandableCardTitle"
        :class="{ expandableCardTitleOpen: open }"
      ><span>Create New Question</span>
      </span>
    </div>
    <div class="wrapper" :class="{ openWrapper: open }">
      <div class="items" :class="{ selectHide: !open }">
        <CreateQuestionEditorView @question-created="updateQuestionList" :toggle="open"/>
      </div>
    </div>
  </div>
</template>
<script>
import CreateQuestionEditorView from './CreateQuestionEditorView.vue'

export default {
  name: "ExpandableAddQuestionCard",
  components: {
    CreateQuestionEditorView,
  },
  data() {
    return {
      open: false,
      addQuestion: false,
      selectedOption: {},
      selectQuestion: {
        title: "Select a Question",
      },
    };
  },
  methods: {
    toggleCard() {
      this.open = !this.open;
      this.$emit('toggle', this.open);
    },
    updateQuestionList() {
      this.$emit("update-questions");
    }
  },
  emits: [
    "toggle",
    "update-questions",
  ],
};
</script>
<style scoped>
.expandableCard.expandableCardOpen {
  background-color: #ffffff !important;
  border-radius: 1px;
  border: 0.25px solid #2e3e91;
  height: 100%;
  overflow: auto;
}
.wrapper {
  background: #fff;
  overflow: hidden;

  transition: all 0.5s ease-in-out;
  overflow: auto;
}

.openWrapper {
  height: 100%;;
}
.expandableCard {
  position: relative;
  text-align: left;
  z-index: 1;
  font-size: 9pt;
  /* filter: drop-shadow(1px 1px 1px rgba(39, 29, 122, 0.2)); */
  border: 0.25px solid #2e3e91;
  display: flex;
  flex-direction: column;
  margin: 10px;
}

.expandableCardTitle {
  font-size: 10pt;
  padding-left: 30px;
  padding-right: 8px;
}
.expandableCardTitleOpen {
  font-weight: 600;
}
.buttonList {
  display: flex;
  flex-direction: row;
  height: 200px;
}
.buttonList .toolButton {
  margin-right: 5px;
  margin-left: 10px;
  height: 30px;
}

.expandableCard .selected {
  color: #271d7a;
  cursor: pointer;
  user-select: none;
  padding: 2px 30px 2px 8px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  height: 37px;
  background-color: #e2f1f4;
}
.expandableCardDetail {
  padding-bottom: 10px;
  color: #271d7a;
  padding-left: 10px;
  user-select: none;
}
.expandableCard .selected:after {
  position: absolute;
  top: 22px;
  right: 10px;
}
.expandableQuestionAdd {
  width: 14px;
  margin-top: 6px;
  padding: 3px;
  /* outline: 1px solid #271d7a; */
  background-color: #fff;
  position: absolute;
  top: 5px;
  right: 18px;
  border-radius: 2px;
}

.expandableCard .items {
  overflow: hidden;
  background-color: #ffffff;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  color: #271d7a;
  font-size: 10pt;
  height: 100%;
}

.expandableCardOpen {
  background-color: #eff4f9;
  z-index: 5;
  height: auto;
}

.selectHide {
  display: none !important;
}
.expandableCardCaret {
  position: absolute;
  left: 0;
  top: 0;
  width: 26px;
  padding-top: 14px;
  padding-left: 12px;
}
.optionsList {
  display: flex;
  flex-direction: column;
  padding-left: 10px;
}
.addIcon {
  width: 14px;
  padding-left: 4px;
}
</style>
