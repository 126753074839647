<template>
    <h1 class="header">Call Totals</h1>
    <div class="row">
      <div class="infoContainer">
        <div>
          <span class="queueNumber">
            {{ queue.stats.inbound_calls }}
          </span>
          <div class="callsWaiting">
            <img
              :src="require(`@/assets/icons/Inbound.png`)"
              class="waitingImg"
            /><span class="infoLabel">Inbound</span>
          </div>
        </div>
        <div>
          <span class="queueNumber">
            {{ queue.stats.outbound_calls }}
          </span>
          <div class="callsWaiting">
            <img
              :src="require(`@/assets/icons/Outbound.png`)"
              class="waitingImg"
            /><span class="infoLabel">Outbound</span>
          </div>
        </div>
      </div>
      <div class="donutContainer">
        <DoughnutChart :queue="queue" :style="{ marginBottom: '30px' }" />
        <div class="column" :style="{ marginBottom: '30px' }">
          <div v-for="(item, index) in adjustedLegendLabels" :key="index">
            <div class="legendContainer">
              <img :src="require(`@/assets/icons/${item.icon}`)" class="icon" />
              <span class="label"> {{ item.name }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import DoughnutChart from "./DoughnutChartRealTime.vue";
  export default {
    name: "CallTotalsCard",
    props: {
      queue: Object,
    },
    data() {
      return {
        legendData: [
          {
            name: "Answered Calls",
            icon: "Inbound.png",
          },
          {
            name: "Missed Calls",
            icon: "Missed.png",
          },
          {
            name: "Abandoned Calls",
            icon: "Abandonded.png",
          },
          {
            name: "Calls Handed to Overflow",
            icon: "External.png",
          },
        ],
      };
    },
    computed: {
      adjustedLegendLabels() {
        var legend = [...this.legendData];
        var sum = parseFloat(this.queue.stats.answered_calls) + parseFloat(this.queue.stats.missed_calls) + parseFloat(this.queue.stats.abandoned_calls) + parseFloat(this.queue.stats.overflow_calls);
        var answeredCalls = Math.round((parseFloat(this.queue.stats.answered_calls) / sum) * 100) || 0;
        var missedCalls = Math.round((parseFloat(this.queue.stats.missed_calls) / sum) * 100) || 0;
        var abandonedCalls = Math.round((parseFloat(this.queue.stats.abandoned_calls) / sum) * 100) || 0;
        var handedToOverflow = Math.round((parseFloat(this.queue.stats.overflow_calls) / sum) * 100) || 0;
  
        for(var item of legend) {
          switch (item.name) {
            case "Answered Calls":
              item.name = `${item.name}: ${answeredCalls}%`;
              break;
            case "Missed Calls":
              item.name = `${item.name}: ${missedCalls}%`;
              break;
            case "Abandoned Calls":
              item.name = `${item.name}: ${abandonedCalls}%`;
              break;
            case "Calls Handed to Overflow":
              item.name = `${item.name}: ${handedToOverflow}%`;
              break;
            default:
              break;
          }
        }
  
        return legend;
      }
    },
    components: { DoughnutChart },
  };
  </script>
  <style scoped>
  .header {
    padding: 10px 15px 15px 15px;
    font-size: 11pt;
    color: #271d7a;
  }
  .row {
    display: flex;
    flex-direction: row;
    text-align: center;
    flex-wrap: wrap;
    justify-content: center;
    align-items: stretch;
  }
  .infoContainer {
    flex: 1 30%;
    display: flex;
    width: 100%;
    flex-direction: column;
    box-shadow: inset -5px 1px 2px -5px rgba(39, 29, 122, 0.2);
    margin-bottom: 20px;
  }
  .donutContainer {
    flex: 1 70%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    height: 100%;
    width: 100%;
  }
  .column {
    padding-left: 20px;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding-top: 10px;
  }
  
  .queueNumber {
    font-size: 30pt;
    color: #271d7a;
    font-weight: 500;
    text-align: center;
  }
  .waitingImg {
    width: 10px;
    height: 10px;
    margin-top: 6px;
  }
  .callsWaiting {
    display: flex;
    text-align: center;
    justify-content: center;
  }
  .label {
    text-align: left;
    padding-left: 10px;
    font-size: 11pt;
    color: #271d7a;
    font-weight: 500;
    display: flex;
    align-items: center;
    text-align: left;
    width: 140px;
  }
  .infoLabel {
    text-align: center;
    padding-left: 10px;
    font-size: 11pt;
    color: #271d7a;
    font-weight: 500;
    display: flex;
    align-items: center;
    display: inline-flex;
  }
  .icon {
    width: 10px;
    height: 10px;
  }
  .legendContainer {
    display: inline-flex;
    flex: 1;
    align-items: center;
    margin-bottom: 10px;
    flex-wrap: wrap;
  }
  </style>
  