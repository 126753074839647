<template>
    <span>{{
    stopwatch.hours < 10 ? `0${stopwatch.hours}` : stopwatch.hours
    }}</span
    >:<span>{{
    stopwatch.minutes < 10 ? `0${stopwatch.minutes}` : stopwatch.minutes
    }}</span
    >:<span>{{
    stopwatch.seconds < 10 ? `0${stopwatch.seconds}` : stopwatch.seconds
    }}</span>
</template>

<script>
import { useStopwatch } from "vue-timer-hook";
export default {
    name: "StopWatchComp",
    props: {
        seconds: Number,
        status: String
    },
    data() {
        return {
            stopwatch: useStopwatch(this.seconds),
        }
    },
    watch: {
        seconds() {
            this.stopwatch = useStopwatch(this.seconds);
        },
        status() {
            this.stopwatch = useStopwatch(this.seconds);
        }
    }
    
}
</script>


<style scoped>


</style>