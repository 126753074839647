<template>
  <div>
    <div class="sideDrawer">
      <ManagementSideDrawer
        ref="drawer"
        :drawer-open="drawer"
        buttonType="Close"
        :numCols="12"
        @close-click="handleCloseClick"
        :drawer-data="getDrawerData"
        @form-submission="handleSubmit"
        @submissionDataUpdate="handleDrawerUpdate"
        :previousSubmissionData="preSubmissionData"
        :readonly="isLocalReadonly"
      />
    </div>
    <div class="tableContainer">
      <TableComp
        :headers="getTableHeaders"
        :data-list="getTableData"
        background-color="#e2f1f4"
        :single-select="true"
        :select-all="false"
        uniqueKey="id"
        :left-nav-present="true"
        @selected-item="handleSelectedItem"
        @edit-row="handleEditRow"
        @click-row="handleEditRow"
      />
    </div>
    <AlertsGroup
      v-model:alerts="alerts"
    />
  </div>
</template>

<script>
import TableComp from "@/components/base_components/TableComp.vue";
import ManagementSideDrawer from "@/components/Voice/Management/SideDrawer/ManagementSideDrawer.vue";
import AlertsGroup from '@/components/base_components/AlertsGroup.vue';
import { MISSING_FIELDS } from '@/constants/alerts.constants';
import { CONFIRM_REMOVE, ERROR, SUCCESS, UPDATE } from '@/constants/statuses.constants';
import {
  ADD_INFRACTION,
  EDIT_INFRACTION,
  REMOVE_INFRACTION,
  INFRACTION_CREATE_ALERT,
  INFRACTION_REMOVE_ALERT,
  INFRACTION_UPDATE_ALERT
} from '@/constants/pages/infractions.constants';
import { FORM_DRAWER_TYPE } from '@/constants/drawer.constants';
// import { formatTableDate } from '@/helpers/dateUtils';
import { addAlert } from '@/helpers/alertUtils';

import { useCCManagementAttendanceStore } from "@/stores/ContactCenter/Management/CCManagementAttendanceStore.js";
import { mapStores } from 'pinia';

export default {
  name: 'InfractionsView',
  components: {
    TableComp,
    ManagementSideDrawer,
    AlertsGroup
  },
  props: {
    drawer: {
      type: Boolean,
      default: false
    },
    create: {
      type: Boolean,
      default: false
    },
    update: {
      type: Boolean,
      default: false
    },
    isRemove: {
      type: Boolean,
      default: false
    },
    remove: {
      type: Boolean,
      default: false
    },
    search: {
      type: String,
      default: ''
    },
    isAdmin: {
      type: Boolean,
      default: false
    },
    isReadonly: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      tableHeaders: [
        { text: "Date", value: "date" },
        { text: "Employee", value: "employee" },
        { text: "Infraction", value: "infraction" },
        { text: "Documentation", value: "documentation" },
      ],
      tableData:  [
        // {
        //   id: 1,
        //   date: "2022-09-06",
        //   employee: 'John Doe',
        //   infraction: 'Some infraction',
        //   documentation: 'Some documentation'
        // },
        // {
        //   id: 2,
        //   date: "2023-09-07",
        //   employee: 'John Doe 1',
        //   infraction: 'Some infraction 1',
        //   documentation: 'Some documentation 1'
        // }
      ],

      createFormData: [
        { title: ADD_INFRACTION, button: ADD_INFRACTION, type: FORM_DRAWER_TYPE },
        { title: '', inputs: [{
        name: "Name of Employee",
        value: 'employee',
        type: "select",
        options: this.transformedResultOptions,
        required: true,
      },
      {
        name: "Date of Attendance Issue",
        type: "datePicker",
        value: 'date',
        required: true,
      },
       {
        name: "Is this attendance issue an infraction?",
        type: "select",
        value: 'infraction',
        options: [
          { label: "Yes", value: "yes" },
          { label: "No", value: "No" },
        ],
        required: true,
      },
      {
        name: "Scheduled Shift",
        type: "bubble",
        value: 'scheduled_shift'
      },
      {
        name: "Clock in Time EST",
        type: "bubble",
        value: 'clock_in_time_est'
      },
      {
        name: "Clock out Time EST",
        type: "bubble",
        value: 'clock_out_time_est'
      },
      {
        name: "Details of issue",
        type: "textarea",
        value: 'documentation',
        required: true,
        readonly: false
      }] }
      ],
      updateFormData: [
        { title: EDIT_INFRACTION, button: UPDATE, type: FORM_DRAWER_TYPE },
        { title: '', inputs: [{
        name: "Name of Employee",
        value: 'employee',
        type: "select",
        options: this.transformedResultOptions,
        required: true,
      },
      {
        name: "Date of Attendance Issue",
        type: "datePicker",
        value: 'date',
        required: true,
      },
       {
        name: "Is this attendance issue an infraction?",
        type: "select",
        value: 'infraction',
        options: [
          { label: "Yes", value: "yes" },
          { label: "No", value: "No" },
        ],
        required: true,
      },
      {
        name: "Scheduled Shift",
        type: "bubble",
        value: 'scheduled_shift'
      },
      {
        name: "Clock in Time EST",
        type: "bubble",
        value: 'clock_in_time_est'
      },
      {
        name: "Clock out Time EST",
        type: "bubble",
        value: 'clock_out_time_est'
      },
      {
        name: "Details of issue",
        type: "textarea",
        value: 'documentation',
        required: true,
        readonly: false
      }] }
      ],
      removeFormData: [
        { title: REMOVE_INFRACTION, button: CONFIRM_REMOVE, type: FORM_DRAWER_TYPE },
        { title: '', inputs: [{
        name: "Name of Employee",
        value: 'employee',
        type: "select",
        options: this.transformedResultOptions,
        required: true,
      },
      {
        name: "Date of Attendance Issue",
        type: "datePicker",
        value: 'date',
        required: true,
      },
      {
        name: "Details of issue",
        type: "textarea",
        value: 'documentation',
        required: true,
        readonly: false
      }] }
      ],

      alerts: [],
      submissionData: {},
      isSelectedRow: false,
      scheduledShift: '',
      transformedResultOptions: [],
      employee_information: []
    }
  },
  async created() {
    let resultOptions = await this.CCManagementAttendanceStore.getEmployeeInformationOptions();
    let employee_information = await this.CCManagementAttendanceStore.getSpecificEmployeeInformation();
    this.employee_information = employee_information;

    this.transformedResultOptions = resultOptions.map(name => ({
      label: name,
      value: name,
    }));

    this.removeFormData[1].inputs[0].options = this.transformedResultOptions;
    this.createFormData[1].inputs[0].options = this.transformedResultOptions;
    this.updateFormData[1].inputs[0].options = this.transformedResultOptions;

    console.log("this.transformedResultOptions", this.transformedResultOptions);

    let result = await this.CCManagementAttendanceStore.getInfractionInformation();
    console.log("mounted result: ", result);

    if (result) {
      this.tableData = this.CCManagementAttendanceStore.getInfractions.map(infraction => {
        return {
          id: infraction.id,
          date: infraction.date,
          employee: infraction.employee_name,
          infraction: infraction.infraction ? "Y" : "N",
          documentation: infraction.details,
          scheduled_shift: infraction.scheduled_shift,
          clock_in_time_est: infraction.clock_in_time,
          clock_out_time_est: infraction.clock_out_time,
          employee_id: infraction.employee_id,
          _version: infraction._version
        };
      });

      console.log("this.tableData", this.tableData);
    } else {
      console.error("Failed to fetch work partners: ", result);
    }

  },
  watch: {
    'CCManagementAttendanceStore.getInfractions': {
      deep: true,
      handler(newInfractions) {
        this.tableData = newInfractions.map(infraction => {
          return {
            id: infraction.id,
            date: infraction.date,
            employee: infraction.employee_name,
            infraction: infraction.infraction ? "Y" : "N",
            documentation: infraction.details,
            scheduled_shift: infraction.scheduled_shift,
            clock_in_time_est: infraction.clock_in_time,
            clock_out_time_est: infraction.clock_out_time,
            employee_id: infraction.employee_id,
            _version: infraction._version
          };
        });
      },
    },
    scheduledShift (value) {
      console.log("this.$refs.drawer", this.$refs.drawer);  
      console.log("this.$refs.drawer.$el", this.$refs.drawer.$el);
      var el = this.$refs.drawer.$el.querySelector('input[placeholder="Scheduled Shift"]');
      if(el) {
        el.value = value
        el.dispatchEvent(new Event('change'));
      }
    },
  },
  computed: {
    ...mapStores(useCCManagementAttendanceStore),
    localDrawer: {
      get () {
        return this.drawer;
      },
      set (drawer) {
        this.$emit('update:drawer', drawer);
      }
    },
    isCreateForm: {
      get () {
        return this.create;
      },
      set (create) {
        this.$emit('update:create', create);
      }
    },
    isUpdateForm: {
      get () {
        return this.update;
      },
      set (update) {
        this.$emit('update:update', update);
      }
    },
    isRemoveForm: {
      get () {
        return this.remove;
      },
      set (remove) {
        this.$emit('update:remove', remove);
      }
    },
    localIsRemove: {
      get () {
        return this.isRemove;
      },
      set (isRemove) {
        this.$emit('update:isRemove', isRemove);
      }
    },
    isLocalReadonly: {
      get () {
        return this.isReadonly
      },
      set (isReadonly) {
        this.$emit('update:isReadonly', isReadonly)
      }
    },
    searchResult: {
      get () {
        return this.search;
      },
      set (search) {
        this.$emit('update:search', search);
      }
    },
    
    getDrawerData () {
      if (this.isCreateForm) {
        return this.createFormData;
      }
      
      if (this.isRemoveForm) {
        return this.removeFormData;
      }

      return this.updateFormData;
    },

    getTableData () {
      return this.filteredData.map(item => {
        return { header: true, ...item }
      })
    },
    getTableHeaders () {
      const adminHeaders = [
        { text: "", value: "checkbox", width: 23.6, fixed: true },  
        ...this.tableHeaders,
        { text: "", value: "edithover", width: 23.95 }
      ];

      return this.isAdmin ? adminHeaders : this.tableHeaders
    },

    filteredData () {
      if (this.searchResult !== '') {
        return this.tableData.filter(item => {
          return Object.values(item).some(fieldValue =>
            String(fieldValue).toLowerCase().includes(this.searchResult.toLowerCase())
          );
        });
      }
      
      return this.tableData
    },
    preSubmissionData () {
      if (this.isCreateForm) {
        return {}
      }
      return this.submissionData;
    }
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.handleResize);
  },
  unmounted () {
    this.isLocalReadonly = false;
    this.localDrawer = false;
    this.isCreateForm = false;
    this.isUpdateForm = false;
    this.localIsRemove = false;
  },
  methods: {
    formatDate(date) {
      const originalDate = date + "T00:00:00.000Z"; 

      const parsedDate = new Date(originalDate);

      const month = (parsedDate.getUTCMonth() + 1).toString().padStart(2, '0');
      const day = parsedDate.getUTCDate().toString().padStart(2, '0');
      const year = parsedDate.getUTCFullYear();
      return `${month}/${day}/${year}`;
    },
    handleSelectedItem(selectedItem) {
      if (selectedItem !== null) {
        this.localIsRemove = true
        const { date, documentation, employee, infraction, id } = selectedItem;
        this.isRemoveForm = true;
        this.isSelectedRow = true;
        let formattedDate = this.formatDate(date);
        
        this.submissionData = {
          id,
          date: formattedDate,
          employee,
          infraction,
          scheduled_shift: employee,
          documentation,
        };
      } else {
        this.localIsRemove = false;
        this.isRemoveForm = false;
        this.isSelectedRow = false;
      }

    },
    handleEditRow (submissionData) {
      const { id, date, documentation, employee, infraction, scheduled_shift, clock_in_time_est, clock_out_time_est } = submissionData;
      this.localDrawer = true;
      this.isRemoveForm = false;
      this.isUpdateForm = true;
      let formattedDate = this.formatDate(date);

      this.submissionData = {
        id,
        date: formattedDate,
        employee,
        infraction,
        scheduled_shift,
        documentation,
        clock_in_time_est,
        clock_out_time_est,
      };
    },
    handleCloseClick () {
      this.localDrawer = false;
      this.isCreateForm = false;
      this.isUpdateForm = false;
      this.isLocalReadonly = false;
    },
    async handleCreateForm(submissionData) {
      if (submissionData !== ERROR) {
        const { date, employee, infraction, documentation, scheduled_shift, clock_in_time_est, clock_out_time_est } = submissionData

        let result = await this.CCManagementAttendanceStore.addInfraction( date, employee.label, infraction.label, documentation, scheduled_shift, clock_in_time_est, clock_out_time_est );

        if (result) {
          addAlert(SUCCESS, INFRACTION_CREATE_ALERT, this.alerts);
          await this.CCManagementAttendanceStore.getInfractionInformation();
          this.localDrawer = false;
          if (!this.isSelectedRow) {
            this.submissionData = {};
          }
          this.isCreateForm = false;
        } else {
          addAlert(ERROR, result.message, this.alerts);
        }
      } else {
        addAlert(ERROR, MISSING_FIELDS, this.alerts);
      }
    },
    async handleRemoveForm (submissionData) {
      if (submissionData !== ERROR) {
        const { id } = submissionData;
        let result = await this.CCManagementAttendanceStore.removeInfraction(id);
        if (result && !result.error) {
          addAlert(SUCCESS, INFRACTION_REMOVE_ALERT, this.alerts);
          await this.CCManagementAttendanceStore.getInfractionInformation();
          this.localDrawer = false;
          this.isRemoveForm = false;
          this.isLocalReadonly = false;
          this.localIsRemove = false;
        } else {
          addAlert(ERROR, "Failed to remove infraction", this.alerts);
        }
      } else {
        addAlert(ERROR, MISSING_FIELDS, this.alerts);
      } 
    },
    async handleUpdateForm(submissionData) {
      if (submissionData !== ERROR) {
        const { id, date, employee, infraction, documentation, scheduled_shift, clock_in_time_est, clock_out_time_est } = submissionData;
        let result = await this.CCManagementAttendanceStore.editInfraction(id, date, employee, infraction, documentation, scheduled_shift, clock_in_time_est, clock_out_time_est );

        if (result && !result.error) { 
          addAlert(SUCCESS, INFRACTION_UPDATE_ALERT, this.alerts);
          await this.CCManagementAttendanceStore.getInfractionInformation();
          this.localDrawer = false;
          this.isRemoveForm = false;
          this.isLocalReadonly = false;
        } else {
          addAlert(ERROR, result.message, this.alerts);
        }
      } else {
        addAlert(ERROR, MISSING_FIELDS, this.alerts);
      }
    },
    handleDrawerUpdate (submissionData) {
      var employee_name = "";
      if (submissionData.employee && typeof submissionData.employee === 'object') {
        employee_name = submissionData.employee?.label;
      } else if (submissionData.employee && typeof submissionData.employee === 'string') {
        employee_name = submissionData.employee;
      }

      var foundObj = this.employee_information.find(obj => obj.name == employee_name);
      if(foundObj) {
        this.scheduledShift = foundObj.scheduled_shift;
      }
      else {
        this.scheduledShift = "";
      }
    },
    handleSubmit (submissionData) {
      console.log('Submitting: ', submissionData);
      if (this.isCreateForm) {
        this.handleCreateForm(submissionData);
      } else if (this.isRemoveForm) {
        this.handleRemoveForm(submissionData);
      } else if (this.isUpdateForm) {
        this.handleUpdateForm(submissionData);
      }
    },
    // handleSubmit (submissionData) {
    //   if (this.isCreateForm) {
    //     this.handleCreateForm (submissionData)
    //   }

    //   if (this.isRemoveForm) {
    //     this.handleRemoveForm(submissionData);
    //   }

    //   if (this.isUpdateForm) {
    //     this.handleUpdateForm(submissionData);
    //   }
    // },
    formatSelect (item) {
      if (typeof item === 'string') {
        return item
      }

      return item?.label
    }
  },
  emits: [
    "update:drawer",
    "update:create",
    "update:update",
    "update:remove",
    "update:isReadonly",
    "update:isRemove",
    "update:search"
  ]
}
</script>

<style scoped>
.pageContent {
  flex-grow: 1;
}
.mainContainer {
  display: flex;
  background-color: #e2f1f4;
  height: 100%;
}
.mainContent {
  display: flex;
  flex-grow: 1;
  position: relative;
}
.tableContainer {
  height: 100%;
  width: 90%;
  margin-left: 20px;
  margin-top: 20px;
  /* padding: 20px; */
  background-color: #e2f1f4;
}
.sideDrawer {
  flex: 0 0 auto;
}
.readonly :deep(.dateInputSingle),
.readonly :deep(.dropdownContainer),
.readonly :deep(.textareaNormal) {
  user-select: none;
  pointer-events: none;
}
</style>