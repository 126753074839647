// TODO: FIX THIS FILE IT HAS ESLINT ISSUES
import { defineStore } from 'pinia'
import { API } from "aws-amplify";
import * as queries from "@/graphql/queries.js";
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api";
import { Auth } from "aws-amplify";
import { useAuthStore } from '@/stores/auth/authStore';
import AWS from "aws-sdk";
import awsconfig from '@/aws-exports';

export const useGeneralStore = defineStore('General', {
    state: () => ({
        stateOptions: [
            { label: 'Alabama', value: 'AL' },
            { label: 'Alaska', value: 'AK' },
            { label: 'Arizona', value: 'AZ' },
            { label: 'Arkansas', value: 'AR' },
            { label: 'California', value: 'CA' },
            { label: 'Colorado', value: 'CO' },
            { label: 'Connecticut', value: 'CT' },
            { label: 'Delaware', value: 'DE' },
            { label: 'Florida', value: 'FL' },
            { label: 'Georgia', value: 'GA' },
            { label: 'Hawaii', value: 'HI' },
            { label: 'Idaho', value: 'ID' },
            { label: 'Illinois', value: 'IL' },
            { label: 'Indiana', value: 'IN' },
            { label: 'Iowa', value: 'IA' },
            { label: 'Kansas', value: 'KS' },
            { label: 'Kentucky', value: 'KY' },
            { label: 'Louisiana', value: 'LA' },
            { label: 'Maine', value: 'ME' },
            { label: 'Maryland', value: 'MD' },
            { label: 'Massachusetts', value: 'MA' },
            { label: 'Michigan', value: 'MI' },
            { label: 'Minnesota', value: 'MN' },
            { label: 'Mississippi', value: 'MS' },
            { label: 'Missouri', value: 'MO' },
            { label: 'Montana', value: 'MT' },
            { label: 'Nebraska', value: 'NE' },
            { label: 'Nevada', value: 'NV' },
            { label: 'New Hampshire', value: 'NH' },
            { label: 'New Jersey', value: 'NJ' },
            { label: 'New Mexico', value: 'NM' },
            { label: 'New York', value: 'NY' },
            { label: 'North Carolina', value: 'NC' },
            { label: 'North Dakota', value: 'ND' },
            { label: 'Ohio', value: 'OH' },
            { label: 'Oklahoma', value: 'OK' },
            { label: 'Oregon', value: 'OR' },
            { label: 'Pennsylvania', value: 'PA' },
            { label: 'Rhode Island', value: 'RI' },
            { label: 'South Carolina', value: 'SC' },
            { label: 'South Dakota', value: 'SD' },
            { label: 'Tennessee', value: 'TN' },
            { label: 'Texas', value: 'TX' },
            { label: 'Utah', value: 'UT' },
            { label: 'Vermont', value: 'VT' },
            { label: 'Virginia', value: 'VA' },
            { label: 'Washington', value: 'WA' },
            { label: 'West Virginia', value: 'WV' },
            { label: 'Wisconsin', value: 'WI' },
            { label: 'Wyoming', value: 'WY' }
        ],
        partnerInformation: {},
        partnerOptions: [],
        userAssociatedPartners: [],
        userAssociatedPartner: {},
        RT_STILL_ACTIVE: true
    }), 
    getters: {
        getStateOptions(state) {
            return state.stateOptions
        },
        getPartnerInformation(state) {
            return state.partnerInformation
        },
        getPartnerOptions(state) {
            return state.partnerOptions
        },
        getUserAssociatedPartners(state) {
            return state.userAssociatedPartners
        },
        getRTState(state) {
            return state.RT_STILL_ACTIVE;
        }
    },
    actions: {

        async fetchGeneralLogs(type, partner, error, msg_date) {
            var error_message = "Unable to Get Logs";
            try {

                var hasData = true;
                var nextToken = null;
                var logsData = [];

                let generalFilter = {
                    _deleted: { ne: true },
                    msg_type: {eq: type }
                };
                if (error !== "Show All Records") {
                    if (error === "Yes") {
                        generalFilter.is_error = { eq: true };
                    } else {
                        generalFilter.is_error = { eq: false };
                    }
                } 
                if (partner !== "" && partner !== "All Partners") {
                    generalFilter.partner = { eq: partner };
                }
               
                if (typeof msg_date == 'object') {
                    generalFilter.and = [{ msg_date: {gt: msg_date.startDate, lt: msg_date.endDate}}];
                }
                while (hasData) {
                    var options = {
                        query: queries.searchGeneralLogs,
                        variables: {
                            filter: generalFilter,
                            limit: 2000
                        },
                        authMode: GRAPHQL_AUTH_MODE.API_KEY
                    }

                    if (nextToken != null) {
                        options.variables.nextToken = nextToken;
                    }

                    var logs = await API.graphql(options);

                    for (const item of logs.data.searchGeneralLogs.items) {
                        logsData.push(item);
                    }

                    if (logs.data.searchGeneralLogs.nextToken != null) {
                        nextToken = logs.data.searchGeneralLogs.nextToken;
                    }
                    else {
                        hasData = false;
                    }
                }
                logsData.forEach(item => {
                    item.msg_date = new Date(item.msg_date);
                });
                logsData.sort((a, b) => b.msg_date - a.msg_date);

                return {error: false, message: 'Ok', logs: logsData};
            }
            catch (error) {

                return {error: true, message: error_message};
            }
        },
        async initializePage() {
            var authStore = useAuthStore();
            return authStore.validateAdminStatus();
        },
        async initializeAdminPage() {
            var authStore = useAuthStore();
            var foundObj = authStore.users_applications.find(obj => obj.application_section == "Admin");
            var adminAllowedPermissions = foundObj?.allowed_permissions;
            if(adminAllowedPermissions) {
                return adminAllowedPermissions.split(',').map(item => item.trim());
            } else {
                return [];
            }
        },
        async initializeContactCenterPage() {
            var result = {};
            var authStore = useAuthStore();
            var isAdmin = await authStore.validateAdminStatus();
            result['isAdmin'] = isAdmin;
            var foundObj = authStore.users_applications.find(obj => obj.application_section == "Contact Center" && obj.name == "Management");
            var adminAllowedPermissions = foundObj?.allowed_permissions;
            if(adminAllowedPermissions) {
                result['settings'] = adminAllowedPermissions.split(',').map(item => item.trim());
            } else {
                result['settings'] = [];
            }

            return result;
        },
        async initializeCustomerManagementPage() {
            var result = {};
            var authStore = useAuthStore();
            var isAdmin = await authStore.validateAdminStatus();
            result['isAdmin'] = isAdmin;
            var foundObj = authStore.users_applications.find(obj => obj.application_section == "Billing" && obj.name == "Customer Management");
            var adminAllowedPermissions = foundObj?.allowed_permissions;
            if(adminAllowedPermissions) {
                result['settings'] = adminAllowedPermissions.split(',').map(item => item.trim());
            } else {
                result['settings'] = [];
            }

            return result;
        },
        async initializeE911Page() {
            var result = {};
            var authStore = useAuthStore();
            var foundObj = authStore.users_applications.find(obj => obj.application_section == "Voice" && obj.name == "E911");
            var adminAllowedPermissions = foundObj?.allowed_permissions;
            if(adminAllowedPermissions) {
                result['settings'] = adminAllowedPermissions.split(',').map(item => item.trim());
            } else {
                result['settings'] = [];
            }

            return result;
        },
        async initializeProvisioiningPage() {
            var result = {};
            var authStore = useAuthStore();
            var foundObj = authStore.users_applications.find(obj => obj.application_section == "Voice" && obj.name == "Provisioning");
            var adminAllowedPermissions = foundObj?.allowed_permissions;
            if(adminAllowedPermissions) {
                console.log('adminAllowedPermissions', adminAllowedPermissions)
                if (adminAllowedPermissions.startsWith(',')) {
                    adminAllowedPermissions = adminAllowedPermissions.substring(1);
                }
                result['settings'] = adminAllowedPermissions.split(',').map(item => item.trim());
            } else {
                result['settings'] = [];
            }

            return result;
        },
        async initializeMultiLineProvisioiningPage() {
            var result = {};
            var authStore = useAuthStore();
            var foundObj = authStore.users_applications.find(obj => obj.application_section == "Voice" && obj.name == "Requests");
            var adminAllowedPermissions = foundObj?.allowed_permissions;
            if(adminAllowedPermissions) {
                if (adminAllowedPermissions.startsWith(',')) {
                    adminAllowedPermissions = adminAllowedPermissions.substring(1);
                }
                result['settings'] = adminAllowedPermissions.split(',').map(item => item.trim());
            } else {
                result['settings'] = [];
            }

            return result;
        },
        async initializeVoiceManagementPage() {
            var result = {};
            var authStore = useAuthStore();
            var isAdmin = await authStore.validateAdminStatus();
            result['isAdmin'] = isAdmin;
            var foundObj = authStore.users_applications.find(obj => obj.application_section == "Voice" && obj.name == "Management");
            var adminAllowedPermissions = foundObj?.allowed_permissions;
            if(adminAllowedPermissions) {
                result['settings'] = adminAllowedPermissions.split(',').map(item => item.trim());
            } else {
                result['settings'] = [];
            }

            return result;
        },
        async fetchAllPartnerInformation() {
            var error_message = "Unable to Get Partners";
            this.partnerOptions = [];
            try {

                var hasData = true;
                var nextToken = null;
                var partnersData = [];


                while (hasData) {
                    var options = {
                        query: queries.listPartners,
                        variables: {
                            filter: {},
                            limit: 2000
                        },
                        authMode: GRAPHQL_AUTH_MODE.API_KEY
                    }

                    if (nextToken != null) {
                        options.variables.nextToken = nextToken;
                    }

                    var partners = await API.graphql(options);

                    for (const item of partners.data.listPartners.items) {
                        partnersData.push(item);
                    }

                    if (partners.data.listPartners.nextToken != null) {
                        nextToken = partners.data.listPartners.nextToken;
                    }
                    else {
                        hasData = false;
                    }
                }

                for(let partnerObj of partnersData) {
                    this.partnerInformation[partnerObj.partner] = partnerObj;
                    this.partnerOptions.push({label: partnerObj.name, value: partnerObj.partner})
                }
                // let sortedOptions = this.partnerOptions.sort((a, b) => {
                //     const labelA = a.label.toLowerCase().trim();
                //     const labelB = b.label.toLowerCase().trim();
                    
                //     if (labelA < labelB) return -1;
                //     if (labelA > labelB) return 1;
                //     return 0;
                // });
                // this.partnerOptions = sortedOptions;
                // this.partnerOptions.unshift({label: "All Partners", value: "All Partners"})
                return {error: false, message: 'Ok'};
            }
            catch (error) {
                return {error: true, message: error_message};
            }
        },
        async fetchSomePartners(filterInputs = {}) {
            this.partnerInformation = {};
            this.partnerOptions = [];
            var filterOptions = {};
            var error_message = 'Unable to Get Partners';
            try {

                if(Object.prototype.hasOwnProperty.call(filterInputs, 'notAllowedArray')) {
                    var filterArray = [];

                    for(let item of filterInputs.notAllowedArray) {
                        filterArray.push({partner: {ne: item}});
                    }

                    filterOptions.and = filterArray;
                }

                if(Object.prototype.hasOwnProperty.call(filterInputs, 'has_glds_billing')) {
                    var hasGLDS = filterInputs.has_glds_billing;

                    filterOptions.has_glds_billing = {has_glds_billing: {eq: hasGLDS}}
                }

                if(Object.prototype.hasOwnProperty.call(filterInputs, 'has_provisioning')) {
                    var hasProvisioning = filterInputs.has_provisioning;

                    filterOptions.has_provisioning = {has_provisioning: {eq: hasProvisioning}}
                }

                filterOptions["_deleted"] = {ne: true};

                var hasData = true;
                var nextToken = null;
                var partnersData = [];

                var listQuery = `query ListPartners(
                    $filter: ModelPartnersFilterInput
                    $limit: Int
                    $nextToken: String
                  ) {
                    listPartners(filter: $filter, limit: $limit, nextToken: $nextToken) {
                      items {
                        partner
                        name
                        revio_id
                        company_name
                        powered_by
                        logo
                        logo_height
                        logo_width
                        website_url
                        switch_parent_id
                        switch_child_id
                        bill_profile_id
                        usage_plan_group_id
                        phone_number
                        email
                        coop_table_id
                        wholesale_as_retail
                        fusf_custom_tax_exemption
                        foc_notification_emails
                        fusf_exemption
                        has_glds_billing
                        has_provisioning
                        port_network_email_list
                        port_action_email_list
                        port_impending_foc_email_list
                        queue
                        id
                        createdAt
                        updatedAt
                        _version
                      }
                      nextToken
                      startedAt
                      __typename
                    }
                  }
                `;

                while (hasData) {
                    var options = {
                        query: listQuery,
                        variables: {
                            filter: filterOptions,
                            limit: 2000
                        },
                        authMode: GRAPHQL_AUTH_MODE.API_KEY
                    }

                    if (nextToken != null) {
                        options.variables.nextToken = nextToken;
                    }

                    var partners = await API.graphql(options);

                    for (const item of partners.data.listPartners.items) {
                        partnersData.push(item);
                    }

                    if (partners.data.listPartners.nextToken != null) {
                        nextToken = partners.data.listPartners.nextToken;
                    }
                    else {
                        hasData = false;
                    }
                }

                for(let partnerObj of partnersData) {
                    this.partnerInformation[partnerObj.partner] = partnerObj;
                    this.partnerOptions.push({label: partnerObj.name, value: partnerObj.partner})
                }

                return {error: false, message: 'Ok'};
                
            }
            catch (error) {
                return {error: true, message: error_message};
            }
        },
        async fetchUserPartner() {
            this.userAssociatedPartner = {};
            var userInfo = {};
            const authStore = useAuthStore();
            var username = authStore.getCurrentUser.username;
            var error_message = 'Unable to Get Partner';

            try {
                let options = {
                    query: queries.getUsers,
                    variables: {
                        id: username
                    },
                    authMode: GRAPHQL_AUTH_MODE.API_KEY
                }

                let result = await API.graphql(options);
                if (result.data.getUsers && !result.data.getUsers._deleted) {
                    if(Object.keys(result.data.getUsers).length > 0) {
                        userInfo = result.data.getUsers;
                    } else {
                        return { error: true, message: error_message, userInfo: null };
                    }
                }
                return { error: false, message: "Ok", userInfo: userInfo };

            } catch (error) {
                return { error: true, message: error_message, userInfo: null };
            }
        },
        fetchUserAssociatedPartners() {
            this.userAssociatedPartners = [];
            var error_message = 'Unable to Get Partners';
            try {

                //TODO: Call to Fetch Data from Database Table
                //QUESTION: Is there a distinct or group by that can be used within the call?
                var result = {} //TEMP
                if(result.body.status == 400) {
                    return {error: true, message: error_message};
                }

                this.userAssociatedPartners = result.body.whateverTheQueryNameIs.items;
                return {error: false, message: 'Ok'};
                
            }
            catch (error) {
                return {error: true, message: error_message};
            }
        },
        isString(valueToValidate) {
            var isValid = valueToValidate === "string";
            return isValid; 
        },
        isInt(valueToValidate) {
            var intTest = parseInt(valueToValidate);
            if(isNaN(intTest)) {
                return false;
            }
            return true;
        },
        formatISOStringToLocalDate(isoString) {
            const dateObj = new Date(isoString);

            const local_date = dateObj.toLocaleString("en-US", {
                year: "numeric",
                month: "numeric",
                day: "numeric",
                hour: "numeric",
                minute: "numeric",
                timeZoneName: "short",
            });
            
            return local_date;
        },
        isEmpty(valueToValidate) {
            var isValid = valueToValidate === "" || valueToValidate === undefined;
            return isValid; 
        },
        containsSmartQuote(valueToValidate) {
            return /[\u2018\u2019\u201A]/.test(valueToValidate) || /[\u201C\u201D\u201E]/.test(valueToValidate);
        },
        hasWhiteSpacesOnEnds(valueToValidate) {
            return /^\s|\s$/.test(valueToValidate); 
        },
        containsTab(valueToValidate) {
            return /\t/.test(valueToValidate); 
        },
        containsNewLine(valueToValidate) {
            return /\n/.test(valueToValidate); 
        },
        hasNoNonWordCharacters(valueToValidate) {
            return !/\W/.test(valueToValidate); 
        },
        containsSpaces(valueToValidate) {
            return /\s/.test(valueToValidate);
        },
        async sendMail(to, from, subject, body, cc, bcc, attachments = null) {

            console.log("Sending Email");
            const api_name = "mail";
            const path = "/frontend/send";

            const userAuth = `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`;
            
            var bodyJSON = {
                to: to,
                from: from,
                subject: subject,
                body: body,
                cc: cc,
                bcc: bcc,
            }

            if (attachments != null) {
                body.attachments = attachments
            }

            const myInit = {
                body: bodyJSON,
                headers: {
                    Authorization: userAuth,
                    "Content-Type": "application/json"
                }  
            }

            try {
                const response = await API.post(api_name, path, myInit);
                console.log(response)
            }
            catch(error) {
                // TODO: what needs to be done here besides a console.log? 
                console.log(error);
            }
        },
        setRTFlag(flag) {
            this.RT_STILL_ACTIVE = flag;
        },
        async checkRTHealth() {
            const authStore = useAuthStore();
            const secret = authStore.getUserManagementSecret;

            AWS.config.update({
                region: awsconfig.aws_project_region,
                accessKeyId: secret.ACCESS_KEY_ID,
                secretAccessKey: secret.SECRET_ACCESS_KEY
            });

            if (this.RT_STILL_ACTIVE) {
                try {
                    const lambda = new AWS.Lambda();
                    const lambda_result = await lambda.invoke({
                        FunctionName: "checkRTHealth",
                    }).promise();

                    if (lambda_result.Payload !== '200') {
                        this.RT_STILL_ACTIVE = false;
                    }
                }
                catch(error) {
                    console.log(error);
                    this.RT_STILL_ACTIVE = false;
                }
            }
        }
    },
})