<template>
    <div class="flowsContainer">
        <div :style="{ height: PROPS.dynamicHeight - 100 + 'px' }">
            <RealTimeQueueDash v-if="selectedQueue != null" :queues="queueData" :queueSelected="selectedQueue"/>

            <TableComponent v-else ref="QueueDashboardTable" :table_headers="headers" :table_data="queueData"
                :hide_footer="true" :sortBy="sortBy" :sortType="sortType" :actionControlBarActive="true"
                :searchableColumns="true">

                <!-- Right Side Table Actions Template -->
                <template v-slot:rightSideActions>
                    <div>
                        <span title="Switch Socket is Connecting" class="dot" style="background-color: yellow;"
                            v-if="QueueSocketStatus == '0'"></span>
                        <span title="Switch Socket is Connected" class="dot" style="background-color: green;"
                            v-if="QueueSocketStatus == '1'"></span>
                        <span title="Switch Socket is Closing" class="dot" style="background-color: orange;"
                            v-if="QueueSocketStatus == '2'"></span>
                        <span title="Switch Socket is Closed" class="dot" style="background-color: red;"
                            v-if="QueueSocketStatus == '3'"></span>
                    </div>
                </template>


                <!-- Item Templates -->

                <template #item-queueName="item">
                    <span @click="handleSelectedQueue(item)">{{ item.queueName }}</span>
                </template>


                <template #item-callsWaitingAndWaitTime="item">
                    <div style="padding-bottom: 5px; padding-top: 5px;">
                        <img :src="require(`@/assets/icons/${getColumnIcons('Calls Waiting & Wait Time')[0]
                            }`)
                            " class="tableIcons" />
                        <span>{{ item.callsWaitingAndWaitTime.first }}</span>
                    </div>
                    <div>
                        <img :src="require(`@/assets/icons/${getColumnIcons('Calls Waiting & Wait Time')[1]
                            }`)
                            " class="tableIcons" />
                        <span>{{ item.callsWaitingAndWaitTime.second }}</span>
                    </div>
                </template>
                <template #item-agentsOnPhoneAndAway="item">
                    <div style="padding-bottom: 5px; padding-top: 5px;">
                        <img :src="require(`@/assets/icons/${getColumnIcons('Agents On Phone & Away')[0]
                            }`)
                            " class="tableIcons" />
                        <span>{{ item.agentsOnPhoneAndAway.first }}</span>
                    </div>
                    <div>
                        <img :src="require(`@/assets/icons/${getColumnIcons('Agents On Phone & Away')[1]
                            }`)
                            " class="tableIcons" />
                        <span>{{ item.agentsOnPhoneAndAway.second }}</span>
                    </div>
                </template>
                <template #item-logicomUSAAndOverflowTickets="item">
                    <div style="padding-bottom: 5px; padding-top: 5px;">
                        <img :src="require(`@/assets/icons/${getColumnIcons('LogicomUSA & Overflow Tickets')[0]
                            }`)
                            " class="tableIcons" />
                        <span>{{ item.logicomUSAAndOverflowTickets.first }}</span>
                    </div>
                    <div>
                        <img :src="require(`@/assets/icons/${getColumnIcons('LogicomUSA & Overflow Tickets')[1]
                            }`)
                            " class="tableIcons" />
                        <span>{{ item.logicomUSAAndOverflowTickets.second }}</span>
                    </div>
                </template>
                <template #item-ticketsEscalated="item">
                    <div style="padding-bottom: 5px; padding-top: 5px;">
                        <img :src="require(`@/assets/icons/${getColumnIcons('Tickets Escalated')[0]
                            }`)
                            " class="tableIcons" />
                        <span>{{ item.ticketsEscalated.first }}</span>
                    </div>
                    <div>
                        <img :src="require(`@/assets/icons/${getColumnIcons('Tickets Escalated')[1]
                            }`)
                            " class="tableIcons" />
                        <span>{{ item.ticketsEscalated.second }}</span>
                    </div>
                </template>
                <template #item-answeredCalls="item">
                    <div>
                        <img :src="require(`@/assets/icons/${getColumnIcons('Answered Calls')
                            }`)
                            " class="tableIcons" />
                        <span v-html="item.answeredCalls"></span>
                    </div>
                </template>
                <template #item-inboundAndOutboundCalls="item">
                    <div style="padding-bottom: 5px; padding-top: 5px;">
                        <img :src="require(`@/assets/icons/${getColumnIcons('Inbound and Outbound Calls')[0]
                            }`)
                            " class="tableIcons" />
                        <span>{{ item.inboundAndOutboundCalls.first }}</span>
                    </div>
                    <div>
                        <img :src="require(`@/assets/icons/${getColumnIcons('Inbound and Outbound Calls')[1]
                            }`)
                            " class="tableIcons" />
                        <span>{{ item.inboundAndOutboundCalls.second }}</span>
                    </div>
                </template>
                <template #item-missedAndAbandonedCalls="item">
                    <div style="padding-bottom: 5px; padding-top: 5px;">
                        <img :src="require(`@/assets/icons/${getColumnIcons('Missed and Abandoned Calls')[0]
                            }`)
                            " class="tableIcons" />
                        <span>{{ item.missedAndAbandonedCalls.first }}</span>
                    </div>
                    <div>
                        <img :src="require(`@/assets/icons/${getColumnIcons('Missed and Abandoned Calls')[1]
                            }`)
                            " class="tableIcons" />
                        <span>{{ item.missedAndAbandonedCalls.second }}</span>
                    </div>
                </template>
            </TableComponent>
        </div>
    </div>
</template>




<script setup>
import TableComponent from "@/components/base_components/TableComponent.vue";
import RealTimeQueueDash from "./RealTimeQueueDash.vue";
import { useQueueDashboardStore } from "@/stores/ContactCenter/Management/QueueDashboardStore.js";
import { defineProps, computed, ref, onMounted, onUnmounted } from 'vue';

//Props
const PROPS = defineProps({
    dynamicHeight: Number
});

//Constants
const QueueDashboardStore = useQueueDashboardStore();
const QueueSocket = ref(null);
const sortBy = "Queue Name";
const sortType = "asc";
const QueueSocketStatus = ref(3);
const QUEUE_SOCKET_TIMER = ref(null);
const QueueDashboardTable = ref(null);
const selectedQueue = ref(null);

var headers = [
    { text: "Queue Name", value: "queueName", sortable: true },
    { text: "Calls Waiting & Wait Time", value: "callsWaitingAndWaitTime" },
    { text: "Agents Ready", value: "agentsReady" },
    { text: "Agents On Phone & Away", value: "agentsOnPhoneAndAway" },
    { text: "New Tickets", value: "newTickets" },
    { text: "LogicomUSA & Overflow Tickets", value: "logicomUSAAndOverflowTickets" },
    { text: "Tickets Escalated", value: "ticketsEscalated" },
    { text: "Open CSR Escalations", value: "csrEscalations" },
    { text: "Answered Calls", value: "answeredCalls" },
    { text: "Inbound and Outbound Calls", value: "inboundAndOutboundCalls" },
    { text: "Missed and Abandoned Calls", value: "missedAndAbandonedCalls" },
    { text: "Calls Handed to Overflow", value: "callsHandedToOverflow" },
    { text: "Calls Waiting in Queue", value: "callsWaitingInQueue" },
    { text: "Average Wait Time", value: "averageWaitTime" },
    { text: "Longest Hold Time - Current", value: "longestHoldTimeCurrent" },
    { text: "Longest Hold Time - Day", value: "longestHoldTimeDay" },
    { text: "Callbacks Scheduled", value: "callbacksScheduled" },
];


//Computed
const queueData = computed(() => {
    return QueueDashboardStore.getQueueData;
});

//Life Cycle Hooks
onMounted(async () => {
    QueueDashboardTable.value.toggleTableLoadingSpinner();

    QueueSocket.value = await QueueDashboardStore.initializeQueueSocket();
    QueueSocketStatus.value = QueueSocket.value.readyState;

    QUEUE_SOCKET_TIMER.value = setInterval(async () => {
        QueueSocketStatus.value = QueueSocket.value.readyState;

        if (QueueSocketStatus.value === 3) {
            QueueSocket.value = await QueueDashboardStore.initializeAgentSocket();
        }
    }, 5000);


    try {
        await QueueDashboardStore.setQueueList();
        await setupQueueSocketListeners();
        QueueDashboardTable.value.toggleTableLoadingSpinner();
    }
    catch (err) {
        console.error("Error initializing Agent Dashboard data:", err);
    }
});

onUnmounted(async () => {
    QueueDashboardStore.resetQueueData();

  if (QueueSocket.value != null) {
    QueueSocket.value.close();
  }

  if (QUEUE_SOCKET_TIMER.value != null) {
    clearInterval(QUEUE_SOCKET_TIMER.value);
  }
});




//Functions
function getColumnIcons(header, value) {
    if (header === "Status") {
        let statusImg;
        if (value?.first === "Away") {
            statusImg = "High Priority.png";
        } else if (value?.first === "Ready") {
            statusImg = "Available.png";
        } else if (value?.first === "Logged Out") {
            statusImg = "Medium Priority.png";
        } else if (value?.first.includes("On-A-Call") || value?.first === "On-External-Call") {
            statusImg = "Urgent Priority.png";
        }
        else { //TODO: Need more images for different statuses
            statusImg = "High Priority.png";
        }
        return [statusImg, "Status Time.png"];
    } else if (
        header === "Outbound and Answered Calls" ||
        header === "Inbound and Outbound Calls"
    ) {
        return ["Outbound.png", "Inbound.png"];
    } else if (header === "Missed and Abandoned Calls") {
        return ["Missed.png", "Abandonded.png"];
    } else if (header === "Tickets Resolved") {
        return ["Created Tickets.png", "Resolved Tickets.png"];
    } else if (header === "Tickets Escalated") {
        return ["Internal.png", "External.png"];
    } else if (header === "Agents On Phone & Away") {
        return ["Urgent Priority.png", "High Priority.png"];
    } else if (header === "LogicomUSA & Overflow Tickets") {
        return ["LogicomUSA.png", "Overflow.png"];
    } else if (header === "Calls Waiting & Wait Time") {
        return ["callswaiting.png", "waittime_larger.png"];
    } else if (header === "Missed Calls") {
        return "Missed.png";
    } else if (header === "Abandoned Calls") {
        return "Abandonded.png";
    } else if (header === "Outbound Calls") {
        return "Outbound.png";
    } else if (header === "Answered Calls") {
        return "Inbound.png";
    }
    return null;
}

async function setupQueueSocketListeners() {
    try {
        QueueSocket.value.onmessage = async function (e) {
            var event = JSON.parse(e.data);

            console.log("QUEUE SOCKET EVENT: ", event);

            if (event.name == "sync") {
                await QueueDashboardStore.handleQueueEvent(event);
            }
            else if (event.name == "leave") {
                //Call Left the queue??? 
            }
            else if (event.name == "join") {
                //Call Joined the queue???
                await QueueDashboardStore.handleQueueEvent(event);
            }
            else if (event.name == "delivered") {
                await QueueDashboardStore.handleQueueEvent(event);
            }
            else {
                console.log(event);
            }
        }.bind(this);
    }
    catch (err) {
        console.log(err);
    }
}

async function handleSelectedQueue(queue) {
    selectedQueue.value = queue.queueName;
}

</script>

<style scoped>
.flowsContainer {
    padding: 20px;
    width: 100%;
    background-color: #e2f1f4;
}

.tableIcons {
    width: 28px;
    padding-right: 15px;
}

.tableText {
    color: #271d7a;
    font-size: 12pt;
}

.dot {
    height: 25px;
    width: 25px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
}
</style>
