/* eslint-disable */
import { defineStore } from 'pinia'
import { API } from "aws-amplify";
import * as queries from "@/graphql/queries.js";
import * as mutations from "@/graphql/mutations.js";
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api";
import { Storage } from 'aws-amplify';
import { queryGraphQL } from "@/helpers/dbUtil.js";


export const useFlowManagerStore = defineStore('FlowManager', {
    state: () => ({
        questions:[],
        flows:[],
        partners:[],
        queues: new Map()
    }), 
    getters: {
        fetchQuestions() {
            return this.questions;
        },
        fetchFlows() {
            return this.flows;
        },
        fetchPartners(){
            return this.fetchPartners;
        },
        fetchQueues(){
            return this.queues;
        },
        fetchQueuesOptionArray(){
            let return_array = [];
            return_array.push(
{
                    label: "All",
                    value: "All",
                    queue: "All"
                },
                {
                    label: "Work in Progress",
                    value: "Work in Progress",
                    queue: "Work in Progress"
                },
                {
                    label: "Supporting Flow",
                    value: "Supporting Flow",
                    queue: "Supporting Flow"
                },
                {
                    label: "Overflow",
                    value: "Overflow",
                    queue: "Overflow"
                }
            );
            for(const [queue, partner] of this.queues.entries()){
                if(queue){

                    return_array.push({
                        label: queue,
                        value: queue,
                        queue: queue
                    });
                }
            }
                        return return_array;
        }
    },
    actions: {
        recursiveCount(subArray) {

            
            let count = 0;
            for (const item of subArray) {
                if (typeof item === 'object') {
                    count++;
                    if (Array.isArray(item.dependents)) {
                        count += this.recursiveCount(item.dependents); // Recursively count objects in dependents property
                    }
                }
            }
            return count;
        },
        async getQuestions() {
            try {
                var hasData = true;
                var nextToken = null;
                var requestsData = [];

                const listFlowQuestions = /* GraphQL */ `
                query ListFlowQuestions(
                    $filter: ModelFlowQuestionsFilterInput
                    $limit: Int
                    $nextToken: String
                ) {
                    listFlowQuestions(filter: $filter, limit: $limit, nextToken: $nextToken) {
                    items {
                        id
                        type
                        question
                        options
                        url
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                        __typename
                    }
                    nextToken
                    startedAt
                    __typename
                    }
                }
                `;


                
                while (hasData) {
                    var options = {
                        query: listFlowQuestions,
                        variables: {
                            filter: {
                                _deleted: {ne: true}
                            },
                            limit: 2000
                        },
                        authMode: GRAPHQL_AUTH_MODE.API_KEY
                    }

                    if (nextToken != null) {
                        options.variables.nextToken = nextToken;
                    }

                    var requests = await API.graphql(options);

                    if(requests.data.listFlowQuestions && requests.data.listFlowQuestions.items.length > 0){
                        for (const item of requests.data.listFlowQuestions.items) {
                            item.description = item.question;
                            item.open = false;
                            requestsData.push(item);
                        }
                    }

                    if (requests.data.listFlowQuestions.nextToken != null) {
                        nextToken = requests.data.listFlowQuestions.nextToken;
                    }
                    else {
                        hasData = false;
                    }
                }


                await Promise.all(requestsData.map(async (question) => { 
                    var flows_list = this.findQuestionInFlows(question.id, this.flows);
                    flows_list = [...new Set(flows_list)];

                    question["options"] = question["options"] ? JSON.parse(question["options"]) : [];
                    question.flows_list = flows_list;
                    if (flows_list.length) {
                        question.flowsUsingQuestion = flows_list.length;
                    }
                    else {
                        question.flowsUsingQuestion = "None";
                    }

                    
                    question.questionID = question.id;
                    question.dynamicOptions = question["options"];
                    question.questionType = question.type;
                    question["Enter url"] = question.url;
                    question.header = true;
                }));


                this.questions = requestsData;
            }
            catch (error) {
                console.log("getQuestions error",error);
            }
        },
        async getPartners() {
            try {
                var options = {
                    query: queries.listQueues,
                    variables: {
                        limit: 2000,
                        filter: {
                            _deleted: {ne: true},
                            queue: {ne: null},
                            queue: {ne: ""},
                        }
                    },
                    authMode: GRAPHQL_AUTH_MODE.API_KEY
                }

                const response = await API.graphql(options);

                var partners_return = response.data.listQueues.items;

                var queue_not_allowed_array = ["Billing", "Contact Center Leadership", "Contact Center Partners", "Contact Center Support", "ContactCenterManagement", "Default", "Development", "DevelopmentSupport", "IT Help Desk", "LNP", "Provisioning", "Voice", "SwitchOps", "SwitchOpsEscalations"];

                for(let partner of partners_return){
                    this.partners.push({
                        name: partner.name,
                        partner: partner.partner,
                        queue: partner.queue
                    });

                    if (!queue_not_allowed_array.includes(partner.queue)) {
                        this.queues.set(partner.queue, partner.queue_name);
                    }
                }  
            }
            catch(error) {
                console.log(error);
            }
        },
        async getQueues(current_user_partner) {
            try {
                let filter = {
                    _deleted: {ne: true},
                    queue: {ne: null},
                    queue: {ne: ""},
                }

                if (current_user_partner != "LogicomUSA") {
                    filter['queue'] = {eq: current_user_partner}
                }

                var options = {
                    query: queries.listQueues,
                    variables: {
                        limit: 2000,
                        filter: filter
                    },
                    authMode: GRAPHQL_AUTH_MODE.API_KEY
                }

                const response = await API.graphql(options);

                var queues_response = response.data.listQueues.items;

                var queue_not_allowed_array = ["Billing", "Contact Center Leadership", "Contact Center Partners", "Contact Center Support", "ContactCenterManagement", "Default", "Development", "DevelopmentSupport", "IT Help Desk", "LNP", "Provisioning", "Voice", "SwitchOps", "SwitchOpsEscalations"];

                let queue_array = [];

                for(let queue of queues_response){
                    if (!queue_not_allowed_array.includes(queue.queue)) {
                        queue_array.push({
                            label: queue.queue_name,
                            value: queue.queue
                        })
                    }
                }  

                return queue_array;
            }
            catch(error) {
                console.log(error);
            }
        },
        async getAllFlows(current_user_partner) {
            try {

                let filter = {
                    _deleted: {ne: true},
                }

                if (current_user_partner != "LogicomUSA") {
                    filter['allowed_queues'] = {eq: current_user_partner}
                }

                var options = {
                    query: queries.listFlows,
                    variables: {
                        limit: 2000,
                        filter: filter
                    },
                    authMode: GRAPHQL_AUTH_MODE.API_KEY
                }

                const response = await API.graphql(options);

                var flows = response.data.listFlows.items;
                
                for (var flow of flows) {
                    flow.bucket = 'staging';
                    // TODO: figure out if we are going to preview production flows if we aren't we don't need the following code
                    // flow.production_json = "";
                    // if(flow.s3_production_bucket_key){
                    //     result = await Storage.get(flow.s3_production_bucket_key, { level: "public", download: true });
                    //     flow.production_json = JSON.parse(await result.Body.text());
                    // }
                    // flow.bucket = 'production';

                    flow.changed = "No";
                    if(flow.changed_flag){
                        flow.changed = "Yes";
                    }
                    flow.markedForRemoval = "No";
                    if(flow.removal_flag){
                        flow.markedForRemoval = "Yes";
                    }
                    flow.currentlyPublished = "No";
                    if(flow.s3_production_bucket_key != null && flow.s3_production_bucket_key != "") {
                        flow.currentlyPublished = "Yes";
                    }
                    flow.flowName = flow.name;
                    flow.partners_label = flow.allowed_queues;
                    flow.partners = flow.allowed_queues;
                    flow.partners_label_staging = flow.allowed_queues_staging;
                    flow.partners_staging = flow.allowed_queues_staging;
                    flow.headers = true;
                }

                return flows;
            }
            catch(error) {
                console.log("flows load error: ",error);
            }
        },
        async getFlows() {
            await this.getPartners();
            try {
                var options = {
                    query: queries.listFlows,
                    variables: {
                        limit: 2000,
                        filter: {
                            _deleted: {ne: true}
                        }
                    },
                    authMode: GRAPHQL_AUTH_MODE.API_KEY
                }

                const response = await API.graphql(options);

                var flows = response.data.listFlows.items;
                
                await Promise.all(flows.map(async (flow) => {
                    let result = await Storage.get(flow.s3_staging_bucket_key, { level: "public", download: true, cacheControl: "no-cache" });
                                        flow.staging_json = JSON.parse(await result.Body.text());

                    flow.bucket = 'staging';
                    // TODO: figure out if we are going to preview production flows if we aren't we don't need the following code
                    // flow.production_json = "";
                    // if(flow.s3_production_bucket_key){
                    //     result = await Storage.get(flow.s3_production_bucket_key, { level: "public", download: true });
                    //     flow.production_json = JSON.parse(await result.Body.text());
                    // }
                    // flow.bucket = 'production';

                    flow.changed = "No";
                    if(flow.changed_flag){
                        flow.changed = "Yes";
                    }
                    flow.markedForRemoval = "No";
                    if(flow.removal_flag){
                        flow.markedForRemoval = "Yes";
                    }
                    flow.currentlyPublished = "No";
                    if(flow.s3_production_bucket_key != null && flow.s3_production_bucket_key != "") {
                        flow.currentlyPublished = "Yes";
                    }
                    flow.flowName = flow.name;
                    // console.log(flow.json);
                    flow.questions = this.recursiveCount(flow.staging_json);
                    flow.staging_json_stringified = JSON.stringify(flow.staging_json);

                    flow.partners_label = flow.allowed_queues;
                    flow.partners = flow.allowed_queues;
                    flow.partners_label_staging = flow.allowed_queues_staging;
                    flow.partners_staging = flow.allowed_queues_staging;
                    flow.headers = true;
                }));

                this.flows = [...flows];
            }
            catch(error) {
                console.log("flows load error: ",error);
            }
        },
        async updateFlow(id, allowed_queues, name, flow, version) {
            // TODO: push flow to s3 staging bucket with name_timestamp.json
            let s3_staging_bucket_key = `Flows-staging/${name}.json`;
            
            // flow = `{"label": "${name}", "value":${JSON.stringify(flow)}}`;

            console.log("UPDATING FLOW");
            console.log(flow);
            
            try{
                await Storage.put(s3_staging_bucket_key, flow, {
                    contentType: 'text/json',
                    level: 'public', 
                });
            }
            catch(error){
                console.log("error with upload", error);
            }

            try {
                var options = {
                    query: mutations.updateFlows,
                    variables: {
                        input: {
                            id: id,
                            allowed_queues_staging: allowed_queues,
                            s3_staging_bucket_key: s3_staging_bucket_key,
                            changed_flag: true,
                            _version: version
                        }
                    },
                    authMode: GRAPHQL_AUTH_MODE.API_KEY
                };

                // let createResult = await API.graphql(options);
                await API.graphql(options);
            }
            catch (errors) {
                console.log("create immediate disconnect not working", errors);
            }
            await this.getFlows();
        },
        async createFlow(allowed_queues, name, flow) {
            let s3_staging_bucket_key = `Flows-staging/${name}.json`;
            
            try{
                await Storage.put(s3_staging_bucket_key, flow, {
                    contentType: 'text/json',
                    level: 'public', 
                });
            }
            catch(error){
                console.log("error with upload", error);
            }

            try {
                var options = {
                    query: mutations.createFlows,
                    variables: {
                        input: {
                            name: name,
                            allowed_queues_staging: allowed_queues,
                            s3_staging_bucket_key: s3_staging_bucket_key
                        }
                    },
                    authMode: GRAPHQL_AUTH_MODE.API_KEY
                };

                await API.graphql(options);
            }
            catch (errors) {
                console.log("create immediate disconnect not working", errors);
            }
            await this.getFlows();
        },
        async unRemoveFlow(id, version) {
            try {
                var options = {
                    query: mutations.updateFlows,
                    variables: {
                        input: {
                            id: id,
                            removal_flag: false,
                            _version: version
                        }
                    },
                    authMode: GRAPHQL_AUTH_MODE.API_KEY
                };

                // let createResult = await API.graphql(options);
                await API.graphql(options);
            }
            catch (errors) {
                console.log("create immediate disconnect not working", errors);
            }

            await this.getFlows();
        },
        async removeFlow(id, version) {
            try {
                var options = {
                    query: mutations.updateFlows,
                    variables: {
                        input: {
                            id: id,
                            removal_flag: true,
                            _version: version
                        }
                    },
                    authMode: GRAPHQL_AUTH_MODE.API_KEY
                };

                // let createResult = await API.graphql(options);
                await API.graphql(options);
            }
            catch (errors) {
                console.log("create immediate disconnect not working", errors);
            }
            await this.getFlows();
        },
        async updateFlowQuestions(id, question, question_type, question_options, question_url, version) {
            try {
                var options = {
                    query: mutations.updateFlowQuestions,
                    variables: {
                        input: {
                            id: id,
                            question: question,
                            type: question_type,
                            options: question_options,
                            url: question_url,
                            _version: version
                        }
                    },
                    authMode: GRAPHQL_AUTH_MODE.API_KEY
                };

                // let createResult = await API.graphql(options);
                await API.graphql(options);
            }
            catch (errors) {
                console.log("Error updating question", errors);
            }
            await this.getQuestions();
        },
        async removeFlowQuestions(id, version) {

            const deleteMutation = /* GraphQL */ `
                mutation DeleteFlowQuestions(
                    $input: DeleteFlowQuestionsInput!
                    $condition: ModelFlowQuestionsConditionInput
                ) {
                    deleteFlowQuestions(input: $input, condition: $condition) {
                        id
                        type
                        question
                        options
                        url
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                        __typename
                    }
                }
          `;
            const options = {
                query: deleteMutation,
                variables: {
                    input: {
                        id: id,
                        _version: version
                    }
                },
                authMode: GRAPHQL_AUTH_MODE.API_KEY
            };

            try {
                const result = await API.graphql(options);
                if (!result.data.deleteFlowQuestions.id) {
                    return { error: true, message: 'Unable to delete question'};
                } else {
                    await this.getQuestions();
                    return { error: false, message: 'Ok'};

                }
            }
            catch (error) {
                return { error: true, message: 'Unable to delete question'};
            }
        },
        async applyQuestionToFlows(question_id) {
            const getFlowQuestions = /* GraphQL */ `
                query GetFlowQuestions($id: ID!) {
                    getFlowQuestions(id: $id) {
                        id
                        type
                        question
                        options
                        url
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                        __typename
                    }
                }
            `;

            try {
                var options = {
                    query: getFlowQuestions,
                    variables: {
                        id: question_id
                    },
                    authMode: GRAPHQL_AUTH_MODE.API_KEY
                }

                const result = await queryGraphQL(options);

                if (!result.data.getFlowQuestions.id) {
                    return {error: true, message: error_message};
                }

                var returnedQuestion = {};
                var flows_list = this.findQuestionInFlows(result.data.getFlowQuestions.id, this.flows);
                var returnedOptions = "";


                try {
                    returnedOptions = JSON.parse(result.data.getFlowQuestions.options);
                } catch(error) {
                    returnedOptions = "";
                }

                returnedQuestion.id = result.data.getFlowQuestions.id;
                returnedQuestion.type = result.data.getFlowQuestions.type;
                returnedQuestion.question = result.data.getFlowQuestions.question;
                returnedQuestion.options = returnedOptions;
                returnedQuestion.url = result.data.getFlowQuestions.url;
                returnedQuestion.createdAt = result.data.getFlowQuestions.createdAt;
                returnedQuestion.updatedAt = result.data.getFlowQuestions.updatedAt;
                returnedQuestion._version = result.data.getFlowQuestions._version;
                returnedQuestion._deleted = result.data.getFlowQuestions._deleted;
                returnedQuestion._lastChangedAt = result.data.getFlowQuestions._lastChangedAt;
                returnedQuestion.__typename = result.data.getFlowQuestions.__typename;
                returnedQuestion.description = result.data.getFlowQuestions.question;
                returnedQuestion.flowsUsingQuestion = flows_list.length > 0 ? flows_list.length : "None";
                returnedQuestion.questionID = result.data.getFlowQuestions.id;
                returnedQuestion.dynamicOptions = returnedOptions;
                returnedQuestion.questionType = result.data.getFlowQuestions.type;
                returnedQuestion["Enter url"] = result.data.getFlowQuestions.url;
                returnedQuestion.header = true;
                returnedQuestion.timestamp = Date.now();

                for(let flow_id of flows_list) {
                    let foundObj = this.flows.find((obj) => obj.id == flow_id);
                    if(foundObj) {
                        var current_staging_json = JSON.parse(JSON.stringify(foundObj.staging_json));
                        var updated_flow = await this.seekAndModifyQuestion(current_staging_json, returnedQuestion);
                        
                        let id = foundObj?.id || "";
                        let allowed_queues_staging = foundObj?.allowed_queues_staging || "";
                        let name = foundObj?.flowName || "";
                        let version = foundObj?._version || "";

                        if(id && allowed_queues_staging && name && version) {
                            await this.updateFlow(id, allowed_queues_staging, name, updated_flow, version);    
                        }
                    }
                }

                return {error: false, message: "Flows Updated"};
            }
            catch(error) {
                // TODO: what needs to be done here besides a console.log? 
                console.log(error);
                return {error: true, message: "An Error Occurred"};
            }
        },
        async seekAndModifyQuestion(subArray, returnedQuestion) {
            var returned_array = [];

            for(var question of subArray) {

                var workingQuestion = question;
                var workingQuestionHasDependents = true;

                if(workingQuestion.questionID == returnedQuestion.id) {
                        
                    if(workingQuestion.type != "Flow") {

                        if(returnedQuestion.options && returnedQuestion.options.length == 0) {
                            workingQuestion = returnedQuestion;
                        } else {
                            var filteredDependents = [];
                            if(workingQuestion.dependents && workingQuestion.dependents.length > 0) {
                                var newOptionsObj = {};
                                for(var i in returnedQuestion.options) {
                                    var val = returnedQuestion.options[i];
                                    newOptionsObj[i] = val;
                                }

                                var oldOptionsObj = {};
                                for(var j in workingQuestion.options) {
                                    var val = workingQuestion.options[j].value;
                                    oldOptionsObj[val] = j;
                                }

                                for(var dependent of workingQuestion.dependents) {
                                    var temp = dependent;
                                    var deptOn = dependent.dependentOn;
                                    var index = oldOptionsObj[deptOn];
                                    if(Object.keys(newOptionsObj).indexOf(index) != -1) {
                                        temp.dependentOn = newOptionsObj[index].value;
                                        filteredDependents.push(temp);
                                    }
                                }
                            }
                            else {
                                workingQuestionHasDependents = false;
                            }

                            if (workingQuestionHasDependents) {
                                workingQuestion = JSON.parse(JSON.stringify(returnedQuestion));
                                workingQuestion.dependents = filteredDependents;
                            }
                            else {
                                workingQuestion = JSON.parse(JSON.stringify(returnedQuestion));
                                delete workingQuestion.dependents;
                            } 
                        }

                        workingQuestion.open = question.open;
                        if(question.dependentOn) {
                            workingQuestion.dependentOn = question.dependentOn;
                        }
                    }

                }

                //Handle any dependents
                if(workingQuestion.dependents && workingQuestion.dependents.length > 0) {
                    let dependent_json_array = await this.seekAndModifyQuestion(workingQuestion.dependents, returnedQuestion);
                    workingQuestion.dependents = dependent_json_array;
                }
                returned_array.push(workingQuestion);
            }

            return returned_array;
        },
        async saveFlowQuestions(question, question_type, question_options, question_url) {
            try {
                var options = {
                    query: mutations.createFlowQuestions,
                    variables: {
                        input: {
                            question: question,
                            type: question_type,
                            options: question_options,
                            url: question_url
                        }
                    },
                    authMode: GRAPHQL_AUTH_MODE.API_KEY
                };

                let createResult = await API.graphql(options);
                //await API.graphql(options);
                console.log("Create question result", createResult);
            }
            catch (errors) {
                console.log("create immediate disconnect not working", errors);
            }
            await this.getQuestions();
        },
        async copyFile(sourceKey, targetKey) {
            try {
                await Storage.copy({ key:sourceKey }, { key:targetKey });
                console.log(`File copied from ${sourceKey} to ${targetKey}`);
            } catch (error) {
                console.error(`Error copying file: Failed to File copied from ${sourceKey} to ${targetKey}:`, error);
            }
        },
        async publishIndividualFlowToProduction(flow_id) {
            await this.getFlows();

            var flow = this.flows.find(obj => obj.id == flow_id);
            if(flow) {
                if(flow.removal_flag){
                    try {
                        var options = {
                            query: mutations.deleteFlows,
                            variables: {
                                input: {
                                    id: flow.id,
                                    _version: flow._version
                                }
                            },
                            authMode: GRAPHQL_AUTH_MODE.API_KEY
                        };
        
                        // let createResult = await API.graphql(options);
                        await API.graphql(options);
                    }
                    catch (errors) {
                        console.log("remove individual flow not working", errors);
                    }
                }
                else{
                    console.log(flow);
                    let s3_production_bucket_key = flow.s3_staging_bucket_key.replace("staging", "production");
                    await this.copyFile(flow.s3_staging_bucket_key, s3_production_bucket_key);
                    try {
                        var options = {
                            query: mutations.updateFlows,
                            variables: {
                                input: {
                                    id: flow.id,
                                    s3_production_bucket_key: s3_production_bucket_key,
                                    changed_flag: false,
                                    removal_flag: false,
                                    allowed_queues: flow.allowed_queues_staging,
                                    _version: flow._version
                                }
                            },
                            authMode: GRAPHQL_AUTH_MODE.API_KEY
                        };
        
                        // let createResult = await API.graphql(options);
                        await API.graphql(options);
                    }
                    catch (errors) {
                        console.log("update individual flow not working", errors);
                    }
                }
            }

            await this.getFlows();
        },
        async publishFlowsToProduction(){
            await this.getFlows();

            for(let flow of this.flows){
                if(flow.removal_flag){
                    try {
                        var options = {
                            query: mutations.deleteFlows,
                            variables: {
                                input: {
                                    id: flow.id,
                                    _version: flow._version
                                }
                            },
                            authMode: GRAPHQL_AUTH_MODE.API_KEY
                        };
        
                        // let createResult = await API.graphql(options);
                        await API.graphql(options);
                    }
                    catch (errors) {
                        console.log("create immediate disconnect not working", errors);
                    }
                }
                else{
                    console.log(flow);
                    let s3_production_bucket_key = flow.s3_staging_bucket_key.replace("staging", "production");
                    await this.copyFile(flow.s3_staging_bucket_key, s3_production_bucket_key);
                    try {
                        var options = {
                            query: mutations.updateFlows,
                            variables: {
                                input: {
                                    id: flow.id,
                                    s3_production_bucket_key: s3_production_bucket_key,
                                    changed_flag: false,
                                    removal_flag: false,
                                    allowed_queues: flow.allowed_queues_staging,
                                    _version: flow._version
                                }
                            },
                            authMode: GRAPHQL_AUTH_MODE.API_KEY
                        };
        
                        // let createResult = await API.graphql(options);
                        await API.graphql(options);
                    }
                    catch (errors) {
                        console.log("create immediate disconnect not working", errors);
                    }
                }
            }
            await this.getFlows();
        },
        async overwriteIndividualStagingFlowFromProduction(flow_id) {
            await this.getFlows();

            var flow = this.flows.find(obj => obj.id == flow_id);
            if(flow) {
                if(flow.s3_production_bucket_key){
                    this.copyFile(flow.s3_production_bucket_key, flow.s3_staging_bucket_key);
                }

                try {
                    var options = {
                        query: mutations.updateFlows,
                        variables: {
                            input: {
                                id: flow.id,
                                changed_flag: false,
                                removal_flag: false,
                                allowed_queues_staging: flow.allowed_queues,
                                _version: flow._version
                            }
                        },
                        authMode: GRAPHQL_AUTH_MODE.API_KEY
                    };

                    // let createResult = await API.graphql(options);
                    await API.graphql(options);
                }
                catch (errors) {
                    console.log("individual staging overwrite not working", errors);
                }
            }

            await this.getFlows();
        },
        async overwriteStagingFlowsFromProduction(){
            await this.getFlows();

            for(let flow of this.flows){
                if(flow.s3_production_bucket_key){
                    this.copyFile(flow.s3_production_bucket_key, flow.s3_staging_bucket_key);
                }

                try {
                    var options = {
                        query: mutations.updateFlows,
                        variables: {
                            input: {
                                id: flow.id,
                                changed_flag: false,
                                removal_flag: false,
                                allowed_queues_staging: flow.allowed_queues,
                                _version: flow._version
                            }
                        },
                        authMode: GRAPHQL_AUTH_MODE.API_KEY
                    };
    
                    // let createResult = await API.graphql(options);
                    await API.graphql(options);
                }
                catch (errors) {
                    console.log("create immediate disconnect not working", errors);
                }
            }
            await this.getFlows();
        },
        findQuestionInFlows(questionID, flows) {
            let flow_list = [];

            let result = flows.filter((flow) => flow.staging_json_stringified.includes(questionID));

            if (result) {
                for (let item of result) {
                    flow_list.push(item.id); 
                }
            }

            return flow_list;
        },

    },
})


